<alert *ngFor="let alert of alerts" [type]="alert.type" dismissible="true" [dismissOnTimeout]="alert.timeout">
  <div *ngIf="alert.title" class="alert-heading">
    <i *ngIf="'success' === alert.type" class="fa fa-lg fa-check-circle mr-2"></i>
    <i *ngIf="'info' === alert.type" class="fa fa-lg fa-info-circle mr-2"></i>
    <i *ngIf="'danger' === alert.type || 'warning' === alert.type" class="fa fa-lg fa-exclamation-circle mr-2"></i>{{alert.title}}</div>
  <div *ngIf="alert.link">
    <a [href]="alert.link.url">{{alert.link.name}}</a>
  </div>
  <ul class="content" *ngIf="alert.content && alert.content.length">
    <li class="content-item" *ngFor="let item of alert.content">{{ item }}</li>
  </ul>
</alert>
