// http状态吗
export enum EHttpStatusCode {
    /// <summary>
    /// 成功
    /// </summary>
    Ok = 200,
    /// <summary>
    /// 参数不合法
    /// </summary>
    BadRequest = 400,
    /// <summary>
    /// 权限不足
    /// </summary>
    Forbidden = 403,
    /// <summary>
    /// 内容不存在
    /// </summary>
    NotFound = 404,
    /// <summary>
    /// 内容已被他人修改
    /// </summary>
    Conflict = 409,
    /// <summary>
    /// 状态检查不通过
    /// </summary>
    PreconditionFailed = 412,
    /// <summary>
    /// 服务异常
    /// </summary>
    InternalServerError = 500,
    /// <summary>
    /// 重定向
    /// </summary>
    Redirect = 302,
    /// <summary>
    /// 跳转到已处理列表
    /// </summary>
    RedirectDone = 301,
    /// <summary>
    /// 跳转到待处理列表
    /// </summary>
    RedirectTodo = 304,

}
