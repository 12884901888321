import { Component, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import _ from 'lodash';

import { CRadioBase } from 'src/app/classes';
import { PAGE_SIZE, PAGE_LINK_LIMIT, PERSON_MAX_LIMIT } from 'src/app/consts';
import { EOrganizationChartItemOperationType, EOrganizationTemplateType, EOrganizationShareLevel } from 'src/app/enums';
import { ReduxService } from 'src/app/redux/redux.service';
import { UserService } from 'src/app/services/user.service';
import { OrganizationTemplateService } from 'src/app/services/organization-template.service';
import {
  ITab, IOrganizationTemplate,
  IOrganizationTemplateParams,
  IDivisionPlanEnginField,
  IPagination,
  IOrganizationChartItem, IAlert,
  IProject,
  TOrganizationChartItemAddWay
} from 'src/app/models';
import { CheckEnginFieldTreeComponent } from 'src/app/modules/shared/check-engin-field-tree/check-engin-field-tree.component';
import { SharedService } from 'src/app/services/shared.service';
import { SubscriptionService } from 'src/app/services/subscription.service';

@Component({
  selector: 'app-add-organization-chart-item',
  templateUrl: './add-organization-chart-item.component.html',
  styleUrls: ['./add-organization-chart-item.component.scss']
})
export class AddOrganizationChartItemComponent extends CRadioBase<IOrganizationTemplate, IOrganizationTemplateParams> {
  modalRef: BsModalRef;
  @ViewChild('addOrganizationItem') modalEle: TemplateRef<any>;
  @ViewChild('appCheckEnginFieldTree') checkEnginFieldTreeComponent: CheckEnginFieldTreeComponent;

  defaultTab = 1;
  tabs: ITab[] = [{
    label: '标准组织模板',
    value: 1
  }, {
    label: '自定义组织节点',
    value: 2
  }];
  tabValue: number;

  // tags: IOrganizationNodeTag[] = [];

  myForm: FormGroup;
  nodeDataForm: FormGroup;
  addWays: TOrganizationChartItemAddWay[];
  enginField: IDivisionPlanEnginField;
  title: string;
  organizationChartItems: IOrganizationChartItem[];
  enableEdit: boolean;
  oldOrganizationChartItem: IOrganizationChartItem;
  parentNodePath: string;
  project: IProject;

  page = 1;
  limit = PAGE_SIZE;
  pageLinkLimit = PAGE_LINK_LIMIT;
  count = 0;
  standardOrganizationChartItems: IOrganizationChartItem[]; // ”标准组织节点“tab需要展示的节点
  checkedStandardOrganizationChartItems: IOrganizationChartItem[]; // 选中的“标准组织节点”
  checkedOrganizationChartItem: IOrganizationChartItem;
  constructor(
    private reduxService: ReduxService,
    private modalService: BsModalService,
    private userService: UserService,
    private organizationTemplateService: OrganizationTemplateService,
    private sharedService: SharedService,
    private subscriptionService: SubscriptionService
  ) {
    super(organizationTemplateService, 'getOrganizationTemplates', 'id');
    this.reduxService.subscribeStore(() => this.readState());
    this.readState();
    this.nodeDataForm = this.fb.group({
      disable_delete: [false, Validators.required], // 是否可移除
      person_max_limit: [PERSON_MAX_LIMIT, Validators.required], // 角色最多人员数
      person_min_limit: [1, Validators.required], // 角色最少人员数
      is_can_split_node: [true, Validators.required], // 组织节点是否可向下拆解
      is_can_maintain_team: [false, Validators.required], // PMT经理是否可维护工程师
      is_can_inform_team_member: [false, Validators.required], // 是否发送维护多功能小组提醒
      // tag_key: [EOrganizationNodeTagType.None] // 标签
      description: [''], // 角色描述
    });

    this.myForm = this.fb.group({
      name: ['', Validators.required],
      engin_field_key: [''],
      engin_field_name: [''],
      node_data: this.nodeDataForm
    });
  }

  // tslint:disable-next-line: use-lifecycle-interface
  ngOnInit(): void {
  }

  readState() {
    const isShow = this.reduxService.getAddOrganizationChartItemsShow();
    this.addWays = this.reduxService.getAddOrganizationChartItemAddWays();
    this.organizationChartItems = this.reduxService.getOrganizationChartItems();
    this.enableEdit = this.reduxService.getAddOrganizationChartItemsEnableEdit();
    this.oldOrganizationChartItem = this.reduxService.getCheckedOrganizationChartItem();
    this.parentNodePath = this.reduxService.getAddOrganizationChartItemsParentNodePath();
    this.project = this.reduxService.getCommonProject();
    this.checkedOrganizationChartItem = this.reduxService.getCheckedOrganizationChartItem();
    if (isShow) {
      this.show();
    } else {
      this.hide();
    }
  }

  initTabs() {
    this.tabs = [{
      label: '标准组织模板',
      value: 1
    }, {
      label: '自定义组织节点',
      value: 2
    }];
  }

  changeTab(tabValue: number) {
    this.tabValue = tabValue;
    this.title = this.tabValue === 1 ? '模板列表' : this.tabValue === 2 ? '自定义组织节点' : '标准组织节点';
    if (tabValue === 1) {
      this.getOrganizationTemplatesCount();
    } else {
      // 处理: 自定义节点选择完所属业务重新调用show(),tabValue恒为1的问题
      this.defaultTab = 2;
    }
    // super.clear();
  }

  toCheckEnginField() {
    this.checkEnginFieldTreeComponent.show({
      division_org_number: this.project.development_division_org_number,
      category_key: this.project.category_key,
    });
  }
  checkEnginField(enginField: IDivisionPlanEnginField) {
    if (!enginField) {
      return;
    }
    this.myForm.controls.engin_field_key.setValue(enginField.key);
    this.myForm.controls.engin_field_name.setValue(enginField.name);
  }
  show() {
    this.page = 1;
    this.initTabs();
    this.initStandardOrganizationChartItems();
    if (this.project) {
      this.queryParams = this.sharedService.omitEmptyParams({
        parent_node_path: this.parentNodePath,
        project_code: this.project.code,
        page: this.page,
        limit: this.limit,
        type: EOrganizationTemplateType.Standard,
        share_level: EOrganizationShareLevel.All
      });
    } else {
      // 如果没有project的值(维护私有组织模板)，则只显示自定义组织节点
      this.addWays = ['custom'];
    }
    if (!_.includes(this.addWays, 'custom')) {
      this.tabs = _.reject(this.tabs, {
        label: '自定义组织节点',
      });
    }
    if (!_.includes(this.addWays, 'template')) {
      this.tabs = _.reject(this.tabs, {
        label: '标准组织模板',
      });
    }
    this.tabValue = 'template' === this.addWays[0] && this.defaultTab === 1 ? 1 : 2;
    this.title = 'template' === this.addWays[0] && this.defaultTab === 1 ? '模板列表' : '自定义组织节点';
    // this.tags = this.organizationTemplateService.getOrganizationNodeTags();
    if (!this.modalRef) {
      this.modalRef = this.modalService.show(this.modalEle, { class: 'modal-lg', ignoreBackdropClick: true });
    }
    if ('template' === this.addWays[0]) {
      super.getItems();
      this.getOrganizationTemplatesCount();
    }
    if (this.enableEdit) {
      this.setOldOrganizationChartItem();
    }

  }

  initStandardOrganizationChartItems() {
    // 组织维护时，增加从标准组织模板中新增单个组织节点功能
    // 当组织节点的子节点是通过标准组织模板添加的，且有些被删除时，增加“标准组织节点”tab
    const organizationChartItems = _.reject(_.cloneDeep(this.organizationChartItems), {
      operation: EOrganizationChartItemOperationType.Delete
    });
    const hasTemplateOrgIdChildItem: IOrganizationChartItem = _.find(organizationChartItems, chartItem => {
      // 存在template_org_id属性 && template_org_id不为null
      return chartItem.parent_node_path === this.parentNodePath
        && chartItem.template_org_id
        && !_.isNull(chartItem.template_org_id);
    });
    if (!hasTemplateOrgIdChildItem) {
      // 没有子节点 || 子节点都是自定义节点，不做处理
      return;
    }
    const templateOrgId = hasTemplateOrgIdChildItem.template_org_id;
    // 找出所有使用此templateOrgId的子节点
    const hasTemplateOrgIdChildItems = _.filter(organizationChartItems, {
      parent_node_path: this.parentNodePath,
      template_org_id: templateOrgId
    });
    // 获取此templateOrgId的所有模板子节点
    const params = {
      id: templateOrgId,
      type: EOrganizationTemplateType.Standard
    };
    this.organizationTemplateService.getOrganizationTemplate(params).subscribe(template => {
      if (hasTemplateOrgIdChildItems.length === template.data.items.length) {
        // 若模板节点length 与 使用此templateOrgId的子节点length相同,删除“标准组织节点”tab
        this.standardOrganizationChartItems = [];
        this.tabs = _.reject(this.tabs, {
          label: '标准组织节点'
        });
        return;
      }
      // 若length不同，过滤出被删除的模板节点，供选择
      this.standardOrganizationChartItems = _.cloneDeep(template.data.items);
      _.forEach(hasTemplateOrgIdChildItems, organizationItem => {
        _.remove(this.standardOrganizationChartItems, { name: organizationItem.name });
      });
      this.patchTabs();
      _.forEach(this.organizationChartItems, organizationChartItem => {
        // 为模板节点分配_template_rank属性
        const existInTemplate = _.find(template.data.items, {
          name: organizationChartItem.name,
          level: organizationChartItem.level,
          id: organizationChartItem.template_org_node_id
        });
        if (!existInTemplate) {
          return;
        }
        organizationChartItem._template_rank = existInTemplate.rank;
      });
    });
  }

  patchTabs() {
    if (_.find(this.tabs, { label: '标准组织节点' })) {
      return;
    }
    // 增加“标准组织节点”tab
    this.tabs.push({
      label: '标准组织节点',
      value: 3
    });
  }

  checkStandardOrganizationChartItems(checkedItems: IOrganizationChartItem[]) {
    this.checkedStandardOrganizationChartItems = checkedItems;
  }

  confirm() {
    if (this.tabValue === 2) {
      // 移除”删除“的组织节点
      const organizationChartItems = _.reject(this.organizationChartItems, { operation: EOrganizationChartItemOperationType.Delete });
      let isPathRepeat: boolean;
      _.forEach(organizationChartItems, (organizationChartItem) => {
        const customPath = `${this.parentNodePath}${this.myForm.value.name}/`;
        const organizationChartItemPath = `${organizationChartItem.parent_node_path}${organizationChartItem.name}/`;
        // 如果二者相等且enableEdit为true,则代表这个节点是在进行编辑的节点，不参与对比
        if (this.oldOrganizationChartItem.id === organizationChartItem.id && this.enableEdit) {
          return;
        }
        if (customPath === organizationChartItemPath) {
          const alert: IAlert = {
            type: 'danger',
            title: `名称为“${this.myForm.value.name}”的组织已存在`
          };
          this.subscriptionService.publishAlert(alert);
          isPathRepeat = true;
          return;
        }
      });
      if (isPathRepeat) {
        return;
      }
      // 最小人数不能大于最大人数
      if (!this.organizationTemplateService.verifyMaxNumberGreaterMinNumber
        (+this.nodeDataForm.value.person_max_limit, +this.nodeDataForm.value.person_min_limit)) {
        return;
      }
      // 提交前添加属性center_keys, center_names
      this.myForm.value.node_data.center_keys = [];
      this.myForm.value.node_data.center_names = [];
      // 当前是处于自定义节点模板下
      if (this.enableEdit) {
        const data = _.cloneDeep(this.myForm.value);
        if (this.myForm.controls.engin_field_key.value !== this.oldOrganizationChartItem.engin_field_key) {
          // 若组织节点的所属业务发生变化,则清空多功能小组
          if (this.oldOrganizationChartItem.team_members && this.oldOrganizationChartItem.team_members.length) {
            _.forEach(this.oldOrganizationChartItem.team_members, team => {
              team.multifunctional_teams.items = [];
            });
          }
        }
        this.oldOrganizationChartItem = Object.assign({}, this.oldOrganizationChartItem, data);
        this.reduxService.updateOrganizationChartItem(this.oldOrganizationChartItem);
        this.hide();
        return;
      }
      if (!this.organizationTemplateService.verifyCustomNodeCanMaintainTeamAndCanSplitNodeNotAtSameTime(this.myForm.value)) {
        return;
      }
      this.reduxService.setAddOrganizationChartItem(this.myForm.value);
    } else if (this.tabValue === 1) {
      this.reduxService.setAddOrganizationChartItem(this.checkedItem.id);
    } else {
      this.reduxService.setAddOrganizationChartItem(this.checkedStandardOrganizationChartItems);
    }
    this.reduxService.confirmAddOrganizationChartItem();
    this.initMyForm();
    super.clear();
  }

  hide() {
    if (this.modalRef) {
      this.modalRef.hide();
      this.modalRef = undefined;
      this.reduxService.cancelAddOrganizationChartItem();
      this.initMyForm();
      this.defaultTab = 1;
      super.clear();
    }
  }

  // checkTag(tag: IOrganizationNodeTag) {
  //   this.myForm.controls.tag_key.setValue(tag.name);
  // }

  initMyForm() {
    this.myForm.controls.name.setValue('');
    this.myForm.controls.engin_field_key.setValue('');
    this.myForm.controls.engin_field_name.setValue('');
    this.nodeDataForm.controls.disable_delete.setValue(false);
    this.nodeDataForm.controls.person_max_limit.setValue(PERSON_MAX_LIMIT);
    this.nodeDataForm.controls.person_min_limit.setValue(1);
    this.nodeDataForm.controls.is_can_split_node.setValue(true);
    this.nodeDataForm.controls.is_can_maintain_team.setValue(false);
    this.nodeDataForm.controls.is_can_inform_team_member.setValue(false);
    this.nodeDataForm.controls.description.setValue('');
    // this.nodeDataForm.controls.tag_key.setValue(EOrganizationNodeTagType.None);
  }

  setOldOrganizationChartItem() {
    this.myForm.controls.name.setValue(this.oldOrganizationChartItem.name);
    this.myForm.controls.engin_field_key.setValue(this.oldOrganizationChartItem.engin_field_key);
    this.myForm.controls.engin_field_name.setValue(this.oldOrganizationChartItem.engin_field_name);
    this.nodeDataForm.controls.disable_delete.setValue(false);
    this.nodeDataForm.controls.person_max_limit.setValue(this.oldOrganizationChartItem.node_data.person_max_limit);
    this.nodeDataForm.controls.person_min_limit.setValue(this.oldOrganizationChartItem.node_data.person_min_limit);
    this.nodeDataForm.controls.is_can_split_node.setValue(this.oldOrganizationChartItem.node_data.is_can_split_node);
    this.nodeDataForm.controls.is_can_maintain_team.setValue(this.oldOrganizationChartItem.node_data.is_can_maintain_team);
    this.nodeDataForm.controls.is_can_inform_team_member.setValue(this.oldOrganizationChartItem.node_data.is_can_inform_team_member);
    this.nodeDataForm.controls.description.setValue(this.oldOrganizationChartItem.node_data.description);
  }

  onPageChanged(pagination: IPagination) {
    this.queryParams.page = pagination.page;
    this.queryParams.limit = pagination.itemsPerPage;
    super.getItems();
    this.getOrganizationTemplatesCount();
  }

  getOrganizationTemplatesCount() {
    this.organizationTemplateService.getOrganizationTemplatesCount(this.queryParams).subscribe(count => {
      this.count = count;
      this.page = this.queryParams.page;
    });
  }
  deleteEnginField() {
    this.myForm.controls.engin_field_key.setValue('');
    this.myForm.controls.engin_field_name.setValue('');
  }

  clickIsCanSplitNodeSwitch() {
    if (!this.nodeDataForm.controls.is_can_split_node.value) {
      return;
    }
    this.nodeDataForm.controls.is_can_maintain_team.setValue(!this.nodeDataForm.controls.is_can_split_node.value);
  }

  clickIsCanMaintainTeamSwitch() {
    if (!this.nodeDataForm.controls.is_can_maintain_team.value) {
      return;
    }
    this.nodeDataForm.controls.is_can_split_node.setValue(!this.nodeDataForm.controls.is_can_maintain_team.value);
  }

}
