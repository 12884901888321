import _ from 'lodash';

import { Reducer, Action } from 'redux';
import {
  SHOW_DIGITAL_DELIVERABLE,
  SET_DIGITAL_DELIVERABLE_DATA,
  CONFIRM_DIGITAL_DELIVERABLE,
  CANCEL_DIGITAL_DELIVERABLE,
  ShowDigitalDeliverableAction,
  SetDigitalDeliverableDataAction,
  ConfirmDigitalDeliverableAction,
  CancelDigitalDeliverableAction
} from '../actions/digital-deliverable.actions';
import { createSelector } from 'reselect';
import { IDigitalDeliverableCallback } from 'src/app/models';

export interface DigitalDeliverableState {
  isShow: boolean;
  editEnable: boolean;
  callback: IDigitalDeliverableCallback;
  data: JSON;
}

const initialState: DigitalDeliverableState = {
  isShow: false,
  editEnable: false,
  callback: undefined,
  data: undefined
};

export const DigitalDeliverableReducer: Reducer<DigitalDeliverableState> =
  (state: DigitalDeliverableState = initialState, action: Action): DigitalDeliverableState => {
    let data;
    switch (action.type) {
      case SHOW_DIGITAL_DELIVERABLE:
        const callback = (action as ShowDigitalDeliverableAction).callback;
        const editEnable = (action as ShowDigitalDeliverableAction).editEnable;
        data = (action as ShowDigitalDeliverableAction).data;
        return Object.assign({}, state, {
          isShow: true,
          callback,
          editEnable,
          data
        });

      case SET_DIGITAL_DELIVERABLE_DATA:
        data = (action as SetDigitalDeliverableDataAction).data;
        return Object.assign({}, state, {
          data
        });

      case CONFIRM_DIGITAL_DELIVERABLE:
        if (state.callback) {
          state.callback(state.data);
        }
        return Object.assign({}, state, {
          isShow: false,
          callback: undefined,
          data: []
        });

      case CANCEL_DIGITAL_DELIVERABLE:
        return Object.assign({}, state, {
          isShow: false,
          callback: undefined,
          data: []
        });

      default:
        return state;
    }
  };

const getState = (state: DigitalDeliverableState): DigitalDeliverableState => state;
export const getDigitalDeliverableShow = createSelector(
  getState,
  (state: DigitalDeliverableState) => state.isShow
);

export const getDigitalDeliverableEditEnable = createSelector(
  getState,
  (state: DigitalDeliverableState) => state.editEnable
);

export const getDigitalDeliverableCallback = createSelector(
  getState,
  (state: DigitalDeliverableState) => state.callback
);

export const getDigitalDeliverableData = createSelector(
  getState,
  (state: DigitalDeliverableState) => state.data
);
