import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import _ from 'lodash';
import { CCheckBase } from 'src/app/classes';
import { PAGE_LINK_LIMIT } from 'src/app/consts';
import { IOrganization, IOrganizationUserParams, IPagination, IUserInfo, IWorktimeUsersParams } from 'src/app/models';
import { UserService } from 'src/app/services/user.service';
import { ProjectRoleService } from '../../permission/services/project-role.service';
import { ECheckOrganizationUsersType } from 'src/app/enums';
import { WorktimeService } from 'src/app/services/worktime.service';
import { ReduxService } from 'src/app/redux/redux.service';


// const CHUNK_SIZE = 11;  // 虚拟滚动一屏显示的行数
// const PATCH_HEIGHT = 0;  // 调整高度
// const TR_HEIGHT = 30; // 表格行高
// const SCROLL_BAR_WIDTH = 17;  // 滚动条宽度
@Component({
  selector: 'app-check-organization-users-list',
  templateUrl: './check-organization-users-list.component.html',
  styleUrls: ['./check-organization-users-list.component.scss']
})
export class CheckOrganizationUsersListComponent extends CCheckBase<IUserInfo, any> implements OnInit, OnChanges {

  // tslint:disable-next-line: variable-name
  private _allCheckedItems: IUserInfo[];
  @Input()
  get allCheckedItems(): IUserInfo[] {
    return this._allCheckedItems;
  }
  set allCheckedItems(value) {
    this._allCheckedItems = value;
    this.allCheckedItemsChange.emit(value);
  }

  @Input() tabValue: number;
  @Input() checkedOrganization: IOrganization;
  @Input() checkedLatelyOrgNumber: string;
  @Input() uncheckedItem: IUserInfo;
  @Input() q: string;

  // viewPortHeight = PATCH_HEIGHT + TR_HEIGHT * CHUNK_SIZE; // 虚拟滚动窗口高度
  // trHeight = TR_HEIGHT; // 虚拟滚动行高
  // scrollbarWidth = SCROLL_BAR_WIDTH;  // 滚动条的宽度
  // chunkSize = CHUNK_SIZE;
  // showVirtualScroll: boolean;

  page = 1;
  limit = 8;
  pageLinkLimit = PAGE_LINK_LIMIT;
  count = 0;
  checkUsersType: ECheckOrganizationUsersType;

  @Output()
  allCheckedItemsChange: EventEmitter<IUserInfo[]> = new EventEmitter();

  lateLyUsers: IUserInfo[];
  constructor(
    private userService: UserService,
    private projectRoleService: ProjectRoleService,
    private reduxService: ReduxService,
    private worktimeService: WorktimeService,
  ) {
    super(userService, 'getLatelyUsers', 'user_id');

  }

  ngOnInit() {
    this.reduxService.subscribeStore(() => this.readState());
    this.readState();
    this.initUserLists();
  }

  readState() {
    this.checkUsersType = this.reduxService.getCheckOrganizationUsersUserType();
  }

  initUserLists() {
    switch (this.tabValue) {
      case 1: // “最近”
        super.updateConstructor(this.userService, 'getLatelyUsers', 'user_id');
        super.getItems(this.allCheckedItems);
        break;

      case 2:  // “组织结构”
        switch (this.checkUsersType) {
          case ECheckOrganizationUsersType.All:
            super.updateConstructor(this.projectRoleService, 'getOrganizationUsers', 'user_id');
            break;
          case ECheckOrganizationUsersType.WorktimeUser:
            super.updateConstructor(this.worktimeService, 'getWorktimeUsers', 'user_id');
            break;
        }
        if (!this.checkedOrganization) {
          this.items = [];
          return;
        }
        this.getOrganizationUsers(this.checkedOrganization.org_number);
        this.getOrganizationUsersCount();
        break;

      case 3:  // “常用组织”
        switch (this.checkUsersType) {
          case ECheckOrganizationUsersType.All:
            super.updateConstructor(this.projectRoleService, 'getOrganizationUsers', 'user_id');
            break;
          case ECheckOrganizationUsersType.WorktimeUser:
            super.updateConstructor(this.worktimeService, 'getWorktimeUsers', 'user_id');
            break;
        }
        this.getOrganizationUsers(this.checkedLatelyOrgNumber);
        this.getOrganizationUsersCount();
        break;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.checkedOrganization && changes.checkedOrganization.currentValue) {
      // 切换“组织架构”的组织
      this.checkedOrganization = changes.checkedOrganization.currentValue;
      if (changes.tabValue) {
        // 未updateConstructor前，不需要getOrganizationUser
        return;
      }
      this.page = 1;
      this.q = ''; // 清空q，防止OnPageChange时，展示查询列表
      this.initUserLists();
    }

    if (changes.checkedLatelyOrgNumber && changes.checkedLatelyOrgNumber.currentValue) {
      // 切换“常用组织”的组织
      this.checkedLatelyOrgNumber = changes.checkedLatelyOrgNumber.currentValue;
      if (changes.tabValue) {
        // 未updateConstructor前，不需要getOrganizationUser
        return;
      }
      this.page = 1;
      this.q = '';
      this.initUserLists();
    }

    if (changes.uncheckedItem && changes.uncheckedItem.currentValue) {
      if (_.find(this.items, { user_id: changes.uncheckedItem.currentValue.user_id })) {
        this.toggleCheckItem(changes.uncheckedItem.currentValue);
      }
    }

    if (changes.q && !_.isUndefined(changes.q.currentValue)) {
      if (changes.q.currentValue) {
        this.q = changes.q.currentValue;
        this.page = 1;
        switch (this.checkUsersType) {
          case ECheckOrganizationUsersType.All:
            super.updateConstructor(this.userService, 'getUsers', 'user_id');
            break;
          case ECheckOrganizationUsersType.WorktimeUser:
            break;
        }
        this.getSearchedUsers();
        this.getSearchedUsersCount();
        return;
      }
      this.initUserLists();
    }
  }

  getOrganizationUsers(checkedOrgNumber: string) {
    if (!checkedOrgNumber) {
      return;
    }
    this.queryParams = {
      page: this.page,
      limit: this.limit,
      org_numbers: [checkedOrgNumber]
    } as IOrganizationUserParams;
    super.getItems(this.allCheckedItems);
  }

  getOrganizationUsersCount() {
    if (!this.queryParams?.org_numbers?.length) {
      return;
    }
    switch (this.checkUsersType) {
      case ECheckOrganizationUsersType.All:
        this.projectRoleService.getOrganizationUsersCount(this.queryParams).subscribe(count => {
          this.count = count;
        });
        break;
      case ECheckOrganizationUsersType.WorktimeUser:
        this.worktimeService.getWorktimeUsersCount(this.queryParams as IWorktimeUsersParams).subscribe(count => {
          this.count = count;
          this.page = this.queryParams.page;
        });
        break;
    }
  }

  getSearchedUsers() {
    this.queryParams = {
      page: this.page,
      limit: this.limit,
      q: this.q
    };
    super.getItems(this.allCheckedItems);
  }

  getSearchedUsersCount() {
    switch (this.checkUsersType) {
      case ECheckOrganizationUsersType.All:
        this.userService.getUsersCount(this.queryParams).subscribe(count => {
          this.count = count;
        });
        break;
      case ECheckOrganizationUsersType.WorktimeUser:
        this.worktimeService.getWorktimeUsersCount(this.queryParams as IWorktimeUsersParams).subscribe(count => {
          this.count = count;
          this.page = this.queryParams.page;
        });
        break;
    }
  }

  onPageChanged(pagination: IPagination) {
    this.page = pagination.page;
    if (this.q) {
      // 通过q来区分当前是否为查询后的列表；若存在q，当前为查询列表
      this.getSearchedUsers();
      return;
    }
    switch (this.tabValue) {
      case 2:  // “组织结构”
        if (!this.checkedOrganization) {
          this.items = [];
          return;
        }
        this.getOrganizationUsers(this.checkedOrganization.org_number);
        break;

      case 3:  // “常用组织”
        this.getOrganizationUsers(this.checkedLatelyOrgNumber);
        break;
    }

  }

  toggleCheckItem(item: IUserInfo) {
    super.toggleCheckItem(item);
    if (this.checkForm.controls[item.user_id + ''].value) {
      this.allCheckedItems = _.concat(this.allCheckedItems, item);
      return;
    }
    this.allCheckedItems = _.reject(this.allCheckedItems, item);
  }

  toggleCheckAll() {
    super.toggleCheckAll();
    if (this.isCheckedAll) {
      this.allCheckedItems = _.uniqBy(_.concat(this.allCheckedItems, this.checkedItems), 'user_id');
      return;
    }
    this.allCheckedItems = _.differenceBy(this.allCheckedItems, this.items, 'user_id');
  }

}
