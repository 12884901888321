import { Action, ActionCreator } from 'redux';
import { IProject, ICheckProjectsCallback } from 'src/app/models';
import { EAuditType } from 'src/app/enums';

// #region 显示
export interface ShowCheckProjectsAction extends Action {
  callback: ICheckProjectsCallback;
  auditType: EAuditType;
  isMultiple: boolean;
  tooltip: string;
  confirmMessage:string
  oldProjects: IProject[];
}

export const SHOW_CHECK_PROJECTS = 'SHOW_CHECK_PROJECTS';
export const showCheckProjects: ActionCreator<ShowCheckProjectsAction> = (
  callback: ICheckProjectsCallback,
  auditType: EAuditType,
  isMultiple?: boolean,
  tooltip?: string,
  confirmMessage?:string,
  oldProjects?: IProject[]
) => ({
  type: SHOW_CHECK_PROJECTS,
  callback,
  auditType,
  isMultiple,
  tooltip,
  confirmMessage,
  oldProjects
});
// #endregion

// #region 确定
// tslint:disable-next-line: no-empty-interface
export interface ConfirmCheckProjectsAction extends Action {
}

export const CONFIRM_CHECK_PROJECTS = 'CONFIRM_CHECK_PROJECTS';
export const confirmCheckProjects: ActionCreator<ConfirmCheckProjectsAction> = () => ({
  type: CONFIRM_CHECK_PROJECTS
});
// #endregion

// #region 取消
// tslint:disable-next-line: no-empty-interface
export interface CancelCheckProjectsAction extends Action {
}

export const CANCEL_CHECK_PROJECTS = 'CANCEL_CHECK_PROJECTS';
export const cancelCheckProjects: ActionCreator<CancelCheckProjectsAction> = () => ({
  type: CANCEL_CHECK_PROJECTS
});
// #endregion

// #region 设置新选中的项目列表
export interface SetCheckProjectsNewProjectsAction extends Action {
  newProjects: IProject[];
}

export const SET_CHECK_PROJECTS_NEW_PROJECTS = 'SET_CHECK_PROJECTS_NEW_PROJECTS';
export const setCheckProjectsNewProjects: ActionCreator<SetCheckProjectsNewProjectsAction> = (newProjects: IProject[]) => ({
  type: SET_CHECK_PROJECTS_NEW_PROJECTS,
  newProjects
});

// #endregion
