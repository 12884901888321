import _ from 'lodash';

import { Reducer, Action } from 'redux';
import {
  SHOW_DIALOG,
  CONFIRM_DIALOG,
  CANCEL_DIALOG,
  ShowDialogAction,
  ConfirmDialogAction,
  CancelDialogAction
} from '../actions/dialog.actions';
import { createSelector } from 'reselect';
import { IDialogCallback } from 'src/app/models';

export interface DialogState {
  isShow: boolean;
  title: string;
  content: string[];
  confirmCallback: IDialogCallback;
  cancelCallback: IDialogCallback;
  confirmCallbackParams: any;
  cancelCallbackParams: any;
  tip: string;
}

const initialState: DialogState = {
  isShow: false,
  title: '',
  content: [],
  confirmCallback: undefined,
  cancelCallback: undefined,
  confirmCallbackParams: undefined,
  cancelCallbackParams: undefined,
  tip: ''
};

export const DialogReducer: Reducer<DialogState> =
  (state: DialogState = initialState, action: Action): DialogState => {
    switch (action.type) {
      case SHOW_DIALOG:
        const title = (action as ShowDialogAction).title;
        const content = (action as ShowDialogAction).content;
        const confirmCallback = (action as ShowDialogAction).confirmCallback;
        const cancelCallback = (action as ShowDialogAction).cancelCallback;
        const confirmCallbackParams = (action as ShowDialogAction).confirmCallbackParams;
        const cancelCallbackParams = (action as ShowDialogAction).cancelCallbackParams;
        const tip = (action as ShowDialogAction).tip;
        return Object.assign({}, state, {
          isShow: true,
          title,
          content,
          confirmCallback,
          cancelCallback,
          confirmCallbackParams,
          cancelCallbackParams,
          tip
        });

      case CONFIRM_DIALOG:
        if (state.confirmCallback) {
          state.confirmCallback(state.confirmCallbackParams);
        }
        return Object.assign({}, state, {
          isShow: false,
          title: '',
          content: [],
          confirmCallback: undefined,
          cancelCallback: undefined,
          tip: ''
        });

      case CANCEL_DIALOG:
        if (state.cancelCallback) {
          state.cancelCallback(state.cancelCallbackParams);
        }
        return Object.assign({}, state, {
          isShow: false,
          title: '',
          content: [],
          confirmCallback: undefined,
          cancelCallback: undefined,
          tip: ''
        });

      default:
        return state;
    }
  };

const getState = (state: DialogState): DialogState => state;
export const getDialog = createSelector(
  getState,
  (state: DialogState) => state
);

export const getDialogShow = createSelector(
  getState,
  (state: DialogState) => state.isShow
);

export const getDialogConfirmCallback = createSelector(
  getState,
  (state: DialogState) => state.confirmCallback
);

export const getDialogCancelCallback = createSelector(
  getState,
  (state: DialogState) => state.cancelCallback
);
