// 抄送组织
import { Action, ActionCreator } from 'redux';
import { ICcOrganization } from 'src/app/models/IAuditInfo';

// #region set
export interface SetCcOrgsAction extends Action {
    organizations: ICcOrganization[];
}
export const SET_CC_ORGANIZATIONS = 'SET_CC_ORGANIZATIONS';
export const setCcOrg: ActionCreator<SetCcOrgsAction> = (organizations: ICcOrganization[]) => ({
    type: SET_CC_ORGANIZATIONS,
    organizations
});
// #endregion

// #region add
export interface AddCcOrgAction extends Action {
    organization: ICcOrganization;
}
export const ADD_CC_ORGANIZATION  = 'ADD_CC_ORGANIZATION';
export const addCcOrg: ActionCreator<AddCcOrgAction> = (organization: ICcOrganization) => ({
    type: ADD_CC_ORGANIZATION,
    organization
});
// #endregion

// #region delete
export interface DeleteCcOrgAction extends Action {
    organization: ICcOrganization;
}
export const DELETE_CC_ORGANIZATION  = 'DELETE_CC_ORGANIZATION';
export const deleteCcOrg: ActionCreator<DeleteCcOrgAction> = (organization: ICcOrganization) => ({
    type: DELETE_CC_ORGANIZATION,
    organization
});
// #endregion

// #region clear
// tslint:disable-next-line: no-empty-interface
export interface ClearCcOrgsAction extends Action {
}
export const CLEAR_CC_ORGANIZATIONS  = 'CLEAR_CC_ORGANIZATIONS';
export const clearCcOrgs: ActionCreator<ClearCcOrgsAction> = () => ({
    type: CLEAR_CC_ORGANIZATIONS
  });
// #endregion
