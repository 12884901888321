import { Action, ActionCreator } from 'redux';

// #region 显示
export interface ShowDeliverableAction extends Action {
  data: JSON;
}

export const SHOW_DELIVERABLE = 'SHOW_DELIVERABLE';
export const showDeliverable: ActionCreator<ShowDeliverableAction> = (
  data?: JSON
) => ({
  type: SHOW_DELIVERABLE,
  data
});
// #endregion

// #region 取消
// tslint:disable-next-line: no-empty-interface
export interface CancelDeliverableAction extends Action {
}

export const CANCEL_DELIVERABLE = 'CANCEL_DELIVERABLE';
export const cancelDeliverable: ActionCreator<CancelDeliverableAction> = () => ({
  type: CANCEL_DELIVERABLE
});
// #endregion

// #region 设置交付物的数据
export interface SetDeliverableDataAction extends Action {
  data: JSON;
}

export const SET_DELIVERABLE_DATA = 'SET_DELIVERABLE_DATA';
export const setDeliverableData: ActionCreator<SetDeliverableDataAction> = (data: JSON) => ({
  type: SET_DELIVERABLE_DATA,
  data
});

// #endregion
