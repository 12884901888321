/// <summary>
/// Config 服务的类型
/// </summary>
export enum EConfigType {
  /// <summary>
  /// 事业部
  /// </summary>
  Division = 10,

  /// <summary>
  /// 项目群
  /// </summary>
  ProjectGroup = 20,

  /// <summary>
  /// 项目级别
  /// </summary>
  ProjectLevel = 30,

  /// <summary>
  /// 项目大分类
  /// </summary>
  ProjectCatalog = 110,

  /// <summary>
  /// 项目细分类
  /// </summary>
  ProjectCategory = 120,

  /// <summary>
  /// 平台
  /// </summary>
  Platform = 130,

  /// <summary>
  /// 平台分类
  /// </summary>
  PlatformClass = 131,

  /// <summary>
  /// 新技术/规划/模块项目分类
  /// </summary>
  NewTechPlanModuleCategory = 140,
  /// <summary>
  /// 新技术方向
  /// </summary>
  NewTechDirection = 141,

  /// <summary>
  /// 新技术类别
  /// </summary>
  NewTechClass = 142,

  /// <summary>
  /// 新技术应用产品线
  /// </summary>
  NewTechLine = 143,

  /// <summary>
  /// 新技术项目来源
  /// </summary>
  NewTechSource = 144,

  /// <summary>
  /// 项目参数文件
  /// </summary>
  ProjectParamFile = 150,

  /// <summary>
  /// 车型类别
  /// </summary>
  VehicleModelType = 160,

  /// <summary>
  /// 管理主体
  /// </summary>
  ManagementHost = 170,

  /// <summary>
  /// 开发主体
  /// </summary>
  DevelopmentHost = 180,

  /// <summary>
  /// 需求主体
  /// </summary>
  DemandHost = 190,

  /// <summary>
  /// 品牌
  /// </summary>
  Brand = 200,

  /// <summary>
  /// 生产工厂
  /// </summary>
  ProductFactory = 210,

  /// <summary>
  /// 产品线
  /// </summary>
  ProductLine = 220,

  /// <summary>
  /// 业务属性
  /// </summary>
  BusinessAttribute = 230,

  /// <summary>
  /// 项目类别
  /// </summary>
  ProjectType = 240,

  /// <summary>
  /// 高新领域
  /// </summary>
  HighTechDomain = 250,

  /// <summary>
  /// 高新重点项目
  /// </summary>
  HighTechKeyPointProject = 260,

  /// <summary>
  /// 基础/扩展项目
  /// </summary>
  Scope = 270,

  /// <summary>
  /// 排放标准
  /// </summary>
  EmissionStandard = 280,

  /// <summary>
  /// 通用车/专用车
  /// </summary>
  GeneralOrSpecial = 290,

  /// <summary>
  /// 所属年度
  /// </summary>
  BelongYear = 300,

  /// <summary>
  /// 业务
  /// </summary>
  Business = 310,
  /// <summary>
  /// 轻量化
  /// </summary>
  LightWeight = 320,

  /// <summary>
  /// NG
  /// </summary>
  NG = 330,

  /// <summary>
  /// 负责单位
  /// </summary>
  Leader = 340,

  /// <summary>
  /// 新能源
  /// </summary>
  NewEnergy = 350,

  /// <summary>
  /// 模块系统
  /// </summary>
  ModuleSystem = 360,

  /// <summary>
  /// 模块分类
  /// </summary>
  ModuleClass = 361,

  /// <summary>
  /// 创建单位
  /// </summary>
  CreateCompany = 370,


  /// <summary>
  /// 中心单位
  /// </summary>
  Center = 380,

  /// <summary>
  /// 项目阶段
  /// </summary>
  ProjectStage = 390,

  /// <summary>
  /// 模块级别
  /// </summary>
  ModuleRank = 391,

  /// <summary>
  /// 集团管控
  /// </summary>
  GroupControl = 400,


  /// <summary>
  /// 人力成本职级
  /// </summary>
  UserCostLevel = 450,

  /// <summary>
  /// 人力成本所有子职级
  /// </summary>
  UserCostChildLevel = 460,

  // #region 需求配置项

  /// <summary>
  /// 需求业务类型
  /// </summary>
  RequirementField = 1000,

  /// <summary>
  /// 需求区域
  /// </summary>
  RequirementRegion = 1010,

  /// <summary>
  /// 需求类型
  /// </summary>
  RequirementType = 1020,

  /// <summary>
  /// 需求层级
  /// </summary>
  RequirementLevel = 1030,

  /// <summary>
  /// 需求团队
  /// </summary>
  RequirementTeam = 1040,

  /// <summary>
  /// 团队角色
  /// </summary>
  RequirementTeamRole = 1050,

  // #region NTP 技术点子

  /// <summary>
  /// 成果所属分类
  /// </summary>
  NTP_AchievementType = 1100,

  /// <summary>
  /// 成果定级建议
  /// </summary>
  NTP_AchievementLevelSuggestion = 1110,

  /// <summary>
  /// 迁移目标规划
  /// </summary>
  NTP_MigrationTargetPlan = 1120,

  /// <summary>
  /// 成熟度分类
  /// </summary>
  NTP_MaturityType = 1130,

  // #endregion
}
