import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import _ from 'lodash';

import { IAlert, IProjectFilterItem, IHttpError } from 'src/app/models';
import { EHttpStatusCode, ELightType, EProjectRelationType, EProjectStatus, EWorktimeDayState } from 'src/app/enums';
import { ReduxService } from 'src/app/redux/redux.service';
import { SubscriptionService } from './subscription.service';
import { AlertService } from './alert.service';
import { SystemService } from './system.service';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  private redirectUrl = '';  // 重定向地址

  constructor(
    private subscriptionService: SubscriptionService,
    private router: Router,
    private reduxService: ReduxService,
    private alertService: AlertService,
    private systemService: SystemService,
    private http: HttpClient
  ) {
    this.subscriptionService.subscribeAlert().subscribe((alert) => {
      this.alertService.addAlert(alert);
    });
  }

  // 刷新页面
  reload(activatedRoute: ActivatedRoute) {
    // 存储当前的路由reuse策略
    const routerStrategy = {
      shouldReuseRoute: this.router.routeReuseStrategy.shouldReuseRoute,  // 因为shouldReuseRoute是个函数，即引用类型，因此使用对象的属性来做深拷贝，防止被修改
      onSameUrlNavigation: this.router.onSameUrlNavigation
    };

    // 修改路由reuse策略
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';

    // 刷新页面
    this.router.navigate(['./'], { relativeTo: activatedRoute, queryParamsHandling: 'preserve' }).then(() => {
      // 恢复原路由reuse策略
      this.router.routeReuseStrategy.shouldReuseRoute = routerStrategy.shouldReuseRoute;
      this.router.onSameUrlNavigation = routerStrategy.onSameUrlNavigation;
    });
  }

  // 移除空属性（undefined、null、 '' 等，不包括0）
  omitEmptyParams<T>(params: T): T {
    // 搜索时移除前后空格
    _.forEach(params as { [key: string]: any; }, (value, key) => {
      if ('q' === key && 'string' === typeof value) {
        params[key] = value.trim();
      }
    });
    return _.omitBy(params as { [key: string]: any; }, value => {
      return !(value || 0 === value);
    }) as T;
  }

  // 移除空数组属性（[]）
  omitEmptyArrayParams<T>(params: T): T {
    return _.omitBy(params as { [key: string]: any; }, value => {
      return _.isArray(value) && !value.length;
    }) as T;
  }

  // 状态灯
  getProjectRelationTypesSync(): IProjectFilterItem[] {
    return [{
      key: EProjectRelationType.Responsible,
      name: '我负责的'
    }, {
      key: EProjectRelationType.Admin,
      name: '我管理的'
    }];
  }

  // 状态灯
  getLightsSync(): IProjectFilterItem[] {
    return [{
      key: ELightType.Red,
      name: '红灯'
    }, {
      key: ELightType.Yellow,
      name: '黄灯'
    }, {
      key: ELightType.Green,
      name: '绿灯'
      // }, {
      //   key: ELightType.GreenWarning,
      //   name: '绿灯!'
    }, {
      key: ELightType.None,
      name: '无灯'
    }];
  }

  // 项目状态
  getProjectStatusesSync(): IProjectFilterItem[] {
    return [{
      key: EProjectStatus.Init,
      name: '未启动'
    }, {
      key: EProjectStatus.Paused,
      name: '已暂停'
    }, {
      key: EProjectStatus.Stopped,
      name: '已中止'
    }, {
      key: EProjectStatus.Running,
      name: '开展中'
    }, {
      key: EProjectStatus.Done,
      name: '已完结'
    }];
  }

  // 工时填报日状态
  getWorktimeDayStatesSync(): IProjectFilterItem[] {
    return [{
      key: EWorktimeDayState.Normal,
      name: '正常'
    }, {
      key: EWorktimeDayState.AdditionalRecording,
      name: '补录'
    }, {
      key: EWorktimeDayState.ExceptionResend,
      name: '异常处理补发'
    }, {
      key: EWorktimeDayState.Leave,
      name: '请假'
    }];
  }

  // 排序
  // propertyValues 有值，则按值进行排序；无值：按[propertyName]默认排序
  sortItemsSync<T>(items: T[], propertyName: string, propertyValues?: any[]): T[] {
    // 按属性[propertyName]默认排序
    if (!propertyValues) {
      return _.sortBy(items, propertyName);
    }
    // 按给定属性值propertyValues排序
    const groupByProperty = _.groupBy(items, propertyName);
    let sortedItems = [];
    _.forEach(propertyValues, value => {
      if (groupByProperty[value]) {
        sortedItems = _.concat(sortedItems, groupByProperty[value]);
      }
    });
    return sortedItems;
  }

  handleError<T>(operation = 'operation', result?: T) {
    return (error: HttpErrorResponse): Observable<T> => {
      if (!error) {
        return of(result as T);
      }
      const httpError: IHttpError = error.error;
      if (!(httpError && httpError.message)) {
        return of(result as T);
      }

      const content = [];
      if (httpError.details) {
        // tslint:disable-next-line: variable-name
        _.forEach(httpError.details, value => {
          value = _.uniq(value);
          if (_.includes(value, httpError.message)) {  // 移除和message相同的内容
            return;
          }
          content.push(value.join('；'));
        });
      }

      const alert: IAlert = {
        type: 'danger',
        title: httpError.message,
        content
      };
      this.subscriptionService.publishAlert(alert);

      switch (error.status) {
        case 401: // Unauthorized
          const redirectUrl = this.getRedirectUrl() || window.location.href;
          const ssoLoginUrl = this.systemService.getSsoLoginUrlSync();
          // 单点等陆
          window.location.href = `${ssoLoginUrl}?return_url=${encodeURIComponent(redirectUrl)}`;
          throw new Error('Unauthorized'); // 页面跳转为异步，在此抛出异常，阻塞后面代码执行
          break;
        default:
      }

      return of(result as T);
    };
  }

  handleErrorReturnHttpError(operation = 'operation', result?: IHttpError) {
    return (error: HttpErrorResponse): Observable<IHttpError> => {
      if (!error) {
        return of(result);
      }
      const httpError: IHttpError = error.error;
      if (!(httpError && httpError.message)) {
        return of(result);
      }

      // 错误类型为“内容已被他人修改”，需要返回 httpError 通过 dialog 确认，其他情况都直接 alert 提示
      if (EHttpStatusCode.Conflict !== httpError.code && EHttpStatusCode.Redirect !== httpError.code) {
        // 错误类型为“重定向”， 不需要alert提示，直接重定向到url
        const content = [];
        if (httpError.details) {
          // tslint:disable-next-line: variable-name
          _.forEach(httpError.details, value => {
            value = _.uniq(value);
            if (_.includes(value, httpError.message)) {  // 移除和message相同的内容
              return;
            }
            content.push(value.join('；'));
          });
        }
        const alert: IAlert = {
          type: 'danger',
          title: httpError.message,
          content
        };
        this.subscriptionService.publishAlert(alert);
      }

      switch (error.status) {
        case 401: // Unauthorized
          const redirectUrl = this.getRedirectUrl() || window.location.href;
          const ssoLoginUrl = this.systemService.getSsoLoginUrlSync();
          // 单点等陆
          window.location.href = `${ssoLoginUrl}?return_url=${encodeURIComponent(redirectUrl)}`;
          throw new Error('Unauthorized'); // 页面跳转为异步，在此抛出异常，阻塞后面代码执行
          break;
        default:
      }

      // 返回http状态码
      return of(httpError);
    };
  }

  setRedirectUrl(url: string) {
    this.redirectUrl = url;
  }

  getRedirectUrl(): string {
    return this.redirectUrl;
  }

  // 是否IE浏览器
  getIsIeSync(): boolean {
    const userAgent = window.navigator.userAgent;
    return -1 !== userAgent.indexOf('NET') && -1 !== userAgent.indexOf('rv');
  }

  // 是否是Windows系统
  getIsWin(): boolean {
    const userAgent = window.navigator.platform === 'Win32';
    return userAgent;
  }
  // 生成新id
  generateNewId(): Observable<string> {
    const options = { // 防止string型的response被直接转换成number，丢失精度
      responseType: 'text' as 'json'
    };
    return this.http.get<string>('/api/audits/newid', options).pipe(
      catchError(this.handleError('get new id', ''))
    );
  }

  // 生成新ids
  generateNewIds(count?: number): Observable<string[]> {
    const params = { count };
    return this.http.get<string[]>('/api/audits/newids', { params: params as unknown as HttpParams }).pipe(
      catchError(this.handleError('get new ids', []))
    );
  }

  verifyTitle(title: string, titleLabel = '下发主题'): boolean {
    if (!title) {
      const alert: IAlert = {
        type: 'danger',
        title: titleLabel,
        content: [`${titleLabel}不能为空`]
      };
      this.subscriptionService.publishAlert(alert);
      return false;
    }
    return true;
  }

  verifyUpdate(title: string, reason: string) {
    return this.verifyChangeTitle(title) && this.verifyChangeReason(reason);
  }

  verifyChangeTitle(title: string): boolean {
    if (!title) {
      const alert: IAlert = {
        type: 'danger',
        title: '变更主题',
        content: ['变更主题不能为空']
      };
      this.subscriptionService.publishAlert(alert);
      return false;
    }
    return true;
  }

  verifyChangeReason(reason: string): boolean {
    if (!reason) {
      const alert: IAlert = {
        type: 'danger',
        title: '变更原因',
        content: ['变更原因不能为空']
      };
      this.subscriptionService.publishAlert(alert);
      return false;
    }
    return true;
  }
  // 阿拉伯数字转中文，最多转到99
  number2zh(
    num: number,
    isPlusOne = true  // 转换为汉字是否默认加一
  ): string {
    const zhNumber = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九', '十', '百', '千', '万', '亿'];
    const zhLength = String(num).length;
    if (isPlusOne) {
      if (zhLength === 1) {
        return zhNumber[num + 1];
      } else if (zhLength === 2) {
        if (zhNumber[Number(String(num).charAt(1)) + 1] !== '十') {
          return zhNumber[String(num).charAt(0)] + '十'
            + zhNumber[Number(String(num).charAt(1)) + 1].replace('零', '');
        } else {
          return zhNumber[Number(String(num).charAt(0)) + 1] + '十';
        }
      }
    } else {
      if (zhLength === 1) {
        return zhNumber[num];
      } else if (zhLength === 2) {
        if (zhNumber[Number(String(num).charAt(1))] !== '零') {
          return zhNumber[String(num).charAt(0)] === '一'
            ? '十' + zhNumber[Number(String(num).charAt(1))]
            : zhNumber[String(num).charAt(0)] + '十' + zhNumber[Number(String(num).charAt(1))];
        } else {
          return zhNumber[String(num).charAt(0)] === '一'
            ? '十'
            : zhNumber[Number(String(num).charAt(0))] + '十';
        }
      }
    }
  }

}
