import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-help-document-rems',
  templateUrl: './help-document-rems.component.html',
  styleUrls: ['./help-document-rems.component.scss']
})
export class HelpDocumentRemsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
