<div class="collapse-header">
  <div class="collapse-title" (click)="toggleExpand();">
    <i class="fa mr-2" [ngClass]="{'fa-minus-square-o': isExpand, 'fa-plus-square-o':!isExpand}"></i>
    {{name}}<a *ngIf="tagPlanReceiptData?.receipt_url"
      [href]="tagPlanReceiptData?.receipt_url">（{{tagPlanReceiptData?.receipt_system}}流程）</a>
    <span class="description">{{description}}</span>
  </div>
  <div class="collapse-operations">
    <ng-content select=".collapse-operations"></ng-content>
  </div>
</div>
<!-- <div class="collapse-body" [@openClose]="isExpand ? 'open' : 'closed'">
  <ng-content></ng-content>
</div> -->

<div class="collapse-body" [ngClass]="{hidden: !isExpand}">
  <ng-content></ng-content>
</div>
