import _ from 'lodash';

import { Reducer, Action } from 'redux';
import {
  SHOW_CHECK_USERS,
  SET_CHECK_USERS_NEW_USERS,
  CONFIRM_CHECK_USERS,
  CANCEL_CHECK_USERS,
  ShowCheckUsersAction,
  SetCheckUsersNewUsersAction,
  ConfirmCheckUsersAction,
  CancelCheckUsersAction
} from '../actions/check-users.actions';
import { createSelector } from 'reselect';
import { IUserInfo, ICheckUsersCallback } from 'src/app/models';
import { ECheckUsersType } from 'src/app/enums';

export interface CheckUsersState {
  isShow: boolean;
  isMultiple: boolean;
  callback: ICheckUsersCallback;
  oldUsers: IUserInfo[];
  newUsers: IUserInfo[];
  checkUsersType: ECheckUsersType;
  params: string | string[];
}

const initialState: CheckUsersState = {
  isShow: false,
  isMultiple: false,
  callback: undefined,
  oldUsers: [],
  newUsers: [],
  checkUsersType: ECheckUsersType.All,
  params: undefined
};

export const CheckUsersReducer: Reducer<CheckUsersState> =
  (state: CheckUsersState = initialState, action: Action): CheckUsersState => {
    switch (action.type) {
      case SHOW_CHECK_USERS:
        const callback = (action as ShowCheckUsersAction).callback;
        const isMultiple = (action as ShowCheckUsersAction).isMultiple;
        const oldUsers = (action as ShowCheckUsersAction).oldUsers || [];
        const checkUsersType = (action as ShowCheckUsersAction).checkUsersType || ECheckUsersType.All;
        const params = (action as ShowCheckUsersAction).params;
        return Object.assign({}, state, {
          isShow: true,
          callback,
          isMultiple,
          oldUsers,
          checkUsersType,
          params
        });

      case SET_CHECK_USERS_NEW_USERS:
        const newUsers = (action as SetCheckUsersNewUsersAction).newUsers;
        return Object.assign({}, state, {
          newUsers
        });

      case CONFIRM_CHECK_USERS:
        if (state.callback) {
          state.callback(state.newUsers);
        }
        return Object.assign({}, state, {
          isShow: false,
          callback: undefined,
          oldUsers: undefined,
          newUsers: [],
          checkUsersType: ECheckUsersType.All,
          params: undefined
        });

      case CANCEL_CHECK_USERS:
        return Object.assign({}, state, {
          isShow: false,
          callback: undefined,
          oldUsers: undefined,
          newUsers: [],
          checkUsersType: ECheckUsersType.All,
          params: undefined
        });

      default:
        return state;
    }
  };

const getState = (state: CheckUsersState): CheckUsersState => state;
export const getCheckUsersShow = createSelector(
  getState,
  (state: CheckUsersState) => state.isShow
);

export const getCheckUsersMultiple = createSelector(
  getState,
  (state: CheckUsersState) => state.isMultiple
);

export const getCheckUsersCallback = createSelector(
  getState,
  (state: CheckUsersState) => state.callback
);

export const getCheckUsersNewUsers = createSelector(
  getState,
  (state: CheckUsersState) => state.newUsers
);

export const getCheckUsersOldUsers = createSelector(
  getState,
  (state: CheckUsersState) => state.oldUsers
);

export const getCheckUsersCheckUsersType = createSelector(
  getState,
  (state: CheckUsersState) => state.checkUsersType
);

export const getCheckUsersParams = createSelector(
  getState,
  (state: CheckUsersState) => state.params
);
