<div class="btn-group" dropdown>
  <a dropdownToggle class="btn dropdown-toggle">
    <i class="fa fa-user mr-1"></i>{{ currentUser && currentUser.name
    }}<span class="caret"></span>
  </a>
  <ul *dropdownMenu class="dropdown-menu dropdown-menu-right">
    <li role="menuitem">
      <a class="dropdown-item" (click)="logout()">
        <i class="fa fa-sign-out mr-1"></i>退出
      </a>
    </li>
  </ul>
</div>
