// *工作流节点类型
export enum EWorkflowStepType {
    /// <summary>
    /// 中间节点
    /// </summary>
    Normal = 0,

    /// <summary>
    /// 开始节点
    /// </summary>
    Start = 1,

    /// <summary>
    /// 结束节点
    /// </summary>
    End = 2
}
