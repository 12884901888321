import { Action, ActionCreator } from 'redux';
import { ECheckOrganizationUsersType } from 'src/app/enums';
import { ICheckOrganizationUsersCallback, IUserInfo } from 'src/app/models';

// #region 显示
export interface ShowCheckOrganizationUsersAction extends Action {
    callback: ICheckOrganizationUsersCallback;
    isMultiple?: boolean;
    checkOrganizationUsersType?: ECheckOrganizationUsersType;
}

export const SHOW_CHECK_ORGANIZATION_USERS = 'SHOW_CHECK_ORGANIZATION_USERS';
export const showCheckOrganizationUsers: ActionCreator<ShowCheckOrganizationUsersAction> = (
    callback: ICheckOrganizationUsersCallback,
    isMultiple?: boolean,
    checkOrganizationUsersType?: ECheckOrganizationUsersType
) => ({
    type: SHOW_CHECK_ORGANIZATION_USERS,
    callback,
    isMultiple,
    checkOrganizationUsersType
});
// #endregion

// #region 确定
// tslint:disable-next-line: no-empty-interface
export interface ConfirmCheckOrganizationUsersAction extends Action {
}

export const CONFIRM_CHECK_ORGANIZATION_USERS = 'CONFIRM_CHECK_ORGANIZATION_USERS';
export const confirmCheckOrganizationUsers: ActionCreator<ConfirmCheckOrganizationUsersAction> = () => ({
    type: CONFIRM_CHECK_ORGANIZATION_USERS
});
// #endregion

// #region 取消
// tslint:disable-next-line: no-empty-interface
export interface CancelCheckOrganizationUsersAction extends Action {
}

export const CANCEL_CHECK_ORGANIZATION_USERS = 'CANCEL_CHECK_ORGANIZATION_USERS';
export const cancelCheckOrganizationUsers: ActionCreator<CancelCheckOrganizationUsersAction> = () => ({
    type: CANCEL_CHECK_ORGANIZATION_USERS
});
// #endregion

// #region 设置新选中的用户列表
export interface SetCheckOrganizationUsersNewUsersAction extends Action {
    newUsers: IUserInfo[];
}

export const SET_CHECK_ORGANIZATION_USERS_NEW_USERS = 'SET_CHECK_ORGANIZATION_USERS_NEW_USERS';
export const setCheckOrganizationUsersNewUsers: ActionCreator<SetCheckOrganizationUsersNewUsersAction> = (newUsers: IUserInfo[]) => ({
    type: SET_CHECK_ORGANIZATION_USERS_NEW_USERS,
    newUsers
});

  // #endregion

