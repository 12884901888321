<ng-template #addOrganizationItem>

  <div class="modal-header">
    <h5 class="modal-title">{{title}}</h5>
    <button type="button" class="close" (click)="hide()">
      <i class="fa fa-times"></i>
    </button>
  </div>

  <div class="modal-body">
    <app-tabs [items]='tabs' [default]='defaultTab' (itemChange)="changeTab($event)"
      *ngIf="addWays.length > 1 && tabs.length > 1">
    </app-tabs>
    <div class="content" [ngClass]="{hidden: 1!==tabValue}">
      <form [formGroup]="radioForm">
        <table class="table-sm table-bordered table-hover table-fixed text-center align-middle">
          <thead>
            <tr>
              <th style="width: 40px;"></th>
              <th style="width: 60px;">序号</th>
              <th>组织模板名称</th>
              <th style="width: 110px;">创建人</th>
              <th style="width: 160px;">创建时间</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of items; let i =index" (click)="checkItem(item)">
              <td>
                <input type="radio" [id]="item.id" [value]="item.id" [formControl]="radio">
              </td>
              <td>{{(page - 1) * limit + i + 1}}</td>
              <td class="ellipsis text-left" [title]="item.name">{{item.name}}</td>
              <td>{{item.created_by_name}}</td>
              <td>{{item.created_on | dateFormatShort}}</td>
            </tr>
          </tbody>
        </table>
      </form>
      <div class="pagination-box">
        <app-pagination
          [pageLinkLimit]="pageLinkLimit"
          [page]="page"
          [limit]="limit"
          [count]="count"
          (pageChanged)="onPageChanged($event)"
        ></app-pagination>
      </div>
    </div>
    <div class="content" [ngClass]="{hidden: 2!==tabValue}">
      <form [formGroup]="myForm" class="ez-page-body">
        <table class="table-sm table-bordered table-fixed text-center align-middle">
          <tbody>
            <tr>
              <td class="text-right" style="width:260px;">
                <i class="fa fa-asterisk text-danger mr-1"></i>
                <span class="mr-2">角色名称</span>
              </td>
              <td>
                <input type="text" class="form-control" formControlName="name" trim="blur">
              </td>
            </tr>
            <tr>
              <td class="text-right">
                <span class="mr-2">所属业务</span>
              </td>
              <td class="engin-field">
                <input type="text" class="form-control " [value]="myForm.controls.engin_field_name.value" readonly
                  (click)="toCheckEnginField()">
                <i *ngIf="myForm.controls.engin_field_name.value" class="fa fa-times-circle delete"
                  (click)="deleteEnginField()"></i>
              </td>
            </tr>
            <!-- <tr>
                            <td>
                                <i class="fa fa-asterisk text-danger mr-1"></i>是否可移除
                            </td>
                            <td>
                                <app-switch type="success" editEnable
                                    [formControl]="nodeDataForm.controls['disable_delete']">
                                </app-switch>
                            </td>
                        </tr> -->
            <tr>
              <td class="text-right">
                <i class="fa fa-asterisk text-danger mr-1"></i>
                <span class="mr-2">角色最少人员数</span>
              </td>
              <td>
                <input type="text" class="form-control" [formControl]="nodeDataForm.controls['person_min_limit']"
                  oninput="value=value.replace(/^(0+)|[^\d]+/g,'')" placeholder="请输入大于0的整数">
              </td>
            </tr>
            <tr>
              <td class="text-right">
                <i class="fa fa-asterisk text-danger mr-1"></i>
                <span class="mr-2">角色最多人员数</span>
              </td>
              <td>
                <input type="text" class="form-control" [formControl]="nodeDataForm.controls['person_max_limit']"
                  oninput="value=value.replace(/^(0+)|[^\d]+/g,'')" placeholder="请输入大于0的整数">
              </td>
            </tr>
            <tr>
              <td class="text-right switch">
                <i class="fa fa-asterisk text-danger mr-1"></i>
                <span class="mr-2">
                  组织节点是否可向下拆解
                </span>
              </td>
              <td>
                <app-switch type="success" class="d-flex justify-content-start" editEnable
                  (click)="clickIsCanSplitNodeSwitch()" [formControl]="nodeDataForm.controls['is_can_split_node']">
                </app-switch>
              </td>
            </tr>
            <tr>
              <td class="text-right switch">
                <i class="fa fa-asterisk text-danger mr-1"></i>
                <span class="mr-2">PMT经理是否可维护工程师</span>
              </td>
              <td>
                <app-switch class="d-flex justify-content-start" type="success" editEnable
                  (click)="clickIsCanMaintainTeamSwitch()"
                  [formControl]="nodeDataForm.controls['is_can_maintain_team']">
                </app-switch>
              </td>
            </tr>
            <tr>
              <td class="text-right switch">
                <i class="fa fa-asterisk text-danger mr-1"></i>
                <span class="mr-2">发送维护多功能小组提醒</span>
              </td>
              <td>
                <app-switch class="d-flex justify-content-start" type="success" editEnable
                  [formControl]="nodeDataForm.controls['is_can_inform_team_member']">
                </app-switch>
              </td>
            </tr>
            <tr>
              <td class="text-right">
                <span class="mr-2">角色描述</span>
              </td>
              <td>
                <!-- <select class="form-control" [formControl]="nodeDataForm.controls['tag_key']">
                                    <option *ngFor="let tag of tags" [value]="tag.key" (click)="checkTag(tag)">
                                        {{tag.name}}
                                    </option>
                                </select> -->
                <!-- <input type="text" [formControl]="nodeDataForm.controls['description']"
                                    class="form-control"> -->
                <textarea [formControl]="nodeDataForm.controls['description']" class="form-control"></textarea>
              </td>
            </tr>
          </tbody>
        </table>
      </form>
    </div>
    <div class="content" [ngClass]="{hidden: 3!==tabValue}">
      <app-check-standard-organization-items [items]="standardOrganizationChartItems"
        (checkedItemsChange)="checkStandardOrganizationChartItems($event)">
      </app-check-standard-organization-items>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-primary mr-2" (click)="confirm()" [disabled]="!((checkedItem?.id && tabValue===1 ) || (myForm.valid && tabValue === 2) || 
      (this.checkedStandardOrganizationChartItems?.length && tabValue === 3))">
      确定
    </button>
    <button type="button" class="btn btn-outline-secondary" (click)="hide()">
      返回
    </button>
  </div>
</ng-template>
<app-check-engin-field-tree #appCheckEnginFieldTree [(checkedEnginField)]="enginField"
  (checkedEnginFieldChange)="checkEnginField($event)">
</app-check-engin-field-tree>