import { Component, ElementRef, Input, OnInit, Renderer2 } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import _ from "lodash";
import { filter, startWith } from "rxjs/operators";
import { INav } from "src/app/models";

const MAX_LENGTH = 9;

@Component({
  selector: 'app-extra-long-navs',
  templateUrl: './extra-long-navs.component.html',
  styleUrls: ['./extra-long-navs.component.scss']
})
export class ExtraLongNavsComponent implements OnInit {
  @Input() items: INav[] = [];
  isExpand: boolean;
  isInMoreReport: boolean;
  currentRoute: string;
  maxLength = MAX_LENGTH;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private router: Router
  ) {
    this.renderer.listen("window", "click", (e: Event) => {
      // 点击“更多操作”以外的区域时，隐藏下拉菜单
      const moreReportElement =
        this.el.nativeElement.querySelector(".more-menu");
      if (
        e.target !== moreReportElement &&
        !moreReportElement?.contains(e.target)
      ) {
        this.isExpand = false;
      }
    });
  }

  ngOnInit(): void {
    this.getCurrentRoute();
  }

  getShowItems() {
    // 菜单过多时，小屏展示不开，只展示前9个；其余在“更多操作”菜单中展开
    if (this.items?.length > MAX_LENGTH) {
      return _.take(this.items, MAX_LENGTH);
    } else {
      return this.items;
    }
  }

  getMoreItems() {
    return _.drop(this.items, MAX_LENGTH);
  }

  toggle() {
    this.isExpand = !this.isExpand;
  }

  getCurrentRoute() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd), startWith(this.router),)
      .subscribe((event: NavigationEnd) => {
        const currentRouteWithParams = _.compact(event.url.split("/"))[1]; // 当前路由
        this.currentRoute = _.split(currentRouteWithParams, '?')[0]
      });
  }

  getIsInMoreReport() {
    const moreReportRoutes = _.map(this.getMoreItems(), 'routerLink');
    return _.includes(moreReportRoutes, this.currentRoute);
  }
}

