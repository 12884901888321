// *系统模块类型
export enum ESystemModuleType {
    /// <summary>
    /// 系统
    /// </summary>
    System = 10,

    /// <summary>
    /// 模块团队
    /// </summary>
    ModuleTeam = 20,

    /// <summary>
    /// 一级模块
    /// </summary>
    PrimaryModule = 30,

    /// <summary>
    /// 模块级别
    /// </summary>
    ModuleClass = 40

}
