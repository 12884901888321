import { Injectable } from '@angular/core';
import _ from 'lodash';

import { IAlert } from 'src/app/models';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  alerts: IAlert[] = [];

  constructor() { }

  addAlert(alert: IAlert): void {
    alert.timeout = alert.timeout || 5000;
    this.alerts.push(alert);  // 增加（改变原数组）
  }

  deleteAlert(alert: IAlert): void {
    const index = _.findIndex(this.alerts, alert);
    if (-1 !== index) {
      this.alerts.splice(index, 1); // 删除（改变原数组）
    }
  }
}
