// 选择项目群管理员（信息维护、组织管理的下发单，可以再项目群管理员之间转办）
import {
  Component,
  TemplateRef,
  ViewChild,
  Inject,
  Output,
  EventEmitter
} from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import _ from 'lodash';

import { CRadioBase } from 'src/app/classes';
import { IUserInfo, ITaskToTurnUsersParams } from 'src/app/models';
import { AuditService } from 'src/app/services/audit.service';

@Component({
  selector: 'app-check-group-admin',
  templateUrl: './check-group-admin.component.html',
  styleUrls: ['./check-group-admin.component.scss']
})
export class CheckGroupAdminComponent extends CRadioBase<IUserInfo, ITaskToTurnUsersParams> {
  title: string;
  @Output()
  confirmChange: EventEmitter<IUserInfo[]> = new EventEmitter();

  modalRef: BsModalRef;
  @ViewChild('checkGroupManager') modalEle: TemplateRef<any>;

  constructor(
    private modalService: BsModalService,
    @Inject(AuditService) private auditService: AuditService
  ) {
    super(auditService, 'getTaskToTurnUsers', 'user_id');
  }

  show(taskId: string, title?: string) {
    if (!this.modalRef) {
      this.title = title;
      this.queryParams = {
        task_id: taskId
      };
      super.getItems();
      this.modalRef = this.modalService.show(this.modalEle, { class: 'modal-lg', ignoreBackdropClick: true });
    }
  }

  hide() {
    if (this.modalRef) {
      this.modalRef.hide();
      this.modalRef = undefined;
      super.clear();
    }
  }

  confirm() {
    const checkedUser = super.getCheckedItem();
    this.confirmChange.emit([checkedUser]);
    this.hide();
  }
}
