// 分级树分级类型
export enum ELevelType {
    Milestone = 0,  // 节点
    MilestoneProcessSchedule = 1,   // 节点过程调度
    ProjectProcessSchedule = 2, // 计划过程调度
    PlanArchive = 10,   // 计划归类
    PlanDates = 11, // 计划日期
    StandardPlanDates = 12, // 标准计划日期
    SpecialPlanDates = 13, // 专项计划日期
    RiskPlanDates = 14, // 风险计划日期
    StandardPlan = 20,  // 标准计划
    SpecialPlan = 21,   // 专项计划
    RiskPlan = 22, // 风险计划
    SubPlan = 23,   // 子计划
    Plan = 24, // 计划
    PlanProcessSchedule = 25,    // 计划过程调度

    // 前端自定义
    _EpPlanDeliverable = 30 // EP计划的交付物计划
}
