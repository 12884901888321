<td>{{ index + 1 }}</td>
<td>
  <!-- 技术成果名称 -->
  <input
    *ngIf="editEnable"
    type="text"
    class="form-control"
    [formControl]="myForm.controls['name']"
  />
  <span *ngIf="!editEnable">{{ item.name }}</span>
</td>
<td>
  <!-- 所属分类 -->
  <select
    *ngIf="editEnable"
    class="form-control"
    [formControl]="myForm.controls['type_code']"
  >
    <option value="">--选择所属分类</option>
    <option *ngFor="let t of types" [ngValue]="t.key">
      {{ t.name }}-{{ t.key }}
    </option>
  </select>
  <span *ngIf="!editEnable && item.type_name"
    >{{ item.type_name }}-{{ item.type_code }}</span
  >
</td>
<td>
  <!-- 成果交付物内容 -->
  <span *ngIf="editEnable">{{ myForm.value.deliverable }}</span>
  <span *ngIf="!editEnable">{{ item.deliverable }}</span>
</td>
<td>
  <!-- 技术成果描述 -->
  <textarea
    id="textarea-{{index}}"
    *ngIf="editEnable"
    [title]="myForm.controls['tech_description'].value"
    class="form-control"
    rows="1"
    [formControl]="myForm.controls['tech_description']"
    (input)="onInputChange($event)"
  ></textarea>
  <span *ngIf="!editEnable">{{ item.tech_description }}</span>
</td>
<td>
  <!-- 定级建议 -->
  <select
    *ngIf="editEnable"
    class="form-control"
    [formControl]="myForm.controls['level_code']"
  >
    <option value="">--选择定级建议</option>
    <option *ngFor="let s of suggestions" [ngValue]="s.key">
      {{ s.name }}
    </option>
  </select>
  <span *ngIf="!editEnable">{{ item.level_name }}</span>
</td>
<td>
  <!-- 流水编码 -->
  <span *ngIf="editEnable">{{ myForm.value.code }}</span>
  <span *ngIf="!editEnable">{{ item.code }}</span>
</td>
<td>
  <!-- 是否废弃 -->
  <app-switch
    *ngIf="!(item.id && editEnable)"
    [formControl]="myForm.controls['is_droped']"
  ></app-switch>
  <app-switch
    *ngIf="item.id && editEnable"
    editEnable
    type="success"
    [formControl]="myForm.controls['is_droped']"
  ></app-switch>
</td>
<td>
  <!-- 成果详情页面 -->
  <a *ngIf="item.ntp_detail_url" target="_blank" [href]="item.ntp_detail_url"
    >成果详情页面</a
  >
</td>
<td *ngIf="editEnable">
  <!-- 操作 -->
  <button
    class="btn btn-sm btn-danger"
    [disabled]="!!item.id"
    (click)="delete()"
  >
    删除
  </button>
</td>
