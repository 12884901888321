import { Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import _ from 'lodash';

import { UserService } from 'src/app/services/user.service';
import { IOrganization, IOrganizationTreeItem } from 'src/app/models';
import { ROOT_ORGANIZATION_TREE_ITEM_PARENT_CODE } from 'src/app/consts';


@Component({
  selector: 'app-check-organization-tree',
  templateUrl: './check-organization-tree.component.html',
  styleUrls: ['./check-organization-tree.component.scss']
})
export class CheckOrganizationTreeComponent implements OnInit, OnChanges {
  items: IOrganizationTreeItem[] = [];
  checkedItem: IOrganizationTreeItem;

  @Input() withIsCanUpdate: boolean;  // 请求结果带上 is_can_update属性（是否可添加或者删除管理员）
  @Output() checkedOrganizationChange: EventEmitter<IOrganization> = new EventEmitter();

  constructor(
    private userService: UserService
  ) { }

  ngOnInit() {
    this.getRootOrganizationTreeItems();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.withIsCanUpdate) {
      this.withIsCanUpdate = 'false' !== changes.withIsCanUpdate.currentValue
        && changes.withIsCanUpdate.currentValue
        || '' === changes.withIsCanUpdate.currentValue;
    }
  }

  getOrganizationTreeItemChildren(organizationTreeItem: IOrganizationTreeItem) {
    this.userService.getOrganizationTreeItemChildren(
      organizationTreeItem.org_number,
      this.withIsCanUpdate
    ).subscribe(organizationTreeItems => {
      // 添加交互属性
      _.forEach(organizationTreeItems, item => {
        item._level = organizationTreeItem._level + 1;
        item._is_show = true;
        item._is_expand = false;
        item._has_loaded_children = false;
      });
      const index = _.findIndex(this.items, { org_number: organizationTreeItem.org_number });
      this.items = [
        ...this.items.slice(0, index + 1),
        ...organizationTreeItems,
        ...this.items.slice(index + 1)
      ];
    });
  }

  getRootOrganizationTreeItems() {
    this.userService.getOrganizationTreeItemChildren(
      ROOT_ORGANIZATION_TREE_ITEM_PARENT_CODE,
      this.withIsCanUpdate
    ).subscribe(organizationTreeItems => {
      // 添加交互属性
      _.forEach(organizationTreeItems, item => {
        item._is_expand = false;
        item._level = 0;
        item._is_show = true;
        item._has_loaded_children = false;
      });
      this.items = organizationTreeItems;
    });
  }

  checkItem(item: IOrganizationTreeItem) {
    this.checkedItem = item;
    this.checkedOrganizationChange.emit(
      _.omit(item, ['_is_expand', '_level', '_is_show', '_has_loaded_children'])
    );
  }

  toggleExpandItem(item: IOrganizationTreeItem) {
    item._is_expand = !item._is_expand;
    if (item._is_expand) {
      if (item._has_loaded_children) {
        this.showOrHideChildren(item, true);
        return;
      }
      item._has_loaded_children = true;
      this.getOrganizationTreeItemChildren(item);
    } else {
      this.showOrHideChildren(item, false);
    }
  }

  showOrHideChildren(item: IOrganizationTreeItem, isShow: boolean) {
    const children = _.filter(this.items, { parent_org_number: item.org_number });
    _.forEach(children, child => {
      child._is_show = isShow;
      if (child._is_expand && child.has_children) {
        this.showOrHideChildren(child, isShow);
      }
    });
  }
}
