import { Output, EventEmitter, OnInit, Directive } from '@angular/core';
import _ from 'lodash';
import { tap } from 'rxjs/operators';

@Directive()
export abstract class CListBase<T, S> implements OnInit {
  items: Array<T> = [];
  rejectItems?: Array<T> = [];  // 需过滤掉的items
  queryParams?: S; // 获取列表方法的请求参数对象
  @Output() itemsChange: EventEmitter<Array<T>> = new EventEmitter<Array<T>>();

  constructor(
    protected service: any,
    protected getItemsFun: string,
    protected byProperty?: string // 该属性作为区分各项的唯一标识
  ) {
  }

  ngOnInit() {
  }

  // 更新constructor
  protected updateConstructor(service: any, getItemsFun: string, byProperty?: string) {
    this.service = service;
    this.getItemsFun = getItemsFun;
    this.byProperty = byProperty;
  }

  protected getItems() {
    return this.service[this.getItemsFun](this.queryParams).pipe(
      tap((items: Array<T>) => {
        if (this.rejectItems && this.rejectItems.length && this.byProperty) {
          this.items = _.differenceBy(items, this.rejectItems, this.byProperty);
        } else {
          this.items = items;
        }
        this.itemsChange.emit(items);
      })
    );
  }
}
