import { Injectable } from '@angular/core';
import _ from 'lodash';

import { UserService } from './user.service';
import {
  IOrganizationChartItem,
  IOrganizationTeamItem,
  IOrgChart,
  IUserInfo
} from 'src/app/models';
import {
  ORGANIZATION_ROOT_PARENT_ID,
  ORGANIZATION_CHART_PRODUCT_ENGINEERING_ADMIN_NODE_PATH,
  ORGANIZATION_CHART_MANAGEMENT_ADMIN,
  ORGANIZATION_CHART_ROOT_PARENT_NODE_PATH,
  ORGANIZATION_CHART_ITEM_STYLE
} from 'src/app/consts';
import {
  IOrganizationPerson
} from 'src/app/models/IOrganizationChartItem';
import { EOrganizationChartItemType } from 'src/app/enums';

@Injectable({
  providedIn: 'root'
})
export class OrgChartService {

  constructor(
    private userService: UserService
  ) { }

  // 给transformed组织树节点分配显示属性
  assignOrganizationChartItemsTransformedShowProperties(
    items: IOrganizationChartItem[],
    editEnable: boolean,
    isCanUpdateProjectManagementAdmin: boolean,  // 是否能变更管理经理节点
    customBlackList: string[], // 不能添加自定义子节点的节点path列表
    isProjectInWhitelist: boolean // 当前项目是否在白名单内
  ): IOrganizationChartItem[] {
    items = _.cloneDeep(items);
    const currentUser = this.userService.getCurrentUserSync();
    _.forEach(items, item => {
      item._show_line_top = ORGANIZATION_ROOT_PARENT_ID !== item.parent_id && !item._is_placeholder;
      item._show_edit_btn = editEnable
        && item._enable_edit
        // 修正暂存后自定义节点不可编辑（disable_edit_name属性已弃用）
        // && !item.node_data?.disable_edit_name
        && !item.template_org_id
        && item._is_checked;
      item._show_delete_btn = editEnable
        && item._enable_edit
        && !item.node_data?.disable_delete
        && item._is_checked;
      item._show_persons = !!(item.persons && item.persons.length);
      if (item._show_persons) {
        _.forEach(item.persons, person => {
          person._show_delete_btn = editEnable
            && item._enable_edit
            && item._is_checked
            && !this.isManagementAdminDisableDeleteSelf(person, item, isCanUpdateProjectManagementAdmin, currentUser);
        });
      }
      item._show_add_persons_btn = editEnable && item._enable_edit;
      item._disable_add_persons_btn = item.node_data?.person_max_limit
        && item.persons?.length >= item.node_data?.person_max_limit;
      item._show_team_members = !!(item.team_members && item.team_members.length);
      const canEditEngineer = this.isCanEditEngineer({ persons: item.persons, currentUser, items, isProjectInWhitelist, parentNodePath: item.parent_node_path });
      let normalId: string;
      let normalItem: IOrganizationChartItem;
      switch (item._type) {
        case EOrganizationChartItemType.Normal:
          item._show_edit_team_members_btn = editEnable
            && item?.node_data?.is_can_maintain_team
            && canEditEngineer;
          const canEditMultifunctionalTeam = this.isCanEditMultifunctionalTeam(item.team_members, currentUser);
          item._show_edit_multifunctional_teams_btn = editEnable
            && item?.node_data?.is_can_maintain_team && canEditMultifunctionalTeam;
          break;
        case EOrganizationChartItemType.Engineer:
          normalId = item.id.split('-')[0];
          normalItem = _.find(items, { id: normalId });
          item._show_edit_team_members_btn = editEnable && normalItem && normalItem._show_edit_team_members_btn;
          item._show_edit_multifunctional_teams_btn = editEnable && normalItem && normalItem._show_edit_multifunctional_teams_btn;
          break;
        case EOrganizationChartItemType.MultifunctionalTeam:
          normalId = item.id.split('-')[0];
          normalItem = _.find(items, { id: normalId });
          item._show_edit_team_members_btn = editEnable && normalItem && normalItem._show_edit_team_members_btn;
          const idArr = item.id.split('-');
          idArr.pop();
          const engineerNodeId = idArr.join('-');
          let engineerNode: IOrganizationChartItem;
          engineerNode = _.find(items, { id: engineerNodeId });
          item._show_edit_multifunctional_teams_btn
            = editEnable && engineerNode?.persons[0]?.user_id === this.userService.getCurrentUserSync().user_id;
          break;
      }

      const canAddCustomSibling = !_.includes(customBlackList, item.parent_node_path);
      item._show_add_siblings_btn = editEnable
        && item._enable_edit
        && item._is_checked
        && !(ORGANIZATION_ROOT_PARENT_ID === item.parent_id)
        && canAddCustomSibling;
      item._show_add_parent_btn = editEnable
        && item._enable_edit
        && item._is_checked
        && ORGANIZATION_ROOT_PARENT_ID === item.parent_id;
      item._show_add_children_btn = editEnable
        && item?.node_data?.is_can_split_node
        && (item._enable_edit || item._enable_edit_offsprings)
        && item._is_checked;
      const path = ORGANIZATION_CHART_ROOT_PARENT_NODE_PATH === item.parent_node_path ?
        `/${item.name}/` // 是项目经理
        : `${item.parent_node_path}${item.name}/`; // 不是项目经理
      item._can_add_custom_children = !_.includes(customBlackList, path);

      this.assignStyle(item);
      this.generateOrganizationTeamsStrings(item);
    });
    return items;
  }

  // 可以维护工程师（PMT经理||管理经理, 显示维护团队button）
  // PMT经理||管理经理 节点下成员中有当前用户
  private isCanEditEngineer(
{ persons, currentUser, items, isProjectInWhitelist, parentNodePath }: {
  persons: IOrganizationPerson[]; currentUser: IUserInfo; items: IOrganizationChartItem[]; isProjectInWhitelist: boolean; parentNodePath: string // 当前PMT节点的parent_node_path
    ;
}  ): boolean {
    // 若项目不在白名单内,”管理经理“节点为普通节点，没有维护工程师的权限
    // 若项目在白名单内 && 当前PMT节点在'/项目经理/产品工程经理/'路径下，“管理经理”可维护此节点的工程师
    if (isProjectInWhitelist && _.includes(parentNodePath, ORGANIZATION_CHART_PRODUCT_ENGINEERING_ADMIN_NODE_PATH)) {
      const projectManagementAdminChartItem = _.find(items, ORGANIZATION_CHART_MANAGEMENT_ADMIN) as IOrganizationChartItem;
      if (projectManagementAdminChartItem && projectManagementAdminChartItem.persons) {
        persons = _.concat(persons, projectManagementAdminChartItem.persons);
      }
    }
    if (persons?.length) {
      return _.includes(_.map(persons, 'user_id'), currentUser.user_id);
    } else {
      return false;
    }
  }

  // 管理经理禁止删除自己
  private isManagementAdminDisableDeleteSelf(
    person: IOrganizationPerson,
    item: IOrganizationChartItem,
    isCanUpdateProjectManagementAdmin: boolean,  // 是否能变更管理经理节点
    currentUser: IUserInfo
  ): boolean {
    return person.user_id === currentUser.user_id
      && _.find([item], ORGANIZATION_CHART_MANAGEMENT_ADMIN)
      && !isCanUpdateProjectManagementAdmin;
  }

  // 可以维护工程师（工程师显示维护多功能小组button）
  private isCanEditMultifunctionalTeam(
    teamMembers: IOrganizationTeamItem[],
    currentUser: IUserInfo
  ): boolean {
    if (teamMembers?.length) {
      return _.includes(_.map(teamMembers, 'user_id'), currentUser.user_id);
    } else {
      return false;
    }
  }

  // 计算节点的样式（padding、竖向连接线位置）
  private assignStyle(item: IOrganizationChartItem) {
    let paddingLeft = 0;
    let paddingRight = 0;
    if (item._offspring_count && item._offspring_count > 1) {
      paddingLeft = (item._offspring_count * ORGANIZATION_CHART_ITEM_STYLE.WIDTH
        + (item._offspring_count - 1) * ORGANIZATION_CHART_ITEM_STYLE.SPACER
      ) * 0.5 - ORGANIZATION_CHART_ITEM_STYLE.WIDTH * 0.5;
      paddingRight = paddingLeft;
    }
    item._padding_left = `${paddingLeft}px`;
    item._padding_right = `${paddingRight}px`;
    item._vertical_line_location = paddingLeft + ORGANIZATION_CHART_ITEM_STYLE.WIDTH * 0.5
      - ORGANIZATION_CHART_ITEM_STYLE.LINE_WIDTH * 0.5 + 'px';
  }

  // 生成团队成员名称拼接的字符串列表
  private generateOrganizationTeamsStrings(item: IOrganizationChartItem) {
    const organizationTeams: IOrganizationTeamItem[] = item.team_members;
    if (organizationTeams && organizationTeams.length) {
      // _.forEach(organizationTeams, organizationTeam => {
      //   let persons = [];
      //   if (!(organizationTeam.multifunctional_teams && organizationTeam.multifunctional_teams.items)) {
      //     return;
      //   }
      //   _.forEach(organizationTeam.multifunctional_teams.items, item => {
      //     persons = _.concat(persons, item.persons);
      //   });
      //   const organizationTeamStr = persons.length ? organizationTeam.name + '(' + _.map(persons, 'name') + ')' : organizationTeam.name;
      //   this.organizationTeamsStr.push(organizationTeamStr);
      // });
      item._teams_strings = _.map(organizationTeams, 'name');
    }
  }

  // transformed组织树节点 => orgChart
  organizationChartItemsTransformed2orgChart(organizationChartItems: IOrganizationChartItem[]): IOrgChart {
    if (!organizationChartItems.length) {
      return null;
    }
    const group = _.groupBy(organizationChartItems, 'parent_id');
    // const root = (  // 根节点（根节点的parent_id值有可能被存为'undefined'、''）
    //   group[JSON.stringify(ORGANIZATION_ROOT_PARENT_ID)]
    //   // tslint:disable-next-line: no-string-literal
    //   || group['undefined']
    //   || group['']
    // )[0];

    // 查找根节点（没有parent）
    let root: IOrganizationChartItem;
    _.forEach(organizationChartItems, item => {
      if (-1 === _.findIndex(organizationChartItems, { id: item.parent_id })) {
        root = item;
        return false;
      }
    });

    const orgChart = {
      ...root,
      isCollapsed: root._is_collapsed,
      groupScale: 1,
      children: group[root.id]
    };

    function appendChildren(items: IOrgChart[]) {
      _.forEach(items, item => {
        item.isCollapsed = item._is_collapsed;
        item.children = group[item.id] || [];
        appendChildren(item.children);
      });
    }
    appendChildren(orgChart.children);
    return orgChart;
  }
}
