import { NgModule, Version } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { ngfModule } from 'angular-file';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { OrgchartModule } from 'orgchart';

import { SharedOrganizationChartModule } from 'src/app/modules/shared-organization-chart/shared-organization-chart.module';

// Pipe
import { HtmlPipe } from 'src/app/pipes/html.pipe';
import { SafePipe } from 'src/app/pipes/safe.pipe';
import { DateFormatPipe } from 'src/app/pipes/date-format.pipe';
import { DateFormatShortPipe } from 'src/app/pipes/date-format-short.pipe';

import { CheckUserComponent } from './check-user/check-user.component';
import { CheckUsersComponent } from './check-users/check-users.component';
import { CheckOrganizationTemplateComponent } from './check-organization-template/check-organization-template.component';
import { CheckProjectComponent } from './check-project/check-project.component';
import { CheckProjectOrganizationComponent } from './check-project-organization/check-project-organization.component';
import { CheckOrganizationComponent } from './check-organization/check-organization.component';
import { CheckOrganizationsComponent } from './check-organizations/check-organizations.component';
import { CheckStandardPlanTemplatesComponent } from './check-standard-plan-templates/check-standard-plan-templates.component';
import { CheckDeliverablesComponent } from './check-deliverables/check-deliverables.component';
import { CheckEnginFieldComponent } from './check-engin-field/check-engin-field.component';

import { CollapseComponent } from './collapse/collapse.component';
import { WorkflowComponent } from './workflow/workflow.component';
import { WorkflowStepComponent } from './workflow-step/workflow-step.component';
import { WorkflowStartComponent } from './workflow-start/workflow-start.component';
import { WorkflowEndComponent } from './workflow-end/workflow-end.component';
import { MilestoneIssueItemComponent } from './milestone-issue-item/milestone-issue-item.component';
import { MilestoneIssueListComponent } from './milestone-issue-list/milestone-issue-list.component';
import { PlanIssueItemComponent } from './plan-issue-item/plan-issue-item.component';
import { PlanIssueListComponent } from './plan-issue-list/plan-issue-list.component';
import { PlanTreeComponent } from './plan-tree/plan-tree.component';
import { PlanTreeItemComponent } from './plan-tree-item/plan-tree-item.component';
import { PlanTreeControllerComponent } from './plan-tree-controller/plan-tree-controller.component';
import { ShowDeliverablesComponent } from './show-deliverables/show-deliverables.component';
import { CarComponent } from './car/car.component';
import { PageGroupComponent } from './page-group/page-group.component';
import { DeliverablesComponent } from './deliverables/deliverables.component';
import { DeliverableItemComponent } from './deliverable-item/deliverable-item.component';
import { DeliverablesVersionsComponent } from './deliverables-versions/deliverables-versions.component';
import {
  DeliverablesSystemAdminUpdateComponent
} from './deliverables-system-admin-update/deliverables-system-admin-update.component';
import { DigitalDeliverableComponent } from './digital-deliverable/digital-deliverable.component';
// tslint:disable-next-line: max-line-length
import { DeliverableInfoComponent } from './deliverable-info/deliverable-info.component';
import { ApprovalHistoriesComponent } from './approval-histories/approval-histories.component';
import { LightComponent } from './light/light.component';
import { SwitchComponent } from './switch/switch.component';
import { MilestoneReceiptInfoComponent } from './milestone-receipt-info/milestone-receipt-info.component';
import { AttachmentsComponent } from './attachments/attachments.component';
import { RequirementAttachmentsComponent } from './requirement-attachments/requirement-attachments.component';
import { NavsComponent } from './navs/navs.component';
import { NavsBtnComponent } from './navs-btn/navs-btn.component';
import { NavsTabComponent } from './navs-tab/navs-tab.component';
import { TabsComponent } from './tabs/tabs.component';
import { TabsBtnComponent } from './tabs-btn/tabs-btn.component';
import { TabsVComponent } from './tabs-v/tabs-v.component';
import { TabsSideComponent } from './tabs-side/tabs-side.component';

import { ProjectInfoComponent } from './project-info/project-info.component';
import { MilestoneInfoComponent } from './milestone-info/milestone-info.component';
import { PlanInfoComponent } from './plan-info/plan-info.component';
import { PlanDeliverablesComponent } from './plan-deliverables/plan-deliverables.component';
import { SpecialPlanDeliverableComponent } from './special-plan-deliverable/special-plan-deliverable.component';
import { ReceiptInfoComponent } from './receipt-info/receipt-info.component';
import { ScheduleInfoComponent } from './schedule-info/schedule-info.component';
import { RiskAnalysisInfoComponent } from './risk-analysis-info/risk-analysis-info.component';
import { ProcessScheduleInfoComponent } from './process-schedule-info/process-schedule-info.component';
import { TurnComponent } from './turn/turn.component';
import { ChatComponent } from './chat/chat.component';
import { ShowQctsComponent } from './show-qcts/show-qcts.component';
import { ShowPfcsComponent } from './show-pfcs/show-pfcs.component';
import { ShowSalesComponent } from './show-sales/show-sales.component';
import { ShowTreeComponent } from './show-tree/show-tree.component';
import { ShowTreeItemComponent } from './show-tree-item/show-tree-item.component';
import { LevelTreeComponent } from './level-tree/level-tree.component';
import { LevelTreeItemComponent } from './level-tree-item/level-tree-item.component';
import { CompletionStatusComponent } from './completion-status/completion-status.component';
import { UnstandardDeliverableComponent } from './unstandard-deliverable/unstandard-deliverable.component';
import { ModifiedDataItemComponent } from './modified-data-item/modified-data-item.component';
import { CheckOrganizationTreeComponent } from './check-organization-tree/check-organization-tree.component';
import { CheckOrganizationsTreeComponent } from './check-organizations-tree/check-organizations-tree.component';
import { ProcessScheduleComponent } from './process-schedule/process-schedule.component';

import { ProjectFiltersComponent } from './project-filters/project-filters.component';
import { ProjectFilterComponent } from './project-filter/project-filter.component';
import { ProjectFiltersCheckedComponent } from './project-filters-checked/project-filters-checked.component';
import { ProjectFilterManagementHostComponent } from './project-filter-management-host/project-filter-management-host.component';
import { CheckGroupAdminComponent } from './check-group-admin/check-group-admin.component';
import { ShowOrganizationComponent } from './show-organization/show-organization.component';
import { UpdateHistoriesComponent } from './update-histories/update-histories.component';
import { ShowProjectMoreInfoComponent } from './show-project-more-info/show-project-more-info.component';
import { VersionsComponent } from './versions/versions.component';
import { ShowCarComponent } from './show-car/show-car.component';
import { ProjectFilterLightComponent } from './project-filter-light/project-filter-light.component';
import { CcComponent } from './cc/cc.component';
import { AddOrganizationChartItemComponent } from './add-organization-char-item/add-organization-chart-item.component';
import { CheckEnginFieldTreeComponent } from './check-engin-field-tree/check-engin-field-tree.component';
import { ViewOrganizationChartComponent } from './view-organization-chart/view-organization-chart.component';
import { AlphaMaintainListComponent } from './alpha-maintain-list/alpha-maintain-list.component';
import { AlphaMaintainItemComponent } from './alpha-maintain-item/alpha-maintain-item.component';
import { BetaMaintainItemComponent } from './beta-maintain-item/beta-maintain-item.component';
import { BetaMaintainListComponent } from './beta-maintain-list/beta-maintain-list.component';
import { ViewScoreReferenceComponent } from './view-score-reference/view-score-reference.component';
import { ViewScoreReferenceListComponent } from './view-score-reference-list/view-score-reference-list.component';
import { ShowPlanDeliverablesComponent } from './show-plan-deliverables/show-plan-deliverables.component';
import { CheckStandardOrganizationItemsComponent } from './check-standard-organization-items/check-standard-organization-items.component';
import { CheckOrganizationUsersComponent } from './check-organization-users/check-organization-users.component';
import { CheckLatelyOrganizationComponent } from './check-lately-organization/check-lately-organization.component';
import { CheckOrganizationUsersListComponent } from './check-organization-users-list/check-organization-users-list.component';
import { CheckOrganizationUserListComponent } from './check-organization-user-list/check-organization-user-list.component';
import { WorktimeIssueListComponent } from './worktime-issue-list/worktime-issue-list.component';
import { WorktimeIssueItemComponent } from './worktime-issue-item/worktime-issue-item.component';
import { SystemModuleComponent } from './system-module/system-module.component';
import { CheckWorktimeProjectComponent } from './check-worktime-project/check-worktime-project.component';
import { CheckRelationPlansComponent } from './check-relation-plans/check-relation-plans.component';
import { WorktimeRelationPlansListComponent } from './worktime-relation-plans-list/worktime-relation-plans-list.component';
import { CheckWorktimeProjectsComponent } from './check-worktime-projects/check-worktime-projects.component';
import { NewNavsComponent } from './new-navs/new-navs.component';
import { OrgChartComponent } from './org-chart/org-chart.component';
import { OrgChartItemComponent } from './org-chart-item/org-chart-item.component';
import { PerformanceApprovalListComponent } from './performance-approval-list/performance-approval-list.component';
import { PerformanceApprovalItemComponent } from './performance-approval-item/performance-approval-item.component';
// tslint:disable-next-line:max-line-length
import { PerformanceApprovalSecondFinalKpiComponent } from './performance-approval-second-final-kpi/performance-approval-second-final-kpi.component';
// tslint:disable-next-line:max-line-length
import { PerformanceApprovalFinalSubsidyComponent } from './performance-approval-final-subsidy/performance-approval-final-subsidy.component';
import { InputTrimModule } from 'ng2-trim-directive';
import { EditPlanFromTaskIdComponent } from './edit-plan-from-task-id/edit-plan-from-task-id.component';
import { TabsWithRouterlinkComponent } from './tabs-with-routerlink/tabs-with-routerlink.component';
import { ProjectRolesListComponent } from './project-roles-list/project-roles-list.component';
import { TagInputModule } from 'ngx-chips';
import { AchievementTargetComponent } from './achievement-target/achievement-target.component';
import { AchievementTargetItemComponent } from './achievement-target-item/achievement-target-item.component';
import { MigrationTargetComponent } from './migration-target/migration-target.component';
import { MigrationTargetItemComponent } from './migration-target-item/migration-target-item.component';
import { MigrationTargetWeightsComponent } from './migration-target-weights/migration-target-weights.component';
import { ExtraLongNavsComponent } from './extra-long-navs/extra-long-navs.component';
import { WorktimeBudgetMilestoneItemComponent } from './worktime-budget-milestone-item/worktime-budget-milestone-item.component';
import { WorktimeBudgetMilestoneListComponent } from './worktime-budget-milestone-list/worktime-budget-milestone-list.component';
import { MdmProjectInfoComponent } from './mdm-project-info/mdm-project-info.component';
import { ZentaoNavsBtnComponent } from './zentao-navs-btn/zentao-navs-btn.component';
import { PaginationComponent } from './pagination/pagination.component';
import { ZentaoNavsComponent } from './zentao-navs/zentao-navs.component';
import { CheckProjectCategoryComponent } from './check-project-category/check-project-category.component';
import { ProjectCategoryListComponent } from './project-category-list/project-category-list.component';
import { AddMilestoneComponent } from './add-milestone/add-milestone.component';
import { ProjectGroupListComponent } from './project-group-list/project-group-list.component';
import { CheckProjectGroupComponent } from './check-project-group/check-project-group.component';


@NgModule({
  declarations: [
    HtmlPipe,
    SafePipe,
    DateFormatPipe,
    DateFormatShortPipe,

    CheckUserComponent,
    CheckUsersComponent,
    CheckProjectComponent,
    CheckOrganizationTemplateComponent,
    CheckProjectOrganizationComponent,
    CheckOrganizationComponent,
    CheckOrganizationTreeComponent,
    CheckOrganizationsComponent,
    CheckOrganizationsTreeComponent,
    CheckStandardPlanTemplatesComponent,
    CheckDeliverablesComponent,
    CheckEnginFieldComponent,
    AddOrganizationChartItemComponent,
    CollapseComponent,
    NavsComponent,
    NavsBtnComponent,
    TabsComponent,
    TabsBtnComponent,
    TabsVComponent,
    TabsSideComponent,
    AttachmentsComponent,
    RequirementAttachmentsComponent,
    ShowDeliverablesComponent,
    WorkflowComponent,
    WorkflowStepComponent,
    WorkflowStartComponent,
    WorkflowEndComponent,
    CcComponent,
    MilestoneIssueItemComponent,
    MilestoneIssueListComponent,
    PlanIssueItemComponent,
    PlanIssueListComponent,
    PlanTreeComponent,
    PlanTreeItemComponent,
    PlanTreeControllerComponent,
    CarComponent,
    PlanInfoComponent,
    PlanDeliverablesComponent,
    SpecialPlanDeliverableComponent,
    ShowPlanDeliverablesComponent,
    ReceiptInfoComponent,
    PageGroupComponent,
    DeliverablesComponent,
    DeliverableItemComponent,
    DeliverablesVersionsComponent,
    DeliverablesSystemAdminUpdateComponent,
    DigitalDeliverableComponent,
    DeliverableInfoComponent,
    ApprovalHistoriesComponent,
    LightComponent,
    SwitchComponent,
    MilestoneReceiptInfoComponent,
    ProjectInfoComponent,
    MilestoneInfoComponent,
    ScheduleInfoComponent,
    ProcessScheduleInfoComponent,
    NavsTabComponent,
    TurnComponent,
    ChatComponent,
    ShowQctsComponent,
    ShowPfcsComponent,
    ShowSalesComponent,
    DeliverableInfoComponent,
    RiskAnalysisInfoComponent,
    ShowTreeComponent,
    ShowTreeItemComponent,
    LevelTreeComponent,
    LevelTreeItemComponent,
    CompletionStatusComponent,
    UnstandardDeliverableComponent,
    ModifiedDataItemComponent,
    ProcessScheduleComponent,
    ProjectFiltersComponent,
    ProjectFilterComponent,
    ProjectFiltersCheckedComponent,
    ProjectFilterLightComponent,
    ProjectFilterManagementHostComponent,
    CheckGroupAdminComponent,
    ShowOrganizationComponent,
    UpdateHistoriesComponent,
    ShowProjectMoreInfoComponent,
    VersionsComponent,
    ShowCarComponent,
    CheckEnginFieldTreeComponent,
    ViewOrganizationChartComponent,
    AlphaMaintainListComponent,
    AlphaMaintainItemComponent,
    BetaMaintainItemComponent,
    BetaMaintainListComponent,
    ViewScoreReferenceComponent,
    ViewScoreReferenceListComponent,
    PlanDeliverablesComponent,
    SpecialPlanDeliverableComponent,
    ShowPlanDeliverablesComponent,
    CheckStandardOrganizationItemsComponent,
    CheckOrganizationUsersComponent,
    CheckLatelyOrganizationComponent,
    CheckOrganizationUsersListComponent,
    CheckOrganizationUserListComponent,
    WorktimeIssueListComponent,
    WorktimeIssueItemComponent,
    SystemModuleComponent,
    CheckWorktimeProjectComponent,
    CheckRelationPlansComponent,
    WorktimeRelationPlansListComponent,
    CheckWorktimeProjectsComponent,
    NewNavsComponent,
    OrgChartComponent,
    OrgChartItemComponent,
    PerformanceApprovalListComponent,
    PerformanceApprovalItemComponent,
    PerformanceApprovalSecondFinalKpiComponent,
    PerformanceApprovalFinalSubsidyComponent,
    EditPlanFromTaskIdComponent,
    TabsWithRouterlinkComponent,
    ProjectRolesListComponent,
    AchievementTargetComponent,
    AchievementTargetItemComponent,
    MigrationTargetComponent,
    MigrationTargetItemComponent,
    MigrationTargetWeightsComponent,
    ExtraLongNavsComponent,
    WorktimeBudgetMilestoneItemComponent,
    WorktimeBudgetMilestoneListComponent,
    MdmProjectInfoComponent,
    ZentaoNavsBtnComponent,
    PaginationComponent,
    ZentaoNavsComponent,
    CheckProjectCategoryComponent,
    ProjectCategoryListComponent,
    AddMilestoneComponent,
    ProjectGroupListComponent,
    CheckProjectGroupComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    BsDatepickerModule,
    BsDropdownModule,
    ModalModule,
    PaginationModule,
    PopoverModule,
    ngfModule,
    InfiniteScrollModule,
    ScrollingModule,
    VirtualScrollerModule,
    TypeaheadModule,
    OrgchartModule,
    InputTrimModule,
    SharedOrganizationChartModule,
    TagInputModule,
  ],
  exports: [
    HtmlPipe,
    SafePipe,
    DateFormatPipe,
    DateFormatShortPipe,

    CheckUserComponent,
    CheckUsersComponent,
    CheckProjectComponent,
    CheckOrganizationTemplateComponent,
    CheckProjectOrganizationComponent,
    CheckOrganizationComponent,
    CheckOrganizationTreeComponent,
    CheckOrganizationsComponent,
    CheckOrganizationsTreeComponent,
    CheckStandardPlanTemplatesComponent,
    CheckDeliverablesComponent,
    CheckEnginFieldComponent,
    CheckEnginFieldTreeComponent,
    AddOrganizationChartItemComponent,
    CollapseComponent,
    NavsComponent,
    NavsBtnComponent,
    NavsTabComponent,
    TabsComponent,
    TabsBtnComponent,
    TabsVComponent,
    TabsSideComponent,
    OrgChartComponent,
    AttachmentsComponent,
    RequirementAttachmentsComponent,
    ShowDeliverablesComponent,
    WorkflowComponent,
    WorkflowStepComponent,
    WorkflowStartComponent,
    WorkflowEndComponent,
    CcComponent,
    MilestoneIssueListComponent,
    PlanIssueListComponent,
    PlanTreeComponent,
    PlanTreeControllerComponent,
    CarComponent,
    PlanInfoComponent,
    PlanDeliverablesComponent,
    ShowPlanDeliverablesComponent,
    SpecialPlanDeliverableComponent,
    ReceiptInfoComponent,
    PageGroupComponent,
    DeliverablesComponent,
    DeliverablesVersionsComponent,
    DeliverablesSystemAdminUpdateComponent,
    DigitalDeliverableComponent,
    DeliverableInfoComponent,
    ApprovalHistoriesComponent,
    LightComponent,
    SwitchComponent,
    MilestoneReceiptInfoComponent,
    ProjectInfoComponent,
    MilestoneInfoComponent,
    ScheduleInfoComponent,
    ProcessScheduleInfoComponent,
    RiskAnalysisInfoComponent,
    TurnComponent,
    ChatComponent,
    ShowTreeComponent,
    LevelTreeComponent,
    ShowCarComponent,
    ShowTreeItemComponent,
    CompletionStatusComponent,
    ModifiedDataItemComponent,
    ProcessScheduleComponent,
    ProjectFiltersComponent,
    ProjectFiltersCheckedComponent,
    UpdateHistoriesComponent,
    VersionsComponent,
    ViewOrganizationChartComponent,
    AlphaMaintainListComponent,
    AlphaMaintainItemComponent,
    BetaMaintainItemComponent,
    BetaMaintainListComponent,
    CheckOrganizationUsersComponent,
    CheckOrganizationUsersListComponent,
    WorktimeIssueListComponent,
    WorktimeIssueItemComponent,
    SystemModuleComponent,
    CheckRelationPlansComponent,
    WorktimeRelationPlansListComponent,
    CheckWorktimeProjectComponent,
    CheckWorktimeProjectsComponent,
    ProjectFilterComponent,
    ProjectFilterManagementHostComponent,
    NewNavsComponent,
    PerformanceApprovalListComponent,
    PerformanceApprovalFinalSubsidyComponent,
    TabsWithRouterlinkComponent,
    ProjectRolesListComponent,
    AchievementTargetComponent,
    ExtraLongNavsComponent,
    WorktimeBudgetMilestoneListComponent,
    MdmProjectInfoComponent,
    ZentaoNavsBtnComponent,
    PaginationComponent,
    ZentaoNavsComponent,
    CheckProjectCategoryComponent,
    ProjectCategoryListComponent,
    AddMilestoneComponent,
    ProjectGroupListComponent,
    CheckProjectGroupComponent,
  ]
})
export class SharedModule { }
