import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import _ from 'lodash';

import { SharedService } from '../services/shared.service';
import { TreeService } from 'src/app/services/tree.service';
import { TBillType, IMilestone, IPlan, IPlanTreeState, IProject, IShowTreeItem, TShowPlansType } from 'src/app/models';
import { EAuditStatus, EDeliverableCategory, EPlanType } from 'src/app/enums';

type TLocalPlanTreeProperty = 'plans' | 'currentPlan' | 'currentMilestone' | 'project';

function getInitialPlanTreeState(): IPlanTreeState {
  return _.cloneDeep({
    editEnable: false,
    editingPlan: undefined,
    project: undefined,
    milestones: [],
    currentMilestone: undefined,
    plans: [],
    showPlans: [],
    dirtyPlans: [],
    currentMilestonePlans: [],
    currentMilestoneShowPlans: [],
    currentMilestoneIsCheckedAll: false,
    currentMilestoneCheckedPlans: [],
    foldedPlanIds: [],
    currentPlan: undefined,
    dirty: false,
    showPlansType: 'editable',
    showVirtualScroll: false
  });
}

@Injectable({
  providedIn: 'root'
})
export class PlanTreeStateService {
  // 获取EP计划所属业务
  // 如果计划的所属业务是这个，则计划为EP计划（不可分解）
  private epEnginFiledKey: string;

  private planTreeState: IPlanTreeState = getInitialPlanTreeState();

  private planTreeState$: BehaviorSubject<IPlanTreeState> = new BehaviorSubject<IPlanTreeState>(this.planTreeState);
  // 计划列表分chunk加载更新
  private planTreeStateMute$: Subject<IPlanTreeState> = new Subject<IPlanTreeState>();
  // 计划列表不分chunk，一次性更新

  constructor(
    private http: HttpClient,
    private sharedService: SharedService,
    private treeService: TreeService
  ) { }

  // 获取EP计划所属业务
  // 如果计划的所属业务是这个，则计划为EP计划（不可分解）
  getProjectEpEnginFieldKey(projectCode: string) {
    const options = { // 防止string型的response被直接转换成number，丢失精度
      responseType: 'text' as 'json'
    };
    return this.http.get<string>(`/api/audits/project/${projectCode}/get-ep-engin-field`, options).pipe(
      tap(epEnginFiledKey => {
        this.epEnginFiledKey = epEnginFiledKey;
      })
    );
  }

  // 是否是EP计划
  getIsEpPlanSync(plan: IShowTreeItem): boolean {
    return plan.data.engin_field_key === this.epEnginFiledKey
      && plan.level === 1;
  }

  subscribePlanTreeState(): Observable<IPlanTreeState> {
    return this.planTreeState$;
  }

  private publishPlanTreeState() {
    this.planTreeState$.next(this.planTreeState);
  }

  subscribePlanTreeStateMute(): Observable<IPlanTreeState> {
    return this.planTreeStateMute$;
  }

  private publishPlanTreeStateMute() {
    this.planTreeStateMute$.next(this.planTreeState);
  }

  resetPlanTreeState(isMute?: boolean) {
    this.planTreeState = getInitialPlanTreeState();
    if (isMute) {
      this.publishPlanTreeStateMute();
    } else {
      this.publishPlanTreeState();
    }
  }

  setPlanTreeEditEnable(editEnable: boolean, isMute?: boolean) {
    this.planTreeState.editEnable = editEnable;
    if (isMute) {
      this.publishPlanTreeStateMute();
    } else {
      this.publishPlanTreeState();
    }
  }

  setPlanTreeDirty(dirty: boolean, isMute?: boolean) {
    this.planTreeState.dirty = dirty;
    if (isMute) {
      this.publishPlanTreeStateMute();
    } else {
      this.publishPlanTreeState();
    }
  }

  setPlanTreeEditingPlan(plan: IPlan, isMute?: boolean) {
    this.planTreeState.editingPlan = plan;
    if (isMute) {
      this.publishPlanTreeStateMute();
    } else {
      this.publishPlanTreeState();
    }
  }

  setPlanTreeProject(project: IProject, isMute?: boolean) {
    this.planTreeState.project = project;
    if (isMute) {
      this.publishPlanTreeStateMute();
    } else {
      this.publishPlanTreeState();
    }
  }

  setPlanTreeMilestones(milestones: IMilestone[], isMute?: boolean) {
    if (milestones.length) {
      //  如果currentMilestone没有值，则值为milestones的第一个元素
      if (!this.planTreeState.currentMilestone) {
        this.planTreeState.currentMilestone = _.head(milestones);
        // 本地存储currentMilestone(当前节点)(防止计划页刷新后丢失数据)
        this.setLocalPlanTree<IMilestone>('currentMilestone', _.head(milestones));
      }
      this.planTreeState.currentMilestonePlans = _.filter(this.planTreeState.plans, {
        project_milestone_id: this.planTreeState.currentMilestone.id
      });
      // 计算各节点的dirtyPlan数量
      if (this.planTreeState.dirtyPlans.length) {
        milestones = this.getMilestonesWithDirtyPlansCount(milestones, this.planTreeState.dirtyPlans);
      }
      this.planTreeState.currentMilestoneShowPlans = this.plans2showPlans(
        this.planTreeState.currentMilestonePlans, this.planTreeState.foldedPlanIds
      );
      this.planTreeState.milestones = milestones;
    }
    if (isMute) {
      this.publishPlanTreeStateMute();
    } else {
      this.publishPlanTreeState();
    }
  }

  checkPlanTreeMilestone(milestone: IMilestone, isMute?: boolean) {
    this.planTreeState.currentMilestone = milestone;
    this.planTreeState.currentMilestonePlans = this.planTreeState.currentMilestone
      ? _.filter(this.planTreeState.plans, { project_milestone_id: this.planTreeState.currentMilestone.id })
      : [];
    this.planTreeState.currentMilestoneShowPlans = this.plans2showPlans(
      this.planTreeState.currentMilestonePlans, this.planTreeState.foldedPlanIds
    );
    this.planTreeState.currentMilestoneCheckedPlans = [];
    this.planTreeState.currentMilestoneIsCheckedAll = false;
    if (isMute) {
      this.publishPlanTreeStateMute();
    } else {
      this.publishPlanTreeState();
    }
  }

  setPlanTreeCurrentMilestoneIsCheckedAll(isCheckedAll: boolean, isMute?: boolean) {
    this.planTreeState.currentMilestoneIsCheckedAll = isCheckedAll;
    this.planTreeState.currentMilestonePlans = this.planTreeState.currentMilestone
      ? _.filter(this.planTreeState.plans, { project_milestone_id: this.planTreeState.currentMilestone.id })
      : [];
    const currentMilestoneUnapprovedPlans = _.reject(this.planTreeState.currentMilestonePlans, item => !!item._is_issue_approved);
    this.planTreeState.currentMilestoneCheckedPlans = this.planTreeState.currentMilestoneIsCheckedAll
      ? currentMilestoneUnapprovedPlans
      : [];
    if (isMute) {
      this.publishPlanTreeStateMute();
    } else {
      this.publishPlanTreeState();
    }
  }

  setPlanTreePlans(plans: IPlan[], isMute?: boolean) {
    this.planTreeState.plans = plans;
    this.planTreeState.showPlans = this.plans2showPlans(this.planTreeState.plans, this.planTreeState.foldedPlanIds);
    if (this.planTreeState.currentMilestone) {
      this.planTreeState.currentMilestonePlans = _.filter(plans, { project_milestone_id: this.planTreeState.currentMilestone.id });
      this.planTreeState.currentMilestoneShowPlans = this.plans2showPlans(
        this.planTreeState.currentMilestonePlans, this.planTreeState.foldedPlanIds
      );
    }
    if (isMute) {
      this.publishPlanTreeStateMute();
    } else {
      this.publishPlanTreeState();
    }
  }

  setPlanTreeDirtyPlans(plans: IPlan[], isMute?: boolean) {
    this.planTreeState.dirtyPlans = plans;
    this.planTreeState.milestones = this.getMilestonesWithDirtyPlansCount(this.planTreeState.milestones, this.planTreeState.dirtyPlans);
    if (isMute) {
      this.publishPlanTreeStateMute();
    } else {
      this.publishPlanTreeState();
    }
  }

  // 给计划的专项交付物分配新id
  // （处理：因复制计划时，专项交付物直接复制，id相同，导致计划流程及交付物版本异常）
  private assignNewIdsForPlanSpecialDeliverables(plans: IPlan[]): Observable<IPlan[]> {
    plans = _.cloneDeep(plans);
    let specialDeliverablesCount = 0; // 专项交付物的数量
    _.forEach(plans, plan => {
      _.forEach(plan.target_deliverables, item => {
        if (EDeliverableCategory.Spec === item.type) {
          specialDeliverablesCount++;
        }
      });
    });
    return this.sharedService.generateNewIds(specialDeliverablesCount).pipe(
      map(newIds => {
        if (!newIds.length) {
          return plans;
        }
        _.forEach(plans, plan => {
          _.forEach(plan.target_deliverables, item => {
            if (EDeliverableCategory.Spec === item.type) {
              item.id = newIds.shift();
            }
          });
        });
        return plans;
      })
    );
  }

  addPlanTreePlan(newPlan: IPlan, isMute?: boolean) {
    this.assignNewIdsForPlanSpecialDeliverables([newPlan]).subscribe(plans => {
      const plan = plans[0];
      plan.is_can_split = true; // 计划可分解（有权限添加，添加的计划即可分解）
      plan.is_can_update = true; // 计划可变更（有权限添加，添加的计划即可变更）
      plan.is_can_copy = true; // 计划可复制（有权限添加，添加的计划即可复制）
      plan._is_issue_approved = false;  // 下发通过状态
      plan._is_new = true; // 新增计划
      plan._min_target_end_time = new Date();

      this.planTreeState.plans.push(plan);
      this.planTreeState.dirtyPlans.push(plan);
      if (plan.milestone_code === this.planTreeState.currentMilestone.code) {
        this.planTreeState.currentMilestonePlans.push(plan);
      }
      this.planTreeState.milestones = this.getMilestonesWithDirtyPlansCount(this.planTreeState.milestones, this.planTreeState.dirtyPlans);
      this.planTreeState.showPlans = this.plans2showPlans(this.planTreeState.plans, this.planTreeState.foldedPlanIds);
      this.planTreeState.currentMilestoneShowPlans = this.plans2showPlans(
        this.planTreeState.currentMilestonePlans,
        this.planTreeState.foldedPlanIds
      );
      this.planTreeState.currentMilestoneIsCheckedAll = false;
      this.planTreeState.dirty = true;
      if (isMute) {
        this.publishPlanTreeStateMute();
      } else {
        this.publishPlanTreeState();
      }
    });
  }

  addPlanTreePlans(newPlans: IPlan[], isMute?: boolean) {
    this.assignNewIdsForPlanSpecialDeliverables(newPlans).subscribe(plans => {
      _.forEach(plans, plan => {
        plan.is_can_split = true; // 计划可分解（有权限添加，添加的计划即可分解）
        plan.is_can_update = true; // 计划可变更（有权限添加，添加的计划即可变更）
        plan.is_can_copy = true; // 计划可复制（有权限添加，添加的计划即可复制）
        plan._is_issue_approved = false;  // 下发通过状态
        plan._is_new = true; // 计划变更时新增加的计划
        plan._min_target_end_time = new Date();
      });
      this.planTreeState.plans = _.concat(this.planTreeState.plans, plans);
      this.planTreeState.dirtyPlans = _.concat(this.planTreeState.dirtyPlans, plans);
      const currentMilestoneNewPlans = _.filter(plans, { milestone_code: this.planTreeState.currentMilestone.code });
      this.planTreeState.currentMilestonePlans = _.concat(this.planTreeState.currentMilestonePlans, currentMilestoneNewPlans);
      this.planTreeState.milestones = this.getMilestonesWithDirtyPlansCount(this.planTreeState.milestones, this.planTreeState.dirtyPlans);
      this.planTreeState.showPlans = this.plans2showPlans(this.planTreeState.plans, this.planTreeState.foldedPlanIds);
      this.planTreeState.currentMilestoneShowPlans = this.plans2showPlans(
        this.planTreeState.currentMilestonePlans,
        this.planTreeState.foldedPlanIds
      );
      this.planTreeState.currentMilestoneIsCheckedAll = false;
      this.planTreeState.dirty = true;
      if (isMute) {
        this.publishPlanTreeStateMute();
      } else {
        this.publishPlanTreeState();
      }
    });
  }

  deletePlanTreePlan(plan: IPlan, isMute?: boolean) {
    this.planTreeState.plans = _.reject(this.planTreeState.plans, { parent_id: plan.id }); // 删除子计划
    this.planTreeState.plans = _.reject(this.planTreeState.plans, { id: plan.id }); // 删除计划

    this.planTreeState.dirtyPlans = _.reject(this.planTreeState.dirtyPlans, { parent_id: plan.id });
    this.planTreeState.dirtyPlans = _.reject(this.planTreeState.dirtyPlans, { id: plan.id });

    this.planTreeState.currentMilestonePlans = this.planTreeState.currentMilestone
      ? _.filter(this.planTreeState.plans, { project_milestone_id: this.planTreeState.currentMilestone.id })
      : [];
    this.planTreeState.currentMilestoneCheckedPlans = _.reject(this.planTreeState.currentMilestoneCheckedPlans, { parent_id: plan.id });
    this.planTreeState.currentMilestoneCheckedPlans = _.reject(this.planTreeState.currentMilestoneCheckedPlans, { id: plan.id });

    const currentMilestoneUnapprovedPlans = _.reject(this.planTreeState.currentMilestonePlans, { _is_issue_approved: true });
    this.planTreeState.currentMilestoneIsCheckedAll = currentMilestoneUnapprovedPlans.length
      && currentMilestoneUnapprovedPlans.length === this.planTreeState.currentMilestoneCheckedPlans.length;

    this.planTreeState.milestones = this.getMilestonesWithDirtyPlansCount(this.planTreeState.milestones, this.planTreeState.dirtyPlans);
    this.planTreeState.showPlans = this.plans2showPlans(this.planTreeState.plans, this.planTreeState.foldedPlanIds);
    this.planTreeState.currentMilestoneShowPlans = this.plans2showPlans(
      this.planTreeState.currentMilestonePlans,
      this.planTreeState.foldedPlanIds
    );
    this.planTreeState.dirty = true;
    if (isMute) {
      this.publishPlanTreeStateMute();
    } else {
      this.publishPlanTreeState();
    }
  }

  deletePlanTreePlans(deletePlans: IPlan[], isMute?: boolean) {
    const deletePlanIds = _.map(deletePlans, 'id');
    this.planTreeState.plans = _.reject(this.planTreeState.plans, p => { // 删除子计划
      return _.includes(deletePlanIds, p.parent_id);
    });
    this.planTreeState.plans = _.reject(this.planTreeState.plans, p => { // 删除计划
      return _.includes(deletePlanIds, p.id);
    });

    this.planTreeState.dirtyPlans = _.reject(this.planTreeState.dirtyPlans, p => { // 删除子计划
      return _.includes(deletePlanIds, p.parent_id);
    });
    this.planTreeState.dirtyPlans = _.reject(this.planTreeState.dirtyPlans, p => { // 删除计划
      return _.includes(deletePlanIds, p.id);
    });

    this.planTreeState.currentMilestonePlans = this.planTreeState.currentMilestone
      ? _.filter(this.planTreeState.plans, { project_milestone_id: this.planTreeState.currentMilestone.id })
      : [];
    this.planTreeState.currentMilestoneCheckedPlans = _.reject(this.planTreeState.currentMilestoneCheckedPlans, p => { // 删除子计划
      return _.includes(deletePlanIds, p.parent_id);
    });
    this.planTreeState.currentMilestoneCheckedPlans = _.reject(this.planTreeState.currentMilestoneCheckedPlans, p => { // 删除计划
      return _.includes(deletePlanIds, p.id);
    });

    const currentMilestoneUnapprovedPlans = _.reject(this.planTreeState.currentMilestonePlans, { _is_issue_approved: true });
    this.planTreeState.currentMilestoneIsCheckedAll = currentMilestoneUnapprovedPlans.length
      && currentMilestoneUnapprovedPlans.length === this.planTreeState.currentMilestoneCheckedPlans.length;
    this.planTreeState.milestones = this.getMilestonesWithDirtyPlansCount(this.planTreeState.milestones, this.planTreeState.dirtyPlans);
    this.planTreeState.showPlans = this.plans2showPlans(this.planTreeState.plans, this.planTreeState.foldedPlanIds);
    this.planTreeState.currentMilestoneShowPlans = this.plans2showPlans(
      this.planTreeState.currentMilestonePlans,
      this.planTreeState.foldedPlanIds
    );
    this.planTreeState.dirty = true;
    if (isMute) {
      this.publishPlanTreeStateMute();
    } else {
      this.publishPlanTreeState();
    }
  }

  updatePlanTreePlan(plan: IPlan, isMute?: boolean) {
    plan._is_issue_approved = false;  // 下发通过状态

    this.planTreeState.dirtyPlans = _.reject(this.planTreeState.dirtyPlans, { id: plan.id });
    this.planTreeState.dirtyPlans.push(plan);

    const updateIndexInCheckedPlans = _.findIndex(this.planTreeState.currentMilestoneCheckedPlans, { id: plan.id });
    if (updateIndexInCheckedPlans !== -1) {
      const checkedPlans = _.cloneDeep(this.planTreeState.currentMilestoneCheckedPlans);
      this.planTreeState.currentMilestoneCheckedPlans = [
        ...checkedPlans.slice(0, updateIndexInCheckedPlans),
        plan,
        ...checkedPlans.slice(updateIndexInCheckedPlans + 1)
      ];
    }

    const updateIndex = _.findIndex(this.planTreeState.plans, { id: plan.id });
    if (-1 === updateIndex) {
      return;
    } else {
      const plans = _.cloneDeep(this.planTreeState.plans);
      this.planTreeState.plans = [
        ...plans.slice(0, updateIndex),
        plan,
        ...plans.slice(updateIndex + 1)
      ];
      this.planTreeState.currentMilestonePlans = this.planTreeState.currentMilestone
        ? _.filter(this.planTreeState.plans, { project_milestone_id: this.planTreeState.currentMilestone.id })
        : [];
      this.planTreeState.milestones = this.getMilestonesWithDirtyPlansCount(this.planTreeState.milestones, this.planTreeState.dirtyPlans);
      this.planTreeState.showPlans = this.plans2showPlans(this.planTreeState.plans, this.planTreeState.foldedPlanIds);
      this.planTreeState.currentMilestoneShowPlans = this.plans2showPlans(
        this.planTreeState.currentMilestonePlans,
        this.planTreeState.foldedPlanIds
      );
      this.planTreeState.dirty = true;
    }
    if (isMute) {
      this.publishPlanTreeStateMute();
    } else {
      this.publishPlanTreeState();
    }
  }

  updatePlanTreePlans(updatePlans: IPlan[], isMute?: boolean) {
    let dirtyPlans = _.cloneDeep(this.planTreeState.dirtyPlans);
    const plans = _.cloneDeep(this.planTreeState.plans);
    const currentMilestonePlans = _.cloneDeep(this.planTreeState.currentMilestonePlans);
    _.forEach(updatePlans, plan => {
      const updateIndex = _.findIndex(this.planTreeState.plans, { id: plan.id });
      const currentMilestoneIndex = _.findIndex(this.planTreeState.currentMilestonePlans, { id: plan.id });
      plan._is_issue_approved = false;  // 下发通过状态
      plans[updateIndex] = plan;
      currentMilestonePlans[currentMilestoneIndex] = plan;
      dirtyPlans = _.reject(dirtyPlans, { id: plan.id });
      dirtyPlans.push(plan);
    });
    this.planTreeState.dirtyPlans = dirtyPlans;
    this.planTreeState.plans = plans;
    this.planTreeState.currentMilestonePlans = currentMilestonePlans;
    this.planTreeState.milestones = this.getMilestonesWithDirtyPlansCount(this.planTreeState.milestones, this.planTreeState.dirtyPlans);
    this.planTreeState.showPlans = this.plans2showPlans(this.planTreeState.plans, this.planTreeState.foldedPlanIds);
    this.planTreeState.currentMilestoneShowPlans = this.plans2showPlans(
      this.planTreeState.currentMilestonePlans,
      this.planTreeState.foldedPlanIds
    );
    this.planTreeState.dirty = true;
    if (isMute) {
      this.publishPlanTreeStateMute();
    } else {
      this.publishPlanTreeState();
    }
  }

  toggleCheckPlanTreePlan(plan: IPlan, isMute?: boolean) {
    let isChecked = -1 !== _.findIndex(this.planTreeState.currentMilestoneCheckedPlans, { id: plan.id });
    isChecked = !isChecked;
    if (isChecked) {
      this.planTreeState.currentMilestoneCheckedPlans = _.unionBy([...this.planTreeState.currentMilestoneCheckedPlans, plan], 'id');
    } else {
      this.planTreeState.currentMilestoneCheckedPlans = _.reject(this.planTreeState.currentMilestoneCheckedPlans, { id: plan.id });
    }

    const currentMilestoneUnapprovedPlans = _.reject(this.planTreeState.currentMilestonePlans, { _is_issue_approved: true });
    this.planTreeState.currentMilestoneIsCheckedAll = currentMilestoneUnapprovedPlans.length
      && currentMilestoneUnapprovedPlans.length === this.planTreeState.currentMilestoneCheckedPlans.length;
    if (isMute) {
      this.publishPlanTreeStateMute();
    } else {
      this.publishPlanTreeState();
    }
  }

  toggleFoldPlanTreePlan(plan: IPlan, isMute?: boolean) {
    if (_.includes(this.planTreeState.foldedPlanIds, plan.id)) {
      this.planTreeState.foldedPlanIds = _.without(this.planTreeState.foldedPlanIds, plan.id);
    } else {
      this.planTreeState.foldedPlanIds.push(plan.id);
    }
    this.planTreeState.showPlans = this.plans2showPlans(this.planTreeState.plans, this.planTreeState.foldedPlanIds);
    this.planTreeState.currentMilestoneShowPlans = this.plans2showPlans(
      this.planTreeState.currentMilestonePlans,
      this.planTreeState.foldedPlanIds
    );
    if (isMute) {
      this.publishPlanTreeStateMute();
    } else {
      this.publishPlanTreeState();
    }
  }

  setPlanTreeCurrentPlan(plan: IPlan, isMute?: boolean) {
    this.planTreeState.currentPlan = plan;
    if (isMute) {
      this.publishPlanTreeStateMute();
    } else {
      this.publishPlanTreeState();
    }
  }

  setPlanTreeShowPlansType(showPlansType: TShowPlansType, isMute?: boolean) {
    this.planTreeState.showPlansType = showPlansType;
    if (isMute) {
      this.publishPlanTreeStateMute();
    } else {
      this.publishPlanTreeState();
    }
  }

  setPlanTreeShowVirtualScroll(showVirtualScroll: boolean, isMute?: boolean) {
    this.planTreeState.showVirtualScroll = showVirtualScroll;
    if (isMute) {
      this.publishPlanTreeStateMute();
    } else {
      this.publishPlanTreeState();
    }
  }

  private plans2showPlans(plans: IPlan[], foldedPlanIds: string[]): IShowTreeItem[] {
    return this.treeService.flatTreeItems2showTreeItems<IPlan, IShowTreeItem>(plans, foldedPlanIds);
  }

  // 计算各节点下的新增、变更计划的数量
  private getMilestonesWithDirtyPlansCount(milestones: IMilestone[], dirtyPlans: IPlan[]): IMilestone[] {
    const newMilestones = _.cloneDeep(milestones);
    _.forEach(newMilestones, milestone => {
      milestone._dirty_plans_count = _.filter(dirtyPlans, { milestone_code: milestone.code }).length;
    });
    return newMilestones;
  }

  // 给showPlans分配显示相关的属性
  assignShowPlansShowProperties(
    showPlans: IShowTreeItem[],
    currentMilestoneCheckedPlans: IPlan[],
    currentMilestone: IMilestone,
    currentPlan: IPlan,
    editEnable: boolean,
    billType: TBillType,
    showPlansType: TShowPlansType
  ): IShowTreeItem[] {
    // 过滤掉折叠节点的子节点
    _.forEach(showPlans, item => {
      item._is_hide = item.is_hide();
    });
    showPlans = _.reject(showPlans, { _is_hide: true });

    const isCurrentMilestoneNotPassed = currentMilestone && !currentMilestone.is_read_only; // 当前节点未过阀
    _.forEach(showPlans, (item, index) => {
      item.can_edit = editEnable && 'issue' === billType;  // 是否可编辑（显示checkbox列、操作列）
      item.is_approved_plan = item.data._is_issue_approved; // 是否已下发通过的计划
      item.is_standard_plan = EPlanType.Standard === item.data.plan_type; // 是否标准计划
      item.is_risk_plan = EPlanType.Risk === item.data.plan_type; // 是否风险计划
      item.is_current_plan = currentPlan && currentPlan.id === item.data.id;  // 是否当前计划
      item.name_padding_left = (item.level - 1) * 16; // 计划名称左边距（px）
      item.can_edit_engin_field = item.can_edit && !item.is_standard_plan && !item.is_approved_plan;  // 可编辑所属业务
      item.can_edit_target_end_time = item.can_edit && !item.is_approved_plan;  // 可编辑计划完成时间
      item.can_edit_responsible_person = item.can_edit && !item.is_approved_plan;  // 可编辑责任人
      item.can_edit_responsible_org = item.can_edit && !item.is_approved_plan;  // 可编辑责任单位
      item.disable_add_special_child = this.getIsEpPlanSync(item)
        || !((item.data.is_can_split || item.data.is_can_update) && isCurrentMilestoneNotPassed);  // 禁用“添加专项计划”
      item.show_update = editEnable && 'update' === billType;  // 显示“变更”按钮
      item.disable_update = !(item.data.is_can_update && isCurrentMilestoneNotPassed);   // 禁用变更
      item.is_checked = -1 !== _.findIndex(currentMilestoneCheckedPlans, { id: item.id });
      item.deliverables_str = item.is_standard_plan || EPlanType.Spec === item.data.plan_type
        ? _.map(item.data.target_deliverables, 'name').join(' / ')
        : item.data.un_standard_deliverable;  // 交付物名称组成的字符串
      item.is_can_copy = item.data.is_can_copy; // 可复制
    });

    switch (showPlansType) {
      // 可编辑（只展示可编辑的计划及其祖先）
      case 'editable':
        // - 过滤出可编辑的计划
        const editableShowPlans: IShowTreeItem[] = _.filter(showPlans, showPlan => {
          const result = ('issue' === billType && !showPlan.disable_add_special_child)  // 计划下发页可追加子计划
            || ('update' === billType && !showPlan.disable_update || !showPlan.disable_add_special_child)  // 计划变更页可变更 || 可追加
            || showPlan.data._is_new; // 新增的计划都是可编辑的（无论是否EP计划）
          return !!result;
        });
        const editableShowPlansAndAncestors: IShowTreeItem[] = _.cloneDeep(editableShowPlans);

        // - 追加上可编辑计划的祖先计划
        // 递归追加可编辑计划的祖先计划
        const insertAncestors = (item: IShowTreeItem) => {
          if (!item.parent_id) {  // 一级计划，不做处理
            return;
          }
          if (-1 !== _.findIndex(editableShowPlansAndAncestors, { id: item.parent_id })) {  // 祖先计划已存在于列表中，不追加
            return;
          }
          const index = _.findIndex(editableShowPlansAndAncestors, { id: item.id });
          const parentItem = _.find(showPlans, { id: item.parent_id });
          editableShowPlansAndAncestors.splice(index, 0, parentItem);
          insertAncestors(parentItem);
        };
        _.forEach(editableShowPlans, item => {
          insertAncestors(item);
        });
        showPlans = editableShowPlansAndAncestors;
        break;

      // 计划编制（全部）
      case 'all':
        break;

      // 未下发
      case 'unIssued':
        showPlans = _.filter(showPlans, item => !item.is_approved_plan);
        break;
    }
    // 克隆一份，使plan-tree-item能实时更新 is_checked 的 checkbox 的UI状态。（否则，因为plan-tree-item的输入属性item是引用类型，变更检测认为没有发生变化，不会更新UI）
    return _.cloneDeep(showPlans);
  }

  // 本地保存planTree的属性(防止计划页刷新后丢失数据)
  setLocalPlanTree<T>(property: TLocalPlanTreeProperty, value: T): void {
    value = _.cloneDeep(value);
    if ('project' === property) {
      // bugfix: 因组织数据庞大，导致sessionStorage溢出
      (value as IProject).project_org_tree = {};
    }
    if ('plans' === property) {
      const sliceIndex = Math.ceil((value as unknown as any[]).length / 2)
      console.log((value as unknown as any[]).length, sliceIndex);
      const sessionStoragePlans = _.slice(value as unknown as any[], 0, sliceIndex);
      const localStoragePlans = _.slice(value as unknown as any[], sliceIndex);
      sessionStorage.setItem(property, JSON.stringify(sessionStoragePlans));
      localStorage.setItem(property, JSON.stringify(localStoragePlans));
    } else {
      sessionStorage.setItem(property, JSON.stringify(value));
    }
  }
  // 获取本地保存planTree的属性(防止计划页刷新后丢失数据)
  getLocalPlanTree<T>(property: TLocalPlanTreeProperty): T {
    let value;
    if ('plans' === property) {
      const sessionStoragePlans = JSON.parse(sessionStorage.getItem(property));
      const localStoragePlans = JSON.parse(localStorage.getItem(property));
      value = _.concat(sessionStoragePlans, localStoragePlans);
    } else {
      value = JSON.parse(sessionStorage.getItem(property));
    }

    // 如果value没有值，则不返回值
    if (!value) {
      return;
    }
    // 如果value为数组，需要遍历value，将_min_target_end_time有值且类型为string的item的_min_target_end_time转换回Date类型
    if (_.isArray(value)) {
      _.forEach(value, item => {
        if (item._min_target_end_time && 'string' === typeof item._min_target_end_time) {
          item._min_target_end_time = new Date(item._min_target_end_time);
        }
      });
    } else {
      // 如果value的_min_target_end_time有值且类型为string，将value的_min_target_end_time的类型转换回Date类型
      if (value._min_target_end_time && 'string' === typeof value._min_target_end_time) {
        value._min_target_end_time = new Date(value._min_target_end_time);
      }
    }
    return value;
  }
  // 销毁本地保存的planTree属性(防止计划页刷新后丢失数据)
  removeLocalPlanTree<T>(property: TLocalPlanTreeProperty) {
    sessionStorage.removeItem(property);
    localStorage.removeItem(property);
  }
}
