// *计划查询状态
export enum EPlanFilterStatus {
   /// <summary>
   /// 未接收
   /// </summary>
   ToReceive = 10,
   /// <summary>
   /// 未提交
   /// </summary>
   ToSubmit = 20,
   /// <summary>
   /// 流程中
   /// </summary>
   Reviewing = 30,
   /// <summary>
   /// 已取消
   /// </summary>
   Cancelled = 40,
   /// <summary>
   /// 已完成
   /// </summary>
   Receipted = 50,
}
