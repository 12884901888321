// *工时填报日状态
export enum EWorktimeDayState {
    /// <summary>
    /// 正常
    /// </summary>
    Normal = 0,

    /// <summary>
    /// 补录（加班）
    /// </summary>
    AdditionalRecording = 1,

    /// </summary>
    /// 异常处理补发
    /// </summary>
    ExceptionResend = 2,

    /// </summary>
    /// 请假
    /// </summary>
    Leave = 4,

}
