import { Reducer, Action } from 'redux';
import {
  SetCcPersonsAction,
  AddCcPersonsAction,
  DeleteCcPersonAction,
  ClearCcPersonsAction,
  SET_CC_PERSONS,
  ADD_CC_PERSONS,
  DELETE_CC_PERSON,
  CLEAR_CC_PERSONS
} from '../actions/cc.actions';
import { createSelector } from 'reselect';
import { ICcPerson } from 'src/app/models';
import _ from 'lodash';

export interface CcState {
  persons: ICcPerson[];
}

const initialState: CcState = {
  persons: []
};

export const CcReducer: Reducer<CcState> =
  (state: CcState = initialState, action: Action): CcState => {
    let persons: ICcPerson[];
    switch (action.type) {
      case SET_CC_PERSONS:
        persons = (action as SetCcPersonsAction).persons;
        return Object.assign({}, state, {
          persons
        });
      case ADD_CC_PERSONS:
        persons = (action as AddCcPersonsAction).persons;
        return Object.assign({}, state, {
          persons: _.unionBy(state.persons, persons)
        });
      case DELETE_CC_PERSON:
        const person = (action as DeleteCcPersonAction).person;
        return Object.assign({}, state, {
          persons: _.reject(state.persons, { user_id: person.user_id })
        });
      case CLEAR_CC_PERSONS:
        return Object.assign({}, state, { persons: [] });
      default:
        return state;
    }
  };

const getState = (state: CcState): CcState => state;
export const getCcPersons = createSelector(
  getState,
  (state: CcState) => state.persons
);
