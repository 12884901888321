<div class="ez-document-layout">
    <div class="box">
        <div class="box-title">
            <div class="title">
                <i class="fa fa-bookmark mr-2"></i> <span class="text">{{systemDocuments?.description}}</span>
                <div class="file-upload" *ngIf="isSystemAdmin">
                    <button class="btn btn-primary btn-sm" ngfSelect multiple="true" [disabled]="isBusy"
                        [(files)]="files" (filesChange)="uploadFile($event, systemDocuments?.key)">
                        <i class="fa fa-upload mr-1"></i>上传
                    </button>
                </div>
            </div>
        </div>
        <div class="box-body">
            <ul class="content">
                <li *ngFor="let item of systemDocuments?.data.data" class="file" [title]="item.name">
                    <div class="file-name">
                        <i class="fa fa-circle mr-1"></i>
                        <a target="_blank" [href]="item.url">{{item.name}}
                        </a>
                    </div>
                    <div *ngIf="isSystemAdmin" (click)="toDeleteFile(item, systemDocuments?.key)">
                        <i class="fa fa-times fa-lg"></i>
                    </div>
                </li>
            </ul>
        </div>
    </div>
    <div class="box">
        <div class="box-title">
            <div class="title">
                <i class="fa fa-bookmark mr-2"></i><span class="text">{{trainingManuals?.description}}</span>
                <div class="file-upload" *ngIf="isSystemAdmin">
                    <button class="btn btn-primary btn-sm" ngfSelect multiple="true" [disabled]="isBusy"
                        [(files)]="files" (filesChange)="uploadFile($event, trainingManuals?.key)">
                        <i class="fa fa-upload mr-1"></i>上传
                    </button>
                </div>
            </div>
        </div>
        <div class="box-body">
            <ul class="content">
                <li *ngFor="let item of trainingManuals?.data.data" class="file" [title]="item.name">
                    <div class="file-name">
                        <i class="fa fa-circle mr-1"></i><a target="_blank" [href]="item.url">{{item.name}}</a>
                    </div>
                    <div *ngIf="isSystemAdmin" (click)="toDeleteFile(item, trainingManuals?.key)">
                        <i class="fa fa-times fa-lg"></i>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</div>