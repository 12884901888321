import { Reducer, Action } from 'redux';
import {
  SetDeliverablesItemsAction,
  SetDeliverablesLightEditEnableAction,
  SetDeliverablesFileEditEnableAction,
  SetDeliverablesOperateEnableAction,
  SetDeliverablesShowAllAction,
  UpdateDeliverablesItemAction,
  SetDeliverablesDirtyAction,
  SetDeliverablesIsLastVersionAction,
  SET_DELIVERABLES_ITEMS,
  SET_DELIVERABLES_LIGHT_EDIT_ENABLE,
  SET_DELIVERABLES_FILE_EDIT_ENABLE,
  SET_DELIVERABLES_OPERATE_ENABLE,
  SET_DELIVERABLES_SHOW_ALL,
  UPDATE_DELIVERABLES_ITEM,
  SET_DELIVERABLES_DIRTY,
  SET_DELIVERABLES_IS_LAST_VERSION
} from '../actions/deliverables.actions';
import { createSelector } from 'reselect';
import {
  IDeliverableItem
} from 'src/app/models';
import _ from 'lodash';

function verifyItem(item: IDeliverableItem): boolean {
  // TODO
  return true;
}

function verifyItems(items: IDeliverableItem[]): boolean {
  // TODO
  return true;
}

export interface DeliverablesState {
  items: IDeliverableItem[];
  lightEditEnable: boolean;
  fileEditEnable: boolean;
  operateEnable: boolean;
  showAll: boolean;
  valid: boolean;
  dirty: boolean;
  isLastVersion: boolean;  // 是否更新交付物
}

const initialState: DeliverablesState = {
  items: [],
  lightEditEnable: false,
  fileEditEnable: false,
  operateEnable: false,
  showAll: false,
  valid: false,
  dirty: false,
  isLastVersion: true
};

export const DeliverablesReducer: Reducer<DeliverablesState> =
  (state: DeliverablesState = initialState, action: Action): DeliverablesState => {
    let items: IDeliverableItem[] = [];
    let updateItem: IDeliverableItem;

    switch (action.type) {
      case SET_DELIVERABLES_ITEMS:
        items = (action as SetDeliverablesItemsAction).items;
        return Object.assign({}, state, {
          items,
          valid: verifyItems(items)
        });

      case SET_DELIVERABLES_LIGHT_EDIT_ENABLE:
        const lightEditEnable = (action as SetDeliverablesLightEditEnableAction).lightEditEnable;
        return Object.assign({}, state, {
          lightEditEnable
        });

      case SET_DELIVERABLES_FILE_EDIT_ENABLE:
        const fileEditEnable = (action as SetDeliverablesFileEditEnableAction).fileEditEnable;
        return Object.assign({}, state, {
          fileEditEnable
        });

      case SET_DELIVERABLES_OPERATE_ENABLE:
        const operateEnable = (action as SetDeliverablesOperateEnableAction).operateEnable;
        return Object.assign({}, state, {
          operateEnable
        });

      case SET_DELIVERABLES_SHOW_ALL:
        const showAll = (action as SetDeliverablesShowAllAction).showAll;
        return Object.assign({}, state, {
          showAll
        });

      case UPDATE_DELIVERABLES_ITEM:
        items = state.items;
        const item = (action as UpdateDeliverablesItemAction).item;
        updateItem = _.find(items, { code: item.code });
        updateItem = Object.assign({}, updateItem, item);
        return Object.assign({}, state, {
          items,
          valid: verifyItems(items),
          dirty: true
        });

      case SET_DELIVERABLES_DIRTY:
        const dirty = (action as SetDeliverablesDirtyAction).dirty;
        return Object.assign({}, state, {
          dirty
        });

      case SET_DELIVERABLES_IS_LAST_VERSION:
        const isLastVersion = (action as SetDeliverablesIsLastVersionAction).isLastVersion;
        return Object.assign({}, state, {
          isLastVersion
        });

      default:
        return state;
    }
  };

const getState = (state: DeliverablesState): DeliverablesState => state;

export const getDeliverablesItems = createSelector(
  getState,
  (state: DeliverablesState) => state.items
);

export const getDeliverablesLightEditEnable = createSelector(
  getState,
  (state: DeliverablesState) => state.lightEditEnable
);

export const getDeliverablesFileEditEnable = createSelector(
  getState,
  (state: DeliverablesState) => state.fileEditEnable
);

export const getDeliverablesOperateEnable = createSelector(
  getState,
  (state: DeliverablesState) => state.operateEnable
);

export const getDeliverablesShowAll = createSelector(
  getState,
  (state: DeliverablesState) => state.showAll
);

export const getDeliverablesValid = createSelector(
  getState,
  (state: DeliverablesState) => state.valid
);

export const getDeliverablesDirty = createSelector(
  getState,
  (state: DeliverablesState) => state.dirty
);

export const getDeliverablesIsLastVersion = createSelector(
  getState,
  (state: DeliverablesState) => state.isLastVersion
);
