// 审批流程模板的访问
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { SharedService } from '../services/shared.service';

@Injectable({
  providedIn: 'root',
})
export class ProcessTemplateGuard implements CanActivate {
  constructor(
    private router: Router,
    private userService: UserService,
    private sharedService: SharedService
  ) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    // 系统管理员
    if (this.userService.getCurrentIsSystemAdminSync()) {
      return true;
    } else if (this.userService.getCurrentIsSecondOrgTemplateAdminSync()) {
      // 二级标准组织模板管理员
      this.router.navigate(['/management-center/template/standard-organization']);
      return false;
    } else if (this.userService.getCurrentIsDivisionAdminSync()) {
      // 事业部管理员
      this.router.navigate(['/management-center/template/organization']);
      return false;
    } else if (this.userService.getCurrentIsCategoryAdminSync()) {
      // 细分类管理员
      this.router.navigate(['/management-center/template/plan']);
      return false;
    } else {
      this.router.navigate(['/work-center']);
      return false;
    }
  }
}

