// *计划类型
export enum EPlanType {
    /// <summary>
    /// 标准计划
    /// </summary>
    Standard = 0,

    /// <summary>
    /// 专项计划
    /// </summary>
    Spec = 10,

    /// <summary>
    /// 风险计划
    /// </summary>
    Risk = 20,

    // 节点（前端用于项目树形图展示）
    Milestone = 30,

    // 过程调度（前端用于项目树形图展示）
    ProcessSchedule = 40
}
