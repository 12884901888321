import _ from 'lodash';

import { Reducer, Action } from 'redux';
import {
  SHOW_CHAT,
  SET_CHAT_INFO,
  CONFIRM_CHAT,
  CANCEL_CHAT,
  ShowChatAction,
  SetChatInfoAction,
  ConfirmChatAction,
  CancelChatAction
} from '../actions/chat.actions';
import { createSelector } from 'reselect';
import { IChatInfo, IChatCallback } from 'src/app/models';

export interface ChatState {
  isShow: boolean;
  auditId: string;
  callback: IChatCallback;
  chatInfo: IChatInfo;
}

const initialState: ChatState = {
  isShow: false,
  auditId: undefined,
  callback: undefined,
  chatInfo: undefined
};

export const ChatReducer: Reducer<ChatState> =
  (state: ChatState = initialState, action: Action): ChatState => {
    switch (action.type) {
      case SHOW_CHAT:
        const callback = (action as ShowChatAction).callback;
        const auditId = (action as ShowChatAction).auditId;
        return Object.assign({}, state, {
          isShow: true,
          auditId,
          callback
        });

      case SET_CHAT_INFO:
        const chatInfo = (action as SetChatInfoAction).chatInfo;
        return Object.assign({}, state, {
          chatInfo
        });

      case CONFIRM_CHAT:
        if (state.callback) {
          state.callback();
        }
        return Object.assign({}, state, {
          isShow: false,
          auditId: undefined,
          callback: undefined,
          chatInfo: undefined
        });

      case CANCEL_CHAT:
        if (state.callback) {
          state.callback();
        }
        return Object.assign({}, state, {
          isShow: false,
          auditId: undefined,
          callback: undefined,
          chatInfo: undefined
        });

      default:
        return state;
    }
  };

const getState = (state: ChatState): ChatState => state;
export const getChatShow = createSelector(
  getState,
  (state: ChatState) => state.isShow
);

export const getChatAuditId = createSelector(
  getState,
  (state: ChatState) => state.auditId
);

export const getChatCallback = createSelector(
  getState,
  (state: ChatState) => state.callback
);

export const getChatInfo = createSelector(
  getState,
  (state: ChatState) => state.chatInfo
);
