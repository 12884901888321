// *工时预算推送范围管理
export enum EWorktimeForecastPermissionType {
  /// <summary>
  /// 个人
  /// </summary>
  User = 0,

  /// <summary>
  /// 项目组织角色
  /// </summary>
  OrganizationRole = 10,
}