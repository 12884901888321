import { Action, ActionCreator } from 'redux';
import { IUser, IDigitalDeliverableCallback } from 'src/app/models';

// #region 显示
export interface ShowDigitalDeliverableAction extends Action {
  callback: IDigitalDeliverableCallback;
  editEnable: boolean;
  data: JSON;
}

export const SHOW_DIGITAL_DELIVERABLE = 'SHOW_DIGITAL_DELIVERABLE';
export const showDigitalDeliverable: ActionCreator<ShowDigitalDeliverableAction> = (
  callback: IDigitalDeliverableCallback,
  editEnable: boolean,
  data?: JSON
) => ({
  type: SHOW_DIGITAL_DELIVERABLE,
  callback,
  editEnable,
  data
});
// #endregion

// #region 确定
// tslint:disable-next-line: no-empty-interface
export interface ConfirmDigitalDeliverableAction extends Action {
}

export const CONFIRM_DIGITAL_DELIVERABLE = 'CONFIRM_DIGITAL_DELIVERABLE';
export const confirmDigitalDeliverable: ActionCreator<ConfirmDigitalDeliverableAction> = () => ({
  type: CONFIRM_DIGITAL_DELIVERABLE
});
// #endregion

// #region 取消
// tslint:disable-next-line: no-empty-interface
export interface CancelDigitalDeliverableAction extends Action {
}

export const CANCEL_DIGITAL_DELIVERABLE = 'CANCEL_DIGITAL_DELIVERABLE';
export const cancelDigitalDeliverable: ActionCreator<CancelDigitalDeliverableAction> = () => ({
  type: CANCEL_DIGITAL_DELIVERABLE
});
// #endregion

// #region 设置数据化交付物的数据
export interface SetDigitalDeliverableDataAction extends Action {
  data: JSON;
}

export const SET_DIGITAL_DELIVERABLE_DATA = 'SET_DIGITAL_DELIVERABLE_DATA';
export const setDigitalDeliverableData: ActionCreator<SetDigitalDeliverableDataAction> = (data: JSON) => ({
  type: SET_DIGITAL_DELIVERABLE_DATA,
  data
});

// #endregion
