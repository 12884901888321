// 配置角色和人员-项目角色
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { SharedService } from '../services/shared.service';

@Injectable({
  providedIn: 'root',
})
export class PermissionProjectRoleGuard implements CanActivate {
  constructor(
    private router: Router,
    private userService: UserService,
    private sharedService: SharedService
  ) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    // 系统管理员
    if (this.userService.getCurrentIsSystemAdminSync()) {
      return true;
      // 需求管理工程师
    } else if (this.userService.getCurrentIsRequirementManagementEngineerSync()) {
      this.router.navigate(['/management-center/permission/requirement']);
      return false;
    } else {
      this.sharedService.setRedirectUrl(window.location.href);
      this.router.navigate(['/work-center']);
      return false;
    }
  }
}
