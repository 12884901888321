import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import _ from 'lodash';

import { SharedService } from './shared.service';
import { ITaskInfo, ITaskInfoParams, IReceive } from 'src/app/models';
import { EAuditType } from 'src/app/enums';
import { COUNT_THROTTLE_TIME } from '../consts';

@Injectable({
  providedIn: 'root'
})
export class TaskInfoService {
  private apiUrl = '/api/audits/tasks';

  // tslint:disable-next-line: variable-name
  private _taskInfosCount = 0;
  // tslint:disable-next-line: variable-name
  private _taskInfosParams: ITaskInfoParams;
  // tslint:disable-next-line: variable-name
  private _taskInfosTimeStamp: number;

  constructor(
    private http: HttpClient,
    private sharedService: SharedService
  ) { }

  getTaskInfos(params: ITaskInfoParams): Observable<ITaskInfo[]> {
    params.isLatest = true;
    return this.http.get<ITaskInfo[]>(`${this.apiUrl}`, { params: params as unknown as HttpParams }).pipe(
      catchError(this.sharedService.handleError<ITaskInfo[]>('get tasks', []))
    );
  }

  getTaskInfosCount(params: ITaskInfoParams, enforceNew?: boolean): Observable<number> {
    params.isLatest = true;

    // # 节流
    // tslint:disable-next-line: variable-name
    const _params = _.cloneDeep(params);
    delete _params.page;

    const timeStamp = new Date().getTime();
    const isTimeOut = this._taskInfosTimeStamp && timeStamp - this._taskInfosTimeStamp > COUNT_THROTTLE_TIME;

    // ！强制获取新数据 && ！超时 && 参数不变，返回之前获取的count
    if (!enforceNew && !isTimeOut && _.isEqual(this._taskInfosParams, _params)) {
      return of(this._taskInfosCount);
    }

    // 获取新count
    return this.http.get<number>(`${this.apiUrl}-count`, { params: params as unknown as HttpParams }).pipe(
      tap(count => {
        this._taskInfosCount = count;
        this._taskInfosParams = _.cloneDeep(_params);
        this._taskInfosTimeStamp = timeStamp;
      }),
      catchError(this.sharedService.handleError<number>('get tasks count', 0))
    );
  }

  // 获取新任务的audit_id（创建任务时需分配）
  getNewTaskAuditId(auditType: EAuditType): Observable<string> {
    const options = { // 强制返回string类型
      responseType: 'text' as 'json'
    };
    return this.http.get<string>(`${this.apiUrl}/${auditType}`, options).pipe(
      catchError(this.sharedService.handleError<string>('get new task auditId', ''))
    );
  }

  // 获取待接收任务
  getTodoReceives(): Observable<IReceive[]> {
    return this.http.get<IReceive[]>(`/api/audits/todos`).pipe(
      catchError(this.sharedService.handleError<IReceive[]>('get todo receives', []))
    );
  }

  // 接收待办任务
  receiveTask(): Observable<boolean> {
    return this.http.post<boolean>(`/api/audits/todos/check`, {}).pipe(
      catchError(this.sharedService.handleError<boolean>('receive', true))
    );
  }

}
