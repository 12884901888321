import { Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import _ from 'lodash';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { EDeliverableType } from 'src/app/enums';
import { IAchievementDeliverableContentItem, IAchievementTarget, IAlert, IConfigInfo, IDeliverableItem } from 'src/app/models';
import { AlertService } from 'src/app/services/alert.service';
import { PropertyConfigService } from '../../property-config/services/property-config.service';
import { ReduxService } from 'src/app/redux/redux.service';
import { forkJoin } from 'rxjs';
import { SubscriptionService } from 'src/app/services/subscription.service';
import { DeliverableService } from 'src/app/services/deliverable.service';

const NEW_ACHIEVEMENT_TARGET = {
  code: '',  // 成果编码
  name: '', // 成果名称
  type_code: '', // 成果类型编码
  type_name: '', // 成果类型名称
  deliverable: '', // 成果交付物内容
  tech_description: '', // 新技术成果描述
  level_code: '', // 定级建议编码
  level_name: '', // 定级建议名称
  is_droped: false, // 是否废弃
  ntp_detail_url: '', // 成果详情页
  _valid: false
}

@Component({
  selector: 'app-achievement-target',
  templateUrl: './achievement-target.component.html',
  styleUrls: ['./achievement-target.component.scss']
})
export class AchievementTargetComponent implements OnInit {
  modalRef: BsModalRef;
  items: IAchievementTarget[];
  editEnable: boolean;
  item: IDeliverableItem;
  types: IConfigInfo[];
  contents: IAchievementDeliverableContentItem[];
  suggestions: IConfigInfo[];
  projectCode: string;
  newAchievementTarget: IAchievementTarget = NEW_ACHIEVEMENT_TARGET;
  valid: boolean;
  fileEditEnable: boolean;
  @ViewChild('achievementTarget') modalEle: TemplateRef<any>;
  @Output()
  itemChange: EventEmitter<{ items: IAchievementTarget[], valid: boolean }> = new EventEmitter<{ items: IAchievementTarget[], valid: boolean }>();

  migrationTarget: IDeliverableItem; // 迁移目标
  showMigrationTargetLink: boolean;
  @Output()
  showMigrationTarget?: EventEmitter<IDeliverableItem> = new EventEmitter<IDeliverableItem>();

  constructor(
    private modalService: BsModalService,
    private alertService: AlertService,
    private propertyConfigService: PropertyConfigService,
    private reduxService: ReduxService,
    private subscriptionService: SubscriptionService,
    private deliverableService: DeliverableService
  ) { }

  ngOnInit() {
    this.reduxService.subscribeStore(() => this.readState());
    this.readState();
  }

  readState() {
    this.fileEditEnable = this.reduxService.getDeliverablesFileEditEnable();
  }

  initItems() {
    const report_data = _.find(this.item.items, { type: EDeliverableType.AchievementTarget })?.report_data;
    if (report_data?.items?.length) {
      this.items = report_data.items as IAchievementTarget[];
      if (this.fileEditEnable) {
        // 修正items：将type_name、level_name、deliverable更新为最新
        _.forEach(this.items, item => {
          item.type_name = _.find(this.types, { key: item.type_code })?.name;
          item.level_name = _.find(this.suggestions, { key: item.level_code })?.name;
          item.deliverable = _.find(this.contents, { ntp_type_code: item.type_code })?.content;
        });
      }
    } else {
      // 默认有一行
      this.items = [];
      this.items.push(_.cloneDeep(this.newAchievementTarget));
    }
  }

  show(item: IDeliverableItem, editEnable: boolean, projectCode: string, showMigrationTargetLink: boolean = false) {
    this.showMigrationTargetLink = showMigrationTargetLink;
    if (this.modalRef) {
      return;
    }
    this.init(item, editEnable, projectCode);
    this.modalRef = this.modalService.show(
      this.modalEle,
      {
        backdrop: false, // 不显示背景
        class: 'as-page'  // modal作为页面
      }
    );
  }

  init(item: IDeliverableItem, editEnable: boolean, projectCode: string) {
    this.item = _.cloneDeep(item);
    this.editEnable = editEnable;
    this.projectCode = projectCode;
    this.getMigrationTarget(projectCode);
    this.initConfigs();
  }

  initConfigs() {
    forkJoin([
      this.propertyConfigService.getAchievementTypesExcludeDisabled(),
      this.propertyConfigService.getAchievementLevelSuggestionsExcludeDisabled(),
      this.propertyConfigService.getAchievementDeliverableContentsList()
    ]).subscribe(results => {
      this.types = results[0];
      this.suggestions = results[1];
      this.contents = results[2];
      this.initItems();
      this.validChange();
    });
  }

  add() {
    this.items.push(_.cloneDeep(this.newAchievementTarget));
    this.validChange();
  }

  deleteItem(index: number) {
    this.items.splice(index, 1);
  }

  confirm() {
    if (!this.verifyConfigsValid(true)) {
      return;
    }
    this.itemChange.emit({ items: this.items, valid: this.valid });
    this.alert('提交成功');
    this.hide();
  }

  save() {
    this.itemChange.emit({ items: this.items, valid: this.valid && this.verifyConfigsValid() });
    this.alert('暂存成功');
  }

  alert(title: string) {
    const alert: IAlert = {
      type: 'success',
      title,
      content: []
    };
    this.alertService.addAlert(alert);
  }

  hide() {
    if (this.modalRef) {
      this.modalRef.hide();
      this.modalRef = undefined;
    }
  }

  validChange() {
    this.valid = !_.find(this.items, { _valid: false }) && !!this.items.length;
  }

  verifyConfigsValid(showAlert?: boolean) {
    let flag = true;
    const typesByKey = _.groupBy(this.types, 'key');
    const suggestionsByKey = _.groupBy(this.suggestions, 'key');
    _.forEach(this.items, item => {
      if (!item.is_droped && !typesByKey[item.type_code]) {
        if (showAlert) {
          const alert: IAlert = {
            type: 'danger',
            title: `技术成果名称为“${item.name}”的所属分类已废弃，请重新选择所属分类或废弃该成果目标`,
          };
          this.subscriptionService.publishAlert(alert);
        }
        flag = false;
        return;
      }
      // 非必填
      if (!item.is_droped && item.level_code && !suggestionsByKey[item.level_code]) {
        if (showAlert) {
          const alert: IAlert = {
            type: 'danger',
            title: `技术成果名称为“${item.name}”的定级建议已废弃，请重新选择定级建议或废弃该成果目标`,
          };
          this.subscriptionService.publishAlert(alert);
        }
        flag = false;
      }
    });
    return flag;
  }

  getMigrationTarget(project_code: string) {
    this.deliverableService.getDigitalDeliverableByType(project_code, EDeliverableType.MigrationTarget).subscribe(deliverable => {
      if (!deliverable?.data?.items?.length) {
        return;
      }
      this.migrationTarget = {
        items: [{
          type: EDeliverableType.MigrationTarget,
          report_data: {
            items: deliverable.data.items,
            values: deliverable.data.values
          }
        }]
      } as IDeliverableItem;
    });
  }

  viewMigrationTarget() {
    if (this.migrationTarget) {
      this.showMigrationTarget.emit(this.migrationTarget);
    }
  }
}
