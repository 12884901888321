// *项目状态
export enum EProjectStatus {
    /// <summary>
    /// 未启动：未编制组织计划
    /// </summary>
    Init = 0,

    /// <summary>
    /// 已暂停：项目暂停，可以重启
    /// </summary>
    Paused = 10,

    /// <summary>
    /// 已中止：项目完结，且不会再重启
    /// </summary>
    Stopped = 20,

    /// <summary>
    /// 开展中：首次编制计划且下发审批流程通过
    /// </summary>
    Running = 30,

    /// <summary>
    /// 已完结：最后一个节点过阀审批流程结束
    /// </summary>
    Done = 40
}
