<form *ngIf="showItemsFlag" [formGroup]="checkForm">
  <table class="table-sm table-bordered table-hover table-fixed text-center align-middle">
    <thead>
      <tr (click)="toggleCheckAll()">
        <th class="masked" style="width:40px;">
          <input type="checkbox" [checked]="isCheckedAll">
        </th>
        <th style="width: 60px;">序号</th>
        <th>标准组织节点名称</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of items; let i =index" (click)="toggleCheckItem(item)">
        <td class="masked">
          <input type="checkbox" [id]="item.id" [formControl]="checkForm.controls[item.id]">
        </td>
        <td>{{ i + 1}}</td>
        <td class="ellipsis text-left" [title]="item.name">{{item.name}}</td>
      </tr>
    </tbody>
  </table>
</form>
