import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import {
  IRoleInfo,
  IProjectManagementAdmin,
  IProjectManagementAdminParams,
  IAddProjectManagementAdminParams,
  IDeletedProjectManagementAdminParams,
  ICenterDirectorAdmin,
  IAddCenterDirectorAdminParams,
  ICenterDirectorAdminParams,
  IAlphaAdmin,
  IAlphaAdminParams,
  IAddAlphaAdminParams,
  IDeleteAlphaAdminParams,
  IWorkingHourAdmin,
  IWorkingHourAdminParams,
  IResponse,
  IGammaAdmin,
  IGammaAdminParams,
  ISetResponsiblePersonParams,
  IDeleteCenterDirectorAdminParams,
  ICenterUser,
  ICenterUserParams,
  IOrganizationUserParams,
  IOrganizationUser,
  IWorkTimeApproverParams,
  IWorkTimeApproverInfo,
  IAddWorkTimeApproverParams,
  IDeleteWorkTimeApproverParams,
  IKpiApprovalAdmin,
  IKpiApprovalAdminParams,
  IKpiReportViewer,
  IKpiReportViewerParams,
  ISecondOrgTemplateAdmin,
  ISecondOrgTemplateAdminParams,
  IAddSecondOrgTemplateAdminParams,
  IDeleteSecondOrgTemplateAdminParams,
  IWorktimeExportAdmin,
  IWorktimeExportAdminParams,
  IAddWorktimeExportAdminParams,
  IWorktimeExportOrganization,
  IWorktimeExportOrganizationParams,
  IAddWorktimeExportOrganizationParams
} from 'src/app/models';
import { HttpClient, HttpParams } from '@angular/common/http';
import { SharedService } from 'src/app/services/shared.service';
import { catchError, map } from 'rxjs/operators';
import { IAdminInfo, IAdminParams } from 'src/app/models/IUserInfo';
import _ from 'lodash';
import { IDeleteDivisionParams, IDivisionAdminParams } from 'src/app/models/IDivision';
import { DivisionService } from '../../division/services/division.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectRoleService {

  constructor(
    private http: HttpClient,
    private sharedService: SharedService,
    private divisionService: DivisionService,
  ) { }

  tabs: IRoleInfo[] = [{
    can_delete: false,
    description: '某事业部管理员',
    key: 'division-admin',
    name: '事业部管理员',
  }, {
    can_delete: false,
    description: '某事业部的项目细分类管理员',
    key: 'category-admin',
    name: '项目细分类管理员',
  }, {
    can_delete: false,
    description: '某事业部的某项目群管理员',
    key: 'project-group-admin',
    name: '项目群管理员',
  }];

  getTabs(): Observable<IRoleInfo[]> {
    return of(this.tabs);
  }

  // 管理员用户列表
  getAdmins(params: IAdminParams): Observable<IAdminInfo[]> {
    // tslint:disable-next-line:variable-name
    const _params = _.cloneDeep(this.sharedService.omitEmptyParams(params));
    delete _params.user_id;
    return this.http.get<IAdminInfo[]>(`/api/users/admins`, { params: _params as unknown as HttpParams }).pipe(
      map(items => {
        _.forEach(items, (item, index) => { item._rank = index; });
        return items;
      }),
      catchError(this.sharedService.handleError('get admins', [])),
    );
  }
  // 管理员用户总数
  getAdminsCount(params: IAdminParams): Observable<number> {
    // tslint:disable-next-line:variable-name
    const _params = _.cloneDeep(this.sharedService.omitEmptyParams(params));
    delete _params.page;
    delete _params.limit;
    delete _params.user_id;
    return this.http.get<number>(`api/users/admins-count`, { params: _params as unknown as HttpParams }).pipe(
      catchError(this.sharedService.handleError('get admins count', 0))
    );
  }
  // 删除事业部管理员
  deleteDivisionAdmin(params: IDeleteDivisionParams): Observable<IResponse> {
    return this.http.delete<IResponse>(`/api/divisions/${params.division_org_number}/admins/${params.user_id}`).pipe(
      catchError(this.sharedService.handleError('delete division admins', { success: false, errors: [] }))
    );
  }

  // 获取管理经理列表
  getProjectManagementAdmins(params: IProjectManagementAdminParams): Observable<IProjectManagementAdmin[]> {
    params = this.sharedService.omitEmptyParams(params);
    return this.http.get<IProjectManagementAdmin[]>
      (`/api/divisions/project-management/${params.division_org_number}/admins`, { params: params as unknown as HttpParams }).pipe(
        catchError(this.sharedService.handleError('get project management', []))
      );
  }

  // 获取管理经理列表总条数
  getProjectManagementAdminsCount(params: IProjectManagementAdminParams): Observable<number> {
    return this.http.get<number>
      (`/api/divisions/project-management-count/${params.division_org_number}/admins`, { params: params as unknown as HttpParams }).pipe(
        catchError(this.sharedService.handleError('get project management count', 0))
      );
  }

  // 添加管理经理
  addProjectManagementAdmin(params: IAddProjectManagementAdminParams): Observable<any> {
    return this.http.post<any>
      (`/api/divisions/project-management/${params.division_org_number}/${params.project_group_key}/admins/${params.user_id}`, params).pipe(
        catchError(this.sharedService.handleError('post project management', undefined))
      );
  }

  // 删除管理经理
  deleteProjectManagementAdmin(params: IDeletedProjectManagementAdminParams): Observable<IDeletedProjectManagementAdminParams[]> {
    return this.http.delete<IDeletedProjectManagementAdminParams[]>
      (`/api/divisions/project-management/${params.division_org_number}/${params.project_group_key}/admins/${params.user_id}`).pipe(
        catchError(this.sharedService.handleError('delete project management', []))
      );
  }

  // 获取中心主任列表
  getCenterDirectorAdmins(params: ICenterDirectorAdminParams): Observable<ICenterDirectorAdmin[]> {
    return this.http.get<ICenterDirectorAdmin[]>
      (`/api/divisions/center-director/${params.center_key}/admins`, { params: params as unknown as HttpParams }).pipe(
        catchError(this.sharedService.handleError('get center director', []))
      );
  }

  // 获取中心主任总条数
  getCenterDirectorAdminsCount(params: ICenterDirectorAdminParams): Observable<number> {
    return this.http.get<number>
      (`/api/divisions/center-director-count/${params.center_key}/admins`, { params: params as unknown as HttpParams }).pipe(
        catchError(this.sharedService.handleError('get center director count', 0))
      );
  }

  // 添加中心主任
  addCenterDirectorAdmin(params: IAddCenterDirectorAdminParams): Observable<IResponse> {
    return this.http.post<IResponse>
      (`/api/divisions/center-director/${params.center_key}/admins/${params.user_id}`, params).pipe(
        catchError(this.sharedService.handleError('post center director', { success: false, errors: [] }))
      );
  }

  // 删除中心主任
  deleteCenterDirectorAdmin(params: IDeleteCenterDirectorAdminParams): Observable<IResponse> {
    return this.http.delete<IResponse>
      (`/api/divisions/center-director/${params.center_key}/admins/${params.user_id}`).pipe(
        catchError(this.sharedService.handleError('delete center director', { success: false, errors: [] }))
      );
  }
  // 设置为负责人
  setResponsiblePerson(params: ISetResponsiblePersonParams): Observable<IResponse> {
    return this.http.put<IResponse>
      (`/api/divisions/center-director/${params.center_key}/admins/${params.user_id}`, params).pipe(
        catchError(this.sharedService.handleError('set center director', { success: false, errors: [] }))
      );
  }

  // 获取绩效α管理员列表
  // tslint:disable-next-line:variable-name
  getAlphaAdmins(_params: IAlphaAdminParams): Observable<IAlphaAdmin[]> {
    const params = this.sharedService.omitEmptyParams(_params);
    return this.http.get<IAlphaAdmin[]>
      (`/api/divisions/kpi-alpha-admin/admins`, { params: params as unknown as HttpParams }).pipe(
        catchError(this.sharedService.handleError('get alpha', []))
      );
  }

  // 获取绩效α管理员列表条数
  // tslint:disable-next-line:variable-name
  getAlphaAdminsCount(_params: IAlphaAdminParams): Observable<number> {
    const params = this.sharedService.omitEmptyParams(_params);
    return this.http.get<number>
      (`/api/divisions/kpi-alpha-admin-count/admins`, { params: params as unknown as HttpParams }).pipe(
        catchError(this.sharedService.handleError('get alpha count', 0))
      );
  }

  // 添加绩效α管理员
  addAlphaAdmin(params: IAddAlphaAdminParams): Observable<IResponse> {
    return this.http.post<IResponse>
      (`/api/divisions/kpi-alpha-admin/${params.division_org_number}/admins/${params.user_id}`, params).pipe(
        catchError(this.sharedService.handleError('post alpha', { success: false, errors: [] }))
      );
  }

  // 删除绩效α管理员
  deleteAlphaAdmin(params: IDeleteAlphaAdminParams): Observable<IResponse> {
    return this.http.delete<IResponse>
      (`/api/divisions/kpi-alpha-admin/${params.division_org_number}/admins/${params.user_id}`).pipe(
        catchError(this.sharedService.handleError('delete alpha', { success: false, errors: [] }))
      );
  }

  // 获取工时管理员列表
  getWorkingHourAdmins(params: IWorkingHourAdminParams): Observable<IWorkingHourAdmin[]> {
    return this.http.get<IWorkingHourAdmin[]>
      (`/api/divisions/working-hours-admin/admins`, { params: params as unknown as HttpParams }).pipe(
        catchError(this.sharedService.handleError('get working hour', []))
      );
  }

  // 获取工时管理员条数
  getWorkingHourAdminsCount(params: IWorkingHourAdminParams): Observable<number> {
    return this.http.get<number>
      (`/api/divisions/working-hours-admin-count/admins`).pipe(
        catchError(this.sharedService.handleError('get working hour count', 0))
      );
  }

  // 添加工时管理员
  addWorkingHourAdmin(userId: string): Observable<IResponse> {
    return this.http.post<IResponse>
      (`/api/divisions/working-hours-admin/admins/${userId}`, userId).pipe(
        catchError(this.sharedService.handleError('post working hour', { success: false, errors: [] }))
      );
  }

  // 删除工时管理员
  deleteWorkingHourAdmin(userId: string): Observable<IResponse> {
    return this.http.delete<IResponse>
      (`/api/divisions/working-hours-admin/admins/${userId}`).pipe(
        catchError(this.sharedService.handleError('delete working hour', { success: false, errors: [] }))
      );
  }

  // 获取γ管理员列表
  getGammaAdmins(params: IGammaAdminParams): Observable<IGammaAdmin[]> {
    return this.http.get<IGammaAdmin[]>
      (`/api/divisions/kpi-gamma-admin/admins`, { params: params as unknown as HttpParams }).pipe(
        catchError(this.sharedService.handleError('get gamma ', []))
      );
  }

  // 获取γ管理员条数
  getGammaAdminsCount(params: IGammaAdminParams): Observable<number> {
    return this.http.get<number>
      (`/api/divisions/kpi-gamma-admin-count/admins`, { params: params as unknown as HttpParams }).pipe(
        catchError(this.sharedService.handleError('get gamma count', 0))
      );
  }

  // 添加γ管理员
  addGammaAdmin(userId: string): Observable<IResponse> {
    return this.http.post<IResponse>
      (`/api/divisions/kpi-gamma-admin/admins/${userId}`, userId).pipe(
        catchError(this.sharedService.handleError('post gamma', { success: false, errors: [] }))
      );
  }

  // 删除γ管理员
  deleteGammaAdmin(userId: string): Observable<IResponse> {
    return this.http.delete<IResponse>
      (`/api/divisions/kpi-gamma-admin/admins/${userId}`).pipe(
        catchError(this.sharedService.handleError('delete gamma', { success: false, errors: [] }))
      );
  }

  // 设置管理员维护γ值
  setGammaPerson(userId: string): Observable<IResponse> {
    return this.http.put<IResponse>
      (`/api/divisions/kpi-gamma-admin/admins/${userId}`, '').pipe(
        catchError(this.sharedService.handleError('put gamma', { success: false, errors: [] }))
      );
  }

  // 获取中心下人员列表(已废弃)
  getCenterUsers(params: ICenterUserParams): Observable<ICenterUser[]> {
    return this.http.get<ICenterUser[]>
      (`/api/divisions/center-user/users`, { params: params as unknown as HttpParams }).pipe(
        catchError(this.sharedService.handleError('get center users ', []))
      );
  }

  // 获取中心下人员列表总数(已废弃)
  getCenterUsersCount(params: ICenterUserParams): Observable<number> {
    return this.http.get<number>
      (`/api/divisions/center-user/users-count`, { params: params as unknown as HttpParams }).pipe(
        catchError(this.sharedService.handleError('get center users count', 0))
      );
  }

  // 获取组织下人员列表
  getOrganizationUsers(params: IOrganizationUserParams): Observable<IOrganizationUser[]> {
    return this.http.get<ICenterUser[]>
      (`api/users/organizations/users`, { params: params as unknown as HttpParams }).pipe(
        catchError(this.sharedService.handleError('get organization users ', []))
      );
  }

  // 获取组织下人员列表总数
  getOrganizationUsersCount(params: IOrganizationUserParams): Observable<number> {
    return this.http.get<number>
      (`api/users/organizations/users-count`, { params: params as unknown as HttpParams }).pipe(
        catchError(this.sharedService.handleError('get organization users count', 0))
      );
  }

  // 获取工时审批员列表
  getWorkTimeApprovers(params: IWorkTimeApproverParams): Observable<IWorkTimeApproverInfo[]> {
    return this.http.get<IWorkTimeApproverInfo[]>('/api/worktime-users/audit/users', { params: params as unknown as HttpParams }).pipe(
      catchError(this.sharedService.handleError('get approver', []))
    );
  }

  // 获取工时审批员条数
  getWorkTimeApproversCount(params: IWorkTimeApproverParams): Observable<number> {
    return this.http.get<number>('/api/worktime-users/audit/users-count', { params: params as unknown as HttpParams }).pipe(
      catchError(this.sharedService.handleError('get approver count', 0))
    );
  }

  // 添加工时审批员
  addWorkTimeApprover(params: IAddWorkTimeApproverParams): Observable<IResponse> {
    return this.http.post<IResponse>('/api/worktime-users/audit/users', params).pipe(
      catchError(this.sharedService.handleError('add approver', { success: false, errors: [] }))
    );
  }

  // 删除工时管理员
  deleteWorkTimeApprover(params: IDeleteWorkTimeApproverParams): Observable<IResponse> {
    return this.http.delete<IResponse>('/api/worktime-users/audit/users', { params: params as unknown as HttpParams }).pipe(
      catchError(this.sharedService.handleError('delete approver', { success: false, errors: [] }))
    );
  }

  // 获取绩效报批管理员
  getKpiApprovalAdmins(params: IKpiApprovalAdminParams): Observable<IKpiApprovalAdmin[]> {
    return this.http.get<IKpiApprovalAdmin[]>
      ('/api/divisions/kpi-approval-admin/list', { params: params as unknown as HttpParams }).pipe(
        catchError(this.sharedService.handleError('get kpi approval admin ', []))
      );
  }

  // 获取绩效报批管理员总条数
  getKpiApprovalAdminsCount(params: IKpiApprovalAdminParams): Observable<number> {
    return this.http.get<number>
      ('/api/divisions/kpi-approval-admin/count', { params: params as unknown as HttpParams }).pipe(
        catchError(this.sharedService.handleError('get kpi approval admin count', 0))
      );
  }

  // 添加绩效报批管理员
  addKpiApprovalAdmin(userId: string): Observable<IResponse> {
    return this.http.post<IResponse>
      (`/api/divisions/kpi-approval-admin/add/${userId}`, userId).pipe(
        catchError(this.sharedService.handleError('post kpi approval admin', { success: false, errors: [] }))
      );
  }

  // 删除绩效报批管理员
  deleteKpiApprovalAdmin(userId: string): Observable<IResponse> {
    return this.http.delete<IResponse>
      (`/api/divisions/kpi-approval-admin/delete/${userId}`).pipe(
        catchError(this.sharedService.handleError('delete kpi approval admin', { success: false, errors: [] }))
      );
  }

  // 设置绩效报批管理员负责人
  setKpiApprovalAdmin(userId: string): Observable<IResponse> {
    return this.http.put<IResponse>
      (`/api/divisions/kpi-approval-admin/update/${userId}`, '').pipe(
        catchError(this.sharedService.handleError('put kpi approval admin', { success: false, errors: [] }))
      );
  }

  // 获取总院绩效管理员
  getKpiReportViewer(params: IKpiReportViewerParams): Observable<IKpiReportViewer[]> {
    return this.http.get<IKpiReportViewer[]>
      ('/api/divisions/kpi-report-viewer/list', { params: params as unknown as HttpParams }).pipe(
        catchError(this.sharedService.handleError('get kpi report viewer ', []))
      );
  }

  // 获取总院绩效管理员条数
  getKpiReportViewerCount(params: IKpiReportViewerParams): Observable<number> {
    return this.http.get<number>
      ('/api/divisions/kpi-report-viewer/count', { params: params as unknown as HttpParams }).pipe(
        catchError(this.sharedService.handleError('get kpi report viewer count', 0))
      );
  }

  // 添加总院绩效管理员
  addKpiReportViewer(userId: string): Observable<IResponse> {
    return this.http.post<IResponse>
      (`/api/divisions/kpi-report-viewer/${userId}`, userId).pipe(
        catchError(this.sharedService.handleError('post kpi report viewer', { success: false, errors: [] }))
      );
  }

  // 删除总院绩效管理员
  deleteKpiReportViewer(userId: string): Observable<IResponse> {
    return this.http.delete<IResponse>
      (`/api/divisions/kpi-report-viewer/${userId}`).pipe(
        catchError(this.sharedService.handleError('delete kpi report viewer', { success: false, errors: [] }))
      );
  }

  // 获取二级标准组织模板管理员
  getSecondOrgTemplateAdmin(params: ISecondOrgTemplateAdminParams): Observable<ISecondOrgTemplateAdmin[]> {
    return this.http.get<ISecondOrgTemplateAdmin[]>
      ('/api/divisions/second-org-template/admins', { params: params as unknown as HttpParams }).pipe(
        catchError(this.sharedService.handleError('get second org template admins ', []))
      );
  }
  // 二级标准组织模板管理员条数
  getSecondOrgTemplateAdminsCount(params: ISecondOrgTemplateAdminParams): Observable<number> {
    return this.http.get<number>
      ('/api/divisions/second-org-template/admins-count', { params: params as unknown as HttpParams }).pipe(
        catchError(this.sharedService.handleError('get second org template admins count ', 0))
      );
  }

  // 添加二级标准组织模板管理员
  addSecondOrgTemplateAdmin(params: IAddSecondOrgTemplateAdminParams): Observable<IResponse> {
    return this.http.post<IResponse>
      ('/api/divisions/second-org-template/admins/add', params).pipe(
        catchError(this.sharedService.handleError('post second org template admin', { success: false, errors: [] }))
      );
  }

  // 删除二级标准组织模板管理员
  deleteSecondOrgTemplateAdmin(params: IDeleteSecondOrgTemplateAdminParams): Observable<IResponse> {
    return this.http.delete<IResponse>
      (`/api/divisions/second-org-template/admins/delete/${params.id}`).pipe(
        catchError(this.sharedService.handleError('delete  second org template admin', { success: false, errors: [] }))
      );
  }

  // 获取工时导出管理员列表
  getWorktimeExportAdmins(params: IWorktimeExportAdminParams): Observable<IWorktimeExportAdmin[]> {
    return this.http.get<IWorktimeExportAdmin[]>
      ('/api/divisions/worktime-export/admins', { params: params as unknown as HttpParams }).pipe(
        catchError(this.sharedService.handleError('get work time export admins ', []))
      );
  }

  // 获取工时导出管理员列表条数
  getWorktimeExportAdminsCount(params: IWorktimeExportAdminParams): Observable<number> {
    return this.http.get<number>
      ('/api/divisions/worktime-export/admins-count').pipe(
        catchError(this.sharedService.handleError('get work time export admins ', 0))
      );
  }

  // 添加工时导出管理员
  addWorktimeExportAdmin(params: IAddWorktimeExportAdminParams): Observable<IResponse> {
    return this.http.post<IResponse>
      (`/api/divisions/worktime-export/admins/add`, params).pipe(
        catchError(this.sharedService.handleError('post work time export admin', { success: false, errors: [] }))
      );
  }

  // 删除工时导出管理员
  deleteWorktimeExportAdmin(userId: string): Observable<IResponse> {
    return this.http.delete<IResponse>
      (`/api/divisions/worktime-export/admins/delete/${userId}`).pipe(
        catchError(this.sharedService.handleError('delete work time export admin', { success: false, errors: [] }))
      );
  }

  // 获取工时导出组织列表
  getWorktimeExportOrganizations(params: IWorktimeExportOrganizationParams): Observable<IWorktimeExportOrganization[]> {
    return this.http.get<IWorktimeExportOrganization[]>
      ('/api/divisions/worktime-export/orgs', { params: params as unknown as HttpParams }).pipe(
        catchError(this.sharedService.handleError('get work time export organizations ', []))
      );
  }

  // 获取工时导出组织条数
  getWorktimeExportOrganizationsCount(params: IWorktimeExportOrganizationParams): Observable<number> {
    return this.http.get<number>
      ('/api/divisions/worktime-export/orgs-count', { params: params as unknown as HttpParams }).pipe(
        catchError(this.sharedService.handleError('get work time export organizations count', 0))
      );
  }

  // 添加工时导出组织
  addWorktimeExportOrganization(params: IAddWorktimeExportOrganizationParams): Observable<IResponse> {
    return this.http.post<IResponse>
      ('/api/divisions/worktime-export/orgs/add', params).pipe(
        catchError(this.sharedService.handleError('post work time export organization', { success: false, errors: [] }))
      );
  }

  // 删除工时导出组织
  deleteWorktimeExportOrganization(orgNumber: string): Observable<IResponse> {
    return this.http.delete<IResponse>
      (`/api/divisions/worktime-export/orgs/delete/${orgNumber}`).pipe(
        catchError(this.sharedService.handleError('delete work time export organization', { success: false, errors: [] }))
      );
  }


}
