import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, forkJoin, of } from 'rxjs';
import { catchError, switchMap, map, shareReplay } from 'rxjs/operators';
import _ from 'lodash';

import { EConfigType } from 'src/app/enums/EConfigType';
import { IAddOrUpdateConfigInfoCommand, IAllSystemModuleConfig, IAllSystemModuleConfigParams, ICommonConfig, IConfigInfo, IPermission, IPermissionItem, IResponse, ISystemModuleConfig, ISystemModuleConfigParams } from 'src/app/models';
import { SharedService } from './shared.service';
import { UserService } from './user.service';
import { EDeliverableType, EPermissionKind } from '../enums';
import { REQUIREMENT_MANAGEMENT_ENGINEER_ROLE_NAME, REQUIREMENT_TYPE_CUSTOMER_VOICE } from '../consts';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  constructor(
    private http: HttpClient,
    private sharedService: SharedService,
    private userService: UserService
  ) { }

  getNewCode(): Observable<string> {
    const options = { // 防止string型的response被直接转换成number，丢失精度
      responseType: 'text' as 'json'
    };

    return this.http.get<string>('/api/configs/new-code', options).pipe(
      catchError(this.sharedService.handleError('get new code', ''))
    );
  }

  getConfigs(type: EConfigType): Observable<IConfigInfo[]> {
    return this.http.get<IConfigInfo[]>(`/api/configs/${type}`);
  }

  addOrUpdateConfig(command: IAddOrUpdateConfigInfoCommand): Observable<IResponse> {
    return this.http.post<IResponse>(`/api/configs`, command).pipe(
      catchError(this.sharedService.handleError('add or update config', { success: false, errors: [] }))
    )
  }

  // 事业部
  getDivisions(): Observable<IConfigInfo[]> {
    return this.getConfigs(EConfigType.Division);
  }

  // 项目群
  getProjectGroups(): Observable<IConfigInfo[]> {
    return this.getConfigs(EConfigType.ProjectGroup);
  }

  // 项目级别
  getProjectLevels(): Observable<IConfigInfo[]> {
    return this.getConfigs(EConfigType.ProjectLevel);
  }

  // 项目大分类
  getProjectCatalogs(): Observable<IConfigInfo[]> {
    return this.getConfigs(EConfigType.ProjectCatalog);
  }

  // 项目细分类
  getProjectCategories(): Observable<IConfigInfo[]> {
    return this.getConfigs(EConfigType.ProjectCategory);
  }

  // 平台
  getPlatforms(): Observable<IConfigInfo[]> {
    return this.getConfigs(EConfigType.Platform);
  }

  // 平台分类
  getPlatformClasses(): Observable<IConfigInfo[]> {
    return this.getConfigs(EConfigType.PlatformClass);
  }

  // 新技术/规划/模块项目分类
  getNewTechPlanModuleCategories(): Observable<IConfigInfo[]> {
    return this.getConfigs(EConfigType.NewTechPlanModuleCategory);
  }

  // 新技术方向
  getNewTechDirections(): Observable<IConfigInfo[]> {
    return this.getConfigs(EConfigType.NewTechDirection);
  }

  // 新技术类别
  getNewTechClasses(): Observable<IConfigInfo[]> {
    return this.getConfigs(EConfigType.NewTechClass);
  }

  // 新技术应用产品线
  getNewTechLines(): Observable<IConfigInfo[]> {
    return this.getConfigs(EConfigType.NewTechLine);
  }

  // 新技术项目来源
  getNewTechSources(): Observable<IConfigInfo[]> {
    return this.getConfigs(EConfigType.NewTechSource);
  }

  // 项目参数文件
  getProjectParamFiles(): Observable<IConfigInfo[]> {
    return this.getConfigs(EConfigType.ProjectParamFile);
  }

  // 车型类别
  getVehicleModelTypes(): Observable<IConfigInfo[]> {
    return this.getConfigs(EConfigType.VehicleModelType);
  }

  // 管理主体
  getManagementHosts(): Observable<IConfigInfo[]> {
    return this.getConfigs(EConfigType.ManagementHost);
  }

  // 开发主体
  getDevelopmentHosts(): Observable<IConfigInfo[]> {
    return this.getConfigs(EConfigType.DevelopmentHost);
  }

  // 需求主体
  getDemandHosts(): Observable<IConfigInfo[]> {
    return this.getConfigs(EConfigType.DemandHost);
  }

  // 品牌
  getBrands(): Observable<IConfigInfo[]> {
    return this.getConfigs(EConfigType.Brand);
  }

  // 生产工厂
  getProductFactories(): Observable<IConfigInfo[]> {
    return this.getConfigs(EConfigType.ProductFactory);
  }

  // 产品线
  getProductLines(): Observable<IConfigInfo[]> {
    return this.getConfigs(EConfigType.ProductLine);
  }

  // 业务属性
  getBusinessAttributes(): Observable<IConfigInfo[]> {
    return this.getConfigs(EConfigType.BusinessAttribute);
  }

  // 项目类别
  getProjectTypes(): Observable<IConfigInfo[]> {
    return this.getConfigs(EConfigType.ProjectType);
  }

  // 高新领域
  getHighTechDomains(): Observable<IConfigInfo[]> {
    return this.getConfigs(EConfigType.HighTechDomain);
  }

  // 高新重点项目
  getHighTechKeyPointProjects(): Observable<IConfigInfo[]> {
    return this.getConfigs(EConfigType.HighTechKeyPointProject);
  }

  // 基础/扩展项目
  getScopes(): Observable<IConfigInfo[]> {
    return this.getConfigs(EConfigType.Scope);
  }

  // 排放标准
  getEmissionStandards(): Observable<IConfigInfo[]> {
    return this.getConfigs(EConfigType.EmissionStandard);
  }

  // 通用车/专用车
  getGeneralOrSpecials(): Observable<IConfigInfo[]> {
    return this.getConfigs(EConfigType.GeneralOrSpecial);
  }

  // 所属年度
  getBelongYears(): Observable<IConfigInfo[]> {
    return this.getConfigs(EConfigType.BelongYear);
  }

  // 业务
  getBusinesses(): Observable<IConfigInfo[]> {
    return this.getConfigs(EConfigType.Business);
  }

  // 轻量化
  getLightWeights(): Observable<IConfigInfo[]> {
    return this.getConfigs(EConfigType.LightWeight);
  }

  // NG
  getNGs(): Observable<IConfigInfo[]> {
    return this.getConfigs(EConfigType.NG);
  }

  // 负责单位
  getLeaders(): Observable<IConfigInfo[]> {
    return this.getConfigs(EConfigType.Leader);
  }

  // 新能源
  getNewEnergies(): Observable<IConfigInfo[]> {
    return this.getConfigs(EConfigType.NewEnergy);
  }

  // 模块系统
  getModuleSystems(): Observable<IConfigInfo[]> {
    return this.getConfigs(EConfigType.ModuleSystem);
  }

  // 模块分类
  getModuleClasses(): Observable<IConfigInfo[]> {
    return this.getConfigs(EConfigType.ModuleClass);
  }

  // 创建单位
  getCreateCompanies(): Observable<IConfigInfo[]> {
    return this.getConfigs(EConfigType.CreateCompany);
  }

  // 中心单位
  getCenterUnits(): Observable<IConfigInfo[]> {
    return this.getConfigs(EConfigType.Center);
  }

  // 项目阶段
  getProjectStages(): Observable<IConfigInfo[]> {
    return this.getConfigs(EConfigType.ProjectStage);
  }

  // 模块级别
  getModuleRanks(): Observable<IConfigInfo[]> {
    return this.getConfigs(EConfigType.ModuleRank);
  }

  // 集团管控
  getGroupControls(): Observable<IConfigInfo[]> {
    return this.getConfigs(EConfigType.GroupControl);
  }

  // 人力成本职级
  getUserCostLevels(): Observable<IConfigInfo[]> {
    return this.getConfigs(EConfigType.UserCostLevel);
  }

  // 人力成本子职级
  getUserCostChildLevels(): Observable<IConfigInfo[]> {
    return this.getConfigs(EConfigType.UserCostChildLevel);
  }

  // #region 需求配置

  // 需求业务类型
  getRequirementFields(): Observable<IConfigInfo[]> {
    return this.getConfigs(EConfigType.RequirementField);
  }

  // 需求适用区域
  getRequirementRegions(): Observable<IConfigInfo[]> {
    return this.getConfigs(EConfigType.RequirementRegion);
  }

  // 需求类型
  getRequirementTypes(): Observable<IConfigInfo[]> {
    return this.getConfigs(EConfigType.RequirementType);
  }

  // 需求类型（过滤掉客户声音）
  getRequirementTypesOmitCustomVoice(): Observable<IConfigInfo[]> {
    return this.getRequirementTypes().pipe(
      map(fields => _.reject(fields, f => f.key === REQUIREMENT_TYPE_CUSTOMER_VOICE.key))
    );
  }

  // 需求层级
  getRequirementLevels(): Observable<IConfigInfo[]> {
    return this.getConfigs(EConfigType.RequirementLevel);
  }

  // 需求团队
  getRequirementTeams(): Observable<IConfigInfo[]> {
    return this.getConfigs(EConfigType.RequirementTeam);
  }

  // 需求团队角色
  getRequirementTeamRoles(teamKey: string /* 需求团队key */): Observable<IConfigInfo[]> {
    return this.getConfigs(EConfigType.RequirementTeamRole).pipe(
      map(roles => {
        roles = _.reject(roles, { name: REQUIREMENT_MANAGEMENT_ENGINEER_ROLE_NAME });
        return _.filter(roles, item => {
          const teamKeys = JSON.parse(item.data);
          return _.includes(teamKeys, teamKey);
        });
      })
    );
  }

  // #endregion

  // 权限
  getPermissions(): Observable<IPermission[]> {
    return this.userService.getCurrentDivisionOrgNumber().pipe(
      switchMap(divisionOrgNumber => {
        return this.http.get<IPermission[]>(`/api/divisions/${divisionOrgNumber}/permissions`);
      }),
      catchError(this.sharedService.handleError(`get permissions`, []))
    );
  }

  // 权限项
  getPermissionItem(kind: EPermissionKind): Observable<IPermissionItem> {
    return this.http.get<IPermissionItem>(`/api/permission-options/${kind}`).pipe(
      catchError(this.sharedService.handleError<IPermissionItem>(`get permissionOptions`, undefined))
    );
  }

  // 获取权限内的 项目细分类列表
  getPermittedProjectCategory(): Observable<IConfigInfo[]> {
    return forkJoin([
      this.getPermissions(),
      this.getPermissionItem(EPermissionKind.StandardPlanTemplateMaintenance),
      this.getProjectCategories()
    ]).pipe(
      map(result => {
        const standardPlanTemplateMaintenance = _.find(result[0], { kind: EPermissionKind.StandardPlanTemplateMaintenance });
        if (!standardPlanTemplateMaintenance) {
          return [];
        }
        const standardPlanTemplateMaintenanceValue = standardPlanTemplateMaintenance.value;
        const standardPlanTemplateMaintenanceOptions = result[1].options;
        const projectCategories = result[2];
        const permittedProjectCategories = [];
        // 根据权限位，筛选出细分类列表
        _.forEach(projectCategories, c => {
          const option = _.find(standardPlanTemplateMaintenanceOptions, { name: c.name });
          // tslint:disable-next-line: no-bitwise
          if (option && (option.value & standardPlanTemplateMaintenanceValue) > 0) {
            permittedProjectCategories.push(c);
          }
        });
        return permittedProjectCategories;
      })
    );

  }

  private systemModuleConfigsRequests: {
    paramsString: string;   // 参数字符串
    request$: Observable<ISystemModuleConfig[]>    // 请求结果
  }[] = [];

  private requestSystemModuleConfigsObservable(params: ISystemModuleConfigParams, enforceNew?: boolean): Observable<ISystemModuleConfig[]> {
    params = this.sharedService.omitEmptyParams(params);
    const cachedRequest = _.find(this.systemModuleConfigsRequests, { paramsString: JSON.stringify(params) });
    if (cachedRequest && !enforceNew) {
      return cachedRequest.request$;
    } else {
      const request$ = this.http.get<ISystemModuleConfig[]>('/api/worktime-data/system-module', { params: params as unknown as HttpParams }).pipe(
        shareReplay(1),
        map(configs => _.cloneDeep(configs))
      );
      if (cachedRequest) {
        cachedRequest.request$ = request$;
      } else {
        this.systemModuleConfigsRequests.push({
          paramsString: JSON.stringify(params),
          request$: request$
        });
      }
      return request$;
    }
  }

  private allSystemModuleConfigsRequest: {
    paramsString: string;   // 参数字符串
    request$: Observable<IAllSystemModuleConfig[]>    // 请求结果
  };

  private requestAllSystemModuleConfigsObservable(params: IAllSystemModuleConfigParams, enforceNew?: boolean): Observable<IAllSystemModuleConfig[]> {
    params = this.sharedService.omitEmptyParams(params);
    if (this.allSystemModuleConfigsRequest
      && this.allSystemModuleConfigsRequest.paramsString === JSON.stringify(params)
      && !enforceNew) {
      const cachedRequest = this.allSystemModuleConfigsRequest;
      return cachedRequest.request$;
    } else {
      const request$ = this.http.get<IAllSystemModuleConfig[]>('/api/worktime-data/all-system-module', { params: params as unknown as HttpParams }).pipe(
        shareReplay(1),
        map(configs => _.cloneDeep(configs))
      );
      this.allSystemModuleConfigsRequest = {
        paramsString: JSON.stringify(params),
        request$: request$
      }
      return request$;
    }
  }

  // 系统模块配置
  getSystemModuleConfigs(params: ISystemModuleConfigParams, enforceNew?: boolean): Observable<ISystemModuleConfig[]> {
    return this.requestSystemModuleConfigsObservable(params, enforceNew).pipe(
      catchError(this.sharedService.handleError('get system module configs', []))
    );
  }

  getAllSystemModuleConfigs(params: IAllSystemModuleConfigParams, enforceNew?: boolean): Observable<IAllSystemModuleConfig[]> {
    return this.requestAllSystemModuleConfigsObservable(params, enforceNew).pipe(
      catchError(this.sharedService.handleError('get system module configs', []))
    );
  }

  getEnabledUserCostChildLevels(userCostLevel: string): Observable<IConfigInfo[]> {
    const params = { user_cost_level: userCostLevel };
    return this.http.get<IConfigInfo[]>(`/api/configs/worktime-cost/user-cost-children-level-enable`, { params: params as unknown as HttpParams }).pipe(
      catchError(this.sharedService.handleError('get enabled user cost levels', []))
    )
  }

  // 数据化交付物类型列表
  getDigitalDeliverableTypesConfigs(): Observable<ICommonConfig[]> {
    return this.http.get<ICommonConfig[]>(`/api/configs/deliverables/digital/types`).pipe(
      catchError(this.sharedService.handleError('get digital types configs', []))
    );
  }
} 
