import { Component, OnInit } from '@angular/core';
import _ from 'lodash';
import { INav, IUserInfo } from 'src/app/models';
import { SystemService } from 'src/app/services/system.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-management-center',
  templateUrl: './management-center.component.html',
  styleUrls: ['./management-center.component.scss']
})
export class ManagementCenterComponent implements OnInit {
  navs: INav[] = [];
  isSystemAdmin: boolean; // 系统管理员
  isDivisionAdmin: boolean; // 事业部管理员
  isCategoryAdmin: boolean; // 细分类管理员
  isProjectGroupAdmin: boolean; // 项目群管理员
  isOrganizationAdmin: boolean; // 组织管理员
  isProjectManagementAdmin: boolean; // 管理经理
  isProductEngineeringAdmin: boolean; // 产品工程经理
  isSecondOrgTemplateAdmin: boolean; // 二级标准组织模板管理员
  isKpiApprovalAdmin: boolean; // 绩效报批管理员
  isRequirementManagementEngineer: boolean; // 需求管理工程师
  hasPermissionToMaintainLaborCost: boolean; // 有权限维护人力成本
  currentUser: IUserInfo;

  constructor(
    private userService: UserService,
    private systemService: SystemService
  ) { }

  ngOnInit(): void {
    this.getCurrentUser();
  }

  getCurrentUser() {
    this.currentUser = this.userService.getCurrentUserSync();
    this.isSystemAdmin = this.userService.getCurrentIsSystemAdminSync();
    this.isDivisionAdmin = this.userService.getCurrentIsDivisionAdminSync();
    this.isCategoryAdmin = this.userService.getCurrentIsCategoryAdminSync();
    this.isProjectGroupAdmin = this.userService.getCurrentIsProjectGroupAdminSync();
    this.isOrganizationAdmin = this.userService.getCurrentIsOrganizationAdminSync();
    this.isProjectManagementAdmin = this.userService.getCurrentIsProjectManagementAdminSync();
    this.isProductEngineeringAdmin = this.userService.getCurrentIsProductEngineeringAdminSync();
    this.isSecondOrgTemplateAdmin = this.userService.getCurrentIsSecondOrgTemplateAdminSync();
    this.isKpiApprovalAdmin = this.userService.getCurrentIsKpiApprovalAdminSync();
    this.isRequirementManagementEngineer = this.userService.getCurrentIsRequirementManagementEngineerSync();

    this.userService.hasPermissionToMaintainLaborCost().subscribe(hasPermissionToMaintainLaborCost => {
      this.hasPermissionToMaintainLaborCost = hasPermissionToMaintainLaborCost;
      this.initNavs();
    })
  }

  initNavs() {
    // 系统管理员 || 管理经理
    if (this.isSystemAdmin || this.isProjectManagementAdmin) {
      this.navs = _.concat(this.navs, [{
        label: '系统管理员操作',
        routerLink: this.isSystemAdmin
          ? 'system-admin'
          : 'system-admin/management-admin-query',
      }]);
    }
    if (this.showPermission()) {
      this.navs = _.concat(this.navs, [{
        label: '配置角色和人员',
        routerLink: 'permission',
      }]);
    }
    if (this.isSystemAdmin || this.isOrganizationAdmin) {
      this.navs = _.concat(this.navs,[{
        label: '组织查阅管理',
        routerLink: 'organization-authorization',
      }]);
    }
    if (this.isSystemAdmin ||
      this.isDivisionAdmin ||
      this.isCategoryAdmin ||
      this.isKpiApprovalAdmin) {
      this.navs = _.concat(this.navs,[{
        label: '流程移交管理',
        routerLink: 'issue-transfer',
      }]);
    }
    if (this.isSystemAdmin) {
      this.navs = _.concat(this.navs,[{
        label: '项目信息下拉值管理',
        routerLink: 'project-info-dropdown',
      }]);
    }
    if (this.isDivisionAdmin) {
      this.navs = _.concat(this.navs,[{
        label: '项目细分类维护',
        routerLink: 'project-category',
      }]);
    }
    if (this.isCategoryAdmin) {
      this.navs = _.concat(this.navs,[{
        label: '项目群管理员维护',
        routerLink: 'project-group',
      }]);
    }
    if (this.isSystemAdmin ||
      this.isDivisionAdmin ||
      this.isCategoryAdmin ||
      this.isSecondOrgTemplateAdmin) {
      this.navs = _.concat(this.navs,[{
        label: '标准模板维护',
        routerLink: 'template',
      }]);
    }
    if (this.isSystemAdmin) {
      this.navs = _.concat(this.navs,[{
        label: '授权管理',
        routerLink: 'authorization',
      }]);
    }
    if (this.hasPermissionToMaintainLaborCost) {
      this.navs = _.concat(this.navs,[{
        label: '人力成本维护',
        routerLink: 'labor-cost',
      }]);
    }
    if (this.isSystemAdmin) {
      this.navs = _.concat(this.navs,[{
        label: '系统授权',
        routerLink: 'system-auth',
      }]);
    }
  }

  // 有权限查看“配置角色和人员”
  showPermission() {
    const system = this.systemService.getSystemSync();
    return system === 'PCMS' ? this.isSystemAdmin : this.isSystemAdmin || this.isRequirementManagementEngineer;
  }

}

