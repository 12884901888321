import {
  Component,
  TemplateRef,
  ViewChild,
  OnInit
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import _ from 'lodash';

import { IUserInfo, ICheckUsersCallback, IChatInfo, IWorkflowStep, IAlert } from 'src/app/models';
import { ReduxService } from 'src/app/redux/redux.service';
import { AlertService } from 'src/app/services/alert.service';
import { ChatService } from 'src/app/services/chat.service';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit {
  modalRef: BsModalRef;
  @ViewChild('chat') modalEle: TemplateRef<any>;

  myForm: FormGroup;
  auditId: string;
  chatPersons: IUserInfo[] = [];
  workflowCurrentStep: IWorkflowStep;

  constructor(
    private modalService: BsModalService,
    private reduxService: ReduxService,
    private alertService: AlertService,
    private chatService: ChatService,
    private fb: FormBuilder
  ) {
    this.reduxService.subscribeStore(() => this.readState());
    this.readState();
  }

  ngOnInit() {
    this.myForm = this.fb.group({
      message: ['', Validators.required]
    });
  }

  readState() {
    this.workflowCurrentStep = this.reduxService.getCurrentWorkflowStep();
    const isShow = this.reduxService.getChatShow();
    this.auditId = this.reduxService.getChatAuditId();

    if (isShow) {
      this.show();
    } else {
      this.hide();
    }
  }

  show() {
    if (!this.modalRef) {
      this.modalRef = this.modalService.show(this.modalEle, { class: 'modal-lg', ignoreBackdropClick: true });
    }
  }

  hide() {
    if (this.modalRef) {
      this.modalRef.hide();
      this.modalRef = undefined;
      this.reduxService.cancelChat();
      this.chatPersons = [];
      this.myForm.controls.message.setValue('');
    }
  }

  confirm() {
    // 验证被沟通人不是该节点的审批人
    const workflowCurrentStepPersonIds = _.map(this.workflowCurrentStep.persons, 'user_id');
    const chatPersonIds = _.map(this.chatPersons, 'user_id');
    if (_.intersection(workflowCurrentStepPersonIds, chatPersonIds).length) {
      const alert: IAlert = {
        type: 'danger',
        title: '被沟通人不能是该节点的审批人'
      };
      this.alertService.addAlert(alert);
      return;
    }

    const chatInfo: IChatInfo = {
      title: '沟通',
      at_user_ids: chatPersonIds,
      message: this.myForm.controls.message.value
    };
    this.chatService.createChat(this.auditId, chatInfo, this.workflowCurrentStep.persons).subscribe(result => {
      if (result) {
        const alert: IAlert = {
          type: 'success',
          title: '沟通成功'
        };
        this.alertService.addAlert(alert);
        this.chatPersons = [];
        this.myForm.controls.message.setValue('');
        // this.reduxService.setChatInfo(chatInfo);
        this.reduxService.confirmChat();
      }
    });
  }

  toCheckChatPersons() {
    this.reduxService.showCheckOrganizationUsers(this.checkChatPersons, true);

  }

  checkChatPersons: ICheckUsersCallback = (users: IUserInfo[]) => {
    this.chatPersons = _.concat(this.chatPersons, users);
  }

  deleteChatPerson(person: IUserInfo) {
    this.chatPersons = _.reject(this.chatPersons, person);
  }
}
