<ng-template #appCheckEnginFieldTree>
  <div class="modal-header">
    <h5 class="modal-title">所属业务列表</h5>
    <button type="button" class="close" (click)="hide()">
      <i class="fa fa-times"></i>
    </button>
  </div>
  <div class="modal-body">
    <div class="page">
      <div class="organization-tree-item" *ngFor="let item of items"
        [ngClass]="{isChecked: checkedItem && checkedItem.key===item.key}">
        <div *ngIf="item._is_show" class="content">
          <div class="row align-items-center no-gutters">
            <div class="col-auto mr-2" [ngStyle]="{'padding-left.px': item._level * 32}"
              (click)="toggleExpandItem(item);">
              <i class="fa fa-plus-square-o" *ngIf="item._has_children && !item._is_expand"></i>
              <i class="fa fa-minus-square-o" *ngIf="item._has_children && item._is_expand"></i>
              <i class="fa fa-file-o" *ngIf="!item._has_children"></i>
            </div>
            <!-- 名称 -->
            <div class="col" (click)="checkItem(item);">
              {{item.name}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-primary mr-2" (click)="confirm()" [disabled]="!checkedItem">
      确定
    </button>
    <button type="button" class="btn btn-outline-secondary" (click)="hide()">
      返回
    </button>
  </div>
</ng-template>
