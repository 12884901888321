<ng-template #dialog>
  <div class="modal-header">
    <h5 class="modal-title">{{title}}</h5>
  </div>
  <div class="modal-body">
    <ul class="content">
      <li class="content-item" *ngFor="let item of content">{{ item }}</li>
    </ul>
  </div>
  <div class="modal-footer">
    <div *ngIf="tip" class="tip" >
      {{tip}}
    </div>
    <button type="button" class="btn btn-primary mr-2" (click)="confirm()">
      确定
    </button>
    <button *ngIf="cancelDialogCallback" type="button" class="btn btn-outline-secondary ml-2" (click)="hide()">
      取消
    </button>
  </div>
</ng-template>
