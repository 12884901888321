import _ from 'lodash';

import { Reducer, Action } from 'redux';
import {
    SHOW_CHECK_ORGANIZATION_USERS,
    CONFIRM_CHECK_ORGANIZATION_USERS,
    CANCEL_CHECK_ORGANIZATION_USERS,
    SET_CHECK_ORGANIZATION_USERS_NEW_USERS,
    ShowCheckOrganizationUsersAction,
    SetCheckOrganizationUsersNewUsersAction
} from '../actions/check-organization-users.actions';
import { createSelector } from 'reselect';
import { IUserInfo, ICheckOrganizationUsersCallback } from 'src/app/models';
import { ECheckOrganizationUsersType } from 'src/app/enums';

export interface CheckOrganizationUsersState {
    isShow: boolean;
    isMultiple: boolean;
    callback: ICheckOrganizationUsersCallback;
    newUsers: IUserInfo[];
    checkOrganizationUsersType?: ECheckOrganizationUsersType;
}

const initialState: CheckOrganizationUsersState = {
    isShow: false,
    isMultiple: false,
    callback: undefined,
    newUsers: [],
    checkOrganizationUsersType: ECheckOrganizationUsersType.All
};

export const CheckOrganizationUsersReducer: Reducer<CheckOrganizationUsersState> =
    (state: CheckOrganizationUsersState = initialState, action: Action): CheckOrganizationUsersState => {
        switch (action.type) {
            case SHOW_CHECK_ORGANIZATION_USERS:
                const callback = (action as ShowCheckOrganizationUsersAction).callback;
                const isMultiple = (action as ShowCheckOrganizationUsersAction).isMultiple;
                // tslint:disable-next-line:max-line-length
                const checkOrganizationUsersType = (action as ShowCheckOrganizationUsersAction).checkOrganizationUsersType || ECheckOrganizationUsersType.All;

                return Object.assign({}, state, {
                    isShow: true,
                    callback,
                    isMultiple,
                    checkOrganizationUsersType
                });

            case SET_CHECK_ORGANIZATION_USERS_NEW_USERS:
                const newUsers = (action as SetCheckOrganizationUsersNewUsersAction).newUsers;
                return Object.assign({}, state, {
                    newUsers
                });

            case CONFIRM_CHECK_ORGANIZATION_USERS:
                if (state.callback) {
                    state.callback(state.newUsers);
                }
                return Object.assign({}, state, {
                    isShow: false,
                    callback: undefined,
                    newUsers: [],
                    checkOrganizationUsersType: ECheckOrganizationUsersType.All
                });

            case CANCEL_CHECK_ORGANIZATION_USERS:
                return Object.assign({}, state, {
                    isShow: false,
                    callback: undefined,
                    newUsers: [],
                    checkOrganizationUsersType: ECheckOrganizationUsersType.All

                });

            default:
                return state;
        }
    };

const getState = (state: CheckOrganizationUsersState): CheckOrganizationUsersState => state;
export const getCheckOrganizationUsersShow = createSelector(
    getState,
    (state: CheckOrganizationUsersState) => state.isShow
);

export const getCheckOrganizationUsersMultiple = createSelector(
    getState,
    (state: CheckOrganizationUsersState) => state.isMultiple
);

export const getCheckOrganizationUsersNewUsers = createSelector(
    getState,
    (state: CheckOrganizationUsersState) => state.newUsers
);

export const getCheckOrganizationUsersCallback = createSelector(
    getState,
    (state: CheckOrganizationUsersState) => state.callback
);

export const getCheckOrganizationUsersCheckUsersType = createSelector(
    getState,
    (state: CheckOrganizationUsersState) => state.checkOrganizationUsersType
);
