export enum EReceiptSystemType {
    /// <summary>
    /// pcms
    /// </summary>
    Pcms = 0,

    /// <summary>
    /// ep
    /// </summary>
    Ep = 10,

    /// <summary>
    /// 标签计划
    /// </summary>
    TagPlan = 20,
}
