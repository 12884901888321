import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpRequest, HttpResponse } from '@angular/common/http';
import { filter, map, catchError } from 'rxjs/operators';
import _ from 'lodash';

import { SharedService } from './shared.service';
import { IFileUploadInfo, IAlert } from 'src/app/models';
import { FILE_SIZE_LIMIT, FILE_URL_PREFIX } from 'src/app/consts';
import { AlertService } from './alert.service';
import { EZipDownloadType } from '../enums/EZipDownloadType';
import { IUpdateWorkflowAttachmentsCommand } from '../models/IUpload';
import { EAuditType } from '../enums';

@Injectable({
  providedIn: 'root'
})
export class FileService {
  apiUrl = '/api/files';

  constructor(
    private http: HttpClient,
    private sharedService: SharedService,
    private alertService: AlertService
  ) { }

  uploadFile(file: File, auditId?: string): Observable<IFileUploadInfo> {
    const myFormData: FormData = new FormData();
    myFormData.append('file', file, file.name);
    const config = auditId
      ? new HttpRequest(
        'POST',
        `/api/files/upload/${auditId}/requirement`,
        myFormData,
        {
          reportProgress: true,
        }
      )
      : new HttpRequest(
        'POST',
        `${this.apiUrl}/upload`,
        myFormData,
        {
          reportProgress: true,
        }
      );
    return this.http.request(config).pipe(
      filter(event => event instanceof HttpResponse),
      map(event => {
        if (event instanceof HttpResponse) {
          return event.body as string;
        }
      }),
      catchError(this.sharedService.handleError('upload file', undefined))
    );
  }

  // 验证文件大小
  verifyFileSize(
    file: File,
    sizeLimit: number = FILE_SIZE_LIMIT // 文件大小限制
  ) {
    let valid = true;
    if (file.size > sizeLimit * 1048576) { // byte. 1024 = 1k . 1048576 = 1mb
      const alert: IAlert = {
        type: 'danger',
        title: `「${file.name}」文件大小超过${sizeLimit}M`
      };
      this.alertService.addAlert(alert);
      valid = false;
    }
    return valid;
  }

  verifyFileSizes(files: File[], sizeLimit?: number) {
    let valid = true;
    _.forEach(files, file => {
      const fileSizeValid = this.verifyFileSize(file, sizeLimit);
      valid = valid && fileSizeValid;
      return fileSizeValid;
    });
    return valid;
  }

  // 获取打包下载地址
  getZipDownloadHref(params: {
    auditId: string;
    type: EZipDownloadType, // 打包下载的文件类型
    name: string; // 打包下载的文件名
    version?: number; // 项目版本号
    deliverables_version?: number; // 交付物版本号
  }): string {
    let href = `/api/files/archive/workflow/${params.auditId}/${params.type}`;
    const queryParams = this.sharedService.omitEmptyParams({
      name: params.name,
      version: params.version,
      deliverables_version: params.deliverables_version
    });
    // tslint:disable-next-line: variable-name
    const _queryParams = [];
    _.forEach(queryParams, (value, key) => {
      _queryParams.push(`${key}=${value}`);
    });
    const queryParamsString = _queryParams.join('&');
    if (queryParamsString) {
      href += `?${queryParamsString}`;
    }
    return href;
  }

  // 获取导出交付物明细链接
  getExportHref(params: {
    auditId: string;
    type: EAuditType;
    version?: number; // 项目版本号
    deliverableVersion?: number; // 交付物版本号
  }): string {
    let href = `/api/reporting/deliverables/${params.type}/${params.auditId}`;
    const queryParams = this.sharedService.omitEmptyParams({
      projectVersion: params.version,
      deliverableVersion: params.deliverableVersion,
    });
    // tslint:disable-next-line: variable-name
    const _queryParams = [];
    _.forEach(queryParams, (value, key) => {
      _queryParams.push(`${key}=${value}`);
    });
    const queryParamsString = _queryParams.join('&');
    if (queryParamsString) {
      href += `?${queryParamsString}`;
    }
    return href;
  }


  // 更新流程附件
  updateAttachments(auditId: string, updateWorkflowAttachmentsCommand: IUpdateWorkflowAttachmentsCommand): Observable<boolean> {
    return this.http.put<boolean>(`/api/audits/${auditId}/update-attachments`, updateWorkflowAttachmentsCommand).pipe(
      catchError(this.sharedService.handleError<any>('update workflow Attachments', false))
    );
  }

  // 文件是否可预览
  getCanPreview(file: IFileUploadInfo): boolean {
    var supportPreviewFileTypes = [
      'DOC',
      'DOCX',
      'ODT',
      'XLS',
      'XLSX',
      'ODS',
      'PPT',
      'PPTX',
      'ODP',
      'PDF',
      'PNG',
      'BMP',
      'JPG',
      'JPEG',
      'PSD',
      'GIF',
      'TXT',
    ];
    var fileType = file.name.split('.').pop();
    return fileType && supportPreviewFileTypes.includes(fileType.toUpperCase());
  }

  // 预览文件
  previewFile(file: IFileUploadInfo) {
    console.log('file: ', file)
    var url = `${file.url_path}?downloadName=${encodeURIComponent(file.name)}`;
    url = url.replace(this.apiUrl, '');
    url = `/ez-docs/preview?file=${url}`;
    console.log('preview:', url)
    window.open(url);
  }

  // 下载文件
  downloadFile(
    file: IFileUploadInfo, 
    plan_id?: string // 交付物附件下载传参plan_id
  ) {
    const url = plan_id 
    ? `${FILE_URL_PREFIX}${file.url_path}?plan_id=${plan_id}&downloadName=${encodeURIComponent(file.name)}`
    : `${FILE_URL_PREFIX}${file.url_path}?downloadName=${encodeURIComponent(file.name)}`;
    window.open(url);
  }
}
