// *抄送
import { Action, ActionCreator } from 'redux';
import { ICcPerson } from 'src/app/models';

// #region Set
export interface SetCcPersonsAction extends Action {
  persons: ICcPerson[];
}
export const SET_CC_PERSONS = 'SET_CC_PERSONS';
export const setCcPersons: ActionCreator<SetCcPersonsAction> = (persons: ICcPerson[]) => ({
  type: SET_CC_PERSONS,
  persons
});
// #endregion

// #region Add
export interface AddCcPersonsAction extends Action {
  persons: ICcPerson[];
}
export const ADD_CC_PERSONS = 'ADD_CC_PERSONS';
export const addCcPersons: ActionCreator<AddCcPersonsAction> = (persons: ICcPerson[]) => ({
  type: ADD_CC_PERSONS,
  persons
});
// #endregion

// #region Delete
export interface DeleteCcPersonAction extends Action {
  person: ICcPerson;
}
export const DELETE_CC_PERSON = 'DELETE_CC_PERSON';
export const deleteCcPerson: ActionCreator<DeleteCcPersonAction> = (person: ICcPerson) => ({
  type: DELETE_CC_PERSON,
  person
});
// #endregion

// #region Clear
// tslint:disable-next-line: no-empty-interface
export interface ClearCcPersonsAction extends Action {
}

export const CLEAR_CC_PERSONS = 'CLEAR_CC_PERSONS';
export const clearCcPersons: ActionCreator<ClearCcPersonsAction> = () => ({
  type: CLEAR_CC_PERSONS
});
// #endregion
