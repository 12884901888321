import { Injectable } from '@angular/core';
import _ from 'lodash';

import { IShowTreeItem, IFlatTreeItem } from 'src/app/models';
import { ROOT_PARENT_ID, NUMBER_PAD_LENGTH } from 'src/app/consts';
import { EPlanType } from 'src/app/enums';

// 按level分组
interface GroupByLevel<T> {
  [level: string]: T[];
}

// 按parent_id分组
interface GroupByParentId<U> {
  [parentId: string]: U[];
}

@Injectable({
  providedIn: 'root'
})
export class TreeService {
  constructor() {
  }

  // 按ID进行排序
  private sortItemsById<T extends IFlatTreeItem>(items: T[]): T[] {
    return _.sortBy(items, 'id');
  }

  // 按照计划类型（节点、标准、专项、风险、过程调度）进行排序
  private sortItemsByPlanType<T extends IFlatTreeItem>(items: T[]): T[] {
    const groupByPlanType = _.groupBy(items, 'plan_type');
    let sortedItems = [];
    if (groupByPlanType[EPlanType.Milestone]) {
      sortedItems = _.concat(sortedItems, groupByPlanType[EPlanType.Milestone]);
    }
    if (groupByPlanType[EPlanType.Standard]) {
      sortedItems = _.concat(sortedItems, groupByPlanType[EPlanType.Standard]);
    }
    if (groupByPlanType[EPlanType.Spec]) {
      sortedItems = _.concat(sortedItems, groupByPlanType[EPlanType.Spec]);
    }
    if (groupByPlanType[EPlanType.Risk]) {
      sortedItems = _.concat(sortedItems, groupByPlanType[EPlanType.Risk]);
    }
    if (groupByPlanType[EPlanType.ProcessSchedule]) {
      sortedItems = _.concat(sortedItems, groupByPlanType[EPlanType.ProcessSchedule]);
    }
    return sortedItems;
  }

  // 将 IFlatTreeItem[] 按 level 进行分组
  private buildGroupByLevel<T extends IFlatTreeItem>(flatTreeItems: T[]): GroupByLevel<T> {
    // parent_id为空的，都转为undefined（ROOT_PARENT_ID）
    _.forEach(flatTreeItems, item => {
      if (!item.parent_id) {
        item.parent_id = ROOT_PARENT_ID;
      }
    });
    // tslint:disable-next-line: variable-name
    function _buildGroupByLevel(parent_id: string, level: number) {
      const group: T[] = _.filter(flatTreeItems, item => parent_id === item.parent_id) as T[];
      if (group.length) {
        groupByLevel[level] = groupByLevel[level] ? _.concat(groupByLevel[level], group) : group;
        _.forEach(group, item => {
          _buildGroupByLevel(item.id, level + 1);
        });
      }
    }

    const groupByLevel: GroupByLevel<T> = {};
    _buildGroupByLevel(ROOT_PARENT_ID, 1);

    return groupByLevel;
  }

  // IFlatTreeItem[] -> 带有基本属性（id、parentId、level、data）的 IShowTreeItem[]
  private flatTree2showTreeWithBasicProperties<T extends IFlatTreeItem, U extends IShowTreeItem>(
    flatTreeItems: T[],
    groupByLevel: GroupByLevel<T>
  ): U[] {
    // 获取分支的级别（一级计划level为1，多级计划level依次+1）
    function getLevel(item: T): number {
      let level = 0;  // 非法level
      // tslint:disable-next-line: variable-name
      _.forEach(groupByLevel, (group, _level) => {
        if (-1 !== _.findIndex(group, flatTreeItem => flatTreeItem.id === item.id)) {
          level = +_level;
        }
      });
      return level;
    }

    const showTreeItems: U[] = [];
    _.forEach(flatTreeItems, (item) => {
      showTreeItems.push({
        id: item.id,
        parent_id: item.parent_id,
        level: getLevel(item),
        data: item
      } as U);
    });
    return showTreeItems;
  }

  // 分配 IShowTreeItem[] 的sn
  private assignShowTreeSn<U extends IShowTreeItem>(showTreeItems: U[]) {
    const maxLevel = _.max(_.map(showTreeItems, 'level'));
    // 将 IShowTreeItem[] 按 parent_id 进行分组
    for (let i = 1; i <= maxLevel; i++) {
      const subItemsOfLevel = _.filter(showTreeItems, item => i === item.level);
      if (!subItemsOfLevel.length) {
        break;
      }
      const groupByParentId: GroupByParentId<U> = _.groupBy(subItemsOfLevel, 'parent_id') as GroupByParentId<U>;
      this.assignGroupSn(showTreeItems, groupByParentId);
    }
  }

  // 分配 IShowTreeItem[] 的 group 的sn
  private assignGroupSn<U extends IShowTreeItem>(showTreeItems: U[], groupByParentId: GroupByParentId<U>) {
    _.forEach(groupByParentId, (group: IShowTreeItem[], parentId: string) => {
      const parentItem: IShowTreeItem = _.find(showTreeItems, item => parentId === item.id) as IShowTreeItem;
      this.assignItemSn(groupByParentId[parentId], parentItem);
    });
  }

  // 分配 IShowTreeItem[] 的 item 的sn
  private assignItemSn<U extends IShowTreeItem>(group: U[], parentItem: U) {
    // group = this.sortItemsByPlanType(group);
    _.forEach(group, (item: U, index: number) => {
      if (parentItem) {
        item.sn = parentItem.sn + '.' + (index + 1).toString();
      } else {
        item.sn = (index + 1).toString();
      }
    });
  }

  // 分配 IShowTreeItem[] 的交互属性
  private assignShowTreeInteractiveProperties<U extends IShowTreeItem>(showTreeItems: U[], foldedPlanIds: string[]) {
    _.forEach(showTreeItems, (showTreeItem: U) => {
      showTreeItem._hide = {};
      showTreeItem.is_folded = _.includes(foldedPlanIds, showTreeItem.id);

      // 通过所有 祖先节点 的is_folded，计算当前节点的 is_hide。
      // - 任意一个祖先节点折叠，则当前节点隐藏。（祖先节点.is_folded=false, 对应的 当前节点 _hide[祖先节点sn]=is_folded = true）
      showTreeItem.is_hide = () => {
        let isHide = false;
        _.forEach(showTreeItem._hide, (value) => {
          isHide = isHide || value;
        });
        return isHide;
      };
      showTreeItem.has_children = -1 !== _.findIndex(showTreeItems, item => item.parent_id === showTreeItem.id);
    });

    // 扫描 当前节点 的 后代节点（子、孙...），toggle当前节点的 is_folded，并为所有后代节点的 _hide增加 [当前节点sn]:is_folded
    // - 当前节点折叠(is_folded=true)， 则当前节点的所有后代节点的 _hide[当前节点sn]=true
    // - 当前节点展开(is_folded=false)， 则当前节点的所有后代节点的 _hide[当前节点sn]=false
    _.forEach(showTreeItems, (currentItem: U) => {
      _.forEach(showTreeItems, item => {
        if (item.sn.startsWith(currentItem.sn + '.')) { // 当前节点的后代节点
          item._hide[currentItem.sn] = currentItem.is_folded;  // 后代节点的_hide，添加 [当前节点sn]:当前节点is_folded
        }
      });
    });
  }

  // 按sn排序
  private sortItemsBySn<U extends IShowTreeItem>(showTreeItems: U[]): U[] {
    // 添加pad_sn属性，用于排序
    _.forEach(showTreeItems, item => {
      const snArray = item.sn.split('.');
      const padSnArray = _.map(snArray, sn => _.padStart(sn, NUMBER_PAD_LENGTH));
      item.pad_sn = padSnArray.join('.');
    });
    return _.sortBy(showTreeItems, 'pad_sn');
  }

  // 同级树列表 IFlatTreeItem[] -> 展示树节点列表 IShowTreeItem[]（展示计划树、计划模板树时使用，用户折叠展开等功能）
  flatTreeItems2showTreeItems<T extends IFlatTreeItem, U extends IShowTreeItem>(flatTreeItems: T[], foldedPlanIds: string[]): U[] {
    // 按ID进行排序
    // flatTreeItems = this.sortItemsById<T>(flatTreeItems);

    // 按照计划类型（节点、标准、专项、风险、过程调度）进行排序
    flatTreeItems = this.sortItemsByPlanType<T>(flatTreeItems);

    // 将 IFlatTreeItem[] 按 level 进行分组
    const groupByLevel: GroupByLevel<T> = this.buildGroupByLevel<T>(flatTreeItems);

    // IFlatTreeItem[] -> 带有基本属性（id、parentId、level、data）的 IShowTreeItem[]
    const showTreeItems = this.flatTree2showTreeWithBasicProperties<T, U>(flatTreeItems, groupByLevel);

    // 分配 IShowTreeItem[] 的sn
    this.assignShowTreeSn<U>(showTreeItems);

    // 分配 IShowTreeItem[] 的交互属性
    this.assignShowTreeInteractiveProperties<U>(showTreeItems, foldedPlanIds);

    // 按sn排序
    return this.sortItemsBySn(showTreeItems);
  }

}
