// 审批服务
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, switchMap, map, audit } from 'rxjs/operators';
import _ from 'lodash';

import {
  IAuditInfo,
  IAuditInfoSubmitUpdateCommand,
  IAuditInfoSaveUpdateCommand,
  IApprovalLog,
  IAuditInfoApproveUpdateCommand,
  IAuditInfoRejectUpdateCommand,
  IAuditInfoTransferUpdateCommand,
  IAuditInfoPersonsParams,
  IUserInfo,
  ITaskToTurnUsersParams,
  IResumeProjectCommand,
  IPauseProjectCommand,
  IStopProjectCommand,
  IAuditInfoSubmitCreateCommand,
  ICheckEpParams,
  TScheduleType,
  IScheduleCommand,
  IScheduleProjectCommand,
  IScheduleMilestoneCommand,
  ISchedulePlanCommand,
  IHttpError
} from 'src/app/models';
import { IAuditInfoSaveCreateCommand } from 'src/app/models';
import { SharedService } from './shared.service';
import { ConfigService } from './config.service';
import { TaskInfoService } from './task-info.service';
import { IAdminAuditTransferCommand } from 'src/app/models/IAuditInfo';
import { EHttpStatusCode } from 'src/app/enums';

@Injectable({
  providedIn: 'root'
})
export class AuditService {
  apiUrl = '/api/audits';

  constructor(
    private http: HttpClient,
    private sharedService: SharedService,
    private configService: ConfigService,
    private taskInfoService: TaskInfoService
  ) { }

  // 创建流程
  // post save
  // 返回新创建的任务id
  createAudit(auditInfoSaveCreateCommand: IAuditInfoSaveCreateCommand): Observable<string> {
    let auditId: string;
    return this.taskInfoService.getNewTaskAuditId(auditInfoSaveCreateCommand.audit_type).pipe(
      switchMap(taskAuditId => {
        auditId = taskAuditId;
        auditInfoSaveCreateCommand.id = taskAuditId;
        return this.http.post<any>(`${this.apiUrl}/save`, auditInfoSaveCreateCommand);
      }),
      catchError(this.sharedService.handleError<any>(`create audit`, undefined)),
      // tslint:disable-next-line:no-shadowed-variable
      map(_ => {
        // 成功返回auditId，失败返回undefined（bugfix：来自OA的任务，重复创建任务单，报412"节点下发单已存在，不能重复创建"）
        return _ ? auditId : undefined;
      })
    );
  }

  // 创建流程
  // post save
  // 返回HTTP状态码
  createAuditReturnHttpStatusCode(auditInfoSaveCreateCommand: IAuditInfoSaveCreateCommand): Observable<IHttpError> {
    let auditId: string;
    return this.taskInfoService.getNewTaskAuditId(auditInfoSaveCreateCommand.audit_type).pipe(
      switchMap(taskAuditId => {
        auditId = taskAuditId;
        auditInfoSaveCreateCommand.id = taskAuditId;
        return this.http.post<any>(`${this.apiUrl}/save`, auditInfoSaveCreateCommand);
      }),
      // tslint:disable-next-line:no-shadowed-variable
      map(() => {
        return {
          code: EHttpStatusCode.Ok,
          message: auditId
        };
      }),
      catchError(this.sharedService.handleErrorReturnHttpError(`create audit return http status`))
    );
  }

  // 创建流程
  // post save
  // 返回新创建的任务id
  createAuditForAlphaAndGamma(auditInfoSaveCreateCommand: IAuditInfoSaveCreateCommand): Observable<IHttpError> {
    let auditId: string;
    return this.taskInfoService.getNewTaskAuditId(auditInfoSaveCreateCommand.audit_type).pipe(
      switchMap(taskAuditId => {
        auditId = taskAuditId;
        auditInfoSaveCreateCommand.id = taskAuditId;
        return this.http.post<any>(`${this.apiUrl}/save`, auditInfoSaveCreateCommand);
      }),
      catchError(this.sharedService.handleErrorReturnHttpError(`create audit`)),
      // tslint:disable-next-line:no-shadowed-variable
      map(_ => {
        if (JSON.stringify(_) === '{}') {
          // 初次调用createAuditForAlphaAndGamma, 成功, 返回auditId
          const httpResponse: IHttpError = {
            code: EHttpStatusCode.Ok,
            message: auditId
          };
          return httpResponse;
        } else if (_.code === EHttpStatusCode.Redirect) {
          // 失败 && code === 302, 跳转到重定向地址
          return _;
        } else {
          // 失败 && code !== 302
          return undefined;
        }
      })
    );
  }

  // 创建流程
  // post save
  // 创建并暂存需求单
  createAndSaveRequirementAudit(auditInfoSaveCreateCommand: IAuditInfoSaveCreateCommand): Observable<string> {
    return this.http.post<any>(`${this.apiUrl}/save`, auditInfoSaveCreateCommand).pipe(
      catchError(this.sharedService.handleError<any>(`create and save requirement audit`, undefined))
    )
  }

  // 创建流程
  // post submit
  // 创建并提交需求单
  createAndSubmitRequirementAudit(auditInfoSubmitCreateCommand: IAuditInfoSaveCreateCommand): Observable<string> {
    return this.http.post<any>(`${this.apiUrl}/submit`, auditInfoSubmitCreateCommand).pipe(
      catchError(this.sharedService.handleError<any>(`create and submit requirement audit`, undefined))
    )
  }

  getAudit(id: string, version?: number): Observable<IAuditInfo> {
    const params = this.sharedService.omitEmptyParams({
      version
    }) as unknown as HttpParams;
    return this.http.get<IAuditInfo>(`${this.apiUrl}/${id}`, { params }).pipe(
      catchError(this.sharedService.handleError<IAuditInfo>(`get audit: ${id}`, undefined))
    );
  }

  // 创建并提交
  createAndSubmitAudit(auditInfoSubmitCreateCommand: IAuditInfoSubmitCreateCommand): Observable<string> {
    let auditId: string;
    return this.taskInfoService.getNewTaskAuditId(auditInfoSubmitCreateCommand.audit_type).pipe(
      switchMap(taskAuditId => {
        auditId = taskAuditId;
        auditInfoSubmitCreateCommand.id = taskAuditId;
        return this.http.post<any>(`${this.apiUrl}/submit`, auditInfoSubmitCreateCommand);
      }),
      catchError(this.sharedService.handleError<any>(`create and submit audit`, undefined)),
      // tslint:disable-next-line:no-shadowed-variable
      map(_ => {
        return auditId;
      })
    );
  }

  // 提交
  submitAudit(auditInfoSubmitUpdateCommand: IAuditInfoSubmitUpdateCommand): Observable<boolean> {
    return this.http.put<boolean>(`${this.apiUrl}/${auditInfoSubmitUpdateCommand.id}/submit`, auditInfoSubmitUpdateCommand).pipe(
      catchError(this.sharedService.handleError<any>(`submit audit: ${auditInfoSubmitUpdateCommand.id}`, false))
    );
  }

  // 提交（返回HTTP状态码）
  submitAuditReturnHttpStatusCode(auditInfoSubmitUpdateCommand: IAuditInfoSubmitUpdateCommand): Observable<IHttpError> {
    return this.http.put<boolean>(`${this.apiUrl}/${auditInfoSubmitUpdateCommand.id}/submit`, auditInfoSubmitUpdateCommand).pipe(
      map(() => {
        return {
          code: EHttpStatusCode.Ok,
          message: ''
        };
      }),
      catchError(this.sharedService.handleErrorReturnHttpError(`submit audit: ${auditInfoSubmitUpdateCommand.id}`))
    );
  }

  // 暂存
  saveAudit(auditInfoSaveUpdateCommand: IAuditInfoSaveUpdateCommand): Observable<boolean> {
    return this.http.put<boolean>(`${this.apiUrl}/${auditInfoSaveUpdateCommand.id}/save`, auditInfoSaveUpdateCommand).pipe(
      catchError(this.sharedService.handleError<any>(`save audit: ${auditInfoSaveUpdateCommand.id}`, false))
    );
  }

  // 作废
  dropAudit(id: string): Observable<boolean> {
    const body = {
      id
    };
    return this.http.put<boolean>(`${this.apiUrl}/${id}/drop`, body).pipe(
      catchError(this.sharedService.handleError<any>(`drop audit: ${id}`, false))
    );
  }

  // 撤回
  recallAudit(id: string): Observable<boolean> {
    const body = {
      id,
      remark: ''
    };
    return this.http.put<boolean>(`${this.apiUrl}/${id}/recall`, body).pipe(
      catchError(this.sharedService.handleError<any>(`recall audit: ${id}`, false))
    );
  }

  // 通过
  passAudit(command: IAuditInfoApproveUpdateCommand): Observable<boolean> {
    return this.http.put<boolean>(`${this.apiUrl}/${command.id}/approve`, command).pipe(
      catchError(this.sharedService.handleError<boolean>(`pass audit: ${command.id}`, false))
    );
  }

  // 驳回
  rejectAudit(command: IAuditInfoRejectUpdateCommand): Observable<boolean> {
    return this.http.put<boolean>(`${this.apiUrl}/${command.id}/reject`, command).pipe(
      catchError(this.sharedService.handleError<boolean>(`reject audit: ${command.id}`, false))
    );
  }

  // 转办
  turnAudit(command: IAuditInfoTransferUpdateCommand): Observable<boolean> {
    return this.http.put<boolean>(`${this.apiUrl}/${command.id}/transfer`, command).pipe(
      catchError(this.sharedService.handleError<boolean>(`turn audit: ${command.id}`, false))
    );
  }

  // (系统管理员)流程审批移交
  adminTransferAudit(command: IAdminAuditTransferCommand): Observable<boolean> {
    return this.http.put<boolean>(`/api/audits/admins/transfer/${command.id}`, command).pipe(
      catchError(this.sharedService.handleError<boolean>(`turn audit: ${command.id}`, false))
    );
  }

  // 获取审批历史
  getApprovalLogs(auditId: string, projectVersion: string): Observable<IApprovalLog[]> {
    const params = this.sharedService.omitEmptyParams({
      version: projectVersion
    });
    return this.http.get<IApprovalLog[]>(`${this.apiUrl}/${auditId}/logs`, { params }).pipe(
      map(approvalLogs => {
        // 处理链接形式的变更历史（节点变更、计划变更、交付物更新）
        _.forEach(approvalLogs, approvalLog => {
          if (_.startsWith(approvalLog.content, 'JDBG')) {
            approvalLog._link_titles = [approvalLog.remark || '查看节点变更详情'];
            approvalLog._link_urls = [`/project-center/detail/approval/${approvalLog.content}`];
          }
          if (_.startsWith(approvalLog.content, 'JHBG')) {
            approvalLog._link_titles = [approvalLog.remark || '查看计划变更详情'];
            approvalLog._link_urls = [`/project-center/detail/approval/${approvalLog.content}`];
          }
          if ('交付物更新' === approvalLog.operation) {
            approvalLog._link_titles = ['查看交付物更新详情'];
            approvalLog._link_urls = [`/project-center/detail/approval/${approvalLog.content}`];
          }
          // EP计划等（与服务端约定，remark、content中包含多条数据时，使用空格分隔开）
          if (_.startsWith(approvalLog.content, 'http://') || _.startsWith(approvalLog.content, 'https://') || _.startsWith(approvalLog.content, '/#/')) {
            approvalLog._is_ep = true;
            approvalLog._link_titles = approvalLog.remark ? approvalLog.remark.split(' ') : [];
            approvalLog._link_urls = approvalLog.content ? approvalLog.content.split(' ') : [];
          }
        });
        return approvalLogs;
      }),
      catchError(this.sharedService.handleError<IApprovalLog[]>(`get audit logs ${auditId}`, []))
    );
  }

  // (系统管理员)修改审批历史
  updateApprovalLog(id: number, content: string): Observable<boolean> {
    const approvalLogUpdateCommand = { content };
    return this.http.put<boolean>(`/api/audits/admins/update/log/${id}`, approvalLogUpdateCommand).pipe(
      catchError(this.sharedService.handleError<boolean>('update approval logs', false))
    );
  }

  // (系统管理员)删除审批历史
  deleteApprovalLog(id: number): Observable<boolean> {
    return this.http.delete<boolean>(`/api/audits/admins/drop/log/${id}`).pipe(
      catchError(this.sharedService.handleError<boolean>('delete approval log', false))
    );
  }

  // 获取下发单转办的项目群管理员列表
  getTaskToTurnUsers(params: ITaskToTurnUsersParams): Observable<IUserInfo[]> {
    return this.http.get<IUserInfo[]>(`${this.apiUrl}/tasks-to-turn-users`, { params: params as unknown as HttpParams }).pipe(
      catchError(this.sharedService.handleError<IUserInfo[]>('get tasks-to-turn-users', []))
    );
  }

  // 获取流程默认审批人
  getAuditInfoDefaultPersons(params: IAuditInfoPersonsParams): Observable<IUserInfo[]> {
    return this.http.get<IUserInfo[]>(`${this.apiUrl}/users/${params.type}`, { params: params as unknown as HttpParams }).pipe(
      catchError(this.sharedService.handleError<IUserInfo[]>(`get audit default persons`, []))
    );
  }

  // 计划回执校验
  verifyPlanReceipt(planId: string): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/check/plan/${planId}/receipt`).pipe(
      catchError(this.sharedService.handleError<any>(`check plan receipt`, ''))
    );
  }

  // 节点过阀校验（前置节点是否已过阀）
  verifyMilestoneReceipt(auditId: string): Observable<boolean> {
    return this.http.get<IHttpError>(`${this.apiUrl}/check/milestone/${auditId}/receipt`).pipe(
      map(() => true),
      catchError(this.sharedService.handleError<boolean>(`check milestone receipt`, false))
    );
  }

  // 获取项目状态审批记录
  getProjectStateHistories(projectCode: string, version?: number): Observable<IAuditInfo[]> {
    const params = this.sharedService.omitEmptyParams({
      version
    }) as unknown as HttpParams;
    return this.http.get<IAuditInfo[]>(`${this.apiUrl}/project/${projectCode}/history/states`, { params }).pipe(
      catchError(this.sharedService.handleError<IAuditInfo[]>(`get project state histories`, []))
    );
  }

  // 查询项目审批单审批进度详情
  getAuditProgress(projectCode: string, version?: number): Observable<IAuditInfo> {
    // tslint:disable-next-line: variable-name
    const _params = { version };
    const queryParams = this.sharedService.omitEmptyParams(_params);
    // tslint:disable-next-line: max-line-length
    return this.http.get<IAuditInfo>(`${this.apiUrl}/project/${projectCode}/audit/progress`, { params: queryParams as unknown as HttpParams });
  }

  // 判断是否EP计划（已废弃）
  checkIsEpPlan(params: ICheckEpParams): Observable<boolean> {
    params.parent_id = params.parent_id || '';
    return this.http.get<boolean>(`${this.apiUrl}/project/plan/check-ep`, {
      responseType: 'text' as 'json',
      params: params as unknown as HttpParams
    }).pipe(
      map(result => {
        return !!result;
      }),
      catchError(this.sharedService.handleError<boolean>(`check is EP`, false))
    );
  }

  // 创建过程调度
  createProcessSchedule(
    scheduleType: TScheduleType,
    relativeId: string, // 项目code、节点id、计划id
    scheduleCommand: IScheduleCommand
  ): Observable<string> {
    let command;
    switch (scheduleType) {
      case 'project':
        command = { ...scheduleCommand, project_code: relativeId } as IScheduleProjectCommand;
        break;
      case 'milestone':
        command = { ...scheduleCommand, milestone_id: relativeId } as IScheduleMilestoneCommand;
        break;
      case 'plan':
        command = { ...scheduleCommand, plan_id: relativeId } as ISchedulePlanCommand;
        break;
    }
    return this.http.post<string>(`${this.apiUrl}/schedule/${scheduleType}`, command).pipe(
      catchError(this.sharedService.handleError<string>(`create process schedule`, ''))
    );
  }

  // 查询项目是否在白名单配置
  checkProjectIsInWhitelist(projectCode: string): Observable<boolean> {
    return this.http.get<boolean>(`/api/audits/project/${projectCode}/check-project-whitelist`).pipe(
      catchError(this.sharedService.handleError('get is project whitelist', false))
    );
  }
}
