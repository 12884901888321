<ng-template #checkProject>
  <div class="modal-header">
    <h5 class="modal-title">
      项目列表<span style="font-size:14px;">{{tooltip}}</span>
    </h5>
    <button type="button" class="close" (click)="hide()">
      <i class="fa fa-times"></i>
    </button>
  </div>

  <div class="modal-body">
    <form class="mb-3" [formGroup]="filterForm" (ngSubmit)="searchProjects()">
      <div class="input-group">
        <input type="text" class="form-control" placeholder="项目名称、编码" formControlName="q">
        <div class="input-group-append">
          <button class="btn btn-primary">
            <i class="fa fa-search mr-1"></i>查询
          </button>
        </div>
      </div>
    </form>

    <form [formGroup]="radioForm">
      <table class="table-sm table-bordered table-hover text-center table-hover align-middle table-fixed">
        <thead>
          <tr>
            <th style="width:40px;"></th>
            <th style="width:60px;">序号</th>
            <th>项目名称</th>
            <th style="width:120px;">项目编码</th>
            <th style="width:100px;">项目管理员</th>
            <th style="width:90px;">管理经理</th>
            <th style="width:130px;">项目计划关闭时间</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of items; let i=index;" (click)="checkItem(item)">
            <td class="masked">
              <input type="radio" [id]="item.code" [value]="item.code" [formControl]="radio">
            </td>
            <td>{{i+1}}</td>
            <td class="ellipsis text-left" [title]="item.name">
              {{item.name}}
            </td>
            <td class="ellipsis text-left">{{item.code}}</td>
            <td>{{item.project_manager_name}}</td>
            <td class="ellipsis" [title]="item.project_management_admin || ''">{{item.project_management_admin}}
            </td>
            <td>
              {{item.target_end_on | dateFormatShort}}
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  </div>

  <div class="modal-footer row">
    <div class="confirmMessage col-12" *ngIf="confirmMessage">
      备注：{{confirmMessage}}
    </div>
    <button type="button" class="btn btn-primary mr-2" (click)="confirm()" [disabled]="!checkedItem">
      确定
    </button>
    <button type="button" class="btn btn-outline-secondary" (click)="hide()">
      返回
    </button>
  </div>
</ng-template>
