// *交付物计划类型
export enum EDeliverableCategory {
    /// <summary>
    /// 标准计划交付物
    /// </summary>
    Standard = 0,

    /// <summary>
    /// 专项计划交付物
    /// </summary>
    Spec = 10,
}
