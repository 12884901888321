/// <summary>
/// 组织节点类型（用于前端区分）
/// </summary>
export enum EOrganizationChartItemType {
    /// <summary>
    /// 标准节点
    /// </summary>
    Normal = 0,

    /// <summary>
    /// 工程师转成的节点
    /// </summary>
    Engineer = 10,

    /// <summary>
    /// 多功能小组转成节点
    /// </summary>
    MultifunctionalTeam = 20
}
