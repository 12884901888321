// *审批类型
export enum EAuditType {
    /// <summary>
    /// 项目信息下发审批
    /// </summary>
    ProjectInfoIssue = 10,

    /// <summary>
    /// 项目信息变更审批
    /// </summary>
    ProjectInfoUpdate = 11,

    /// <summary>
    /// 项目暂停审批
    /// </summary>
    ProjectToPause = 12,

    /// <summary>
    /// 项目终止审批
    /// </summary>
    ProjectToStop = 13,

    /// <summary>
    /// 项目重启审批
    /// </summary>
    ProjectToResume = 14,

    /// <summary>
    /// 项目过程调度
    /// </summary>
    ProjectProcessSchedule = 15,

    /// <summary>
    /// 项目过程调度接收
    /// </summary>
    ProjectProcessScheduleReceive = 16,

    /// <summary>
    /// 项目组织下发审批
    /// </summary>
    ProjectOrganizationIssue = 20,

    /// <summary>
    /// 项目组织变更审批
    /// </summary>
    ProjectOrganizationUpdate = 21,

    /// <summary>
    /// 项目组织接收
    /// </summary>
    ProjectOrganizationReceive = 22,


    /// <summary>
    /// 节点下发审批
    /// </summary>
    MilestoneIssue = 30,

    /// <summary>
    /// 节点变更审批
    /// </summary>
    MilestoneUpdate = 31,

    /// <summary>
    /// 节点过阀审批
    /// </summary>
    MilestoneReceipt = 32,

    /// <summary>
    /// 节点计划接收
    /// </summary>
    MilestoneReceive = 33,

    /// <summary>
    /// 节点过程调度
    /// </summary>
    MilestoneProcessSchedule = 35,

    /// <summary>
    /// 节点过程调度接收
    /// </summary>
    MilestoneProcessScheduleReceive = 36,

    /// <summary>
    /// 标准计划下发审批
    /// </summary>
    StandardPlanIssue = 40,

    /// <summary>
    /// 标准计划变更审批
    /// </summary>
    StandardPlanUpdate = 41,

    /// <summary>
    /// 标准计划回执审批
    /// </summary>
    StandardPlanReceipt = 42,

    /// <summary>
    /// 标准计划接收
    /// </summary>
    StandardPlanReceive = 43,

    /// <summary>
    /// 风险计划下发审批
    /// </summary>
    RiskPlanIssue = 50,

    /// <summary>
    /// 风险计划变更审批
    /// </summary>
    RiskPlanUpdate = 51,

    /// <summary>
    /// 风险计划回执审批
    /// </summary>
    RiskPlanReceipt = 52,

    /// <summary>
    /// 风险计划接收
    /// </summary>
    RiskPlanReceive = 53,

    /// <summary>
    /// 专项计划下发审批
    /// </summary>
    SpecialPlanIssue = 60,

    /// <summary>
    /// 专项计划变更审批
    /// </summary>
    SpecialPlanUpdate = 61,

    /// <summary>
    /// 专项计划回执审批
    /// </summary>
    SpecialPlanReceipt = 62,

    /// <summary>
    /// 专项计划接收
    /// </summary>
    SpecialPlanReceive = 63,

    /// <summary>
    /// 过程调度审批
    /// </summary>
    ProcessSchedule = 70,

    /// <summary>
    /// 交付物版本化审批
    /// </summary>
    AttachmentsVersioning = 80,

    /// <summary>
    /// 计划下发审批
    /// </summary>
    PlanIssue = 91,

    /// <summary>
    /// 计划变更审批
    /// </summary>
    PlanUpdate = 92,

    /// <summary>
    /// 计划回执
    /// </summary>
    PlanReceipt = 93,

    /// <summary>
    /// 计划接收
    /// </summary>
    PlanReceive = 94,

    /// <summary>
    /// 计划过程调度
    /// </summary>
    PlanProcessSchedule = 95,

    /// <summary>
    /// 计划过程调度接收
    /// </summary>
    PlanProcessScheduleReceive = 96,

    /// <summary>
    /// 流程沟通
    /// </summary>
    FlowChat = 100,

    /// <summary>
    /// 项目群绩效系数α值维护
    /// </summary>
    AlphaValueMaintain = 110,

    /// <summary>
    /// 主观评价β值维护
    /// </summary>
    BetaValueMaintain = 120,

    /// <summary>
    /// 主观评价β值变更
    /// </summary>
    BetaValueUpdateMaintain = 121,

    /// <summary>
    /// 宏观调控系数γ值维护
    /// </summary>
    GammaValueMaintain = 130,

    /// <summary>
    /// 项目精力占比维护
    /// </summary>
    ProjectEnergyRateMaintain = 140,

    /// <summary>
    /// 工时维护
    /// </summary>
    WorkingTimeMaintain = 150,

    /// <summary>
    /// 工时预算
    /// </summary>
    WorkingForecast = 155,

    /// <summary>
    /// 系统模块维护
    /// </summary>
    ModuleMaintain = 160,
    /// 绩效报批
    /// </summary>
    PerformanceApprovalMaintain = 170,

    /// <summary>
    /// 需求提报
    /// </summary>
    RequirementCreate = 1000,

    /// <summary>
    /// 需求分析
    /// </summary>
    RequirementAnalysis = 1010,

    /// <summary>
    /// 需求废止
    /// </summary>
    RequirementDrop = 1020,

    /// <summary>
    /// 需求关联
    /// </summary>
    RequirementConnect = 1030,

    /// <summary>
    /// 需求解关联
    /// </summary>
    RequirementDisConnect = 1040,
}
