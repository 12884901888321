import { Reducer, Action } from 'redux';
import { createSelector } from 'reselect';
import _ from 'lodash';

import {
  SetOrganizationChartItemsAction,
  SetOrganizationChartCurrentAllItemsAction,
  SetOrganizationChartPreviewAllItemsAction,
  SetOrganizationChartPathAction,
  SetOrganizationChartEditEnableAction,
  SetOrganizationChartDirtyAction,
  SetOrganizationChartViewTypeAction,
  CheckOrganizationChartItemAction,
  CheckOrganizationChartItemTransformedAction,
  ToggleCollapseOrganizationChartItemTransformedAction,
  ToggleCollapseAllOrganizationChartItemsAction,
  UpdateOrganizationChartItemAction,
  AddOrganizationChartItemsAction,
  DeleteOrganizationChartItemsAction,
  DeleteOrganizationChartOffspringItemsAction,
  AddTemplateOrganizationChartItemsAction,
  SetOrganizationTemplateOrgHashCodesAction,
  AddOrganizationTemplateOrgHashCodeAction,
  DeleteOrganizationTemplateOrgHashCodeAction,
  SetOrganizationChartNeedUpdateParentIdItemAction,
  SET_ORGANIZATION_CHART_ITEMS,
  SET_ORGANIZATION_CHART_CURRENT_ALL_ITEMS,
  SET_ORGANIZATION_CHART_PREVIEW_ALL_ITEMS,
  SET_ORGANIZATION_CHART_PATH,
  SET_ORGANIZATION_CHART_EDIT_ENABLE,
  SET_ORGANIZATION_CHART_DIRTY,
  SET_ORGANIZATION_CHART_VIEW_TYPE,
  CHECK_ORGANIZATION_CHART_ITEM,
  CHECK_ORGANIZATION_CHART_ITEM_TRANSFORMED,
  TOGGLE_COLLAPSE_ORGANIZATION_CHART_ITEM_TRANSFORMED,
  TOGGLE_COLLAPSE_ALL_ORGANIZATION_CHART_ITEMS,
  UPDATE_ORGANIZATION_CHART_ITEM,
  ADD_ORGANIZATION_CHART_ITEMS,
  ADD_STANDARD_ORGANIZATION_CHART_ITEMS,
  DELETE_ORGANIZATION_CHART_ITEMS,
  DELETE_ORGANIZATION_CHART_OFFSPRING_ITEMS,
  ADD_TEMPLATE_ORGANIZATION_CHART_ITEMS,
  SET_ORGANIZATION_TEMPLATE_ORG_HASH_CODES,
  ADD_ORGANIZATION_TEMPLATE_ORG_HASH_CODE,
  DELETE_ORGANIZATION_TEMPLATE_ORG_HASH_CODE,
  SET_ORGANIZATION_NEED_UPDATE_PARENT_ID_ITEM
} from '../actions/organization-chart.actions';
import {
  IOrganizationChartItem,
  IOrganizationChartLayer,
  IOrganizationChartGroup,
  IOrganizationTemplateOrgHashCode,
  TOrganizationChartContentType,
  IUserInfo
} from 'src/app/models';
import {
  ORGANIZATION_CHART_ROOT_NAME,
  ORGANIZATION_CHART_ROOT_PARENT_NODE_PATH,
  ORGANIZATION_ROOT_PARENT_ID,
  ORGANIZATION_CHART_STUB_PARENT_NODE_PATH_SEPARATOR,
  ORGANIZATION_CHART_PRODUCT_ENGINEERING_ADMIN_NODE_PATH,
  ORGANIZATION_CHART_MANAGEMENT_ADMIN
} from 'src/app/consts';
import { EOrganizationChartItemOperationType, EOrganizationChartItemType } from 'src/app/enums';
import {
  TOrganizationChartViewType,
  TOrganizationChartItemAddType,
  IOrganizationTeamItem,
  IOrganizationNode,
  IOrganizationPerson
} from 'src/app/models/IOrganizationChartItem';
import { OrganizationTransformService } from 'src/app/services/organization-transform.service';
const organizationTransformService = new OrganizationTransformService();
// #region 内部方法

interface GroupByParentId {
  [parentId: string]: IOrganizationChartGroup;
}

// 比较工程师是否变化前移除多余属性（将user_id与module_name作为唯一标识）
function removePropertyBeforeCompare(organizationTeams: IOrganizationTeamItem[]) {
  const newOrganizationTeams = [];
  _.forEach(organizationTeams, team => {
    newOrganizationTeams.push({
      user_id: team.user_id,
      module_name: team.module_name
    });
  });
  return newOrganizationTeams;
}

// 添加items，构成新组织树
function addItems(
  oldItems: IOrganizationChartItem[], // 原组织树的节点
  newItems: IOrganizationChartItem[],  // 新增的节点
  insertIndex?: number  // 插入位置（不传，则拼接到数组后面）
): IOrganizationChartItem[] {
  let cloneOldItems = _.cloneDeep(oldItems);
  const toAddItems: IOrganizationChartItem[] = [];
  _.forEach(newItems, newItem => {
    const existItem = _.find(cloneOldItems, {
      parent_node_path: newItem.parent_node_path,
      name: newItem.name
    });
    // 1. 原组织树上存在该节点
    if (existItem) {
      const _original_name = existItem.name;
      const _original_persons = _.cloneDeep(existItem.persons);
      switch (existItem.operation) {
        case EOrganizationChartItemOperationType.InUsing:
          // (不可能满足此条件)
          break;
        case EOrganizationChartItemOperationType.Add:
          // (不可能满足此条件)
          break;
        case EOrganizationChartItemOperationType.Delete:
          // 原节点是当前根节点的祖先节点（通过level比较），将原节点作为当前根节点的父节点
          const inUsingCloneOldItems = _.reject(cloneOldItems, { operation: EOrganizationChartItemOperationType.Delete });
          const oldMinLevel = _.min(_.map(inUsingCloneOldItems, 'level'));
          const oldRootItem = _.find(inUsingCloneOldItems, { level: oldMinLevel });
          if (existItem.level < oldMinLevel) {
            existItem.parent_id = ORGANIZATION_ROOT_PARENT_ID;
            oldRootItem.parent_id = existItem.id;
          }
          // 如果新增节点和原节点的主要属性一致，将原节点标记为”未变动“
          if (existItem.name === newItem.name
            && existItem.engin_field_key === newItem.engin_field_key
            && getIsNodeDataEqual(existItem.node_data, newItem.node_data)
            && _.isEqual(_.sortBy(_.map(existItem.persons, 'user_id')), _.sortBy(_.map(newItem.persons, 'user_id')))
            // tslint:disable-next-line:max-line-length
            &&
            _.isEqual(_.sortBy(removePropertyBeforeCompare(newItem.team_members), ['user_id', 'module_name']),
              _.sortBy(removePropertyBeforeCompare(existItem.team_members), ['user_id', 'module_name']))
            && _.isEqual(_.sortBy(existItem.team_member_roles, 'rank'), _.sortBy(newItem.team_member_roles, 'rank'))
          ) {
            existItem.operation = EOrganizationChartItemOperationType.InUsing;
            // 不一致，则使用新节点的属性（id除外）覆盖原节点，且将原节点标记为”变更“
          } else {
            existItem.engin_field_key = newItem.engin_field_key;
            existItem.engin_field_name = newItem.engin_field_name;
            existItem.node_data = newItem.node_data;
            existItem.persons = newItem.persons;
            existItem.template_org_id = newItem.template_org_id;
            existItem.template_org_node_id = newItem.template_org_node_id;
            existItem.team_members = newItem.team_members;
            existItem.team_member_roles = newItem.team_member_roles;
            existItem._original_name = _original_name;  // 存储原始名称
            existItem._original_persons = _original_persons;  // 存储原始persons
            existItem.operation = EOrganizationChartItemOperationType.Update;
          }
          break;
        case EOrganizationChartItemOperationType.Update:
          // (不可能满足此条件）
          break;
      }
      // 2. 原组织树上不存在该节点，增加新节点
    } else {
      newItem.operation = EOrganizationChartItemOperationType.Add;
      toAddItems.push(newItem);
    }
  });
  if (isNaN(insertIndex)) {
    cloneOldItems = _.concat(cloneOldItems, toAddItems);
  } else {
    cloneOldItems = [
      ...cloneOldItems.slice(0, insertIndex),
      ...toAddItems,
      ...cloneOldItems.slice(insertIndex)
    ];
  }
  _.forEach(cloneOldItems, (item, index) => {
    item.rank = index;
  });
  return cloneOldItems;
}

// 删除items，构成新组织树
function deleteItems(
  oldItems: IOrganizationChartItem[], // 原组织树的节点
  toDeleteItems: IOrganizationChartItem[] // 要删除的节点
): IOrganizationChartItem[] {
  let cloneOldItems = _.cloneDeep(oldItems);
  _.forEach(toDeleteItems, toDeleteItem => {
    const existItem = _.find(cloneOldItems, {
      parent_node_path: toDeleteItem.parent_node_path,
      name: toDeleteItem.name
    });
    // 1. 原组织树上存在该节点
    if (existItem) {
      let offsprings: IOrganizationChartItem[];
      let isDeleteRootAncestor: boolean;
      switch (existItem.operation) {
        case EOrganizationChartItemOperationType.InUsing:
          // 该节点是“项目经理”的某个祖先节点，则该节点的第一个不可删的后代节点作为根节点，生成一颗新的组织树，属于原组织树，但不属于新组织树的节点，都标记为”删除“
          isDeleteRootAncestor = deleteRootAncestor(cloneOldItems, existItem);
          // 该节点不是“项目经理”的某个祖先节点，则该节点及其后代节点都标记为”删除“
          if (!isDeleteRootAncestor) {
            offsprings = findOffsprings(cloneOldItems, existItem);
            existItem.operation = EOrganizationChartItemOperationType.Delete;
            _.forEach(offsprings, item => {
              item.operation = EOrganizationChartItemOperationType.Delete;
            });
          }
          break;
        case EOrganizationChartItemOperationType.Add:
          // 该节点是“项目经理”的某个祖先节点，则该节点的第一个不可删的后代节点作为根节点，生成一颗新的组织树，属于原组织树，但不属于新组织树的节点，都标记为”删除“
          isDeleteRootAncestor = deleteRootAncestor(cloneOldItems, existItem);
          // 该节点不是“项目经理”的某个祖先节点，则删除该节点及其后代节点
          if (!isDeleteRootAncestor) {
            offsprings = findOffsprings(cloneOldItems, existItem);
            cloneOldItems = _.differenceBy(
              cloneOldItems,
              [existItem, ...offsprings],
              ['parent_node_path', 'name']
            );
          }
          break;
        case EOrganizationChartItemOperationType.Delete:
          // (不可能满足此条件）
          break;
        case EOrganizationChartItemOperationType.Update:
          // 修正：因_original_name为null导致 被标记为“删除”的节点name与persons为空
          existItem.name = existItem._original_name ? existItem._original_name: existItem.name;  // 回滚到原始名称
          existItem.persons = existItem._original_persons ? existItem._original_persons: existItem.persons;  // 回滚到原始persons
          existItem.operation = EOrganizationChartItemOperationType.Delete;
          break;
      }
    }
    // 2. 原组织树上不存在该节点，不做处理
  });
  _.forEach(cloneOldItems, (item, index) => {
    item.rank = index;
  });
  return cloneOldItems;
}

// 删除根节点（项目经理）的某个祖先节点（以被删除节点为根而形成的分支，之外的节点中不存在不可删除的）
// - toDeleteItem的后代节点中存在不可删除的
// - 将被删节点的位于主树干上的子节点作为根节点，生成一颗新的组织树
// - 其他节点都标记为”删除“，其中“新增”后又“删除”的节点，直接删除该节点；
// (该方法会改变oldItems)
function deleteRootAncestor(
  oldItems: IOrganizationChartItem[], // 原组织树的节点
  toDeleteItem: IOrganizationChartItem, // 要删除的节点
): boolean /* 删除 */ {
  const offsprings = findOffsprings(oldItems, toDeleteItem);  // 后代节点
  const currentAndOffsprings = [toDeleteItem, ...offsprings];
  const treeWithoutCurrentBranch = _.differenceBy(oldItems, currentAndOffsprings, 'id');
  // 当前分支之外的节点中不存在不可删除的，则该节点及其祖先节点都标记为”删除“
  if (-1 === _.findIndex(treeWithoutCurrentBranch, ['node_data.disable_delete', true])) {
    // 将被删节点的位于主树干上的节点作为新的根节点
    const newRootItem = _.find(offsprings, (item) => item.parent_id === toDeleteItem.id && !_.includes(item.parent_node_path, '/@/'));
    newRootItem.parent_id = ORGANIZATION_ROOT_PARENT_ID; // 新的根节点
    const newTree = _.reject(offsprings, { parent_id: toDeleteItem.id }); // 新的组织树

    // 属于原组织树，但不属于新组织树的节点，都标记为”删除“；
    // 其中“新增”后又“删除”的节点，直接删除该节点；
    const addThenDeleteItemIds: string[] = [];  // “新增”后，又“删除”的节点的ids
    _.forEach(oldItems, item => {
      if (-1 === _.findIndex(newTree, { id: item.id })) {
        if (EOrganizationChartItemOperationType.Add === item.operation) {
          addThenDeleteItemIds.push(item.id);
        }
        item.operation = EOrganizationChartItemOperationType.Delete;
      }
    });
    _.remove(oldItems, item => _.includes(addThenDeleteItemIds, item.id));
    return true;
  } else {
    return false;
  }
}

// 祖先节点删除后代节点，后代节点中包含根节点（项目经理）
// 返回：以根节点（项目经理）为根的组织树保持不变，删除该树之外的其它节点
function deleteOffspringsIncludingRoot(
  oldItems: IOrganizationChartItem[], // 原组织树的节点
  toDeleteOffsprings: IOrganizationChartItem[], // 要删除的后代节点
): IOrganizationChartItem[] {
  oldItems = _.cloneDeep(oldItems);
  const newItems: IOrganizationChartItem[] = [];
  // 以根节点（项目经理）为根，形成新的组织树
  const newRootItem = _.find(toDeleteOffsprings, { parent_node_path: ORGANIZATION_CHART_ROOT_PARENT_NODE_PATH });
  const newOffsprings = findOffsprings(oldItems, newRootItem);
  const newTree = [newRootItem, ...newOffsprings];
  _.forEach(oldItems, item => {
    // 属于新组织树的节点
    if (-1 !== _.findIndex(newTree, { id: item.id })) {
      // 根节点（项目经理），更新parent_id
      if (ORGANIZATION_CHART_ROOT_PARENT_NODE_PATH === item.parent_node_path) {
        item.parent_id = ORGANIZATION_ROOT_PARENT_ID;
      }
      newItems.push(item);
      // 不属于新组织树的节点
    } else {
      switch (item.operation) {
        // “新增”的节点，直接删除
        case EOrganizationChartItemOperationType.Add:
          break;
        // “变更”、“删除”、“使用中”的节点，状态改为“删除”
        case EOrganizationChartItemOperationType.Update:
        case EOrganizationChartItemOperationType.Delete:
        case EOrganizationChartItemOperationType.InUsing:
          item.operation = EOrganizationChartItemOperationType.Delete;
          newItems.push(item);
          break;
      }
    }
  });
  return newItems;
}

// 变更items，构成新组织树
function updateItems(
  oldItems: IOrganizationChartItem[], // 原组织树的节点
  toUpdateItems: IOrganizationChartItem[] // 要变更的节点
): IOrganizationChartItem[] {
  let cloneOldItems = _.cloneDeep(oldItems);
  _.forEach(toUpdateItems, toUpdateItem => {
    let existItem = _.find(cloneOldItems, item => {
      return item.id === toUpdateItem.id; // 通过id查找原节点（如果name变更，则通过name和parent_node_path无法查找到原节点）
    });
    // 1. 原组织树上存在该节点
    if (existItem) {
      // 处理原计划树上存在 name、parent_node_path与被变更的节点相同，但是是被删除的节点
      cloneOldItems = handleExistItemDeleted(cloneOldItems, toUpdateItem);

      const isNameUpdate = existItem.name !== toUpdateItem.name;  // 节点名称发生变更
      const _original_name = existItem.name;
      const _original_persons = _.cloneDeep(existItem.persons);
      const isNodeDataUpdate = !getIsNodeDataEqual(existItem.node_data, toUpdateItem.node_data); // node_data发生变更
      let isPersonsUpdate = !_.isEqual( // persons发生变更
        _.sortBy(_.map(existItem.persons, 'user_id')),
        _.sortBy(_.map(toUpdateItem.persons, 'user_id'))
      );

      // _enable_edit保持不变
      toUpdateItem._enable_edit = existItem._enable_edit;

      existItem = Object.assign(existItem, toUpdateItem); // （assign保持引用不变）
      existItem.node_data = toUpdateItem.node_data;
      existItem.persons = toUpdateItem.persons;
      // 成员数量超出最大限制，则删除超出的
      if (existItem.persons && existItem.persons.length > +existItem.node_data.person_max_limit) {
        existItem.persons = _.take(existItem.persons, +existItem.node_data.person_max_limit);
        isPersonsUpdate = true;
      }
      // 根据“允许维护多功能小组”属性，更新多功能小组列表（变为false，则清除多功能小组）
      existItem.team_members = toUpdateItem.node_data.is_can_maintain_team ? toUpdateItem.team_members : [];

      // existItem.is_normal_update = isNodeDataUpdate || isPersonsUpdate; // 是否是自身属性（team_members之外的其他属性）发生变更
      existItem.is_normal_update = isNameUpdate || isPersonsUpdate; // 是否是自身属性发生变更（节点名称发生变更 || 成员发生变更），据此显示黄色
      // 更新operation
      switch (existItem.operation) {
        case EOrganizationChartItemOperationType.InUsing:
          existItem._original_name = _original_name;  // 存储原始名称
          existItem._original_persons = _original_persons;  // 存储原始persons
          existItem.operation = EOrganizationChartItemOperationType.Update;
          break;
        case EOrganizationChartItemOperationType.Add:
          break;
        case EOrganizationChartItemOperationType.Delete:
          existItem._original_name = _original_name;  // 存储原始名称
          existItem._original_persons = _original_persons;  // 存储原始persons
          existItem.operation = EOrganizationChartItemOperationType.Update;
          break;
        case EOrganizationChartItemOperationType.Update:
          break;
      }
    }
    // 2. 原组织树上不存在该节点，不做处理
  });
  _.forEach(cloneOldItems, (item, index) => {
    item.rank = index;
  });
  return cloneOldItems;
}

// 处理原计划树上存在 name、parent_node_path与被变更的节点相同，但是是被删除的节点
// （删除A，增加B，然后将B重命名为A；或者删除A，将B重命名为A。会存在这种情况）
function handleExistItemDeleted(cloneOldItems: IOrganizationChartItem[], toUpdateItem: IOrganizationChartItem): IOrganizationChartItem[] {
  // 原计划树上存在 name、parent_node_path与被变更的节点相同，但是是被删除的节点
  let existItemDeleted = _.find(cloneOldItems, item => {
    return item.name === toUpdateItem.name
      && item.parent_node_path === toUpdateItem.parent_node_path
      && EOrganizationChartItemOperationType.Delete === item.operation;
  });

  if (!existItemDeleted) {
    return cloneOldItems;
  }

  // bugfix：修正标记为“删除”的组织节点的成员展示变更后的成员，应展示变更前的组织成员
  const _original_persons = _.cloneDeep(existItemDeleted.persons);

  const isNameUpdate = false; // name是一样的
  let isPersonsUpdate = !_.isEqual( // persons发生变更
    _.sortBy(_.map(existItemDeleted.persons, 'user_id')),
    _.sortBy(_.map(toUpdateItem.persons, 'user_id'))
  );

  //#region 将B的属性赋值给A（id不变），标记为Update
  const toUpdateItemWithoutId = _.cloneDeep(toUpdateItem);
  delete toUpdateItemWithoutId.id;
  existItemDeleted = Object.assign(existItemDeleted, toUpdateItemWithoutId); // （assign保持引用不变）
  existItemDeleted.node_data = toUpdateItem.node_data;
  existItemDeleted.persons = toUpdateItem.persons;
  // 成员数量超出最大限制，则删除超出的
  if (existItemDeleted.persons && existItemDeleted.persons.length > +existItemDeleted.node_data.person_max_limit) {
    existItemDeleted.persons = _.take(existItemDeleted.persons, +existItemDeleted.node_data.person_max_limit);
    isPersonsUpdate = true;
  }
  // 根据“允许维护多功能小组”属性，更新多功能小组列表（变为false，则清除多功能小组）
  existItemDeleted.team_members = toUpdateItem.node_data.is_can_maintain_team ? toUpdateItem.team_members : [];
  existItemDeleted.is_normal_update = isNameUpdate || isPersonsUpdate; // 是否是自身属性发生变更（节点名称发生变更 || 成员发生变更），据此显示黄色
  // 标记为Update
  existItemDeleted._original_persons = _original_persons;  // 存储原始persons
  existItemDeleted.operation = EOrganizationChartItemOperationType.Update;
  //#endregion

  switch (toUpdateItem.operation) {
    // 原来有A。删除A，增加B，然后将B重命名为A（此时A的operation为Add）
    // 则将B的属性赋值给A（id不变），标记为Update，删除B
    case EOrganizationChartItemOperationType.Add:
      // 删除B
      cloneOldItems = _.reject(cloneOldItems, { id: toUpdateItem.id });
      break;

    // 原来有A、B。删除A，将B重命名为A（此时A的operation为InUsing、Update）
    // 则将B的属性赋值给A（id不变），标记为Update，将B标记为Delete
    case EOrganizationChartItemOperationType.InUsing:
    case EOrganizationChartItemOperationType.Update:
      toUpdateItem.operation = EOrganizationChartItemOperationType.Delete;
      break;
  }
  return cloneOldItems;
}

// 比较模板生成的节点列表相比之前组织树的变化，得出增、删、改的节点列表
function compareTemplateItemsWithCurrentTree(
  oldItems: IOrganizationChartItem[], // 原组织树的节点
  templateItems: IOrganizationChartItem[], // 由组织模板生成的组织节点列表
  parentNodePath: string,  // 由模板生成的节点的 parent_node_path
  currentUser: IUserInfo, // (处理：组织模板中，节点的"工程师角色"发生变更)
  isProjectInWhitelist: boolean // (处理：组织模板中，节点的"工程师角色"发生变更)
): {
  addItems: IOrganizationChartItem[];
  deleteItems: IOrganizationChartItem[];
  updateItems: IOrganizationChartItem[];
} {
  const itemGroups = {
    addItems: [],
    deleteItems: [],
    updateItems: []
  };
  _.forEach(templateItems, templateItem => {
    const existItem = _.find(oldItems, {
      parent_node_path: templateItem.parent_node_path,
      name: templateItem.name
    });
    // 1. 原组织树上存在该节点
    if (existItem) {
      const isTemplateEngineerRoleChanged = !_.isEqual( // 模板的"工程师角色"有变更
        _.sortBy(_.map(templateItem.team_member_roles, 'name')),
        _.sortBy(_.map(existItem.team_member_roles, 'name'))
      );

      // template_org_id、node_data属性有不一样的 || 模板的"工程师角色"有变更
      if (existItem.template_org_id !== templateItem.template_org_id
        || !getIsNodeDataEqual(existItem.node_data, templateItem.node_data)
        || isTemplateEngineerRoleChanged
      ) {
        // id，保持原节点的值
        templateItem.id = existItem.id;
        // 原节点的状态!==“删除”，则新节点的persons、team_members取原节点的
        if (EOrganizationChartItemOperationType.Delete !== existItem.operation) {
          templateItem.persons = existItem.persons;
          templateItem.team_members = existItem.team_members;
        }
        // 成员数量超出最大限制，则删除超出的
        if (templateItem.persons && templateItem.persons.length > +templateItem.node_data.person_max_limit) {
          templateItem.persons = _.take(templateItem.persons, +templateItem.node_data.person_max_limit);
        }

        // 处理：组织模板中，节点的"工程师角色"发生变更（仅当前用户是PMT经理时，清空工程师角色名称）
        const isCanEditEngineer = getIsCanEditEngineer({
          persons: templateItem.persons,
          currentUser,
        });
        if (isTemplateEngineerRoleChanged && isCanEditEngineer) {
          handleOrganizationTemplateTeamMemberRoleChanged(templateItem);
        }

        // 将节点添加到“变更”列表
        itemGroups.updateItems.push(templateItem);
      }
      // 2. 原组织树上不存在该节点，将节点添加到“新增”列表
    } else {
      itemGroups.addItems.push(templateItem);
    }
  });

  // 3. 原兄弟节点中有该节点，但新节点列表中没有该节点
  // 原组织树上，这个分支上的兄弟节点
  const oldBrotherItems = _.filter(oldItems, item => {
    return item.parent_node_path === parentNodePath
      && EOrganizationChartItemOperationType.Delete !== item.operation;
  });
  const oldBrotherDeleteItems = _.differenceBy(oldBrotherItems, templateItems, 'name');
  _.forEach(oldBrotherDeleteItems, deleteItem => {
    // 原节点没有子节点 && 没有成员 && 没有团队成员
    if (!deleteItem._children_count
      && !(deleteItem.persons && deleteItem.persons.length)
      && !(deleteItem.team_members && deleteItem.team_members.length)
    ) {
      // 将原节点添加到“删除”列表;
      itemGroups.deleteItems.push(deleteItem);
      // 否则，如果原节点不可删或不可改名
    } else if (deleteItem.node_data && (deleteItem.node_data.disable_delete || deleteItem.node_data.disable_edit_name)) {
      // 将原节点添加到”变更“列表，且修改其属性为可删除、可编辑名称，移除template_org_id、template_org_node_id属性;;
      deleteItem.disable_delete = false;  // TODO POP 调整属性后，移除
      deleteItem.disable_edit_name = false; // TODO POP 调整属性后，移除
      if (deleteItem.node_data) {
        deleteItem.node_data.disable_delete = false;
        deleteItem.node_data.disable_edit_name = false;
      }
      delete deleteItem.template_org_id;
      delete deleteItem.template_org_node_id;
      itemGroups.updateItems.push(deleteItem);
    }
  });

  return itemGroups;
}

// 可以维护工程师（管理经理不再维护工程师）
// (PMT经理 节点下成员中有当前用户)
// 修正：管理经理调整PMT经理时，因为组织模板变更，将不符合的工程师角色删除，导致提交的单子中工程师角色为空
function getIsCanEditEngineer(
  { persons, currentUser }: {
    persons: IOrganizationPerson[]; currentUser: IUserInfo;
  }): boolean {
  if (persons?.length) {
    return _.includes(_.map(persons, 'user_id'), currentUser?.user_id);
  } else {
    return false;
  }
}

// 处理：组织模板变更，节点的"工程师角色"发生变更
// (如果原"工程师角色"被删除，则将使用该"工程师角色"的工程师节点的name清空)
function handleOrganizationTemplateTeamMemberRoleChanged(
  changedOrganizationItem: IOrganizationChartItem // 根据模板更新过属性的组织节点
) {
  _.forEach(changedOrganizationItem.team_members, teamMember => {
    if (!_.includes(_.map(changedOrganizationItem.team_member_roles, 'name'), teamMember.module_name)) {
      teamMember.module_name = '';
    }
  });
}

// 比较node_data是否一样
function getIsNodeDataEqual(xNodeData: IOrganizationNode, yNodeData: IOrganizationNode): boolean {
  xNodeData = _.cloneDeep(xNodeData);
  yNodeData = _.cloneDeep(yNodeData);
  const isCenterKeyEqual = _.isEqual(_.sortBy(xNodeData.center_keys), _.sortBy(yNodeData.center_keys));

  return xNodeData.disable_delete === yNodeData.disable_delete // 是否可移除
    && xNodeData.disable_edit_name === yNodeData.disable_edit_name // 是否可编辑组织名称
    && xNodeData.engineer_org_number === yNodeData.engineer_org_number // 工程师所属单位
    && xNodeData.is_can_maintain_team === yNodeData.is_can_maintain_team // PMT经理是否可维护团队
    && xNodeData.is_can_split_node === yNodeData.is_can_split_node // 组织节点是否可向下拆解
    && xNodeData.person_max_limit === yNodeData.person_max_limit // 角色最多人员数
    && xNodeData.person_min_limit === yNodeData.person_min_limit // 角色最少人员数
    && xNodeData.description === yNodeData.description // 角色描述
    && xNodeData.is_can_inform_team_member === yNodeData.is_can_inform_team_member // 发送多功能小组提醒
    && isCenterKeyEqual; // 所属单位
}

// 添加模板生成的节点，构成新组织树
// （可能是第一次添加模板生成的节点，也可能是添加同一模板生成的节点，也可能是添加另一个模板生成的节点）
function addTemplateOrganizationChartItems(
  state: OrganizationChartState, action: Action
): OrganizationChartState {
  let oldItems = _.cloneDeep(state.items);
  const templateItems = (action as AddTemplateOrganizationChartItemsAction).items;
  const currentUser = (action as AddTemplateOrganizationChartItemsAction).currentUser;
  const isProjectInWhitelist = (action as AddTemplateOrganizationChartItemsAction).isProjectInWhitelist;
  const cloneTemplateItems = _.cloneDeep(templateItems);
  const parentNodePath = (action as AddTemplateOrganizationChartItemsAction).parentNodePath;
  const permissions = (action as AddTemplateOrganizationChartItemsAction).permissions;
  const itemGroups = compareTemplateItemsWithCurrentTree(oldItems, templateItems, parentNodePath, currentUser, isProjectInWhitelist);
  oldItems = addItems(oldItems, itemGroups.addItems);
  oldItems = deleteItems(oldItems, itemGroups.deleteItems);
  oldItems = updateItems(oldItems, itemGroups.updateItems);
  oldItems = rankItems(oldItems, cloneTemplateItems);
  // 有权限的分支上的节点，分配是否可编辑权限、是否可编辑后代节点权限
  oldItems = assignEnableEditProperty(oldItems, permissions);
  oldItems = assignEnableEditOffSpringsProperty(oldItems, permissions);

  // 展开当前节点
  const parentIndex = _.findIndex(oldItems, { id: templateItems[0]?.parent_id });
  if (-1 !== parentIndex) {
    oldItems[parentIndex]._is_collapsed = false;
  }

  const templateOrgHashCodes = getInUsingOrganizationTemplateHashCodes(oldItems, state.organizationTemplateOrgHashCodes);
  const allItems = formAllItems(state.currentAllItems, oldItems);
  const oldItemsTransformed = engineersAndMultifunctionalTeams2NormalItems(oldItems, state.editEnable);
  const allItemsTransformed = engineersAndMultifunctionalTeams2NormalItems(allItems, state.editEnable);
  return Object.assign({}, state, {
    items: oldItems,
    itemsTransformed: oldItemsTransformed,
    itemsTransformedWithoutDeleted: removeItemsTransformedDeleted(oldItemsTransformed),
    layers: items2Layers(oldItems),
    layersTransformed: items2Layers(oldItemsTransformed),
    layersWithoutDeletedItems: items2Layers(oldItems, true),
    layersWithoutDeletedItemsTransformed: items2Layers(oldItemsTransformed, true),
    allItems,
    allLayers: items2Layers(allItems),
    allLayersTransformed: items2Layers(allItemsTransformed),
    allLayersWithoutDeletedItems: items2Layers(allItems, true),
    allLayersWithoutDeletedItemsTransformed: items2Layers(allItemsTransformed, true),
    organizationTemplateOrgHashCodes: templateOrgHashCodes,
    dirty: true
  });
}

// 为组织节点分配_enable_edit属性
function assignEnableEditProperty(
  items: IOrganizationChartItem[],
  permissions: string[]
): IOrganizationChartItem[] {
  if (!permissions) {
    return items;
  }
  _.forEach(items, item => {
    // 有权限的分支上的除根节点外的所有节点，可编辑
    item._enable_edit = -1 !== _.findIndex(permissions, permission => {
      return _.includes(item.parent_node_path, permission);
    });
  });
  return _.cloneDeep(items);
}

// 为组织节点分配_enable_edit_offsprings属性
function assignEnableEditOffSpringsProperty(
  items: IOrganizationChartItem[],
  permissions: string[]
): IOrganizationChartItem[] {
  if (!permissions) {
    return items;
  }
  _.forEach(items, item => {
    // 有权限的分支上的根节点，可编辑后代节点
    item._enable_edit_offsprings = -1 !== _.findIndex(permissions, permission => {
      return ORGANIZATION_CHART_ROOT_PARENT_NODE_PATH === item.parent_node_path
        ? `/${item.name}/` === permission
        : `${item.parent_node_path}${item.name}/` === permission;
    });
  });
  return _.cloneDeep(items);
}

// 组织树排序
function rankItems(
  items: IOrganizationChartItem[], // 添加标准组织模板后生成的完整组织树
  templateItems: IOrganizationChartItem[] // 由模板生成的组织节点
): IOrganizationChartItem[] {
  _.forEach(items, item => {
    const existItem = _.find(templateItems, {
      name: item.name,
      parent_node_path: item.parent_node_path
    });
    if (existItem) {
      item.rank = existItem.rank;
      item._template_rank = existItem.rank;
      return;
    }
    item.rank = 0;
    removeTemplateRankProperty(items);
  });
  return _.sortBy(items, 'rank');
}

// 移除自定义节点的_template_rank属性
function removeTemplateRankProperty(items: IOrganizationChartItem[]) {
  const customOrganizationChartItems = _.filter(items, item => {
    return !item.template_org_id;
  });
  _.forEach(customOrganizationChartItems, item => {
    delete item._template_rank;
  });
}

// 获取当前节点的后代节点
function findOffsprings(
  items: IOrganizationChartItem[],  // 所有节点
  currentItem: IOrganizationChartItem  // 当前节点
): IOrganizationChartItem[] {
  let offsprings = [];
  function filterOffsprings(parentItem: IOrganizationChartItem) {
    const children = _.filter(items, { parent_id: parentItem.id });
    offsprings = _.concat(offsprings, children);
    _.forEach(children, item => {
      filterOffsprings(item);
    });
  }
  filterOffsprings(currentItem);
  return offsprings;
}

// 构建完整组件树
// - 由 当前组件树 和 组织单当前分支 merge 而成
function formAllItems(
  currentAllItems: IOrganizationChartItem[],  // 当前树的所有节点
  items: IOrganizationChartItem[] // 组织单当前分支的节点
): IOrganizationChartItem[] {
  const minLevel = _.min(_.map(items, 'level'));
  const branchRoot = _.find(items, { level: minLevel });
  if (!branchRoot) {
    return [];
  }
  const currentBranchRoot = _.find(currentAllItems, ['parent_node_path', branchRoot.parent_node_path]);
  let treeWithoutCurrentBranch = [];
  if (currentBranchRoot) {
    const offsprings = findOffsprings(currentAllItems, currentBranchRoot);
    treeWithoutCurrentBranch = _.difference(currentAllItems, [...offsprings, currentBranchRoot]);
  }
  return _.concat(treeWithoutCurrentBranch, items);
}

// 给每个节点计算：末代节点总数
function assignOffspringCount(layers: IOrganizationChartLayer[], groupByParentId: GroupByParentId) {
  // 初始化_offspring_count=1
  _.forEach(layers, layer => {
    _.forEach(layer, group => {
      _.forEach(group, item => {
        item._offspring_count = 1;
      });
    });
  });

  // 计算父节点的_offspring_count
  for (let i = layers.length - 1; i > 0; i--) {
    _.forEach(layers[i], group => {
      const parent_id = group[0].parent_id;

      // 计算父节点的_offspring_count
      // tslint:disable-next-line: variable-name
      const _offspring_counts = _.map(group, '_offspring_count');
      // tslint:disable-next-line: variable-name
      const parent__offspring_count = _.reduce(_offspring_counts, (sum, n) => {
        return sum + n;
      }, 0);

      // 设置父节点的_offspring_count
      if (i - 1 >= 0) {
        _.forEach(layers[i - 1], parentLayerGroup => {
          _.forEach(parentLayerGroup, item => {
            if (item.id === parent_id) {
              item._offspring_count = parent__offspring_count;
            }
          });
        });
      }
    });
  }

  // 将_offspring_count=1，但实际没有后代的节点，_offspring_count设为0
  _.forEach(layers, layer => {
    _.forEach(layer, group => {
      _.forEach(group, item => {
        if (1 === item._offspring_count) {
          item._offspring_count = groupByParentId[item.id] ? 1 : 0;
        }
      });
    });
  });

  return layers;
}

// 转换成分层结构，用于UI展示
function items2Layers(
  items: IOrganizationChartItem[],
  isWithoutDeletedItems?: boolean // 移除被标记为”删除“的节点
): IOrganizationChartLayer[] {
  let cloneItems = _.cloneDeep(items);
  if (isWithoutDeletedItems) {
    // 过滤掉删除的节点（删除标准节点、工程师节点，不删除多功能小组节点）
    cloneItems = _.reject(cloneItems, item => {
      return EOrganizationChartItemOperationType.Delete === item.operation
        && EOrganizationChartItemType.MultifunctionalTeam !== item._type;
    });
  }
  // 将level最小的节点作为根节点
  const levels = _.map(cloneItems, 'level');
  const minLevel = _.min(levels);
  const rootItem = _.find(cloneItems, { level: minLevel });
  if (rootItem) {
    rootItem.parent_id = ORGANIZATION_ROOT_PARENT_ID;
  }

  // 按parent_id分组
  const groupByParentId: GroupByParentId = _.groupBy(cloneItems, 'parent_id');

  // 计算_children_count值
  _.forEach(cloneItems, item => {
    item._children_count = groupByParentId[item.id] ? groupByParentId[item.id].length : 0;
  });

  // 按parent_id进行分层
  const layers = [[groupByParentId[ORGANIZATION_ROOT_PARENT_ID]]]; // 初始化层数组，添加第一层

  // 递归追加层
  function addLayers() {
    const lastLayer = layers[layers.length - 1];

    const newLayer = [];
    let newLayExistRealItem = false; // 该新建层存在真实节点
    _.forEach(lastLayer, group => {
      _.forEach(group, item => {
        if (groupByParentId[item.id]) { // 有真实子节点
          newLayExistRealItem = true;
          item._has_real_children = true;
          newLayer.push(groupByParentId[item.id]);
        } else {  // 没有真实子节点，添加占位子节点
          item._has_real_children = false;
          newLayer.push([{
            parent_id: item.id,
            id: generatePlaceholderId(),
            _is_placeholder: true
          }]);
        }
      });
    });

    if (newLayExistRealItem) {
      layers.push(newLayer);
      addLayers();
    }
  }
  addLayers();

  // 给每个节点计算：末代节点总数
  assignOffspringCount(layers, groupByParentId);

  return layers;
}

// 将工程师、多功能小组 转换成 组织树节点
function engineersAndMultifunctionalTeams2NormalItems(
  items: IOrganizationChartItem[],  // 原组织树
  editEnable: boolean
): IOrganizationChartItem[] {
  const newItems: IOrganizationChartItem[] = [];
  _.forEach(items, item => {
    item._type = EOrganizationChartItemType.Normal;
    if (!item.team_members?.length) {
      newItems.push(item);
      return;
    }
    engineers2NormalItems(item, newItems, editEnable);
    newItems.push(_.cloneDeep(item));
  });

  return newItems;
}
// 将工程师 转换成 组织树节点
function engineers2NormalItems(
  item: IOrganizationChartItem,  // 原组织树节点
  newItems: IOrganizationChartItem[], // 转换后的组织树
  editEnable: boolean
) {
  _.forEach(item.team_members, (teamMember, tIndex) => {
    // 工程师 -> 节点
    const operation = organizationTransformService.transformEngineerNodeOperation(item.operation, teamMember.operation);
    const engineerItemId = `${item.id}-${tIndex}`;
    const engineerItem: IOrganizationChartItem = {
      _type: EOrganizationChartItemType.Engineer,
      _is_collapsed: teamMember._is_collapsed,
      operation,
      parent_id: item.id,
      id: engineerItemId,
      name: teamMember.module_name,
      persons: [{
        user_id: teamMember.user_id,
        name: teamMember.name
      }],
      node_data: item.node_data
    };
    newItems.push(engineerItem);
    if (!teamMember.multifunctional_teams?.items?.length) {
      return;
    }
    multifunctionalTeams2NormalItems(item, teamMember, engineerItem, newItems, editEnable);
  });
}
// 将多功能小组 转换成 组织树节点
function multifunctionalTeams2NormalItems(
  item: IOrganizationChartItem,  // 原组织树节点
  teamMember: IOrganizationTeamItem, // 原工程师
  engineerItem: IOrganizationChartItem, // 生成的工程师节点
  newItems: IOrganizationChartItem[], // 转换后的组织树
  editEnable: boolean
) {
  const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
  // 可维护该工程师节点下的多功能小组（ 可维护多功能小组 && 是该多功能小组节点的父工程师节点上的工程师 ）
  const canEditMultifunctionalTeam = editEnable
    && item.node_data?.is_can_maintain_team
    && teamMember.user_id === currentUser.user_id;

  _.forEach(teamMember.multifunctional_teams.items, (mItem, mIndex) => {
    // 多功能小组 -> 节点
    const operation = organizationTransformService.transformMultifunctionalTeamNodeOperation(
      item.operation,
      engineerItem.operation,
      mItem.operation
    );
    const multifunctionalItem: IOrganizationChartItem = {
      _type: EOrganizationChartItemType.MultifunctionalTeam,
      operation,
      parent_id: engineerItem.id,
      id: `${engineerItem.id}-${mIndex}`,
      name: mItem.name,
      persons: mItem.persons
    };

    // 添加该节点：可维护该工程师节点下的多功能小组（编辑时展示） || 有组员 || 被删除（详情页要展示）
    if (canEditMultifunctionalTeam || mItem.persons.length || EOrganizationChartItemOperationType.Delete === mItem.operation) {
      newItems.push(multifunctionalItem);
    }
  });
}

// 计算新占位Id（供占位节点使用，为<=-1的负数）
let placeholderId = -1;
function generatePlaceholderId(): string {
  if (-1 === placeholderId) {
    placeholderId--;
    return -1 + '';
  } else {
    placeholderId--;
    return placeholderId + '';
  }
}

// 添加标准组织节点（addType为child），构成新组织树
function addStandardOrganizationChartItems(
  state: OrganizationChartState,
  action: Action
): OrganizationChartState {
  let items = state.items;
  const referenceItem = (action as AddOrganizationChartItemsAction).referenceItem; // 标准组织节点的父节点
  const newItems = (action as AddOrganizationChartItemsAction).newItems;
  let insertIndex: number;
  let parentNodePath: string;
  let isStubNode: boolean;  // 非主分支上的节点
  parentNodePath = generateAddItemParentNodePath(items, referenceItem, 'child');
  isStubNode = _.includes(parentNodePath, `/${ORGANIZATION_CHART_STUB_PARENT_NODE_PATH_SEPARATOR}/`);
  _.forEach(newItems, newItem => {
    newItem.parent_node_path = parentNodePath;
    newItem.is_stub_node = isStubNode;
    // 分配newItem 的insertIndex
    insertIndex = assignInsertIndexByTemplateRank(items, newItem);
    items = addItems(items, [newItem], insertIndex);
  });

  // 展开当前节点
  const referenceIndex = _.findIndex(items, { id: referenceItem.id });
  items[referenceIndex]._is_collapsed = false;

  const allItems = formAllItems(state.currentAllItems, items);
  const itemsTransformed = engineersAndMultifunctionalTeams2NormalItems(items, state.editEnable);
  const allItemsTransformed = engineersAndMultifunctionalTeams2NormalItems(allItems, state.editEnable);
  return Object.assign({}, state, {
    items,
    itemsTransformed,
    itemsTransformedWithoutDeleted: removeItemsTransformedDeleted(itemsTransformed),
    layers: items2Layers(items),
    layersTransformed: items2Layers(itemsTransformed),
    layersWithoutDeletedItems: items2Layers(items, true),
    layersWithoutDeletedItemsTransformed: items2Layers(itemsTransformed, true),
    allItems,
    allLayers: items2Layers(allItems),
    allLayersTransformed: items2Layers(allItemsTransformed),
    allLayersWithoutDeletedItems: items2Layers(allItems, true),
    allLayersWithoutDeletedItemsTransformed: items2Layers(allItemsTransformed, true),
    dirty: true,

  });
}

function assignInsertIndexByTemplateRank(items: IOrganizationChartItem[], item: IOrganizationChartItem): number {
  // 距新增节点最近的_template_rank, 获取其index
  const existedTemplateItems = _.filter(_.cloneDeep(items), {
    template_org_id: item.template_org_id
  });
  const existedTemplateRanks = _.map(existedTemplateItems, '_template_rank');
  let nearTemplateRank = existedTemplateRanks[0];
  _.forEach(existedTemplateRanks, existedTemplateRank => {
    if (Math.abs(existedTemplateRank - item._template_rank) < Math.abs(nearTemplateRank - item._template_rank)) {
      nearTemplateRank = existedTemplateRank;
    }
  });
  const referenceIndex = _.findIndex(items, {
    template_org_id: item.template_org_id,
    _template_rank: nearTemplateRank
  });

  if (item._template_rank < nearTemplateRank) {
    // 插入到前面
    return referenceIndex;
  }
  // 插入到后面
  return referenceIndex + 1;
}

// 添加items
function addOrganizationChartItems(state: OrganizationChartState, action: Action): OrganizationChartState {
  let items = state.items;
  const referenceItem = (action as AddOrganizationChartItemsAction).referenceItem;
  const newItems = (action as AddOrganizationChartItemsAction).newItems;
  const addType = (action as AddOrganizationChartItemsAction).addType;
  const referenceIndex = _.findIndex(items, { id: referenceItem.id });
  if (-1 === referenceIndex) {
    return state;
  }
  let insertIndex = 0;

  let parentNodePath: string;
  let isStubNode: boolean;  // 非主分支上的节点
  let needUpdateParentIdItem: IOrganizationChartItem;
  switch (addType) {
    case 'before':
      parentNodePath = generateAddItemParentNodePath(items, referenceItem, 'before');
      isStubNode = _.includes(parentNodePath, `/${ORGANIZATION_CHART_STUB_PARENT_NODE_PATH_SEPARATOR}/`);
      insertIndex = referenceIndex;
      _.forEach(newItems, item => {
        item.parent_node_path = parentNodePath;
        item.is_stub_node = isStubNode;
      });
      break;
    case 'after':
      parentNodePath = generateAddItemParentNodePath(items, referenceItem, 'after');
      isStubNode = _.includes(parentNodePath, `/${ORGANIZATION_CHART_STUB_PARENT_NODE_PATH_SEPARATOR}/`);
      insertIndex = referenceIndex + 1;
      _.forEach(newItems, item => {
        item.parent_node_path = parentNodePath;
        item.is_stub_node = isStubNode;
      });
      break;
    case 'child':
      parentNodePath = generateAddItemParentNodePath(items, referenceItem, 'child');
      isStubNode = _.includes(parentNodePath, `/${ORGANIZATION_CHART_STUB_PARENT_NODE_PATH_SEPARATOR}/`);
      insertIndex = items.length;
      _.forEach(newItems, item => {
        item.parent_node_path = parentNodePath;
        item.is_stub_node = isStubNode;
      });
      break;
    case 'parent':
      isStubNode = true;
      insertIndex = 0;
      _.find(items, { id: referenceItem.id }).parent_id = newItems[0].id; // 重新分配原根节点的parent_id
      newItems[0].parent_id = ORGANIZATION_ROOT_PARENT_ID;
      newItems[0].parent_node_path = generateAddItemParentNodePath(items, referenceItem, 'parent');
      newItems[0].is_stub_node = isStubNode;

      // 如果原 needUpdateParentIdItem 为空，则记录参考节点为“需要更新parent_id的节点”
      if (!state.needUpdateParentIdItem) {
        needUpdateParentIdItem = _.cloneDeep(referenceItem);
        needUpdateParentIdItem.parent_id = newItems[0].id;
        needUpdateParentIdItem.operation = EOrganizationChartItemOperationType.InUsing;
      }
      break;
  }
  items = addItems(items, newItems, insertIndex);

  // 展开当前节点
  if ('child' === addType) {
    items[referenceIndex]._is_collapsed = false;
  }

  const allItems = formAllItems(state.currentAllItems, items);
  const itemsTransformed = engineersAndMultifunctionalTeams2NormalItems(items, state.editEnable);
  const allItemsTransformed = engineersAndMultifunctionalTeams2NormalItems(allItems, state.editEnable);
  return Object.assign({}, state, {
    items,
    itemsTransformed,
    itemsTransformedWithoutDeleted: removeItemsTransformedDeleted(itemsTransformed),
    layers: items2Layers(items),
    layersTransformed: items2Layers(itemsTransformed),
    layersWithoutDeletedItems: items2Layers(items, true),
    layersWithoutDeletedItemsTransformed: items2Layers(itemsTransformed, true),
    allItems,
    allLayers: items2Layers(allItems),
    allLayersTransformed: items2Layers(allItemsTransformed),
    allLayersWithoutDeletedItems: items2Layers(allItems, true),
    allLayersWithoutDeletedItemsTransformed: items2Layers(allItemsTransformed, true),
    dirty: true,
    needUpdateParentIdItem: state.needUpdateParentIdItem || needUpdateParentIdItem
  });
}

// 获取新增节点的parent_node_path
function generateAddItemParentNodePath(
  items: IOrganizationChartItem[],  // 原节点列表
  referenceItem: IOrganizationChartItem,  // 参考节点
  addType: TOrganizationChartItemAddType  // 添加位置
) {
  let parentNodePath: string;
  switch (addType) {
    case 'before':
    case 'after':
      // 如果 referenceItem 是”项目经理“
      if (ORGANIZATION_CHART_ROOT_PARENT_NODE_PATH === referenceItem.parent_node_path) {
        const rootItem = _.find(items, { parent_node_path: ORGANIZATION_CHART_ROOT_PARENT_NODE_PATH }); // 根节点
        const parentOfRoot = _.find(items, { level: -1, parent_node_path: `/${rootItem.name}/` }); // "项目经理"的父节点
        parentNodePath = `${parentOfRoot.parent_node_path}${ORGANIZATION_CHART_STUB_PARENT_NODE_PATH_SEPARATOR}/${parentOfRoot.name}/`;
        // 如果 referenceItem 是“项目经理”的直系祖先（parent_node_path 的形式为 "/x/项目经理/"）
      } else if (referenceItem.level < 0
        && !_.includes(referenceItem.parent_node_path, `/${ORGANIZATION_CHART_STUB_PARENT_NODE_PATH_SEPARATOR}/`)
      ) {
        const parentOfReferenceItem = _.find(items, { // 参考节点的父节点
          level: referenceItem.level - 1,
          parent_node_path: `/${referenceItem.name}${referenceItem.parent_node_path}`
        });
        // 则新子节点的 parent_node_path 的形式为 "/x/项目经理/@/referenceName/"
        // tslint:disable-next-line: max-line-length
        parentNodePath = `${referenceItem.parent_node_path}${ORGANIZATION_CHART_STUB_PARENT_NODE_PATH_SEPARATOR}/${parentOfReferenceItem.name}/`;
        // 如果 referenceItem 是从“项目经理”的直系祖先上分出的节点及后代节点，不包括”项目经理“这一枝（parent_node_path 中含有 "/@/"）
      } else if (_.includes(referenceItem.parent_node_path, `/${ORGANIZATION_CHART_STUB_PARENT_NODE_PATH_SEPARATOR}/`)) {
        parentNodePath = referenceItem.parent_node_path;
      } else {
        parentNodePath = referenceItem.parent_node_path;
      }
      break;

    case 'child':
      // 如果 referenceItem 是”项目经理“
      if (ORGANIZATION_CHART_ROOT_PARENT_NODE_PATH === referenceItem.parent_node_path) {
        parentNodePath = `/${referenceItem.name}/`;
        // 如果 referenceItem 是“项目经理”的直系祖先（parent_node_path 的形式为 "/x/项目经理/"）
      } else if (
        referenceItem.level < 0
        && !_.includes(referenceItem.parent_node_path, `/${ORGANIZATION_CHART_STUB_PARENT_NODE_PATH_SEPARATOR}/`)
      ) {
        // 则新子节点的 parent_node_path 的形式为 "/x/项目经理/@/referenceName/"
        parentNodePath = `${referenceItem.parent_node_path}${ORGANIZATION_CHART_STUB_PARENT_NODE_PATH_SEPARATOR}/${referenceItem.name}/`;
        // 如果 referenceItem 是从“项目经理”的直系祖先上分出的节点及后代节点，不包括”项目经理“这一枝（parent_node_path 中含有 "/@/"）
      } else if (_.includes(referenceItem.parent_node_path, `/${ORGANIZATION_CHART_STUB_PARENT_NODE_PATH_SEPARATOR}/`)) {
        // 则新子节点的 parent_node_path 的形式为 "/x/项目经理/@/.../referenceName/"
        parentNodePath = `${referenceItem.parent_node_path}${referenceItem.name}/`;
      } else {
        parentNodePath = `${referenceItem.parent_node_path}${referenceItem.name}/`;
      }
      break;

    case 'parent':
      parentNodePath = ORGANIZATION_CHART_ROOT_PARENT_NODE_PATH === referenceItem.parent_node_path
        ? `/${referenceItem.name}/` // referenceItem是项目经理
        : `/${referenceItem.name}${referenceItem.parent_node_path}`; // referenceItem不是项目经理
      break;
  }
  return parentNodePath;
}

// 获取组织树上使用的组织模板
function getInUsingOrganizationTemplateHashCodes(
  allItems: IOrganizationChartItem[],  // 组织树上的节点列表
  templateOrgHashCodes: IOrganizationTemplateOrgHashCode[] // 当前的hashCodes
): IOrganizationTemplateOrgHashCode[] {
  const inUsingItems = _.reject(allItems, { operation: EOrganizationChartItemOperationType.Delete });
  let cloneTemplateOrgHashCodes = _.cloneDeep(templateOrgHashCodes);
  const treeTemplateIds = _.uniq(_.map(inUsingItems, 'template_org_id'));  // 组织树上使用的组织模板的ids
  const existTemplateIds = _.map(cloneTemplateOrgHashCodes, 'template_org_id');
  const deletedTemplateIds = _.difference(existTemplateIds, treeTemplateIds);
  // 删除组织树上不用的hashCode
  cloneTemplateOrgHashCodes = _.reject(cloneTemplateOrgHashCodes, value => -1 !== _.indexOf(deletedTemplateIds, value.template_org_id));
  return cloneTemplateOrgHashCodes;
}

// 修正需要更新parent_id的节点
function fixNeedUpdateParentIdItem(items: IOrganizationChartItem[], oldNeedUpdateParentIdItem: IOrganizationChartItem) {
  // 如果“需要更新parent_id的节点”被删除了（新增祖先节点，随后又删除），则清空needUpdateParentIdItem
  // (通过id、parent_id比较)
  let needUpdateParentIdItem = _.cloneDeep(oldNeedUpdateParentIdItem);
  if (needUpdateParentIdItem && -1 === _.findIndex(items, {
    id: needUpdateParentIdItem.id,
    parent_id: needUpdateParentIdItem.parent_id
  })) {
    needUpdateParentIdItem = undefined;
  }

  // 删除了祖先节点，新根节点须更新parent_id
  // （删除祖先节点，会分配新根节点的parent_id为null，而祖先节点的operation标记为“删除”，parent_id扔为null，这就导致有2个parent_id为null）
  const rootItems = _.filter(items, it => !it.parent_id);
  if (rootItems && rootItems.length > 1) {
    needUpdateParentIdItem = _.find(rootItems, it => EOrganizationChartItemOperationType.Delete !== it.operation);
  }
  return needUpdateParentIdItem;
}

// 给节点初始化 _is_collapsed 属性
// （默认展开到一级节点，其它节点折叠）
// （查看更新前、查看更新后，节点全部展开）
function assignItemsIsCollapsed(items: IOrganizationChartItem[], contentType: TOrganizationChartContentType = 'doing'): IOrganizationChartItem[] {
  items = _.cloneDeep(items);

  switch (contentType) {
    case 'updateBefore':
    case 'preview':
      _.forEach(items, item => {
        item._is_collapsed = false;
        if (!item.team_members?.length) {
          return;
        }
        _.forEach(item.team_members, engineer => {
          engineer._is_collapsed = false;
        });
      });
      break;

    case 'doing':
    default:
      _.forEach(items, item => {
        item._is_collapsed = item.level > 0;
        if (!item.team_members?.length) {
          return;
        }
        _.forEach(item.team_members, engineer => {
          engineer._is_collapsed = true;
        });
      });
  }
  return items;
}

// 移除itemsTransformed中被标记为“删除”的节点
function removeItemsTransformedDeleted(itemsTransformed: IOrganizationChartItem[]): IOrganizationChartItem[] {
  return _.reject(itemsTransformed, item => {
    return EOrganizationChartItemOperationType.Delete === item.operation
      && EOrganizationChartItemType.MultifunctionalTeam !== item._type;
  });
}

// 折叠节点及其后代节点
function collapseOrganizationChartItemAndOffsprings(item: IOrganizationChartItem, items: IOrganizationChartItem[]/*全部*/) {
  item._is_collapsed = true;
  const children = _.filter(items, { parent_id: item.id });
  if (children.length) {
    _.forEach(children, child => {
      collapseOrganizationChartItemAndOffsprings(child, items);
    });
  }
}

export interface OrganizationChartState {
  items: IOrganizationChartItem[];  // 组织单的当前分支的节点
  itemsTransformed: IOrganizationChartItem[]; // 组织单的当前分支节点（工程师、多功能小组 转成 组织节点）
  itemsTransformedWithoutDeleted: IOrganizationChartItem[]; // 组织单的当前分支节点（工程师、多功能小组 转成 组织节点）（不包含标记为”删除“的节点）
  layers: IOrganizationChartLayer[];  // 组织单的当前分支的节点组成的层级树（包含标记为”删除“的节点）
  layersTransformed: IOrganizationChartLayer[]; // 组织单的当前分支的节点组成的层级树（包含标记为”删除“的节点）(工程师、多功能小组 转成 组织节点)
  layersWithoutDeletedItems: IOrganizationChartLayer[];  // 组织单的当前分支的节点组成的层级树（不包含标记为”删除“的节点）
  layersWithoutDeletedItemsTransformed: IOrganizationChartLayer[];
  allItems: IOrganizationChartItem[];  // 组织单的所有节点（由 items 和 currentAllItems 合并merge而成）
  allLayers: IOrganizationChartLayer[];  // 组织单的所有节点组成的层级树（包含标记为”删除“的节点）
  allLayersTransformed: IOrganizationChartLayer[];
  allLayersWithoutDeletedItems: IOrganizationChartLayer[];  // 组织单的所有节点组成的层级树（不包含标记为”删除“的节点）
  allLayersWithoutDeletedItemsTransformed: IOrganizationChartLayer[];
  currentAllItems: IOrganizationChartItem[];  // 当前组织的所有节点
  currentAllLayers: IOrganizationChartLayer[];  // 当前组织的所有节点组成的层级树（包含标记为”删除“的节点）
  currentAllItemsTransformed: IOrganizationChartItem[];
  currentAllLayersTransformed: IOrganizationChartLayer[];
  previewAllItems: IOrganizationChartItem[];  // 预览变更后的组织的所有节点
  previewAllLayers: IOrganizationChartLayer[];  // 预览变更后的组织的所有节点组成的层级树（包含标记为”删除“的节点）
  previewAllItemsTransformed: IOrganizationChartItem[];
  previewAllLayersTransformed: IOrganizationChartLayer[];
  previewAllLayersWithoutDeletedItems: IOrganizationChartLayer[]; // 预览变更后的组织的所有节点组成的层级树（不包含标记为“删除”的节点）
  previewAllLayersWithoutDeletedItemsTransformed: IOrganizationChartLayer[];
  checkedItem: IOrganizationChartItem;  // (为提升性能，组织树节点使用 IOrganizationChartItem._is_checked 显示选中状态)
  editEnable: boolean;  // (为提升性能，组织树节点直接通过属性传递。该属性现在只用于组织树的转换、权限等，不用于页面展示)
  dirty: boolean;
  path: string; // 组织单路径
  viewType: TOrganizationChartViewType; // 组织单展示类型：分支，整棵树
  organizationTemplateOrgHashCodes: IOrganizationTemplateOrgHashCode[];  // 当前组织分支树（items）上使用的组织模板
  needUpdateParentIdItem: IOrganizationChartItem; // 需要更新parent_id的节点（因为原根节点添加了父节点，导致需要重新根节点）
}

const initialState: OrganizationChartState = {
  items: [],
  itemsTransformed: [],
  itemsTransformedWithoutDeleted: [],
  layers: [],
  layersTransformed: [],
  layersWithoutDeletedItems: [],
  layersWithoutDeletedItemsTransformed: [],
  allItems: [],
  allLayers: [],
  allLayersTransformed: [],
  allLayersWithoutDeletedItems: [],
  allLayersWithoutDeletedItemsTransformed: [],
  currentAllItems: [],
  currentAllLayers: [],
  currentAllItemsTransformed: [],
  currentAllLayersTransformed: [],
  previewAllItems: [],
  previewAllLayers: [],
  previewAllItemsTransformed: [],
  previewAllLayersTransformed: [],
  previewAllLayersWithoutDeletedItems: [],
  previewAllLayersWithoutDeletedItemsTransformed: [],
  checkedItem: undefined,
  editEnable: false,
  dirty: false,
  path: undefined,
  viewType: 'part',
  organizationTemplateOrgHashCodes: [],
  needUpdateParentIdItem: undefined
};

export const OrganizationChartReducer: Reducer<OrganizationChartState> =
  (state: OrganizationChartState = initialState, action: Action): OrganizationChartState => {
    let items: IOrganizationChartItem[];
    let itemsTransformed: IOrganizationChartItem[];
    let itemsTransformedWithoutDeleted: IOrganizationChartItem[];
    let allItems: IOrganizationChartItem[];
    let allItemsTransformed: IOrganizationChartItem[];
    let currentAllItemsTransformed: IOrganizationChartItem[];
    let previewAllItemsTransformed: IOrganizationChartItem[];
    let templateOrgHashCodes: IOrganizationTemplateOrgHashCode[];
    let checkedItem: IOrganizationChartItem;
    let needUpdateParentIdItem: IOrganizationChartItem;
    switch (action.type) {
      case SET_ORGANIZATION_CHART_ITEMS:
        items = (action as SetOrganizationChartItemsAction).items;
        items = _.sortBy(items, 'rank');
        items = assignItemsIsCollapsed(items);
        itemsTransformed = engineersAndMultifunctionalTeams2NormalItems(items, state.editEnable);
        allItems = formAllItems(state.currentAllItems, items);
        allItemsTransformed = engineersAndMultifunctionalTeams2NormalItems(allItems, state.editEnable);
        return Object.assign({}, state, {
          items,
          itemsTransformed,
          itemsTransformedWithoutDeleted: removeItemsTransformedDeleted(itemsTransformed),
          layers: items2Layers(items),
          layersTransformed: items2Layers(itemsTransformed),
          layersWithoutDeletedItems: items2Layers(items, true),
          layersWithoutDeletedItemsTransformed: items2Layers(itemsTransformed, true),
          allItems,
          allLayers: items2Layers(allItems),
          allLayersTransformed: items2Layers(allItemsTransformed),
          allLayersWithoutDeletedItems: items2Layers(allItems, true),
          allLayersWithoutDeletedItemsTransformed: items2Layers(allItemsTransformed, true)
        });

      case SET_ORGANIZATION_CHART_CURRENT_ALL_ITEMS:
        let currentAllItems = (action as SetOrganizationChartCurrentAllItemsAction).currentAllItems;
        currentAllItems = _.sortBy(currentAllItems, 'rank');
        currentAllItems = assignItemsIsCollapsed(currentAllItems, 'updateBefore');
        currentAllItemsTransformed = engineersAndMultifunctionalTeams2NormalItems(currentAllItems, state.editEnable);
        allItems = formAllItems(currentAllItems, state.items);
        allItems = assignItemsIsCollapsed(allItems, 'updateBefore');
        allItemsTransformed = engineersAndMultifunctionalTeams2NormalItems(allItems, state.editEnable);
        return Object.assign({}, state, {
          currentAllItems,
          currentAllLayers: items2Layers(currentAllItems),
          currentAllItemsTransformed,
          currentAllLayersTransformed: items2Layers(currentAllItemsTransformed, false),
          allItems,
          allLayers: items2Layers(allItems),
          allLayersTransformed: items2Layers(allItemsTransformed),
          allLayersWithoutDeletedItems: items2Layers(allItems, true),
          allLayersWithoutDeletedItemsTransformed: items2Layers(allItemsTransformed, true)
        });

      case SET_ORGANIZATION_CHART_PREVIEW_ALL_ITEMS:
        let previewAllItems = (action as SetOrganizationChartPreviewAllItemsAction).previewAllItems;
        previewAllItems = _.sortBy(previewAllItems, 'rank');
        previewAllItems = assignItemsIsCollapsed(previewAllItems, 'preview');
        previewAllItemsTransformed = engineersAndMultifunctionalTeams2NormalItems(previewAllItems, state.editEnable);
        return Object.assign({}, state, {
          previewAllItems,
          previewAllLayers: items2Layers(previewAllItems),
          previewAllItemsTransformed,
          previewAllLayersTransformed: items2Layers(previewAllItemsTransformed),
          previewAllLayersWithoutDeletedItems: items2Layers(previewAllItems, true),
          previewAllLayersWithoutDeletedItemsTransformed: items2Layers(previewAllItemsTransformed, true)
        });

      case SET_ORGANIZATION_CHART_EDIT_ENABLE:
        const editEnable = (action as SetOrganizationChartEditEnableAction).editEnable;
        return Object.assign({}, state, {
          editEnable
        });

      case SET_ORGANIZATION_CHART_PATH:
        const path = (action as SetOrganizationChartPathAction).path;
        return Object.assign({}, state, {
          path
        });

      case SET_ORGANIZATION_CHART_DIRTY:
        const dirty = (action as SetOrganizationChartDirtyAction).dirty;
        return Object.assign({}, state, {
          dirty
        });

      case SET_ORGANIZATION_CHART_VIEW_TYPE:
        const viewType = (action as SetOrganizationChartViewTypeAction).viewType;
        return Object.assign({}, state, {
          viewType
        });

      case CHECK_ORGANIZATION_CHART_ITEM:
        checkedItem = (action as CheckOrganizationChartItemAction).item;
        items = state.items;
        allItems = state.allItems;
        _.forEach(items, it => {
          it._is_checked = checkedItem && checkedItem.id === it.id;
        });
        _.forEach(allItems, it => {
          it._is_checked = checkedItem && checkedItem.id === it.id;
        });
        itemsTransformed = engineersAndMultifunctionalTeams2NormalItems(items, state.editEnable);
        allItemsTransformed = engineersAndMultifunctionalTeams2NormalItems(allItems, state.editEnable);
        return Object.assign({}, state, {
          checkedItem,
          items,
          itemsTransformed,
          itemsTransformedWithoutDeleted: removeItemsTransformedDeleted(itemsTransformed),
          layers: items2Layers(items),
          layersTransformed: items2Layers(itemsTransformed),
          layersWithoutDeletedItems: items2Layers(items, true),
          layersWithoutDeletedItemsTransformed: items2Layers(itemsTransformed, true),
          allItems,
          allLayers: items2Layers(allItems),
          allLayersTransformed: items2Layers(allItemsTransformed),
          allLayersWithoutDeletedItems: items2Layers(allItems, true),
          allLayersWithoutDeletedItemsTransformed: items2Layers(allItemsTransformed, true)
        });

      case CHECK_ORGANIZATION_CHART_ITEM_TRANSFORMED:
        checkedItem = (action as CheckOrganizationChartItemTransformedAction).itemTransformed;
        switch (checkedItem._type) {
          case EOrganizationChartItemType.Normal:
            items = state.items;
            allItems = state.allItems;
            _.forEach(items, it => {
              it._is_checked = checkedItem && checkedItem.id === it.id;
            });
            _.forEach(allItems, it => {
              it._is_checked = checkedItem && checkedItem.id === it.id;
            });
            itemsTransformed = engineersAndMultifunctionalTeams2NormalItems(items, state.editEnable);
            allItemsTransformed = engineersAndMultifunctionalTeams2NormalItems(allItems, state.editEnable);
            return Object.assign({}, state, {
              checkedItem,
              items,
              itemsTransformed,
              itemsTransformedWithoutDeleted: removeItemsTransformedDeleted(itemsTransformed),
              layers: items2Layers(items),
              layersTransformed: items2Layers(itemsTransformed),
              layersWithoutDeletedItems: items2Layers(items, true),
              layersWithoutDeletedItemsTransformed: items2Layers(itemsTransformed, true),
              allItems,
              allLayers: items2Layers(allItems),
              allLayersTransformed: items2Layers(allItemsTransformed),
              allLayersWithoutDeletedItems: items2Layers(allItems, true),
              allLayersWithoutDeletedItemsTransformed: items2Layers(allItemsTransformed, true)
            });

          case EOrganizationChartItemType.Engineer:
          case EOrganizationChartItemType.MultifunctionalTeam:
          default:
            items = state.items;
            allItems = state.allItems;
            itemsTransformed = state.itemsTransformed;
            itemsTransformedWithoutDeleted = removeItemsTransformedDeleted(itemsTransformed);
            _.forEach(items, it => {
              it._is_checked = false;
            });
            _.forEach(allItems, it => {
              it._is_checked = false;
            });
            _.forEach(itemsTransformed, it => {
              it._is_checked = checkedItem && checkedItem.id === it.id;
            });
            _.forEach(itemsTransformedWithoutDeleted, it => {
              it._is_checked = checkedItem && checkedItem.id === it.id;
            });
            _.forEach(allItems, it => {
              it._is_checked = checkedItem && checkedItem.id === it.id;
            });
            allItemsTransformed = engineersAndMultifunctionalTeams2NormalItems(allItems, state.editEnable);
            return Object.assign({}, state, {
              checkedItem: _.find(items, ['id', (checkedItem.id).split('-')[0]]), // 取Normal父节点
              items,
              itemsTransformed,
              itemsTransformedWithoutDeleted,
              layers: items2Layers(items),
              layersTransformed: items2Layers(itemsTransformed),
              layersWithoutDeletedItems: items2Layers(items, true),
              layersWithoutDeletedItemsTransformed: items2Layers(itemsTransformed, true),
              allItems,
              allLayers: items2Layers(allItems),
              allLayersTransformed: items2Layers(allItemsTransformed),
              allLayersWithoutDeletedItems: items2Layers(allItems, true),
              allLayersWithoutDeletedItemsTransformed: items2Layers(allItemsTransformed, true)
            });
        }

      case TOGGLE_COLLAPSE_ORGANIZATION_CHART_ITEM_TRANSFORMED:
        const clickedItem = (action as ToggleCollapseOrganizationChartItemTransformedAction).itemTransformed;
        items = state.items;
        const idArr = clickedItem.id.split('-');
        const normalItem = _.find(items, { id: idArr[0] });
        if (!idArr[1]) {  // 普通节点
          normalItem._is_collapsed = !normalItem._is_collapsed;
          _.forEach(normalItem.team_members, engineer => {
            engineer._is_collapsed = true;
          });
          const children = _.filter(items, { parent_id: normalItem.id });
          _.forEach(children, child => {
            child._is_collapsed = true;
          });
        } else {// 工程师节点
          const engineer = normalItem.team_members[idArr[1]];
          engineer._is_collapsed = !engineer._is_collapsed;
        }
        itemsTransformed = engineersAndMultifunctionalTeams2NormalItems(items, state.editEnable);
        allItems = formAllItems(state.currentAllItems, items);
        allItemsTransformed = engineersAndMultifunctionalTeams2NormalItems(allItems, state.editEnable);
        return Object.assign({}, state, {
          items,
          itemsTransformed,
          itemsTransformedWithoutDeleted: removeItemsTransformedDeleted(itemsTransformed),
          layers: items2Layers(items),
          layersTransformed: items2Layers(itemsTransformed),
          layersWithoutDeletedItems: items2Layers(items, true),
          layersWithoutDeletedItemsTransformed: items2Layers(itemsTransformed, true),
          allItems,
          allLayers: items2Layers(allItems),
          allLayersTransformed: items2Layers(allItemsTransformed),
          allLayersWithoutDeletedItems: items2Layers(allItems, true),
          allLayersWithoutDeletedItemsTransformed: items2Layers(allItemsTransformed, true)
        });

      case TOGGLE_COLLAPSE_ALL_ORGANIZATION_CHART_ITEMS:
        const isCollapsed = (action as ToggleCollapseAllOrganizationChartItemsAction).isCollapsed;
        const contentType = (action as ToggleCollapseAllOrganizationChartItemsAction).contentType;
        switch (contentType) {
          case 'updateBefore':
            items = state.currentAllItems;
            break;
          case 'doing':
            items = state.items;
            break;
          case 'preview':
            items = state.previewAllItems;
            break;
        }
        _.forEach(items, item => {
          if (-1 === _.findIndex(items, { id: item.parent_id })) {
            item._is_collapsed = false;
          } else {
            item._is_collapsed = isCollapsed;
            if (!item.team_members?.length) {
              return;
            }
            _.forEach(item.team_members, engineer => {
              engineer._is_collapsed = isCollapsed;
              if (engineer.multifunctional_teams?.items?.length) {
                return;
              }
            });
          }
        });
        itemsTransformed = engineersAndMultifunctionalTeams2NormalItems(items, state.editEnable);
        switch (contentType) {
          case 'updateBefore':
            return Object.assign({}, state, {
              currentAllItems: items,
              currentAllItemsTransformed: itemsTransformed
            });
          case 'doing':
            return Object.assign({}, state, {
              items,
              itemsTransformed,
              itemsTransformedWithoutDeleted: removeItemsTransformedDeleted(itemsTransformed)
            });
          case 'preview':
            return Object.assign({}, state, {
              previewAllItems: items,
              previewAllItemsTransformed: itemsTransformed
            });
        }

      case UPDATE_ORGANIZATION_CHART_ITEM:
        const item = (action as UpdateOrganizationChartItemAction).item;
        items = updateItems(state.items, [item]);
        itemsTransformed = engineersAndMultifunctionalTeams2NormalItems(items, state.editEnable);
        allItems = formAllItems(state.currentAllItems, items);
        allItemsTransformed = engineersAndMultifunctionalTeams2NormalItems(allItems, state.editEnable);
        return Object.assign({}, state, {
          items,
          itemsTransformed,
          itemsTransformedWithoutDeleted: removeItemsTransformedDeleted(itemsTransformed),
          layers: items2Layers(items),
          layersTransformed: items2Layers(itemsTransformed),
          layersWithoutDeletedItems: items2Layers(items, true),
          layersWithoutDeletedItemsTransformed: items2Layers(itemsTransformed, true),
          allItems,
          allLayers: items2Layers(allItems),
          allLayersTransformed: items2Layers(allItemsTransformed),
          allLayersWithoutDeletedItems: items2Layers(allItems, true),
          allLayersWithoutDeletedItemsTransformed: items2Layers(allItemsTransformed, true),
          dirty: true
        });

      case ADD_ORGANIZATION_CHART_ITEMS:
        return addOrganizationChartItems(state, action);

      case ADD_STANDARD_ORGANIZATION_CHART_ITEMS:
        return addStandardOrganizationChartItems(state, action);

      case DELETE_ORGANIZATION_CHART_ITEMS:
        const toDeleteItems = (action as DeleteOrganizationChartItemsAction).items;
        items = deleteItems(state.items, toDeleteItems);

        needUpdateParentIdItem = fixNeedUpdateParentIdItem(items, state.needUpdateParentIdItem);

        templateOrgHashCodes = getInUsingOrganizationTemplateHashCodes(items, state.organizationTemplateOrgHashCodes);
        allItems = formAllItems(state.currentAllItems, items);
        itemsTransformed = engineersAndMultifunctionalTeams2NormalItems(items, state.editEnable);
        allItemsTransformed = engineersAndMultifunctionalTeams2NormalItems(allItems, state.editEnable);
        return Object.assign({}, state, {
          items,
          itemsTransformed,
          itemsTransformedWithoutDeleted: removeItemsTransformedDeleted(itemsTransformed),
          layers: items2Layers(items),
          layersTransformed: items2Layers(itemsTransformed),
          layersWithoutDeletedItems: items2Layers(items, true),
          layersWithoutDeletedItemsTransformed: items2Layers(itemsTransformed, true),
          allItems,
          allLayers: items2Layers(allItems),
          allLayersTransformed: items2Layers(allItemsTransformed),
          allLayersWithoutDeletedItems: items2Layers(allItems, true),
          allLayersWithoutDeletedItemsTransformed: items2Layers(allItemsTransformed, true),
          organizationTemplateOrgHashCodes: templateOrgHashCodes,
          dirty: true,
          needUpdateParentIdItem
        });

      case DELETE_ORGANIZATION_CHART_OFFSPRING_ITEMS:
        items = state.items;
        const currentItem = (action as DeleteOrganizationChartOffspringItemsAction).item;
        // 查找被删除节点的后代节点
        const offsprings = findOffsprings(items, currentItem);

        // 被删除的后代节点中是否有根节点（项目经理）
        const newItems = -1 !== _.findIndex(offsprings, { parent_node_path: ORGANIZATION_CHART_ROOT_PARENT_NODE_PATH })
          ? deleteOffspringsIncludingRoot(items, offsprings)
          : deleteItems(items, offsprings);

        needUpdateParentIdItem = fixNeedUpdateParentIdItem(newItems, state.needUpdateParentIdItem);

        templateOrgHashCodes = getInUsingOrganizationTemplateHashCodes(newItems, state.organizationTemplateOrgHashCodes);
        allItems = formAllItems(state.currentAllItems, newItems);
        itemsTransformed = engineersAndMultifunctionalTeams2NormalItems(newItems, state.editEnable);
        allItemsTransformed = engineersAndMultifunctionalTeams2NormalItems(allItems, state.editEnable);
        return Object.assign({}, state, {
          items: newItems,
          itemsTransformed,
          itemsTransformedWithoutDeleted: removeItemsTransformedDeleted(itemsTransformed),
          layers: items2Layers(newItems),
          layersTransformed: items2Layers(itemsTransformed),
          layersWithoutDeletedItems: items2Layers(newItems, true),
          layersWithoutDeletedItemsTransformed: items2Layers(itemsTransformed, true),
          allItems,
          allLayers: items2Layers(allItems),
          allLayersTransformed: items2Layers(allItemsTransformed),
          allLayersWithoutDeletedItems: items2Layers(allItems, true),
          allLayersWithoutDeletedItemsTransformed: items2Layers(allItemsTransformed, true),
          organizationTemplateOrgHashCodes: templateOrgHashCodes,
          dirty: true,
          needUpdateParentIdItem
        });

      case ADD_TEMPLATE_ORGANIZATION_CHART_ITEMS:
        return addTemplateOrganizationChartItems(state, action);

      case SET_ORGANIZATION_TEMPLATE_ORG_HASH_CODES:
        const organizationTemplateOrgHashCodes = (action as SetOrganizationTemplateOrgHashCodesAction).organizationTemplateOrgHashCodes;
        return Object.assign({}, state, {
          organizationTemplateOrgHashCodes
        });

      case ADD_ORGANIZATION_TEMPLATE_ORG_HASH_CODE:
        items = state.items;
        const organizationTemplateOrgHashCode = (action as AddOrganizationTemplateOrgHashCodeAction).organizationTemplateOrgHashCode;
        templateOrgHashCodes = getInUsingOrganizationTemplateHashCodes(items, state.organizationTemplateOrgHashCodes);
        // 新增
        const existTemplateOrgHashCode = _.find(templateOrgHashCodes, { template_org_id: organizationTemplateOrgHashCode.template_org_id });
        if (existTemplateOrgHashCode) { // 已存在，则更新hash值
          existTemplateOrgHashCode.template_hash_code = organizationTemplateOrgHashCode.template_hash_code;
        } else {  // 不存在，则新增
          templateOrgHashCodes = [...templateOrgHashCodes, organizationTemplateOrgHashCode];
        }
        return Object.assign({}, state, {
          organizationTemplateOrgHashCodes: templateOrgHashCodes
        });

      case DELETE_ORGANIZATION_TEMPLATE_ORG_HASH_CODE:
        items = state.items;
        const deleteTemplateOrgId = (action as DeleteOrganizationTemplateOrgHashCodeAction).templateOrgId;
        templateOrgHashCodes = getInUsingOrganizationTemplateHashCodes(items, state.organizationTemplateOrgHashCodes);
        templateOrgHashCodes = _.reject(templateOrgHashCodes, { template_org_id: deleteTemplateOrgId });
        return Object.assign({}, state, {
          organizationTemplateOrgHashCodes: templateOrgHashCodes
        });

      case SET_ORGANIZATION_NEED_UPDATE_PARENT_ID_ITEM:
        needUpdateParentIdItem = (action as SetOrganizationChartNeedUpdateParentIdItemAction).item;
        return Object.assign({}, state, {
          needUpdateParentIdItem
        });

      default:
        return state;
    }
  };

// 根据分支根节点的path，从完整树上查找该分支
function findBranch(branchRootPath: string, allItems: IOrganizationChartItem[]): IOrganizationChartItem[] {
  const pathArr = _.split(branchRootPath, '/');
  pathArr.pop();
  const branchRootName = pathArr.pop();
  pathArr.push('');
  const branchRootParentNodePath = pathArr.join('/');
  const branchRoot = _.find(allItems, { parent_node_path: branchRootParentNodePath, name: branchRootName }); // 当前分支的根
  const offsprings = findOffsprings(allItems, branchRoot);
  return [...offsprings, branchRoot];
}

const getState = (state: OrganizationChartState): OrganizationChartState => state;
export const getOrganizationChartItems = createSelector(
  getState,
  (state: OrganizationChartState) => state.items
);

export const getOrganizationChartItemsTransformed = createSelector(
  getState,
  (state: OrganizationChartState) => state.itemsTransformed
);

export const getOrganizationChartItemsTransformedWithoutDeleted = createSelector(
  getState,
  (state: OrganizationChartState) => state.itemsTransformedWithoutDeleted
);

// 组织层级树（包含标记为删除的节点）
export const getOrganizationChartLayers = createSelector(
  getState,
  (state: OrganizationChartState) => state.layers
);

export const getOrganizationChartLayersTransformed = createSelector(
  getState,
  (state: OrganizationChartState) => state.layersTransformed
);

// 组织层级树（不包含标记为删除的节点）
export const getOrganizationChartLayersWithoutDeletedItems = createSelector(
  getState,
  (state: OrganizationChartState) => state.layersWithoutDeletedItems
);

export const getOrganizationChartLayersWithoutDeletedItemsTransformed = createSelector(
  getState,
  (state: OrganizationChartState) => state.layersWithoutDeletedItemsTransformed
);

export const getOrganizationChartAllItems = createSelector(
  getState,
  (state: OrganizationChartState) => state.allItems
);

export const getOrganizationChartAllLayers = createSelector(
  getState,
  (state: OrganizationChartState) => state.allLayers
);

export const getOrganizationChartAllLayersTransformed = createSelector(
  getState,
  (state: OrganizationChartState) => state.allLayersTransformed
);

export const getOrganizationChartAllLayersWithoutDeletedItems = createSelector(
  getState,
  (state: OrganizationChartState) => state.allLayersWithoutDeletedItems
);

export const getOrganizationChartAllLayersWithoutDeletedItemsTransformed = createSelector(
  getState,
  (state: OrganizationChartState) => state.allLayersWithoutDeletedItemsTransformed
);

export const getOrganizationChartCurrentItems = createSelector(
  getState,
  (state: OrganizationChartState) => findBranch(state.path, state.currentAllItems)
);

export const getOrganizationChartCurrentLayers = createSelector(
  getState,
  (state: OrganizationChartState) => items2Layers(findBranch(state.path, state.currentAllItems))
);

export const getOrganizationChartCurrentLayersTransformed = createSelector(
  getState,
  (state: OrganizationChartState) => items2Layers(
    engineersAndMultifunctionalTeams2NormalItems(findBranch(state.path, state.currentAllItems), state.editEnable)
  )
);

export const getOrganizationChartCurrentAllItems = createSelector(
  getState,
  (state: OrganizationChartState) => state.currentAllItems
);

export const getOrganizationChartCurrentAllLayers = createSelector(
  getState,
  (state: OrganizationChartState) => state.currentAllLayers
);

export const getOrganizationChartCurrentAllItemsTransformed = createSelector(
  getState,
  (state: OrganizationChartState) => state.currentAllItemsTransformed
);

export const getOrganizationChartCurrentAllLayersTransformed = createSelector(
  getState,
  (state: OrganizationChartState) => state.currentAllLayersTransformed
);

export const getOrganizationChartPreviewItems = createSelector(
  getState,
  (state: OrganizationChartState) => findBranch(state.path, state.previewAllItems)
);

export const getOrganizationChartPreviewLayers = createSelector(
  getState,
  (state: OrganizationChartState) => items2Layers(findBranch(state.path, state.previewAllItems))
);

export const getOrganizationChartPreviewLayersTransformed = createSelector(
  getState,
  (state: OrganizationChartState) => items2Layers(
    engineersAndMultifunctionalTeams2NormalItems(findBranch(state.path, state.previewAllItems), state.editEnable)
  )
);

export const getOrganizationChartPreviewAllItems = createSelector(
  getState,
  (state: OrganizationChartState) => state.previewAllItems
);

export const getOrganizationChartPreviewAllLayers = createSelector(
  getState,
  (state: OrganizationChartState) => state.previewAllLayers
);

export const getOrganizationChartPreviewAllLayersTransformed = createSelector(
  getState,
  (state: OrganizationChartState) => state.previewAllLayersTransformed
);

export const getOrganizationChartPreviewAllItemsTransformed = createSelector(
  getState,
  (state: OrganizationChartState) => state.previewAllItemsTransformed
);

export const getOrganizationChartPreviewAllLayersWithoutDeletedItems = createSelector(
  getState,
  (state: OrganizationChartState) => state.previewAllLayersWithoutDeletedItems
);

export const getOrganizationChartPreviewAllLayersWithoutDeletedItemsTransformed = createSelector(
  getState,
  (state: OrganizationChartState) => state.previewAllLayersWithoutDeletedItemsTransformed
);

export const getCheckedOrganizationChartItem = createSelector(
  getState,
  (state: OrganizationChartState) => state.checkedItem
);

export const getOrganizationChartEditEnable = createSelector(
  getState,
  (state: OrganizationChartState) => state.editEnable
);

export const getOrganizationChartDirty = createSelector(
  getState,
  (state: OrganizationChartState) => state.dirty
);

export const getOrganizationChartViewType = createSelector(
  getState,
  (state: OrganizationChartState) => state.viewType
);

export const getOrganizationTemplateOrgHashCodes = createSelector(
  getState,
  (state: OrganizationChartState) => state.organizationTemplateOrgHashCodes
);

export const getOrganizationChartNeedUpdateParentIdItem = createSelector(
  getState,
  (state: OrganizationChartState) => state.needUpdateParentIdItem
);

