import {
  Reducer,
  combineReducers
} from 'redux';

import {
  CommonState,
  CommonReducer
} from './common.reducer';
export * from './common.reducer';

import {
  WorkflowState,
  WorkflowReducer
} from './workflow.reducer';
export * from './workflow.reducer';

import {
  OrganizationChartState,
  OrganizationChartReducer,
} from './organization-chart.reducer';
export * from './organization-chart.reducer';

import {
  AddOrganizationChartItemState,
  CheckAddOrganizationChartItemReducer
} from './add-organization-chart-item.reducer';
export * from './add-organization-chart-item.reducer';

import {
  PlanTreeState,
  PlanTreeReducer
} from './plan-tree.reducer';
export * from './plan-tree.reducer';

import {
  MilestoneState,
  MilestoneReducer
} from './milestone.reducer';
export * from './milestone.reducer';

import {
  CheckUsersState,
  CheckUsersReducer
} from './check-users.reducer';
export * from './check-users.reducer';

import {
  CheckProjectsState,
  CheckProjectsReducer
} from './check-projects.reducer';
export * from './check-projects.reducer';

import {
  DeliverableState,
  DeliverableReducer
} from './deliverable.reducer';
export * from './deliverable.reducer';

import {
  DeliverablesState,
  DeliverablesReducer
} from './deliverables.reducer';
export * from './deliverables.reducer';

import {
  DigitalDeliverableState,
  DigitalDeliverableReducer
} from './digital-deliverable.reducer';
export * from './digital-deliverable.reducer';

import {
  AttachmentsState,
  AttachmentsReducer
} from './attachments.reducer';
export * from './attachments.reducer';

import {
  TurnState,
  TurnReducer
} from './turn.reducer';
export * from './turn.reducer';

import {
  ChatState,
  ChatReducer
} from './chat.reducer';
export * from './chat.reducer';

import {
  ProjectFiltersState,
  ProjectFiltersReducer
} from './project-filters.reducer';
export * from './project-filters.reducer';

import {
  SaveNotifyState,
  SaveNotifyReducer
} from './save-notify.reducer';
export * from './save-notify.reducer';

import {
  DialogState,
  DialogReducer
} from './dialog.reducer';
export * from './dialog.reducer';

import {
  CcState,
  CcReducer
} from './cc.reducer';
export * from './cc.reducer';

import {
  CcOrgState,
  CcOrgsReducer
} from './cc-organization.reducer';
export * from './cc-organization.reducer';

import {
  CheckOrganizationUsersState,
  CheckOrganizationUsersReducer
} from './check-organization-users.reducer';
export * from './check-organization-users.reducer';

import {
  RequirementState,
  RequirementReducer
} from './requirement.reducer';
export * from './requirement.reducer';

import {
  RequirementAttachmentsState,
  RequirementAttachmentsReducer
} from './requirement-attachments.reducer';
export * from './requirement-attachments.reducer';
export interface AppState {
  common: CommonState;
  workflow: WorkflowState;
  organizationChart: OrganizationChartState;
  addOrganizationChartItem: AddOrganizationChartItemState;
  planTree: PlanTreeState;
  milestone: MilestoneState;
  checkUsers: CheckUsersState;
  checkProjects: CheckProjectsState;
  deliverables: DeliverablesState;
  deliverable: DeliverableState;
  digitalDeliverable: DigitalDeliverableState;
  attachments: AttachmentsState;
  turn: TurnState;
  chat: ChatState;
  projectFilters: ProjectFiltersState;
  saveNotify: SaveNotifyState;
  dialog: DialogState;
  cc: CcState;
  ccOrg: CcOrgState;
  checkOrganizationUsers: CheckOrganizationUsersState;
  requirement: RequirementState;
  requirementAttachments: RequirementAttachmentsState;
}

const rootReducer: Reducer<AppState> = combineReducers<AppState>({
  common: CommonReducer,
  workflow: WorkflowReducer,
  organizationChart: OrganizationChartReducer,
  addOrganizationChartItem: CheckAddOrganizationChartItemReducer,
  planTree: PlanTreeReducer,
  milestone: MilestoneReducer,
  checkUsers: CheckUsersReducer,
  checkProjects: CheckProjectsReducer,
  deliverables: DeliverablesReducer,
  deliverable: DeliverableReducer,
  digitalDeliverable: DigitalDeliverableReducer,
  turn: TurnReducer,
  attachments: AttachmentsReducer,
  chat: ChatReducer,
  projectFilters: ProjectFiltersReducer,
  saveNotify: SaveNotifyReducer,
  dialog: DialogReducer,
  cc: CcReducer,
  ccOrg: CcOrgsReducer,
  checkOrganizationUsers: CheckOrganizationUsersReducer,
  requirement: RequirementReducer,
  requirementAttachments: RequirementAttachmentsReducer
});

export default rootReducer;
