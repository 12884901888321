import { Reducer, Action } from 'redux';
import {
  SetPlanTreeEditEnableAction,
  SetPlanTreeDirtyAction,
  SetPlanTreeEditingPlanAction,
  SetPlanTreeProjectAction,
  SetPlanTreeMilestonesAction,
  CheckPlanTreeMilestoneAction,
  SetPlanTreePlansAction,
  SetPlanTreeDirtyPlansAction,
  AddPlanTreePlanAction,
  AddPlanTreePlansAction,
  DeletePlanTreePlanAction,
  DeletePlanTreePlansAction,
  UpdatePlanTreePlanAction,
  ToggleCheckPlanTreePlanAction,
  ToggleFoldPlanTreePlanAction,
  SetPlanTreeCurrentMilestoneIsCheckedAllAction,
  SetPlanTreeCurrentPlanAction,
  SetPlanTreeShowIssueApprovedPlansAction,
  SET_PLAN_TREE_EDIT_ENABLE,
  SET_PLAN_TREE_DIRTY,
  SET_PLAN_TREE_EDITING_PLAN,
  SET_PLAN_TREE_PROJECT,
  SET_PLAN_TREE_MILESTONES,
  CHECK_PLAN_TREE_MILESTONE,
  SET_PLAN_TREE_PLANS,
  SET_PLAN_TREE_DIRTY_PLANS,
  ADD_PLAN_TREE_PLAN,
  ADD_PLAN_TREE_PLANS,
  DELETE_PLAN_TREE_PLAN,
  DELETE_PLAN_TREE_PLANS,
  UPDATE_PLAN_TREE_PLAN,
  TOGGLE_CHECK_PLAN_TREE_PLAN,
  TOGGLE_FOLD_PLAN_TREE_PLAN,
  SET_PLAN_TREE_CURRENT_MILESTONE_IS_CHECKED_ALL,
  SET_PLAN_TREE_CURRENT_PLAN,
  SET_PLAN_TREE_SHOW_ISSUE_APPROVED_PLANS,
} from '../actions/plan-tree.actions';
import { createSelector } from 'reselect';

import _ from 'lodash';
import { IPlan, IShowTreeItem, IMilestone, IProject } from 'src/app/models';

import { TreeService } from 'src/app/services/tree.service';
import { EAuditStatus } from 'src/app/enums';
const treeService = new TreeService();


export interface PlanTreeState {
  editEnable: boolean;
  editingPlan: IPlan; // 正在编辑中的计划（进入标准、风险计划编辑页面）
  project: IProject; // 当前项目
  milestones: IMilestone[]; // 节点
  currentMilestone: IMilestone; //  当前节点
  plans: IPlan[]; // 计划
  showPlans: IShowTreeItem[]; // 用于树形展示的计划
  dirtyPlans: IPlan[]; // 变更或新增的计划
  currentMilestonePlans: IPlan[]; // 当前节点的计划
  currentMilestoneShowPlans: IShowTreeItem[]; // 当前节点用于树形展示的计划
  currentMilestoneIsCheckedAll: boolean;  // 当前节点的计划全部选中（不包含以审批通过的，审批通过的不可check）
  currentMilestoneCheckedPlans: IPlan[];  // 当前节点选中的计划
  foldedPlanIds: string[];  // 折叠状态的计划id
  currentPlan: IPlan;
  dirty: boolean;
  showIssueApprovedPlans: boolean; // 是否显示已下发审批通过的的计划
}

const initialState: PlanTreeState = {
  editEnable: false,
  editingPlan: undefined,
  project: undefined,
  milestones: [],
  currentMilestone: undefined,
  plans: [],
  showPlans: [],
  dirtyPlans: [],
  currentMilestonePlans: [],
  currentMilestoneShowPlans: [],
  currentMilestoneIsCheckedAll: false,
  currentMilestoneCheckedPlans: [],
  foldedPlanIds: [],
  currentPlan: undefined,
  dirty: false,
  showIssueApprovedPlans: true,
};

function plans2showPlans(plans: IPlan[], foldedPlanIds: string[]): IShowTreeItem[] {
  return treeService.flatTreeItems2showTreeItems<IPlan, IShowTreeItem>(plans, foldedPlanIds);
}


// 计算各节点下的新增、变更计划的数量
function getMilestonesWithDirtyPlansCount(milestones: IMilestone[], dirtyPlans: IPlan[]): IMilestone[] {
  const newMilestones = _.cloneDeep(milestones);
  _.forEach(newMilestones, milestone => {
    milestone._dirty_plans_count = _.filter(dirtyPlans, { milestone_code: milestone.code }).length;
  });
  return newMilestones;
}

export const PlanTreeReducer: Reducer<PlanTreeState> =
  (state: PlanTreeState = initialState, action: Action): PlanTreeState => {
    let plan: IPlan;
    let plans: IPlan[];
    let dirtyPlans: IPlan[];
    let milestones: IMilestone[];
    let currentMilestone: IMilestone;
    let currentMilestonePlans: IPlan[];
    let currentMilestoneUnapprovedPlans: IPlan[]; // 当前节点下，未被审批通过的计划
    let currentMilestoneCheckedPlans: IPlan[];
    let currentMilestoneIsCheckedAll: boolean;
    let foldedPlanIds: string[];
    switch (action.type) {
      case SET_PLAN_TREE_EDIT_ENABLE:
        const editEnable = (action as SetPlanTreeEditEnableAction).editEnable;
        return Object.assign({}, state, { editEnable });

      case SET_PLAN_TREE_DIRTY:
        const dirty = (action as SetPlanTreeDirtyAction).dirty;
        return Object.assign({}, state, { dirty });

      case SET_PLAN_TREE_EDITING_PLAN:
        plan = (action as SetPlanTreeEditingPlanAction).plan;
        return Object.assign({}, state, { editingPlan: plan });

      case SET_PLAN_TREE_PROJECT:
        const project = (action as SetPlanTreeProjectAction).project;
        return Object.assign({}, state, { project });

      case SET_PLAN_TREE_MILESTONES:
        plans = state.plans;
        foldedPlanIds = state.foldedPlanIds;
        dirtyPlans = state.dirtyPlans;
        milestones = (action as SetPlanTreeMilestonesAction).milestones;
        if (milestones.length) {
          currentMilestone = milestones[0];
          if (plans.length) {
            currentMilestonePlans = _.filter(plans, { project_milestone_id: currentMilestone.id });
            // 计算各节点的dirtyPlan数量
            if (dirtyPlans.length) {
              milestones = getMilestonesWithDirtyPlansCount(milestones, dirtyPlans);
            }
            return Object.assign({}, state, {
              milestones,
              currentMilestone,
              currentMilestonePlans,
              currentMilestoneShowPlans: plans2showPlans(currentMilestonePlans, foldedPlanIds)
            });
          } else {
            return Object.assign({}, state, { milestones, currentMilestone });
          }
        } else {
          return state;
        }

      case CHECK_PLAN_TREE_MILESTONE:
        plans = state.plans;
        foldedPlanIds = state.foldedPlanIds;
        currentMilestone = (action as CheckPlanTreeMilestoneAction).milestone;
        currentMilestonePlans = currentMilestone ? _.filter(plans, { project_milestone_id: currentMilestone.id }) : [];
        currentMilestoneCheckedPlans = [];
        currentMilestoneIsCheckedAll = false;
        return Object.assign({}, state, {
          currentMilestone,
          currentMilestonePlans,
          currentMilestoneShowPlans: plans2showPlans(currentMilestonePlans, foldedPlanIds),
          currentMilestoneCheckedPlans,
          currentMilestoneIsCheckedAll
        });

      case SET_PLAN_TREE_PLANS:
        foldedPlanIds = state.foldedPlanIds;
        currentMilestone = state.currentMilestone;
        plans = (action as SetPlanTreePlansAction).plans;
        if (currentMilestone) {
          currentMilestonePlans = _.filter(plans, { project_milestone_id: currentMilestone.id });
          return Object.assign({}, state, {
            plans,
            showPlans: plans2showPlans(plans, foldedPlanIds),
            currentMilestonePlans,
            currentMilestoneShowPlans: plans2showPlans(currentMilestonePlans, foldedPlanIds)
          });
        } else {
          return Object.assign({}, state, {
            plans,
            showPlans: plans2showPlans(plans, foldedPlanIds)
          });
        }

      case SET_PLAN_TREE_DIRTY_PLANS:
        milestones = state.milestones;
        dirtyPlans = (action as SetPlanTreeDirtyPlansAction).plans;
        return Object.assign({}, state, {
          milestones: getMilestonesWithDirtyPlansCount(milestones, dirtyPlans),
          dirtyPlans
        });

      case ADD_PLAN_TREE_PLAN:
        milestones = state.milestones;
        plans = state.plans;
        dirtyPlans = state.dirtyPlans;
        foldedPlanIds = state.foldedPlanIds;
        currentMilestone = state.currentMilestone;
        currentMilestonePlans = state.currentMilestonePlans;
        plan = (action as AddPlanTreePlanAction).plan;
        plan.is_can_split = true; // 计划可分解（有权限添加，添加的计划即可分解）
        plan.is_can_update = true; // 计划可变更（有权限添加，添加的计划即可变更）
        plan._is_issue_approved = false;  // 下发通过状态
        plan._is_new = true; // 新增计划
        plan._min_target_end_time = new Date();
        console.log('add plan:', plan);
        plans = [...plans, plan];
        dirtyPlans = [...dirtyPlans, plan];
        if (plan.milestone_code === currentMilestone.code) {
          currentMilestonePlans = [...currentMilestonePlans, plan];
        }
        return Object.assign({}, state, {
          milestones: getMilestonesWithDirtyPlansCount(milestones, dirtyPlans),
          plans,
          dirtyPlans,
          showPlans: plans2showPlans(plans, foldedPlanIds),
          currentMilestonePlans,
          currentMilestoneShowPlans: plans2showPlans(currentMilestonePlans, foldedPlanIds),
          currentMilestoneIsCheckedAll: false,
          dirty: true
        });

      case ADD_PLAN_TREE_PLANS:
        milestones = state.milestones;
        plans = state.plans;
        dirtyPlans = state.dirtyPlans;
        foldedPlanIds = state.foldedPlanIds;
        currentMilestone = state.currentMilestone;
        currentMilestonePlans = state.currentMilestonePlans;
        const newPlans = (action as AddPlanTreePlansAction).plans;
        _.forEach(newPlans, newPlan => {
          newPlan.is_can_split = true; // 计划可分解（有权限添加，添加的计划即可分解）
          newPlan.is_can_update = true; // 计划可变更（有权限添加，添加的计划即可变更）
          newPlan._is_issue_approved = false;  // 下发通过状态
          newPlan._is_new = true; // 计划变更时新增加的计划
          newPlan._min_target_end_time = new Date();
        });
        console.log('add plans:', plans);
        plans = _.concat(plans, newPlans);
        dirtyPlans = _.concat(dirtyPlans, newPlans);
        const currentMilestoneNewPlans = _.filter(newPlans, { milestone_code: currentMilestone.code });
        currentMilestonePlans = _.concat(currentMilestonePlans, currentMilestoneNewPlans);
        return Object.assign({}, state, {
          milestones: getMilestonesWithDirtyPlansCount(milestones, dirtyPlans),
          plans,
          dirtyPlans,
          showPlans: plans2showPlans(plans, foldedPlanIds),
          currentMilestonePlans,
          currentMilestoneShowPlans: plans2showPlans(currentMilestonePlans, foldedPlanIds),
          currentMilestoneIsCheckedAll: false,
          dirty: true
        });

      case DELETE_PLAN_TREE_PLAN:
        milestones = state.milestones;
        plans = state.plans;
        dirtyPlans = state.dirtyPlans;
        foldedPlanIds = state.foldedPlanIds;
        currentMilestone = state.currentMilestone;
        currentMilestoneCheckedPlans = state.currentMilestoneCheckedPlans;
        plan = (action as DeletePlanTreePlanAction).plan;

        plans = _.reject(plans, { parent_id: plan.id }); // 删除子计划
        plans = _.reject(plans, { id: plan.id }); // 删除计划

        dirtyPlans = _.reject(dirtyPlans, { parent_id: plan.id });
        dirtyPlans = _.reject(dirtyPlans, { id: plan.id });

        currentMilestonePlans = currentMilestone ? _.filter(plans, { project_milestone_id: currentMilestone.id }) : [];
        currentMilestoneCheckedPlans = _.reject(currentMilestoneCheckedPlans, { parent_id: plan.id });
        currentMilestoneCheckedPlans = _.reject(currentMilestoneCheckedPlans, { id: plan.id });

        currentMilestoneUnapprovedPlans = _.reject(currentMilestonePlans, { audit_status: EAuditStatus.Approved });
        currentMilestoneIsCheckedAll = currentMilestoneUnapprovedPlans.length
          && currentMilestoneUnapprovedPlans.length === currentMilestoneCheckedPlans.length;
        return Object.assign({}, state, {
          milestones: getMilestonesWithDirtyPlansCount(milestones, dirtyPlans),
          plans,
          dirtyPlans,
          showPlans: plans2showPlans(plans, foldedPlanIds),
          currentMilestonePlans,
          currentMilestoneShowPlans: plans2showPlans(currentMilestonePlans, foldedPlanIds),
          currentMilestoneCheckedPlans,
          currentMilestoneIsCheckedAll,
          dirty: true
        });

      case DELETE_PLAN_TREE_PLANS:
        milestones = state.milestones;
        plans = state.plans;
        dirtyPlans = state.dirtyPlans;
        foldedPlanIds = state.foldedPlanIds;
        currentMilestone = state.currentMilestone;
        currentMilestoneCheckedPlans = state.currentMilestoneCheckedPlans;
        const deletePlans = (action as DeletePlanTreePlansAction).plans;
        const deletePlanIds = _.map(deletePlans, 'id');
        plans = _.reject(plans, p => { // 删除子计划
          return _.includes(deletePlanIds, p.parent_id);
        });
        plans = _.reject(plans, p => { // 删除计划
          return _.includes(deletePlanIds, p.id);
        });

        dirtyPlans = _.reject(dirtyPlans, p => { // 删除子计划
          return _.includes(deletePlanIds, p.parent_id);
        });
        dirtyPlans = _.reject(dirtyPlans, p => { // 删除计划
          return _.includes(deletePlanIds, p.id);
        });

        currentMilestonePlans = currentMilestone ? _.filter(plans, { project_milestone_id: currentMilestone.id }) : [];
        currentMilestoneCheckedPlans = _.reject(currentMilestoneCheckedPlans, p => { // 删除子计划
          return _.includes(deletePlanIds, p.parent_id);
        });
        currentMilestoneCheckedPlans = _.reject(currentMilestoneCheckedPlans, p => { // 删除计划
          return _.includes(deletePlanIds, p.id);
        });

        currentMilestoneUnapprovedPlans = _.reject(currentMilestonePlans, { audit_status: EAuditStatus.Approved });
        currentMilestoneIsCheckedAll = currentMilestoneUnapprovedPlans.length
          && currentMilestoneUnapprovedPlans.length === currentMilestoneCheckedPlans.length;
        return Object.assign({}, state, {
          milestones: getMilestonesWithDirtyPlansCount(milestones, dirtyPlans),
          plans,
          dirtyPlans,
          showPlans: plans2showPlans(plans, foldedPlanIds),
          currentMilestonePlans,
          currentMilestoneShowPlans: plans2showPlans(currentMilestonePlans, foldedPlanIds),
          currentMilestoneCheckedPlans,
          currentMilestoneIsCheckedAll,
          dirty: true
        });

      case UPDATE_PLAN_TREE_PLAN:
        milestones = state.milestones;
        plans = state.plans;
        dirtyPlans = state.dirtyPlans;
        currentMilestone = state.currentMilestone;
        foldedPlanIds = state.foldedPlanIds;
        plan = (action as UpdatePlanTreePlanAction).plan;
        plan._is_issue_approved = false;  // 下发通过状态

        dirtyPlans = _.reject(dirtyPlans, { id: plan.id });
        dirtyPlans.push(plan);

        const updateIndex = _.findIndex(plans, { id: plan.id });
        if (-1 === updateIndex) {
          return state;
        } else {
          plans = [
            ...plans.slice(0, updateIndex),
            plan,
            ...plans.slice(updateIndex + 1)
          ];
          currentMilestonePlans = currentMilestone ? _.filter(plans, { project_milestone_id: currentMilestone.id }) : [];
          return Object.assign({}, state, {
            milestones: getMilestonesWithDirtyPlansCount(milestones, dirtyPlans),
            plans,
            showPlans: plans2showPlans(plans, foldedPlanIds),
            dirtyPlans,
            currentMilestonePlans,
            currentMilestoneShowPlans: plans2showPlans(currentMilestonePlans, foldedPlanIds),
            dirty: true
          });
        }

      case TOGGLE_CHECK_PLAN_TREE_PLAN:
        currentMilestonePlans = state.currentMilestonePlans;
        currentMilestoneCheckedPlans = state.currentMilestoneCheckedPlans;
        plan = (action as ToggleCheckPlanTreePlanAction).plan;
        const isChecked = (action as ToggleCheckPlanTreePlanAction).isChecked;
        if (isChecked) {
          currentMilestoneCheckedPlans = _.unionBy([...currentMilestoneCheckedPlans, plan], 'id');
        } else {
          currentMilestoneCheckedPlans = _.reject(currentMilestoneCheckedPlans, { id: plan.id });
        }

        currentMilestoneUnapprovedPlans = _.reject(currentMilestonePlans, { audit_status: EAuditStatus.Approved });
        currentMilestoneIsCheckedAll = currentMilestoneUnapprovedPlans.length
          && currentMilestoneUnapprovedPlans.length === currentMilestoneCheckedPlans.length;

        return Object.assign({}, state, {
          currentMilestoneIsCheckedAll,
          currentMilestoneCheckedPlans
        });

      case TOGGLE_FOLD_PLAN_TREE_PLAN:
        plans = state.plans;
        currentMilestonePlans = state.currentMilestonePlans;
        foldedPlanIds = state.foldedPlanIds;
        plan = (action as ToggleFoldPlanTreePlanAction).plan;
        if (_.includes(foldedPlanIds, plan.id)) {
          foldedPlanIds = _.without(foldedPlanIds, plan.id);
        } else {
          foldedPlanIds.push(plan.id);
        }
        return Object.assign({}, state, {
          foldedPlanIds,
          currentMilestoneShowPlans: plans2showPlans(currentMilestonePlans, foldedPlanIds),
          showPlans: plans2showPlans(plans, foldedPlanIds)
        });

      case SET_PLAN_TREE_CURRENT_MILESTONE_IS_CHECKED_ALL:
        plans = state.plans;
        currentMilestone = state.currentMilestone;
        currentMilestoneIsCheckedAll = (action as SetPlanTreeCurrentMilestoneIsCheckedAllAction).isCheckedAll;
        currentMilestonePlans = currentMilestone ? _.filter(plans, { project_milestone_id: currentMilestone.id }) : [];
        currentMilestoneUnapprovedPlans = _.reject(currentMilestonePlans, { audit_status: EAuditStatus.Approved });
        currentMilestoneCheckedPlans = currentMilestoneIsCheckedAll ? currentMilestoneUnapprovedPlans : [];
        return Object.assign({}, state, {
          currentMilestoneIsCheckedAll,
          currentMilestonePlans,
          currentMilestoneCheckedPlans
        });

      case SET_PLAN_TREE_CURRENT_PLAN:
        plan = (action as SetPlanTreeCurrentPlanAction).plan;
        return Object.assign({}, state, {
          currentPlan: plan
        });

      case SET_PLAN_TREE_SHOW_ISSUE_APPROVED_PLANS:
        const showIssueApprovedPlans = (action as SetPlanTreeShowIssueApprovedPlansAction).showIssueApprovedPlans;
        return Object.assign({}, state, {
          showIssueApprovedPlans
        });

      default:
        return state;
    }
  };

const getState = (state: PlanTreeState): PlanTreeState => state;
export const getPlanTreeEditEnable = createSelector(
  getState,
  (state: PlanTreeState) => state.editEnable
);

export const getPlanTreeDirty = createSelector(
  getState,
  (state: PlanTreeState) => state.dirty
);

export const getPlanTreeEditingPlan = createSelector(
  getState,
  (state: PlanTreeState) => state.editingPlan
);

export const getPlanTreeProject = createSelector(
  getState,
  (state: PlanTreeState) => state.project
);


export const getPlanTreeMilestones = createSelector(
  getState,
  (state: PlanTreeState) => state.milestones
);

export const getPlanTreeCurrentMilestone = createSelector(
  getState,
  (state: PlanTreeState) => state.currentMilestone
);

export const getPlanTreeCurrentMilestoneIsCheckedAll = createSelector(
  getState,
  (state: PlanTreeState) => state.currentMilestoneIsCheckedAll
);

export const getPlanTreePlans = createSelector(
  getState,
  (state: PlanTreeState) => state.plans
);

export const getPlanTreeDirtyPlans = createSelector(
  getState,
  (state: PlanTreeState) => state.dirtyPlans
);

export const getPlanTreeCurrentMilestoneShowPlans = createSelector(
  getState,
  (state: PlanTreeState) => state.currentMilestoneShowPlans
);

export const getPlanTreeShowPlans = createSelector(
  getState,
  (state: PlanTreeState) => state.showPlans
);

export const getPlanTreeCurrentMilestoneCheckedPlans = createSelector(
  getState,
  (state: PlanTreeState) => state.currentMilestoneCheckedPlans
);

export const getPlanTreeCurrentPlan = createSelector(
  getState,
  (state: PlanTreeState) => state.currentPlan
);

export const getPlanTreeShowIssueApprovedPlans = createSelector(
  getState,
  (state: PlanTreeState) => state.showIssueApprovedPlans
);

