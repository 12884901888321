// *工时节假日类型
export enum EWorktimeHolidayType {
    /// <summary>
    /// 工作日
    /// </summary>
    WorkDay = 10,

    /// <summary>
    /// 节假日
    /// </summary>
    Holiday = 20
}
