import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

import {
  PermissionGuard,
  ProjectGroupGuard,
  DivisionGuard,
  ProjectCategoryGuard,
  IssueTransferGuard,
  OrganizationAuthorizationGuard,
  AdminDropGuard,
  AdminOaTodoGuard,
  AdminApprovalTransferGuard,
  PerformanceRuleConfigGuard,
  ScoreReferenceGuard,
  PerformanceApprovalGuard,
  LaborCostGuard,
  AuthorizationGuard,
  routeChildrenGuard,
  SetupGuard,
} from 'src/app/guards';
import { SystemAdminComponent } from './components/system-admin/system-admin.component';
import { HelpDocumentRemsComponent } from './modules/portal/components/help-document-rems/help-document-rems.component';
import { ManagementCenterComponent } from './components/management-center/management-center.component';
import { ProjectInfoDropdownGuard } from './guards/project-info-dropdown.guards';

const routes: Routes = [{
  path: '',
  canActivateChild: [routeChildrenGuard],
  children: [
    { path: '', redirectTo: 'work-center', pathMatch: 'full' },
    {
      path: 'portal',
      loadChildren: () => import('./modules/portal/portal.module').then(mod => mod.PortalModule)
    }, {
      path: 'work-center',
      loadChildren: () => import('./modules/work-center/work-center.module').then(mod => mod.WorkCenterModule),
    }, {
      path: 'project-center',
      loadChildren: () => import('./modules/project-center/project-center.module').then(mod => mod.ProjectCenterModule)
    }, {
      path: 'requirement-center',
      loadChildren: () => import('./modules/requirement-center/requirement-center.module').then(mod => mod.RequirementCenterModule)
    }, {
      path: 'report',
      loadChildren: () => import('./modules/report/report.module').then(mod => mod.ReportModule)
    }, {
      path: 'login',
      loadChildren: () => import('./modules/login/login.module').then(mod => mod.LoginModule)
    },
    {
      path: 'management-center',
      component: ManagementCenterComponent,
      children: [
        { path: '', redirectTo: 'system-admin', pathMatch: 'full' },
        {
          path: 'system-admin',
          component: SystemAdminComponent,
          children: [
            {
              path: '', redirectTo: 'division', pathMatch: 'full'
            }, {
              path: 'division',
              canActivate: [DivisionGuard],
              loadChildren: () => import('./modules/division/division.module').then(mod => mod.DivisionModule)
            }, {
              path: 'admin-drop',
              canActivate: [AdminDropGuard],
              loadChildren: () => import('./modules/admin-drop/admin-drop.module').then(
                mod => mod.AdminDropModule
              )
            }, {
              path: 'admin-oa-todo',
              canActivate: [AdminOaTodoGuard],
              loadChildren: () => import('./modules/admin-oa-todo/admin-oa-todo.module').then(
                mod => mod.AdminOaTodoModule
              )
            }, {
              path: 'performance',
              canActivate: [PerformanceRuleConfigGuard],
              loadChildren: () => import('./modules/performance-rule-config/performance-rule-config.module').then(
                mod => mod.PerformanceRuleConfigModule
              )
            }, {
              path: 'performance-approval',
              canActivate: [PerformanceApprovalGuard],
              loadChildren: () => import('./modules/performance-approval/performance-approval.module').then(
                mod => mod.PerformanceApprovalModule
              )
            },
            {
              path: 'worktime-rules',
              loadChildren: () => import('./modules/worktime-rules/worktime-rules.module').then(
                mod => mod.WorktimeRulesModule
              )
            },
            {
              path: 'score-reference',
              canActivate: [ScoreReferenceGuard],
              loadChildren: () => import('./modules/score-reference/score-reference.module').then(
                mod => mod.ScoreReferenceModule
              )
            },
            {
              path: 'part-auth',
              loadChildren: () => import('./modules/part-auth/part-auth.module').then(
                mod => mod.PartAuthModule
              )
            },
            {
              path: 'bpm-leave-record',
              loadChildren: () => import('./modules/bpm-leave-record/bpm-leave-record.module').then(
                mod => mod.BpmLeaveRecordModule
              )
            },
            {
              path: 'management-admin-query',
              loadChildren: () => import('./modules/management-admin-query/management-admin-query.module').then(
                mod => mod.ManagementAdminQueryModule
              )
            },
            {
              path: 'property',
              loadChildren: () => import('./modules/property-config/property-config.module').then(
                mod => mod.PropertyConfigModule
              )
            },
            {
              path: 'engin-field',
              loadChildren: () => import('./modules/engin-field/engin-field.module').then(
                mod => mod.EnginFieldModule
              )
            },
          ]
        },
        {
          path: 'permission',
          canActivate: [PermissionGuard],
          loadChildren: () => import('./modules/permission/permission.module').then(mod => mod.PermissionModule)
        }, {
          path: 'organization-authorization',
          canActivate: [OrganizationAuthorizationGuard],
          loadChildren: () => import('./modules/organization-authorization/organization-authorization.module').then(
            mod => mod.OrganizationAuthorizationModule
          )
        }, {
          path: 'issue-transfer',
          canActivate: [IssueTransferGuard],
          loadChildren: () => import('./modules/issue-transfer/issue-transfer.module').then(mod => mod.IssueTransferModule)
        }, {
          path: 'project-info-dropdown',
          canActivate: [ProjectInfoDropdownGuard],
          loadChildren: () => import('./modules/project-info-dropdown/project-info-dropdown.module').then(mod => mod.ProjectInfoDropdownModule)
        }, {
          path: 'project-group',
          canActivate: [ProjectGroupGuard],
          loadChildren: () => import('./modules/project-group/project-group.module').then(mod => mod.ProjectGroupModule)
        }, {
          path: 'project-category',
          canActivate: [ProjectCategoryGuard],
          loadChildren: () => import('./modules/project-category/project-category.module').then(mod => mod.ProjectCategoryModule)
        }, {
          path: 'template',
          loadChildren: () => import('./modules/template/template.module').then(mod => mod.TemplateModule)
        }, {
          path: 'project-collection',
          loadChildren: () => import('./modules/project-collection/project-collection.module').then(mod => mod.ProjectCollectionModule)
        }, {
          path: 'standard-interface',
          loadChildren: () => import('./modules/standard-interface/standard-interface.module').then(
            mod => mod.StandardInterfaceModule
          )
        }, {
          path: 'worktime-approver',
          loadChildren: () => import('./modules/worktime-approver/worktime-approver.module').then(
            mod => mod.WorktimeApproverModule
          )
        },
        {
          path: 'authorization',
          canActivate: [AuthorizationGuard],
          loadChildren: () => import('./modules/authorization/authorization.module').then(
            mod => mod.AuthorizationModule
          )
        },
        {
          path: 'labor-cost',
          canActivate: [LaborCostGuard],
          loadChildren: () => import('./modules/labor-cost/labor-cost.module').then(
            mod => mod.LaborCostModule
          )
        },
        {
          path: 'system-auth',
          canActivate: [AuthorizationGuard],
          loadChildren: () => import('./modules/system-auth/system-auth.module').then(
            mod => mod.SystemAuthModule
          )
        },
      ]
    },

    {
      path: 'help-document', component: HelpDocumentRemsComponent
    },
  ]
},
{
  path: 'setup',
  loadChildren: () => import('./modules/setup/setup.module').then(mod => mod.SetupModule),
  canActivate: [SetupGuard],
},

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
