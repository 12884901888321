/// <summary>
/// 人力成本维护type
/// </summary>
export enum ECostLevelType {
  /// <summary>
  /// 职级
  /// </summary>
  Hierarchy = 10,

  /// <summary>
  /// 试验试制工人
  /// </summary>
  Trial = 20,

  /// <summary>
  /// 一般管理技术
  /// </summary>
  Commonly = 30,
}
