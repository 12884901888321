// *审批状态
export enum EAuditStatus {
    /// <summary>
    /// 未提交
    /// </summary>
    UnSubmitted = 0,
    /// <summary>
    /// 审批中
    /// </summary>
    Reviewing = 10,
    /// <summary>
    /// 通过
    /// </summary>
    Approved = 20,
    /// <summary>
    /// 驳回
    /// </summary>
    Rejected = 30,
    /// <summary>
    /// 废弃
    /// </summary>
    Abandoned = -1
}
