import { Injectable } from '@angular/core';
import { IWorkflowTemplate, IWorkflowTemplateParams, IWorkflowTemplateUpdateCommand } from 'src/app/models';
import { IWorkflowStep } from 'src/app/models';
import { Observable, of, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { IWorkflowTemplateType } from 'src/app/models';
import { SharedService } from './shared.service';
import { EWorkflowStepType } from '../enums';


@Injectable({
  providedIn: 'root'
})
export class TemplateService {
  constructor(
    private http: HttpClient,
    private sharedService: SharedService
    ) {
    //  this.postData();
    // this.updateData();
    // this.updateFlowList();
    // this.getFlowList();
  }

  steps1: IWorkflowStep[] = [{
    type: EWorkflowStepType.Start,
    name: '开始',
    default_handler_role_id: 20,
    default_handler_role_name: '提交人',
    enable_delete_step: false,
    enable_delete_person: false,
    person_limit: 1,
    remark: '',
    rank: 0

  }, {
    type: EWorkflowStepType.Normal,
    name: '审核',
    default_handler_role_id: 0,
    default_handler_role_name: '',
    enable_delete_step: false,
    enable_delete_person: false,
    person_limit: -1,
    remark: '',
    rank: 1
  }, {
    type: EWorkflowStepType.Normal,
    name: '批准',
    default_handler_role_id: 0,
    default_handler_role_name: '',
    enable_delete_step: false,
    enable_delete_person: false,
    person_limit: -1,
    remark: '',
    rank: 2

  }, {
    type: EWorkflowStepType.End,
    name: '结束',
    default_handler_role_id: 0,
    default_handler_role_name: '',
    enable_delete_step: false,
    enable_delete_person: false,
    person_limit: 0,
    remark: '',
    rank: 3

  }];
  steps2: IWorkflowStep[] = [{
    type: EWorkflowStepType.Start,
    name: '开始',
    default_handler_role_id: 20,
    default_handler_role_name: '提交人',
    enable_delete_step: false,
    enable_delete_person: false,
    person_limit: 1,
    remark: '',
    rank: 0

  }, {
    type: EWorkflowStepType.Normal,
    name: '审核',
    default_handler_role_id: 0,
    default_handler_role_name: '',
    enable_delete_step: false,
    enable_delete_person: false,
    person_limit: -1,
    remark: '',
    rank: 1

  }, {
    type: EWorkflowStepType.Normal,
    name: '会签',
    default_handler_role_id: 40,
    default_handler_role_name: '后置计划责任人',
    enable_delete_step: false,
    enable_delete_person: false,
    person_limit: -1,
    remark: '',
    rank: 2
  }, {
    type: EWorkflowStepType.Normal,
    name: '审定',
    default_handler_role_id: 0,
    default_handler_role_name: '',
    enable_delete_step: true,
    enable_delete_person: true,
    person_limit: -1,
    remark: '',
    rank: 3

  }, {
    type: EWorkflowStepType.Normal,
    name: '管理员',
    default_handler_role_id: 30,
    default_handler_role_name: '上级责任人',
    enable_delete_step: true,
    enable_delete_person: true,
    person_limit: -1,
    remark: '',
    rank: 4

  }, {
    type: EWorkflowStepType.Normal,
    name: '批准',
    default_handler_role_id: 0,
    default_handler_role_name: '',
    enable_delete_step: false,
    enable_delete_person: false,
    person_limit: -1,
    remark: '',
    rank: 5
  }, {
    type: EWorkflowStepType.End,
    name: '结束',
    default_handler_role_id: 0,
    default_handler_role_name: '',
    enable_delete_step: false,
    enable_delete_person: false,
    person_limit: 0,
    remark: '',
    rank: 6

  }];
  steps3: IWorkflowStep[] = [{
    type: EWorkflowStepType.Start,
    name: '开始',
    default_handler_role_id: 20,
    default_handler_role_name: '提交人',
    enable_delete_step: false,
    enable_delete_person: false,
    person_limit: 1,
    remark: '',
    rank: 0

  }, {
    type: EWorkflowStepType.Normal,
    name: '审核',
    default_handler_role_id: 0,
    default_handler_role_name: '',
    enable_delete_step: false,
    enable_delete_person: true,
    person_limit: -1,
    remark: '',
    rank: 1

  }, {
    type: EWorkflowStepType.Normal,
    name: '管理员',
    default_handler_role_id: 30,
    default_handler_role_name: '上级责任人',
    enable_delete_step: false,
    enable_delete_person: false,
    person_limit: -1,
    remark: '',
    rank: 2
  }, {
    type: EWorkflowStepType.Normal,
    name: '批准',
    default_handler_role_id: 0,
    default_handler_role_name: '',
    enable_delete_step: true,
    enable_delete_person: true,
    person_limit: -1,
    remark: '',
    rank: 3
  }, {
    type: EWorkflowStepType.End,
    name: '结束',
    default_handler_role_id: 0,
    default_handler_role_name: '',
    enable_delete_step: false,
    enable_delete_person: false,
    person_limit: 0,
    remark: '',
    rank: 4

  }];
  steps4: IWorkflowStep[] = [{
    type: EWorkflowStepType.Start,
    name: '开始',
    default_handler_role_id: 20,
    default_handler_role_name: '提交人',
    enable_delete_step: false,
    enable_delete_person: false,
    person_limit: 1,
    remark: '',
    rank: 0

  }, {
    type: EWorkflowStepType.Normal,
    name: '审核',
    default_handler_role_id: 0,
    default_handler_role_name: '',
    enable_delete_step: true,
    enable_delete_person: true,
    person_limit: -1,
    remark: '',
    rank: 1
  }, {
    type: EWorkflowStepType.Normal,
    name: '项目管理经理',
    default_handler_role_id: 10,
    default_handler_role_name: '项目管理经理',
    enable_delete_step: false,
    enable_delete_person: false,
    person_limit: -1,
    remark: '',
    rank: 2
  }, {
    type: EWorkflowStepType.Normal,
    name: '项目经理',
    default_handler_role_id: 10,
    default_handler_role_name: '项目经理',
    enable_delete_step: true,
    enable_delete_person: true,
    person_limit: -1,
    remark: '',
    rank: 3
  }, {
    type: EWorkflowStepType.Normal,
    name: '批准',
    default_handler_role_id: 0,
    default_handler_role_name: '',
    enable_delete_step: true,
    enable_delete_person: true,
    person_limit: -1,
    remark: '',
    rank: 4
  }, {
    type: EWorkflowStepType.End,
    name: '结束',
    default_handler_role_id: 0,
    default_handler_role_name: '',
    enable_delete_step: false,
    enable_delete_person: false,
    person_limit: 0,
    remark: '',
    rank: 5

  }];
  steps5: IWorkflowStep[] = [{
    type: EWorkflowStepType.Start,
    name: '开始',
    default_handler_role_id: 20,
    default_handler_role_name: '提交人',
    enable_delete_step: false,
    enable_delete_person: false,
    person_limit: 1,
    remark: '',
    rank: 0
  }, {
    type: EWorkflowStepType.Normal,
    name: '审核',
    default_handler_role_id: 0,
    default_handler_role_name: '',
    enable_delete_step: true,
    enable_delete_person: true,
    person_limit: -1,
    remark: '',
    rank: 1

  }, {
    type: EWorkflowStepType.Normal,
    name: '批准',
    default_handler_role_id: 0,
    default_handler_role_name: '',
    enable_delete_step: false,
    enable_delete_person: true,
    person_limit: -1,
    remark: '',
    rank: 2

  }, {
    type: EWorkflowStepType.End,
    name: '结束',
    default_handler_role_id: 0,
    default_handler_role_name: '',
    enable_delete_step: false,
    enable_delete_person: false,
    person_limit: 0,
    remark: '',
    rank: 3

  }];

  // templates: ITemplate[] = [
  //   {
  //     code: 'issue-approval',
  //     name: '下发审批流程',
  //     data: {steps: this.steps1}
  //   },
  //   {
  //     code: 'standard-plan-receipt-approval',
  //     name: '标准计划回执流程',
  //     data: {steps: this.steps2}
  //   }, {
  //     code: 'special-plan-receipt-approval',
  //     name: '专项计划回执流程',
  //     data: {steps: this.steps3}
  //   }, {
  //     code: 'milestone-receipt-approval',
  //     name: '节点过阀审批流程',
  //     data: {steps: this.steps4}
  //   }, {
  //     code: 'update-approval',
  //     name: '变更审批流程',
  //     data: {steps: this.steps5}
  //   }
  // ];

  flowUrl = '/api/templates/projects/flows';
  typeUrl = '/api/templates/projects/flows/user-type';
  code = 'update-approval';

  updateUrl = `${this.flowUrl}/${this.code}`;
  updateFlowList() {
    this.http.put<IWorkflowTemplate>(this.updateUrl,
      {
            code: 'update-approval',
            name: '变更审批流程',
            data: {
              steps: this.steps5,
              tips: ''
            }
          },
    ).subscribe(data => console.log('update--', data));
  }

  getWorkflowTemplates(params: IWorkflowTemplateParams): Observable<IWorkflowTemplate[]> {
    params = this.sharedService.omitEmptyParams<IWorkflowTemplateParams>(params);
    return this.http.get<IWorkflowTemplate[]>(`${this.flowUrl}`, { params: params as HttpParams}).pipe(
      catchError(this.sharedService.handleError<IWorkflowTemplate[]>('get workflow templates', []))
    );
  }
  getWorkflowTemplate(params: IWorkflowTemplateParams): Observable<IWorkflowTemplate> {
    return this.http.get<IWorkflowTemplate>(`${this.flowUrl}/${params.code}`).pipe(
      catchError(this.sharedService.handleError<IWorkflowTemplate>(`get plan template: ${params.code}`, undefined))
    );
  }

  updateWorkflowTemplate(workflowTemplateUpdateCommand: IWorkflowTemplateUpdateCommand): Observable<IWorkflowTemplate> {
   return this.http.put<IWorkflowTemplate>(`${this.flowUrl}/${workflowTemplateUpdateCommand.code}`, workflowTemplateUpdateCommand).pipe(
      catchError(this.sharedService.handleError<any>('update workflow template', false))
   );
  }

  getDefaultRole(): Observable<IWorkflowTemplateType[]> {
    return this.http.get<IWorkflowTemplateType[]>(`${this.typeUrl}`).pipe(
      catchError(this.sharedService.handleError<IWorkflowTemplateType[]>('get workflow template type', []))
    );
  }
}
