<div *ngIf="!items.length" class="text-center mt-5">
  <div><i class="fa fa-mixcloud fa-5x fa-fw text-secondary"></i></div>
  <div class="text-secondary"><b>暂无信息</b></div>
</div>
<div *ngIf="items && items.length">
  <form [formGroup]="radioForm">
    <table class="table-sm table-bordered table-hover table-fixed text-center align-middle">
      <thead>
        <tr>
          <th style="width:40px;"></th>
          <th style="width:60px;">序号</th>
          <th style="width:100px;">姓名</th>
          <th style="width:120px;">用户名</th>
          <th>部门</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of items; let i = index;" (click)="checkItem(item)">
          <td class="masked">
            <input type="radio" [id]="item.user_id" [value]="item.user_id" [formControl]="radio">
          </td>
          <td>{{(page - 1) * limit + i + 1}}</td>
          <td class="ellipsis">{{item.name}}</td>
          <td class="ellipsis">{{item.user_id}}</td>
          <td class="text-left ellipsis" [title]="item.org_full_path">{{item.org_full_path}}</td>
        </tr>
      </tbody>
    </table>
    <div class="pagination-box" *ngIf="tabValue !== 1 || tabValue === 1 && q">
      <app-pagination
        [pageLinkLimit]="pageLinkLimit"
        [page]="page"
        [limit]="limit"
        [count]="count"
        (pageChanged)="onPageChanged($event)"
      ></app-pagination>
    </div>
  </form>
</div>