// 若未初始化完成，强制跳转至初始化页面
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree, CanActivateChild } from '@angular/router';
import { SharedService } from '../services/shared.service';
import { Observable } from 'rxjs';
import { SetupService } from '../services/setup.service';

@Injectable({
  providedIn: 'root',
})
// tslint:disable-next-line:class-name
export class routeChildrenGuard implements CanActivateChild {
  constructor(
    private router: Router,
    private setupService: SetupService,
    private sharedService: SharedService
  ) { }
  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if (!this.setupService.getSetupIsDone()) {
      this.router.navigate(['/setup']);
      return false;
    }
    return true;
  }
}