<form [formGroup]="radioForm">
  <table class="table-sm table-bordered table-hover table-fixed text-center align-middle">
    <thead>
      <tr>
        <th class="masked" style="width:40px;">
        </th>
        <th>名称</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of items;" (click)="checkItem(item)">
        <td class="masked">
          <input type="radio" [id]="item.org_number" [value]="item.org_number" [formControl]="radio">
        </td>
        <td class="text-left ellipsis" [title]="item.org_full_path">{{item.org_full_path}}</td>
      </tr>
    </tbody>
  </table>
</form>
