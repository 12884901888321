<div class="ez-pagination">
  <span class="mr-3">共 {{ count }} 项</span>
  <span class="mr-3 font-12">每页 {{ limit }} 项</span>
  <pagination
    [boundaryLinks]="true"
    [maxSize]="pageLinkLimit"
    [totalItems]="count"
    [ngModel]="page"
    [itemsPerPage]="limit"
    [customFirstTemplate]="firstTemplate"
    [customLastTemplate]="lastTemplate"
    [customPreviousTemplate]="prevTemplate"
    [customNextTemplate]="nextTemplate"
    [customPageTemplate]="pageTemplate"
    (pageChanged)="onPageChanged($event)"
  >
  </pagination>
</div>

<!-- 自定义第一页模板 -->
<ng-template #firstTemplate>
  <span aria-hidden="true"><i class="fa fa-angle-double-left"></i></span>
</ng-template>

<!-- 自定义最后一页模板 -->
<ng-template #lastTemplate>
  <span aria-hidden="true"><i class="fa fa-angle-double-right"></i></span>
</ng-template>

<!-- 自定义上一页模板 -->
<ng-template #prevTemplate>
  <span aria-hidden="true"><i class="fa fa-angle-left"></i></span>
</ng-template>

<!-- 自定义下一页模板 -->
<ng-template #nextTemplate>
  <span aria-hidden="true"><i class="fa fa-angle-right"></i></span>
</ng-template>

<!-- 自定义页码模板 -->
<ng-template #pageTemplate> {{ page }}/{{ pageCount() }} </ng-template>
