<ng-template #checkUsers>
  <div class="modal-header">
    <h5 [ngSwitch]="checkUsersType" class="modal-title">
      <div *ngSwitchCase="checkUsersTypeEnum.ProjectManagementAdmin">管理经理列表</div>
      <div *ngSwitchCase="checkUsersTypeEnum.OrganizationUsers">所属单位人员列表</div>
      <div *ngSwitchCase="checkUsersTypeEnum.RecommendedUsers">推荐用户列表</div>
      <div *ngSwitchCase="checkUsersTypeEnum.Approver">审批人列表</div>
      <div *ngSwitchCase="checkUsersTypeEnum.WorktimeUsers">工时填报人列表</div>
      <div *ngSwitchDefault>用户列表</div>
    </h5>
    <button type="button" class="close" (click)="hide()">
      <i class="fa fa-times"></i>
    </button>
  </div>

  <div class="modal-body">
    <form class="mb-3" [formGroup]="filterForm" (ngSubmit)="search()">
      <div class="input-group">
        <input type="text" class="form-control" placeholder="姓名、用户名" formControlName="q">
        <div class="input-group-append">
          <button class="btn btn-primary">
            <i class="fa fa-search mr-1"></i>查询
          </button>
        </div>
      </div>
    </form>

    <form [formGroup]="checkForm">
      <table class="table-sm table-bordered table-hover table-fixed text-center align-middle">
        <thead>
          <tr (click)="toggleCheckAll()">
            <th class="masked" style="width:40px;">
              <input type="checkbox" [checked]="isCheckedAll">
            </th>
            <th style="width:60px;">序号</th>
            <th style="width:120px;">姓名</th>
            <th style="width:150px;">用户名</th>
            <th>部门</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of items; let i=index;" (click)="toggleCheckItem(item)">
            <td [ngSwitch]="checkUsersType" class="masked">
              <input *ngSwitchCase="checkUsersTypeEnum.ProjectManagementAdmin" type="checkbox" [id]="item.id"
                [formControl]="checkForm.controls[item.id]">
              <input *ngSwitchDefault type="checkbox" [id]="item.user_id"
                [formControl]="checkForm.controls[item.user_id]">
            </td>
            <td *ngIf="!isLately">{{(page - 1) * limit + i + 1}}</td>
            <td *ngIf="isLately">{{i + 1}}</td>
            <td class="ellipsis">{{item.name}}</td>
            <td class="ellipsis">{{item.user_id}}</td>
            <td class="text-left ellipsis" [title]="item.org_full_path">{{item.org_full_path}}</td>
          </tr>
        </tbody>
      </table>
    </form>

    <div class="pagination-box" *ngIf="!isLately">
      <app-pagination
        [pageLinkLimit]="pageLinkLimit"
        [page]="page"
        [limit]="limit"
        [count]="count"
        (pageChanged)="onPageChanged($event)"
      ></app-pagination>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-primary mr-2" [disabled]="!checkedItems.length" (click)="confirm()">
      确定
    </button>
    <button type="button" class="btn btn-outline-secondary" (click)="hide()">
      返回
    </button>
  </div>
</ng-template>