// 零部件成熟度授权类型
export enum EPartAuthType {
    /// <summary>
    /// 人员
    /// </summary>
    Personal = 10,
    /// <summary>
    /// 项目角色
    /// </summary>
    ProjectRole = 20,
    /// <summary>
    /// 项目组织角色
    /// </summary>
    ProjectOrgRole = 30
}
