import { Component, OnInit, ViewChild, TemplateRef, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, FormArray, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ReduxService } from 'src/app/redux/redux.service';
import _ from 'lodash';
import { IDeliverableItem, IAlert, IMilestone } from 'src/app/models';
import { SubscriptionService } from 'src/app/services/subscription.service';
import { AlertService } from 'src/app/services/alert.service';

@Component({
  selector: 'app-digital-deliverable',
  templateUrl: './digital-deliverable.component.html',
  styleUrls: ['./digital-deliverable.component.scss']
})
export class DigitalDeliverableComponent implements OnInit {

  @Output()
  itemChange: EventEmitter<IDeliverableItem> = new EventEmitter();
  editEnable: boolean;
  item: IDeliverableItem;
  myForm: FormGroup;
  saleConceptTargetForm: FormGroup;
  otherForm: FormGroup;
  qualityTargetForm: FormGroup;
  digitalDeliverable;
  // tslint:disable-next-line: variable-name
  concept_sales_target: any;
  // tslint:disable-next-line: variable-name
  quality_target: any;
  othersCopy: any;
  others: any;
  items: IDeliverableItem[] = [];
  milestones: IMilestone[];
  lowerCaseMilestones: IMilestone[];

  modalRef: BsModalRef;
  @ViewChild('digitalDeliverable') modalEle: TemplateRef<any>;

  constructor(
    private modalService: BsModalService,
    private reduxService: ReduxService,
    private fb: FormBuilder,
    private subscriptionService: SubscriptionService,
    private alertService: AlertService
  ) {
    this.saleConceptTargetForm = this.fb.group({
      lifetime_years: ['1', Validators.required],
      concept_report_code: ['', Validators.required],
      concept_report_name: ['', Validators.required],
      approved_report_name: ['', Validators.required],
      approved_report_code: ['', Validators.required],
      sops: this.fb.array([
        this.fb.group({
          sop1: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
          sop2: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
          sop3: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
          sop4: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
          sop5: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
          sop6: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
          sop7: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
          sop8: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
          sop9: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
          sop10: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
          sop11: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
          sop12: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
        })
      ]),
    });

    this.otherForm = this.fb.group({
      product_additional_value_rates: this.fb.group({}),
      vehicle_cost_targets: this.fb.group({}),
      project_budgets: this.fb.group({})
    });

    this.qualityTargetForm = this.fb.group({
      subject_assessment: ['', Validators.required],
      special_parts_pass_rates: this.fb.group({
        experimental_vehicle_B: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
        experimental_vehicle_C: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
        product_start_PPV: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
        product_start_PP: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
        product_start_SOP: ['', [Validators.required, Validators.pattern('^[0-9]*$')]]
      }),
      audit_deduction_items: this.fb.group({
        experimental_vehicle_B: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
        experimental_vehicle_C: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
        product_start_PPV: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
        product_start_PP: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
        product_start_SOP: ['', [Validators.required, Validators.pattern('^[0-9]*$')]]
      }),
      ppap_complete_rate: this.fb.group({
        g3_target: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
        g2_sop_target: ['', [Validators.required, Validators.pattern('^[0-9]*$')]]
      }),
      vehicle_iptv: this.fb.group({
        parts_target: ['', [Validators.required, Validators.pattern('^[0-9]*$')]]
      })
    });

    this.myForm = this.fb.group({
      concept_sales_target: this.saleConceptTargetForm,
      quality_target: this.qualityTargetForm,
      others: this.otherForm,
    });
  }

  ngOnInit() {
    this.init();
  }

  get sops() {
    return this.saleConceptTargetForm.get('sops') as FormArray;
  }
  get specialPartsPassRatesForm() {
    return this.qualityTargetForm.get('special_parts_pass_rates') as FormGroup;
  }
  get auditDeductionItemsForm() {
    return this.qualityTargetForm.get('audit_deduction_items') as FormGroup;
  }
  get ppapCompleteRateForm() {
    return this.qualityTargetForm.get('ppap_complete_rate') as FormGroup;
  }
  get vehicleIptvForm() {
    return this.qualityTargetForm.get('vehicle_iptv') as FormGroup;
  }
  get productAdditionalValueRatesForm() {
    return this.otherForm.get('product_additional_value_rates') as FormGroup;
  }
  get vehicleCostTargetsForm() {
    return this.otherForm.get('vehicle_cost_targets') as FormGroup;
  }
  get projectBudgetsForm() {
    return this.otherForm.get('project_budgets') as FormGroup;
  }



  initOthersForm() {
    // milestones根据项目获取
    _.forEach(this.lowerCaseMilestones, item => {
      // tslint:disable-next-line:max-line-length
      this.productAdditionalValueRatesForm.addControl(item.code, new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$')]));
      this.vehicleCostTargetsForm.addControl(item.code, new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$')]));
      this.projectBudgetsForm.addControl(item.code, new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$')]));
    });
  }
  // 动态表单
  init() {
    let flag = 0;
    this.saleConceptTargetForm.controls.lifetime_years.valueChanges.subscribe(year => {
      if (this.sops.length > year) {
        while (this.sops.length - year > 0) {
          this.sops.removeAt(this.sops.length - 1);
        }
      } else {
        while (year - this.sops.length > 0) {
          this.sops.push(
            this.fb.group({
              sop1: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
              sop2: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
              sop3: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
              sop4: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
              sop5: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
              sop6: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
              sop7: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
              sop8: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
              sop9: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
              sop10: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
              sop11: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
              sop12: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
            }));
        }
      }
      // 只有在第一次进入编辑页时赋值
      if (this.concept_sales_target && flag === 0) {
        this.sops.patchValue(this.concept_sales_target.sops);
        flag = 1;
      }
    });
  }

  initData() {
    // 初始数据为空时
    if (JSON.stringify(this.digitalDeliverable) === '{}') {
    } else {
      // 有初始数据时
      this.concept_sales_target = this.digitalDeliverable.concept_sales_target;
      this.quality_target = this.digitalDeliverable.quality_target;
      // 修改历史数据-> 将整车IPTV的数据填到零部件目标中
      if (_.get(this.quality_target, 'vehicle_iptv') && typeof (this.quality_target.vehicle_iptv) === 'string') {
        this.quality_target.vehicle_iptv = { parts_target: this.quality_target.vehicle_iptv };
      }
      this.others = this.digitalDeliverable.others;
    }
  }

  initMyForm() {
    if (JSON.stringify(this.digitalDeliverable) === '{}') {
      this.myForm.reset();
      this.saleConceptTargetForm.controls.lifetime_years.setValue('1');
    } else {
      this.myForm.patchValue(this.digitalDeliverable);
    }
  }

  show(item?: IDeliverableItem, editEnable?: boolean, milestones?: IMilestone[]) {
    this.milestones = milestones;
    // milestone.code 转为小写属性绑定
    this.toLowerCaseMilestone();
    this.item = _.cloneDeep(item);
    this.editEnable = editEnable;
    this.digitalDeliverable = this.item.items[1].report_data;
    this.initData();
    this.initOthersForm();
    this.initMyForm();

    if (this.modalRef) {
      return;
    }
    this.modalRef = this.modalService.show(
      this.modalEle,
      {
        backdrop: false, // 不显示背景
        class: 'as-page'  // modal作为页面
      }
    );
  }

  toLowerCaseMilestone() {
    this.lowerCaseMilestones = _.cloneDeep(this.milestones);
    _.forEach(this.lowerCaseMilestones, milestone => {
      milestone.code = milestone.code.toLowerCase();
    });
  }

  isNum(value: any) {
    value = value.target.value;
    if (!(/(^[1-9]\d*$)/.test(value))) {
      const alert: IAlert = {
        type: 'danger',
        title: '项目生命周期必须为正整数',
      };
      this.subscriptionService.publishAlert(alert);
      this.saleConceptTargetForm.controls.lifetime_years.setValue('');
      return false;
    }
  }
  hide() {
    if (this.modalRef) {
      this.modalRef.hide();
      this.modalRef = undefined;
      this.reduxService.cancelDigitalDeliverable();
    }
  }

  confirm() {
    const data = this.myForm.value;
    data.valid = this.myForm.valid;
    this.itemChange.emit(data);
    this.alert('提交成功');
    this.hide();
  }

  save() {
    const data = this.myForm.value;
    data.valid = this.myForm.valid;
    this.itemChange.emit(data);
    this.alert('暂存成功');
  }

  alert(title: string) {
    const alert: IAlert = {
      type: 'success',
      title,
      content: []
    };
    this.alertService.addAlert(alert);
  }

  Number(value: any, title: string) {
    value = value.target.value;
    const EMAIL_REG = new RegExp('^[0-9]*$');
    if (!(EMAIL_REG.test(value))) {
      const alert: IAlert = {
        type: 'danger',
        title: `${title}必须为整数`,
      };
      this.subscriptionService.publishAlert(alert);
      return false;
    }
  }


}



