<ul class="nav">
  <li
    class="nav-item"
    *ngFor="let item of items"
    [routerLink]="[item.routerLink]"
    routerLinkActive="active"
  >
    <a class="nav-link" href="javascript:;">
      <span class="title">{{ item.label }}</span>
      <span *ngIf="item.badge" class="badge badge-pill badge-danger">{{
        item.badge
      }}</span>
    </a>
  </li>
</ul>
