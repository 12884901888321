import { Component, OnInit } from '@angular/core';
import { IUserInfo } from 'src/app/models';
import { SystemService } from 'src/app/services/system.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {
  currentUser: IUserInfo;

  constructor(
    private systemService: SystemService,
    private userService: UserService,
  ) { }

  ngOnInit(): void {
    this.getCurrentUser();
  }


  getCurrentUser() {
    this.currentUser = this.userService.getCurrentUserSync();
  }

  logout() {
    sessionStorage.clear();
    localStorage.clear();
    window.location.href = this.systemService.getSsoLogoutUrlSync();
  }

}
