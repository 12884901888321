import { Action, ActionCreator } from 'redux';
import { ISaveNotifyCallback } from 'src/app/models';

// #region 显示
export interface ShowSaveNotifyAction extends Action {
  confirmCallback: ISaveNotifyCallback;
  cancelCallback: ISaveNotifyCallback;
}

export const SHOW_SAVE_NOTIFY = 'SHOW_SAVE_NOTIFY';
export const showSaveNotify: ActionCreator<ShowSaveNotifyAction> = (
  confirmCallback: ISaveNotifyCallback,
  cancelCallback: ISaveNotifyCallback
) => ({
  type: SHOW_SAVE_NOTIFY,
  confirmCallback,
  cancelCallback
});
// #endregion

// #region 确定
// tslint:disable-next-line: no-empty-interface
export interface ConfirmSaveNotifyAction extends Action {
}

export const CONFIRM_SAVE_NOTIFY = 'CONFIRM_SAVE_NOTIFY';
export const confirmSaveNotify: ActionCreator<ConfirmSaveNotifyAction> = () => ({
  type: CONFIRM_SAVE_NOTIFY
});
// #endregion

// #region 取消
// tslint:disable-next-line: no-empty-interface
export interface CancelSaveNotifyAction extends Action {
}

export const CANCEL_SAVE_NOTIFY = 'CANCEL_SAVE_NOTIFY';
export const cancelSaveNotify: ActionCreator<CancelSaveNotifyAction> = () => ({
  type: CANCEL_SAVE_NOTIFY
});
// #endregion
