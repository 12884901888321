import { Component, OnInit } from '@angular/core';
import _ from 'lodash';

import { IAlert } from 'src/app/models';
import { AlertService } from 'src/app/services/alert.service';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {
  alerts: IAlert[] = [];

  constructor(
    private alertService: AlertService
  ) {
  }

  ngOnInit() {
    this.alerts = this.alertService.alerts;
  }

  close(dismissedAlert: any): void {
    this.alerts = _.reject(this.alerts, dismissedAlert);
  }

}
