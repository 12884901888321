// *计划状态
export enum EPlanStatus {
    /// <summary>
    /// 待下发
    /// </summary>
    ToIssue = 0,
    /// <summary>
    /// 待接收
    /// </summary>
    ToReceive = 10,
    /// <summary>
    /// 待回执
    /// </summary>
    ToReceipt = 20,
    /// <summary>
    /// 已回执（回执完成情况为“申请完成”，且审批通过）
    /// </summary>
    Receipted = 30,
    /// <summary>
    /// 已取消（回执完成情况为“申请取消”，并审批通过）
    /// </summary>
    Cancelled = -1
}
