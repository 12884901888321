// β维护类型
export enum EBetaMaintainType {
    /// <summary>
    /// PMT经理打分
    /// </summary>
    ToPMT = 10,
    /// <summary>
    /// 工程师打分
    /// </summary>
    ToEngineer = 20
}
