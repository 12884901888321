import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import {
  IAlert,
  ILoadingCallback
} from 'src/app/models';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {
  loading$: Subject<ILoadingCallback> = new Subject<ILoadingCallback>(); // 加载中
  pureLoading$: Subject<boolean> = new Subject<boolean>(); // 加载中（没有callback）
  alert$: Subject<IAlert> = new Subject<IAlert>();
  todoApprovalCount$: Subject<number> = new Subject<number>();  // 流程审批待处理数量
  todoReceiptCount$: Subject<number> = new Subject<number>(); // 计划回执待处理数量
  todoWorktimeCount$: Subject<number> = new Subject<number>(); // 工时管理待处理数量
  todoPerformanceCount$: Subject<number> = new Subject<number>(); // 绩效管理待处理数量
  todoRequirementCount$: Subject<number> = new Subject<number>(); // 需求管理待处理数量
  todoMyRequirementCount$: Subject<number> = new Subject<number>();// 我的需求草稿数量
  todoProjectCount$: Subject<number> = new Subject<number>(); // 信息维护待处理数量
  reloadOrganizationAuth$: Subject<any> = new Subject<any>(); // 重新加载组织管理权限
  needGetCenterDirector$: Subject<any> = new Subject<any>(); // 重新获取中心主任
  needSaveAudit$: Subject<any> = new Subject<any>(); // 需要暂存
  hideAddChildPlanDropDown$: Subject<boolean> = new Subject<boolean>(); // 计划树，关闭“添加子计划”下拉菜单
  getRelationPlans$: Subject<any> = new Subject<any>(); // 工时填报，需要重新获取关联计划列表
  getApprovers$: Subject<any> = new Subject<any>(); // 工时填报，项目变更时，需要重新获取审批人节点默认带人
  afterVirtualScrollInit$: Subject<boolean> = new Subject<boolean>(); // 绩效报批，虚拟滚动afterViewInit
  getSetup$: Subject<any> = new Subject<any>(); // 初始化设置，每完成一步，需重新获取

  constructor() { }

  publishLoading(callback: ILoadingCallback) {
    this.loading$.next(callback);
  }
  subscribeLoading(): Observable<ILoadingCallback> {
    return this.loading$;
  }

  publishPureLoading(isLoading: boolean) {
    this.pureLoading$.next(isLoading);
  }
  subscribePureLoading(): Observable<boolean> {
    return this.pureLoading$;
  }

  publishAlert(alert: IAlert) {
    this.alert$.next(alert);
  }
  subscribeAlert(): Observable<IAlert> {
    return this.alert$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
    );
  }

  publishTodoApprovalCount(count: number) {
    this.todoApprovalCount$.next(count);
  }
  subscribeTodoApprovalCount(): Observable<number> {
    return this.todoApprovalCount$;
  }

  publishTodoReceiptCount(count: number) {
    this.todoReceiptCount$.next(count);
  }
  subscribeTodoReceiptCount(): Observable<number> {
    return this.todoReceiptCount$;
  }

  publishTodoWorktimeCount(count: number) {
    this.todoWorktimeCount$.next(count);
  }
  subscribeTodoWorktimeCount(): Observable<number> {
    return this.todoWorktimeCount$;
  }

  publishTodoPerformanceCount(count: number) {
    this.todoPerformanceCount$.next(count);
  }
  subscribeTodoPerformanceCount(): Observable<number> {
    return this.todoPerformanceCount$;
  }

  publishTodoRequirementCount(count: number) {
    this.todoRequirementCount$.next(count);
  }
  subscribeTodoRequirementCount(): Observable<number> {
    return this.todoRequirementCount$;
  }

  publishTodoMyRequirementCount(count: number) {
    this.todoMyRequirementCount$.next(count);
  }
  subscribeTodoMyRequirementCount(): Observable<number> {
    return this.todoMyRequirementCount$;
  }

  publishTodoProjectCount(count: number) {
    this.todoProjectCount$.next(count);
  }
  subscribeTodoProjectCount(): Observable<number> {
    return this.todoProjectCount$;
  }

  publishReloadOrganizationAuth() {
    this.reloadOrganizationAuth$.next();
  }
  subscribeReloadOrganizationAuth(): Observable<any> {
    return this.reloadOrganizationAuth$;
  }

  publishNeedGetCenterDirector() {
    this.needGetCenterDirector$.next();
  }
  subscribeNeedGetCenterDirector(): Observable<any> {
    return this.needGetCenterDirector$;
  }

  publishNeedSaveAudit() {
    this.needSaveAudit$.next();
  }

  subscribeNeedSaveAudit(): Observable<any> {
    return this.needSaveAudit$;
  }

  publishHideAddChildPlanDropDown() {
    this.hideAddChildPlanDropDown$.next();
  }

  subscribeHideAddChildPlanDropDown(): Observable<boolean> {
    return this.hideAddChildPlanDropDown$;
  }

  publishGetRelationPlans() {
    this.getRelationPlans$.next();
  }

  subscribeGetRelationPlans(): Observable<any> {
    return this.getRelationPlans$;
  }

  publishGetApprovals() {
    this.getApprovers$.next();
  }

  subscribeGetApprovers(): Observable<any> {
    return this.getApprovers$;
  }

  publishAfterVirtualScrollInit(flag: boolean) {
    this.afterVirtualScrollInit$.next(flag);
  }

  subscribeAfterVirtualScrollInit(): Observable<boolean> {
    return this.afterVirtualScrollInit$.pipe(
      distinctUntilChanged(),
    );
  }

  publishSetup() {
    this.getSetup$.next();
  }
  subscribeSetup(): Observable<any> {
    return this.getSetup$;
  }
}
