export enum ELightType {
    /// <summary>
    /// 无灯: 未到计划完成时间
    /// </summary>
    None = 0,

    /// <summary>
    /// 红灯: 到期未完结
    /// </summary>
    Red = 10,

    /// <summary>
    /// 黄灯: 走过延期申请，审批通过，延期后的时间未到计划完成时间
    /// </summary>
    Yellow = 20,

    /// <summary>
    /// 绿灯: 初始计划时间内完成审批
    /// </summary>
    Green = 30,

    /// <summary>
    /// 绿灯!: 超过计划完成时间完成，走过延期申请并最后完成
    /// </summary>
    GreenWarning = 31
}
