// 看板类型
export enum EPlanKanbanType {
    /// <summary>
    /// 全部
    /// </summary>
    All = 10,

    /// <summary>
    /// 我下发的
    /// </summary>
    Issue = 20,

    /// <summary>
    /// 我管理的
    /// </summary>
    Manager = 30,

    /// <summary>
    /// 我负责的
    /// </summary>
    Responsible = 40,

    /// <summary>
    /// 我转办的
    /// </summary>
    Transfer = 50,

    /// <summary>
    /// 待回执
    /// </summary>
    ToReceipt = 60,

    /// <summary>
    /// fpm项目
    /// </summary>
    Fpm = 70,
}
