// *拥有人工成本维护权限的类型
export enum EWorktimeCostPermissionType {

    /// <summary>
    /// 个人
    /// </summary>
    User = 10,

    /// <summary>
    /// 组织
    /// </summary>
    Organization = 20,

    /// <summary>
    /// 项目角色
    /// </summary>
    ProjectRole = 30,

    /// <summary>
    /// 项目组织角色
    /// </summary>
    ProjectOrgRole = 40,
}
