import { OnInit, Directive, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { CListBase } from './CListBase';
import _ from 'lodash';

@Directive()
export abstract class CCheckBase<T, S> extends CListBase<T, S> implements OnInit, OnDestroy {
  protected fb: FormBuilder = new FormBuilder();

  checkForm: FormGroup;
  isCheckedAll: boolean;
  checkedItems: Array<T> = [];

  constructor(
    protected service: any,
    protected getItemsFun: string,
    protected byProperty: string
  ) {
    super(service, getItemsFun, byProperty);
    this.checkForm = this.fb.group({});
    this.checkForm.valueChanges.subscribe((newValue) => {
      this.isCheckedAll = _.reduce(newValue, (result, value, key) => {
        return result && (!!value);
      }, true);
      this.refreshCheckedItems();
    });
  }

  ngOnInit() {
  }

  ngOnDestroy() {

  }

  getItems(checkedItems?: Array<T>, callback?: (items?: Array<T>) => void) {
    super.getItems().subscribe((items: Array<T>) => {
      this.checkForm.reset();
      // 清理checkbox FormControl
      _.forEach(this.checkForm.controls, (value, key) => {
        this.checkForm.removeControl(key);
      });
      this.isCheckedAll = false;
      // 绑定checkbox FormControl
      _.forEach(items, (item: T) => {
        this.checkForm.addControl(item[this.byProperty] + '', new FormControl());
      });
      if (callback) {
        callback(items);
      }
      if (!(checkedItems && checkedItems.length)) {
        return;
      }
      _.forEach(checkedItems, item => {
        if (!this.checkForm.controls[item[this.byProperty] + '']) {
          return;
        }
        this.checkForm.controls[item[this.byProperty] + ''].setValue(true);
      });
    });
  }

  setItems(items: Array<T>, checkedItems?: Array<T>) {
    // 清理checkbox FormControl
    _.forEach(this.checkForm.controls, (value, key) => {
      this.checkForm.removeControl(key);
    });
    this.isCheckedAll = false;
    // 绑定checkbox FormControl
    _.forEach(items, (item: T) => {
      this.checkForm.addControl(item[this.byProperty] + '', new FormControl());
    });
    if (!(checkedItems && checkedItems.length)) {
      return;
    }
    _.forEach(checkedItems, item => {
      if (!this.checkForm.controls[item[this.byProperty] + '']) {
        return;
      }
      this.checkForm.controls[item[this.byProperty] + ''].setValue(true);
    });
  }

  private refreshCheckedItems() {
    const checkedItemIds = _.keys(_.pickBy(this.checkForm.value, (value) => {
      return true === value;
    }));
    this.checkedItems = _.filter(this.items, (item) => {
      return _.includes(checkedItemIds, item[this.byProperty] + '');
    });
  }

  checkAllChange(event) {
    _.forEach(this.items, (item: T) => {
      this.checkForm.controls[item[this.byProperty] + ''].setValue(event.target.checked);
    });
  }

  protected getCheckedItems() {
    return this.checkedItems;
  }

  // masked上点击的方法
  toggleCheckItem(item: T) {
    this.checkForm.controls[item[this.byProperty] + ''].setValue(!this.checkForm.controls[item[this.byProperty] + ''].value);
  }

  toggleCheckAll() {
    const isCheckedAll = !this.isCheckedAll;
    _.forEach(this.checkForm.controls, control => {
      control.setValue(isCheckedAll);
    });
  }

  // 清空数据
  clear() {
    this.isCheckedAll = false;
    this.items = [];
    this.checkedItems = [];
    _.forEach(this.checkForm.controls, (value, key) => {
      this.checkForm.removeControl(key);
    });
  }
}
