import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import _ from 'lodash';

import { SystemService } from 'src/app/services/system.service';
import { UserService } from 'src/app/services/user.service';
import { SubscriptionService } from 'src/app/services/subscription.service';

import { IUserInfo } from 'src/app/models';
import { TSystem } from 'src/app/types';

@Component({
  selector: 'app-left-nav-zentao',
  templateUrl: './left-nav-zentao.component.html',
  styleUrls: ['./left-nav-zentao.component.scss']
}

) export class LeftNavZentaoComponent implements OnInit, OnDestroy {
  isSystemAdmin: boolean; // 系统管理员
  isDivisionAdmin: boolean; // 事业部管理员
  isCategoryAdmin: boolean; // 细分类管理员
  isProjectGroupAdmin: boolean; // 项目群管理员
  isOrganizationAdmin: boolean; // 组织管理员
  isProjectManagementAdmin: boolean; // 管理经理
  isProductEngineeringAdmin: boolean; // 产品工程经理
  isSecondOrgTemplateAdmin: boolean; // 二级标准组织模板管理员
  isKpiApprovalAdmin: boolean; // 绩效报批管理员
  isRequirementManagementEngineer: boolean; // 需求管理工程师
  hasPermissionToMaintainLaborCost: boolean; // 有权限维护人力成本

  currentUser: IUserInfo;
  isInManagement: boolean;
  isExpand = true;
  isUnfold = true;
  managementIsShow: boolean;
  managementRoutes: string[] = [ // “管理中心”中的路由
    'permission',
    'template',
    'division',
    'project-category',
    'project-group',
    'issue-transfer',
    'organization-authorization',
    'admin-drop',
    'admin-oa-todo',
    'performance',
    'score-reference',
    'performance-approval',
    'system-admin',
    'authorization',
    'labor-cost'
  ];

  clickAppSubscription: Subscription;
  system: TSystem;
  constructor(
    private router: Router,
    private systemService: SystemService,
    private userService: UserService,
    private subscriptionService: SubscriptionService
  ) { }

  ngOnInit(): void {
    this.activeManagementMenu();
    this.getCurrentUser();
    this.getSystemSync();

    this.clickAppSubscription = this.subscriptionService.subscribeHideAddChildPlanDropDown().subscribe(() => {
      this.hiddenShowManagement();
    });
  }

  ngOnDestroy() {
    if (this.clickAppSubscription) {
      this.clickAppSubscription.unsubscribe();
    }
  }

  // 设置“管理中心”菜单的active状态
  activeManagementMenu() {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      const currentRoute = _.compact(event.url.split('/'))[0]; // 当前路由
      this.isInManagement = _.includes(this.managementRoutes, currentRoute);
    }

    );
  }

  getCurrentUser() {
    this.currentUser = this.userService.getCurrentUserSync();
    this.isSystemAdmin = this.userService.getCurrentIsSystemAdminSync();
    this.isDivisionAdmin = this.userService.getCurrentIsDivisionAdminSync();
    this.isCategoryAdmin = this.userService.getCurrentIsCategoryAdminSync();
    this.isProjectGroupAdmin = this.userService.getCurrentIsProjectGroupAdminSync();
    this.isOrganizationAdmin = this.userService.getCurrentIsOrganizationAdminSync();
    this.isProjectManagementAdmin = this.userService.getCurrentIsProjectManagementAdminSync();
    this.isProductEngineeringAdmin = this.userService.getCurrentIsProductEngineeringAdminSync();
    this.isSecondOrgTemplateAdmin = this.userService.getCurrentIsSecondOrgTemplateAdminSync();
    this.isKpiApprovalAdmin = this.userService.getCurrentIsKpiApprovalAdminSync();
    this.isRequirementManagementEngineer = this.userService.getCurrentIsRequirementManagementEngineerSync();

    this.userService.hasPermissionToMaintainLaborCost().subscribe(hasPermissionToMaintainLaborCost => {
      this.hasPermissionToMaintainLaborCost = hasPermissionToMaintainLaborCost;
    })
  }

  logout() {
    sessionStorage.clear();
    localStorage.clear();
    window.location.href = this.systemService.getSsoLogoutUrlSync();
  }

  toggle() {
    this.isExpand = !this.isExpand;
  }

  unfoldManagement() {
    this.isUnfold = !this.isUnfold;
  }

  toggleShowManagement(event) {
    event.stopPropagation();
    this.managementIsShow = !this.managementIsShow;
  }

  hiddenShowManagement() {
    this.managementIsShow = false;
  }

  getSystemSync() {
    this.system = this.systemService.getSystemSync();
  }

  // 有权限查看“管理中心”
  showManagementCenter() {
    const permission = this.isSystemAdmin ||
      this.isDivisionAdmin ||
      this.isCategoryAdmin ||
      this.isOrganizationAdmin ||
      this.isSecondOrgTemplateAdmin ||
      this.isKpiApprovalAdmin ||
      this.isProjectManagementAdmin ||
      this.hasPermissionToMaintainLaborCost;
    // 'PCMS'系统时，需求管理工程师无权限；'ALL'系统时，需求管理工程师有权限
    return this.system === 'PCMS' ? permission : permission || this.isRequirementManagementEngineer;
  }

  // 有权限查看“配置角色和人员”
  showPermission() {
    return this.system === 'PCMS' ? this.isSystemAdmin : this.isSystemAdmin || this.isRequirementManagementEngineer;
  }
}
