/// <summary>
/// 组织节点操作类型
/// </summary>
export enum EOrganizationChartItemOperationType {
    /// <summary>
    /// 未变动
    /// </summary>
    InUsing = 0,

    /// <summary>
    /// 增加
    /// </summary>
    Add = 10,

    /// <summary>
    /// 变更
    /// </summary>
    Update = 20,

    /// <summary>
    /// 删除
    /// </summary>
    Delete = 30
}
