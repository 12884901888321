import { Action, ActionCreator } from 'redux';
import { IChatCallback, IChatInfo } from 'src/app/models';

// #region 显示
export interface ShowChatAction extends Action {
  auditId: string;
  callback: any;
}

export const SHOW_CHAT = 'SHOW_CHAT';
export const showChat: ActionCreator<ShowChatAction> = (
  callback: IChatCallback,
  auditId: string
) => ({
  type: SHOW_CHAT,
  callback,
  auditId
});
// #endregion

// #region 确定
// tslint:disable-next-line: no-empty-interface
export interface ConfirmChatAction extends Action {
}

export const CONFIRM_CHAT = 'CONFIRM_CHAT';
export const confirmChat: ActionCreator<ConfirmChatAction> = () => ({
  type: CONFIRM_CHAT
});
// #endregion

// #region 取消
// tslint:disable-next-line: no-empty-interface
export interface CancelChatAction extends Action {
}

export const CANCEL_CHAT = 'CANCEL_CHAT';
export const cancelChat: ActionCreator<CancelChatAction> = () => ({
  type: CANCEL_CHAT
});
// #endregion

// #region 设置转办信息
export interface SetChatInfoAction extends Action {
  chatInfo: IChatInfo;
}

export const SET_CHAT_INFO = 'SET_CHAT_INFO';
export const setChatInfo: ActionCreator<SetChatInfoAction> = (chatInfo: IChatInfo) => ({
  type: SET_CHAT_INFO,
  chatInfo
});

// #endregion
