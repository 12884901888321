import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { SharedService } from 'src/app/services/shared.service';
import _ from 'lodash';
import {
  IDivisionAdmin, IUserInfo, IDivisionCreateCommand,
  IDivisionPermission, IDivisionAdminParams, IDeletedAdminsParams,
  IPermissionItem,
  IConfigInfo,
  IAddDivisionProjectGroupCommand,
  IResponse
} from 'src/app/models';
import { IAddDivisionProjectCategoryCommand, IDeleteProjectCategoryParams, IDeleteProjectGroupParams, IUpdateDivisionProjectCategoryBatchCommand, IUpdateDivisionProjectGroupBatchCommand } from 'src/app/models/IDivision';

@Injectable({
  providedIn: 'root'
})

export class DivisionService {

  users: IUserInfo[] = [];
  constructor(
    private http: HttpClient,
    private sharedService: SharedService
  ) { }

  // 获取事业部管理员列表
  getAdmins(params: IDivisionAdminParams): Observable<IDivisionAdmin[]> {
    return this.http.get<IDivisionAdmin[]>(`/api/divisions/${params.key}/admins`, { params: params as unknown as HttpParams }).pipe(
      catchError(this.sharedService.handleError('get division admins', []))
    );
  }

  // 添加事业部管理员
  addAdmin(addDivisionAdmin: IDivisionCreateCommand): Observable<any> {
    return this.http.post<any>(`/api/divisions/${addDivisionAdmin.division_org_number}/admins`, addDivisionAdmin).pipe(
      catchError(this.sharedService.handleError('add division admins', undefined))
    );
  }

  // 删除事业部管理员
  deleteAdmin(params: IDeletedAdminsParams): Observable<IDeletedAdminsParams[]> {
    return this.http.delete<IDeletedAdminsParams[]>(`/api/divisions/${params.division_org_number}/admins/${params.user_id}`).pipe(
      catchError(this.sharedService.handleError('delete division admin', []))
    );
  }

  getPermissions(key: string): Observable<IDivisionPermission[]> {
    return this.http.get<IDivisionPermission[]>(`/api/divisions/${key}/permissions`).pipe(
      catchError(this.sharedService.handleError('get division permission', []))
    );
  }

  getPermissionOptions(kind: number): Observable<IPermissionItem> {
    return this.http.get<IPermissionItem>(`/api/permission-options/${kind}`).pipe(
      catchError(this.sharedService.handleError('get permission type', { kind: 0, name: '' }))
    );
  }

  // 获取细分类列表
  getProjectCategories(params: IDivisionAdminParams): Observable<IConfigInfo[]> {
    return this.http.get<IConfigInfo[]>(`/api/divisions/${params.division_org_number}/project-categories`,
      { params: params as HttpParams }).pipe(
        catchError(this.sharedService.handleError('get project categories', []))
      );
  }

  // 添加细分类
  addProjectCategory(addDivisionProjectCategory: IAddDivisionProjectCategoryCommand, params: IDivisionAdminParams): Observable<boolean> {
    return this.http.post<boolean>(`/api/divisions/${params.division_org_number}/project-categories`,
      addDivisionProjectCategory).pipe(
        catchError(this.sharedService.handleError('add project category', false))
      );
  }

  // 删除细分类
  deleteProjectCategory(params: IDeleteProjectCategoryParams): Observable<boolean> {
    return this.http.delete<boolean>(`/api/divisions/${params.division_org_number}/project-categories/${params.project_category_key}`).pipe(
      catchError(this.sharedService.handleError('delete project category', false))
    );
  }

  // 获取项目群列表
  getProjectGroups(params: IDivisionAdminParams): Observable<IConfigInfo[]> {
    return this.http.get<IConfigInfo[]>(`/api/divisions/${params.division_org_number}/project-groups`).pipe(
      catchError(this.sharedService.handleError('get project groups', []))
    );
  }

  // 添加项目群
  addProjectGroup(addDivisionProjectGroup: IAddDivisionProjectGroupCommand, params: IDivisionAdminParams): Observable<boolean> {
    return this.http.post<boolean>(`/api/divisions/${params.division_org_number}/project-groups`, addDivisionProjectGroup).pipe(
      catchError(this.sharedService.handleError('add project groups', false))
    );
  }

  // 删除项目群
  deleteProjectGroup(params: IDeleteProjectGroupParams): Observable<boolean> {
    return this.http.delete<boolean>(`/api/divisions/${params.division_org_number}/project-groups/${params.project_group_key}`).pipe(
      catchError(this.sharedService.handleError('delete project group', false))
    );
  }

  // setup 时使用 
  batchUpdateProjectCategories(command: IUpdateDivisionProjectCategoryBatchCommand): Observable<IResponse> {
    return this.http.post<IResponse>(`/api/divisions/${command.division_org_number}/project-categories/batch`, command).pipe(
      catchError(this.sharedService.handleError('batch update project categories', { success: false, errors: [] }))
    );
  }

  // setup 时使用 
  batchUpdateProjectGroups(command: IUpdateDivisionProjectGroupBatchCommand): Observable<IResponse> {
    return this.http.post<IResponse>(`/api/divisions/${command.division_org_number}/project-groups/batch`, command).pipe(
      catchError(this.sharedService.handleError('batch update project groups', { success: false, errors: [] }))
    );
  }

  addDivisionAdmin(divisionId: string, users: IUserInfo[]) {
    for (const each of users.keys()) {
      users[each].org_name = divisionId;
    }
    const json = this.users.concat(users);
    const newJson = [];
    for (const i of json.keys()) {
      let flag = true;
      for (const j of newJson.keys()) {
        if (json[i].user_id === newJson[j].id && json[i].org_name === newJson[j].division) {
          flag = false;
        }
      }
      if (flag) {
        newJson.push(json[i]);
      }
    }
    console.log(newJson, '合并后的');
    this.users = newJson;
    console.log('赋值后的users', this.users);
    return of(this.users);
  }

  toDeleteAdmins(divisionId: string, admins: IUserInfo[]) {
    console.log(admins, '传进来的参数');
    const user = _.pullAll(this.users, admins);
    console.log(user, '删除的尝试');
    this.users = user;
    return of(this.users);

  }
}

