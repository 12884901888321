import { Action, ActionCreator } from 'redux';
import { ECheckUsersType } from 'src/app/enums';
import { IUserInfo, ICheckUsersCallback } from 'src/app/models';

// #region 显示
export interface ShowCheckUsersAction extends Action {
  callback: ICheckUsersCallback;
  isMultiple?: boolean;
  oldUsers?: IUserInfo[];
  checkUsersType?: ECheckUsersType;
  params?: string | string[]; // α、γ管理员params为auditId; 中心主任人员列表为center_keys
}

export const SHOW_CHECK_USERS = 'SHOW_CHECK_USERS';
export const showCheckUsers: ActionCreator<ShowCheckUsersAction> = (
  callback: ICheckUsersCallback,
  isMultiple?: boolean,
  oldUsers?: IUserInfo[],
  checkUsersType?: ECheckUsersType,
  params?: string | string[]
) => ({
  type: SHOW_CHECK_USERS,
  callback,
  isMultiple,
  oldUsers,
  checkUsersType,
  params
});
// #endregion

// #region 确定
// tslint:disable-next-line: no-empty-interface
export interface ConfirmCheckUsersAction extends Action {
}

export const CONFIRM_CHECK_USERS = 'CONFIRM_CHECK_USERS';
export const confirmCheckUsers: ActionCreator<ConfirmCheckUsersAction> = () => ({
  type: CONFIRM_CHECK_USERS
});
// #endregion

// #region 取消
// tslint:disable-next-line: no-empty-interface
export interface CancelCheckUsersAction extends Action {
}

export const CANCEL_CHECK_USERS = 'CANCEL_CHECK_USERS';
export const cancelCheckUsers: ActionCreator<CancelCheckUsersAction> = () => ({
  type: CANCEL_CHECK_USERS
});
// #endregion

// #region 设置新选中的用户列表
export interface SetCheckUsersNewUsersAction extends Action {
  newUsers: IUserInfo[];
}

export const SET_CHECK_USERS_NEW_USERS = 'SET_CHECK_USERS_NEW_USERS';
export const setCheckUsersNewUsers: ActionCreator<SetCheckUsersNewUsersAction> = (newUsers: IUserInfo[]) => ({
  type: SET_CHECK_USERS_NEW_USERS,
  newUsers
});

// #endregion
