import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {
  IAddWorktimeUserCommand, IDeleteWorktimeUserParams, IResponse, ISuggestedUsersParams,
  IUpdateWorktimeUserCommand, IWorktimeUserInfo, IWorktimeUsersParams
} from 'src/app/models';
import { SharedService } from 'src/app/services/shared.service';

@Injectable({
  providedIn: 'root'
})
export class PersonalManagementService {
  apiUrl = '/api/worktime-users';

  constructor(
    private http: HttpClient,
    private sharedService: SharedService
  ) { }

  // 查询工时填报人员列表
  getWorktimeUsers(params: IWorktimeUsersParams): Observable<IWorktimeUserInfo[]> {
    params = this.sharedService.omitEmptyParams(params);
    return this.http.get<IWorktimeUserInfo[]>(`${this.apiUrl}/users`, { params: params as unknown as HttpParams }).pipe(
      catchError(this.sharedService.handleError('get workflow users', []))
    );
  }

  // 查询工时填报人员总数
  getWorktimeUsersCount(q: string): Observable<number> {
    const params = this.sharedService.omitEmptyParams({ q });
    return this.http.get<number>(`${this.apiUrl}/users-count`, { params: params as unknown as HttpParams }).pipe(
      catchError(this.sharedService.handleError('get workflow users count', 0))
    );
  }

  // 增加工时填报人员
  addWorktimeUsers(command: IAddWorktimeUserCommand): Observable<IResponse> {
    return this.http.post<IResponse>(`${this.apiUrl}/users`, command).pipe(
      catchError(this.sharedService.handleError('', { success: false, errors: [] }))
    );
  }

  // 更新工时填报人员
  updateWorktimeUsers(command: IUpdateWorktimeUserCommand): Observable<IResponse> {
    return this.http.put<IResponse>(`${this.apiUrl}/users`, command).pipe(
      catchError(this.sharedService.handleError('', { success: false, errors: [] }))
    );
  }

  // 删除工时填报人员
  deleteWorktimeUsers(params: IDeleteWorktimeUserParams): Observable<IResponse> {
    return this.http.delete<IResponse>(`${this.apiUrl}/users`, { params: params as unknown as HttpParams }).pipe(
      catchError(this.sharedService.handleError('', { success: false, errors: [] }))
    );
  }

  // 查询推荐用户列表
  getSuggestedUsers(params: ISuggestedUsersParams): Observable<IWorktimeUserInfo[]> {
    params = this.sharedService.omitEmptyParams(params);
    return this.http.get<IWorktimeUserInfo[]>(`${this.apiUrl}/suggested-users`, { params: params as unknown as HttpParams }).pipe(
      catchError(this.sharedService.handleError('get workflow suggested users', []))
    );
  }

  // 查询推荐用户总数
  getSuggestedUsersCount(params: ISuggestedUsersParams): Observable<number> {
    params = this.sharedService.omitEmptyParams(params);
    return this.http.get<number>(`${this.apiUrl}/suggested-users-count`, { params: params as unknown as HttpParams }).pipe(
      catchError(this.sharedService.handleError('get workflow suggested users', 0))
    );
  }
}
