import {
  Component,
  TemplateRef,
  ViewChild,
  OnInit
} from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ReduxService } from 'src/app/redux/redux.service';
import { IDialogCallback } from 'src/app/models';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {
  modalRef: BsModalRef;
  @ViewChild('dialog') modalEle: TemplateRef<any>;

  title: string;
  content: string[] = [];
  tip: string;
  cancelDialogCallback: IDialogCallback;

  constructor(
    private modalService: BsModalService,
    private reduxService: ReduxService
  ) {
    this.reduxService.subscribeStore(() => this.readState());
    this.readState();
  }

  ngOnInit() {
  }

  readState() {
    const dialog = this.reduxService.getDialog();
    this.title = dialog.title;
    this.content = dialog.content;
    this.tip = dialog.tip;
    const isShow = this.reduxService.getDialogShow();
    this.cancelDialogCallback = this.reduxService.getDialogCancelCallback();

    if (isShow) {
      this.show();
    } else {
      this.hide();
    }
  }


  show() {
    if (!this.modalRef) {
      this.modalRef = this.modalService.show(this.modalEle, { class: 'modal-sm', ignoreBackdropClick: true });
    }
  }

  hide() {
    if (this.modalRef) {
      this.modalRef.hide();
      this.modalRef = undefined;
      this.reduxService.cancelDialog();
    }
  }

  confirm() {
    this.reduxService.confirmDialog();
  }


}
