<ng-template #checkGroupManager>
  <div class="modal-header">
    <h5 class="modal-title">{{ title || '项目群管理员列表'}}</h5>
    <button type="button" class="close" (click)="hide()">
      <i class="fa fa-times"></i>
    </button>
  </div>

  <div class="modal-body">
    <form [formGroup]="radioForm">
      <table class="table-sm table-bordered table-hover table-fixed text-center align-middle">
        <thead>
          <tr>
            <th style="width:40px;"></th>
            <th style="width:60px;">序号</th>
            <th style="width:120px;">姓名</th>
            <th style="width:150px;">用户名</th>
            <th>部门</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of items; let i = index;" (click)="checkItem(item)">
            <td class="masked">
              <input type="radio" [id]="item.user_id" [value]="item.user_id" [formControl]="radio">
            </td>
            <td>{{i + 1}}</td>
            <td class="ellipsis">{{item.name}}</td>
            <td class="ellipsis">{{item.user_id}}</td>
            <td class="text-left ellipsis" [title]="item.org_full_path">{{item.org_full_path}}</td>
          </tr>
        </tbody>
      </table>
    </form>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-primary mr-2" (click)="confirm()" [disabled]="!checkedItem">
      确定
    </button>
    <button type="button" class="btn btn-outline-secondary" (click)="hide()">
      返回
    </button>
  </div>
</ng-template>
