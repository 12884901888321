// 选择用户列表，列出的用户类型
export enum ECheckUsersType {
    All = 0, // 所有用户（默认）
    ProjectManagementAdmin = 1, // 管理经理
    KpiAlphaAdmin = 2, // 产品创造绩效管理员
    KpiGammaAdmin = 3, // 人力γ值管理员
    OrganizationUsers = 4, // 所属单位人员列表
    RecommendedUsers = 5, // 推荐用户列表
    Approver = 6, // 审批人列表
    WorktimeUsers = 7, // 工时填报人员列表
    PerformanceApprovalAdmin = 8, // 绩效管理员
}
