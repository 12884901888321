import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of, forkJoin } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { EOrganizationChartItemOperationType, EOrganizationNodeTagType, EOrganizationTemplateType } from 'src/app/enums';
import _ from 'lodash';

import {
  IOrganizationTemplate,
  IOrganizationTemplateParams,
  IOrganizationTemplateCreateCommand,
  IOrganizationTemplateUpdateCommand,
  IOrganizationChartItem,
  IProject,
  IAlert,
  IConfigInfo,
  IOrganizationNodeTag,
  INodePathCheckParams,
  ICommonResult,
  IOrganizationChartGroup,
  IOrganizationTemplateOrgHashCode,
  IOrganizationTemplateDetailParams,
  IUnstandardOrganizationTemplateParams,
  IProjectManagementAdminParams,
  ISecondStandardOrganizationTemplateAdminDivisionParams,
  ISecondStandardOrganizationTemplateAdminProjectCategoryParams,
  ISecondStandardOrganizationTemplateAdminDivision,
  ISecondStandardOrganizationTemplateAdminProjectCategory,
  IOrganizationTeamItem,
  IUserInfo,
  ITeamMemberRole
} from 'src/app/models';
import { DEFAULT_ORGANIZATION_CHART_ITEMS, ORGANIZATION_CHART_MANAGEMENT_ADMIN, ORGANIZATION_CHART_PRODUCT_ENGINEERING_ADMIN_NODE_PATH, ORGANIZATION_CHART_ROOT_PARENT_NODE_PATH } from 'src/app/consts';
import { EOrganizationShareLevel } from 'src/app/enums';
import { SharedService } from './shared.service';
import { UserService } from './user.service';
import { ReduxService } from '../redux/redux.service';
import { SubscriptionService } from './subscription.service';
import { IProjectCategoryParams } from '../models/IProjectCategoryAdmins';
import { ProjectRoleService } from '../modules/permission/services/project-role.service';
import { OrgChartService } from './org-chart.service';
import { MultifunctionalTeamService } from './multifunctional-team.service';

interface GroupByParentId {
  [parentId: string]: IOrganizationChartGroup;
}
@Injectable({
  providedIn: 'root'
})
export class OrganizationTemplateService {
  apiUrl = '/api/templates/projects/orgs';

  constructor(
    private http: HttpClient,
    private sharedService: SharedService,
    private userService: UserService,
    private reduxService: ReduxService,
    private subscriptionService: SubscriptionService,
    private projectRoleService: ProjectRoleService,
    private orgChartService: OrgChartService,
    private multifunctionalTeamService: MultifunctionalTeamService
  ) { }

  getOrganizationTemplates(params?: IOrganizationTemplateParams): Observable<IOrganizationTemplate[]> {
    return this.http.get<IOrganizationTemplate[]>(`${this.apiUrl}`, { params: params as unknown as HttpParams }).pipe(
      catchError(this.sharedService.handleError<IOrganizationTemplate[]>('get org templates', []))
    );
  }

  getOrganizationTemplatesCount(params?: IOrganizationTemplateParams): Observable<number> {
    return this.http.get<number>('/api/templates/projects/orgs-count', { params: params as unknown as HttpParams }).pipe(
      catchError(this.sharedService.handleError<number>('get org templates count', 0))
    );
  }

  // 获取所有的组织模板
  getAllOrganizationTemplates(): Observable<IOrganizationTemplate[]> {
    return this.getOrganizationTemplates().pipe(
      catchError(this.sharedService.handleError<IOrganizationTemplate[]>('get all org templates', []))
    );
  }

  // 获取可用的组织模板（组织下发、变更时）
  getAvailableOrganizationTemplates(
    params: {
      division_org_number?: string;    // 项目的事业部编码
      project_category_key?: string;   // 项目的细分类编码
      share_level: EOrganizationShareLevel;
      type: EOrganizationTemplateType;
    }
  ): Observable<IOrganizationTemplate[]> {
    return this.getOrganizationTemplates(params).pipe(
      catchError(this.sharedService.handleError<IOrganizationTemplate[]>('get available org templates', []))
    );
  }

  // 获取自己的组织模板
  // tslint:disable-next-line:variable-name
  getPersonalOrganizationTemplates(_params?: IOrganizationTemplateParams): Observable<IOrganizationTemplate[]> {
    let params: IOrganizationTemplateParams;
    params = this.sharedService.omitEmptyParams({
      page: _params.page,
      limit: _params.limit,
      share_level: _params.share_level,
      type: _params.type,
    });
    return this.getOrganizationTemplates(params).pipe(
      catchError(this.sharedService.handleError<IOrganizationTemplate[]>('get personal org templates', []))
    );
  }
  // tslint:disable-next-line:variable-name
  getPersonalOrganizationTemplatesCount(_params?: IOrganizationTemplateParams): Observable<number> {
    const params = this.sharedService.omitEmptyParams({
      page: _params.page,
      limit: _params.limit,
      share_level: EOrganizationShareLevel.Personal,
      type: _params.type
    });
    return this.getOrganizationTemplatesCount(params).pipe(
      catchError(this.sharedService.handleError<number>('get personal org templates', 0))
    );
  }

  // 获取除了自己的组织模板
  // categoryKey有值，则是该项目细分类下的；无值，则为该事业部下的
  // 事业部有值则为该事业部下的；无值，空
  // tslint:disable-next-line:variable-name
  getOrganizationTemplatesNotIncludeMyPersonal(_params?: IOrganizationTemplateParams): Observable<IOrganizationTemplate[]> {
    return this.userService.getCurrentDivisionOrgNumber().pipe(
      switchMap(currentDivisionOrgNumber => {
        const params = this.sharedService.omitEmptyParams({
          division_org_number: currentDivisionOrgNumber,
          category_key: _params.project_category_key,
          page: _params.page,
          limit: _params.limit,
          not_include_my_personal: true,
          type: _params.type,
          share_level: _params.share_level
        });
        return this.getOrganizationTemplates(params);
      }),
      catchError(this.sharedService.handleError<IOrganizationTemplate[]>('get org templates not include my personal', []))
    );
  }

  // tslint:disable-next-line:variable-name
  getOrganizationTemplatesNotIncludeMyPersonalCount(_params?: IOrganizationTemplateParams): Observable<number> {
    return this.userService.getCurrentDivisionOrgNumber().pipe(
      switchMap(currentDivisionOrgNumber => {
        const params = this.sharedService.omitEmptyParams({
          division_org_number: currentDivisionOrgNumber,
          category_key: _params.project_category_key,
          page: _params.page,
          limit: _params.limit,
          not_include_my_personal: true,
          type: _params.type,
          share_level: _params.share_level
        });
        return this.getOrganizationTemplatesCount(params);
      }),
      catchError(this.sharedService.handleError<number>('get org templates not include my personal', 0))
    );
  }

  getOrganizationTemplate(params: IOrganizationTemplateDetailParams): Observable<IOrganizationTemplate> {
    return this.http.get<IOrganizationTemplate>(`${this.apiUrl}/${params.id}/${params.type}`).pipe(
      map(organizationTemplate => {
        // organizationTemplate.is_deleted = true; // TODO DELETE
        _.forEach(organizationTemplate.data.items, item => {
          if (!item.node_data) {
            return;
          }
          // 处理后端将number存成string
          item.node_data = {
            disable_delete: JSON.parse(item.node_data.disable_delete as unknown as string),
            // tslint:disable-next-line:max-line-length
            is_can_maintain_team: item.node_data.is_can_maintain_team ? JSON.parse(item.node_data.is_can_maintain_team as unknown as string) : false,
            // tslint:disable-next-line:max-line-length
            is_can_split_node: item.node_data.is_can_split_node ? JSON.parse(item.node_data.is_can_split_node as unknown as string) : false,
            is_can_inform_team_member: item.node_data.is_can_inform_team_member,
            person_max_limit: +item.node_data.person_max_limit,
            person_min_limit: +item.node_data.person_min_limit,
            description: item.node_data.description,
            center_keys: item.node_data.center_keys,
            center_names: item.node_data.center_names,
            engineer_org_number: item.node_data.engineer_org_number,
            engineer_org_name: item.node_data.engineer_org_name,
            disable_edit_name: item.node_data.disable_edit_name,
            tc_system_code: item.node_data.tc_system_code || ''
          };
        });
        return organizationTemplate;
      }),
      catchError(this.sharedService.handleError<IOrganizationTemplate>('get org template', undefined))
    );
  }

  createOrganizationTemplate(organizationTemplateCreateCommand: IOrganizationTemplateCreateCommand): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}`, organizationTemplateCreateCommand).pipe(
      catchError(this.sharedService.handleError<any>('create org template', false))
    );
  }

  updateOrganizationTemplate(organizationTemplateUpdateCommand: IOrganizationTemplateUpdateCommand): Observable<any> {
    return this.http.put<any>(`${this.apiUrl}/${organizationTemplateUpdateCommand.id}`, organizationTemplateUpdateCommand).pipe(
      catchError(this.sharedService.handleError<any>('update org template', false))
    );
  }

  deleteOrganizationTemplate(id: number): Observable<any> {
    return this.http.delete<any>(`${this.apiUrl}/${id}`).pipe(
      catchError(this.sharedService.handleError<any>('delete org template', false))
    );
  }

  deleteOrganizationTemplates(items: IOrganizationTemplate[]): Observable<any> {
    const requests = [];
    _.forEach(items, item => {
      requests.push(this.deleteOrganizationTemplate(item.id));
    });
    return forkJoin(requests).pipe(
      catchError(this.sharedService.handleError<any>('delete org templates', false))
    );
  }

  // 获取非标准组织模板
  getUnstandardOrganizationTemplates(params?: IUnstandardOrganizationTemplateParams): Observable<IOrganizationTemplate[]> {
    return this.http.get<IOrganizationTemplate[]>(`${this.apiUrl}/unstandard`, { params: params as unknown as HttpParams }).pipe(
      catchError(this.sharedService.handleError<IOrganizationTemplate[]>('get org templates', []))
    );
  }
  // 获取非标准组织模板条数
  getUnstandardOrganizationTemplatesCount(params?: IUnstandardOrganizationTemplateParams): Observable<number> {
    return this.http.get<number>
      ('/api/templates/projects/orgs-count/unstandard', { params: params as unknown as HttpParams }).pipe(
        catchError(this.sharedService.handleError<number>('get org templates count', 0))
      );
  }

  // 修改非标准组织模板
  updateUnstandardOrganizationTemplate(organizationTemplateUpdateCommand: IOrganizationTemplateUpdateCommand): Observable<any> {
    return this.http.put<any>(`${this.apiUrl}/unstandard/${organizationTemplateUpdateCommand.id}`, organizationTemplateUpdateCommand).pipe(
      catchError(this.sharedService.handleError<any>('update unstandard org template', false))
    );
  }

  // 增加非标准组织模板
  createUnstandardOrganizationTemplate(organizationTemplateCreateCommand: IOrganizationTemplateCreateCommand): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/unstandard`, organizationTemplateCreateCommand).pipe(
      catchError(this.sharedService.handleError<any>('create unstandard org template', false))
    );
  }

  // 删除非标组组织模板
  deleteUnstandardOrganizationTemplate(id: number): Observable<any> {
    return this.http.delete<any>(`${this.apiUrl}/unstandard/${id}`).pipe(
      catchError(this.sharedService.handleError<any>('delete unstandard org template', false))
    );
  }

  deleteUnstandardOrganizationTemplates(items: IOrganizationTemplate[]): Observable<any> {
    const requests = [];
    _.forEach(items, item => {
      requests.push(this.deleteUnstandardOrganizationTemplate(item.id));
    });
    return forkJoin(requests).pipe(
      catchError(this.sharedService.handleError<any>('delete unstandard org templates', false))
    );
  }

  // 项目群管理员获取自己所属的细分类
  getProjectCategories(params: IProjectCategoryParams): Observable<IConfigInfo[]> {
    // tslint:disable-next-line:max-line-length
    return this.http.get<IConfigInfo[]>(`/api/divisions/${params.division_org_number}/project-categories/${params.project_admin_user_id}`
    ).pipe(
      catchError(this.sharedService.handleError('get project categories', []))
    );
  }

  // 检测标准组织模板节点路径格式、角色有效性
  getParentNodePathValid(params: INodePathCheckParams): Observable<ICommonResult> {
    return this.http.get<ICommonResult>('/api/templates/projects/orgs/path/check', { params: params as unknown as HttpParams }).pipe(
      catchError(this.sharedService.handleError('check node path', { result: false, msg: '' }))
    );
  }

  // 获取默认组织模板
  getDefaultTemplates(): Observable<IOrganizationTemplate[]> {
    return this.http.get<IOrganizationTemplate[]>('/api/templates/projects/orgs/default-template').pipe(
      catchError(this.sharedService.handleError('get default organization chart items', []))
    );

  }

  verifyOrganizationChartItems(orgChartItems: IOrganizationChartItem[]): boolean {
    // 移除”删除“的组织节点
    const organizationChartItems = _.reject(_.cloneDeep(orgChartItems), { operation: EOrganizationChartItemOperationType.Delete });
    // tslint:disable-next-line: max-line-length
    return this.verifyOrganizationChartItemsNameNotDuplicate(organizationChartItems)
      && this.verifyOrganizationChartItemsPersonsMinLimit(organizationChartItems)
      && this.verifyOrganizationChartItemsEngineersNotNull(organizationChartItems)
      && this.verifyOrganizationChartItemsEngineerNamesNotNull(organizationChartItems)
      && this.verifyOrganizationChartItemsEngineersNotDuplicate(organizationChartItems)
      && this.verifyCanMaintainTeamAndCanSplitNodeNotAtSameTime(organizationChartItems);
  }

  // 校验工程师name是否存在于模板配置中“工程师角色”列表
  /* (导致工程师name为不合法的原因：
  1. 组织模板变更，修改了PMT1下的“工程师角色”
  2. PMT2先进行组织模板变更，提交成功
  3. 导致PMT1进行组织模板变更时，没有“组织模板发生变更”的提醒，未将不符合的工程师角色清空)
  */
  /* (导致工程师name为不合法的原因：
  1. 变更标准组织模板，仅变更工程师角色
  2. PMT在进行组织变更时，提醒模板发生了变更，此时未提交items，仅提交teams，所以未更新组织树item中的“工程师角色”（team_member_roles）属性
  3. 再次进行组织变更时，组织树中的“工程师角色”不准确，与组织模板比较，未清空不合法的工程师角色名称
  */
  verifyOrganizationChartItemsEngineerNamesValid(
    isProjectInWhitelist: boolean,
    orgChartItems: IOrganizationChartItem[]
  ): Observable<boolean> {
    let valid = true;
    orgChartItems = _.cloneDeep(orgChartItems);
    const currentUser = this.userService.getCurrentUserSync()
    orgChartItems = _.filter(orgChartItems, item => {
      return item.operation !== EOrganizationChartItemOperationType.Delete
        && !!item.template_org_node_id
        && item.node_data.is_can_maintain_team
        && _.includes(_.map(item.persons, 'user_id'), currentUser.user_id);
    });
    const requests: Observable<ITeamMemberRole[]>[] = [];
    _.forEach(orgChartItems, item => {
      requests.push(this.multifunctionalTeamService.getDefaultRoleNames(item.template_org_node_id));
    });
    if (!requests.length) {
      return of(true);
    }
    return forkJoin(requests).pipe(
      map(results => {
        _.forEach(orgChartItems, (item, index) => {
          item.team_members = _.reject(item.team_members, { operation: EOrganizationChartItemOperationType.Delete });
          const templateModuleNames = _.map(results[index], 'name');
          _.forEach(item.team_members, teamMember => {
            if (templateModuleNames.length && !_.includes(templateModuleNames, teamMember.module_name)) {
              valid = false;
              const alert: IAlert = {
                type: 'danger',
                title: `“${item.name}”的工程师的角色名称不符合要求，请重新选择`,
              };
              this.subscriptionService.publishAlert(alert);
            }
          });
        });
        return valid;
      })
    )
  }

  // 验证项目经理、项目管理经理、项目管理员、采购工程经理最少一人
  verifyOrganizationChartItemsPersonsMinLimit(organizationChartItems: IOrganizationChartItem[]): boolean {
    // 筛选出有限制的chartItems
    let hasPerson = true;
    const filterOrganizationChartItems = _.filter(organizationChartItems, (organizationChartItem) => {
      return organizationChartItem.person_min_limit + '' === '1';
    });
    _.forEach(filterOrganizationChartItems, (organizationChartItem) => {
      if (!organizationChartItem.persons || organizationChartItem.persons.length < 1) {
        const alert: IAlert = {
          type: 'danger',
          title: '添加成员',
          content: ['项目经理、项目管理经理、项目管理员、采购工程经理成员均不能为空']
        };
        this.subscriptionService.publishAlert(alert);
        hasPerson = false;
      }
    });
    return hasPerson;
  }

  // 验证'PMT经理是否可维护工程师'和'组织节点是否可向下拆解'不是同时维护
  verifyCanMaintainTeamAndCanSplitNodeNotAtSameTime(organizationChartItems: IOrganizationChartItem[]): boolean {
    let maintainNotAtSameTime = true;
    let invalidOrgChartItem: IOrganizationChartItem;
    _.forEach(organizationChartItems, (organizationChartItem, i) => {
      if (organizationChartItem.node_data.is_can_maintain_team === true
        && organizationChartItem.node_data.is_can_split_node === true) {
        maintainNotAtSameTime = false;
        invalidOrgChartItem = organizationChartItem;
      }
    });
    if (!maintainNotAtSameTime) {
      const alert: IAlert = {
        type: 'danger',
        title: `「${invalidOrgChartItem?.name}」组织节点的'组织节点是否可向下拆解'与'PMT经理是否可维护工程师'不允许同时维护`,
      };
      this.subscriptionService.publishAlert(alert);
      return false;
    }
    return true;
  }

  // 验证自定义节点'PMT经理是否可维护工程师'和'组织节点是否可向下拆解'不是同时维护
  verifyCustomNodeCanMaintainTeamAndCanSplitNodeNotAtSameTime(organizationChartItem): boolean {
    let maintainNotAtSameTime = true;
    if (organizationChartItem.node_data.is_can_maintain_team === true
      && organizationChartItem.node_data.is_can_split_node === true) {
      maintainNotAtSameTime = false;
    }
    if (!maintainNotAtSameTime) {
      const alert: IAlert = {
        type: 'danger',
        title: `'组织节点是否可向下拆解'与'PMT经理是否可维护工程师'不允许同时维护`,
      };
      this.subscriptionService.publishAlert(alert);
      return false;
    }
    return true;
  }
  // 同一级别的组织节点名称不能重复
  verifyOrganizationChartItemsNameNotDuplicate(organizationChartItems: IOrganizationChartItem[]): boolean {
    // 去掉name空格
    let flag = true;
    _.forEach(organizationChartItems, (organizationChartItem => {
      organizationChartItem.name = organizationChartItem.name.trim();
    }));
    const groupByParentId = _.groupBy(organizationChartItems, 'parent_id');
    const parentIds = _.uniq(_.map(organizationChartItems, 'parent_id'));
    _.forEach(parentIds, parentId => {
      const noDuplicateOrganizationChartItems: IOrganizationChartItem[] = _.uniqBy(groupByParentId[parentId], 'name');
      if (noDuplicateOrganizationChartItems.length !== groupByParentId[parentId].length) {
        const alert: IAlert = {
          type: 'danger',
          title: '同一级别的组织节点名称不能重复',
        };
        this.subscriptionService.publishAlert(alert);
        flag = false;
      }
    });
    return flag;
  }

  // 工程师不能为空（不选人）
  verifyOrganizationChartItemsEngineersNotNull(organizationChartItems: IOrganizationChartItem[]): boolean {
    let valid = true;
    _.forEach(organizationChartItems, item => {
      if (EOrganizationChartItemOperationType.Delete === item.operation) {
        return;
      }
      item.team_members = _.reject(item.team_members, { operation: EOrganizationChartItemOperationType.Delete });
      if (!(item.team_members?.length > 1)) {
        return;
      }
      _.forEach(item.team_members, team => {
        valid = valid && !!team.user_id;
        if (!valid) {
          const alert: IAlert = {
            type: 'danger',
            title: `“${team.module_name}”工程师不能为空`,
          };
          this.subscriptionService.publishAlert(alert);
          return false;
        }
      });
      return valid;
    });
    return valid;
  }

  // 工程师name不能为空
  // (导致name为空的原因：组织模板变更，修改了“工程师角色”，导致原来使用的“工程师角色”不存在了，会清空工程师name)
  // 仅当前用户是PMT经理 && 当前用户自己的工程师被清空，给出提醒，并不允许提交
  verifyOrganizationChartItemsEngineerNamesNotNull(organizationChartItems: IOrganizationChartItem[]): boolean {
    let valid = true;
    _.forEach(organizationChartItems, item => {
      if (EOrganizationChartItemOperationType.Delete === item.operation) {
        return;
      }
      const currentUser = this.userService.getCurrentUserSync();
      if (item.node_data.is_can_maintain_team && _.includes(_.map(item.persons, 'user_id'), currentUser.user_id)) {
        item.team_members = _.reject(item.team_members, { operation: EOrganizationChartItemOperationType.Delete });
        _.forEach(item.team_members, team => {
          if (!team.module_name) {
            const alert: IAlert = {
              type: 'danger',
              title: `“${item.name}”的工程师的角色名称不能为空`,
            };
            this.subscriptionService.publishAlert(alert);
            valid = false;
          }
        });
      }
      return valid;
    });
    return valid;
  }

  // 工程师的模块名、用户不能同时一样
  // 仅对PMT经理做校验，其他角色允许提交
  verifyOrganizationChartItemsEngineersNotDuplicate(organizationChartItems: IOrganizationChartItem[]): boolean {
    let valid = true;
    let duplicateEngineer: IOrganizationTeamItem = null;
    _.forEach(organizationChartItems, item => {
      if (EOrganizationChartItemOperationType.Delete === item.operation) {
        return;
      }
      const currentUser = this.userService.getCurrentUserSync();
      if (item.node_data.is_can_maintain_team && _.includes(_.map(item.persons, 'user_id'), currentUser.user_id)) {
        item.team_members = _.reject(item.team_members, { operation: EOrganizationChartItemOperationType.Delete });
        if (!(item.team_members?.length > 1)) {
          return;
        }
        const teamMembers = item.team_members;
        _.forEach(teamMembers, member => {
          if (!member.user_id) {
            return;
          }
          const duplicateEngineers = _.filter(teamMembers, {
            module_name: member.module_name,
            user_id: member.user_id
          });
          if (duplicateEngineers.length > 1) {
            duplicateEngineer = duplicateEngineers[0];
            const alert: IAlert = {
              type: 'danger',
              title: `“${duplicateEngineer.module_name}”重复`
            };
            this.subscriptionService.publishAlert(alert);
            valid = false;
          }
        });
      }
    });
    return valid;
  }

  // 校验管理经理节点是否合法（属于当前项目的事业部、项目群下的管理经理）
  verifyProjectManagementAdminValid(
    allOrganizationChartItems: IOrganizationChartItem[],
    project: IProject,
    isProjectInWhitelist: boolean): Observable<boolean> {
    if (!isProjectInWhitelist) {
      return of(true);
    }
    const organizationChartItems = _.reject(allOrganizationChartItems, { operation: EOrganizationChartItemOperationType.Delete });
    const projectManagementAdminItem = _.find(organizationChartItems, ORGANIZATION_CHART_MANAGEMENT_ADMIN) as IOrganizationChartItem;
    if (!projectManagementAdminItem) {
      return of(true);
    }
    let projectManagementAdminValid = true;
    const params: IProjectManagementAdminParams = {
      division_org_number: project.management_division_org_number,
      project_group_key: project.project_group,
      limit: 999
    };
    return this.projectRoleService.getProjectManagementAdmins(params).pipe(
      map(admins => {
        const userIds = _.map(admins, 'user_id');
        _.forEach(projectManagementAdminItem.persons, ProjectManagementAdmin => {
          if (!_.includes(userIds, ProjectManagementAdmin.user_id)) {
            projectManagementAdminValid = _.includes(userIds, ProjectManagementAdmin.user_id);
          }
        });
        if (!projectManagementAdminValid) {
          const alert: IAlert = {
            type: 'danger',
            title: `组织树中的管理经理不是${project.management_host_name}-${project.project_group_name}下的，请重新选择`,
          };
          this.subscriptionService.publishAlert(alert);
        }
        return projectManagementAdminValid;
      })
    );
  }

  // 项目细分类必选
  verifyHasChooseProjectCategory(categoryKey: string) {
    if (!categoryKey) {
      const alert: IAlert = {
        type: 'danger',
        title: '请先选择项目细分类',
      };
      this.subscriptionService.publishAlert(alert);
      return false;
    }
    return true;

  }

  //  标准组织模板和自定义模板的最小人数不能大于最大人数
  verifyMaxNumberGreaterMinNumber(maxNumber: number, minNumber: number) {
    if (minNumber > maxNumber) {
      const alert: IAlert = {
        type: 'danger',
        title: '角色最少人员数不能大于角色最多人员数',
      };
      this.subscriptionService.publishAlert(alert);
      return false;
    }
    return true;
  }

  // 获取组织节点ID
  generateOrganizationChartItemId(): Observable<string> {
    const options = { // 防止string型的response被直接转换成number，丢失精度
      responseType: 'text' as 'json'
    };
    return this.http.get<string>('/api/audits/newid', options).pipe(
      catchError(this.sharedService.handleError('get organizationChartItem id', ''))
    );
  }

  // 批量生成组织节点ID
  generateOrganizationChartItemIds(count?: number): Observable<string[]> {
    const params = { count };
    return this.http.get<string[]>('/api/audits/newids', { params: params as unknown as HttpParams }).pipe(
      catchError(this.sharedService.handleError('get organizationChartItem ids', []))
    );
  }

  // 由标准组织模板生成组织节点列表
  // 1.选择标准组织模板时，无allOrganizationChartItems参数，标准组织模板所有节点生成组织节点列表
  // 2.标准组织模板发生变更，进行模板替换时，有allOrganizationChartItems参数，取出标准组织模板中不可删节点，以及存在组织树上的可删节点，生成组织节点列表
  generateOrganizationChartItemsFromTemplate(
    organizationTemplateId: number,
    allOrganizationChartItems?: IOrganizationChartItem[] // 组织树的所有节点列表(由当前组织树与变更节点merge生成)
  ): Observable<IOrganizationChartItem[]> {
    let organizationTemplate: IOrganizationTemplate;
    let templateItems: IOrganizationChartItem[];
    const params = {
      id: organizationTemplateId,
      type: EOrganizationTemplateType.Standard
    };
    return this.getOrganizationTemplate(params).pipe(
      switchMap(template => {
        organizationTemplate = template;
        // 组织模板被删除，则返回空 requests 列表
        if (organizationTemplate.is_deleted) {
          this.reduxService.deleteOrganizationTemplateOrgHashCode(organizationTemplateId);
          return of([]);
        }
        return this.generateOrganizationChartItemIds(organizationTemplate.data.items.length);
      }),
      map(newCodes => {
        // 组织模板被删除，则返回空组织节点列表
        if (!newCodes.length) {
          return [];
        }
        if (!allOrganizationChartItems) {
          templateItems = _.sortBy(organizationTemplate.data.items, 'rank');
        } else {
          templateItems = _.sortBy(
            this.filterDisableAndPartEnableTemplateChartItems(allOrganizationChartItems, organizationTemplate.data.items), 'rank');
        }
        const organizationChartItems: IOrganizationChartItem[] = [];
        _.forEach(templateItems, (templateItem, index) => {
          organizationChartItems.push({
            project_code: undefined,  // 根据项目进行分配
            parent_id: undefined, // 根据父节点进行分配
            level: undefined, // 根据父节点进行分配
            parent_node_path: undefined, // 根据父节点进行分配
            id: newCodes[index],
            name: templateItem.name,
            engin_field_key: templateItem.engin_field_key,
            engin_field_name: templateItem.engin_field_name,
            node_data: templateItem.node_data,
            template_org_id: organizationTemplate.id,
            template_org_node_id: templateItem.id,
            persons: templateItem.persons,
            team_members: templateItem.team_members,
            team_member_roles: templateItem.team_member_roles,
            disable_delete: templateItem.disable_delete,
            disable_edit_name: templateItem.disable_edit_name,
            person_min_limit: templateItem.person_min_limit,
            person_max_limit: templateItem.person_max_limit,
            rank: templateItem.rank // 为节点添加rank属性
          });
        });
        this.reduxService.addOrganizationTemplateOrgHashCode({
          template_org_id: organizationTemplate.id,
          template_hash_code: organizationTemplate.nodes_hash_code
        });
        return organizationChartItems;
      })
    );
  }

  // 从标准组织模板中，过滤出不可删节点、（可删节点 && 存在于原组织树）
  private filterDisableAndPartEnableTemplateChartItems(
    organizationChartItems: IOrganizationChartItem[], // 组织树的所有节点列表
    templateChartItems: IOrganizationChartItem[] // 标准组织模板所有组织节点
  ): IOrganizationChartItem[] {
    // 标准组织模板中不可删除的节点
    const disableDeleteTemplateChartItems = _.filter(templateChartItems, organizationChartItem => {
      return organizationChartItem.node_data.disable_delete === true;
    });
    const enableDeleteAndExistInOldOrganizationChartItems = [];
    // 标准组织模板中可删节点
    const enableDeleteTemplateChartItems = _.filter(templateChartItems, organizationChartItem => {
      return organizationChartItem.node_data.disable_delete === false;
    });
    _.forEach(enableDeleteTemplateChartItems, enableDeleteTemplateItem => {
      const existOrganizationChartItem = _.find(_.reject(organizationChartItems, {
        operation: EOrganizationChartItemOperationType.Delete
      }), {
        name: enableDeleteTemplateItem.name,
        level: enableDeleteTemplateItem.level
      });
      if (existOrganizationChartItem) {
        // 属于标准组织模板中可删节点 && 存在于组织树的节点
        enableDeleteAndExistInOldOrganizationChartItems.push(enableDeleteTemplateItem);
      }
    });
    const templateItems = _.concat(disableDeleteTemplateChartItems, enableDeleteAndExistInOldOrganizationChartItems);
    return templateItems;

  }


  // 由个人组织模板生成组织节点列表
  // tslint:disable-next-line:max-line-length
  generateOrganizationChartItemsFromPersonalTemplate(organizationTemplateId: number, projectCode: string): Observable<IOrganizationChartItem[]> {
    let organizationTemplate: IOrganizationTemplate;
    const params = {
      id: organizationTemplateId,
      type: EOrganizationTemplateType.Personal
    };
    return this.getOrganizationTemplate(params).pipe(
      switchMap(template => {
        organizationTemplate = template;
        return this.generateOrganizationChartItemIds(organizationTemplate.data.items.length);
      }),
      map(newCodes => {
        const organizationChartItems = organizationTemplate.data.items;
        _.forEach(organizationChartItems, (item, index) => {
          const oldId = item.id;
          item.id = newCodes[index];
          item.project_code = projectCode;
          item._enable_edit = true;
          item.operation = EOrganizationChartItemOperationType.Add;
          const children = _.filter(organizationChartItems, { parent_id: oldId });
          if (!children.length) {
            return;
          }
          // 将子节点的parentId替换
          _.forEach(children, child => {
            child.parent_id = item.id;
          });
        });
        return organizationChartItems;
      })
    );
  }


  // 根据node_path生成parent_node_path
  // 0级别及以上: node_path格式'/项目经理/产品工程经理/' parent_node_path为'/项目经理/';
  // 0级别以下: node_path格式为'/总经理/经理/项目经理/' parent_node_path为'/经理/项目经理/'
  generateParentNodePath(nodePath: string, level: number): string {
    if (level >= 0) {
      const length = nodePath.lastIndexOf('/');
      return nodePath.substring(0, nodePath.lastIndexOf('/', length - 1) + 1);
    } else {
      const path = nodePath.substring(1);
      return path.substring(path.indexOf('/'));
    }
  }

  // 标签
  getOrganizationNodeTags(): IOrganizationNodeTag[] {
    return [
      {
        name: '国内',
        key: EOrganizationNodeTagType.Domestic
      }, {
        name: '国外',
        key: EOrganizationNodeTagType.Foreign
      }
    ];
  }

  // 检查组织树中使用的组织模板是否发生了变更
  verifyOrganizationTemplatesUpdated(
    organizationChartItems: IOrganizationChartItem[], // 组织树的所有节点列表
    templateOrgHashCodes: IOrganizationTemplateOrgHashCode[],
    currentUser?: IUserInfo,  // (处理：组织模板中，节点的"工程师角色"发生变更)
    isProjectInWhitelist?: boolean,   // (处理：组织模板中，节点的"工程师角色"发生变更)
    permissions: string[] = ['/'] // （处理：模板替换后，新增的组织节点未分配_enable_edit属性，导致未展示“添加成员”按钮，需刷新后才可展示）
  ): Observable<boolean> {
    return this.getUpdatedOrganizationTemplates(templateOrgHashCodes).pipe(
      map(updatedOrganizationTemplates => {
        if (!updatedOrganizationTemplates.length) {
          return false;
        }
        this.reduxService.showDialog(
          '加载新组织模板数据',
          [`您所使用的组织模板已发生变更，点击“确定”加载最新数据。`],
          this.updateTemplatedOrganizationChartItems,
          undefined,
          {
            organizationChartItems,
            updatedOrganizationTemplates,
            currentUser,
            isProjectInWhitelist,
            permissions
          }
        );
        return true;
      })
    );
  }

  // 对比获取：发生了变更的标准组织模板
  private getUpdatedOrganizationTemplates(templateOrgHashCodes: IOrganizationTemplateOrgHashCode[]): Observable<IOrganizationTemplate[]> {
    if (!templateOrgHashCodes?.length) {
      return of([]);
    }
    const requests: Observable<IOrganizationTemplate>[] = [];
    _.forEach(templateOrgHashCodes, templateInfo => {
      const params = {
        id: templateInfo.template_org_id,
        type: EOrganizationTemplateType.Standard
      };
      requests.push(this.getOrganizationTemplate(params));
    });
    return forkJoin(requests).pipe(
      map(organizationTemplates => {
        const updatedOrganizationTemplates = [];
        _.forEach(organizationTemplates, (organizationTemplate, index) => {
          // 被删除 || 被修改
          if (organizationTemplate.is_deleted || organizationTemplate.nodes_hash_code !== templateOrgHashCodes[index].template_hash_code) {
            updatedOrganizationTemplates.push(organizationTemplate);
          }
        });
        return updatedOrganizationTemplates;
      }));
  }

  // 变更了组织模板，使用组织模板新数据，更新组织节点
  private updateTemplatedOrganizationChartItems = (params: {
    organizationChartItems: IOrganizationChartItem[], // 组织树的所有节点列表
    updatedOrganizationTemplates: IOrganizationTemplate[], // 发生变更的组织模板列表
    currentUser: IUserInfo,
    isProjectInWhitelist: boolean,
    permissions: string[]
  }): void => {
    // 由模板生成的组织节点（使用find只找第一个）
    const templatedItems: IOrganizationChartItem[] = [];
    _.forEach(params.updatedOrganizationTemplates, template => {
      templatedItems.push(_.find(params.organizationChartItems, { template_org_id: template.id }));
    });
    // 由模板生成的节点的父节点组成的列表
    const parentItemIds = _.map(templatedItems, 'parent_id');
    const parentItems = [];
    _.forEach(parentItemIds, parentItemId => {
      parentItems.push(_.find(params.organizationChartItems, { id: parentItemId }));
    });

    const requests: Observable<IOrganizationChartItem[]>[] = [];
    _.forEach(params.updatedOrganizationTemplates, template => {
      requests.push(this.generateOrganizationChartItemsFromTemplate(template.id, params.organizationChartItems));
    });
    forkJoin(requests).subscribe(results => {
      _.forEach(results, (newItems, index) => {
        const usedTemplateItem = parentItems[index];
        // 组织模板被删除（组织节点列表为空）
        if (!newItems.length) {
          this.reduxService.addTemplateOrganizationChartItems([], usedTemplateItem.node_path);
          return;
        }

        _.forEach(newItems, item => {
          item.parent_id = usedTemplateItem.id;
          item.level = usedTemplateItem.level + 1;
          item.parent_node_path = ORGANIZATION_CHART_ROOT_PARENT_NODE_PATH === usedTemplateItem.parent_node_path
            ? `/${usedTemplateItem.name}/`
            : `${usedTemplateItem.parent_node_path}${usedTemplateItem.name}/`;
        });
        this.reduxService.addTemplateOrganizationChartItems(newItems, usedTemplateItem.node_path, params.currentUser, params.isProjectInWhitelist, params.permissions);
        this.subscriptionService.publishNeedSaveAudit();
      });

    });
  }


  // 检测PMT经理节点在使用的组织模板中是否发生了变更
  verifyCanMaintainTeamItemsTemplatesUpdated(
    canMaintainTeamItems: IOrganizationChartItem[], // PMT经理节点列表
  ): Observable<boolean> {
    return this.getUpdatedCanMaintainTeamItemsTemplates(canMaintainTeamItems).pipe(
      map(updatedOrganizationTemplates => {
        if (!updatedOrganizationTemplates.length) {
          return false;
        }
        this.reduxService.showDialog(
          '加载新组织模板数据',
          [`您所使用的组织模板已发生变更，点击“确定”加载最新数据。`],
          this.updateTemplatedCanMaintainTeamItems,
          undefined,
          {
            canMaintainTeamItems,
            updatedOrganizationTemplates
          }
        );
        return true;
      })
    );
  }

  private getUpdatedCanMaintainTeamItemsTemplates(
    canMaintainTeamItems: IOrganizationChartItem[] // PMT经理节点列表
  ): Observable<IOrganizationTemplate[]> {
    const requests: Observable<IOrganizationTemplate>[] = [];
    // 过滤标准组织节点
    canMaintainTeamItems = _.filter(canMaintainTeamItems, item => !_.isNull(item.template_org_id));

    if (!canMaintainTeamItems.length) {
      return of([]);
    }
    _.forEach(canMaintainTeamItems, templateInfo => {
      const params = {
        id: templateInfo.template_org_id,
        type: EOrganizationTemplateType.Standard
      };
      requests.push(this.getOrganizationTemplate(params));
    });
    return forkJoin(requests).pipe(
      map(organizationTemplates => {
        const updatedOrganizationTemplates = [];
        // 比较原组织节点PMT经理与标准组织模板中PMT经理属性是否相同
        _.forEach(organizationTemplates, (organizationTemplate, index) => {
          const oldItem = _.find(canMaintainTeamItems, { template_org_id: organizationTemplate.id });
          const templateItem = _.find(organizationTemplate.data.items, {
            name: oldItem.name
          });
          if (!templateItem) {
            // 标准组织模板中PMT节点被删除，不做处理
          }
          // 若模板中工程师所属单位发生变更
          if (templateItem && oldItem.node_data.engineer_org_number !== templateItem.node_data.engineer_org_number) {
            updatedOrganizationTemplates.push(organizationTemplate);
          }
        });
        return updatedOrganizationTemplates;
      }));
  }

  // PMT所在组织模板中PMT节点发生了变更，只更新PMT组织节点
  private updateTemplatedCanMaintainTeamItems = (params: {
    canMaintainTeamItems: IOrganizationChartItem[], // PMT经理节点列表
    updatedOrganizationTemplates: IOrganizationTemplate[] // 发生变更的模板
  }): void => {
    _.forEach(params.updatedOrganizationTemplates, updatedOrganizationTemplate => {
      const oldItem = _.find(params.canMaintainTeamItems, { template_org_id: updatedOrganizationTemplate.id });
      const templateItem = _.find(updatedOrganizationTemplate.data.items, {
        name: oldItem.name
      });
      // 更新PMT组织节点的工程师所属单位属性
      oldItem.node_data.engineer_org_number = templateItem.node_data.engineer_org_number;
      oldItem.node_data.engineer_org_name = templateItem.node_data.engineer_org_name;
      setTimeout(() => {
        this.reduxService.updateOrganizationChartItem(oldItem);
        this.subscriptionService.publishNeedSaveAudit();
      }, 10);
    });
  }

  // 获取二级组织管理员所在事业部
  // tslint:disable-next-line: max-line-length
  getSecondStandardOrganizationTemplateAdminDivisions(params: ISecondStandardOrganizationTemplateAdminDivisionParams): Observable<IConfigInfo[]> {
    return this.http.get<ISecondStandardOrganizationTemplateAdminDivision[]>
      ('/api/divisions/second-org-template/admins/config', { params: params as unknown as HttpParams }).pipe(
        map((results: ISecondStandardOrganizationTemplateAdminDivision[]) => {
          const items: IConfigInfo[] = [];
          _.forEach(results, result => {
            items.push({
              key: result.code,
              name: result.name,
              rank: result.rank
            });
          });
          return items;
        }),
        catchError(this.sharedService.handleError('get second standard organization template admin divisions ', []))
      );
  }

  // 获取二级组织管理员所在细分类
  // tslint:disable-next-line: max-line-length
  getSecondStandardOrganizationTemplateAdminProjectCategories(params: ISecondStandardOrganizationTemplateAdminProjectCategoryParams): Observable<IConfigInfo[]> {
    return this.http.get<ISecondStandardOrganizationTemplateAdminProjectCategory[]>
      ('/api/divisions/second-org-template/admins/config', { params: params as unknown as HttpParams }).pipe(
        map((results: ISecondStandardOrganizationTemplateAdminProjectCategory[]) => {
          const items: IConfigInfo[] = [];
          _.forEach(results, result => {
            items.push({
              key: result.code,
              name: result.name,
              rank: result.rank
            });
          });
          return items;
        }),
        catchError(this.sharedService.handleError('get second standard organization template admin project categories ', []))
      );
  }
}
