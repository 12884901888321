import { Component, OnInit } from '@angular/core';
import { INav } from 'src/app/models';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-system-admin',
  templateUrl: './system-admin.component.html',
  styleUrls: ['./system-admin.component.scss']
})
export class SystemAdminComponent implements OnInit {
  navs: INav[] = [];

  constructor(
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.initNavs();
  }

  initNavs() {
    // 系统管理员
    if (this.userService.getCurrentIsSystemAdminSync()) {
      this.navs = [{
        label: '事业部管理',
        routerLink: 'division',
      }, {
        label: '单子作废',
        routerLink: 'admin-drop',
      }, {
        label: '查看待办',
        routerLink: 'admin-oa-todo',
      },
      // , {
      //   label: '绩效规则配置',
      //   routerLink: 'performance',
      // }, {
      //   label: '绩效审批流程',
      //   routerLink: 'performance-approval',
      // },
      {
        label: '工时规则配置',
        routerLink: 'worktime-rules',
      },
      //  {
      //   label: '打分参考',
      //   routerLink: 'score-reference',
      // }, 
      {
        label: '零部件成熟度',
        routerLink: 'part-auth',
      }, {
        label: '管理经理抢单查询',
        routerLink: 'management-admin-query',
      }, {
        label: '属性配置',
        routerLink: 'property',
      }, {
        label: '所属业务配置',
        routerLink: 'engin-field',
      }];
    }

    // 管理经理
    if (this.userService.getCurrentIsProjectManagementAdminSync()) {
      this.navs = [{
        label: '管理经理抢单查询',
        routerLink: 'management-admin-query',
      }];
    }
  }

}
