import { Action, ActionCreator } from 'redux';
import { IProjectFilterItem, IProjectFilterType, IOrganization, IProjectFilterProjectCode } from 'src/app/models';

//#region
export interface SetProjectFiltersItemsAction extends Action {
  filterType: IProjectFilterType;
  items: IProjectFilterItem[];
}
export const SET_PROJECT_FILTERS_ITEMS = 'SET_PROJECT_FILTERS_ITEMS';
export const setProjectFiltersItems: ActionCreator<SetProjectFiltersItemsAction> = (
  filterType: IProjectFilterType,
  items: IProjectFilterItem[]
) => ({
  type: SET_PROJECT_FILTERS_ITEMS,
  filterType,
  items
});
//#endregion

//#region toggle单个筛选项的选中状态
export interface ToggleCheckProjectFiltersItemAction extends Action {
  filterType: IProjectFilterType;
  item: IProjectFilterItem;
  isMultiple: boolean;
}
export const TOGGLE_CHECK_PROJECT_FILTERS_ITEM = 'TOGGLE_CHECK_PROJECT_FILTERS_ITEM';
export const toggleCheckProjectFiltersItem: ActionCreator<ToggleCheckProjectFiltersItemAction> = (
  filterType: IProjectFilterType,
  item: IProjectFilterItem,
  isMultiple: boolean
) => ({
  type: TOGGLE_CHECK_PROJECT_FILTERS_ITEM,
  filterType,
  item,
  isMultiple
});
//#endregion

//#region 清空某一行的选中状态
export interface ClearCheckProjectFiltersItemsAction extends Action {
  filterType: IProjectFilterType;
}
export const CLEAR_CHECK_PROJECT_FILTERS_ITEMS = 'CLEAR_CHECK_PROJECT_FILTERS_ITEMS';
export const clearCheckProjectFiltersItems: ActionCreator<ClearCheckProjectFiltersItemsAction> = (filterType: IProjectFilterType) => ({
  type: CLEAR_CHECK_PROJECT_FILTERS_ITEMS,
  filterType
});
//#endregion

//#region 组织
export interface SetProjectFiltersOrganizationsAction extends Action {
  items: IOrganization[];
}
export const SET_PROJECT_FILTERS_ORGANIZATIONS = 'SET_PROJECT_FILTERS_ORGANIZATIONS';
export const setProjectFiltersOrganizations: ActionCreator<SetProjectFiltersOrganizationsAction> = (
  items: IOrganization[]
) => ({
  type: SET_PROJECT_FILTERS_ORGANIZATIONS,
  items
});
//#endregion

//#region 项目编码
export interface SetProjectFiltersProjectCodesAction extends Action {
  items: IProjectFilterProjectCode[];
}
export const SET_PROJECT_FILTERS_PROJECT_CODES = 'SET_PROJECT_FILTERS_PROJECT_CODES';
export const setProjectFiltersProjectCodes: ActionCreator<SetProjectFiltersProjectCodesAction> = (
  items: IProjectFilterProjectCode[]
) => ({
  type: SET_PROJECT_FILTERS_PROJECT_CODES,
  items
});
//#endregion
