// 若已初始化完成，setup路由不可访问，强制跳转至工作中心
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { SharedService } from '../services/shared.service';
import { Observable } from 'rxjs';
import { SetupService } from '../services/setup.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
// tslint:disable-next-line:class-name

export class SetupGuard implements CanActivate {
  constructor(
    private router: Router,
    private setupService: SetupService,
    private sharedService: SharedService
  ) { }
  canActivate(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if (this.setupService.getSetupIsDone()) {
      this.router.navigate(['/work-center']);
      return false;
    } 
    return true;
  }
}