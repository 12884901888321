import { Action, ActionCreator } from 'redux';
import { ECheckUsersType } from 'src/app/enums';
import { ITurnCallback, ITurnInfo, ITaskInfo, ITurnFailedCallback, IWorkflow } from 'src/app/models';

// #region 显示
export interface ShowTurnAction extends Action {
  callback: any;
  callbackFailed: any;
  auditId: string;
  turnTask: ITaskInfo; // 有：信息维护下发单，可以在项目群管理员之间转办；组织管理单转办，可以转办给相应权限人 无：普通转办
  turnBy: string;  // 进行管理员转办时，传当前处理人的account（场景：处理人离职了，管理员将其转办给他人）
  flow: IWorkflow; // （流程审批移交需要显示审批流） 有：系统管理员流程审批移交； 无：普通转办
  turnUserType: ECheckUsersType; // 无: 所有用户 有: 转办的用户类型
}

export const SHOW_TURN = 'SHOW_TURN';
export const showTurn: ActionCreator<ShowTurnAction> = (
  callback: ITurnCallback,
  callbackFailed: ITurnFailedCallback,
  auditId: string,
  turnTask,
  turnBy: string,
  flow: IWorkflow,
  turnUserType: ECheckUsersType
) => ({
  type: SHOW_TURN,
  callback,
  callbackFailed,
  auditId,
  turnTask,
  turnBy,
  flow,
  turnUserType
});
// #endregion

// #region 确定
// tslint:disable-next-line: no-empty-interface
export interface ConfirmTurnAction extends Action {
}

export const CONFIRM_TURN = 'CONFIRM_TURN';
export const confirmTurn: ActionCreator<ConfirmTurnAction> = () => ({
  type: CONFIRM_TURN
});
// #endregion

// #region 取消
// tslint:disable-next-line: no-empty-interface
export interface CancelTurnAction extends Action {
}

export const CANCEL_TURN = 'CANCEL_TURN';
export const cancelTurn: ActionCreator<CancelTurnAction> = () => ({
  type: CANCEL_TURN
});
// #endregion

// #region 设置转办信息
export interface SetTurnInfoAction extends Action {
  turnInfo: ITurnInfo;
}

export const SET_TURN_INFO = 'SET_TURN_INFO';
export const setTurnInfo: ActionCreator<SetTurnInfoAction> = (turnInfo: ITurnInfo) => ({
  type: SET_TURN_INFO,
  turnInfo
});

// #endregion
