import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SharedService } from 'src/app/services/shared.service';
import { ISetup, ISetupCommand, ISetupStep } from '../models';
import { EConfigType } from '../enums';
import { ADMIN_SETTINGS_STEP_NUMBER, ENGIN_FIELD_STEP_NUMBER, MILESTONE_TEMPLATE_STEP_NUMBER, PROJECT_INFO_DROPDOWN_STEP_NUMBER } from '../consts';

@Injectable({
  providedIn: 'root'
})
export class SetupService {

  setupSteps: ISetupStep[] = [
    { name: '项目信息下拉值', is_done: false, current_step: PROJECT_INFO_DROPDOWN_STEP_NUMBER, url: '/setup/project-info-dropdown' },
    { name: '管理员', is_done: false, current_step: ADMIN_SETTINGS_STEP_NUMBER, url: '/setup/admins-setting' },
    { name: '节点模板', is_done: false, current_step: MILESTONE_TEMPLATE_STEP_NUMBER, url: '/setup/milestone-template' },
    { name: '所属业务', is_done: false, current_step: ENGIN_FIELD_STEP_NUMBER, url: '/setup/engin-field' }
  ];

  // - 项目信息下拉值列表中必须维护的属性
  // - 管理主体（type=170）、开发主体（type=180）、项目级别（type=30）、细分类（type=120）、项目群（type=20）、所属年度（type=300）
  propertyTypes: number[] = [
    EConfigType.ProjectCategory,
    EConfigType.ManagementHost,
    EConfigType.DevelopmentHost,
    EConfigType.ProjectGroup,
    EConfigType.ProjectLevel,
    EConfigType.BelongYear
  ];

  constructor(
    private http: HttpClient,
    private sharedService: SharedService
  ) { }

  // 查询设置进度
  getSetupSteps(): Observable<ISetupStep[]> {
    return of(this.setupSteps);
  }

  // 查询设置进度
  getSetup(): Observable<ISetup> {
    return this.http.get<ISetup>(`/api/configs/setup`).pipe(
      catchError(this.sharedService.handleError<ISetup>('get set up', { current_step: 0, is_done: false }))
    ).pipe(
      map(result => {
        this.setSetupIsDone(result.is_done);
        return result;
      })
    );
  }

  // 设置进度
  updateSetup(command: ISetupCommand): Observable<any> {
    return this.http.post<ISetup>(`/api/configs/setup/next`, command).pipe(
      catchError(this.sharedService.handleError<ISetup>('update set up', undefined))
    );
  }

  // 项目信息下拉值中必须维护的属性
  getMustMaintainPropertyTypesListSync(): number[] {
    return this.propertyTypes;
  }

  setSetupIsDone(value: boolean): void {
    sessionStorage.setItem('is_done', JSON.stringify(value));
  }

  getSetupIsDone(): boolean {
    return JSON.parse(sessionStorage.getItem('is_done'));
  }

}
