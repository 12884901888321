import { Reducer, Action } from 'redux';
import {
  SetMilestonesAction,
  SetCurrentMilestoneAction,
  SetEndOnAction,
  SET_MILESTONES,
  SET_CURRENT_MILESTONE,
  SET_END_ON
} from '../actions/milestone.actions';
import { createSelector } from 'reselect';
import { IMilestone} from 'src/app/models';
import _ from 'lodash';

export interface MilestoneState {
  milestones: IMilestone[];
  currentMilestone: IMilestone;
  endOn: Date;
}

const initialState: MilestoneState = {
  milestones: [],
  currentMilestone: null,
  endOn: null
};

export const MilestoneReducer: Reducer<MilestoneState> =
  (state: MilestoneState = initialState, action: Action): MilestoneState => {
    switch (action.type) {
      case SET_MILESTONES:
        const milestones = (action as SetMilestonesAction).milestones;
        return Object.assign({}, state, { milestones });
      case SET_CURRENT_MILESTONE:
        const currentMilestone = (action as SetCurrentMilestoneAction).currentMilestone;
        return Object.assign({}, state, { currentMilestone });
      case SET_END_ON:
        const endOn = (action as SetEndOnAction).endOn;
        return Object.assign({}, state, { endOn });
      default:
        return state;
    }
  };

const getState = (state: MilestoneState): MilestoneState => state;
export const getMilestones = createSelector(
  getState,
  (state: MilestoneState) => state.milestones
);

export const getCurrentMilestone = createSelector(
  getState,
  (state: MilestoneState) => state.currentMilestone
);

export const getEndOn = createSelector(
  getState,
  (state: MilestoneState) => state.endOn
);
