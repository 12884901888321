import { Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChange, SimpleChanges } from '@angular/core';
import { CCheckBase } from 'src/app/classes';
import { IOrganizationChartItem } from 'src/app/models';
import { OrganizationTemplateService } from 'src/app/services/organization-template.service';

@Component({
  selector: 'app-check-standard-organization-items',
  templateUrl: './check-standard-organization-items.component.html',
  styleUrls: ['./check-standard-organization-items.component.scss']
})
export class CheckStandardOrganizationItemsComponent extends CCheckBase<IOrganizationChartItem, any> implements OnInit, OnChanges {

  @Input() items: IOrganizationChartItem[];
  @Output()
  checkedItemsChange: EventEmitter<IOrganizationChartItem[]> = new EventEmitter();
  showItemsFlag: boolean;

  constructor(
    private organizationTemplateService: OrganizationTemplateService
  ) {
    super(organizationTemplateService, 'getOrganizationTemplate', 'id');

  }

  ngOnInit() {
  }

  ngOnChanges(change: SimpleChanges) {
    if (change.items.currentValue) {
      super.setItems(this.items);
      this.showItemsFlag = true;
    }
  }

  toggleCheckItem(item: IOrganizationChartItem) {
    super.toggleCheckItem(item);
    this.checkedItemsChange.emit(this.checkedItems);
  }

  toggleCheckAll() {
    super.toggleCheckAll();
    this.checkedItemsChange.emit(this.checkedItems);
  }

}
