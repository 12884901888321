import { Action, ActionCreator } from 'redux';
import { IDialogCallback } from 'src/app/models';

// #region 显示
export interface ShowDialogAction extends Action {
  title: string;
  content: string[];
  confirmCallback: IDialogCallback;
  cancelCallback?: IDialogCallback;
  confirmCallbackParams?: any;
  cancelCallbackParams?: any;
  tip?: string;
}

export const SHOW_DIALOG = 'SHOW_DIALOG';
export const showDialog: ActionCreator<ShowDialogAction> = (
  title: string,
  content: string[],
  confirmCallback: IDialogCallback,
  cancelCallback?: IDialogCallback,
  confirmCallbackParams?: any,
  cancelCallbackParams?: any,
  tip?: string
) => ({
  type: SHOW_DIALOG,
  title,
  content,
  confirmCallback,
  cancelCallback,
  confirmCallbackParams,
  cancelCallbackParams,
  tip
});
// #endregion

// #region 确定
// tslint:disable-next-line: no-empty-interface
export interface ConfirmDialogAction extends Action {
}

export const CONFIRM_DIALOG = 'CONFIRM_DIALOG';
export const confirmDialog: ActionCreator<ConfirmDialogAction> = () => ({
  type: CONFIRM_DIALOG
});
// #endregion

// #region 取消
// tslint:disable-next-line: no-empty-interface
export interface CancelDialogAction extends Action {
}

export const CANCEL_DIALOG = 'CANCEL_DIALOG';
export const cancelDialog: ActionCreator<CancelDialogAction> = () => ({
  type: CANCEL_DIALOG
});
// #endregion
