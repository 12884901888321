// *组织的共享级别
export enum EOrganizationShareLevel {
    /// <summary>
    /// 仅个人可见
    /// </summary>
    Personal = 0,

    /// <summary>
    /// 项目细分类级别可见
    /// </summary>
    Category = 10,

    /// <summary>
    /// 事业部级别可见
    /// </summary>
    Division = 20,

    /// <summary>
    /// 所有可见
    /// </summary>
    All = 50
}
