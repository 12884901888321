import { Reducer, Action } from 'redux';
import { createSelector } from 'reselect';
import _ from 'lodash';

import {
    SHOW_ADD_ORGANIZATION_CHART_ITEM,
    CANCEL_ADD_ORGANIZATION_CHART_ITEM,
    CONFIRM_ADD_ORGANIZATION_CHART_ITEM,
    SET_SHOW_ADD_ORGANIZATION_CHART_ITEMS,
    SET_ADD_ORGANIZATION_CHART_ITEMS_CUSTOM_BLACK_LIST,
    ShowAddOrganizationChartItemAction,
    SetAddOrganizationChartItemAction,
    SetAddOrganizationChartItemsCustomBlackListAction
} from '../actions/add-organization-chart-item.actions';
import {
    IOrganizationChartItem, TOrganizationChartItemAddWay,
} from 'src/app/models';
import { IAddOrganizationChartItemsCallback } from 'src/app/models';

export interface AddOrganizationChartItemState {
    isShow: boolean;
    callback: IAddOrganizationChartItemsCallback;
    addData: number | IOrganizationChartItem | IOrganizationChartItem[];
    addWays: TOrganizationChartItemAddWay[];
    parentNodePath: string;
    enableEdit: boolean;
    customBlackList: string[];   // 不能添加自定义子节点的组织节点列表（path数组）
}

const initialAddOrganizationItem: AddOrganizationChartItemState = {
    isShow: false,
    callback: undefined,
    addData: undefined,
    addWays: ['template', 'custom'],
    parentNodePath: undefined,
    enableEdit: false,
    customBlackList: []
};

export const CheckAddOrganizationChartItemReducer: Reducer<AddOrganizationChartItemState> =
    (state: AddOrganizationChartItemState = initialAddOrganizationItem, action: Action): AddOrganizationChartItemState => {
        switch (action.type) {
            case SHOW_ADD_ORGANIZATION_CHART_ITEM:
                const addWays = (action as ShowAddOrganizationChartItemAction).addWays;
                const parentNodePath = (action as ShowAddOrganizationChartItemAction).parentNodePath;
                const enableEdit = (action as ShowAddOrganizationChartItemAction).enableEdit;
                const callback = (action as ShowAddOrganizationChartItemAction).callback;
                return Object.assign({}, state, {
                    isShow: true,
                    callback,
                    addWays,
                    parentNodePath,
                    enableEdit
                });
            case CANCEL_ADD_ORGANIZATION_CHART_ITEM:
                return Object.assign({}, state, {
                    isShow: false,
                    callback: undefined,
                });
            case SET_SHOW_ADD_ORGANIZATION_CHART_ITEMS:
                const addData = (action as SetAddOrganizationChartItemAction).addData;
                return Object.assign({}, state, {
                    addData
                });
            case CONFIRM_ADD_ORGANIZATION_CHART_ITEM:
                if (state.callback) {
                    state.callback(state.addData);
                }
                return Object.assign({}, state, {
                    isShow: false,
                });
            case SET_ADD_ORGANIZATION_CHART_ITEMS_CUSTOM_BLACK_LIST:
                const customBlackList = (action as SetAddOrganizationChartItemsCustomBlackListAction).customBlackList;
                return Object.assign({}, state, {
                    customBlackList,
                });
            default:
                return state;
        }
    };
const getAddOrganizationChartItemState = (state: AddOrganizationChartItemState): AddOrganizationChartItemState => state;
export const getAddOrganizationChartItemsShow = createSelector(
    getAddOrganizationChartItemState,
    (state: AddOrganizationChartItemState) => state.isShow
);
export const getAddOrganizationItemsCallback = createSelector(
    getAddOrganizationChartItemState,
    (state: AddOrganizationChartItemState) => state.callback
);
export const getAddOrganizationChartItemAddWays = createSelector(
    getAddOrganizationChartItemState,
    (state: AddOrganizationChartItemState) => state.addWays
);
export const getAddOrganizationChartItemsParentNodePath = createSelector(
    getAddOrganizationChartItemState,
    (state: AddOrganizationChartItemState) => state.parentNodePath
);
export const getAddOrganizationChartItemsEnableEdit = createSelector(
    getAddOrganizationChartItemState,
    (state: AddOrganizationChartItemState) => state.enableEdit
);

export const getAddOrganizationChartItemsCustomBlackList = createSelector(
    getAddOrganizationChartItemState,
    (state: AddOrganizationChartItemState) => state.customBlackList
);
