// 计划导入模板类型
export enum EPlanTemplateType {
    /// <summary>
    /// 标准计划
    /// </summary>
    Standard = 0,

    /// <summary>
    /// 专项计划
    /// </summary>
    Spec = 10,

    /// <summary>
    /// 风险计划
    /// </summary>
    Risk = 20,

    /// <summary>
    /// 交付物计划
    /// </summary>
    Deliverable = 30,

    /// <summary>
    /// 标准子计划
    /// </summary>
    StandardChild = 1,
}
