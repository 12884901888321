import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { ITab } from 'src/app/models';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent implements OnInit, OnChanges {
  checkedValue: number | string;
  @Input() items: ITab[] = [];
  @Input() default: number | string;
  @Output() itemChange = new EventEmitter<number | string>();

  constructor() { }

  ngOnInit() {
    this.checkedValue = this.default;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.default?.currentValue) {
      this.checkedValue = this.default;
    }
  }

  check(item: ITab) {
    this.checkedValue = item.value;
    this.itemChange.emit(this.checkedValue);
  }

}
