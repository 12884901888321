import {
  Component,
  TemplateRef,
  ViewChild,
  OnInit
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { throttleTime } from 'rxjs/operators';
import _ from 'lodash';

import { ReduxService } from 'src/app/redux/redux.service';
import { IUserInfo, ICheckUsersCallback, ITurnInfo, ITaskInfo, IAuditInfoTransferUpdateCommand, IAlert, IWorkflow } from 'src/app/models';
import { CheckGroupAdminComponent } from '../check-group-admin/check-group-admin.component';
import { AuditService } from 'src/app/services/audit.service';
import { AlertService } from 'src/app/services/alert.service';
import { IAdminAuditTransferCommand } from 'src/app/models/IAuditInfo';
import { ECheckUsersType, ETaskInfoType, EWorkflowStepStatus } from 'src/app/enums';


const BOX_WIDTH = 120;
const ARROW_WIDTH = 42;
const END_BOX_WIDTH = 82;

@Component({
  selector: 'app-turn',
  templateUrl: './turn.component.html',
  styleUrls: ['./turn.component.scss']
})
export class TurnComponent implements OnInit {
  modalRef: BsModalRef;
  @ViewChild('turn') modalEle: TemplateRef<any>;

  @ViewChild('appCheckGroupAdmin', { static: true }) checkGroupAdminComponent: CheckGroupAdminComponent;

  myForm: FormGroup;
  turnPerson: IUserInfo;
  turnTask: ITaskInfo;
  auditId: string;
  turnInfo: ITurnInfo;
  turnBy: string;
  flow: IWorkflow;
  current: number;
  userId: string;
  workflowStepStatus = EWorkflowStepStatus; // 当前节点下节点人审批通过，则置灰
  turnUserType: ECheckUsersType;

  private confirm$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private modalService: BsModalService,
    private reduxService: ReduxService,
    private fb: FormBuilder,
    private auditService: AuditService,
    private alertService: AlertService,
  ) {
    this.reduxService.subscribeStore(() => this.readState());
    this.readState();
  }

  ngOnInit() {
    this.myForm = this.fb.group({
      remark: ['']
    });
    this.confirm$.pipe(
      throttleTime(1000),
    ).subscribe(() => {
      const turnInfo: ITurnInfo = {
        person: this.turnPerson,
        remark: this.myForm.controls.remark.value
      };
      this.reduxService.setTurnInfo(turnInfo);
      this.reduxService.confirmTurn();
    });
  }

  readState() {
    const isShow = this.reduxService.getTurnShow();
    this.turnTask = this.reduxService.getTurnTask();
    this.auditId = this.reduxService.getTurnAuditId();
    this.turnBy = this.reduxService.getTurnTurnBy();
    this.flow = this.reduxService.getTurnFlow();
    this.turnUserType = this.reduxService.getTurnUserType();

    if (isShow) {
      this.show();
    } else {
      this.hide();
    }
  }

  getWidth(): number {
    return (BOX_WIDTH + ARROW_WIDTH) * (this.flow.steps.length - 1) + END_BOX_WIDTH;
  }

  getSteps() {
    return _.dropRight(this.flow.steps);
  }

  checkPerson(current: number, userId: string) {
    this.current = current;
    this.userId = userId;
  }

  show() {
    if (!this.modalRef) {
      this.turnPerson = undefined;
      this.myForm.controls.remark.setValue('');
      this.modalRef = this.modalService.show(this.modalEle, { class: 'modal-lg', ignoreBackdropClick: true });
    }
  }

  hide() {
    if (this.modalRef) {
      this.modalRef.hide();
      this.modalRef = undefined;
      this.reduxService.cancelTurn();
    }
  }

  confirm() {
    // (系统管理员)流程审批移交
    if (this.flow) {
      const adminAuditTransferCommand = {
        id: this.auditId,
        account: this.turnPerson.user_id, // 接收人
        remark: this.myForm.controls.remark.value,
        current: this.current, // 需要转办的节点index
        user_id: this.userId // 转办人
      } as IAdminAuditTransferCommand;
      this.auditService.adminTransferAudit(adminAuditTransferCommand).subscribe(result => {
        if (result) {
          this.alert('转办成功');
          this.current = undefined;
          this.userId = undefined;
          this.reduxService.confirmTurn();
        }
      });
    } else {
      const command = {
        id: this.auditId,
        account: this.turnPerson.user_id,
        remark: this.myForm.controls.remark.value
      } as IAuditInfoTransferUpdateCommand;
      if (this.turnBy) {  // 管理员转办
        command.user_id = this.turnBy;
      }
      this.auditService.turnAudit(command).subscribe(result => {
        if (result) {
          this.alert('转办成功');
          this.reduxService.confirmTurn();
        }
      });
    }
  }

  toCheckTurnPerson() {
    console.log('to check turn person:');
    if (this.turnTask) { // 信息维护下发单的转办、组织单的转办
      let title: string;
      if (ETaskInfoType.Org === this.turnTask.type) {
        title = '组织单转办接收人';
      }
      this.checkGroupAdminComponent.show(this.turnTask.id, title);
    } else {
      if (!this.turnUserType) {
        // 普通转办
        this.reduxService.showCheckOrganizationUsers(this.checkTurnPerson);

      } else {
        switch (this.turnUserType) {
          case ECheckUsersType.KpiAlphaAdmin:
            this.reduxService.showCheckUsers(this.checkTurnPerson, false, undefined, ECheckUsersType.KpiAlphaAdmin, this.auditId);
            break;
          case ECheckUsersType.KpiGammaAdmin:
            this.reduxService.showCheckUsers(this.checkTurnPerson, false, undefined, ECheckUsersType.KpiGammaAdmin, this.auditId);
            break;
          case ECheckUsersType.PerformanceApprovalAdmin:
            // tslint:disable-next-line:max-line-length
            this.reduxService.showCheckUsers(this.checkTurnPerson, false, undefined, ECheckUsersType.PerformanceApprovalAdmin);
            break;
        }
      }
    }
  }

  checkTurnPerson: ICheckUsersCallback = (users: IUserInfo[]) => {
    this.turnPerson = users[0];
  }

  alert(title: string) {
    const alert: IAlert = {
      type: 'success',
      title,
    };
    this.alertService.addAlert(alert);
  }
}
