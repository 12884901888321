import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import _ from 'lodash';

import {
  IProject,
  IProjectParams,
  IIssuedProjectParams,
  IProjectRelatedInfoParams,
  IProjectRelatedInfo,
  ITaskInfo,
  ITaskInfoParams,
  IListCommonParams,
  IOrganizationChartItem,
  IPlan,
  IDivisionOption,
  IProjectOrganizationInfoParams,
  IProjectOrganizationInfo,
  IProjectMilestoneParams,
  IMilestone,
  IProjectPlanParams,
  IDivisionCategoriesAndGroups,
  IUserInfo,
  IQcts,
  IPfcs,
  IPfcsParams,
  IProjectMdmInfo,
  IProjectViewerParams,
  IProjectViewer,
  IProjectViewerAddCommand,
  IProjectViewerRemoveCommand,
  IProjectViewerResponse,
  IProjectRestartMilestone,
  ISystemModule,
  IAlert,
  IResponse,
  ICategoriesAndGroupsParams,
  IOrganizationUpdateHistoryParams,
  IOrganizationUpdateHistoryItem,
  ISystemModuleType,
  ITcSystemModuleType,
  IWorktimeForecastProject,
  IWorktimeForecastExportResponse,
  IProjectMdmInfoCommand
} from 'src/app/models';
import { ETaskInfoStatus, ETaskInfoType, EConfigType, EModuleType } from 'src/app/enums';
import { PAGE_SIZE, COUNT_THROTTLE_TIME } from 'src/app/consts';
import { SharedService } from './shared.service';
import { TaskInfoService } from './task-info.service';
import { OrganizationTemplateService } from './organization-template.service';
// tslint:disable-next-line: max-line-length
import { IProjectOrgViewer, IProjectOrgViewerAddCommand, IProjectOrgViewerParams, IProjectOrgViewerRemoveCommand } from '../models/IOrganization';
import { SubscriptionService } from './subscription.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {
  apiUrl = '/api/business/projects';

  // tslint:disable-next-line: variable-name
  private _projectsCount = 0;
  // tslint:disable-next-line: variable-name
  private _projectsParams: IProjectParams;
  // tslint:disable-next-line: variable-name
  private _projectsTimeStamp: number;

  constructor(
    private http: HttpClient,
    private sharedService: SharedService,
    private taskInfoService: TaskInfoService,
    private organizationTemplateService: OrganizationTemplateService,
    private subscriptionService: SubscriptionService
  ) { }

  getTodoTaskInfos(params?: IListCommonParams): Observable<ITaskInfo[]> {
    params = params || { page: 1, limit: PAGE_SIZE };
    const taskInfoParams: ITaskInfoParams = {
      ...params,
      type: ETaskInfoType.Project,
      status: ETaskInfoStatus.Pending
    };
    return this.taskInfoService.getTaskInfos(taskInfoParams);
  }

  getTodoTaskInfosCount(params?: IListCommonParams, enforceNew?: boolean): Observable<number> {
    params = params || { page: 1, limit: PAGE_SIZE };
    const taskInfoParams: ITaskInfoParams = {
      ...params,
      type: ETaskInfoType.Project,
      status: ETaskInfoStatus.Pending
    };
    return this.taskInfoService.getTaskInfosCount(taskInfoParams, enforceNew);
  }

  getDoneTaskInfos(params?: IListCommonParams): Observable<ITaskInfo[]> {
    params = params || { page: 1, limit: PAGE_SIZE };
    const taskInfoParams: ITaskInfoParams = {
      ...params,
      type: ETaskInfoType.Project,
      status: ETaskInfoStatus.Processed
    };
    return this.taskInfoService.getTaskInfos(taskInfoParams);
  }

  getDoneTaskInfosCount(params?: IListCommonParams, enforceNew?: boolean): Observable<number> {
    params = params || { page: 1, limit: PAGE_SIZE };
    const taskInfoParams: ITaskInfoParams = {
      ...params,
      type: ETaskInfoType.Project,
      status: ETaskInfoStatus.Processed
    };
    return this.taskInfoService.getTaskInfosCount(taskInfoParams, enforceNew);
  }

  getTurnedTaskInfos(params?: IListCommonParams): Observable<ITaskInfo[]> {
    params = params || { page: 1, limit: PAGE_SIZE };
    const taskInfoParams: ITaskInfoParams = {
      ...params,
      type: ETaskInfoType.Project,
      status: ETaskInfoStatus.Forwarded
    };
    return this.taskInfoService.getTaskInfos(taskInfoParams);
  }

  getTurnedTaskInfosCount(params?: IListCommonParams): Observable<number> {
    params = params || { page: 1, limit: PAGE_SIZE };
    const taskInfoParams: ITaskInfoParams = {
      ...params,
      type: ETaskInfoType.Project,
      status: ETaskInfoStatus.Forwarded
    };
    return this.taskInfoService.getTaskInfosCount(taskInfoParams);
  }

  getProjects(params?: IProjectParams): Observable<IProject[]> {
    params = params || { page: 1, limit: PAGE_SIZE };
    return this.http.get<IProject[]>(`/api/search/projects`, { params: params as HttpParams }).pipe(
      catchError(this.sharedService.handleError<IProject[]>('get projects', []))
    );
  }

  getProjectsCount(params?: IProjectParams, enforceNew?: boolean): Observable<number> {
    params = params || { page: 1, limit: PAGE_SIZE };
    // 节流
    // tslint:disable-next-line: variable-name
    const _params = _.cloneDeep(params);
    delete _params.page;

    const timeStamp = new Date().getTime();
    const isTimeOut = this._projectsTimeStamp && timeStamp - this._projectsTimeStamp > COUNT_THROTTLE_TIME;

    // ！强制获取新数据 && ！超时 && 参数不变，返回之前获取的count
    if (!enforceNew && !isTimeOut && _.isEqual(this._projectsParams, _params)) {
      return of(this._projectsCount);
    }

    return this.http.get<number>(`/api/search/projects-count`, { params: params as HttpParams }).pipe(
      tap(count => {
        this._projectsCount = count;
        this._projectsParams = _.cloneDeep(_params);
        this._projectsTimeStamp = timeStamp;
      }),
      catchError(this.sharedService.handleError<number>('get projects count', 0))
    );
  }

  getProject(code: string, version?: number, includeSchedules?: boolean): Observable<IProject> {
    const params = this.sharedService.omitEmptyParams({
      code,
      version,
      include_schedules: includeSchedules
    });
    let project: IProject;
    return this.http.get<IProject>(`${this.apiUrl}/${code}`, { params: params as unknown as HttpParams }).pipe(
      switchMap(projectInfo => {
        project = projectInfo;
        return this.getCurrentHasPartAuth(projectInfo.code);
      }),
      map(hasViewPartAuth => {
        project._hasViewPartAuth = hasViewPartAuth;
        return project;
      }),
      catchError(this.sharedService.handleError<IProject>(`get project by code: ${code}`, undefined))
    );
  }

  getLatelyProjects(): Observable<IProject[]> {
    return of([]);  // TODO
  }
  // 获取细分类、项目群
  // 通过事业部或开发主体
  getCategoriesAndGroups(params: ICategoriesAndGroupsParams): Observable<IDivisionCategoriesAndGroups> {
    let queryParams = {};
    // 如果有事业部，通过事业部获取项目群、细分类（项目节点报表，工时统计报表）
    if ('division' === params.by) {
      queryParams = { division: params.code };
    } else {
      // 如果有开发主体，通过开发主体获取项目群、细分类（项目列表，项目计划报表）
      queryParams = { development_host: params.code };
    }
    return this.http.get<IDivisionOption[]>(`/api/search/division-options`,
      { params: queryParams as unknown as HttpParams }
    ).pipe(
      map(divisionOptions => {
        const divisionCategories = _.filter(divisionOptions, ['type', EConfigType.ProjectCategory]);
        const categories = [];
        _.forEach(divisionCategories, item => {
          categories.push({
            key: item.key,
            name: item.name
          });
        });

        const groups = [];
        const divisionGroups = _.filter(divisionOptions, ['type', EConfigType.ProjectGroup]);
        _.forEach(divisionGroups, item => {
          groups.push({
            key: item.key,
            name: item.name
          });
        });
        return {
          categories,
          groups
        };
      }),
      catchError(this.sharedService.handleError<IDivisionCategoriesAndGroups>('get division categories and groups', undefined))
    );
  }

  // 获取项目组织信息（项目组织下发通过后才有）
  getProjectOrganizationsInfo(params: IProjectOrganizationInfoParams): Observable<IProjectOrganizationInfo> {
    return this.http.get<IProjectOrganizationInfo>(`${this.apiUrl}/${params.project_code}/${params.audit_id}/org`).pipe(
      catchError(this.sharedService.handleError<IProjectOrganizationInfo>('get project organizations info', undefined))
    );
  }

  // 获取项目组织信息（项目组织下发通过后才有）
  getProjectOrganizations(params: IProjectOrganizationInfoParams): Observable<IOrganizationChartItem[]> {
    return this.http.get<IProjectOrganizationInfo>(`${this.apiUrl}/${params.project_code}/${params.audit_id}/org`).pipe(
      map(res => res.data.items),
      catchError(this.sharedService.handleError<IOrganizationChartItem[]>('get project organizations', []))
    );
  }

  // 追加项目组织缺少的必填组织s（已废弃）
  // -由MDM同步来的项目，组织缺少“采购工程经理”等，给予追加
  patchAppendRequiredOrganizationChartItemsSync(items: IOrganizationChartItem[]): IOrganizationChartItem[] {
    // const requiredItems = _.filter(
    //   this.organizationTemplateService.getDefaultOrganizationChartItemsSync(), { disable_delete: true }
    // );  // 必填组织
    // _.forEach(requiredItems, requiredItem => {
    //   items = this.patchAppendRequiredOrganizationChartItemSync(items, requiredItems, requiredItem);
    // });
    return items;
  }

  // 追加项目组织缺少的必填组织
  private patchAppendRequiredOrganizationChartItemSync(
    existItems: IOrganizationChartItem[], // 已经存在的组织
    requiredItems: IOrganizationChartItem[],  // 必填组织列表
    requiredItem: IOrganizationChartItem  // 要验证的必填组织
  ): IOrganizationChartItem[] { // 追加完必填组织之后的组织列表
    // 已经有该必填组织，不做处理
    if (-1 !== _.findIndex(existItems, { name: requiredItem.name })) {
      return existItems;
    }

    // 没有该必填组织，追加
    // 计算id
    const stringIds = _.without(_.map(existItems, 'id'), undefined);
    const numberIds = _.map(stringIds, _.parseInt);
    const maxId = _.max(numberIds);

    // 获取parent_id
    const parentRequiredItem = _.find(requiredItems, { id: requiredItem.parent_id });
    if (!parentRequiredItem) {
      return existItems;
    }
    const parentExistItem = _.find(existItems, { name: parentRequiredItem.name });
    if (!parentExistItem) {
      return existItems;
    }

    // 追加
    const appendRequiredItem = Object.assign({}, requiredItem, {
      parent_id: parentExistItem.id,
      id: maxId + 1 + ''
    });
    return _.concat(existItems, appendRequiredItem);
  }

  // 获取项目节点信息（项目节点下发通过后才有）
  getProjectMilestones(params: IProjectMilestoneParams): Observable<IMilestone[]> {
    return this.http.get<IMilestone[]>(`${this.apiUrl}/${params.code}/milestones`, { params: params as unknown as HttpParams }).pipe(
      map(milestones => {
        milestones = _.sortBy(milestones, 'rank');
        // 最后一个过阀的节点，之前的节点，都标记为已过阀
        const lastReadOnlyMilestone = _.findLast(milestones, { is_read_only: true });
        if (lastReadOnlyMilestone) {
          _.forEach(milestones, m => {
            m.is_read_only = m.rank <= lastReadOnlyMilestone.rank;
          });
        }
        // 延期的节点，计划完成时间取延期后的时间
        _.forEach(milestones, m => {
          if (m.is_delay) {
            m.target_end_time = m.delay_end_time;
          }
        });
        return milestones;
      }),
      catchError(this.sharedService.handleError<IMilestone[]>('get project milestones', []))
    );
  }

  // 获取项目重启节点列表
  getProjectRestartMilestones(projectCode: string): Observable<IProjectRestartMilestone[]> {
    return this.http.get<IProjectRestartMilestone[]>(`${this.apiUrl}/restart/${projectCode}/milestones`).pipe(
      map(milestones => _.sortBy(milestones, 'rank')),
      catchError(this.sharedService.handleError<IProjectRestartMilestone[]>('get project restart milestones', []))
    );
  }

  // 获取项目计划列表(带权限信息is_can_split、is_can_update)（项目计划下发通过后才有）
  getProjectPlansWithAuth(params: IProjectPlanParams): Observable<IPlan[]> {
    return this.http.get<IPlan[]>(`${this.apiUrl}/${params.code}/issued-plans`, { params: params as unknown as HttpParams }).pipe(
      map(plans => {
        _.forEach(plans, plan => {
          plan._is_issue_approved = true;
        });
        return plans;
      }),
      catchError(this.sharedService.handleError<IPlan[]>('get project issued plans', []))
    );
  }

  // 获取项目已下发计划列表（不带权限信息）（项目计划下发通过后才有）
  getProjectPlans(params: IProjectPlanParams): Observable<IPlan[]> {
    return this.http.get<IPlan[]>(`${this.apiUrl}/${params.code}/plans`, { params: params as unknown as HttpParams }).pipe(
      catchError(this.sharedService.handleError<IPlan[]>('get project plans', []))
    );
  }

  // 已下发项目列表
  // - 获取当前管理员收到的下发项目数据
  getIssuedProjects(params: IIssuedProjectParams): Observable<IProject[]> {
    return this.http.get<IProject[]>(`${this.apiUrl}/issued/${params.auditType}`).pipe(
      catchError(this.sharedService.handleError<IProject[]>('get issued projects', []))
    );
  }

  // 获取项目相关信息
  getProjectRelatedInfo(params: IProjectRelatedInfoParams): Observable<IProjectRelatedInfo> {
    return this.http.get<IProjectRelatedInfo>(`${this.apiUrl}/infos/${params.type}/${params.code}`, {
      params: params as unknown as HttpParams
    }).pipe(
      catchError(this.sharedService.handleError<IProjectRelatedInfo>(`get project related info:`, undefined))
    );
  }

  // 获取项目的组织角色人员列表（项目经理、项目管理经理、项目管理员）
  getProjectOrganizationRolePersonsSync(project: IProject, roleName: string): IUserInfo[] {
    if (!(_.get(project, 'project_org_tree.items') && project.project_org_tree.items.length)) {
      return [];
    }
    const orgTreeItem = _.find(project.project_org_tree.items, { name: roleName });
    if (!(orgTreeItem && orgTreeItem.persons && orgTreeItem.persons.length)) {
      return [];
    }
    return orgTreeItem.persons;
  }

  // 获取质量信息
  getQcts(projectCode: string): Observable<IQcts[]> {
    return this.http.get<IQcts[]>(`/api/business/projects/${projectCode}/qcts`).pipe(
      catchError(this.sharedService.handleError('get qcts', []))
    );
  }

  // 获取质量问题数
  getQctsCount(params: {
    code: string; // project code
    milestone: string;  // milestone code
  }): Observable<number> {
    return this.http.get<number>(`/api/business/projects/${params.code}/qcts-count`, { params: params as unknown as HttpParams }).pipe(
      catchError(this.sharedService.handleError('get qcts number', 0))
    );
  }

  // 获取预算信息
  getPfcs(params: IPfcsParams): Observable<IPfcs[]> {
    return this.http.get<IPfcs[]>(`/api/business/projects/${params.code}/pfcs`).pipe(
      catchError(this.sharedService.handleError('get pfcs', []))
    );
  }

  // 获取更多信息
  getProjectMdmInfo(projectCode: string): Observable<IProjectMdmInfo> {
    return this.http.get<IProjectMdmInfo>(`/api/business/projects/${projectCode}/mdm-info`).pipe(
      catchError(this.sharedService.handleError('get mdm-info', undefined))
    );
  }

  // 获取项目传阅者
  getProjectViewers(params: IProjectViewerParams): Observable<IProjectViewer[]> {
    // tslint:disable-next-line: variable-name
    const _params = {
      page: params.page,
      limit: params.limit
    } as unknown as HttpParams;
    return this.http.get<IProjectViewer[]>(`/api/business/projects/${params.code}/viewers`, { params: _params }).pipe(
      catchError(this.sharedService.handleError('get project viewers', []))
    );
  }

  getProjectViewersCount(params: IProjectViewerParams): Observable<number> {
    // tslint:disable-next-line: variable-name
    const _params = {
      page: params.page,
      limit: params.limit
    } as unknown as HttpParams;
    return this.http.get<number>(`/api/business/projects/${params.code}/viewers-count`, { params: _params }).pipe(
      catchError(this.sharedService.handleError('get project viewers count', 0))
    );
  }

  addProjectViewer(data: IProjectViewerAddCommand): Observable<IProjectViewerResponse> {
    return this.http.post<IProjectViewerResponse>(`/api/business/projects/${data.code}/viewers`, data).pipe(
      catchError(this.sharedService.handleError('add project viewer', { success: false, errors: [] }))
    );
  }

  removeProjectViewer(data: IProjectViewerRemoveCommand): Observable<IProjectViewerResponse> {
    return this.http.delete<IProjectViewerResponse>(`/api/business/projects/${data.code}/viewers/${data.user_id}`).pipe(
      catchError(this.sharedService.handleError('remove project viewer', { success: false, errors: [] }))
    );
  }

  // 获取授权查看组织
  getProjectOrgViewers(params: IProjectOrgViewerParams): Observable<IProjectOrgViewer[]> {
    // tslint:disable-next-line: variable-name
    const _params = {
      page: params.page,
      limit: params.limit
    };
    return this.http.get<IProjectOrgViewer[]>(`/api/business/projects/${params.code}/org/viewers`,
      { params: _params as unknown as HttpParams }).pipe(
        catchError(this.sharedService.handleError('get project org views', []))
      );
  }

  getProjectOrgViewersCount(params: IProjectOrgViewerParams): Observable<number> {
    // tslint:disable-next-line: variable-name
    const _params = {
      page: params.page,
      limit: params.limit
    };
    return this.http.get<number>(`api/business/projects/${params.code}/org/viewers-count`,
      { params: _params as unknown as HttpParams }).pipe(
        catchError(this.sharedService.handleError('get project org views count', 0))
      );
  }

  addProjectOrgViewer(command: IProjectOrgViewerAddCommand): Observable<IProjectViewerResponse> {
    return this.http.post<IProjectViewerResponse>(`api/business/projects/${command.code}/org/viewers`, command).pipe(
      catchError(this.sharedService.handleError('add project org views', { success: false, errors: [] }))
    );
  }

  removeProjectOrgViewer(command: IProjectOrgViewerRemoveCommand): Observable<IProjectViewerResponse> {
    return this.http.delete<IProjectViewerResponse>(`/api/business/projects/${command.code}/org/viewers/${command.org_number}`).pipe(
      catchError(this.sharedService.handleError('delete project org viewers', { success: false, errors: [] }))
    );
  }

  getModuleMaintainProjects(params: IProjectParams): Observable<IProject[]> {
    return this.http.get<IProject[]>(`/api/business/module-maintain/projects`, { params: params as unknown as HttpParams }).pipe(
      catchError(this.sharedService.handleError('get projects', []))
    );
  }

  verifySystemModulesValidSync(systemModules: ISystemModule[]): boolean {
    return this.verifySystemModulesEmptySync(systemModules)
      && this.verifySystemModulesDuplicateSync(systemModules);
  }

  // 系统、模块团队、一级模块必填
  verifySystemModulesEmptySync(systemModules: ISystemModule[]): boolean {
    let systemModulesEmptyFlag = true;
    if (!systemModules.length) {
      return systemModulesEmptyFlag;
    }
    _.forEach(systemModules, systemModule => {
      if (!systemModule.system_code
        || !systemModule.module_team_code
        || !systemModule.primary_module_code
      ) {
        const alert: IAlert = {
          type: 'danger',
          title: `系统、模块团队、一级模块均不能为空`
        };
        this.subscriptionService.publishAlert(alert);
        systemModulesEmptyFlag = false;
      }
    });
    return systemModulesEmptyFlag;
  }

  // 系统、模块团队、一级模块三者确定唯一一组系统模块
  verifySystemModulesDuplicateSync(systemModules: ISystemModule[]): boolean {
    const systemModuleStrArray = [];
    _.forEach(systemModules, systemModule => {
      const systemModuleStr = `${systemModule.system_code}-${systemModule.module_team_code}-${systemModule.primary_module_code}`;
      systemModuleStrArray.push(systemModuleStr);
    });
    const afterUniqSystemModuleStrArray = _.uniq(systemModuleStrArray);
    if (afterUniqSystemModuleStrArray.length !== systemModuleStrArray.length) {
      const alert: IAlert = {
        type: 'danger',
        title: `系统、模块团队、一级模块不能完全相同`
      };
      this.subscriptionService.publishAlert(alert);
      return false;
    }
    return true;
  }

  upload(excelFile: File, auditId: string): Observable<IResponse> {
    const myFormData: FormData = new FormData();
    const params = new HttpParams({
      fromObject: {
        audit_id: auditId,
      }
    });
    myFormData.append('file', excelFile, excelFile.name);
    const config = new HttpRequest(
      'POST',
      '/api/reporting/system-module/template/import',
      myFormData,
      {
        reportProgress: true,
        params
      }
    );
    return this.http.request(config).pipe(
      filter(event => event instanceof HttpResponse),
      map(event => {
        if (event instanceof HttpResponse) {
          return event.body as IResponse;
        }
      }),
      catchError(this.sharedService.handleError('upload template', { success: false, errors: [] }))
    );
  }

  // 检查用户是否有权限查看零部件成熟度
  getCurrentHasPartAuth(projectCode: string): Observable<boolean> {
    return this.http.get<boolean>
      (`/api/configs/spare-parts/viewer-permission/${projectCode}/check`).pipe(
        catchError(this.sharedService.handleError('get is part auth project roles', false))
      );
  }

  // 查看项目组织变更历史列表
  getProjectOrgUpdateHistoriesList(params: IOrganizationUpdateHistoryParams): Observable<IOrganizationUpdateHistoryItem[]> {
    // tslint:disable-next-line:max-line-length
    return this.http.get<IOrganizationUpdateHistoryItem[]>(`/api/business/history/project-org-audit`, { params: params as unknown as HttpParams }).pipe(
      catchError(this.sharedService.handleError('get project org histories list', []))
    );
  }

  getProjectOrgUpdateHistoriesListCount(params): Observable<number> {
    return this.http.get<number>(`/api/business/history/project-org-audit-count`, { params: params as unknown as HttpParams }).pipe(
      catchError(this.sharedService.handleError('get project org histories list count', 0))
    );
  }

  checkEpOrg(projectCode: string): Observable<{ ep: Date }> {
    return this.http.get<{ ep: Date }>(`api/business/projects/${projectCode}/check-ep-org-sync`).pipe(
      catchError(this.sharedService.handleError('check ep org', { ep: undefined }))
    );
  }

  // 获取项目模块类别
  getSystemModuleTypes(): Observable<ISystemModuleType[]> {
    return this.http.get<ISystemModuleType[]>(`/api/worktime-data/system-module/types`).pipe(
      catchError(this.sharedService.handleError('get system module types', []))
    );
  }

  // 获取Tc项目模块类别
  getTcSystemModuleTypes(module_type: EModuleType): Observable<ITcSystemModuleType[]> {
    const params = { module_type };
    return this.http.get<ITcSystemModuleType[]>(`/api/worktime-data/tc-system-module`, { params: params as unknown as HttpParams }).pipe(
      catchError(this.sharedService.handleError('get tc system module types', []))
    );
  }
  // #region 工时预算模块

  // 工时预算权限(* 返回值为0时无权限，返回值为1时有权限 *)
  getWorktimeForecastPermission(): Observable<number> {
    return this.http.get<number>(`/api/audits/worktime-forecast/permission`).pipe(
      catchError(this.sharedService.handleError('get worktime-forecast permission', 0))
    );
  }

  // 获取项目列表
  getWorktimeForecastProjectsList(params: IListCommonParams): Observable<IWorktimeForecastProject[]> {
    return this.http.get<IWorktimeForecastProject[]>(`/api/audits/worktime-forecast/projects`, { params: params as unknown as HttpParams }).pipe(
      catchError(this.sharedService.handleError('get worktime-forecast projects', []))
    );
  }

  // 获取项目列表count
  getWorktimeForecastProjectsListCount(params: IListCommonParams): Observable<number> {
    return this.http.get<number>(`/api/audits/worktime-forecast/projects-count`, { params: params as unknown as HttpParams }).pipe(
      catchError(this.sharedService.handleError('get worktime-forecast projects count', 0))
    );
  }

  worktimeForecastUpload(excelFile: File, auditId: string): Observable<IWorktimeForecastExportResponse> {
    const myFormData: FormData = new FormData();
    const params = new HttpParams({
      fromObject: {
        audit_id: auditId,
      }
    });
    myFormData.append('file', excelFile, excelFile.name);
    const config = new HttpRequest(
      'POST',
      '/api/business/excel/worktime-forecast/import',
      myFormData,
      {
        reportProgress: true,
        params
      }
    );
    return this.http.request(config).pipe(
      filter(event => event instanceof HttpResponse),
      map(event => {
        if (event instanceof HttpResponse) {
          return event.body as IWorktimeForecastExportResponse;
        }
      }),
      catchError(this.sharedService.handleError('upload worktime-forecast', { data: {}, success: false, errors: [] }))
    );
  }

  // #endregion


  // MDM 项目推送
  createOrUpdateProject(projectInfo: IProjectMdmInfoCommand): Observable<any> {
    const command = {
      HEAD: {
        TYPE: 'STEPProjectMasterData',
        COUNT: '',
        ACCOUNT: 'NA',
        CONSUMER: 'MDM',
        PASSWORD: 'NA',
        SRVLEVEL: '',
        BIZTRANSACTIONID: 'STEP_FT_PI_Outbound'
      },
      LIST: [projectInfo]
    }
    return this.http.post<any>(`api/esb/mdm`, command).pipe(
      catchError(this.sharedService.handleError('create or updateproject', 0))
    );
  }

  transform2MdmProperty(projectInfo: IProjectMdmInfo): IProjectMdmInfoCommand {
    return {
      PLFAZ: projectInfo.plfaz,
      PLSEZ: projectInfo.plsez,
      PROFL: projectInfo.profl,
      Eng_NG: projectInfo.eng_ng,
      ZF0182: projectInfo.zf0182,
      // Eng_F.D: 
      PROFLID: projectInfo.profl_id,
      Eng_NGID: projectInfo.eng_ng_id,
      Eng_Name: projectInfo.eng_name,
      Eng_Type: projectInfo.eng_type,
      ZF0182ID: projectInfo.zf0182_id,
      Eng_BType: projectInfo.eng_factory_id,
      Eng_Brand: projectInfo.eng_brand,
      Eng_Level: projectInfo.eng_level,
      Eng_State: projectInfo.eng_state,
      Eng_Leader: projectInfo.eng_leader,
      Eng_Manage: projectInfo.eng_manage,
      Eng_Number: projectInfo.eng_number,
      Eng_Resume: projectInfo.eng_resume,
      Eng_TypeID: projectInfo.eng_type_id,
      Eng_BrandID: projectInfo.eng_brand_id,
      Eng_BussAtt: projectInfo.eng_buss_att,
      Eng_Factory: projectInfo.eng_factory,
      Eng_GetYear: projectInfo.eng_get_year,
      Eng_LevelID: projectInfo.eng_level_id,
      Eng_NewTech: projectInfo.eng_new_tech,
      Eng_Purpose: projectInfo.eng_purpose,
      Eng_StateID: projectInfo.eng_state_id,
      Eng_SubType: projectInfo.eng_sub_type,
      Eng_Business: projectInfo.eng_business,
      Eng_Category: projectInfo.eng_category,
      Eng_HighTech: projectInfo.eng_high_tech,
      Eng_LeaderID: projectInfo.eng_leader_id,
      Eng_ManageID: projectInfo.eng_manage_id,
      Eng_Platform: projectInfo.eng_platform,
      // CreatedBy-New: 
      Eng_BussAttID: projectInfo.eng_buss_att_id,
      Eng_CloseTime: projectInfo.eng_close_time,
      Eng_Emissions: projectInfo.eng_emissions,
      // Eng_ExtSystem: 
      Eng_FactoryID: projectInfo.eng_factory_id,
      Eng_GetYearID: projectInfo.eng_get_year_id,
      Eng_NewEnergy: projectInfo.eng_new_energy,
      Eng_NewTechID: projectInfo.eng_new_tech_id,
      Eng_SubTypeID: projectInfo.eng_sub_type_id,
      Eng_ApplyScene: projectInfo.eng_apply_scene,
      Eng_BusinessID: projectInfo.eng_business_id,
      Eng_CategoryID: projectInfo.eng_category_id,
      Eng_DemandDate: projectInfo.eng_demand_date,
      Eng_HighTechID: projectInfo.eng_high_tech_id,
      Eng_PlanProNum: projectInfo.eng_plan_pro_num,
      Eng_PlatformID: projectInfo.eng_platform_id,
      Eng_BasicProNum: projectInfo.eng_basic_pro_num,
      Eng_CarCategory: projectInfo.eng_car_category,
      Eng_EmissionsID: projectInfo.eng_emissions_id,
      Eng_LightWeight: projectInfo.eng_light_weight,
      Eng_ModuleClass: projectInfo.eng_module_class,
      Eng_NewEnergyID: projectInfo.eng_new_energy_id,
      Eng_NewTechLine: projectInfo.eng_new_tech_line,
      Eng_ProductLine: projectInfo.eng_product_line,
      Eng_BasicDevelop: projectInfo.eng_basic_develop,
      Eng_CreatCompany: projectInfo.eng_create_company,
      // Eng_GroupControl: projectInfo.eng_group_control,
      Eng_HighTechArea: projectInfo.eng_high_tech_area,
      Eng_ModuleSystem: projectInfo.eng_module_system,
      Eng_NewTechClass: projectInfo.eng_new_tech_class,
      Eng_CarCategoryID: projectInfo.eng_car_category_id,
      Eng_DemandSubject: projectInfo.eng_demand_subject,
      Eng_LightWeightID: projectInfo.eng_light_weight_id,
      // Eng_ModularManage: 
      Eng_ModuleClassID: projectInfo.eng_module_class_id,
      Eng_NewTechLineID: projectInfo.eng_new_tech_line_id,
      Eng_NewTechSource: projectInfo.eng_new_tech_source,
      Eng_PlatformClass: projectInfo.eng_platform_class,
      Eng_ProductLineID: projectInfo.eng_product_line_id,
      Eng_BasicDevelopID: projectInfo.eng_basic_develop_id,
      Eng_CreatCompanyID: projectInfo.eng_create_company_id,
      Eng_DevelopSubject: projectInfo.eng_develop_subject,
      // Eng_GroupControlID: 
      Eng_HighTechAreaID: projectInfo.eng_high_tech_area_id,
      // Eng_ModuleProClass:
      Eng_ModuleSystemID: projectInfo.eng_module_system_id,
      Eng_NewTechClassID: projectInfo.eng_new_tech_class_id,
      Eng_CurrencySpecial: projectInfo.eng_currency_special,
      Eng_DemandSubjectID: projectInfo.eng_demand_subject_id,
      // Eng_ModularManageID: ,
      Eng_NewTechSourceID: projectInfo.eng_new_tech_source_id,
      Eng_PlatformClassID: projectInfo.eng_platform_class_id,
      Eng_Countersignature: projectInfo.eng_countersignature,
      Eng_DevelopSubjectID: projectInfo.eng_develop_subject_id,
      // Eng_ModuleProClassID: 
      Eng_NewTechDirection: projectInfo.eng_new_tech_direction,
      Eng_CurrencySpecialID: projectInfo.eng_currency_special_id,
      Eng_NewTechDirectionID: projectInfo.eng_new_tech_direction_id,
      "CreatedBy-New": projectInfo.created_by_new
    }

  }


}
