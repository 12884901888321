import _ from 'lodash';

import { Reducer, Action } from 'redux';
import {
  SHOW_DELIVERABLE,
  SET_DELIVERABLE_DATA,
  CANCEL_DELIVERABLE,
  ShowDeliverableAction,
  SetDeliverableDataAction,
  CancelDeliverableAction
} from '../actions/deliverable.actions';
import { createSelector } from 'reselect';

export interface DeliverableState {
  isShow: boolean;
  data: JSON;
}

const initialState: DeliverableState = {
  isShow: false,
  data: undefined
};

export const DeliverableReducer: Reducer<DeliverableState> =
  (state: DeliverableState = initialState, action: Action): DeliverableState => {
    let data;
    switch (action.type) {
      case SHOW_DELIVERABLE:
        data = (action as ShowDeliverableAction).data;
        return Object.assign({}, state, {
          isShow: true,
          data
        });

      case SET_DELIVERABLE_DATA:
        data = (action as SetDeliverableDataAction).data;
        return Object.assign({}, state, {
          data
        });

      case CANCEL_DELIVERABLE:
        return Object.assign({}, state, {
          isShow: false,
          data: []
        });

      default:
        return state;
    }
  };

const getState = (state: DeliverableState): DeliverableState => state;
export const getDeliverableShow = createSelector(
  getState,
  (state: DeliverableState) => state.isShow
);

export const getDeliverableData = createSelector(
  getState,
  (state: DeliverableState) => state.data
);
