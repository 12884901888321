// 信息维护的访问
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { SharedService } from '../services/shared.service';
import { Observable } from 'rxjs';
import { ProjectService } from '../services/project.service';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class WorkCenterProjectGuard implements CanActivate {
    constructor(
        private router: Router,
        private userService: UserService,
        private sharedService: SharedService,
        private projectService: ProjectService
    ) { }

    canActivate(
      next: ActivatedRouteSnapshot, 
      state: RouterStateSnapshot
      ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        // 系统管理员 || 项目群管理员 || 项目管理员 || 产品工程经理 || 管理经理 || 有权限进行【工时预算维护】
        return this.projectService.getWorktimeForecastPermission().pipe(
          map(hasPermission => {
            if (this.userService.getCurrentIsSystemAdminSync()
            || this.userService.getCurrentIsProjectGroupAdminSync()
            || this.userService.getCurrentIsProjectAdminSync()
            || this.userService.getCurrentIsProductEngineeringAdminSync()
            || this.userService.getCurrentIsProjectManagementAdminSync()
            || hasPermission) {
              return true;
            } else {
              this.sharedService.setRedirectUrl(window.location.href);
              this.router.navigate(['/work-center']);
              return false;
            }
          })
        );
    }
}
