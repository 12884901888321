// 当天看板计划状态
export enum ECurrentDayKanbanType {
    /// <summary>
    /// 超期
    /// </summary>
    Overdue = 10,

    /// <summary>
    /// 延期
    /// </summary>
    Delay = 20,

    /// <summary>
    /// 到期
    /// </summary>
    Maturity = 30,
}
