<ng-template class="modal" #digitalDeliverable>
  <form [formGroup]="myForm" class="ez-page">
    <div class="ez-page-header">
      <div class="page-title">
        数据化交付物
      </div>
      <div class="page-operations">
        <button type="button" class="btn btn-outline-secondary" (click)="hide()">
          <i class="fa fa-chevron-left mr-1"></i>返回
        </button>
      </div>
    </div>

    <div class="ez-page-body">
      <app-collapse name="概念销售目标" defaultExpand>
        <table formGroupName="concept_sales_target"
          class="table-sm table-bordered table-fixed align-middle text-center">
          <tbody>
            <tr>
              <td *ngIf="editEnable" class="text-left">
                <i class="fa fa-asterisk text-danger mr-1"></i>项目生命周期
              </td>
              <td *ngIf="!editEnable" class="text-center">项目生命周期
              </td>
              <td>
                <input *ngIf="editEnable" class="form-control" (keyup)="isNum($event)" (afterparse)="isNum($event)"
                  style="width:73%; display:inline-block; margin-right: 9%;" formControlName="lifetime_years">
                <span *ngIf="!editEnable">{{concept_sales_target?.lifetime_years}}</span>年
              </td>
              <td class="text-center">
                <i *ngIf="editEnable" class="fa fa-asterisk text-danger mr-1"></i>概念报告编号</td>
              <td>
                <input *ngIf="editEnable" type="text" class="form-control" formControlName="concept_report_code">
                <span *ngIf="!editEnable">{{concept_sales_target?.concept_report_code}}</span>
              </td>
              <td><i *ngIf="editEnable" class="fa fa-asterisk text-danger mr-1"></i>概念报告名称</td>
              <td>
                <input *ngIf="editEnable" type="text" class="form-control" formControlName="concept_report_name">
                <span *ngIf="!editEnable">{{concept_sales_target?.concept_report_name}}</span>
              </td>
              <td><i *ngIf="editEnable" class="fa fa-asterisk text-danger mr-1"></i>立项报告编号</td>
              <td>
                <input *ngIf="editEnable" type="text" class="form-control" formControlName="approved_report_code">
                <span *ngIf="!editEnable">{{concept_sales_target?.approved_report_code}}</span>

              </td>
              <td><i *ngIf="editEnable" class="fa fa-asterisk text-danger mr-1"></i>立项报告名称</td>
              <td colspan="4">
                <input *ngIf="editEnable" type="text" class="form-control" formControlName="approved_report_name">
                <span *ngIf="!editEnable">{{concept_sales_target?.approved_report_name}}</span>
              </td>
            </tr>
            <tr>
              <td *ngIf="editEnable" class="text-left" style="padding-left: 18px;">SOP</td>
              <td *ngIf="!editEnable" class="text-center">SOP</td>
              <td>SOP+1月</td>
              <td>SOP+2月</td>
              <td>SOP+3月</td>
              <td>SOP+4月</td>
              <td>SOP+5月</td>
              <td>SOP+6月</td>
              <td>SOP+7月</td>
              <td>SOP+8月</td>
              <td>SOP+9月</td>
              <td>SOP+10月</td>
              <td>SOP+11月</td>
              <td>SOP+12月</td>
            </tr>
            <tr formArrayName="sops" *ngFor="let item of sops.controls; let i =index;">
              <td *ngIf="editEnable" height="30" class="text-left"><i
                  class="fa fa-asterisk text-danger mr-1"></i>第{{i+1}}年（整数）</td>
              <td *ngIf="!editEnable" height="30" class="text-center">第{{i+1}}年</td>
              <td [formGroupName]="i">
                <input *ngIf="editEnable" type="text" (keyup)="Number($event, 'SOP')"
                  (afterparse)="Number($event, 'SOP')" class="form-control" formControlName="sop1">
                <span *ngIf="!editEnable">{{concept_sales_target?.sops[i].sop1}}</span>
              </td>
              <td [formGroupName]="i">
                <input *ngIf="editEnable" type="text" (keyup)="Number($event, 'SOP')"
                  (afterparse)="Number($event, 'SOP')" class="form-control" formControlName="sop2">
                <span *ngIf="!editEnable">{{concept_sales_target?.sops[i].sop2}}</span>
              </td>
              <td [formGroupName]="i">
                <input *ngIf="editEnable" type="text" (keyup)="Number($event, 'SOP')"
                  (afterparse)="Number($event, 'SOP')" class="form-control" formControlName="sop3">
                <span *ngIf="!editEnable">{{concept_sales_target?.sops[i].sop3}}</span>
              </td>
              <td [formGroupName]="i">
                <input *ngIf="editEnable" type="text" (keyup)="Number($event, 'SOP')"
                  (afterparse)="Number($event, 'SOP')" class="form-control" formControlName="sop4">
                <span *ngIf="!editEnable">{{concept_sales_target?.sops[i].sop4}}</span>
              </td>
              <td [formGroupName]="i">
                <input *ngIf="editEnable" type="text" (keyup)="Number($event, 'SOP')"
                  (afterparse)="Number($event, 'SOP')" class="form-control" formControlName="sop5">
                <span *ngIf="!editEnable">{{concept_sales_target?.sops[i].sop5}}</span>
              </td>
              <td [formGroupName]="i">
                <input *ngIf="editEnable" type="text" (keyup)="Number($event, 'SOP')"
                  (afterparse)="Number($event, 'SOP')" class="form-control" formControlName="sop6">
                <span *ngIf="!editEnable">{{concept_sales_target?.sops[i].sop6}}</span>
              </td>
              <td [formGroupName]="i">
                <input *ngIf="editEnable" type="text" (keyup)="Number($event, 'SOP')"
                  (afterparse)="Number($event, 'SOP')" class="form-control" formControlName="sop7">
                <span *ngIf="!editEnable">{{concept_sales_target?.sops[i].sop7}}</span>
              </td>
              <td [formGroupName]="i">
                <input *ngIf="editEnable" type="text" (keyup)="Number($event, 'SOP')"
                  (afterparse)="Number($event, 'SOP')" class="form-control" formControlName="sop8">
                <span *ngIf="!editEnable">{{concept_sales_target?.sops[i].sop8}}</span>
              </td>
              <td [formGroupName]="i">
                <input *ngIf="editEnable" type="text" (keyup)="Number($event, 'SOP')"
                  (afterparse)="Number($event, 'SOP')" class="form-control" formControlName="sop9">
                <span *ngIf="!editEnable">{{concept_sales_target?.sops[i].sop9}}</span>
              </td>
              <td [formGroupName]="i">
                <input *ngIf="editEnable" type="text" (keyup)="Number($event, 'SOP')"
                  (afterparse)="Number($event, 'SOP')" class="form-control" formControlName="sop10">
                <span *ngIf="!editEnable">{{concept_sales_target?.sops[i].sop10}}</span>
              </td>
              <td [formGroupName]="i">
                <input *ngIf="editEnable" type="text" (keyup)="Number($event, 'SOP')"
                  (afterparse)="Number($event, 'SOP')" class="form-control" formControlName="sop11">
                <span *ngIf="!editEnable">{{concept_sales_target?.sops[i].sop11}}</span>
              </td>
              <td [formGroupName]="i">
                <input *ngIf="editEnable" type="text" (keyup)="Number($event, 'SOP')"
                  (afterparse)="Number($event, 'SOP')" class="form-control" formControlName="sop12">
                <span *ngIf="!editEnable">{{concept_sales_target?.sops[i].sop12}}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </app-collapse>

      <app-collapse name="财务指标" defaultExpand>
        <table formGroupName="others" class="table-sm table-bordered table-fixed align-middle text-center">
          <tbody>
            <tr>
              <td *ngIf="editEnable" style="width: 180px" rowspan="2" class="text-left"><i
                  class="fa fa-asterisk text-danger mr-1"></i>产品附加值率（整数）</td>
              <td *ngIf="!editEnable" style="width: 180px" rowspan="2" class="text-center">产品附加值率</td>
              <td *ngFor="let milestone of milestones" class="text-center">{{milestone.code}}</td>
            </tr>
            <tr formGroupName="product_additional_value_rates">
              <td style="height:30px;" *ngFor="let milestone of lowerCaseMilestones">
                <input *ngIf="editEnable" type="text" (keyup)="Number($event, '产品附加值率')"
                  (afterparse)="Number($event, '产品附加值率')" class="form-control"
                  [formControl]="productAdditionalValueRatesForm.controls[milestone.code]">
                <span *ngIf="!editEnable">{{productAdditionalValueRatesForm.controls[milestone.code].value}}</span>
              </td>
            </tr>
            <tr>
              <td *ngIf="editEnable" rowspan="2" class="text-left"><i
                  class="fa fa-asterisk text-danger mr-1"></i>整车成本目标（整数）</td>
              <td *ngIf="!editEnable" rowspan="2" class="text-center">整车成本目标</td>
              <td *ngFor="let milestone of milestones" class="text-center">{{milestone.code}}</td>
            </tr>
            <tr formGroupName="vehicle_cost_targets">
              <td *ngFor="let milestone of lowerCaseMilestones" style="height:30px;">
                <input *ngIf="editEnable" type="text" (keyup)="Number($event, '整车成本目标')"
                  (afterparse)="Number($event, '整车成本目标')" class="form-control" formControlName="{{milestone.code}}">
                <span *ngIf="!editEnable">{{vehicleCostTargetsForm.controls[milestone.code].value}}</span>
              </td>
            </tr>
            <tr>
              <td *ngIf="editEnable" rowspan="2" class="text-left"><i
                  class="fa fa-asterisk text-danger mr-1"></i>项目预算（整数）</td>
              <td *ngIf="!editEnable" rowspan="2" class="text-center">项目预算</td>
              <td *ngFor="let milestone of milestones" class="text-center">{{milestone.code}}</td>
            </tr>
            <tr formGroupName="project_budgets">
              <td *ngFor="let milestone of lowerCaseMilestones" style="height:30px;">
                <input *ngIf="editEnable" type="text" (keyup)="Number($event, '项目预算')"
                  (afterparse)="Number($event, '项目预算')" class="form-control" formControlName="{{milestone.code}}">
                <span *ngIf="!editEnable">{{projectBudgetsForm.controls[milestone.code].value}}</span>
              </td>
            </tr>

          </tbody>
        </table>
      </app-collapse>

      <app-collapse name="质量指标" defaultExpand>
        <table formGroupName="quality_target" class="table-sm table-bordered table-fixed align-middle text-center">
          <tbody>
            <tr>
              <td *ngIf="editEnable" rowspan="2" class="title text-left" style="width: 180px"><i
                  class="fa fa-asterisk text-danger mr-1"></i>专用零部件入厂合格率（整数）</td>
              <td *ngIf="!editEnable" rowspan="2" class="title text-center">专用零部件入厂合格率</td>
              <td>试验样车-B目标</td>
              <td>试验样车-C目标</td>
              <td>生产启动-PPV目标</td>
              <td>生产启动-PP目标</td>
              <td>生产启动-P/SOP目标</td>
            </tr>
            <tr formGroupName="special_parts_pass_rates">
              <td style="height: 30px;">
                <input type="text" *ngIf="editEnable" class="form-control" (keyup)="Number($event, '专用零部件入厂合格率')"
                  (afterparse)="Number($event, '专用零部件入厂合格率')" formControlName="experimental_vehicle_B" trim="blur">
                <span *ngIf="!editEnable">{{quality_target?.special_parts_pass_rates.experimental_vehicle_B}}</span>
              </td>
              <td>
                <input type="text" *ngIf="editEnable" class="form-control" (keyup)="Number($event, '专用零部件入厂合格率')"
                  (afterparse)="Number($event, '专用零部件入厂合格率')" formControlName="experimental_vehicle_C" trim="blur">
                <span *ngIf="!editEnable">{{quality_target?.special_parts_pass_rates.experimental_vehicle_C}}</span>
              </td>
              <td>
                <input type="text" *ngIf="editEnable" class="form-control" (keyup)="Number($event, '专用零部件入厂合格率')"
                  (afterparse)="Number($event, '专用零部件入厂合格率')" formControlName="product_start_PPV" trim="blur">
                <span *ngIf="!editEnable">{{quality_target?.special_parts_pass_rates.product_start_PPV}}</span>
              </td>
              <td>
                <input type="text" *ngIf="editEnable" class="form-control" (keyup)="Number($event, '专用零部件入厂合格率')"
                  (afterparse)="Number($event, '专用零部件入厂合格率')" formControlName="product_start_PP" trim="blur">
                <span *ngIf="!editEnable">{{quality_target?.special_parts_pass_rates.product_start_PP}}</span>
              </td>
              <td>
                <input type="text" *ngIf="editEnable" class="form-control" (keyup)="Number($event, '专用零部件入厂合格率')"
                  (afterparse)="Number($event, '专用零部件入厂合格率')" formControlName="product_start_SOP" trim="blur">
                <span *ngIf="!editEnable">{{quality_target?.special_parts_pass_rates.product_start_SOP}}</span>
              </td>
            </tr>
            <tr>
              <td *ngIf="editEnable" rowspan="2" class="title text-left"><i
                  class="fa fa-asterisk text-danger mr-1"></i>AUDIT扣分值（整数）</td>
              <td *ngIf="!editEnable" rowspan="2" class="title text-center">AUDIT扣分值</td>
              <td>实验样车-B目标</td>
              <td>实验样车-C目标</td>
              <td>生产启动-PPV目标</td>
              <td>生产启动-PP目标</td>
              <td>生产启动-P/SOP目标</td>
            </tr>
            <tr formGroupName="audit_deduction_items">
              <td style="height: 30px;">
                <input type="text" *ngIf="editEnable" class="form-control" (keyup)="Number($event, 'audit扣分值')"
                  (afterparse)="Number($event, 'audit扣分值')" formControlName="experimental_vehicle_B" trim="blur">
                <span *ngIf="!editEnable">{{quality_target?.audit_deduction_items.experimental_vehicle_B}}</span>
              </td>
              <td>
                <input type="text" *ngIf="editEnable" class="form-control" (keyup)="Number($event, 'audit扣分值')"
                  (afterparse)="Number($event, 'audit扣分值')" formControlName="experimental_vehicle_C" trim="blur">
                <span *ngIf="!editEnable">{{quality_target?.audit_deduction_items.experimental_vehicle_C}}</span>
              </td>
              <td>
                <input type="text" *ngIf="editEnable" class="form-control" (keyup)="Number($event, 'audit扣分值')"
                  (afterparse)="Number($event, 'audit扣分值')" formControlName="product_start_PPV" trim="blur">
                <span *ngIf="!editEnable">{{quality_target?.audit_deduction_items.product_start_PPV}}</span>
              </td>
              <td>
                <input type="text" *ngIf="editEnable" class="form-control" (keyup)="Number($event, 'audit扣分值')"
                  (afterparse)="Number($event, 'audit扣分值')" formControlName="product_start_PP" trim="blur">
                <span *ngIf="!editEnable">{{quality_target?.audit_deduction_items.product_start_PP}}</span>
              </td>
              <td>
                <input type="text" *ngIf="editEnable" class="form-control" (keyup)="Number($event, 'audit扣分值')"
                  (afterparse)="Number($event, 'audit扣分值')" formControlName="product_start_SOP" trim="blur">
                <span *ngIf="!editEnable">{{quality_target?.audit_deduction_items.product_start_SOP}}</span>
              </td>
            </tr>
            <tr formGroupName="vehicle_iptv">
              <td *ngIf="editEnable" class="title text-left"><i class="fa fa-asterisk text-danger mr-1"></i>整车IPTV（整数）
              </td>
              <td *ngIf="!editEnable" class="title text-center">整车IPTV</td>
              <td>零部件目标</td>
              <td colspan="4">
                <input type="text" *ngIf="editEnable" class="form-control" (keyup)="Number($event, '零部件目标')"
                  (afterparse)="Number($event, '零部件目标')" formControlName="parts_target" trim="blur">
                <span *ngIf="!editEnable">{{quality_target?.vehicle_iptv?.parts_target}}</span>
              </td>
            </tr>
            <tr formGroupName="ppap_complete_rate">
              <td *ngIf="editEnable" rowspan="2" class="title text-left"><i
                  class="fa fa-asterisk text-danger mr-1"></i>PPAP完成率（整数）</td>
              <td *ngIf="!editEnable" rowspan="2" class="title text-center">PPAP完成率</td>
              <td>G3目标</td>
              <td colspan="4">
                <input type="text" *ngIf="editEnable" class="form-control" (keyup)="Number($event, 'G3目标')"
                  (afterparse)="Number($event, 'G3目标')" formControlName="g3_target" trim="blur">
                <span *ngIf="!editEnable">{{quality_target?.ppap_complete_rate?.g3_target}}</span>
              </td>
            </tr>
            <tr formGroupName="ppap_complete_rate">
              <td>G2/SOP目标</td>
              <td colspan="4">
                <input type="text" *ngIf="editEnable" class="form-control" (keyup)="Number($event, 'G2/SOP目标')"
                  (afterparse)="Number($event, 'G2/SOP目标')" formControlName="g2_sop_target" trim="blur">
                <span *ngIf="!editEnable">{{quality_target?.ppap_complete_rate?.g2_sop_target}}</span>
              </td>
            </tr>
            <tr>
              <td *ngIf="editEnable" class="title text-left"><i class="fa fa-asterisk text-danger mr-1"></i>主观评价</td>
              <td *ngIf="!editEnable" class="title text-center">主观评价</td>
              <td colspan="5" class="text-center">
                <input type="text" *ngIf="editEnable" class="form-control" formControlName="subject_assessment"
                  trim="blur">
                <span *ngIf="!editEnable">{{quality_target?.subject_assessment}}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </app-collapse>
    </div>

    <div class="ez-page-footer">
      <button *ngIf="editEnable" type="button" class="btn btn-primary mr-2" (click)="save()">
        <i class="fa fa-save mr-1"></i>暂存
      </button>
      <button *ngIf="editEnable" type="button" class="btn btn-primary mr-2" (click)="confirm()"
        [disabled]="!myForm.valid">
        <i class="fa fa-paper-plane mr-1"></i>提交
      </button>
      <button type="button" class="btn btn-outline-secondary" (click)="hide()">
        <i class="fa fa-chevron-left mr-1"></i>返回
      </button>
    </div>
  </form>
</ng-template>
