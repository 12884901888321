import {
  Component,
  TemplateRef,
  ViewChild,
  Inject
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { CRadioBase } from 'src/app/classes';
import { IProject, IProjectParams, IPagination, IIssuedProjectParams } from 'src/app/models';
import { PAGE_SIZE, PAGE_LINK_LIMIT } from 'src/app/consts';
import { ProjectService } from 'src/app/services/project.service';
import { ReduxService } from 'src/app/redux/redux.service';
import { SharedService } from 'src/app/services/shared.service';
import { EAuditType } from 'src/app/enums';
import _ from 'lodash';
@Component({
  selector: 'app-check-project',
  templateUrl: './check-project.component.html',
  styleUrls: ['./check-project.component.scss']
})
export class CheckProjectComponent extends CRadioBase<IProject, IIssuedProjectParams> {
  modalRef: BsModalRef;
  @ViewChild('checkProject') modalEle: TemplateRef<any>;
  isLately: boolean;
  tooltip: string;
  confirmMessage: string;
  isFirst: boolean;
  auditType: EAuditType;
  defaultItems: IProject[];

  filterForm: FormGroup;

  // page = 1;
  // limit = PAGE_SIZE;
  // pageLinkLimit = PAGE_LINK_LIMIT;
  // count = 0;

  constructor(
    private modalService: BsModalService,
    private reduxService: ReduxService,
    private sharedService: SharedService,
    @Inject(ProjectService) private projectService: ProjectService
  ) {
    super(projectService, 'getIssuedProjects', 'code');
    this.reduxService.subscribeStore(() => this.readState());
    this.readState();
    this.filterForm = this.fb.group({
      q: ['']
    });

  }

  readState() {
    const isShow = this.reduxService.getCheckProjectsShow();
    const isMultiple = this.reduxService.getCheckProjectsMultiple();
    this.tooltip = this.reduxService.getCheckProjectsTooltip();
    this.confirmMessage = this.reduxService.getCheckProjectsConfirmMessage();
    this.auditType = this.reduxService.getCheckProjectsAuditType();
    if (isMultiple) {
      return;
    }

    if (isShow) {
      this.show();
    } else {
      this.hide();
    }
  }

  getProjects() {
    this.projectService.getLatelyProjects().subscribe((projects) => {
      this.items = projects;
      this.isLately = true;
      if (!projects.length) {
        this.search();
      }
    });
  }

  // getItemsCount() {
  //   this.projectService.getProjectsCount(this.queryParams).subscribe(count => {
  //     this.count = count;
  //   });
  // }

  search() {
    // this.page = 1;
    // this.queryParams = this.sharedService.omitEmptyParams({
    //   ...this.filterForm.value,
    //   page: this.page,
    //   limit: this.limit
    // });
    this.queryParams = {
      auditType: this.auditType
    };
    this.isLately = false;
    super.getItems();
    // this.getItemsCount();
  }

  // onPageChanged(pagination: IPagination) {
  //   this.queryParams.page = pagination.page;
  //   this.queryParams.limit = pagination.itemsPerPage;
  //   super.getItems();
  //   this.getItemsCount();
  // }

  searchProjects() {
    // 模糊查询 根据name和code
    if (this.isFirst) {
      // 将初始项目列表保存下来 供模糊查询
      this.defaultItems = this.items;
    }
    this.isFirst = false;
    const q = _.trim(_.toLower(this.filterForm.controls.q.value));
    // tslint:disable-next-line: arrow-return-shorthand tslint:disable-next-line: max-line-length
    const items = _.filter(this.defaultItems, item => { return _.includes(_.toLower(item.name), q) || _.includes(_.toLower(item.code), q); });
    this.items = items;
    if (!this.items.length) {
      super.clear();
      return;
    }
    super.checkItem(this.items[0]);

  }

  show() {
    if (!this.modalRef) {
      this.isLately = false;
      this.isFirst = true;
      this.getProjects();
      this.modalRef = this.modalService.show(this.modalEle, { class: 'modal-lg', ignoreBackdropClick: true });
    }
  }

  hide() {
    if (this.modalRef) {
      this.modalRef.hide();
      this.modalRef = undefined;
      this.reduxService.cancelCheckProjects();
      super.clear();
      this.filterForm.controls.q.setValue('');
    }
  }

  confirm() {
    const checkedProject = super.getCheckedItem();
    this.reduxService.setCheckProjectsNewProjects([checkedProject]);
    this.reduxService.confirmCheckProjects();
    this.clear();
    this.filterForm.controls.q.setValue('');
  }
}

