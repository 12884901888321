import {
  Component,
  TemplateRef,
  ViewChild,
  OnInit
} from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ReduxService } from 'src/app/redux/redux.service';

@Component({
  selector: 'app-save-notify',
  templateUrl: './save-notify.component.html',
  styleUrls: ['./save-notify.component.scss']
})
export class SaveNotifyComponent implements OnInit {
  modalRef: BsModalRef;
  @ViewChild('saveNotify') modalEle: TemplateRef<any>;

  constructor(
    private modalService: BsModalService,
    private reduxService: ReduxService
  ) {
    this.reduxService.subscribeStore(() => this.readState());
    this.readState();
  }

  ngOnInit() {
  }

  readState() {
    const isShow = this.reduxService.getSaveNotifyShow();

    if (isShow) {
      this.show();
    } else {
      this.hide();
    }
  }


  show() {
    if (!this.modalRef) {
      this.modalRef = this.modalService.show(this.modalEle, { ignoreBackdropClick: true });
    }
  }

  hide() {
    if (this.modalRef) {
      this.modalRef.hide();
      this.modalRef = undefined;
      this.reduxService.cancelSaveNotify();
    }
  }

  confirm() {
    this.reduxService.confirmSaveNotify();
  }


}
