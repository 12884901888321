import { Reducer, Action } from 'redux';
import {
  SetWorkflowAction,
  SetWorkflowEditEnableAction,
  SetWorkflowDirtyAction,
  AddWorkflowStepAction,
  DeleteWorkflowStepAction,
  UpdateWorkflowStepAction,
  CheckWorkflowStepAction,
  SetWorkflowEnforceDoneAction,
  SetWorkflowShowPersonsMaxLimitAction,
  SET_WORKFLOW,
  SET_WORKFLOW_EDIT_ENABLE,
  SET_WORKFLOW_DIRTY,
  ADD_WORKFLOW_STEP,
  DELETE_WORKFLOW_STEP,
  UPDATE_WORKFLOW_STEP,
  CHECK_WORKFLOW_STEP,
  SET_WORKFLOW_ENFORCE_DONE,
  SET_WORKFLOW_SHOW_PERSONS_MAX_LIMIT
} from '../actions/workflow.actions';
import { createSelector } from 'reselect';
import {
  IWorkflow,
  IWorkflowStep
} from 'src/app/models';
import _ from 'lodash';
import { EWorkflowStepType } from 'src/app/enums';
import { WORKFLOW_STEP_SHOW_PERSONS_MAX_LIMIT } from 'src/app/consts';

function assignStepRank(steps: IWorkflowStep[]) {
  let index = 0;
  _.forEach(steps, step => {
    step.rank = index++;
  });
}

// 添加节点
function addStep(state: WorkflowState, action: Action): WorkflowState {
  let steps = state.steps;
  const referenceStep = (action as AddWorkflowStepAction).referenceStep;
  const addType = (action as AddWorkflowStepAction).addType;
  const referenceIndex = _.findIndex(steps, { rank: referenceStep.rank });
  console.log('add', addType, referenceIndex, referenceStep);
  if (-1 === referenceIndex) {
    return state;
  }
  let insertIndex = 0;

  switch (addType) {
    case 'before':
      insertIndex = referenceIndex;
      break;
    case 'after':
      insertIndex = referenceIndex + 1;
      break;
  }

  // 计算新节点名称
  const stepNames = _.map(steps, 'name');
  const stepName = '节点';
  let stepNameIndex = 1;
  while (-1 !== _.indexOf(stepNames, stepName + stepNameIndex)) {
    stepNameIndex++;
  }
  const newStep: IWorkflowStep = {
    type: EWorkflowStepType.Normal,
    name: `${stepName}${stepNameIndex}`,
    enable_delete_step: true,
    enable_delete_person: true,
    persons: []
  };

  steps = [
    ...steps.slice(0, insertIndex),
    newStep,
    ...steps.slice(insertIndex)
  ];

  assignStepRank(steps);
  console.log('steps:', steps);

  return Object.assign({}, state, {
    steps,
    valid: verifySteps(steps),
    dirty: true
  });
}

// 验证工作流合法性
function verifySteps(steps: IWorkflowStep[]): boolean {
  let valid = true;
  _.forEach(steps, step => {
    valid = valid && verifyStep(step);
  });
  // // 节点名称不重复（开始、结束、项目管理员）
  // const startEndStep: IWorkflowStep[] = _.filter(steps, workflowStep => {
  //   return workflowStep.name === '开始' || workflowStep.name === '结束';
  // });
  // const managerStep: IWorkflowStep[] = _.filter(steps, workflowStep => {
  //   return workflowStep.name === ORGANIZATION_CHART_PROJECT_MANAGEMENT_ADMIN.name;
  // });
  // const nameNotDuplicate = startEndStep.length === 2 && managerStep.length <= 1;
  // // const nameNotDuplicate = !!(steps.length && steps.length === _.unionBy(steps, 'name').length);
  return valid;
}

function verifyStep(step: IWorkflowStep): boolean {
  // 节点名称不为空
  const nameValid = !!step.name;
  // 审批人不为空
  const personsValid = EWorkflowStepType.Normal !== step.type || !!(step.persons && step.persons.length);
  return nameValid && personsValid;
}

export interface WorkflowState {
  tips: string; // 工作流说明
  steps: IWorkflowStep[];
  current: number;
  currentStep: IWorkflowStep;
  checkedStep: IWorkflowStep;
  editEnable: boolean;
  valid: boolean;
  dirty: boolean;
  enforceDone: boolean; // 工作流强制显示完成状态(下发单、变更单作废时)
  showPersonsMaxLimit: number; // 节点显示的审批人数限制，超出则显示“折叠/展开”按钮
}

const initialState: WorkflowState = {
  tips: '',
  steps: [],
  current: undefined,
  currentStep: undefined,
  checkedStep: undefined,
  editEnable: false,
  valid: false,
  dirty: false,
  enforceDone: false,
  showPersonsMaxLimit: WORKFLOW_STEP_SHOW_PERSONS_MAX_LIMIT
};

export const WorkflowReducer: Reducer<WorkflowState> =
  (state: WorkflowState = initialState, action: Action): WorkflowState => {
    let steps: IWorkflowStep[] = [];
    let step: IWorkflowStep;
    let updateStep: IWorkflowStep;

    switch (action.type) {
      case SET_WORKFLOW:
        const workflow = (action as SetWorkflowAction).workflow;
        assignStepRank(workflow.steps);
        const currentStep = _.find(workflow.steps, { rank: workflow.current });
        return Object.assign({}, state, {
          tips: workflow.tips,
          current: workflow.current,
          steps: workflow.steps,
          currentStep,
          checkedStep: undefined,
          valid: verifySteps(workflow.steps)
        });

      case SET_WORKFLOW_EDIT_ENABLE:
        const editEnable = (action as SetWorkflowEditEnableAction).editEnable;
        return Object.assign({}, state, {
          editEnable
        });

      case SET_WORKFLOW_ENFORCE_DONE:
        const enforceDone = (action as SetWorkflowEnforceDoneAction).enforceDone;
        return Object.assign({}, state, {
          enforceDone
        });

      case SET_WORKFLOW_DIRTY:
        const dirty = (action as SetWorkflowDirtyAction).dirty;
        return Object.assign({}, state, {
          dirty
        });

      case ADD_WORKFLOW_STEP:
        return addStep(state, action);

      case DELETE_WORKFLOW_STEP:
        steps = state.steps;
        step = (action as DeleteWorkflowStepAction).step;
        steps = _.reject(steps, { rank: step.rank });
        assignStepRank(steps);
        return Object.assign({}, state, {
          steps,
          valid: verifySteps(steps),
          dirty: true
        });

      case UPDATE_WORKFLOW_STEP:
        steps = state.steps;
        step = (action as UpdateWorkflowStepAction).step;
        updateStep = _.find(steps, { rank: step.rank });
        updateStep = Object.assign(updateStep, step);
        assignStepRank(steps);
        return Object.assign({}, state, {
          steps,
          valid: verifySteps(steps),
          dirty: true
        });

      case CHECK_WORKFLOW_STEP:
        step = (action as CheckWorkflowStepAction).step;
        return Object.assign({}, state, { checkedStep: step });

      case SET_WORKFLOW_SHOW_PERSONS_MAX_LIMIT:
        const showPersonsMaxLimit = (action as SetWorkflowShowPersonsMaxLimitAction).showPersonsMaxLimit;
        return Object.assign({}, state, {
          showPersonsMaxLimit
        });

      default:
        return state;
    }
  };

const getState = (state: WorkflowState): WorkflowState => state;
export const getWorkflow = createSelector(
  getState,
  (state: WorkflowState) => {
    return {
      tips: state.tips,
      steps: state.steps,
      current: state.current,
      currentStep: state.currentStep,
      checkedStep: state.checkedStep,
      editEnable: state.editEnable,
      valid: state.valid,
      enforceDone: state.enforceDone,
      showPersonsMaxLimit: state.showPersonsMaxLimit
    };
  }
);

export const getWorkflowEditEnable = createSelector(
  getState,
  (state: WorkflowState) => state.editEnable
);

export const getWorkflowEnforceDone = createSelector(
  getState,
  (state: WorkflowState) => state.enforceDone
);

export const getWorkflowDirty = createSelector(
  getState,
  (state: WorkflowState) => state.dirty
);

export const getWorkflowValid = createSelector(
  getState,
  (state: WorkflowState) => state.valid
);

export const getWorkflowTips = createSelector(
  getState,
  (state: WorkflowState) => state.tips
);

export const getWorkflowSteps = createSelector(
  getState,
  (state: WorkflowState) => state.steps
);

export const getCurrentWorkflowStep = createSelector(
  getState,
  (state: WorkflowState) => state.currentStep
);

// 抄送功能只允许提交人和修订人选择
export const getCurrentWorkflowStepCanCc = createSelector(
  getState,
  (state: WorkflowState) => {
    if (state.currentStep) {
      return '开始' === state.currentStep.name || '修订' === state.currentStep.name;
    }
    return false;
  }
);

export const getCheckedWorkflowStep = createSelector(
  getState,
  (state: WorkflowState) => state.checkedStep
);

export const getWorkflowShowPersonsMaxLimit = createSelector(
  getState,
  (state: WorkflowState) => state.showPersonsMaxLimit
);
