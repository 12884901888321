//* 管理中心-人工成本维护
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserService } from 'src/app/services/user.service';
import { SharedService } from '../services/shared.service';

@Injectable({
  providedIn: 'root',
})
export class LaborCostGuard implements CanActivate {
  constructor(
    private router: Router,
    private userService: UserService,
    private sharedService: SharedService
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.userService.hasPermissionToMaintainLaborCost().pipe(
      map(hasPermission => {
        if (hasPermission) {
          return true;
        } else {
          this.sharedService.setRedirectUrl(window.location.href);
          this.router.navigate(['/work-center']);
          return false;
        }
      })
    );
  }
}
