import { OnInit, Directive } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { CListBase } from './CListBase';
import _ from 'lodash';

@Directive()
export abstract class CRadioBase<T, S> extends CListBase<T, S> implements OnInit {
  protected fb: FormBuilder = new FormBuilder();

  radioForm: FormGroup;
  radio: AbstractControl;
  checkedItem: T;

  constructor(
    protected service: any,
    protected getItemsFun: string,
    protected byProperty: string
  ) {
    super(service, getItemsFun, byProperty);
    this.radioForm = this.fb.group({
      radio: [undefined, Validators.required]
    });
    this.radio = this.radioForm.controls.radio;
    this.radio.valueChanges.subscribe((value) => {
      this.checkedItem = _.find(this.items, (item) => {
        return item[this.byProperty] === value;
      });
    });
  }

  ngOnInit() {
  }

  getItems(checkedItem?: T) {
    super.getItems().subscribe((items: Array<T>) => {
      if (!items.length) {
        return;
      }
      if (!checkedItem) {
        this.checkedItem = items[0];
        this.radio.setValue(items[0][this.byProperty]);
        return;
      }
      this.checkedItem = checkedItem;
      this.radio.setValue(checkedItem[this.byProperty] ? checkedItem[this.byProperty] : undefined);
    });
  }

  setItems(items: Array<T>) {
    if (!items.length) {
      return;
    }
    this.checkedItem = items[0];
    this.radio.setValue(items[0][this.byProperty]);
  }

  // 默认不勾选；
  getItemsNotCheckedFirstItem(checkedItem?: T) {
    super.getItems().subscribe((items: Array<T>) => {
      if (!items.length) {
        return;
      }
      if (!checkedItem) {
        return;
      }
      this.checkedItem = checkedItem;
      this.radio.setValue(checkedItem[this.byProperty] ? checkedItem[this.byProperty] : undefined);
    });
  }

  protected getCheckedItem() {
    return this.checkedItem;
  }

  // masked上的点击方法
  checkItem(item: T) {
    this.radio.setValue(item[this.byProperty]);
  }

  // 清空
  clear() {
    this.items = [];
    this.radio.setValue(undefined);
  }
}
