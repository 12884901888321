<ul class="nav">
  <li
    class="nav-item"
    *ngFor="let item of getShowItems()"
    [routerLink]="[item.routerLink]"
    routerLinkActive="active"
  >
    <a class="nav-link" href="javascript:;">
      <i *ngIf="item.icon" class="fa mr-2 ml-3" [ngClass]="item.icon"></i>
      <span class="title">{{ item.label }}</span>
    </a>
  </li>
  <li
    *ngIf="items?.length > maxLength"
    class="nav-item more-menu"
    [ngClass]="{ active: getIsInMoreReport() }"
    (click)="toggle()"
  >
    <a class="nav-link" href="javascript:;">
      <i class="fa mr-2 ml-3 fa-ellipsis-h"></i>
      <span class="title no-badge">更多操作</span>
    </a>
    <div *ngIf="isExpand" class="more-menu-list">
      <ul>
        <li
          *ngFor="let item of getMoreItems()"
          [routerLink]="[item.routerLink]"
          routerLinkActive="active"
          (click)="toggle()"
        >
          <a href="javascript:;">
            <i *ngIf="item.icon" class="fa mr-2 ml-3" [ngClass]="item.icon"></i>
            <span class="title">{{ item.label }}</span>
          </a>
        </li>
      </ul>
    </div>
  </li>
</ul>
