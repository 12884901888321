// 需求状态
export enum ERequirementStatus {
  /// <summary>
  /// 未提交
  /// </summary>
  UnSubmitted = -1,

  /// <summary>
  /// 分析中
  /// </summary>
  Analysising = 0,

  /// <summary>
  /// 已采纳
  /// </summary>
  Accepted = 10,

  /// <summary>
  /// 未采纳 
  /// </summary>
  Rejected = 11,

  /// <summary>
  /// 立项中 
  /// </summary>
  InProcess = 20,

  /// <summary>
  /// 开发中 
  /// </summary>
  Developing = 21,

  /// <summary>
  /// 已暂停 
  /// </summary>
  Stopped = 22,

  /// <summary>
  /// 已废止 
  /// </summary>
  Droped = 23,

  /// <summary>
  /// 已交付 
  /// </summary>
  Completed = 30,

}

