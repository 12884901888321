import { Injectable } from '@angular/core';
import { Observable, forkJoin, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import _ from 'lodash';

import {
  IWorkflow,
  IWorkflowStep,
  IWorkflowTemplate,
  IProject,
  IAlert,
  IPlan,
  IAuditInfoPersonsParams,
  IUserInfo,
  IHandlerPerson,
  IOrganizationChartItem,
} from 'src/app/models';
import { TemplateService } from './template.service';
import { SubscriptionService } from './subscription.service';
import { AuditService } from './audit.service';
import { EWorkflowUserType, EWorkflowStepType } from 'src/app/enums';
import { IWorkflowTemplateCode } from 'src/app/models';
import { IProjectStatus } from '../models/IProject';
import {
  APPROVAL_STEP_NAME, CENTER_DIRECTOR_STEP_NAME, ORGANIZATION_CHART_MANAGEMENT_ADMIN,
  ORGANIZATION_CHART_PROJECT_MANAGEMENT_ADMIN,
  WORKFLOW_COUNTERSIGN_STEP,
  WORKFLOW_COUNTERSIGN_STEP_1
} from '../consts';

@Injectable({
  providedIn: 'root'
})
export class WorkflowService {
  constructor(
    private auditService: AuditService,
    private templateService: TemplateService,
    private subscriptionService: SubscriptionService
  ) { }

  // （补丁）填充上级责任人
  // 计划回执审批流程: "管理员/上级责任人"节点（节点的默认值为上级责任人）
  private patchFillSuperiorUser(params: IAuditInfoPersonsParams, project: IProject | IProjectStatus, plan: IPlan): Observable<IUserInfo[]> {
    if (EWorkflowUserType.SuperiorUser !== params.type) {
      return this.auditService.getAuditInfoDefaultPersons(params);
    }
    // 处理后端返回的level为字符串
    if (+plan.level === 1) {
      // 一级计划取项目管理员
      const projectManager = {
        user_id: project.project_manager_id,
        name: project.project_manager_name
      };
      return of([projectManager]);
    } else if (+plan.level === 2) {
      // 二级计划取管理经理或上级计划责任人
      // 计划创建人若为“管理经理”取管理经理，否则取上级计划责任人
      const createdByUserId = plan.created_by;
      params.type = EWorkflowUserType.OrganizationRole;
      params.role = '管理经理';
      return this.auditService.getAuditInfoDefaultPersons(params).pipe(
        switchMap(userInfos => {
          if (userInfos.length && _.find(userInfos, { user_id: createdByUserId })) {
            return of(_.filter(userInfos, { user_id: createdByUserId }));
          } else {
            params.type = EWorkflowUserType.SuperiorUser;
            params.role = '上级计划责任人';
            return this.auditService.getAuditInfoDefaultPersons(params);
          }
        })
      );
    } else {
      // 三四级计划取上级计划责任人
      return this.auditService.getAuditInfoDefaultPersons(params);
    }
  }

  // 工作流各节点填充默认审批人
  // tslint:disable-next-line: max-line-length
  private fillWorkflowDefaultPersons(workflowTemplate: IWorkflowTemplate, project: IProject | IProjectStatus, plan?: IPlan): Observable<IWorkflow> {
    const workflow = workflowTemplate.data;
    const requests = [];
    _.forEach(workflow.steps, step => {
      const params: IAuditInfoPersonsParams = {
        type: step.default_handler_role_id,
        role: step.default_handler_role_name,
        project_code: project.code,
      };
      if (plan) {
        params.plan_id = plan.id;
      }
      /* 计划回执审批流程: "管理员/上级责任人"节点（节点的默认值为上级责任人）
         一级计划取项目管理员
         二级计划取管理经理或上级计划责任人（计划创建人若为“管理经理”取管理经理，否则取上级计划责任人）
         三四级计划取上级计划责任人
      */
      if (EWorkflowUserType.SuperiorUser === params.type) {
        requests.push(this.patchFillSuperiorUser(params, project, plan));
      } else {
        requests.push(this.auditService.getAuditInfoDefaultPersons(params));
      }
    });
    return forkJoin(requests).pipe(
      map(results => {
        for (let i = 0; i < workflow.steps.length; i++) {
          workflow.steps[i].persons = results[i] as IHandlerPerson[];
          // 节点人不可删（默认审批人不可删）
          if (workflow.steps[i].persons.length && !workflow.steps[i].enable_delete_person) {
            _.forEach(workflow.steps[i].persons, person => {
              person.disable_delete = true;
            });
          }
        }
        return workflow;
      })
    );
  }

  // 工作流各节点填充默认审批人（没有project参数）
  private fillWorkflowDefaultPersonsWithoutProject(workflowTemplate: IWorkflowTemplate): Observable<IWorkflow> {
    const workflow = workflowTemplate.data;
    const requests = [];
    _.forEach(workflow.steps, step => {
      const params: IAuditInfoPersonsParams = {
        type: step.default_handler_role_id,
        role: step.default_handler_role_name,
      };
      if (step.default_handler_role_id === EWorkflowUserType.SubmitUser) {
        requests.push(this.auditService.getAuditInfoDefaultPersons(params));
      } else {
        requests.push(of([]));
      }
    });
    return forkJoin(requests).pipe(
      map(results => {
        for (let i = 0; i < workflow.steps.length; i++) {
          workflow.steps[i].persons = results[i] as IHandlerPerson[];
          // 节点人不可删（默认审批人不可删）
          if (workflow.steps[i].persons.length && !workflow.steps[i].enable_delete_person) {
            _.forEach(workflow.steps[i].persons, person => {
              person.disable_delete = true;
            });
          }
        }
        return workflow;
      })
    );
  }

  // 工作流填充”开始“节点
  private fillWorkflowStartStep(workflowTemplate: IWorkflowTemplate): Observable<IWorkflow> {
    const workflow = workflowTemplate.data;
    const startStep = _.find(workflow.steps, { type: EWorkflowStepType.Start });
    const params: IAuditInfoPersonsParams = {
      type: startStep.default_handler_role_id,
      role: startStep.default_handler_role_name,
    };
    return this.auditService.getAuditInfoDefaultPersons(params).pipe(
      map(result => {
        workflow.steps[0].persons = result as IHandlerPerson[];
        return workflow;
      })
    );
  }

  // 获取默认工作流（带有默认审批人）
  getDefaultWorkflow(workflowTemplateCode: IWorkflowTemplateCode, project: IProject | IProjectStatus, plan?: IPlan): Observable<IWorkflow> {
    const params = { code: workflowTemplateCode };
    return this.templateService.getWorkflowTemplate(params).pipe(
      switchMap(workflowTemplate => this.fillWorkflowDefaultPersons(workflowTemplate, project, plan))
    );
  }

  // 获取默认工作流（只填充“开始”节点）
  getDefaultWorkflowOnlyFillStartStep(workflowTemplateCode: IWorkflowTemplateCode): Observable<IWorkflow> {
    const params = { code: workflowTemplateCode };
    return this.templateService.getWorkflowTemplate(params).pipe(
      switchMap(workflowTemplate => this.fillWorkflowStartStep(workflowTemplate))
    );
  }

  // 获取默认工作流（带有默认审批人，没有项目、计划参数）
  getDefaultWorkflowWithoutProject(workflowTemplateCode: IWorkflowTemplateCode): Observable<IWorkflow> {
    const params = { code: workflowTemplateCode };
    return this.templateService.getWorkflowTemplate(params).pipe(
      switchMap(workflowTemplate => this.fillWorkflowDefaultPersonsWithoutProject(workflowTemplate))
    );
  }

  // 流程节点名称不能重复
  verifyWorkflowStepsNameNotDuplicate(workflowSteps: IWorkflowStep[]): boolean {
    // 去掉name空格
    _.forEach(workflowSteps, (workflowStep => {
      workflowStep.name = workflowStep.name.trim();
    }));
    const uniqSteps = _.uniqBy(workflowSteps, 'name');
    const duplicateSteps = _.difference(workflowSteps, uniqSteps);
    if (workflowSteps.length && uniqSteps.length !== workflowSteps.length) {
      const alert: IAlert = {
        type: 'danger',
        title: '流程节点名称重复',
        content: [`“${duplicateSteps[0].name}”节点的节点名称重复`]
      };
      this.subscriptionService.publishAlert(alert);
      return false;
    }
    return true;
  }

  // 流程节点name不能为空
  verifyWorkflowStepsName(workflowSteps: IWorkflowStep[]): boolean {
    let flag = true;
    _.forEach(workflowSteps, workflowStep => {
      if (!(workflowStep.name && workflowStep.name.trim())) {
        const alert: IAlert = {
          type: 'danger',
          title: '流程设置',
          content: ['节点名称不能为空']
        };
        this.subscriptionService.publishAlert(alert);
        flag = false;
      }
    });
    return flag;
  }
  // 中间节点 审批人不能为空
  verifyWorkflowStepsPerson(workflowSteps: IWorkflowStep[]): boolean {
    let flag = true;
    _.forEach(workflowSteps, workflowStep => {
      if (EWorkflowStepType.Normal === workflowStep.type && !(workflowStep.persons && workflowStep.persons.length)) {
        const alert: IAlert = {
          type: 'danger',
          title: '流程设置',
          content: ['审批人不能为空']
        };
        this.subscriptionService.publishAlert(alert);
        flag = false;
      }
    });
    return flag;
  }

  verifyWorkFlowSteps(workflowSteps: IWorkflowStep[]): boolean {
    // tslint:disable-next-line: max-line-length
    return this.verifyWorkflowStepsName(workflowSteps)
      && this.verifyWorkflowStepsNameNotDuplicate(workflowSteps)
      && this.verifyWorkflowStepsPerson(workflowSteps);
  }

  // 已审回执审批流程(标准、专项、风险计划回执页面)、交付物更新审批流程
  // 会签节点：一级计划带项目管理员，二级计划带管理经理，三四级计划不带人，若没有带出人，删除系统“会签”节点
  // -bugfix: 暂存后，自定义的“会签”节点不应该被删除
  // -方案：若存在自定义“会签”节点，系统又带出“会签”节点；将自定义“会签”节点更名为“会签1”
  patchWorkflowCountersignStep(plan: IPlan, project: IProject, workflow: IWorkflow): IWorkflow {
    switch (+plan.level) {
      case 1: // 一级计划取项目管理员
        return this.getWorkflowWithDefaultPersonsSync(project, ORGANIZATION_CHART_PROJECT_MANAGEMENT_ADMIN.name, ORGANIZATION_CHART_PROJECT_MANAGEMENT_ADMIN.parent_node_path, workflow);
      case 2:  // 二级计划取管理经理
        return this.getWorkflowWithDefaultPersonsSync(project, ORGANIZATION_CHART_MANAGEMENT_ADMIN.name, ORGANIZATION_CHART_MANAGEMENT_ADMIN.parent_node_path, workflow);
      default: // 三四级计划不带人，删除系统“会签”节点
        return this.handleCountersignStep([], workflow);
    }
  }

  // 组织变更审批流程
  // 会签节点：三级组织变更带管理经理，其他组织层级变更不带人
  patchOrgWorkflowCountersignStep(
    isLevel3: boolean,
    project: IProject,
    workflow: IWorkflow
  ): IWorkflow {
    if (isLevel3) {
      return this.getWorkflowWithDefaultPersonsSync(project, ORGANIZATION_CHART_MANAGEMENT_ADMIN.name, ORGANIZATION_CHART_MANAGEMENT_ADMIN.parent_node_path, workflow);
    }
    return this.handleCountersignStep([], workflow);
  }

  // 通过项目组织树信息中获取 流程默认审批人
  getWorkflowWithDefaultPersonsSync(
    project: IProject,
    organizationName: string,
    organizationParentNodePath: string,
    workflow: IWorkflow
  ): IWorkflow {
    const defaultPersonOrg = _.find(project.project_org_tree.items, {
      name: organizationName,
      parent_node_path: organizationParentNodePath
    }) as IOrganizationChartItem;
    if (!defaultPersonOrg) {
      // bugfix: 若未找到组织节点，需删除“会签”节点
      return this.handleCountersignStep([], workflow);
    }
    const handlePersons = defaultPersonOrg.persons as IHandlerPerson[];
    _.forEach(handlePersons, person => {
      person.disable_delete = true;
    });
    return this.handleCountersignStep(handlePersons, workflow);
  }

  // 若系统未带出人（项目管理员 | 管理经理）
  // -1.删除系统“会签”节点
  // -2.若有“会签1”节点，重命名为“会签”
  // 若系统可以带出人（项目管理员 | 管理经理）
  // -1.若没有系统“会签”节点，在“批准”节点前添加系统“会签”节点；
  // -2.若有自定义“会签”节点，重命名为“会签1”；
  handleCountersignStep(defaultPersons: IHandlerPerson[], workflow: IWorkflow): IWorkflow {
    const systemCountersignStep = _.find(workflow.steps, {
      name: WORKFLOW_COUNTERSIGN_STEP,
      enable_delete_step: false,
    });
    const customCountersignStep = _.find(workflow.steps, {
      name: WORKFLOW_COUNTERSIGN_STEP,
      enable_delete_step: true,
    });
    const customCountersignStep1 = _.find(workflow.steps, {
      name: WORKFLOW_COUNTERSIGN_STEP_1,
      enable_delete_step: true,
    });
    if (systemCountersignStep) {
      systemCountersignStep.persons = defaultPersons;
    }
    // 若系统未带出人（项目管理员 | 管理经理）
    if (!defaultPersons.length) {
      workflow = this.removeStepWithoutDefaultPersons(workflow);
      if (customCountersignStep1) {
        customCountersignStep1.name = WORKFLOW_COUNTERSIGN_STEP;
      }
      return workflow;
    }
    // 若系统可以带出人（项目管理员 | 管理经理）
    if (!systemCountersignStep) {
      const indexOfApprovalStep = _.findIndex(workflow.steps, {
        name: APPROVAL_STEP_NAME
      });
      const step: IWorkflowStep = {
        type: EWorkflowStepType.Normal,
        type_name: '中间节点',
        name: WORKFLOW_COUNTERSIGN_STEP,
        enable_delete_step: false,
        enable_delete_person: false,
        default_handler_role_id: EWorkflowUserType.CustomUser,
        default_handler_role_name: '',
        person_limit: -1,
        persons: defaultPersons,
        disable_add_person: true
      };
      workflow.steps.splice(indexOfApprovalStep, 0, step);
    }
    if (customCountersignStep) {
      customCountersignStep.name = WORKFLOW_COUNTERSIGN_STEP_1;
    }
    return workflow;
  }

  // 若系统自动带人的节点，自动带不出人，删除该节点
  removeStepWithoutDefaultPersons(workflow: IWorkflow): IWorkflow {
    _.remove(workflow.steps, workflowStep => {
      return workflowStep.type !== EWorkflowStepType.Start
        && workflowStep.type !== EWorkflowStepType.End
        && workflowStep.disable_add_person
        && !workflowStep.persons.length;
    });
    return workflow;
  }

  // hotfix: 每次新进页面，重新获取默认带人的节点（组织成员已被更新，未带出最新组织成员）
  patchFillWorkflowDefaultPersons(workflow: IWorkflow, project: IProject, plan?: IPlan): Observable<IWorkflow> {
    const defaultPatchPersonSteps = _.filter(workflow.steps, step => {
      return step.disable_add_person
        && !step.enable_delete_step
        && !step.enable_delete_person
        && step.type !== EWorkflowStepType.Start
        && step.type !== EWorkflowStepType.End
        && step.name !== CENTER_DIRECTOR_STEP_NAME; // “中心主任”节点不需重新获取（工程师变更带出“中心主任”）
    });
    if (!defaultPatchPersonSteps.length) {
      return of(workflow);
    }
    const requests = [];
    _.forEach(defaultPatchPersonSteps, step => {
      const params: IAuditInfoPersonsParams = {
        type: step.default_handler_role_id,
        role: step.default_handler_role_name,
        project_code: project.code,
      };
      if (plan) {
        params.plan_id = plan.id;
      }
      if (EWorkflowUserType.SuperiorUser === params.type) {
        requests.push(this.patchFillSuperiorUser(params, project, plan));
      } else {
        requests.push(this.auditService.getAuditInfoDefaultPersons(params));
      }
    });
    return forkJoin(requests).pipe(
      map(results => {
        for (let i = 0; i < defaultPatchPersonSteps.length; i++) {
          defaultPatchPersonSteps[i].persons = results[i] as IHandlerPerson[];
          // 节点人不可删（默认审批人不可删）
          if (defaultPatchPersonSteps[i].persons.length && !defaultPatchPersonSteps[i].enable_delete_person) {
            _.forEach(defaultPatchPersonSteps[i].persons, person => {
              person.disable_delete = true;
            });
          }
        }
        return workflow;
      })
    );
  }

  // 若带出了系统“会签”节点，将自定义“会签”重命名为“会签1”
  // 若仅有自定义“会签1”节点，将“会签1”重命名为“会签”
  handleStandardPlanReceiptCountersignStep(workflow: IWorkflow) {
    const systemCountersignStep = _.find(workflow.steps, {
      name: WORKFLOW_COUNTERSIGN_STEP,
      enable_delete_step: false,
    });
    const customCountersignStep = _.find(workflow.steps, {
      name: WORKFLOW_COUNTERSIGN_STEP,
      enable_delete_step: true,
    });
    const customCountersignStep1 = _.find(workflow.steps, {
      name: WORKFLOW_COUNTERSIGN_STEP_1,
      enable_delete_step: true,
    });
    if (systemCountersignStep && customCountersignStep) {
      customCountersignStep.name = `${customCountersignStep.name}1`;
    }
    if (!systemCountersignStep && customCountersignStep1) {
      customCountersignStep.name = systemCountersignStep.name;
    }
  }

  // #region 工作流节点
  // 工作流当前节点是否是指定节点
  isWorkflowAtStep(workflow: IWorkflow, stepName: string): boolean {
    const step = _.find(workflow.steps, { name: stepName });
    return workflow.current === step?.rank;
  }

  // 工作流当前节点是否在指定节点之后
  isWorkflowAfterStep(workflow: IWorkflow, stepName: string): boolean {
    const step = _.find(workflow.steps, { name: stepName });
    return workflow.current > step?.rank;
  }
  // #endregion
}



