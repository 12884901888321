import { Action, ActionCreator } from 'redux';
import {
    IAddOrganizationChartItemsCallback,
    IOrganizationChartItem,
    TOrganizationChartItemAddWay,
} from 'src/app/models';
// #region 显示
export interface ShowAddOrganizationChartItemAction extends Action {
    callback: IAddOrganizationChartItemsCallback;
    addWays: TOrganizationChartItemAddWay[]; // 组织节点的添加途径
    parentNodePath: string; // 通过模板添加子节点时，获取组织模板列表时使用的 路径 参数
    enableEdit: boolean; // 自定义节点模板是否可以编辑
}
export const SHOW_ADD_ORGANIZATION_CHART_ITEM = 'SHOW_ADD_ORGANIZATION_CHART_ITEM';
export const showAddOrganizationChartItem: ActionCreator<ShowAddOrganizationChartItemAction> = (
    callback: IAddOrganizationChartItemsCallback,
    addWays: ['template', 'custom'],
    parentNodePath: string,
    enableEdit: boolean
) => ({
    type: SHOW_ADD_ORGANIZATION_CHART_ITEM,
    callback,
    addWays,
    parentNodePath,
    enableEdit
});
// #endregion

// #region  取消

// tslint:disable-next-line: no-empty-interface
export interface CancelAddOrganizationChartItemAction extends Action {
}
export const CANCEL_ADD_ORGANIZATION_CHART_ITEM = 'CANCEL_ADD_ORGANIZATION_CHART_ITEM';
export const cancelAddOrganizationChartItem: ActionCreator<CancelAddOrganizationChartItemAction> = () => ({
    type: CANCEL_ADD_ORGANIZATION_CHART_ITEM
});
// #endregion

//#region 确定

// tslint:disable-next-line: no-empty-interface
export interface ConfirmAddOrganizationChartItemAction extends Action {
}
export const CONFIRM_ADD_ORGANIZATION_CHART_ITEM = 'CONFIRM_ADD_ORGANIZATION_CHART_ITEM';
export const confirmAddOrganizationChartItem: ActionCreator<ConfirmAddOrganizationChartItemAction> = () => ({
    type: CONFIRM_ADD_ORGANIZATION_CHART_ITEM
});

//#endregion

//#region 设置选中的模板列表
export interface SetAddOrganizationChartItemAction extends Action {
    addData: number | IOrganizationChartItem | IOrganizationChartItem[];
}
export const SET_SHOW_ADD_ORGANIZATION_CHART_ITEMS = 'SET_SHOW_ADD_ORGANIZATION_CHART_ITEMS';
// tslint:disable-next-line:max-line-length
export const setAddOrganizationChartItem: ActionCreator<SetAddOrganizationChartItemAction> = (addData: number | IOrganizationChartItem | IOrganizationChartItem[]) =>
({
    type: SET_SHOW_ADD_ORGANIZATION_CHART_ITEMS,
    addData
});
//#endregion

//#region 设置添加自定义节点黑名单
export interface SetAddOrganizationChartItemsCustomBlackListAction extends Action {
    customBlackList: string[];
}
export const SET_ADD_ORGANIZATION_CHART_ITEMS_CUSTOM_BLACK_LIST = 'SET_ADD_ORGANIZATION_CHART_ITEMS_CUSTOM_BLACK_LIST';
export const setAddOrganizationChartItemsCustomBlackList: ActionCreator<SetAddOrganizationChartItemsCustomBlackListAction>
    = (customBlackList: string[]) => ({
        type: SET_ADD_ORGANIZATION_CHART_ITEMS_CUSTOM_BLACK_LIST,
        customBlackList
    });
//#endregion
