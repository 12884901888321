// 版本类型
export enum EVersionType {
    Project = 10,   // 项目
    ProjectOrganization = 20,   // 组织
    ProjectMilestone = 30,  // 节点
    ProjectPlan = 40,   // 计划
    ProjectDeliverable = 50,    // 交付物
    ProjectDigitalDeliverable = 51, // 数据化交付物
    ProjectWorkflow = 60    // 工作流
}
