// setup 节点模板设置
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree, CanActivate } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import _ from 'lodash';
import { SetupService } from '../services/setup.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class setupMilestoneTemplateGuard implements CanActivate {
  constructor(
    private router: Router,
    private setupService: SetupService
  ) { }
  canActivate(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return forkJoin([
      this.setupService.getSetup(),
      this.setupService.getSetupSteps()
    ]).pipe(
      map(results => {
        const steps = results[1];
        const currentStep = _.find(steps, {
          current_step: results[0].current_step
        });
        const step = _.find(steps, {
          name: '节点模板'
        });
        if (step.current_step !== results[0].current_step) {
          this.router.navigate([`${currentStep.url}`]);
          return false;
        }
        return true;
      })
    );
  }
}