// 需求授权查看类型
export enum ERequirementPermissionType {
  /// <summary>
  /// 按个人授权
  /// </summary>
  User = 0, //

  /// <summary>
  /// 按组织授权
  /// </summary>
  Org = 10
}
