import { Action, ActionCreator } from 'redux';
import { IDeliverableItem } from 'src/app/models';

// #region 设置交付物列表
export interface SetDeliverablesItemsAction extends Action {
  items: IDeliverableItem[];
}
export const SET_DELIVERABLES_ITEMS = 'SET_DELIVERABLES_ITEMS';
export const setDeliverablesItems: ActionCreator<SetDeliverablesItemsAction> = (items: IDeliverableItem[]) => ({
  type: SET_DELIVERABLES_ITEMS,
  items
});
// #endregion

// #region 设置交付物清单的状态灯的可编辑状态
export interface SetDeliverablesLightEditEnableAction extends Action {
  lightEditEnable: boolean;
}
export const SET_DELIVERABLES_LIGHT_EDIT_ENABLE = 'SET_DELIVERABLES_LIGHT_EDIT_ENABLE';
export const setDeliverablesLightEditEnable: ActionCreator<SetDeliverablesLightEditEnableAction> = (lightEditEnable: boolean) => ({
  type: SET_DELIVERABLES_LIGHT_EDIT_ENABLE,
  lightEditEnable
});
// #endregion

// #region 设置交付物清单的文件的可编辑状态
export interface SetDeliverablesFileEditEnableAction extends Action {
  fileEditEnable: boolean;
}
export const SET_DELIVERABLES_FILE_EDIT_ENABLE = 'SET_DELIVERABLES_FILE_EDIT_ENABLE';
export const setDeliverablesFileEditEnable: ActionCreator<SetDeliverablesFileEditEnableAction> = (fileEditEnable: boolean) => ({
  type: SET_DELIVERABLES_FILE_EDIT_ENABLE,
  fileEditEnable
});
// #endregion


// #region 设置交付物清单的可操作状态
export interface SetDeliverablesOperateEnableAction extends Action {
  operateEnable: boolean;
}
export const SET_DELIVERABLES_OPERATE_ENABLE = 'SET_DELIVERABLES_OPERATE_ENABLE';
export const setDeliverablesOperateEnable: ActionCreator<SetDeliverablesOperateEnableAction> = (operateEnable: boolean) => ({
  type: SET_DELIVERABLES_OPERATE_ENABLE,
  operateEnable
});
// #endregion

// #region 设置交付物清单显示全部（包括已提交、未提交）
export interface SetDeliverablesShowAllAction extends Action {
  showAll: boolean;
}
export const SET_DELIVERABLES_SHOW_ALL = 'SET_DELIVERABLES_SHOW_ALL';
export const setDeliverablesShowAll: ActionCreator<SetDeliverablesShowAllAction> = (showAll: boolean) => ({
  type: SET_DELIVERABLES_SHOW_ALL,
  showAll
});
// #endregion

// #region 更新交付物项
export interface UpdateDeliverablesItemAction extends Action {
  item: IDeliverableItem;
}
export const UPDATE_DELIVERABLES_ITEM = 'UPDATE_DELIVERABLES_ITEM';
export const updateDeliverablesItem: ActionCreator<UpdateDeliverablesItemAction> = (item: IDeliverableItem) => ({
  type: UPDATE_DELIVERABLES_ITEM,
  item
});
// #endregion

// #region 设置交付物清单的dirty状态
export interface SetDeliverablesDirtyAction extends Action {
  dirty: boolean;
}
export const SET_DELIVERABLES_DIRTY = 'SET_DELIVERABLES_DIRTY';
export const setDeliverablesDirty: ActionCreator<SetDeliverablesDirtyAction> = (dirty: boolean) => ({
  type: SET_DELIVERABLES_DIRTY,
  dirty
});
// #endregion

// #region 设置 是否最后版本
export interface SetDeliverablesIsLastVersionAction extends Action {
  isLastVersion: boolean;
}
export const SET_DELIVERABLES_IS_LAST_VERSION = 'SET_DELIVERABLES_IS_LAST_VERSION';
export const setDeliverablesIsLastVersion: ActionCreator<SetDeliverablesIsLastVersionAction> =
  (isLastVersion: boolean) => ({
    type: SET_DELIVERABLES_IS_LAST_VERSION,
    isLastVersion
  });
// #endregion

