import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { openCloseAnimation } from 'src/app/animations/open-close';
import { ITagPlanReceiptData } from 'src/app/models';

@Component({
  selector: 'app-collapse',
  templateUrl: './collapse.component.html',
  styleUrls: ['./collapse.component.scss'],
  animations: [
    openCloseAnimation
  ]
})

export class CollapseComponent implements OnInit, OnChanges {
  @Input() name: string;
  @Input() description: string;
  @Input() defaultExpand: boolean;
  @Input() tagPlanReceiptData: ITagPlanReceiptData;
  isExpand: boolean;

  constructor() {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.isExpand = changes.defaultExpand
      && false !== changes.defaultExpand.currentValue
      && 'false' !== changes.defaultExpand.currentValue
      || changes.tagPlanReceiptData && changes.tagPlanReceiptData.currentValue;
  }

  toggleExpand() {
    this.isExpand = !this.isExpand;
  }

  expand(isExpand: boolean) {
    this.isExpand = isExpand;
  }
}
