import { Injectable } from '@angular/core';
import { HttpParams, HttpRequest, HttpClient, HttpResponse } from '@angular/common/http';
import { forkJoin, Observable, of } from 'rxjs';
import { map, catchError, filter, switchMap } from 'rxjs/operators';
import _ from 'lodash';

import {
  ITaskInfo,
  IListCommonParams,
  ITaskInfoParams,
  IUploadPlansResponse,
  IDivisionPlanEnginField,
  IDivisionPlanEnginFieldParams,
  IPlan,
  IMilestone,
  IAlert,
  IPlanTemplate,
  IProject,
  IPlanSearchParams,
  ICommonConfig,
  IPlanSearchOption,
  ILevelTreeItem,
  IEnginFieldByDevelopmentHostAndCategoryParams,
  ISecretPlanPermissionParams,
  ISecretPlanPermissionsItem,
  ICreateSecretPlanPermissionsCommand,
  IUpdateSecretPlanPermissionsCommand,
  IAddOrUpdateEnginFieldCommand,
  IUpdateEnginFieldDisableCommand,
  IBatchUpdateEnginFieldCommand,
} from 'src/app/models';
import {
  ETaskInfoStatus, ETaskInfoType, EUploadType, EPlanType, ELightType, EPlanFilterStatus,
  EWorkflowStepType, EWorkflowUserType,
  EHttpStatusCode
} from 'src/app/enums';
import { APPROVAL_STEP_NAME, PAGE_SIZE, WORKFLOW_COUNTERSIGN_STEP } from 'src/app/consts';
import { TaskInfoService } from './task-info.service';
import { SharedService } from './shared.service';
import { SubscriptionService } from './subscription.service';
import { PlanInfoComponent } from '../modules/shared/plan-info/plan-info.component';
import * as moment from 'moment';
import { PlanTemplateService } from './plan-template.service';
import { AuditService } from './audit.service';
import { PlanTreeStateService } from '../states/plan-tree-state.service';
import { WorkflowService } from './workflow.service';

@Injectable({
  providedIn: 'root'
})
export class PlanService {
  constructor(
    private http: HttpClient,
    private sharedService: SharedService,
    private taskInfoService: TaskInfoService,
    private subscriptionService: SubscriptionService,
    private planTemplateService: PlanTemplateService,
    private auditService: AuditService,
    private planTreeStateService: PlanTreeStateService,
    private workflowService: WorkflowService
  ) { }

  planTypes = [{
    key: EPlanType.Standard,
    name: '标准计划'
  }, {
    key: EPlanType.Spec,
    name: '专项计划'
  }, {
    key: EPlanType.Risk,
    name: '风险计划'
  }];

  lightTypes = [{
    key: ELightType.Red,
    name: '红灯'
  }, {
    key: ELightType.Yellow,
    name: '黄灯'
  }, {
    key: ELightType.None,
    name: '无灯'
  }, {
    key: ELightType.GreenWarning,
    name: '绿灯!'
  }, {
    key: ELightType.Green,
    name: '绿灯'
  }];

  planFilterStatue = [{
    key: EPlanFilterStatus.ToReceive,
    name: '未接收'
  }, {
    key: EPlanFilterStatus.ToSubmit,
    name: '未提交'
  }, {
    key: EPlanFilterStatus.Reviewing,
    name: '流程中'
  }, {
    key: EPlanFilterStatus.Receipted,
    name: '已完成'
  }, {
    key: EPlanFilterStatus.Cancelled,
    name: '已取消'
  }];

  getTodoTaskInfos(params?: IListCommonParams): Observable<ITaskInfo[]> {
    params = params || { page: 1, limit: PAGE_SIZE };
    const taskInfoParams: ITaskInfoParams = {
      ...params,
      type: ETaskInfoType.Plan,
      status: ETaskInfoStatus.Pending
    };
    return this.taskInfoService.getTaskInfos(taskInfoParams);
  }

  getTodoTaskInfosCount(params?: IListCommonParams, enforceNew?: boolean): Observable<number> {
    params = params || { page: 1, limit: PAGE_SIZE };
    const taskInfoParams: ITaskInfoParams = {
      ...params,
      type: ETaskInfoType.Plan,
      status: ETaskInfoStatus.Pending
    };
    return this.taskInfoService.getTaskInfosCount(taskInfoParams, enforceNew);
  }

  getDoneTaskInfos(params?: IListCommonParams): Observable<ITaskInfo[]> {
    params = params || { page: 1, limit: PAGE_SIZE };
    const taskInfoParams: ITaskInfoParams = {
      ...params,
      type: ETaskInfoType.Plan,
      status: ETaskInfoStatus.Processed
    };
    return this.taskInfoService.getTaskInfos(taskInfoParams);
  }

  getDoneTaskInfosCount(params?: IListCommonParams, enforceNew?: boolean): Observable<number> {
    params = params || { page: 1, limit: PAGE_SIZE };
    const taskInfoParams: ITaskInfoParams = {
      ...params,
      type: ETaskInfoType.Plan,
      status: ETaskInfoStatus.Processed
    };
    return this.taskInfoService.getTaskInfosCount(taskInfoParams, enforceNew);
  }

  getPlan(id: string): Observable<IPlan> {
    return this.http.get<IPlan>(`/api/business/plans/${id}`).pipe(
      catchError(this.sharedService.handleError<IPlan>(`get plan by id: ${id}`, undefined))
    );
  }

  // （计划下发）导入计划|风险
  upload(uploadType: EUploadType, excelFile: File, projectCode: string, auditId: string): Observable<IUploadPlansResponse> {
    const myFormData: FormData = new FormData();
    myFormData.append('file', excelFile, excelFile.name);
    const params = new HttpParams({
      fromObject: {
        project_code: projectCode,
        audit_id: auditId,
        import_type: uploadType
      }
    });
    const config = new HttpRequest(
      'POST',
      '/api/business/excel/import-plans',
      myFormData,
      {
        reportProgress: true,
        params
      }
    );
    return this.http.request(config).pipe(
      filter(event => event instanceof HttpResponse),
      map(event => {
        if (event instanceof HttpResponse) {
          return event.body as IUploadPlansResponse;
        }
      }),
      catchError(this.sharedService.handleError('upload plans', undefined))
    );
  }

  // 计划变更导入（返回变更的一级计划和追加的子计划）
  updatePlansUpload(file: File, auditId: string, projectCode: string): Observable<IUploadPlansResponse> {
    const myFormData: FormData = new FormData();
    myFormData.append('file', file, file.name);
    const config = new HttpRequest(
      'POST',
      `/api/reporting/import/project/${projectCode}/updated-plan/${auditId}`,
      myFormData,
      {
        reportProgress: true,
      }
    );
    return this.http.request(config).pipe(
      filter(event => event instanceof HttpResponse),
      map(event => {
        if (event instanceof HttpResponse) {
          return event.body as IUploadPlansResponse;
        }
      }),
      catchError(this.sharedService.handleError('upload plan', undefined))
    );
  }

  // 获取所属业务列表
  // @param: divisionOrgNumber
  // - 创建计划模板时: 当前用户的division
  // - 项目创建计划时使用：项目的development_division_org_number
  getDivisionPlanEnginFields(params: IDivisionPlanEnginFieldParams): Observable<IDivisionPlanEnginField[]> {
    // tslint:disable-next-line: max-line-length
    return this.http.get<IDivisionPlanEnginField[]>(`/api/divisions/${params.division_org_number}/plan-engin-fields/${params.category_key}`).pipe(
      catchError(this.sharedService.handleError<IDivisionPlanEnginField[]>('get division plan engin fields', []))
    );
  }

  // 通过开发主体和细分类获取所示业务列表
  getEnginFieldsByDevelopmentHostAndCategory(params: IEnginFieldByDevelopmentHostAndCategoryParams):
    Observable<IDivisionPlanEnginField[]> {
    return this.http.get<IDivisionPlanEnginField[]>
      (`/api/divisions/${params.development_host_key}/plan-engin-fields/${params.category_key}/developmentHost`).pipe(
        catchError(this.sharedService.handleError<IDivisionPlanEnginField[]>('get division plan engin fields by development host', []))
      );
  }

  addOrUpdateEnginFields(command: IAddOrUpdateEnginFieldCommand): Observable<any> {
    return this.http.post<any>
      (`/api/divisions/${command.division_org_number}/plan-engin-fields`, command).pipe(
        map(() => {
          return {
            code: EHttpStatusCode.Ok,
          };
        }),
        catchError(this.sharedService.handleError<any>('add or update plan engin field', undefined))
      );
  }

  updateEnginFieldDisable(command: IUpdateEnginFieldDisableCommand): Observable<any> {
    return this.http.post<any>
      (`/api/divisions/${command.division_org_number}/plan-engin-fields/disable`, command).pipe(
        catchError(this.sharedService.handleError<any>('update plan engin field disable', undefined))
      );
  }

  batchUpdateEnginFields(command: IBatchUpdateEnginFieldCommand): Observable<any> {
    return this.http.post<any>
      (`/api/divisions/${command.division_org_number}/plan-engin-fields/batch`, command).pipe(
        catchError(this.sharedService.handleError<any>('batch update plan engin field', undefined))
      );
  }

  createStandardPlans(
    checkedStandardPlanTemplates: IPlanTemplate[],
    project: IProject,
    currentMilestone: IMilestone,
    parentId: string, // 父计划id,
    parentPlanTargetEndTime?: string) {
    const requests: Observable<IPlan>[] = [];
    _.forEach(checkedStandardPlanTemplates, template => {
      // tslint:disable-next-line:max-line-length
      requests.push(this.planTemplateService.generatePlanFromTemplate(project, currentMilestone, template, parentId, parentPlanTargetEndTime));
    });
    forkJoin(requests).subscribe(plans => {
      this.planTreeStateService.addPlanTreePlans(plans);
    });
  }

  verifyPlans(plans: IPlan[], dirtyPlans: IPlan[], milestones: IMilestone[]) {
    return this.verifyStandardPlansNotDuplicate(plans, milestones) && this.verifyOtherPlansNotDuplicate(plans, milestones)
      && this.verifyTargetEndTime(plans, dirtyPlans, milestones) && this.verifyMakePlan(plans, milestones);

  }
  // 验证标准计划没有重复（code、计划完成时间、责任人）-> 具体到节点、计划
  verifyStandardPlansNotDuplicate(plans: IPlan[], milestones: IMilestone[]): boolean {
    const standardPlans = _.filter(plans, plan => !!plan.code);
    const uniqStandardPlans = _.uniqBy(standardPlans, item => {
      return [item.code, item.target_end_time, item.responsible_person_id].join('_');
    });
    const isDuplicate = standardPlans.length && standardPlans.length !== uniqStandardPlans.length;
    if (isDuplicate) {
      const duplicatePlans = _.difference(standardPlans, uniqStandardPlans);
      // tslint:disable-next-line: no-shadowed-variable
      const milestone = _.filter(milestones, milestone => {
        return milestone.code === duplicatePlans[0].milestone_code;
      });
      const alert: IAlert = {
        type: 'danger',
        title: '标准计划重复:  ' + milestone[0].code + '节点下计划 ' + duplicatePlans[0].name,
        content: ['相同编码的标准计划,责任人和完成时间不能相同']
      };
      this.subscriptionService.publishAlert(alert);
      return false;
    }
    return true;
  }

  // 专项计划/风险计划不能重复（名称、计划完成时间、责任人、目标要求）
  verifyOtherPlansNotDuplicate(plans: IPlan[], milestones: IMilestone[]): boolean {
    const otherPlans = _.filter(plans, plan => !plan.code);
    const uniqPlans = _.uniqBy(otherPlans, item => {
      return [item.name, item.responsible_person_id, item.target_end_time, item.target_requirements].join('_');
    });
    const isDuplicate = otherPlans.length && otherPlans.length !== uniqPlans.length;
    // return !isDuplicate;
    if (isDuplicate) {
      const duplicatePlans = _.difference(otherPlans, uniqPlans);
      // tslint:disable-next-line: no-shadowed-variable
      const milestone: IMilestone[] = _.filter(milestones, milestone => {
        return milestone.code === duplicatePlans[0].milestone_code;
      });
      const alert: IAlert = {
        type: 'danger',
        title: '专项计划/风险计划重复:  ' + milestone[0].code + '节点下计划 ' + duplicatePlans[0].name,
        content: ['名称、计划完成时间、责任人、目标要求不能完全相同']
      };
      this.subscriptionService.publishAlert(alert);
      return false;
    }
    return true;
  }

  // 编制计划（计划完成时间、责任人、责任单位不能为空）
  verifyMakePlan(plans: IPlan[], milestones: IMilestone[]): boolean {
    let flag = true;
    _.forEach(plans, plan => {
      // tslint:disable-next-line: no-shadowed-variable
      const milestone = _.filter(milestones, milestone => {
        return milestone.code === plan.milestone_code;
      });
      if (!(plan.target_end_time && plan.responsible_org_name && plan.responsible_person_name)) {
        const alert: IAlert = {
          type: 'danger',
          title: milestone[0].code + '节点下计划:  ' + plan.name,
          content: ['计划完成时间、责任人、责任单位均不能为空']
        };
        this.subscriptionService.publishAlert(alert);
        flag = false;
      }
    });
    return flag;
  }

  // 1. 计划完成时间不能晚于当前计划所属节点的节点完成时间 2. 不能早于当前时间（只验证dirtyPlan） 3.子计划的完成时间不能早于父计划的完成时间 ->具体到节点
  verifyTargetEndTime(plans: IPlan[], dirtyPlans: IPlan[], milestones: IMilestone[]) {
    // tslint:disable-next-line: max-line-length
    return this.verifyTargetEndTimeCompareCurrent(dirtyPlans, milestones) && this.verifyTargetEndTimeCompareMilestone(dirtyPlans, milestones) && this.verifyTargetEndTimeCompareChild(plans, milestones);

  }
  verifyTargetEndTimeCompareMilestone(plans: IPlan[], milestones: IMilestone[]): boolean {
    let flag = true;
    _.forEach(plans, plan => {
      // tslint:disable-next-line: no-shadowed-variable
      const milestone = _.filter(milestones, milestone => {
        return milestone.code === plan.milestone_code;
      });
      const milestoneTargetEndTime = milestone[0].target_end_time;
      // 比较计划时间与节点完成时间
      if (moment(plan.target_end_time).isAfter(milestoneTargetEndTime)) {
        const alert: IAlert = {
          type: 'danger',
          title: milestone[0].code + '节点下计划:  ' + plan.name,
          content: ['计划完成时间不能超出节点完成时间']
        };
        this.subscriptionService.publishAlert(alert);
        flag = false;
      }
    });
    return flag;
  }

  verifyTargetEndTimeCompareCurrent(plans: IPlan[], milestones: IMilestone[]) {
    let flag = true;
    _.forEach(plans, plan => {
      // tslint:disable-next-line: no-shadowed-variable
      const milestone = _.filter(milestones, milestone => {
        return milestone.code === plan.milestone_code;
      });
      // 计划完成时间可以是今天
      if (moment(plan.target_end_time).isBefore(moment(), 'day')) {
        const alert: IAlert = {
          type: 'danger',
          title: milestone[0].code + '节点下计划:  ' + plan.name,
          content: ['计划完成时间不能早于当前时间']
        };
        this.subscriptionService.publishAlert(alert);
        flag = false;
      }
    });
    return flag;
  }

  verifyTargetEndTimeCompareChild(plans: IPlan[], milestones: IMilestone[]): boolean {
    let flag = true;
    _.forEach(plans, plan => {
      // tslint:disable-next-line: no-shadowed-variable
      const milestone = _.filter(milestones, milestone => {
        return milestone.code === plan.milestone_code;
      });
      if (plan.parent_id) {
        // tslint:disable-next-line: no-shadowed-variable
        const parentPlan = _.find(plans, parentPlan => {
          return parentPlan.id === plan.parent_id;
        });
        if (moment(plan.target_end_time).isAfter(parentPlan.target_end_time)) {
          const alert: IAlert = {
            type: 'danger',
            title: milestone[0].code + '节点下子计划:  ' + plan.name,
            content: ['子计划完成时间不能晚于父计划完成时间']
          };
          this.subscriptionService.publishAlert(alert);
          flag = false;
        }
      }
    });
    return flag;
  }


  // 将计划按 节点、计划类型、时间 进行排序
  sortPlansSync(plans: IPlan[], milestones: IMilestone[]): IPlan[] {
    let sortedPlans = this.sharedService.sortItemsSync(plans, 'target_end_time');
    // sortedPlans = this.sharedService.sortItemsSync(
    //   sortedPlans,
    //   'plan_type',
    //   [EPlanType.Milestone, EPlanType.Standard, EPlanType.Spec, EPlanType.Risk, EPlanType.ProcessSchedule]
    // );
    sortedPlans = this.sharedService.sortItemsSync(
      sortedPlans,
      'milestone_code',
      _.map(milestones, 'code')
    );
    return sortedPlans;
  }

  // 系统管理员删除计划
  systemAdminDeletePlan(planId: string): Observable<any> {
    return this.http.put<any>(`/api/audits/admins/drop/plan/${planId}`, undefined).pipe(
      catchError(this.sharedService.handleError<any>(`system admin delete plan by id: ${planId}`, false))
    );
  }

  // 项目计划列表查询
  getSearchedPlans(params: IPlanSearchParams): Observable<ILevelTreeItem[]> {
    return this.http.get<ILevelTreeItem[]>(`/api/business/projects/plans-search`, { params: params as unknown as HttpParams }).pipe(
      catchError(this.sharedService.handleError<ILevelTreeItem[]>('', []))
    );
  }

  // 项目计划列表查询选项
  getPlansSearchOptions(projectCode: string) {
    const params = { projectCode };
    // tslint:disable-next-line:max-line-length
    return this.http.get<IPlanSearchOption[]>(`/api/business/projects/plans-search-options`, { params: params as unknown as HttpParams }).pipe(
      catchError(this.sharedService.handleError<IPlanSearchOption[]>('', []))
    );
  }

  getPlanTypesSync(): Observable<ICommonConfig[]> {
    return of(this.planTypes);
  }

  getLightTypesSync(): Observable<ICommonConfig[]> {
    return of(this.lightTypes);
  }

  getPlanFilterStatusSync(): Observable<ICommonConfig[]> {
    return of(this.planFilterStatue);
  }

  // 保密计划授权列表
  getSecretPlanPermissionsList(params: ISecretPlanPermissionParams): Observable<ISecretPlanPermissionsItem[]> {
    return this.http.get<ISecretPlanPermissionsItem[]>(`/api/business/secret-plan/permissions`, { params: params as unknown as HttpParams }).pipe(
      catchError(this.sharedService.handleError<ISecretPlanPermissionsItem[]>('', []))
    );
  }

  // 保密计划授权列表
  getSecretPlanPermissionsListCount(params: ISecretPlanPermissionParams): Observable<number> {
    return this.http.get<number>(`/api/business/secret-plan/permissions-count`, { params: params as unknown as HttpParams }).pipe(
      catchError(this.sharedService.handleError<number>('', 0))
    );
  }

  // 新增保密计划授权
  addSecretPlanPermissions(command: ICreateSecretPlanPermissionsCommand): Observable<void> {
    return this.http.post<void>(`/api/business/secret-plan/permissions`, command).pipe(
      catchError(this.sharedService.handleError<void>('', undefined))
    );
  }

  // 修改保密计划授权
  updateSecretPlanPermission(command: IUpdateSecretPlanPermissionsCommand): Observable<void> {
    return this.http.put<void>(`/api/business/secret-plan/permissions/${command.id}`, command).pipe(
      catchError(this.sharedService.handleError<void>('', undefined))
    );
  }

  // 删除保密计划授权
  removeSecretPlanPermission(id: number): Observable<void> {
    return this.http.delete<void>(`/api/business/secret-plan/permissions/${id}`).pipe(
      catchError(this.sharedService.handleError<void>('', undefined))
    );
  }

}
