<ng-template #turn>
  <form [formGroup]="myForm">
    <div class="modal-header">
      <h5 class="modal-title" *ngIf="!flow">转办</h5>
      <h5 class="modal-title" *ngIf="flow">移交</h5>
      <button type="button" class="close" (click)="hide()">
        <i class="fa fa-times"></i>
      </button>
    </div>
    <div class="modal-body">
      <table class="table-sm table-bordered text-center align-middle table-fixed">
        <tbody>
          <!-- #region 审批流程转办需要传给后端节点&节点下的转办人-->
          <tr *ngIf="flow">
            <td style="width: 110px;">
              <i class="fa fa-asterisk text-danger mr-1"></i>移交人
            </td>
            <td>
              <div class="overflow">
                <div class="d-flex align-items-center" [ngStyle]="{'width.px': getWidth()}">
                  <div *ngFor="let step of getSteps(); let i=index;" class="workflow-step"
                    [ngClass]="{done: flow.current > i}">
                    <div class="box">
                      <div class="title" style="margin-bottom: 2px;">
                        <span>{{step.name}}</span>
                      </div>
                      <div class="content">
                        <ul *ngIf="step.persons.length" class="persons">
                          <li *ngFor="let person of step.persons">
                            <input *ngIf="flow.current === i" type="radio" class="mr-1" id="{{person.name}}{{i}}"
                              name="radio" [disabled]="person.status === workflowStepStatus.Approval"
                              (click)="checkPerson(i, person.user_id)">
                            <span *ngIf="flow.current !== i"></span>
                            <label for="{{person.name}}{{i}}">{{person.name}}</label>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="arrow">
                      <i class="fa fa-long-arrow-right"></i>
                    </div>
                  </div>
                  <div class="workflow-step">
                    <div class="box endBox mr-1">
                      <div class="title endStepTitle">
                        结束
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
          <!-- #endregion -->
          <tr>
            <td style="width: 110px;">
              <i class="fa fa-asterisk text-danger mr-1"></i>接收人
            </td>
            <td>
              <input type="text" class="form-control" [value]="turnPerson&&turnPerson.name || ''" readonly
                (click)="toCheckTurnPerson()">
            </td>
          </tr>
          <tr>
            <td>
              转办意见
            </td>
            <td>
              <textarea class="form-control" rows="3" formControlName="remark"></textarea>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary mr-2" (click)="confirm()"
        [disabled]="((flow && (!userId || !turnPerson)) || (!flow && !turnPerson))">
        确定
      </button>
      <button type="button" class="btn btn-outline-secondary" (click)="hide()">
        返回
      </button>
    </div>
  </form>
</ng-template>

<app-check-group-admin #appCheckGroupAdmin (confirmChange)="checkTurnPerson($event)"></app-check-group-admin>
