// 绩效单子类型
export enum EPerformanceBillType {
    /// <summary>
    /// alpha单
    /// </summary>
    Alpha = 0,
    /// <summary>
    /// 主观评价单
    /// </summary>
    Beta = 1,
    /// <summary>
    /// gamma单
    /// </summary>
    Gamma = 2
}
