// 打分参考
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { SharedService } from '../services/shared.service';

@Injectable({
    providedIn: 'root',
})
// tslint:disable-next-line:class-name
export class ScoreReferenceGuard implements CanActivate {
    constructor(
        private router: Router,
        private userService: UserService,
        private sharedService: SharedService
    ) { }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        // 系统管理员
        if (this.userService.getCurrentIsSystemAdminSync()) {
            return true;
        } else {
            this.sharedService.setRedirectUrl(window.location.href);
            this.router.navigate(['/work-center']);
            return false;
        }
    }
}
