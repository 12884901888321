import { Reducer, Action } from 'redux';
import {
  SetAttachmentsItemsAction,
  // SetAttachmentsEditEnableAction,
  SetAttachmentsDirtyAction,
  AddAttachmentsItemAction,
  DeleteAttachmentsItemAction,
  SET_ATTACHMENTS_ITEMS,
  // SET_ATTACHMENTS_EDIT_ENABLE,
  SET_ATTACHMENTS_DIRTY,
  ADD_ATTACHMENTS_ITEM,
  DELETE_ATTACHMENTS_ITEM
} from '../actions/attachments.actions';
import { createSelector } from 'reselect';
import {
  IFileUploadInfo
} from 'src/app/models';
import _ from 'lodash';

export interface AttachmentsState {
  items: IFileUploadInfo[];
  // editEnable: boolean;
  dirty: boolean;
}

const initialState: AttachmentsState = {
  items: [],
  // editEnable: false,
  dirty: false
};

export const AttachmentsReducer: Reducer<AttachmentsState> =
  (state: AttachmentsState = initialState, action: Action): AttachmentsState => {
    let items: IFileUploadInfo[] = [];
    let item: IFileUploadInfo;
    // let editEnable: boolean;

    switch (action.type) {
      case SET_ATTACHMENTS_ITEMS:
        items = (action as SetAttachmentsItemsAction).items;
        return Object.assign({}, state, {
          items
        });

      // case SET_ATTACHMENTS_EDIT_ENABLE:
      //   editEnable = (action as SetAttachmentsEditEnableAction).editEnable;
      //   return Object.assign({}, state, {
      //     editEnable
      //   });

      case SET_ATTACHMENTS_DIRTY:
        const dirty = (action as SetAttachmentsDirtyAction).dirty;
        return Object.assign({}, state, {
          dirty
        });

      case ADD_ATTACHMENTS_ITEM:
        items = state.items;
        item = (action as AddAttachmentsItemAction).item;
        item._is_new = true;
        return Object.assign({}, state, {
          items: [...items, item],
          dirty: true
        });

      case DELETE_ATTACHMENTS_ITEM:
        items = state.items;
        item = (action as DeleteAttachmentsItemAction).item;
        return Object.assign({}, state, {
          items: _.reject(items, { url_path: item.url_path }),
          dirty: true
        });

      default:
        return state;
    }
  };

const getState = (state: AttachmentsState): AttachmentsState => state;

export const getAttachmentsItems = createSelector(
  getState,
  (state: AttachmentsState) => state.items
);

// export const getAttachmentsEditEnable = createSelector(
//   getState,
//   (state: AttachmentsState) => state.editEnable
// );

export const getAttachmentsDirty = createSelector(
  getState,
  (state: AttachmentsState) => state.dirty
);
