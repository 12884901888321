import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateFormatShort'
})
export class DateFormatShortPipe implements PipeTransform {

  transform(value: Date, ...args: any[]): string {
    if (!value) {
      return '';
    }
    const date = 'object' === typeof value ? value : ('' + value).substr(0, 19);
    return moment(date, 'YYYY-MM-DD').format('YYYY-MM-DD');
  }

}
