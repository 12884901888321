// *需求
import { Action, ActionCreator } from 'redux';
import { IRequirementData, IRequirementInitialAnalysisDto, IRequirementInitialBasicInfoDto } from 'src/app/models';

// #region Set
export interface SetRequirementDataAction extends Action {
  requirementData: IRequirementData;
}
export const SET_REQUIREMENT_DATA = 'SET_REQUIREMENT_DATA';
export const setRequirementData: ActionCreator<SetRequirementDataAction> = (requirementData) => ({
  type: SET_REQUIREMENT_DATA,
  requirementData
});
// #endregion

// #region 更新需求初始基本信息
export interface UpdateRequirementInitialBasicInfoDtoAction extends Action {
  initialBasicInfoDto: IRequirementInitialBasicInfoDto;
}
export const UPDATE_REQUIREMENT_INITIAL_BASIC_INFO_DTO = 'UPDATE_REQUIREMENT_INITIAL_BASIC_INFO_DTO';
export const updateRequirementInitialBasicInfoDto: ActionCreator<UpdateRequirementInitialBasicInfoDtoAction> = (initialBasicInfoDto) => ({
  type: UPDATE_REQUIREMENT_INITIAL_BASIC_INFO_DTO,
  initialBasicInfoDto
});
// #endregion

// #region 设置 需求初始基本信息Dirty
export interface SetRequirementInitialBasicInfoDirtyAction extends Action {
  dirty: boolean;
}
export const SET_REQUIREMENT_INITIAL_BASIC_INFO_DIRTY = 'SET_REQUIREMENT_INITIAL_BASIC_INFO_DIRTY';
export const setRequirementInitialBasicInfoDirty: ActionCreator<SetRequirementInitialBasicInfoDirtyAction> = (dirty: boolean) => ({
  type: SET_REQUIREMENT_INITIAL_BASIC_INFO_DIRTY,
  dirty
});
// #endregion

// #region 更新需求初始需求
export interface UpdateRequirementInitialAnalysisDtoAction extends Action {
  initialAnalysisDto: IRequirementInitialAnalysisDto;
}
export const UPDATE_REQUIREMENT_INITIAL_ANALYSIS_DTO = 'UPDATE_REQUIREMENT_INITIAL_ANALYSIS_DTO';
export const updateRequirementInitialAnalysisDto: ActionCreator<UpdateRequirementInitialAnalysisDtoAction> = (initialAnalysisDto) => ({
  type: UPDATE_REQUIREMENT_INITIAL_ANALYSIS_DTO,
  initialAnalysisDto
});
// #endregion


// #region 设置 需求初始需求Dirty
export interface SetRequirementInitialAnalysisDirtyAction extends Action {
  dirty: boolean;
}
export const SET_REQUIREMENT_INITIAL_ANALYSIS_DIRTY = 'SET_REQUIREMENT_INITIAL_ANALYSIS_DIRTY';
export const setRequirementInitialAnalysisDirty: ActionCreator<SetRequirementInitialAnalysisDirtyAction> = (dirty: boolean) => ({
  type: SET_REQUIREMENT_INITIAL_ANALYSIS_DIRTY,
  dirty
});
// #endregion

// #region 重置
export interface ResetRequirementAction extends Action {
}
export const RESET_REQUIREMENT = 'RESET_REQUIREMENT';
export const resetRequirement: ActionCreator<ResetRequirementAction> = () => ({
  type: RESET_REQUIREMENT
});
// #endregion
