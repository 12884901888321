// 鉴权
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { SystemService } from '../services/system.service';
import { IUserInfo } from 'src/app/models';

@Injectable()
export class AuthProvider {
    currentUser: IUserInfo;

    constructor(
        private http: HttpClient,
        private systemService: SystemService
    ) {
    }

    loadAuthPromise() {
        return this.http.get<IUserInfo>(`/api/users/current`).pipe(
            map(userInfo => {
                this.currentUser = userInfo; // 获取到当前用户信息，记录到provider中
                sessionStorage.setItem('currentUser', JSON.stringify(userInfo));
                return userInfo;
            }),
            catchError(this.handleError<IUserInfo[]>('get current user', undefined))
        ).toPromise();  // 转promise
    }

    handleError<T>(operation = 'operation', result?: T) {
        return (error: HttpErrorResponse): Observable<T> => {
            switch (error.status) {
                case 401: // Unauthorized
                    const redirectUrl = window.location.href;
                    const ssoLoginUrl = this.systemService.getSsoLoginUrlSync();
                    // 单点等陆
                    window.location.href = `${ssoLoginUrl}?return_url=${encodeURIComponent(redirectUrl)}`;
                    throw new Error('Unauthorized'); // 页面跳转为异步，在此抛出异常，阻塞后面代码执行
                    break;
                default:
            }
            return of(result as T);
        };
    }

}
