import { Action, ActionCreator } from 'redux';
import { IFileUploadInfo } from 'src/app/models';

// #region 设置附件列表
export interface SetAttachmentsItemsAction extends Action {
  items: IFileUploadInfo[];
}
export const SET_ATTACHMENTS_ITEMS = 'SET_ATTACHMENTS_ITEMS';
export const setAttachmentsItems: ActionCreator<SetAttachmentsItemsAction> = (items: IFileUploadInfo[]) => ({
  type: SET_ATTACHMENTS_ITEMS,
  items
});
// #endregion

// #region 设置附件可编辑状态
// export interface SetAttachmentsEditEnableAction extends Action {
//   editEnable: boolean;
// }
// export const SET_ATTACHMENTS_EDIT_ENABLE = 'SET_ATTACHMENTS_EDIT_ENABLE';
// export const setAttachmentsEditEnable: ActionCreator<SetAttachmentsEditEnableAction> = (editEnable: boolean) => ({
//   type: SET_ATTACHMENTS_EDIT_ENABLE,
//   editEnable
// });
// #endregion

// #region 设置附件dirty
export interface SetAttachmentsDirtyAction extends Action {
  dirty: boolean;
}
export const SET_ATTACHMENTS_DIRTY = 'SET_ATTACHMENTS_DIRTY';
export const setAttachmentsDirty: ActionCreator<SetAttachmentsDirtyAction> = (dirty: boolean) => ({
  type: SET_ATTACHMENTS_DIRTY,
  dirty
});
// #endregion

// #region 增加附件
export interface AddAttachmentsItemAction extends Action {
  item: IFileUploadInfo;
}
export const ADD_ATTACHMENTS_ITEM = 'ADD_ATTACHMENTS_ITEM';
export const addAttachmentsItem: ActionCreator<AddAttachmentsItemAction> = (item: IFileUploadInfo) => ({
  type: ADD_ATTACHMENTS_ITEM,
  item
});
// #endregion

// #region 删除附件
export interface DeleteAttachmentsItemAction extends Action {
  item: IFileUploadInfo;
}
export const DELETE_ATTACHMENTS_ITEM = 'DELETE_ATTACHMENTS_ITEM';
export const deleteAttachmentsItem: ActionCreator<DeleteAttachmentsItemAction> = (item: IFileUploadInfo) => ({
  type: DELETE_ATTACHMENTS_ITEM,
  item
});
// #endregion

