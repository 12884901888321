import { Injectable } from '@angular/core';
import _ from 'lodash';

import { EOrganizationChartItemOperationType } from '../enums';
import { IMultifunctionalTeam, IOrganizationTeamItem } from '../models/IOrganizationChartItem';

@Injectable({
  providedIn: 'root'
})
export class OrganizationTransformService {

  constructor() { }
  // 维护团队modal，organizationTeam增删时，更新其operation
  organizationTeamUpdateOperation(
    organizationTeams: IOrganizationTeamItem[],
    organizationTeam: IOrganizationTeamItem,
    addOrDelete: 'add' | 'delete'
  ): void {
    switch (addOrDelete) {
      case 'add':
        switch (organizationTeam.operation) {
          case EOrganizationChartItemOperationType.Delete:
            organizationTeam.operation = EOrganizationChartItemOperationType.InUsing;
            break;
        }
        break;
      case 'delete':
        switch (organizationTeam.operation) {
          case EOrganizationChartItemOperationType.InUsing:
            organizationTeam.operation = EOrganizationChartItemOperationType.Delete;
            break;
          case EOrganizationChartItemOperationType.Add:
            _.remove(organizationTeams, organizationTeam);
            break;
          case EOrganizationChartItemOperationType.Delete:
            break;
          case EOrganizationChartItemOperationType.Update:
            organizationTeam.operation = EOrganizationChartItemOperationType.Delete;
            break;
          default:
            organizationTeam.operation = EOrganizationChartItemOperationType.Delete;
        }
        break;
    }
  }

  // 工程师节点，增删人时，更新其operation
  engineerNodeUpdateOperation(teamItem: IOrganizationTeamItem, addOrDeletePerson: 'addPerson' | 'deletePerson'): void {
    switch (addOrDeletePerson) {
      case 'addPerson':
        switch (teamItem.operation) {
          case EOrganizationChartItemOperationType.InUsing:
            teamItem.operation = EOrganizationChartItemOperationType.Add;
            break;
          case EOrganizationChartItemOperationType.Add:
            break;
          case EOrganizationChartItemOperationType.Delete:
            teamItem.operation = EOrganizationChartItemOperationType.Update;
            break;
          case EOrganizationChartItemOperationType.Update:
            break;
        }
        break;
      case 'deletePerson':
        switch (teamItem.operation) {
          case EOrganizationChartItemOperationType.InUsing:
            teamItem.operation = EOrganizationChartItemOperationType.Add;
            break;
          case EOrganizationChartItemOperationType.Add:
            break;
          case EOrganizationChartItemOperationType.Delete:
            teamItem.operation = EOrganizationChartItemOperationType.Update;
            break;
          case EOrganizationChartItemOperationType.Update:
            break;
        }
        break;
    }
  }

  // 多功能小组增删组员时，更新其operation
  multifunctionalTeamUpdateOperation(multifunctionalTeam: IMultifunctionalTeam, addOrDeletePerson: 'addPerson' | 'deletePerson', isBeforeHadPersons?: boolean): void {
    switch (addOrDeletePerson) {
      case 'addPerson':
        switch (multifunctionalTeam.operation) {
          case EOrganizationChartItemOperationType.InUsing:
            if (!isBeforeHadPersons) {
              multifunctionalTeam.operation = EOrganizationChartItemOperationType.Add;
            } else {
              multifunctionalTeam.operation = EOrganizationChartItemOperationType.Update;
            }
            break;
          case EOrganizationChartItemOperationType.Add:
            break;
          case EOrganizationChartItemOperationType.Delete:
            multifunctionalTeam.operation = EOrganizationChartItemOperationType.Update;
            break;
          case EOrganizationChartItemOperationType.Update:
            break;
        }
        break;
      case 'deletePerson':
        switch (multifunctionalTeam.operation) {
          case EOrganizationChartItemOperationType.InUsing:
            if (!multifunctionalTeam.persons.length) {
              multifunctionalTeam.operation = EOrganizationChartItemOperationType.Delete;
            } else {
              multifunctionalTeam.operation = EOrganizationChartItemOperationType.Update;
            }
            break;
          case EOrganizationChartItemOperationType.Add:
            if (!multifunctionalTeam.persons.length) {
              multifunctionalTeam.operation = EOrganizationChartItemOperationType.InUsing;
            }
            break;
          case EOrganizationChartItemOperationType.Delete:
            break;
          case EOrganizationChartItemOperationType.Update:
            if (!multifunctionalTeam.persons.length) {
              multifunctionalTeam.operation = EOrganizationChartItemOperationType.Delete;
            }
            break;
        }
        break;
    }
  }

  // 工程师转组织节点时，分配工程师节点的operation
  transformEngineerNodeOperation(
    normalOperation: EOrganizationChartItemOperationType, // 正常组织节点的operation
    oldOperation: EOrganizationChartItemOperationType // 工程师节点原operation
  ): EOrganizationChartItemOperationType {
    let operation: EOrganizationChartItemOperationType;
    switch (normalOperation) {
      case EOrganizationChartItemOperationType.InUsing:
        operation = oldOperation;
        break;
      case EOrganizationChartItemOperationType.Add:
        operation = EOrganizationChartItemOperationType.Add;
        break;
      case EOrganizationChartItemOperationType.Delete:
        operation = EOrganizationChartItemOperationType.Delete;
        break;
      case EOrganizationChartItemOperationType.Update:
        operation = oldOperation;
        break;
    }
    return operation;
  }

  // 多功能小组转组织节点时，分配多功能小组节点的operation
  transformMultifunctionalTeamNodeOperation(
    itemOperation: EOrganizationChartItemOperationType, // 标准节点的operation
    engineerNodeOperation: EOrganizationChartItemOperationType, // 工程师节点的operation
    oldOperation: EOrganizationChartItemOperationType // 多功能小组原operation
  ): EOrganizationChartItemOperationType {
    let operation: EOrganizationChartItemOperationType;
    switch (itemOperation) {
      case EOrganizationChartItemOperationType.Add:
        operation = EOrganizationChartItemOperationType.Add;
        break;
      case EOrganizationChartItemOperationType.Delete:
        operation = EOrganizationChartItemOperationType.Delete;
        break;
      case EOrganizationChartItemOperationType.InUsing:
      case EOrganizationChartItemOperationType.Update:
        switch (engineerNodeOperation) {
          case EOrganizationChartItemOperationType.InUsing:
            operation = oldOperation;
            break;
          case EOrganizationChartItemOperationType.Add:
            operation = EOrganizationChartItemOperationType.Add;
            break;
          case EOrganizationChartItemOperationType.Delete:
            operation = EOrganizationChartItemOperationType.Delete;
            break;
          case EOrganizationChartItemOperationType.Update:
            operation = oldOperation;
            break;
        }
        break;
    }
    return operation;
  }

}
