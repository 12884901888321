<ng-template class="modal" #achievementTarget>
  <form class="ez-page">
    <div class="ez-page-header">
      <div class="page-title">成果目标</div>
      <div class="page-operations">
        <a *ngIf="showMigrationTargetLink && migrationTarget" class="link mr-2" (click)="viewMigrationTarget()">查看迁移目标详情</a>
        <button
          type="button"
          class="btn btn-outline-secondary"
          (click)="hide()"
        >
          <i class="fa fa-chevron-left mr-1"></i>返回
        </button>
      </div>
    </div>

    <div class="ez-page-body">
      <table
        class="table-sm table-bordered table-fixed align-middle text-center"
      >
        <thead>
          <tr>
            <th style="width: 70px">序号</th>
            <th><i class="fa fa-asterisk text-danger mr-1"></i>技术成果名称</th>
            <th><i class="fa fa-asterisk text-danger mr-1"></i>所属分类</th>
            <th>成果交付物内容</th>
            <th style="width: 250px"><i class="fa fa-asterisk text-danger mr-1"></i>技术成果描述</th>
            <th>定级建议</th>
            <th style="width: 180px">流水编码</th>
            <th style="width: 90px">是否废弃</th>
            <th style="width: 120px">成果详情页面</th>
            <th *ngIf="editEnable" style="width: 90px">操作</th>
          </tr>
        </thead>
        <tbody>
          <tr
            app-achievement-target-item
            *ngFor="let item of items; let i = index;"
            [(item)]="items[i]"
            [editEnable]="editEnable"
            [index]="i"
            [types]="types"
            [contents]="contents"
            [suggestions]="suggestions"
            [projectCode]="projectCode"
            (deleteItem)="deleteItem($event)"
            (valid)="validChange()"
          ></tr>
        </tbody>
      </table>
      <button *ngIf="editEnable" class="btn btn-primary my-2" (click)="add()">
        <i class="fa fa-plus mr-1"></i>新增成果目标
      </button>
    </div>
    <div class="ez-page-footer">
      <button
        *ngIf="editEnable"
        type="button"
        class="btn btn-primary mr-2"
        (click)="save()"
      >
        <i class="fa fa-save mr-1"></i>暂存
      </button>
      <button
        *ngIf="editEnable"
        [disabled]="!valid"
        type="button"
        class="btn btn-primary mr-2"
        (click)="confirm()"
      >
        <i class="fa fa-paper-plane mr-1"></i>提交
      </button>
      <button type="button" class="btn btn-outline-secondary" (click)="hide()">
        <i class="fa fa-chevron-left mr-1"></i>返回
      </button>
    </div>
  </form>
</ng-template>
