import { Reducer, Action } from 'redux';
import {
  SetCommonIsFullscreenAction,
  SetCommonIsBusyAction,
  SetCommonIsProjectCenterAction,
  SetCommonProjectAction,
  SetCommonParentPlanEnginFieldKeyAction,
  SET_COMMON_IS_FULLSCREEN,
  SET_COMMON_IS_BUSY,
  SET_COMMON_IS_PROJECT_CENTER,
  SET_COMMON_PROJECT,
  SET_COMMON_PARENT_PLAN_ENGIN_FIELD_KEY


} from '../actions/common.actions';
import { createSelector } from 'reselect';
import _ from 'lodash';
import { IProject } from 'src/app/models';

export interface CommonState {
  isFullscreen: boolean;  // 全屏
  isBusy: boolean;   // 忙碌状态（按钮应disabled）
  isProjectCenter: boolean; // 是否处于项目中心
  project: IProject;
  parentPlanEnginFieldKey: string; // 父计划的所属业务编码
}

const initialState: CommonState = {
  isFullscreen: false,
  isBusy: false,
  isProjectCenter: false,
  project: undefined,
  parentPlanEnginFieldKey: ''
};

export const CommonReducer: Reducer<CommonState> =
  (state: CommonState = initialState, action: Action): CommonState => {
    switch (action.type) {
      case SET_COMMON_IS_FULLSCREEN:
        const isFullscreen = (action as SetCommonIsFullscreenAction).isFullscreen;
        return Object.assign({}, state, { isFullscreen });
      case SET_COMMON_IS_BUSY:
        const isBusy = (action as SetCommonIsBusyAction).isBusy;
        return Object.assign({}, state, { isBusy });
      case SET_COMMON_IS_PROJECT_CENTER:
        const isProjectCenter = (action as SetCommonIsProjectCenterAction).isProjectCenter;
        return Object.assign({}, state, { isProjectCenter });
      case SET_COMMON_PROJECT:
        const project = (action as SetCommonProjectAction).project;
        return Object.assign({}, state, { project });
      case SET_COMMON_PARENT_PLAN_ENGIN_FIELD_KEY:
        const parentPlanEnginFieldKey = (action as SetCommonParentPlanEnginFieldKeyAction).parentPlanEnginFieldKey;
        return Object.assign({}, state, { parentPlanEnginFieldKey });
      default:
        return state;
    }
  };

const getState = (state: CommonState): CommonState => state;
export const getCommonIsBusy = createSelector(
  getState,
  (state: CommonState) => state.isBusy
);

export const getCommonIsFullscreen = createSelector(
  getState,
  (state: CommonState) => state.isFullscreen
);

export const getCommonIsProjectCenter = createSelector(
  getState,
  (state: CommonState) => state.isProjectCenter
);

export const getCommonProject = createSelector(
  getState,
  (state: CommonState) => state.project
);

export const getCommonParentPlanEnginFieldKey = createSelector(
  getState,
  (state: CommonState) => state.parentPlanEnginFieldKey
);

