import {
  Component,
  TemplateRef,
  ViewChild,
  Inject
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import _ from 'lodash';

import { CRadioBase } from 'src/app/classes';
import { PAGE_SIZE, PAGE_LINK_LIMIT } from 'src/app/consts';
import {
  IUserInfo, IUserInfoParams, IPagination, IAlphaAdminParams, IGammaAdminParams, IOrganizationUserParams, IKpiApprovalAdminParams
} from 'src/app/models';
import { ReduxService } from 'src/app/redux/redux.service';
import { UserService } from 'src/app/services/user.service';
import { SharedService } from 'src/app/services/shared.service';
import { ECheckUsersType } from 'src/app/enums';
import { ProjectRoleService } from '../../permission/services/project-role.service';

@Component({
  selector: 'app-check-user',
  templateUrl: './check-user.component.html',
  styleUrls: ['./check-user.component.scss']
})
export class CheckUserComponent extends CRadioBase<IUserInfo, IUserInfoParams> {
  modalRef: BsModalRef;
  @ViewChild('checkUser') modalEle: TemplateRef<any>;
  isLately: boolean;

  filterForm: FormGroup;
  checkUsersType: ECheckUsersType;
  auditId: string; // α和γ管理员列表params为auditId;
  orgNumbers: string[]; // 组织人员列表params为orgNumbers
  checkUsersTypeEnum = ECheckUsersType;

  page = 1;
  limit = PAGE_SIZE;
  pageLinkLimit = PAGE_LINK_LIMIT;
  count = 0;

  constructor(
    private modalService: BsModalService,
    private reduxService: ReduxService,
    private sharedService: SharedService,
    private projectRoleService: ProjectRoleService,

    @Inject(UserService) private userService: UserService
  ) {
    super(userService, 'getUsers', 'user_id');
    this.reduxService.subscribeStore(() => this.readState());

    this.filterForm = this.fb.group({
      q: ['']
    });
  }

  readState() {
    const isShow = this.reduxService.getCheckUsersShow();
    const isMultiple = this.reduxService.getCheckUsersMultiple();
    this.checkUsersType = this.reduxService.getCheckUsersCheckUsersType();
    this.orgNumbers = this.reduxService.getCheckUsersParams() as string[];
    this.auditId = this.reduxService.getCheckUsersParams() as string;
    if (isMultiple) {
      return;
    }

    if (isShow) {
      this.show();
    } else {
      this.hide();
    }
  }

  getUsers() {
    this.userService.getLatelyUsers().subscribe((users) => {
      this.items = users;
      this.isLately = true;
      if (!users.length) {
        this.search();
      }
    });
  }

  getItemsCount() {
    switch (this.checkUsersType) {
      case ECheckUsersType.KpiAlphaAdmin:
        this.projectRoleService.getAlphaAdminsCount(this.queryParams as IAlphaAdminParams).subscribe(count => {
          this.count = count;
          this.page = this.queryParams.page;
        });
        break;
      case ECheckUsersType.KpiGammaAdmin:
        this.projectRoleService.getGammaAdminsCount(this.queryParams as IGammaAdminParams).subscribe(count => {
          this.count = count;
          this.page = this.queryParams.page;
        });
        break;
      case ECheckUsersType.OrganizationUsers:
        this.projectRoleService.getOrganizationUsersCount(this.queryParams as IOrganizationUserParams).subscribe(count => {
          this.count = count;
          this.page = this.queryParams.page;
        });
        break;
      case ECheckUsersType.PerformanceApprovalAdmin:
        this.projectRoleService.getKpiApprovalAdminsCount(this.queryParams as IKpiApprovalAdminParams).subscribe(count => {
          this.count = count;
          this.page = this.queryParams.page;
        });
        break;
      case ECheckUsersType.All:
      default:
        this.userService.getUsersCount(this.queryParams).subscribe(count => {
          this.count = count;
          this.page = this.queryParams.page;
        });
    }
  }

  search() {
    this.page = 1;
    const params = Object.assign(this.queryParams, { page: this.page }, this.filterForm.value);
    this.queryParams = this.sharedService.omitEmptyParams(params);
    this.isLately = false;
    super.getItems();
    this.getItemsCount();
  }

  onPageChanged(pagination: IPagination) {
    this.queryParams.page = pagination.page;
    this.queryParams.limit = pagination.itemsPerPage;
    super.getItems();
    this.getItemsCount();
  }

  show() {
    if (!this.modalRef) {
      this.isLately = false;
      switch (this.checkUsersType) {
        case ECheckUsersType.KpiAlphaAdmin:
          this.queryParams = {
            page: 1,
            limit: 10,
            audit_id: this.auditId
          } as IAlphaAdminParams;
          super.updateConstructor(this.projectRoleService, 'getAlphaAdmins', 'user_id');
          super.getItems();
          this.getItemsCount();
          break;
        case ECheckUsersType.KpiGammaAdmin:
          this.queryParams = {
            page: 1,
            limit: 10,
            audit_id: this.auditId
          } as IGammaAdminParams;
          super.updateConstructor(this.projectRoleService, 'getGammaAdmins', 'user_id');
          super.getItems();
          this.getItemsCount();
          break;
        case ECheckUsersType.OrganizationUsers:
          this.queryParams = {
            page: 1,
            limit: 10,
            org_numbers: this.orgNumbers
          } as IOrganizationUserParams;
          super.updateConstructor(this.projectRoleService, 'getOrganizationUsers', 'user_id');
          super.getItems();
          this.getItemsCount();
          break;
        case ECheckUsersType.PerformanceApprovalAdmin:
          const currentUser = this.userService.getCurrentUserSync();
          this.queryParams = {
            page: 1,
            limit: 10,
            user_id: currentUser.user_id
          } as IKpiApprovalAdminParams;
          super.updateConstructor(this.projectRoleService, 'getKpiApprovalAdmins', 'user_id');
          super.getItems();
          this.getItemsCount();
          break;
        case ECheckUsersType.All:
        default:
          super.updateConstructor(this.userService, 'getUsers', 'user_id');
          this.getUsers();
      }
      this.modalRef = this.modalService.show(this.modalEle, { class: 'modal-lg', ignoreBackdropClick: true });
    }
  }

  hide() {
    if (this.modalRef) {
      this.modalRef.hide();
      this.modalRef = undefined;
      this.reduxService.cancelCheckUsers();
      super.clear();
      this.filterForm.controls.q.setValue('');
    }
  }

  confirm() {
    const checkedUser = super.getCheckedItem();
    this.userService.addLatelyUser(checkedUser.user_id).subscribe();
    this.reduxService.setCheckUsersNewUsers([checkedUser]);
    this.reduxService.confirmCheckUsers();
    super.clear();
    this.filterForm.controls.q.setValue('');
  }
}
