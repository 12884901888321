import { Component, Input, OnInit } from '@angular/core';
import { INav } from 'src/app/models';

@Component({
  selector: 'app-zentao-navs',
  templateUrl: './zentao-navs.component.html',
  styleUrls: ['./zentao-navs.component.scss']
})
export class ZentaoNavsComponent implements OnInit {
  @Input() items: INav[] = [];

  constructor() { }

  ngOnInit(): void {
  }

}
