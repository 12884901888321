import { Action, ActionCreator } from 'redux';
import { IPlan, IProject, IMilestone } from 'src/app/models';

// #region 设置planTree的可编辑状态
export interface SetPlanTreeEditEnableAction extends Action {
  editEnable: boolean;
}
export const SET_PLAN_TREE_EDIT_ENABLE = 'SET_PLAN_TREE_EDIT_ENABLE';
export const setPlanTreeEditEnable: ActionCreator<SetPlanTreeEditEnableAction> = (editEnable: boolean) => ({
  type: SET_PLAN_TREE_EDIT_ENABLE,
  editEnable
});
// #endregion

// #region 设置planTree的dirty状态
export interface SetPlanTreeDirtyAction extends Action {
  dirty: boolean;
}
export const SET_PLAN_TREE_DIRTY = 'SET_PLAN_TREE_DIRTY';
export const setPlanTreeDirty: ActionCreator<SetPlanTreeDirtyAction> = (dirty: boolean) => ({
  type: SET_PLAN_TREE_DIRTY,
  dirty
});
// #endregion

// #region 设置planTree的正在编辑中的Plan
export interface SetPlanTreeEditingPlanAction extends Action {
  plan: IPlan;
}
export const SET_PLAN_TREE_EDITING_PLAN = 'SET_PLAN_TREE_EDITING_PLAN';
export const setPlanTreeEditingPlan: ActionCreator<SetPlanTreeEditingPlanAction> = (plan: IPlan) => ({
  type: SET_PLAN_TREE_EDITING_PLAN,
  plan
});
// #endregion

// #region 设置planTree的project
export interface SetPlanTreeProjectAction extends Action {
  project: IProject;
}
export const SET_PLAN_TREE_PROJECT = 'SET_PLAN_TREE_PROJECT';
export const setPlanTreeProject: ActionCreator<SetPlanTreeProjectAction> = (project: IProject) => ({
  type: SET_PLAN_TREE_PROJECT,
  project
});
// #endregion

// #region 设置planTree的节点列表
export interface SetPlanTreeMilestonesAction extends Action {
  milestones: IMilestone[];
}
export const SET_PLAN_TREE_MILESTONES = 'SET_PLAN_TREE_MILESTONES';
export const setPlanTreeMilestones: ActionCreator<SetPlanTreeMilestonesAction> = (milestones: IMilestone[]) => ({
  type: SET_PLAN_TREE_MILESTONES,
  milestones
});
// #endregion

// #region 选择planTree的节点
export interface CheckPlanTreeMilestoneAction extends Action {
  milestone: IMilestone;
}
export const CHECK_PLAN_TREE_MILESTONE = 'CHECK_PLAN_TREE_MILESTONE';
export const checkPlanTreeMilestone: ActionCreator<CheckPlanTreeMilestoneAction> = (milestone: IMilestone) => ({
  type: CHECK_PLAN_TREE_MILESTONE,
  milestone
});
// #endregion

// #region 设置当前节点的计划全选状态
export interface SetPlanTreeCurrentMilestoneIsCheckedAllAction extends Action {
  isCheckedAll: boolean;
}
export const SET_PLAN_TREE_CURRENT_MILESTONE_IS_CHECKED_ALL = 'SET_PLAN_TREE_CURRENT_MILESTONE_IS_CHECKED_ALL';
export const setPlanTreeCurrentMilestoneIsCheckedAll: ActionCreator<SetPlanTreeCurrentMilestoneIsCheckedAllAction> =
  (isCheckedAll: boolean) => ({
    type: SET_PLAN_TREE_CURRENT_MILESTONE_IS_CHECKED_ALL,
    isCheckedAll
  });
// #endregion

// #region 设置计划列表
export interface SetPlanTreePlansAction extends Action {
  plans: IPlan[];
}
export const SET_PLAN_TREE_PLANS = 'SET_PLAN_TREE_PLANS';
export const setPlanTreePlans: ActionCreator<SetPlanTreePlansAction> = (plans: IPlan[]) => ({
  type: SET_PLAN_TREE_PLANS,
  plans
});
// #endregion

// #region 设置新增或变更的计划列表
export interface SetPlanTreeDirtyPlansAction extends Action {
  plans: IPlan[];
}
export const SET_PLAN_TREE_DIRTY_PLANS = 'SET_PLAN_TREE_DIRTY_PLANS';
export const setPlanTreeDirtyPlans: ActionCreator<SetPlanTreeDirtyPlansAction> = (plans: IPlan[]) => ({
  type: SET_PLAN_TREE_DIRTY_PLANS,
  plans
});
// #endregion

// #region 添加计划
export interface AddPlanTreePlanAction extends Action {
  plan: IPlan;
}
export const ADD_PLAN_TREE_PLAN = 'ADD_PLAN_TREE_PLAN';
export const addPlanTreePlan: ActionCreator<AddPlanTreePlanAction> = (plan: IPlan) => ({
  type: ADD_PLAN_TREE_PLAN,
  plan
});
// #endregion

// #region 批量添加计划
export interface AddPlanTreePlansAction extends Action {
  plans: IPlan[];
}
export const ADD_PLAN_TREE_PLANS = 'ADD_PLAN_TREE_PLANS';
export const addPlanTreePlans: ActionCreator<AddPlanTreePlansAction> = (plans: IPlan[]) => ({
  type: ADD_PLAN_TREE_PLANS,
  plans
});
// #endregion

// #region 删除计划
export interface DeletePlanTreePlanAction extends Action {
  plan: IPlan;
}
export const DELETE_PLAN_TREE_PLAN = 'DELETE_PLAN_TREE_PLAN';
export const deletePlanTreePlan: ActionCreator<DeletePlanTreePlanAction> = (plan: IPlan) => ({
  type: DELETE_PLAN_TREE_PLAN,
  plan
});
// #endregion

// #region 批量删除计划
export interface DeletePlanTreePlansAction extends Action {
  plans: IPlan[];
}
export const DELETE_PLAN_TREE_PLANS = 'DELETE_PLAN_TREE_PLANS';
export const deletePlanTreePlans: ActionCreator<DeletePlanTreePlansAction> = (plans: IPlan[]) => ({
  type: DELETE_PLAN_TREE_PLANS,
  plans
});
// #endregion

// #region 更新计划
export interface UpdatePlanTreePlanAction extends Action {
  plan: IPlan;
}
export const UPDATE_PLAN_TREE_PLAN = 'UPDATE_PLAN_TREE_PLAN';
export const updatePlanTreePlan: ActionCreator<UpdatePlanTreePlanAction> = (plan: IPlan) => ({
  type: UPDATE_PLAN_TREE_PLAN,
  plan
});
// #endregion

// #region toggle勾选计划
export interface ToggleCheckPlanTreePlanAction extends Action {
  plan: IPlan;
  isChecked: boolean;
}
export const TOGGLE_CHECK_PLAN_TREE_PLAN = 'TOGGLE_CHECK_PLAN_TREE_PLAN';
export const toggleCheckPlanTreePlan: ActionCreator<ToggleCheckPlanTreePlanAction> = (plan: IPlan, isChecked: boolean) => ({
  type: TOGGLE_CHECK_PLAN_TREE_PLAN,
  plan,
  isChecked
});
// #endregion

// #region toggle计划展开状态
export interface ToggleFoldPlanTreePlanAction extends Action {
  plan: IPlan;
}
export const TOGGLE_FOLD_PLAN_TREE_PLAN = 'TOGGLE_FOLD_PLAN_TREE_PLAN';
export const toggleFoldPlanTreePlan: ActionCreator<ToggleFoldPlanTreePlanAction> = (plan: IPlan) => ({
  type: TOGGLE_FOLD_PLAN_TREE_PLAN,
  plan
});
// #endregion

// #region 设置计划树的当前计划（用于展示树showTree标记当前计划）
export interface SetPlanTreeCurrentPlanAction extends Action {
  plan: IPlan;
}
export const SET_PLAN_TREE_CURRENT_PLAN = 'SET_PLAN_TREE_CURRENT_PLAN';
export const setPlanTreeCurrentPlan: ActionCreator<SetPlanTreeCurrentPlanAction> = (plan: IPlan) => ({
  type: SET_PLAN_TREE_CURRENT_PLAN,
  plan
});
// #endregion

// #region 设置planTree的showIssueApprovedPlans属性
export interface SetPlanTreeShowIssueApprovedPlansAction extends Action {
  showIssueApprovedPlans: boolean;
}
export const SET_PLAN_TREE_SHOW_ISSUE_APPROVED_PLANS = 'SET_PLAN_TREE_SHOW_ISSUE_APPROVED_PLANS';
export const setPlanTreeShowIssueApprovedPlans: ActionCreator<SetPlanTreeShowIssueApprovedPlansAction>
  = (showIssueApprovedPlans: boolean) => ({
    type: SET_PLAN_TREE_SHOW_ISSUE_APPROVED_PLANS,
    showIssueApprovedPlans
  });
// #endregion

