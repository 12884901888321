// 事业部管理的访问
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { SharedService } from '../services/shared.service';

@Injectable({
  providedIn: 'root',
})
export class DivisionGuard implements CanActivate {
  constructor(
    private router: Router,
    private userService: UserService,
    private sharedService: SharedService
  ) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const isSystemAdmin = this.userService.getCurrentIsSystemAdminSync();
    const isDivisionAdmin = this.userService.getCurrentIsDivisionAdminSync();
    const isCategoryAdmin = this.userService.getCurrentIsCategoryAdminSync();
    const isOrganizationAdmin = this.userService.getCurrentIsOrganizationAdminSync();
    const isProjectManagementAdmin = this.userService.getCurrentIsProjectManagementAdminSync();
    const isSecondOrgTemplateAdmin = this.userService.getCurrentIsSecondOrgTemplateAdminSync();
    const isKpiApprovalAdmin = this.userService.getCurrentIsKpiApprovalAdminSync();
    const isRequirementManagementEngineer = this.userService.getCurrentIsRequirementManagementEngineerSync();
    // 系统管理员
    if (isSystemAdmin) {
      return true;
    } else if (isProjectManagementAdmin) {
      this.router.navigate(['/management-center/system-admin/management-admin-query']);
      return false;
    } else if (isRequirementManagementEngineer) {
      this.router.navigate(['/management-center/permission']);
      return false;
    } else if (isOrganizationAdmin) {
      this.router.navigate(['/management-center/organization-authorization']);
      return false;
    } else if (isSystemAdmin || isDivisionAdmin || isCategoryAdmin || isKpiApprovalAdmin) {
      this.router.navigate(['/management-center/issue-transfer']);
      return false;
    } else if (isSecondOrgTemplateAdmin) {
      this.router.navigate(['/management-center/template']);
      return false;
    } else {
      this.router.navigate(['/management-center/labor-cost']);
      return false;
    }
  }
}
