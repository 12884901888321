import { Action, ActionCreator } from 'redux';
import {
  IWorkflow,
  IWorkflowStep
} from 'src/app/models';
import { TWorkflowStepAddType } from 'src/app/models/IWorkflow';


// #region setWorkflow
export interface SetWorkflowAction extends Action {
  workflow: IWorkflow;
}
export const SET_WORKFLOW = 'SET_WORKFLOW';
export const setWorkflow: ActionCreator<SetWorkflowAction> = (workflow: IWorkflow) => ({
  type: SET_WORKFLOW,
  workflow
});
// #endregion

// #region setWorkflowEditEnable
export interface SetWorkflowEditEnableAction extends Action {
  editEnable: boolean;
}
export const SET_WORKFLOW_EDIT_ENABLE = 'SET_WORKFLOW_EDIT_ENABLE';
export const setWorkflowEditEnable: ActionCreator<SetWorkflowEditEnableAction> = (editEnable: boolean) => ({
  type: SET_WORKFLOW_EDIT_ENABLE,
  editEnable
});
export interface SetWorkflowEnforceDoneAction extends Action {
  enforceDone: boolean;
}
export const SET_WORKFLOW_ENFORCE_DONE = 'SET_WORKFLOW_ENFORCE_DONE';
export const setWorkflowEnforceDone: ActionCreator<SetWorkflowEnforceDoneAction> = (enforceDone: boolean) => ({
  type: SET_WORKFLOW_ENFORCE_DONE,
  enforceDone
});
// #endregion

// #region setWorkflowDirty
export interface SetWorkflowDirtyAction extends Action {
  dirty: boolean;
}
export const SET_WORKFLOW_DIRTY = 'SET_WORKFLOW_DIRTY';
export const setWorkflowDirty: ActionCreator<SetWorkflowDirtyAction> = (dirty: boolean) => ({
  type: SET_WORKFLOW_DIRTY,
  dirty
});
// #endregion

// #region setWorkflowSteps
export interface SetWorkflowStepsAction extends Action {
  steps: IWorkflowStep[];
}
export const SET_WORKFLOW_STEPS = 'SET_WORKFLOW_STEPS';
export const setWorkflowSteps: ActionCreator<SetWorkflowStepsAction> = (steps: IWorkflowStep[]) => ({
  type: SET_WORKFLOW_STEPS,
  steps
});
// #endregion

// #region addWorkflowStep
export interface AddWorkflowStepAction extends Action {
  referenceStep: IWorkflowStep;
  addType: TWorkflowStepAddType;
}
export const ADD_WORKFLOW_STEP = 'ADD_WORKFLOW_STEP';
export const addWorkflowStep: ActionCreator<AddWorkflowStepAction> = (
  referenceStep: IWorkflowStep,
  addType: TWorkflowStepAddType
) => ({
  type: ADD_WORKFLOW_STEP,
  referenceStep,
  addType
});
// #endregion

// #region deleteWorkflowStep
export interface DeleteWorkflowStepAction extends Action {
  step: IWorkflowStep;
}
export const DELETE_WORKFLOW_STEP = 'DELETE_WORKFLOW_STEP';
export const deleteWorkflowStep: ActionCreator<DeleteWorkflowStepAction> = (step: IWorkflowStep) => ({
  type: DELETE_WORKFLOW_STEP,
  step
});
// #endregion

// #region updateWorkflowStep
export interface UpdateWorkflowStepAction extends Action {
  step: IWorkflowStep;
}
export const UPDATE_WORKFLOW_STEP = 'UPDATE_WORKFLOW_STEP';
export const updateWorkflowStep: ActionCreator<UpdateWorkflowStepAction> = (step: IWorkflowStep) => ({
  type: UPDATE_WORKFLOW_STEP,
  step
});
// #endregion

// #region checkWorkflowStep
export interface CheckWorkflowStepAction extends Action {
  step: IWorkflowStep;
}
export const CHECK_WORKFLOW_STEP = 'CHECK_WORKFLOW_STEP';
export const checkWorkflowStep: ActionCreator<CheckWorkflowStepAction> = (step: IWorkflowStep) => ({
  type: CHECK_WORKFLOW_STEP,
  step
});
// #endregion

// #region setWorkflowShowPersonsMaxLimit
export interface SetWorkflowShowPersonsMaxLimitAction extends Action {
  showPersonsMaxLimit: number;
}
export const SET_WORKFLOW_SHOW_PERSONS_MAX_LIMIT = 'SET_WORKFLOW_SHOW_PERSONS_MAX_LIMIT';
export const setWorkflowShowPersonsMaxLimit: ActionCreator<SetWorkflowShowPersonsMaxLimitAction> = (showPersonsMaxLimit: number) => ({
  type: SET_WORKFLOW_SHOW_PERSONS_MAX_LIMIT,
  showPersonsMaxLimit
});
// #endregion
