import { Component, OnInit } from '@angular/core';

import _ from 'lodash';
import { forkJoin, Observable } from 'rxjs';

import {
  IAlert,
  IDialogCallback,
  IFileUploadInfo,
  IPageContentInfo,
  IUpdatePageContentCommand
} from 'src/app/models';
import { PortalService } from '../../services/portal.service';
import { AlertService } from 'src/app/services/alert.service';
import { FileService } from 'src/app/services/file.service';
import { ReduxService } from 'src/app/redux/redux.service';
import { UserService } from 'src/app/services/user.service';
@Component({
  selector: 'app-help-document',
  templateUrl: './help-document.component.html',
  styleUrls: ['./help-document.component.scss']
})
export class HelpDocumentComponent implements OnInit {
  isSystemAdmin: boolean; // 系统管理员
  systemDocuments: IPageContentInfo;
  files: File[] = [];
  file = {
    name: '',
    url: ''
  };
  data = [];
  updatePageContentCommand: IUpdatePageContentCommand = {
    data: {},
    key: '',
    description: ''
  };
  pageContent: IPageContentInfo[];
  trainingManuals: IPageContentInfo;
  item: any;
  key: string;
  isBusy: boolean; // 文件正在上传

  constructor(
    private portalService: PortalService,
    private alertService: AlertService,
    private fileService: FileService,
    private reduxService: ReduxService,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.getAllPagesInfo();
    this.isSystemAdmin = this.userService.getCurrentIsSystemAdminSync();
  }

  readState() {
    this.isBusy = this.reduxService.getCommonIsBusy();
  }

  getAllPagesInfo() {
    this.portalService.getPagesInfo().subscribe((pageContent) => {
      this.pageContent = pageContent;
      this.systemDocuments = _.find(this.pageContent, item => item.key === 'system_documents');
      this.trainingManuals = _.find(this.pageContent, item => item.key === 'training_manuals');
    });
  }

  uploadFile(files: File[], key: string) {
    const busyAlert: IAlert = {
      type: 'info',
      title: '正在上传...',
      timeout: 60000
    };
    this.alertService.addAlert(busyAlert);

    if (!(files && files.length)) {
      this.alertService.deleteAlert(busyAlert);
      return;
    }

    // 验证文件大小
    if (!this.fileService.verifyFileSizes(files)) {
      this.alertService.deleteAlert(busyAlert);
      this.files = [];
      return;
    }
    this.reduxService.setCommonIsBusy(true);

    const requests: Observable<IFileUploadInfo>[] = [];
    _.forEach(files, file => {
      requests.push(this.fileService.uploadFile(file));
    });
    forkJoin(requests).subscribe(fileUploadInfos => {

      // 接口报404，提示上传失败
      if (_.includes(fileUploadInfos, undefined)) {
        this.alertService.deleteAlert(busyAlert);
        const errorAlert: IAlert = {
          type: 'danger',
          title: '文件上传失败'
        };
        this.alertService.addAlert(errorAlert);
        this.files = [];  // 清空上传文件列表
        this.reduxService.setCommonIsBusy(false);
        return;
      }

      let alert: IAlert;
      _.forEach(fileUploadInfos, fileUploadInfo => {
        this.file.name = fileUploadInfo.name;
        this.file.url = fileUploadInfo.url_path + '?downloadName=' + encodeURIComponent(fileUploadInfo.name);
        this.data.push({ ...this.file });
      });
      this.alertService.deleteAlert(busyAlert);
      alert = {
        type: 'success',
        title: '上传成功'
      };
      this.alertService.addAlert(alert);
      this.files = [];  // 清空上传文件列表
      this.reduxService.setCommonIsBusy(false);
      this.updatePageContentCommand.key = key;
      const document = _.find(this.pageContent, item => item.key === key);
      if (document) {
        const oldData = document.data.data;
        this.updatePageContentCommand.data = { data: [...oldData, ...this.data] };
      } else {
        this.updatePageContentCommand.data = { data: [...this.data] };
      }
      this.postPagesInfo();
      this.data = [];
    });
  }

  postPagesInfo() {
    this.portalService.postPageInfo(this.updatePageContentCommand).subscribe(() => {
      this.getAllPagesInfo();
    });
  }



  toDeleteFile(item: any, key: string) {
    this.item = item;
    this.key = key;
    this.reduxService.showDialog('确认删除', [`确认删除您所选中的文档吗？`], this.deleteFile, this.cancelDeleteAdmins);
  }

  deleteFile: IDialogCallback = () => {
    const document = _.find(this.pageContent, item => item.key === this.key);
    const index = _.findIndex(document.data.data, (dataItem) => {
      return this.item === dataItem;
    });
    this.updatePageContentCommand.key = this.key;
    document.data.data.splice(index, 1);
    const newData = _.cloneDeep(document.data.data);
    this.updatePageContentCommand.data = { data: newData };
    this.postPagesInfo();
  }

  cancelDeleteAdmins() {
  }
}
