import {
  Component,
  TemplateRef,
  ViewChild,
  Inject
} from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import _ from 'lodash';

import { CCheckBase } from 'src/app/classes';
import { PAGE_SIZE, PAGE_LINK_LIMIT } from 'src/app/consts';
import { ReduxService } from 'src/app/redux/redux.service';
import {
  IUserInfo, IUserInfoParams, IPagination, IProject, IProjectManagementAdminParams, IOrganizationUserParams,
  ISuggestedUsersParams,
  IWorktimeFlowStepUsersParams,
  IWorktimeUsersParams
} from 'src/app/models';
import { UserService } from 'src/app/services/user.service';
import { SharedService } from 'src/app/services/shared.service';
import { ECheckUsersType } from 'src/app/enums';
import { ProjectRoleService } from '../../permission/services/project-role.service';
import { PersonalManagementService } from 'src/app/services/personal-management.service';
import { WorktimeService } from 'src/app/services/worktime.service';

@Component({
  selector: 'app-check-users',
  templateUrl: './check-users.component.html',
  styleUrls: ['./check-users.component.scss']
})
export class CheckUsersComponent extends CCheckBase<IUserInfo, IUserInfoParams> {
  modalRef: BsModalRef;
  @ViewChild('checkUsers') modalEle: TemplateRef<any>;
  isLately: boolean;

  filterForm: FormGroup;

  page = 1;
  limit = PAGE_SIZE;
  pageLinkLimit = PAGE_LINK_LIMIT;
  count = 0;

  checkUsersTypeEnum = ECheckUsersType;
  checkUsersType: ECheckUsersType;
  project: IProject;
  orgNumbers: string[];
  currentUser: IUserInfo;

  constructor(
    private modalService: BsModalService,
    private reduxService: ReduxService,
    private sharedService: SharedService,
    private projectRoleService: ProjectRoleService,
    private personalManagementService: PersonalManagementService,
    private worktimeService: WorktimeService,
    @Inject(UserService) private userService: UserService
  ) {
    super(userService, 'getUsers', 'user_id');
    this.reduxService.subscribeStore(() => this.readState());
    this.readState();

    this.filterForm = this.fb.group({
      q: ['']
    });
  }

  readState() {
    const isShow = this.reduxService.getCheckUsersShow();
    const isMultiple = this.reduxService.getCheckUsersMultiple();
    this.rejectItems = this.reduxService.getCheckUsersOldUsers();
    this.checkUsersType = this.reduxService.getCheckUsersCheckUsersType();
    this.project = this.reduxService.getCommonProject();
    this.orgNumbers = this.reduxService.getCheckUsersParams() as string[];
    this.currentUser = this.userService.getCurrentUserSync();

    if (!isMultiple) {
      return;
    }

    if (isShow) {
      this.show();
    } else {
      this.hide();
    }
  }

  getUsers() {
    this.userService.getLatelyUsers().subscribe((users) => {
      this.items = _.differenceBy(users, this.rejectItems, 'user_id');
      this.isLately = true;
      _.forEach(this.items, (item) => {
        this.checkForm.addControl(item.user_id + '', new FormControl());
      });

      if (!users.length) {
        this.search();
      }
    });
  }

  getItemsCount(enforceNew?: boolean) {
    switch (this.checkUsersType) {
      case ECheckUsersType.ProjectManagementAdmin:
        this.projectRoleService.getProjectManagementAdminsCount(this.queryParams as IProjectManagementAdminParams).subscribe(count => {
          this.count = count;
          this.page = this.queryParams.page;
        });
        break;
      case ECheckUsersType.OrganizationUsers:
        this.projectRoleService.getOrganizationUsersCount(this.queryParams as IOrganizationUserParams).subscribe(count => {
          this.count = count;
          this.page = this.queryParams.page;
        });
        break;
      case ECheckUsersType.RecommendedUsers:
        this.personalManagementService.getSuggestedUsersCount(this.queryParams as ISuggestedUsersParams).subscribe(count => {
          this.count = count;
          this.page = this.queryParams.page;
        });
        break;
      case ECheckUsersType.Approver:
        this.worktimeService.getWorkFlowStepUsersCount(this.queryParams as IWorktimeFlowStepUsersParams).subscribe(count => {
          this.count = count;
          this.page = this.queryParams.page;
        });
        break;
      case ECheckUsersType.WorktimeUsers:
        this.worktimeService.getWorktimeUsersCount(this.queryParams as IWorktimeUsersParams).subscribe(count => {
          this.count = count;
          this.page = this.queryParams.page;
        });
        break;
      case ECheckUsersType.All:
      default:
        this.userService.getUsersCount(this.queryParams, enforceNew).subscribe(count => {
          this.count = count;
          this.page = this.queryParams.page;
        });
    }
  }

  search() {
    this.page = 1;
    const params = Object.assign(this.queryParams, { page: this.page }, this.filterForm.value);
    this.queryParams = this.sharedService.omitEmptyParams(params);
    this.isLately = false;
    super.getItems();
    this.getItemsCount();
  }

  onPageChanged(pagination: IPagination) {
    this.queryParams.page = pagination.page;
    this.queryParams.limit = pagination.itemsPerPage;
    super.getItems();
    this.getItemsCount();
  }

  show() {
    if (!this.modalRef) {
      this.isLately = false;
      this.page = 1;
      switch (this.checkUsersType) {
        case ECheckUsersType.ProjectManagementAdmin:
          this.queryParams = {
            division_org_number: this.project.management_division_org_number,
            project_group_key: this.project.project_group,
            page: 1,
            limit: 10
          } as IUserInfoParams;
          super.updateConstructor(this.projectRoleService, 'getProjectManagementAdmins', 'id');
          super.getItems();
          this.getItemsCount();
          break;
        case ECheckUsersType.OrganizationUsers:
          this.queryParams = {
            page: 1,
            limit: 10,
            org_numbers: this.orgNumbers
          } as IOrganizationUserParams;
          super.updateConstructor(this.projectRoleService, 'getOrganizationUsers', 'user_id');
          super.getItems();
          this.getItemsCount();
          break;
        case ECheckUsersType.RecommendedUsers:
          this.queryParams = {
            page: 1,
            limit: 10,
          } as ISuggestedUsersParams;
          super.updateConstructor(this.personalManagementService, 'getSuggestedUsers', 'user_id');
          super.getItems();
          this.getItemsCount();
          break;
        case ECheckUsersType.Approver:
          this.userService.getCurrentUserDivisionOrgNumber(this.currentUser.user_id).subscribe(divisionOrgNumber => {
            this.queryParams = {
              page: 1,
              limit: 10,
              division_org_number: divisionOrgNumber
            } as IWorktimeFlowStepUsersParams;
            super.updateConstructor(this.worktimeService, 'getWorkFlowStepUsers', 'user_id');
            super.getItems();
            this.getItemsCount();
          });
          break;
        case ECheckUsersType.WorktimeUsers:
          this.queryParams = {
            page: 1,
            limit: 10,
          } as IWorktimeUsersParams;
          super.updateConstructor(this.worktimeService, 'getWorktimeUsers', 'user_id');
          super.getItems();
          this.getItemsCount();
          break;
        case ECheckUsersType.All:
        default:
          super.updateConstructor(this.userService, 'getUsers', 'user_id');
          this.getUsers();
      }
      this.modalRef = this.modalService.show(this.modalEle, { class: 'modal-lg', ignoreBackdropClick: true });
    }
  }

  hide() {
    if (this.modalRef) {
      this.modalRef.hide();
      this.modalRef = undefined;
      this.reduxService.cancelCheckUsers();
      super.clear();
      this.filterForm.controls.q.setValue('');
    }
  }

  confirm() {
    const checkedUsers = super.getCheckedItems();
    _.forEach(checkedUsers, user => {
      this.userService.addLatelyUser(user.user_id).subscribe();
    });
    this.reduxService.setCheckUsersNewUsers(checkedUsers);
    this.reduxService.confirmCheckUsers();
    super.clear();
    this.filterForm.controls.q.setValue('');
  }
}
