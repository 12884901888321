import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { IBatchUpdatePageContentCommand, IPageContentInfo, IUpdatePageContentCommand } from 'src/app/models/IPageContentInfo';
import {
  IMyKpiSelfKanbanInfo,
  IMyKpiOtherKanban,
  IMyKpiSelfKanbanParams,
  IMyKpiOtherKanbanParams
} from 'src/app/models';
import { catchError } from 'rxjs/operators';
import { SharedService } from 'src/app/services/shared.service';
import { Observable, of } from 'rxjs';
import { IMonthKanban, IKanbanAncestorIdsParams, IKanbanLevelTreeItem, IKanbanLevelTreeParams, IKanbanAncestorIds } from 'src/app/models';
import _ from 'lodash';
import { ECurrentDayKanbanInfoType, EPlanKanbanType } from 'src/app/enums';

@Injectable({
  providedIn: 'root'
})
export class PortalService {
  apiUrl = '/api/cms/pages';


  constructor(
    private http: HttpClient,
    private sharedService: SharedService,
  ) { }

  getPagesInfo(): Observable<IPageContentInfo[]> {
    return this.http.get<IPageContentInfo[]>(`${this.apiUrl}`).pipe(
      catchError(this.sharedService.handleError('get pages info', []))
    );
  }

  postPageInfo(updatePageContentCommand: IUpdatePageContentCommand): Observable<boolean> {
    return this.http.post<boolean>(`${this.apiUrl}`, updatePageContentCommand).pipe(
      catchError(this.sharedService.handleError('post page info', false))
    );
  }

  postPageInfoBatch(command: IBatchUpdatePageContentCommand): Observable<boolean> {
    return this.http.post<boolean>(`${this.apiUrl}-batch`, command).pipe(
      catchError(this.sharedService.handleError('batch post page info', false))
    );
  }
 
  deletePageInfo(key: string): Observable<any> {
    return this.http.delete<any>(`${this.apiUrl}/${key}`).pipe(
      catchError(this.sharedService.handleError('delete page info', false))
    );
  }

  // 获取月度看板节点计划超期、延期、到期个数
  getKanbanCalendarData(kanbanType: EPlanKanbanType, months: string[]): Observable<IMonthKanban[]> {
    const params = {
      plan_board_type: kanbanType,
      dates: months
    };
    return this.http.get<IMonthKanban[]>('/api/kanban/plan/month-board', { params: params as unknown as HttpParams }).pipe(
      catchError(this.sharedService.handleError('get month-board', []))
    );
  }

  // 获取当天看板节点计划列表
  getCurrentDayKanban(params: IKanbanLevelTreeParams): Observable<IKanbanLevelTreeItem[]> {
    return this.http.get<IKanbanLevelTreeItem[]>('/api/kanban/plan/current-day-board', { params: params as unknown as HttpParams }).pipe(
      catchError(this.sharedService.handleError('get kanban current-day-board', []))
    );
  }

  // 当天看板，获取祖先ids
  getKanbanAncestorIds(id: string, projectCode: string, type: ECurrentDayKanbanInfoType): Observable<IKanbanAncestorIds[]> {
    if (!(id && projectCode && type)) {
      return of([]);
    }
    const params: IKanbanAncestorIdsParams = {
      id,
      project_code: projectCode,
      current_day_board_info_type: type
    };
    return this.http.get<IKanbanAncestorIds[]>(`/api/kanban/last-level/board`, { params: params as unknown as HttpParams }).pipe(
      catchError(this.sharedService.handleError('get ancestor ids', []))
    );
  }

  // 将1/2位数字转化为两位数，如7转化为07，12转化为12
  convertNumber2TwoDigitsSync(oldNumber: number): string {
    const newNumber = `0${oldNumber}`;
    const twoDigitsNumber = newNumber.substring(newNumber.length - 2, newNumber.length); // 截取后两位
    return twoDigitsNumber;
  }

  getKanbanTypeSync(url: string): EPlanKanbanType {
    let kanbanType: EPlanKanbanType;
    if (_.includes(url, '/all/')) {
      kanbanType = EPlanKanbanType.All;
    } else if (_.includes(url, '/i-issued/')) {
      kanbanType = EPlanKanbanType.Issue;
    } else if (_.includes(url, '/i-managed/')) {
      kanbanType = EPlanKanbanType.Manager;
    } else if (_.includes(url, '/i-responsible/')) {
      kanbanType = EPlanKanbanType.Responsible;
    } else if (_.includes(url, '/i-transferred/')) {
      kanbanType = EPlanKanbanType.Transfer;
    } else if (_.includes(url, '/to-receipt/')) {
      kanbanType = EPlanKanbanType.ToReceipt;
    } else if (_.includes(url, '/fpm/')) {
      kanbanType = EPlanKanbanType.Fpm;
    }
    return kanbanType;
  }

  // 我的绩效个人看板信息
  getMyKpiSelfKanbanInfo(params: IMyKpiSelfKanbanParams): Observable<IMyKpiSelfKanbanInfo> {
    return this.http.get<IMyKpiSelfKanbanInfo>
      ('/api/kanban/my-kpi/board-self', { params: params as unknown as HttpParams }).pipe(
      );
  }

  // 我的绩效其他看板
  getMyKpiOtherKanbans(params: IMyKpiOtherKanbanParams): Observable<IMyKpiOtherKanban[]> {
    return this.http.get<IMyKpiOtherKanban[]>
      ('/api/kanban/my-kpi/board-other', { params: params as unknown as HttpParams }).pipe(
      );
  }

  // 获取我的绩效年份
  getMyKpiKanbanYears(): Observable<string[]> {
    return this.http.get<string[]>
      ('/api/kanban/kpi-activity/year').pipe(
        catchError(this.sharedService.handleError('get Kanban year', []))
      );
  }

  // 是否为PMAT经理
  getCurrentIsPmatManager(): Observable<boolean> {
    return this.http.get<boolean>
      ('/api/kanban/check/is-pmat-manager').pipe(
        catchError(this.sharedService.handleError('get is pmat manager', false))
      );
  }

}
