// 组织管理的访问
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { SharedService } from '../services/shared.service';

@Injectable({
    providedIn: 'root',
})
export class WorkCenterOrganizationGuard implements CanActivate {
    constructor(
        private router: Router,
        private userService: UserService,
        private sharedService: SharedService
    ) { }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        // 系统管理员 || 项目群管理员 || 项目管理员 || 产品工程经理 || 管理经理 || 组织维护员
        if (this.userService.getCurrentIsSystemAdminSync()
            || this.userService.getCurrentIsProjectGroupAdminSync()
            || this.userService.getCurrentIsProjectAdminSync()
            || this.userService.getCurrentIsProductEngineeringAdminSync()
            || this.userService.getCurrentIsProjectManagementAdminSync()
            || this.userService.getCurrentIsOrganizationMaintainerSync()
        ) {
            return true;
        } else {
            this.sharedService.setRedirectUrl(window.location.href);
            this.router.navigate(['/work-center']);
            return false;
        }
    }
}
