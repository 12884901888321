import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private currentTheme: 'light' | 'zentao-blue' = 'light';

  setTheme() {
    this.currentTheme = 'zentao-blue';
    document.documentElement.setAttribute('data-theme', 'zentao-blue');
    console.log(document.documentElement);
  }

  getTheme(): 'light' | 'zentao-blue' {
    return this.currentTheme;
  }
}
