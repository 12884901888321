// *任务类型
export enum ETaskInfoType {
    /// <summary>
    /// 待阅审批
    /// </summary>
    Notify = 0,

    /// <summary>
    /// 流程审批
    /// </summary>
    Flow = 10,

    /// <summary>
    /// 计划回执
    /// </summary>
    Receipt = 20,

    /// <summary>
    /// 编制计划
    /// </summary>
    Plan = 30,

    /// <summary>
    /// 编制节点
    /// </summary>
    Milestone = 40,

    /// <summary>
    /// 组织管理
    /// </summary>
    Org = 50,

    /// <summary>
    /// 项目信息维护
    /// </summary>
    Project = 60,

    /// <summary>
    /// 项目状态管理
    /// </summary>
    State = 70,

    /// <summary>
    /// 绩效管理
    /// </summary>
    Performance = 80,

    /// <summary>
    /// 工时管理
    /// </summary>
    WorkTime = 90,

    /// <summary>
    /// 需求管理
    /// </summary>
    RequirementCreate = 100,

    /// <summary>
    /// 需求分析
    /// </summary>
    RequirementAnalysis = 110,
}
