import { Component, EventEmitter, Output } from '@angular/core';
import { CRadioBase } from 'src/app/classes';
import { IOrganization } from 'src/app/models';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-check-lately-organization',
  templateUrl: './check-lately-organization.component.html',
  styleUrls: ['./check-lately-organization.component.scss']
})
export class CheckLatelyOrganizationComponent extends CRadioBase<IOrganization, any> {

  @Output()
  checkedItemChange: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  initCheckedItemChange: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    private userService: UserService
  ) {
    super(userService, 'getLatelyOrganizations', 'org_number');
    super.getItems();

    let initCheckedItemFlag = true;
    this.radioForm.controls.radio.valueChanges.subscribe(value => {
      if (value && initCheckedItemFlag) {
        this.initCheckedItemChange.emit(value);
        initCheckedItemFlag = false;
      }
    });
  }


  checkItem(item: IOrganization) {
    super.checkItem(item);
    this.checkedItemChange.emit(item.org_number);
  }

}
