import { Component, OnInit, Input, OnChanges, SimpleChanges, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export const EXE_SWITCH_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => SwitchComponent),
  multi: true
};

@Component({
  selector: 'app-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss'],
  providers: [EXE_SWITCH_VALUE_ACCESSOR]
})
export class SwitchComponent implements OnInit, OnChanges, ControlValueAccessor {
  @Input() editEnable: boolean;
  @Input() type: 'primary' | 'danger' | 'success';

  // tslint:disable-next-line: variable-name
  _value: boolean;
  @Input()
  get value() {
    return this._value;
  }
  set value(newValue) {
    this._value = newValue;
    this.propagateChange(this.value);
    this.updateSwitchClass(newValue);
  }

  switchClass = {
    'fa-toggle-on': false,
    'fa-toggle-off': true,
    primary: false,
    danger: false
  };

  constructor() { }

  ngOnInit() {
    this.switchClass[this.type] = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.editEnable) {
      this.editEnable = 'false' !== changes.editEnable.currentValue
        && changes.editEnable.currentValue
        || '' === changes.editEnable.currentValue;
    }
  }

  writeValue(value: any) {
    this.value = value;
  }

  propagateChange = (_: any) => { };

  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any) { }

  toggle() {
    if (!this.editEnable) {
      return;
    }
    this.value = !this.value;
  }

  updateSwitchClass(value: boolean) {
    this.switchClass['fa-toggle-on'] = value;
    this.switchClass['fa-toggle-off'] = !value;
  }

}
