import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import _ from 'lodash';
import { IAchievementDeliverableContentItem, IAchievementTarget, IConfigInfo } from 'src/app/models';
import { PropertyConfigService } from '../../property-config/services/property-config.service';

@Component({
  selector: '[app-achievement-target-item]',
  templateUrl: './achievement-target-item.component.html',
  styleUrls: ['./achievement-target-item.component.scss'],
  host: {
    // tslint:disable-next-line: object-literal-key-quotes
    'formGroup': 'myForm',
  }

})
export class AchievementTargetItemComponent implements OnInit {
  private _item: IAchievementTarget;
  @Input()
  get item(): IAchievementTarget {
    return this._item;
  }
  set item(value: IAchievementTarget) {
    this._item = value;
    this.itemChange.emit(value);
  }
  myForm: FormGroup;
  @Input() editEnable: boolean;
  @Input() index: number;
  @Input() types: IConfigInfo[];
  @Input() contents: IAchievementDeliverableContentItem[];
  @Input() suggestions: IConfigInfo[];
  @Input() projectCode: string;

  @Output()
  deleteItem: EventEmitter<number> = new EventEmitter<number>();
  @Output()
  valid: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  itemChange: EventEmitter<IAchievementTarget> = new EventEmitter<IAchievementTarget>();


  constructor(
    private fb: FormBuilder,
    private propertyConfigService: PropertyConfigService,
    private el: ElementRef
  ) {
    this.myForm = this.fb.group({
      code: [''], // 流水编码
      name: ['', Validators.required], // 技术成果名称
      type_code: ['', Validators.required], // 所属分类
      type_name: [''],
      deliverable: [''], // 成果交付物内容
      tech_description: ['', Validators.required], // 技术成果描述
      level_code: [''], // 定级建议
      level_name: [''],
      is_droped: [false]  // 是否废弃
    });

  }

  ngOnInit() {
    this.myForm.patchValue(this.item);

    this.myForm.valueChanges.subscribe(value => {
      this.item = Object.assign(this.item, value, { _valid: this.myForm.valid });
      this.valid.emit();
    });


    this.myForm.controls.type_code.valueChanges.subscribe(type_code => {
      if (type_code) {
        const type = _.find(this.types, { key: type_code });
        this.myForm.controls.type_name.setValue(type?.name);
        const content = _.find(this.contents, { ntp_type_code: type_code });
        this.myForm.controls.deliverable.setValue(content?.content);
        this.getAchievementNewCode();
      } else {
        this.myForm.controls.type_name.setValue('');
        this.myForm.controls.deliverable.setValue('');
        this.myForm.controls.code.setValue('');
      }
    });

    this.myForm.controls.level_code.valueChanges.subscribe(level_code => {
      if (level_code) {
        const level = _.find(this.suggestions, { key: level_code });
        this.myForm.controls.level_name.setValue(level?.name);
        this.getAchievementNewCode();
      } else {
        this.myForm.controls.level_name.setValue('');
        this.myForm.controls.code.setValue('');
      }
    });
  }

  ngAfterViewInit() {
    if (!this.editEnable) {
      return;
    }
    this.el.nativeElement.querySelector(`#textarea-${this.index}`).style.height = 'auto';
    this.el.nativeElement.querySelector(`#textarea-${this.index}`).style.height = this.el.nativeElement.querySelector(`#textarea-${this.index}`).scrollHeight + 'px';
  }

  // 获取流水编码
  getAchievementNewCode() {
    if (this.projectCode && this.myForm.value.type_code && this.myForm.value.level_code) {
      const params = {
        project_code: this.projectCode,
        type_code: this.myForm.value.type_code,
        level_code: this.myForm.value.level_code
      };
      this.propertyConfigService.getAchievementNewCode(params).subscribe(code => {
        this.myForm.controls.code.setValue(code);
      });
    }
  }

  delete() {
    this.deleteItem.emit(this.index);
    this.valid.emit();
  }

  onInputChange(event: any) {
    const textarea = event.target;
    textarea.style.height = 'auto';
    textarea.style.height = textarea.scrollHeight + 'px';
  }

}
