import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, forkJoin, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import _ from 'lodash';
import { COUNT_THROTTLE_TIME } from '../consts';
import * as moment from 'moment';

import {
  IPlanTemplate,
  IPlanTemplateParams,
  IPlanTemplateCreateCommand,
  IPlanTemplateUpdateCommand,
  IPlanTemplateFile,
  IPlanTemplateFileParams,
  IMilestone,
  IPlan,
  IProject,
  IUserInfo,
  IPlanRecommendedTargetEndTimeParams
} from 'src/app/models';
import { PAGE_SIZE } from 'src/app/consts';
import { EPlanType, EWorkflowUserType } from 'src/app/enums';
import { SharedService } from './shared.service';
import { ConfigService } from './config.service';
import { AuditService } from './audit.service';

@Injectable({
  providedIn: 'root'
})
export class PlanTemplateService {
  private apiUrl = '/api/templates/projects/plans';

  // tslint:disable-next-line: variable-name
  private _IPlanTemplateParamsCount = 0;
  // tslint:disable-next-line: variable-name
  private _IPlanTemplateParamsParams: IPlanTemplateParams;
  // tslint:disable-next-line: variable-name
  private _IPlanTemplateParamsTimeStamp: number;

  constructor(
    private http: HttpClient,
    private sharedService: SharedService,
    private configService: ConfigService,
    private auditService: AuditService
  ) {
  }

  getPlanTemplates(params?: IPlanTemplateParams): Observable<IPlanTemplate[]> {
    params = params || { page: 1, limit: PAGE_SIZE };
    return this.http.get<IPlanTemplate[]>(`${this.apiUrl}`, { params: params as HttpParams }).pipe(
      map(items => {
        _.forEach(items, item => {
          // 前置计划
          if (item.prev_plans && item.prev_plans.length) {
            item._prev_plans_string = _.map(item.prev_plans, 'name').join(' / ');
          }
          // 项目细分类
          if (item.shared_categories && item.shared_categories.length) {
            item._shared_categories_string = item.shared_categories.join(' / ');
          }
        });
        return items;
      }),
      catchError(this.sharedService.handleError<IPlanTemplate[]>('get plan templates', []))
    );
  }

  getPlanTemplatesCount(params?: IPlanTemplateParams): Observable<number> {
    params = params || { page: 1, limit: PAGE_SIZE };
    return this.http.get<number>(`${this.apiUrl}-count`, { params: params as HttpParams }).pipe(
      catchError(this.sharedService.handleError<number>('get plan templates count', 0))
    );
  }

  // 通过id获取计划模板详情
  getPlanTemplate(id: number): Observable<IPlanTemplate> {
    return this.http.get<IPlanTemplate>(`${this.apiUrl}/${id}`).pipe(
      catchError(this.sharedService.handleError<IPlanTemplate>(`get plan template: ${id}`, undefined))
    );
  }

  // 通过code获取计划模板详情
  // tslint:disable-next-line: variable-name
  getPlanTemplateByCode(code: string, category_key: string): Observable<IPlanTemplate> {
    const params = this.sharedService.omitEmptyParams({
      category_key
    });
    return this.http.get<IPlanTemplate>(`${this.apiUrl}/code/${code}`, { params: params as unknown as HttpParams }).pipe(
      catchError(this.sharedService.handleError<IPlanTemplate>(`get plan template: ${code}`, undefined))
    );
  }

  createPlanTemplate(planTemplateCreateCommand: IPlanTemplateCreateCommand): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}`, planTemplateCreateCommand).pipe(
      catchError(this.sharedService.handleError<any>('create plan template', false))
    );
  }

  updatePlanTemplate(planTemplateUpdateCommand: IPlanTemplateUpdateCommand): Observable<any> {
    return this.http.put<any>(`${this.apiUrl}/${planTemplateUpdateCommand.id}`, planTemplateUpdateCommand).pipe(
      catchError(this.sharedService.handleError<any>('update plan template', false))
    );
  }

  getPlanTemplateFiles(params: IPlanTemplateFileParams): Observable<IPlanTemplateFile[]> {
    return this.http.get<IPlanTemplateFile[]>(`${this.apiUrl}/files`, { params: params as unknown as HttpParams }).pipe(
      catchError(this.sharedService.handleError<IPlanTemplateFile[]>('get plan template file', []))
    );
  }

  // 停用标准计划模板
  stopPlanTemplate(planId: number): Observable<any> {
    const params = { id: planId };
    return this.http.put<any>(`${this.apiUrl}/${planId}/is-deleted`, { params: params as unknown as HttpParams }).pipe(
      catchError(this.sharedService.handleError<any>('delete plan template', false))
    );
  }

  // 重启标准计划模板
  restartPlanTemplate(planId: number): Observable<any> {
    const params = { id: planId };
    return this.http.put<any>(`/api/templates/projects/plans/${planId}/restart`, { params: params as unknown as HttpParams }).pipe(
      catchError(this.sharedService.handleError<any>('restart plan template', false))
    );
  }

  // 由标准计划模板生成标准计划
  generatePlanFromTemplate(
    project: IProject,
    currentMilestone: IMilestone,
    planTemplate: IPlanTemplate,
    parentId: string, // 父计划id,
    parentPlanTargetEndTime?: string // 一级计划没有parentPlanTargetEndTime
  ): Observable<IPlan> {
    // let targetEndTime;
    // if (0 === currentMilestone.standard_days) {
    //   targetEndTime = currentMilestone.target_end_time;
    // } else {
    //   // 计划实际周期（负数转正数，计划标准周期根据节点实际时长等比缩放）
    //   const cycle = Math.round((-planTemplate.standard_cycle) * currentMilestone.life_cycle_ratio);
    //   // 计划完成时间（节点计划完成时间 - 计划实际周期）
    //   targetEndTime = moment(currentMilestone.gantt_end_on).subtract(cycle, 'day').format('YYYY-MM-DD');
    //   // 如果计划完成时间早于当前时间，则取当前时间
    //   targetEndTime = moment.max(moment(targetEndTime), moment()).format('YYYY-MM-DD');
    //   // 如果计划完成时间晚于父计划完成时间，取父计划完成时间
    //   if (parentPlanTargetEndTime) {
    //     targetEndTime = moment.min(moment(targetEndTime), moment(parentPlanTargetEndTime)).format('YYYY-MM-DD');
    //   }
    // }

    let requests;
    if (planTemplate.project_role) {
      requests = [
        this.configService.getNewCode(),
        this.auditService.getAuditInfoDefaultPersons({  // 带出责任人
          type: EWorkflowUserType.OrganizationRole, // 获取组织中的项目角色
          role: planTemplate.project_role,  // 项目角色
          project_code: project.code
        }),
        this.getRecommendedTargetEndTime({
          project_code: project.code,
          milestone_code: currentMilestone.code,
          standard_cycle: planTemplate.standard_cycle,
          parent_target_time: parentPlanTargetEndTime
        })
      ];
    } else {
      requests = [
        this.configService.getNewCode(),
        of([]),
        this.getRecommendedTargetEndTime({
          project_code: project.code,
          milestone_code: currentMilestone.code,
          standard_cycle: planTemplate.standard_cycle,
          parent_target_time: parentPlanTargetEndTime
        })
      ];
    }

    return forkJoin(requests).pipe(
      map(results => {
        const newCode = results[0] as string;
        const persons = results[1] as IUserInfo[];
        const targetEndTime = results[2] as string;
        const plan: IPlan = {
          id: newCode,
          parent_id: parentId,
          name: planTemplate.name,
          plan_type: EPlanType.Standard,
          code: planTemplate.code,
          target_end_time: targetEndTime,
          project_milestone_id: currentMilestone.id,
          milestone_code: currentMilestone.code,
          target_requirements: planTemplate.target_requirements,
          engin_field_key: planTemplate.engin_field_key,
          engin_field_name: planTemplate.engin_field_name,
          target_deliverable_codes: planTemplate.deliverable_codes,
          target_deliverables: planTemplate.deliverables,
          prev_plan_codes: planTemplate.prev_plan_codes,
          is_can_split: true,  // （有权限创建计划，创建的计划即可分解）
          is_can_update: true,  // （有权限创建计划，创建的计划即可变更）
          is_can_copy: true, // (有权限创建计划，创建的计划即可复制)
          template_plan_id: planTemplate.id // 保存计划模板id
        };
        if (persons && persons.length) {
          // 带出责任人
          plan.responsible_person_id = persons[0].user_id;
          plan.responsible_person_name = persons[0].name;
          // 带出责任单位
          plan.responsible_org_number = persons[0].org_number;
          plan.responsible_org_name = persons[0].org_name;
        }
        return plan;
      })
    );

  }

  // 获取分级计划模板列表
  getLevelPlanTemplates(params?: IPlanTemplateParams): Observable<IPlanTemplate[]> {
    params = params || { page: 1, limit: PAGE_SIZE };
    return this.http.get<IPlanTemplate[]>(`/api/templates/projects/level-plans`, { params: params as HttpParams }).pipe(
      map(items => {
        _.forEach(items, item => {
          // 前置计划
          if (item.prev_plans && item.prev_plans.length) {
            item._prev_plans_string = _.map(item.prev_plans, 'name').join(' / ');
          }
          // 项目细分类
          if (item.shared_categories && item.shared_categories.length) {
            item._shared_categories_string = item.shared_categories.join(' / ');
          }
        });
        return items;
      }),
      catchError(this.sharedService.handleError<IPlanTemplate[]>('get level plan templates', []))
    );
  }

  getLevelPlanTemplatesCount(params?: IPlanTemplateParams, enforceNew?: boolean): Observable<number> {
    params = params || { page: 1, limit: PAGE_SIZE };

    // # 节流
    // tslint:disable-next-line: variable-name
    const _params = _.cloneDeep(params);
    delete _params.page;

    const timeStamp = new Date().getTime();
    const isTimeOut = this._IPlanTemplateParamsTimeStamp && timeStamp - this._IPlanTemplateParamsTimeStamp > COUNT_THROTTLE_TIME;

    // ！强制获取新数据 && ！超时 && 参数不变，返回之前获取的count
    if (!enforceNew && !isTimeOut && _.isEqual(this._IPlanTemplateParamsParams, _params)) {
      return of(this._IPlanTemplateParamsCount);
    }
    return this.http.get<number>(`/api/templates/projects/level-plans-count`, { params: params as HttpParams }).pipe(
      tap(count => {
        this._IPlanTemplateParamsCount = count;
        this._IPlanTemplateParamsParams = _.cloneDeep(_params);
        this._IPlanTemplateParamsTimeStamp = timeStamp;
      }),
      catchError(this.sharedService.handleError<number>('get level plan templates count', 0))
    );
  }

  getRecommendedTargetEndTime(params: IPlanRecommendedTargetEndTimeParams): Observable<string> {
    params = this.sharedService.omitEmptyParams(params);
    // tslint:disable-next-line:max-line-length
    return this.http.get<string>('/api/templates/projects/plans/get-recommended-target-end-time', { params: params as unknown as HttpParams }).pipe(
      catchError(this.sharedService.handleError('get recommended target end time', ''))
    );
  }

  getProcessSystems(): Observable<string[]> {
    return this.http.get<string[]>('/api/open-api/apps').pipe(
      catchError(this.sharedService.handleError('get process systems', []))
    );
  }

  // 获取计划标签列表
  getPlanTags(project_code: string): Observable<string[]> {
    const params = { project_code };
    return this.http.get<string[]>(`${this.apiUrl}/tags`, { params: params as unknown as HttpParams }).pipe(
      catchError(this.sharedService.handleError('get plans tags', []))
    );
  }
}
