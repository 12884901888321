<ng-template #saveNotify>
  <div class="modal-header">
    <h5 class="modal-title">暂存</h5>
    <button type="button" class="close" (click)="hide()">
      <i class="fa fa-times"></i>
    </button>
  </div>
  <div class="modal-body">
    直接返回会丢失所填信息，是否先进行暂存？
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary mr-2" (click)="confirm()">
      是
    </button>
    <button type="button" class="btn btn-outline-secondary" (click)="hide()">
      否
    </button>
  </div>
</ng-template>
