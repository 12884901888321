import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class CacheInterceptor implements HttpInterceptor {
  constructor(
  ) { }
  intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {
    const authReq = req.clone({
      setHeaders: {
        // disable IE ajax request caching
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
        // extra
        'Cache-Control': 'no-cache',
        // tslint:disable-next-line: object-literal-key-quotes
        'Pragma': 'no-cache'
      }
    });
    return next.handle(authReq);
  }
}
