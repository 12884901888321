// 决议项报表的访问
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SharedService } from '../services/shared.service';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class DecisionGuard implements CanActivate {
  constructor(
    private router: Router,
    private userService: UserService,
    private sharedService: SharedService
  ) {

  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // // 系统管理员 || 当前用户属于”集团“事业部
    // return this.userService.getCurrentIsBelongToGroup().pipe(
    //   map(isBelongToGroup => {
    //     if (this.userService.getCurrentIsSystemAdminSync() || isBelongToGroup) {
    //       return true;
    //     } else {
    //       this.sharedService.setRedirectUrl(window.location.href);
    //       this.router.navigate(['/work-center']);
    //       return false;
    //     }
    //   })
    // );
    return true;
  }

}
