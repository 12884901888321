import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import _ from 'lodash';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ROOT_ENGIN_FIELD_PARENT_KEY } from 'src/app/consts';
import { IDivisionPlanEnginField, IDivisionPlanEnginFieldParams } from 'src/app/models';
import { ReduxService } from 'src/app/redux/redux.service';
import { PlanService } from 'src/app/services/plan.service';

@Component({
  selector: 'app-check-engin-field-tree',
  templateUrl: './check-engin-field-tree.component.html',
  styleUrls: ['./check-engin-field-tree.component.scss']
})
export class CheckEnginFieldTreeComponent implements OnInit {
  modalRef: BsModalRef;
  @ViewChild('appCheckEnginFieldTree') modalEle: TemplateRef<any>;

  items: IDivisionPlanEnginField[];
  checkedItem: IDivisionPlanEnginField;
  currentAndAncestorEnginFieldKeys: string[] = []; // 当前所属业务的key及所有的父级所属业务key
  parentPlanEnginFieldKey: string; // 父计划的engin_field_key

  // tslint:disable-next-line:variable-name
  private _checkedEnginField: IDivisionPlanEnginField;
  @Input()
  get checkedEnginField() {
    return this._checkedEnginField;
  }
  set checkedEnginField(value) {
    this._checkedEnginField = value;
    this.checkedEnginFieldChange.emit(value);
  }
  @Output()
  checkedEnginFieldChange: EventEmitter<IDivisionPlanEnginField> = new EventEmitter();

  enginFields: IDivisionPlanEnginField[];
  constructor(
    private modalService: BsModalService,
    private planService: PlanService,
    private reduxService: ReduxService
  ) { }

  ngOnInit() {
    this.reduxService.subscribeStore(() => this.readState());
    this.readState();
  }

  readState() {
    this.parentPlanEnginFieldKey = this.reduxService.getCommonParentPlanEnginFieldKey();
  }

  show(queryParams: IDivisionPlanEnginFieldParams, onlyShowParentEnginFields?: boolean) {
    this.planService.getDivisionPlanEnginFields(queryParams).subscribe((enginFields) => {
      if (onlyShowParentEnginFields) {
        enginFields = _.filter(enginFields, enginField => {
          return !_.includes(enginField.key, '-');
        });
      }
      this.enginFields = enginFields;
      this.initRootEnginFields();
    });
    this.modalRef = this.modalService.show(this.modalEle, { class: 'modal-lg', ignoreBackdropClick: true });
  }

  initRootEnginFields() {
    // 找出所有的父级
    this.items = _.filter(this.enginFields, { parent_key: ROOT_ENGIN_FIELD_PARENT_KEY });
    _.forEach(this.items, parent => {
      const children = _.filter(this.enginFields, { parent_key: parent.key });
      parent._is_show = true;
      parent._level = 0;
      parent._has_loaded_children = false;
      if (children.length) {
        parent._has_children = true;
        parent._is_expand = false;
      }
    });
    // 所属业务默认展开到父计划所属业务层
    this.expandParentPlanItem();
  }

  toggleExpandItem(item: IDivisionPlanEnginField) {
    item._is_expand = !item._is_expand;
    if (item._is_expand) {
      if (item._has_loaded_children) {
        this.showOrHideChildren(item, true);
        return;
      }
      item._has_loaded_children = true;
      this.getEnginFieldChildren(item);
    } else {
      this.showOrHideChildren(item, false);
    }
  }
  getEnginFieldChildren(item: IDivisionPlanEnginField) {
    // 找出了所有的子级
    const items = _.filter(this.enginFields, { parent_key: item.key });
    // 确认每个子级是否还有子业务
    _.forEach(items, parent => {
      const children = _.filter(this.enginFields, { parent_key: parent.key });
      parent._is_show = true;
      parent._level = item._level + 1;
      parent._has_loaded_children = false;
      if (children.length) {
        parent._has_children = true;
        parent._is_expand = false;
      }
    });
    // 找到item的索引，将items插入后面
    const index = _.findIndex(this.items, { key: item.key });
    this.items = [
      ...this.items.slice(0, index + 1),
      ...items,
      ...this.items.slice(index + 1)
    ];
  }

  showOrHideChildren(item: IDivisionPlanEnginField, isShow: boolean) {
    const children = _.filter(this.enginFields, { parent_key: item.key });
    item._is_expand = isShow;
    _.forEach(children, child => {
      child._is_show = isShow;
      if (child._has_children) {
        this.showOrHideChildren(child, isShow);
      }
    });
  }

  checkItem(item: IDivisionPlanEnginField) {
    this.checkedItem = item;
  }

  // 展开父计划所在所属业务层
  expandParentPlanItem() {
    if (!this.parentPlanEnginFieldKey) {
      return;
    }
    this.findParentEnginField(this.parentPlanEnginFieldKey);
    if (!this.currentAndAncestorEnginFieldKeys.length) {
      return;
    }
    // 依次展开;展开到父计划所属业务层
    _.forEach(_.reverse(this.currentAndAncestorEnginFieldKeys), parentEnginFieldKey => {
      const item = _.find(this.items, { key: parentEnginFieldKey });
      this.toggleExpandItem(item);
    });
  }

  // 找出当前所属业务的key及所有的父级所属业务key
  findParentEnginField(key: string) {
    const enginField = _.find(this.enginFields, { key });
    const parentEnginFieldKey = enginField.parent_key;
    this.currentAndAncestorEnginFieldKeys.push(key);
    if (parentEnginFieldKey !== ROOT_ENGIN_FIELD_PARENT_KEY) {
      this.findParentEnginField(parentEnginFieldKey);
    }
  }

  hide() {
    if (this.modalRef) {
      this.checkedItem = undefined;
      this.modalRef.hide();
      this.currentAndAncestorEnginFieldKeys = [];
      this.reduxService.setCommonParentPlanEnginFieldKey('');
    }
  }

  confirm() {
    this.checkedEnginField = this.checkedItem;
    this.hide();
  }

}
