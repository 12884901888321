// *工作流节点审批人类型
export enum EWorkflowUserType {
    /// <summary>
    /// 自定义用户
    /// </summary>
    CustomUser = 0,

    /// <summary>
    /// 项目角色
    /// </summary>
    OrganizationRole = 10,

    /// <summary>
    /// 提交人
    /// </summary>
    SubmitUser = 20,

    /// <summary>
    /// 上级责任人
    /// </summary>
    SuperiorUser = 30,

    /// <summary>
    /// 后置计划责任人
    /// </summary>
    PostPlanUser = 40,

    /// <summary>
    /// 需求管理工程师
    /// </summary>
    ReqManagementEngineer = 50
}
