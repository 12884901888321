import { Action, ActionCreator } from 'redux';
import {
  IOrganizationChartItem,
  TOrganizationChartItemAddType,
  IOrganizationTemplate,
  TOrganizationChartContentType,
  IOrganizationTemplateOrgHashCode,
  IUserInfo
} from 'src/app/models';
import { TOrganizationChartViewType } from 'src/app/models/IOrganizationChartItem';

// #region setOrganizationChartItems
export interface SetOrganizationChartItemsAction extends Action {
  items: IOrganizationChartItem[];
}
export const SET_ORGANIZATION_CHART_ITEMS = 'SET_ORGANIZATION_CHART_ITEMS';
export const setOrganizationChartItems: ActionCreator<SetOrganizationChartItemsAction> = (items: IOrganizationChartItem[]) => ({
  type: SET_ORGANIZATION_CHART_ITEMS,
  items
});
// #endregion

// #region setOrganizationChartCurrentAllItems
export interface SetOrganizationChartCurrentAllItemsAction extends Action {
  currentAllItems: IOrganizationChartItem[];
}
export const SET_ORGANIZATION_CHART_CURRENT_ALL_ITEMS = 'SET_ORGANIZATION_CHART_CURRENT_ALL_ITEMS';
export const setOrganizationChartCurrentAllItems: ActionCreator<SetOrganizationChartCurrentAllItemsAction>
  = (currentAllItems: IOrganizationChartItem[]) => ({
    type: SET_ORGANIZATION_CHART_CURRENT_ALL_ITEMS,
    currentAllItems
  });
// #endregion

// #region setOrganizationChartPreviewAllItems
export interface SetOrganizationChartPreviewAllItemsAction extends Action {
  previewAllItems: IOrganizationChartItem[];
}
export const SET_ORGANIZATION_CHART_PREVIEW_ALL_ITEMS = 'SET_ORGANIZATION_CHART_PREVIEW_ALL_ITEMS';
export const setOrganizationChartPreviewAllItems: ActionCreator<SetOrganizationChartPreviewAllItemsAction>
  = (previewAllItems: IOrganizationChartItem[]) => ({
    type: SET_ORGANIZATION_CHART_PREVIEW_ALL_ITEMS,
    previewAllItems
  });
// #endregion

// #region setOrganizationChartPath
export interface SetOrganizationChartPathAction extends Action {
  path: string;
}
export const SET_ORGANIZATION_CHART_PATH = 'SET_ORGANIZATION_CHART_PATH';
export const setOrganizationChartPath: ActionCreator<SetOrganizationChartPathAction> = (path: string) => ({
  type: SET_ORGANIZATION_CHART_PATH,
  path
});
// #endregion

// #region setOrganizationChartEditEnable
export interface SetOrganizationChartEditEnableAction extends Action {
  editEnable: boolean;
}
export const SET_ORGANIZATION_CHART_EDIT_ENABLE = 'SET_ORGANIZATION_CHART_EDIT_ENABLE';
export const setOrganizationChartEditEnable: ActionCreator<SetOrganizationChartEditEnableAction> = (editEnable: boolean) => ({
  type: SET_ORGANIZATION_CHART_EDIT_ENABLE,
  editEnable
});
// #endregion

// #region setOrganizationChartViewType
export interface SetOrganizationChartViewTypeAction extends Action {
  viewType: TOrganizationChartViewType;
}
export const SET_ORGANIZATION_CHART_VIEW_TYPE = 'SET_ORGANIZATION_CHART_VIEW_TYPE';
export const setOrganizationChartViewType: ActionCreator<SetOrganizationChartViewTypeAction> = (viewType: TOrganizationChartViewType) => ({
  type: SET_ORGANIZATION_CHART_VIEW_TYPE,
  viewType
});
// #endregion

// #region setOrganizationChartDirty
export interface SetOrganizationChartDirtyAction extends Action {
  dirty: boolean;
}
export const SET_ORGANIZATION_CHART_DIRTY = 'SET_ORGANIZATION_CHART_DIRTY';
export const setOrganizationChartDirty: ActionCreator<SetOrganizationChartDirtyAction> = (dirty: boolean) => ({
  type: SET_ORGANIZATION_CHART_DIRTY,
  dirty
});
// #endregion

// #region checkOrganizationChartItem
export interface CheckOrganizationChartItemAction extends Action {
  item: IOrganizationChartItem;
}
export const CHECK_ORGANIZATION_CHART_ITEM = 'CHECK_ORGANIZATION_CHART_ITEM';
export const checkOrganizationChartItem: ActionCreator<CheckOrganizationChartItemAction> = (item: IOrganizationChartItem) => ({
  type: CHECK_ORGANIZATION_CHART_ITEM,
  item
});
// #endregion

// #region checkOrganizationChartItemTransformed
export interface CheckOrganizationChartItemTransformedAction extends Action {
  itemTransformed: IOrganizationChartItem;
}
export const CHECK_ORGANIZATION_CHART_ITEM_TRANSFORMED = 'CHECK_ORGANIZATION_CHART_ITEM_TRANSFORMED';
export const checkOrganizationChartItemTransformed: ActionCreator<CheckOrganizationChartItemTransformedAction>
  = (itemTransformed: IOrganizationChartItem) => ({
    type: CHECK_ORGANIZATION_CHART_ITEM_TRANSFORMED,
    itemTransformed
  });
// #endregion

// #region toggleCollapseOrganizationChartItemTransformed
export interface ToggleCollapseOrganizationChartItemTransformedAction extends Action {
  itemTransformed: IOrganizationChartItem;
}
export const TOGGLE_COLLAPSE_ORGANIZATION_CHART_ITEM_TRANSFORMED = 'TOGGLE_COLLAPSE_ORGANIZATION_CHART_ITEM_TRANSFORMED';
export const toggleCollapseOrganizationChartItemTransformed: ActionCreator<ToggleCollapseOrganizationChartItemTransformedAction>
  = (itemTransformed: IOrganizationChartItem) => ({
    type: TOGGLE_COLLAPSE_ORGANIZATION_CHART_ITEM_TRANSFORMED,
    itemTransformed
  });
// #endregion

// #region toggleCollapseAllOrganizationChartItems
export interface ToggleCollapseAllOrganizationChartItemsAction extends Action {
  isCollapsed: boolean;
  contentType: TOrganizationChartContentType;
}
export const TOGGLE_COLLAPSE_ALL_ORGANIZATION_CHART_ITEMS = 'TOGGLE_COLLAPSE_ALL_ORGANIZATION_CHART_ITEMS';
export const toggleCollapseAllOrganizationChartItems: ActionCreator<ToggleCollapseAllOrganizationChartItemsAction>
  = (isCollapsed: boolean, contentType: TOrganizationChartContentType) => ({
    type: TOGGLE_COLLAPSE_ALL_ORGANIZATION_CHART_ITEMS,
    isCollapsed,
    contentType
  });
// #endregion

// #region updateOrganizationChartItem
export interface UpdateOrganizationChartItemAction extends Action {
  item: IOrganizationChartItem;
}
export const UPDATE_ORGANIZATION_CHART_ITEM = 'UPDATE_ORGANIZATION_CHART_ITEM';
export const updateOrganizationChartItem: ActionCreator<UpdateOrganizationChartItemAction> = (item: IOrganizationChartItem) => ({
  type: UPDATE_ORGANIZATION_CHART_ITEM,
  item
});
// #endregion

// #region 添加items
export interface AddOrganizationChartItemsAction extends Action {
  referenceItem: IOrganizationChartItem;  // 参考节点
  newItems: IOrganizationChartItem[];
  addType: TOrganizationChartItemAddType;
}
export const ADD_ORGANIZATION_CHART_ITEMS = 'ADD_ORGANIZATION_CHART_ITEMS';
export const addOrganizationChartItems: ActionCreator<AddOrganizationChartItemsAction> = (
  referenceItem: IOrganizationChartItem,
  newItems: IOrganizationChartItem[],
  addType: TOrganizationChartItemAddType
) => ({
  type: ADD_ORGANIZATION_CHART_ITEMS,
  referenceItem,
  newItems,
  addType
});
// #endregion

// #region 添加标准组织节点
export interface AddStandardOrganizationChartItemsAction extends Action {
  referenceItem: IOrganizationChartItem;  // 参考节点
  newItems: IOrganizationChartItem[];
}
export const ADD_STANDARD_ORGANIZATION_CHART_ITEMS = 'ADD_STANDARD_ORGANIZATION_CHART_ITEMS';
export const addStandardOrganizationChartItems: ActionCreator<AddStandardOrganizationChartItemsAction> = (
  referenceItem: IOrganizationChartItem,
  newItems: IOrganizationChartItem[],
) => ({
  type: ADD_STANDARD_ORGANIZATION_CHART_ITEMS,
  referenceItem,
  newItems,
});
// #endregion

// #region 删除items
export interface DeleteOrganizationChartItemsAction extends Action {
  items: IOrganizationChartItem[];
}
export const DELETE_ORGANIZATION_CHART_ITEMS = 'DELETE_ORGANIZATION_CHART_ITEMS';
export const deleteOrganizationChartItems: ActionCreator<DeleteOrganizationChartItemsAction> = (
  items: IOrganizationChartItem[]
) => ({
  type: DELETE_ORGANIZATION_CHART_ITEMS,
  items
});

// #region 删除某节点的全部后代节点
export interface DeleteOrganizationChartOffspringItemsAction extends Action {
  item: IOrganizationChartItem;
}
export const DELETE_ORGANIZATION_CHART_OFFSPRING_ITEMS = 'DELETE_ORGANIZATION_CHART_OFFSPRING_ITEMS';
export const deleteOrganizationChartOffspringItems: ActionCreator<DeleteOrganizationChartOffspringItemsAction> = (
  item: IOrganizationChartItem
) => ({
  type: DELETE_ORGANIZATION_CHART_OFFSPRING_ITEMS,
  item
});
// #endregion

// #region 添加组织模板生成的items
export interface AddTemplateOrganizationChartItemsAction extends Action {
  items: IOrganizationChartItem[];  // 由模板生成的组织节点
  parentNodePath: string; // 模板生成的节点列表的 parent_node_path
  currentUser?: IUserInfo;
  isProjectInWhitelist?: boolean;
  permissions?: string[];
}
export const ADD_TEMPLATE_ORGANIZATION_CHART_ITEMS = 'ADD_TEMPLATE_ORGANIZATION_CHART_ITEMS';
export const addTemplateOrganizationChartItems: ActionCreator<AddTemplateOrganizationChartItemsAction> = (
  items: IOrganizationChartItem[],
  parentNodePath: string,
  currentUser: IUserInfo,
  isProjectInWhitelist: boolean,
  permissions: string[],
) => ({
  type: ADD_TEMPLATE_ORGANIZATION_CHART_ITEMS,
  items,
  parentNodePath,
  currentUser,
  isProjectInWhitelist,
  permissions
});
// #endregion

// #region setOrganizationTemplateOrgHashCodes
export interface SetOrganizationTemplateOrgHashCodesAction extends Action {
  organizationTemplateOrgHashCodes: IOrganizationTemplateOrgHashCode[];
}
export const SET_ORGANIZATION_TEMPLATE_ORG_HASH_CODES = 'SET_ORGANIZATION_TEMPLATE_ORG_HASH_CODES';
export const setOrganizationTemplateOrgHashCodes: ActionCreator<SetOrganizationTemplateOrgHashCodesAction>
  = (organizationTemplateOrgHashCodes: IOrganizationTemplateOrgHashCode[]) => ({
    type: SET_ORGANIZATION_TEMPLATE_ORG_HASH_CODES,
    organizationTemplateOrgHashCodes
  });
// #endregion

// #region addOrganizationTemplateOrgHashCode
export interface AddOrganizationTemplateOrgHashCodeAction extends Action {
  organizationTemplateOrgHashCode: IOrganizationTemplateOrgHashCode;
}
export const ADD_ORGANIZATION_TEMPLATE_ORG_HASH_CODE = 'ADD_ORGANIZATION_TEMPLATE_ORG_HASH_CODE';
export const addOrganizationTemplateOrgHashCode: ActionCreator<AddOrganizationTemplateOrgHashCodeAction>
  = (organizationTemplateOrgHashCode: IOrganizationTemplateOrgHashCode) => ({
    type: ADD_ORGANIZATION_TEMPLATE_ORG_HASH_CODE,
    organizationTemplateOrgHashCode
  });
// #endregion

// #region deleteOrganizationTemplateOrgHashCode
export interface DeleteOrganizationTemplateOrgHashCodeAction extends Action {
  templateOrgId: number;
}
export const DELETE_ORGANIZATION_TEMPLATE_ORG_HASH_CODE = 'DELETE_ORGANIZATION_TEMPLATE_ORG_HASH_CODE';
export const deleteOrganizationTemplateOrgHashCode: ActionCreator<DeleteOrganizationTemplateOrgHashCodeAction>
  = (templateOrgId: number) => ({
    type: DELETE_ORGANIZATION_TEMPLATE_ORG_HASH_CODE,
    templateOrgId
  });
// #endregion

// #region setOrganizationChartNeedUpdateParentIdItem
// tslint:disable-next-line:no-empty-interface
export interface SetOrganizationChartNeedUpdateParentIdItemAction extends Action {
  item: IOrganizationChartItem;
}
export const SET_ORGANIZATION_NEED_UPDATE_PARENT_ID_ITEM = 'SET_ORGANIZATION_NEED_UPDATE_PARENT_ID_ITEM';
// tslint:disable-next-line:max-line-length
export const setOrganizationChartNeedUpdateParentIdItem: ActionCreator<SetOrganizationChartNeedUpdateParentIdItemAction> = (item: IOrganizationChartItem) => ({
  type: SET_ORGANIZATION_NEED_UPDATE_PARENT_ID_ITEM,
  item
});
// #endregion

