import { Component, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import _ from 'lodash';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ECheckOrganizationUsersType } from 'src/app/enums';
import { IOrganization, ITab, IUserInfo } from 'src/app/models';
import { ReduxService } from 'src/app/redux/redux.service';
import { UserService } from 'src/app/services/user.service';
const CHECKED_USERS_MAX_COUNT = 9;    // 已选人员一行最多展示9人
@Component({
  selector: 'app-check-organization-users',
  templateUrl: './check-organization-users.component.html',
  styleUrls: ['./check-organization-users.component.scss']
})
export class CheckOrganizationUsersComponent {

  modalRef: BsModalRef;
  checkedUsersMaxCount = CHECKED_USERS_MAX_COUNT;
  @ViewChild('checkOrganizationUsers') modalEle: TemplateRef<any>;
  tabs: ITab[] = [{
    label: '最近',
    value: 1
  }, {
    label: '组织架构',
    value: 2
  }, {
    label: '常用组织',
    value: 3
  }];
  tabValue: number;
  tab = 1;
  showContent: boolean;
  checkedOrganization: IOrganization; // “组织架构”选中的组织
  checkedLatelyOrgNumber: string; // “常用组织”选中的组织
  allCheckedItems: IUserInfo[] = []; // 所有tab选中的items
  uncheckedItem: IUserInfo; // 在“已选人员”被删除的item
  isMultiple: boolean;
  filterForm: FormGroup;
  q: string;
  checkUsersType: ECheckOrganizationUsersType;
  checkUsersTypeEnum = ECheckOrganizationUsersType;
  constructor(
    private modalService: BsModalService,
    private userService: UserService,
    private reduxService: ReduxService,
    private fb: FormBuilder
  ) {
    this.filterForm = this.fb.group({
      q: ['']
    });

    this.reduxService.subscribeStore(() => this.readState());
    this.readState();
  }

  readState() {
    const isShow = this.reduxService.getCheckOrganizationUsersShow();
    this.isMultiple = this.reduxService.getCheckOrganizationUsersMultiple();
    this.checkUsersType = this.reduxService.getCheckOrganizationUsersUserType();

    if (isShow) {
      this.show();
    } else {
      this.hide();
    }
  }

  init() {
    this.initTabs();
    this.tabValue = this.checkUsersType === ECheckOrganizationUsersType.All ? 1 : 2;
    this.showContent = true;
  }

  initTabs() {
    switch (this.checkUsersType) {
      case ECheckOrganizationUsersType.All:
        break;
      case ECheckOrganizationUsersType.WorktimeUser:
        this.tabs = [{
          label: '组织架构',
          value: 2
        }, {
          label: '常用组织',
          value: 3
        }];
        this.tab = 2;
        break;
    }
  }

  show() {
    if (!this.modalRef) {
      this.init();
      this.modalRef = this.modalService.show(this.modalEle, { class: 'modal-lg', ignoreBackdropClick: true });
    }
  }

  changeTab(tabValue: number) {
    this.tabValue = tabValue;
    this.filterForm.controls.q.setValue('');
    this.q = this.filterForm.controls.q.value;
  }

  checkOrganization(checkedOrganization: IOrganization) {
    // 记录“组织架构”，当前选中的组织
    // 为触发子组件ngOnChanges，使用_.cloneDeep
    this.checkedOrganization = _.cloneDeep(checkedOrganization);
  }

  checkLatelyOrganization(checkedOrganizationOrgNumber: string) {
    // 记录“常用组织”，当前选中的组织
    this.checkedLatelyOrgNumber = checkedOrganizationOrgNumber;
  }

  initCheckedLatelyOrganization(checkedLatelyOrgNumber: string) {
    // “常用组织”,默认选中第一条
    this.checkedLatelyOrgNumber = checkedLatelyOrgNumber;
  }

  delete(item: IUserInfo) {
    this.uncheckedItem = item;
    this.allCheckedItems = _.reject(this.allCheckedItems, item);
  }

  confirm() {
    if (this.checkedOrganization) {
      this.userService.addLatelyOrganization(this.checkedOrganization.org_number).subscribe();
    }
    _.forEach(this.allCheckedItems, user => {
      this.userService.addLatelyUser(user.user_id).subscribe();
    });
    this.reduxService.setCheckOrganizationUsersNewUsers(this.allCheckedItems);
    this.reduxService.confirmCheckOrganizationUsers();
    this.hide();
  }

  hide() {
    if (this.modalRef) {
      this.modalRef.hide();
      this.modalRef = undefined;
      this.reduxService.cancelCheckOrganizationUsers();
      this.clear();
    }
  }

  clear() {
    this.allCheckedItems = []; // 所有tab选中的items
    this.checkedOrganization = undefined;
    this.checkedLatelyOrgNumber = '';
    this.filterForm.controls.q.setValue('');
    this.q = '';
  }

  search() {
    this.q = this.filterForm.controls.q.value.trim();
  }

  getCheckedItems(): IUserInfo[] {
    if (!this.showContent) {
      return this.allCheckedItems;
    } else {
      return _.take(this.allCheckedItems, this.checkedUsersMaxCount);
    }
  }

  toggleShowMorePersons() {
    this.showContent = !this.showContent;
  }

}
