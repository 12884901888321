export enum EHolidayConfigType {
    /// <summary>
    /// 法定节假
    /// </summary>
    LegalHoliday = 10,

    /// <summary>
    /// 高温假
    /// </summary>
    HighTemperatureVacation = 20,

    /// <summary>
    /// 周末
    /// </summary>
    Weekend = 30,

    /// <summary>
    /// （法定假日、高温假）
    /// </summary>
    WeekendWork = 30,
}
