// 普通用户强制调整到 info 页面，不可访问初始化页面
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree, CanActivateChild } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import _ from 'lodash';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root',
})
export class setupChildrenGuard implements CanActivateChild {
  constructor(
    private router: Router,
    private userService: UserService
  ) { }
  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if (this.userService.getCurrentIsSystemAdminSync()) {
      return true;
    }
    this.router.navigate([`/setup/info`]);
    return false;
  }
}