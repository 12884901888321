<ng-template #chat>
  <form [formGroup]="myForm">
    <div class="modal-header">
      <h5 class="modal-title">沟通</h5>
      <button type="button" class="close" (click)="hide()">
        <i class="fa fa-times"></i>
      </button>
    </div>
    <div class="modal-body">
      <table class="table-sm table-bordered text-center align-middle">
        <tbody>
          <tr>
            <td style="width: 160px;">
              <i class="fa fa-asterisk text-danger mr-1"></i>
              <button class="btn btn-primary" (click)="toCheckChatPersons()">选择被沟通人</button>
            </td>
            <td class="text-left">
              <div *ngFor="let person of chatPersons" class="chat-person">
                {{person.name}}<i class="fa fa-times ml-1" (click)="deleteChatPerson(person)"></i>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <i class="fa fa-asterisk text-danger mr-1"></i>沟通内容
            </td>
            <td>
              <textarea class="form-control" rows="3" formControlName="message"></textarea>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary mr-2" (click)="confirm()"
        [disabled]="!(myForm.valid && chatPersons.length)">
        确定
      </button>
      <button type="button" class="btn btn-outline-secondary" (click)="hide()">
       返回
      </button>
    </div>
  </form>
</ng-template>
