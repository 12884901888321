// *授权管理-工时费用报表查看权限
export enum EWorktimeCostReportViewPermission {
    /// <summary>
    /// 按人员维度查询
    /// </summary>
    ReportByUser = 1,

    /// <summary>
    /// 按项目维度查询
    /// </summary>
    ReportByProjects = 2,

    /// <summary>
    /// 按单个项目维度查询
    /// </summary>
    ReportByProject = 4,
}