<ng-template #checkOrganizationUsers>
  <div class="modal-header">
    <h5 [ngSwitch]="checkUsersType" class="modal-title">
      <div *ngSwitchCase="checkUsersTypeEnum.WorktimeUser">工时填报人员列表</div>
      <div *ngSwitchDefault>组织人员列表</div>
    </h5>
    <button type="button" class="close" (click)="hide()">
      <i class="fa fa-times"></i>
    </button>
  </div>

  <div class="modal-body">
    <div class="modal-body-header">
      <app-tabs [items]='tabs' [default]='tab' (itemChange)="changeTab($event)"></app-tabs>
      <div *ngIf="tabValue!==3" class="operations">
        <form [formGroup]="filterForm" (ngSubmit)="search()">
          <div class="input-with-icon">
            <input style="width: 200px;" class="form-control form-control rounded" placeholder="姓名、用户名" formControlName="q">
            <i class="fa fa-search input-icon" (click)="search()"></i>
          </div>
        </form>
      </div>
    </div>

    <!-- #region 最近用户 -->
    <ng-container>
      <div class="content" [ngClass]="{hidden: !showContent || tabValue !== 1}">
        <div *ngIf="tabValue===1" class="organization-user">
          <app-check-organization-users-list *ngIf="isMultiple" [tabValue]="tabValue"
            [(allCheckedItems)]="allCheckedItems" [uncheckedItem]="uncheckedItem" [q]="q">
          </app-check-organization-users-list>
          <app-check-organization-user-list *ngIf="!isMultiple" [tabValue]="tabValue"
            [(allCheckedItems)]="allCheckedItems" [uncheckedItem]="uncheckedItem" [q]="q">
          </app-check-organization-user-list>
        </div>
      </div>
    </ng-container>
    <!-- #endregion -->
    <!-- #region 组织架构-->
    <ng-container>
      <div class="content row" [ngClass]="{hidden: !showContent || tabValue !== 2}">
        <div class="organization col-5">
          <app-check-organization-tree (checkedOrganizationChange)="checkOrganization($event)">
          </app-check-organization-tree>
        </div>
        <div *ngIf="tabValue===2" class="organization-user col-7">
          <app-check-organization-users-list *ngIf="isMultiple" [tabValue]="tabValue"
            [(allCheckedItems)]="allCheckedItems" [uncheckedItem]="uncheckedItem" [q]="q"
            [checkedOrganization]="checkedOrganization">
          </app-check-organization-users-list>
          <app-check-organization-user-list *ngIf="!isMultiple" [tabValue]="tabValue"
            [(allCheckedItems)]="allCheckedItems" [uncheckedItem]="uncheckedItem" [q]="q"
            [checkedOrganization]="checkedOrganization">
          </app-check-organization-user-list>
        </div>
      </div>
    </ng-container>
    <!-- #endregion -->
    <!-- #region 常用组织-->
    <ng-container>
      <div class="content row" [ngClass]="{hidden: !showContent || tabValue !== 3}">
        <div class="organization col-5">
          <app-check-lately-organization (checkedItemChange)="checkLatelyOrganization($event)"
            (initCheckedItemChange)="initCheckedLatelyOrganization($event)">
          </app-check-lately-organization>
        </div>
        <div *ngIf="tabValue===3" class="organization-user col-7">
          <app-check-organization-users-list *ngIf="isMultiple" [tabValue]="tabValue"
            [(allCheckedItems)]="allCheckedItems" [uncheckedItem]="uncheckedItem"
            [checkedLatelyOrgNumber]="checkedLatelyOrgNumber" [q]="q">
          </app-check-organization-users-list>
          <app-check-organization-user-list *ngIf="!isMultiple" [tabValue]="tabValue"
            [(allCheckedItems)]="allCheckedItems" [uncheckedItem]="uncheckedItem"
            [checkedLatelyOrgNumber]="checkedLatelyOrgNumber" [q]="q">
          </app-check-organization-user-list>
        </div>

      </div>
    </ng-container>
    <!-- #endregion -->
    <div class="filter" [ngClass]='{"filter-lg":!showContent}'>
      <div class="title mt-2">已选人员:</div>
      <div class="filter-content" [ngClass]='{"filter-content-lg":!showContent}'>
        <ul>
          <li *ngFor="let item of getCheckedItems()" class="isChecked ellipsis">
            {{item.name}}
            <i class="fa fa-times-circle" (click)="delete(item)"></i>
          </li>
        </ul>
        <div *ngIf="allCheckedItems.length > checkedUsersMaxCount" class="show-more" (click)="toggleShowMorePersons();">
          <i *ngIf="!showContent" class="fa fa-angle-double-up text-primary"></i>
          <i *ngIf="showContent" class="fa fa-angle-double-down text-primary"></i>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-primary mr-2" (click)="confirm()">
      确定
    </button>
    <button type="button" class="btn btn-outline-secondary" (click)="hide()">
      返回
    </button>
  </div>
</ng-template>
