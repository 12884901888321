// 当天看板计划列表层级类型
export enum ECurrentDayKanbanInfoType {
    /// <summary>
    /// 项目
    /// </summary>
    Project = 10,

    /// <summary>
    /// 节点
    /// </summary>
    Milestone = 20,

    /// <summary>
    /// 计划
    /// </summary>
    Plan = 30,

    /// <summary>
    /// 调度
    /// </summary>
    Schedule = 40,
}
