import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of, Subscription } from 'rxjs';
import { catchError, map, shareReplay, tap } from 'rxjs/operators';
import { COUNT_THROTTLE_TIME, GROUP_DIVISION, ORGANIZATION_CHART_PROJECT_MANAGEMENT_ADMIN } from '../consts';
import _ from 'lodash';

import {
  IUserInfo,
  IUserInfoParams,
  IUserInfoCreateCommand,
  IOrganization,
  IOrganizationParams,
  IOrganizationTreeItem,
  IProject,
  IProjectGroupAdminInfo,
  IProjectGroupAdminParams,
  IProjectRoleInfo,
  IDivisionCategoryAdminRole,
  IAuditInfo
} from 'src/app/models';
import { EAuditStatus } from 'src/app/enums';
import { PAGE_SIZE, ROOT_ORGANIZATION_TREE_ITEM_PARENT_CODE } from 'src/app/consts';
import { SharedService } from './shared.service';
import { AuthProvider } from 'src/app/providers/auth.provider';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  apiUrl = '/api/users';

  // tslint:disable-next-line: variable-name
  private _usersCount = 0;
  // tslint:disable-next-line: variable-name
  private _usersParams: IUserInfoParams;
  // tslint:disable-next-line: variable-name
  private _usersTimeStamp: number;

  constructor(
    private http: HttpClient,
    private sharedService: SharedService,
    private authProvider: AuthProvider
  ) { }

  getUsers(params?: IUserInfoParams): Observable<IUserInfo[]> {
    params = params || { page: 1, limit: PAGE_SIZE };
    return this.http.get<IUserInfo[]>(`${this.apiUrl}`, { params: params as HttpParams }).pipe(
      catchError(this.sharedService.handleError<IUserInfo[]>('get users', []))
    );
  }

  getUsersCount(params?: IUserInfoParams, enforceNew?: boolean): Observable<number> {
    params = params || { page: 1, limit: PAGE_SIZE };
    // # 节流
    // tslint:disable-next-line: variable-name
    const _params = _.cloneDeep(params);
    delete _params.page;

    const timeStamp = new Date().getTime();
    const isTimeOut = this._usersTimeStamp && timeStamp - this._usersTimeStamp > COUNT_THROTTLE_TIME;

    // ！强制获取新数据 && ！超时 && 参数不变，返回之前获取的count
    if (!enforceNew && !isTimeOut && _.isEqual(this._usersParams, _params)) {
      return of(this._usersCount);
    }
    return this.http.get<number>(`${this.apiUrl}-count`, { params: params as HttpParams }).pipe(
      tap(count => {
        this._usersCount = count;
        this._usersParams = _.cloneDeep(_params);
        this._usersTimeStamp = timeStamp;
      }),
      catchError(this.sharedService.handleError<number>('get users count', 0))
    );
  }

  getUser(userId: string /* 账号 */): Observable<IUserInfo> {
    return this.http.get<IUserInfo>(`${this.apiUrl}/${userId}`).pipe(
      catchError(this.sharedService.handleError<IUserInfo>(`get user: ${userId}`, undefined))
    );
  }

  createUser(UserCreateCommand: IUserInfoCreateCommand): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}`, UserCreateCommand).pipe(
      catchError(this.sharedService.handleError<any>('create user', false))
    );
  }

  getLatelyUsers(): Observable<IUserInfo[]> {
    return this.http.get<IUserInfo[]>(`/api/lately/users`).pipe(
      catchError(this.sharedService.handleError<IUserInfo[]>('get users', []))
    );
  }

  addLatelyUser(userId: string): Observable<boolean> {
    return this.http.post<boolean>(`/api/lately/users`, { user_id: userId }).pipe(
      catchError(this.sharedService.handleError<boolean>('add lately user', true))
    );
  }

  getCurrentUserSync(): IUserInfo {
    const { currentUser } = this.authProvider;
    return currentUser;
  }

  // 获取当前用户的是否是 系统管理员
  getCurrentIsSystemAdminSync(): boolean {
    const currentUser = this.getCurrentUserSync();
    return currentUser && _.includes(currentUser.roles, 'admin');
  }

  // 获取当前用户的是否是 事业部管理员
  getCurrentIsDivisionAdminSync(): boolean {
    const currentUser = this.getCurrentUserSync();
    return currentUser && _.includes(currentUser.roles, 'division-admin');
  }

  // 获取当前用户的是否是 细分类管理员
  getCurrentIsCategoryAdminSync(): boolean {
    const currentUser = this.getCurrentUserSync();
    return currentUser && _.includes(currentUser.roles, 'category-admin');
  }

  // 获取当前用户的是否是 管理经理
  getCurrentIsProjectManagementAdminSync(): boolean {
    const currentUser = this.getCurrentUserSync();
    return currentUser && _.includes(currentUser.roles, 'project-management-admin');
  }

  // 获取当前用户的是否是 产品工程经理
  getCurrentIsProductEngineeringAdminSync(): boolean {
    const currentUser = this.getCurrentUserSync();
    return currentUser && _.includes(currentUser.roles, 'product-engineering-admin');
  }

  // 获取当前用户的是否是 项目群管理员
  getCurrentIsProjectGroupAdminSync(): boolean {
    const currentUser = this.getCurrentUserSync();
    return currentUser && _.includes(currentUser.roles, 'project-group-admin');
  }

  // 获取当前用户的是否是 工时管理员
  getCurrentIsWorktimeAdminSync(): boolean {
    const currentUser = this.getCurrentUserSync();
    return currentUser && _.includes(currentUser.roles, 'working-hours-admin');
  }

  // 获取当前用户的是否是 组织管理员
  getCurrentIsOrganizationAdminSync(): boolean {
    const currentUser = this.getCurrentUserSync();
    return currentUser && _.includes(currentUser.roles, 'organization-admin');
  }
  // 获取当前用户的是否是 二级标准组织模板管理员
  getCurrentIsSecondOrgTemplateAdminSync(): boolean {
    const currentUser = this.getCurrentUserSync();
    return currentUser && _.includes(currentUser.roles, 'second-org-template-admin');
  }

  // 获取当前用户的是否只是普通员工
  getCurrentIsStaffSync(): boolean {
    const currentUser = this.getCurrentUserSync();
    return currentUser && currentUser.roles.length === 1 && _.includes(currentUser.roles, 'staff');
  }

  // 获取当前用户的是否是 项目管理员（任何一个项目的）
  getCurrentIsProjectAdminSync(): boolean {
    const currentUser = this.getCurrentUserSync();
    return currentUser && _.includes(currentUser.roles, 'project-admin');
  }

  // 获取当前用户是否是 产品创造绩效管理员
  getCurrentIsKpiAlphaAdminSync(): boolean {
    const currentUser = this.getCurrentUserSync();
    return currentUser && _.includes(currentUser.roles, 'kpi-alpha-admin');
  }

  // 获取当前用户是否是 绩效维护员（产品工程经理 || PMT/PAT经理）
  getCurrentIsKpiMaintainerSync(): boolean {
    const currentUser = this.getCurrentUserSync();
    return currentUser && _.includes(currentUser.roles, 'kpi-maintainer');
  }

  // 获取当前用户是否是 人力γ值管理员
  getCurrentIsKpiGammaAdminSync(): boolean {
    const currentUser = this.getCurrentUserSync();
    return currentUser && _.includes(currentUser.roles, 'kpi-gamma-admin');
  }
  // 获取当前用户是否是 中心主任
  getCurrentIsCenterDirectorAdminSync(): boolean {
    const currentUser = this.getCurrentUserSync();
    return currentUser && _.includes(currentUser.roles, 'center-director-admin');
  }

  // 获取当前用户是否是 工时填报人员
  getCurrentIsWorktimeMaintainerSync(): boolean {
    const currentUser = this.getCurrentUserSync();
    return currentUser && _.includes(currentUser.roles, 'worktime-maintainer');
  }

  // 获取当前用户是否是 总院绩效管理员
  getCurrentIsKpiReportViewerSync(): boolean {
    const currentUser = this.getCurrentUserSync();
    return currentUser && _.includes(currentUser.roles, 'kpi-report-viewer');
  }

  // 获取当前用户是否是 绩效报批管理员
  getCurrentIsKpiApprovalAdminSync(): boolean {
    const currentUser = this.getCurrentUserSync();
    return currentUser && _.includes(currentUser.roles, 'kpi-approval-admin');
  }

  // 获取当前用户是否是 工时统计报表导出管理员
  getCurrentIsWorktimeExportAdminSync(): boolean {
    const currentUser = this.getCurrentUserSync();
    return currentUser && _.includes(currentUser.roles, 'worktime-export-admin');
  }

  // 获取当前用户是否是 需求管理工程师
  getCurrentIsRequirementManagementEngineerSync(): boolean {
    const currentUser = this.getCurrentUserSync();
    return currentUser && _.includes(currentUser.roles, 'requirement-engineer');
  }

  // 获取当前用户是否是 工时预算管理员（项目财务经理）
  getCurrentIsWorktimeForecastAdminSync(): boolean {
    const currentUser = this.getCurrentUserSync();
    return currentUser && _.includes(currentUser.roles, 'worktime-forecast-admin');
  }

  // 获取当前用户是否是 管理经理（指定项目的）
  getCurrentIsCertainProjectManagementAdminSync(project: IProject): boolean {
    let isProjectManagementAdmin = false;
    if (_.get(project, 'project_org_tree.items')) {
      const projectAdminOrg = _.find(project.project_org_tree.items, { name: '管理经理' });
      if (projectAdminOrg && projectAdminOrg.persons && projectAdminOrg.persons.length) {
        const currentUser = this.getCurrentUserSync();
        isProjectManagementAdmin = -1 !== _.findIndex(projectAdminOrg.persons, { user_id: currentUser.user_id });
      }
    }
    return isProjectManagementAdmin;
  }

  // 获取当前用户是否是 产品工程经理（指定项目的）
  getCurrentIsCertainProductEnginingAdminSync(project: IProject): boolean {
    let isProductEnginingAdmin = false;
    if (_.get(project, 'project_org_tree.items')) {
      const projectAdminOrg = _.find(project.project_org_tree.items, { name: '产品工程经理' });
      if (projectAdminOrg && projectAdminOrg.persons && projectAdminOrg.persons.length) {
        const currentUser = this.getCurrentUserSync();
        isProductEnginingAdmin = -1 !== _.findIndex(projectAdminOrg.persons, { user_id: currentUser.user_id });
      }
    }
    return isProductEnginingAdmin;
  }

  // 获取当前用户是否是 项目群管理员（指定项目的）
  getCurrentIsCertainProjectGroupAdmin(project: IProject): Observable<boolean> {
    const currentUser = this.getCurrentUserSync();
    // 获取事业部、项目群下的管理员列表
    return this.getProjectGroupAdmins(project.code, currentUser.user_id).pipe(
      map(userIds => {
        if (!userIds.length) {
          return false;
        }
        return true;
      })
    );
  }

  // 获取当前用户是否是 项目管理员（指定项目的）
  getCurrentIsCertainProjectAdmin(project: IProject): Observable<boolean> {
    if (!project) {
      return of(false);
    }
    const currentUser = this.getCurrentUserSync();
    return this.getProjectRolesInfo(project.code, currentUser.user_id).pipe(
      map(projectRoleInfos => {
        if (!_.find(projectRoleInfos, { role: 'project-admin' })) {
          return false;
        }
        return true;
      })
    );
  }
  // 获取当前用户是否是 产品工程经理（指定项目的）
  getCurrentIsCertainProductEnginingAdmin(project: IProject): Observable<boolean> {
    if (!project) {
      return of(false);
    }
    const currentUser = this.getCurrentUserSync();
    return this.getProjectRolesInfo(project.code, currentUser.user_id).pipe(
      map(projectRoleInfos => {
        if (!_.find(projectRoleInfos, { role: 'product-engineering-admin' })) {
          return false;
        }
        return true;
      })
    );
  }
  // 获取当前用户是否是 管理经理（指定项目的）
  getCurrentIsCertainProjectManagementAdmin(project: IProject): Observable<boolean> {
    if (!project) {
      return of(false);
    }
    const currentUser = this.getCurrentUserSync();
    return this.getProjectRolesInfo(project.code, currentUser.user_id).pipe(
      map(projectRoleInfos => {
        if (!_.find(projectRoleInfos, { role: 'project-management-admin' })) {
          return false;
        }
        return true;
      })
    );
  }

  // 获取当前用户是否是产品工程经理 || 管理经理（指定项目）
  getCurrentIsCertainProjectManagementAdminOrProductEnginingAdmin(project: IProject): Observable<boolean> {
    if (!project) {
      return of(false);
    }
    const currentUser = this.getCurrentUserSync();
    return this.getProjectRolesInfo(project.code, currentUser.user_id).pipe(
      map(projectRoleInfos => {
        // tslint:disable-next-line:max-line-length
        if (!_.find(projectRoleInfos, { role: 'project-management-admin' }) && !_.find(projectRoleInfos, { role: 'product-engineering-admin' })) {
          return false;
        }
        return true;
      })
    );
  }


  // 获取当前用户的是否是 组织维护员（是未关闭的项目的组织的成员）
  getCurrentIsOrganizationMaintainerSync(): boolean {
    const currentUser = this.getCurrentUserSync();
    return currentUser && _.includes(currentUser.roles, 'organization-maintainer');
  }

  // 获取当前用户属于”集团“事业部
  getCurrentIsBelongToGroup() {
    return this.getCurrentDivisionOrgNumber().pipe(
      map(divisionOrgNumber => {
        return GROUP_DIVISION === divisionOrgNumber;
      })
    );
  }

  // 获取当前用户在指定项目的角色
  projectRolesInfo$: Observable<IProjectRoleInfo[]>;
  private requestProjectRolesInfo(
    projectCode: string,
    userId: string,
    enforceNew?: boolean  // 强制获取最新数据，不取缓存
  ): Observable<IProjectRoleInfo[]> {
    if (enforceNew || !this.projectRolesInfo$) {
      const params = this.sharedService.omitEmptyParams({
        userId
      });
      this.projectRolesInfo$ = this.http.get<IProjectRoleInfo[]>(`/api/audits/projects/roles-info/${projectCode}`, { params: params as unknown as HttpParams })
        .pipe(
          catchError(this.sharedService.handleError('get roles info', [])),
          shareReplay(1)  // 多播
        );
    }
    return this.projectRolesInfo$;
  }
  getProjectRolesInfo(projectCode: string, userId: string): Observable<IProjectRoleInfo[]> {
    return this.requestProjectRolesInfo(projectCode, userId);
  }

  // 当前用户是否有绩效管理的权限
  getCurrentHasPerformancePermissions(): Observable<boolean> {
    return this.http.get<boolean>
      ('/api/kpi/check-is-view/kpi-maintain').pipe(
        catchError(this.sharedService.handleError('get performance permissions', false))
      );
  }

  // // 获取当前用户的是否是事业部、细分类、项目群管理员
  // getCurrentIsAuthorizedManager(): boolean {
  //   const roles = ['division-admin', 'category-admin', 'project-group-admin']; // 事业部、细分类、项目群管理员
  //   const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
  //   let isAuthorized = false;
  //   _.forEach(roles, role => {
  //     isAuthorized = isAuthorized || _.includes(currentUser.roles, role);
  //   });
  //   return isAuthorized;
  // }

  // 获取当前用户的事业部编码（所管理的事业部编码）
  // 用户是事业部B的管理员，所在事业部为事业部C，此接口返回事业部B
  // -事业部、细分类、项目群管理员，会返回事业部编码
  // 否则返回空
  getCurrentDivisionOrgNumber(): Observable<string> {
    return this.http.get<string>(`/api/divisions-current`, {
      responseType: 'text' as 'json'
    }).pipe(
      catchError(this.sharedService.handleError<string>(`get division_org_number`, ''))
    );
  }

  // 获取当前用户的事业部编码
  // -任意角色用户，会返回事业部编码
  getCurrentUserDivisionOrgNumber(userId: string): Observable<string> {
    return this.http.get<string>(`/api/users/${userId}/division-org-number`, {
      responseType: 'text' as 'json'
    }).pipe(
      catchError(this.sharedService.handleError<string>(`get division_org_number`, ''))
    );
  }

  // 获取某个项目群管理员的信息
  getDivisionOrgNumber(params: IProjectGroupAdminParams): Observable<IProjectGroupAdminInfo[]> {
    return this.http.get<IProjectGroupAdminInfo[]>(`/api/divisions/project/group/admin/${params.user_id}`).pipe(
      catchError(this.sharedService.handleError<IProjectGroupAdminInfo[]>(``, []))
    );
  }

  // 获取组织列表
  getOrganizations(params?: IOrganizationParams): Observable<IOrganization[]> {
    return this.http.get<IOrganization[]>(`/api/organizations`, { params: params as HttpParams }).pipe(
      catchError(this.sharedService.handleError<IOrganization[]>(`get organizations`, []))
    );
  }

  getOrganizationsCount(params?: IOrganizationParams): Observable<number> {
    return this.http.get<number>(`/api/organizations-count`, { params: params as HttpParams }).pipe(
      catchError(this.sharedService.handleError<number>(`get organizations count`, 0))
    );
  }

  getLatelyOrganizations(): Observable<IOrganization[]> {
    return this.http.get<IOrganization[]>(`/api/lately/organizations`).pipe(
      catchError(this.sharedService.handleError<IOrganization[]>('get lately organizations', []))
    );
  }

  addLatelyOrganization(organizationNumber: string): Observable<boolean> {
    return this.http.post<boolean>(`/api/lately/organizations`, { org_number: organizationNumber }).pipe(
      catchError(this.sharedService.handleError<boolean>('add lately organization', true))
    );
  }

  // 获取组织详情
  getOrganization(orgNumber: string): Observable<IOrganization> {
    return this.http.get<IOrganization>(`/api/organizations/${orgNumber}`).pipe(
      catchError(this.sharedService.handleError<IOrganization>(`get organization: ${orgNumber}`, undefined))
    );
  }

  // 获取子组织
  // orgNumber不传，默认获取根组织
  getOrganizationTreeItemChildren(
    orgNumber: string = ROOT_ORGANIZATION_TREE_ITEM_PARENT_CODE,
    withIsCanUpdate?: boolean
  ): Observable<IOrganizationTreeItem[]> {
    const params = this.sharedService.omitEmptyParams({
      filterAdmin: withIsCanUpdate  // 请求结果带上 is_can_update属性（是否可添加或者删除管理员）
    }) as unknown as HttpParams;
    return this.http.get<IOrganizationTreeItem[]>(`/api/organizations/${orgNumber}/children`, { params }).pipe(
      catchError(this.sharedService.handleError<IOrganizationTreeItem[]>(`get organizations`, []))
    );
  }

  // 获取有权限维护该项目的项目群管理员userIds
  getProjectGroupAdmins(projectCode: string, userId?: string): Observable<string[]> {
    const params = this.sharedService.omitEmptyParams({ userId });
    // tslint:disable-next-line: max-line-length
    return this.http.get<string[]>(`/api/audits/projects/${projectCode}/project-group-admin`, { params: params as unknown as HttpParams }).pipe(
      catchError(this.sharedService.handleError<string[]>('get project group admin ids', []))
    );
  }

  // 获取用户角色详情
  // 事业部管理员、细分类管理员获取其管理的事业部、细分类（管理的事业部不一定是其所在事业部）
  getDivisionCategoryAdminRoles(userId: string): Observable<IDivisionCategoryAdminRole[]> {
    const params = this.sharedService.omitEmptyParams({ userId });
    // tslint:disable-next-line:max-line-length
    return this.http.get<IDivisionCategoryAdminRole[]>(`/api/users/division-category-admin-roles`, { params: params as unknown as HttpParams }).pipe(
      catchError(this.sharedService.handleError<IDivisionCategoryAdminRole[]>('get project group admin ids', []))
    );
  }

  // 获取当前用户是否有权限导出工时费用统计报表
  hasPermissionToReportWorktimeCost(): Observable<number> {
    return this.http.get<number>(`/api/configs/worktime-cost/permission/has-worktime-cost-report-permission`).pipe(
      catchError(this.sharedService.handleError<number>('has permission to report', 0))
    );
  }

  // 获取当前用户是否有权限维护人工成本
  hasPermissionToMaintainLaborCost(): Observable<boolean> {
    return this.http.get<boolean>(`/api/configs/worktime-cost/permission/has-user-cost-level-permission`).pipe(
      catchError(this.sharedService.handleError<boolean>('has permission to maintain labor cost', false))
    );
  }

  // 当前用户是否可转办项目
  // audit未提交 && (项目管理员 || 项目群管理员 || 细分类管理员 || 事业部管理员 || 系统管理员 )
  getCurrentCanTurnProject(audit: IAuditInfo, project: IProject): Observable<boolean> {
    if (!audit || !project) {
      return of(false);
    }
    return this.getCurrentIsCertainProjectAdmin(project).pipe(
      map(isProjectAdmin => {
        return audit
          && EAuditStatus.UnSubmitted === audit.status
          && (
            isProjectAdmin
            || this.getCurrentIsProjectGroupAdminSync()
            || this.getCurrentIsCategoryAdminSync()
            || this.getCurrentIsDivisionAdminSync()
            || this.getCurrentIsSystemAdminSync()
          );
      })
    );
  }
}
