import { Reducer, Action } from 'redux';
import {
  SET_REQUIREMENT_DATA,
  SetRequirementDataAction,
  UPDATE_REQUIREMENT_INITIAL_BASIC_INFO_DTO,
  UpdateRequirementInitialBasicInfoDtoAction,
  UPDATE_REQUIREMENT_INITIAL_ANALYSIS_DTO,
  UpdateRequirementInitialAnalysisDtoAction,
  SetRequirementInitialBasicInfoDirtyAction,
  SET_REQUIREMENT_INITIAL_ANALYSIS_DIRTY,
  SetRequirementInitialAnalysisDirtyAction,
  SET_REQUIREMENT_INITIAL_BASIC_INFO_DIRTY,
  ResetRequirementAction,
  RESET_REQUIREMENT
} from '../actions/requirement.actions';
import { createSelector } from 'reselect';
import _ from 'lodash';

import {
  IRequirementData,
  IRequirementInitialAnalysisDto,
  IRequirementInitialBasicInfoDto
} from 'src/app/models';

export interface RequirementState {
  requirementData: IRequirementData; // 需求Data
  initialBasicInfoDto: IRequirementInitialBasicInfoDto; // 澄清需求Dto
  initialBasicInfoDirty: boolean; // 澄清需求Dirty
  initialAnalysisDto: IRequirementInitialAnalysisDto; // 初始需求Dto
  initialAnalysisDirty: boolean; // 初始需求Dirty
}

const initialState: RequirementState = {
  requirementData: undefined,
  initialBasicInfoDto: undefined,
  initialBasicInfoDirty: false,
  initialAnalysisDto: undefined,
  initialAnalysisDirty: false
};

export const RequirementReducer: Reducer<RequirementState> =
  (state: RequirementState = initialState, action: Action): RequirementState => {
    let requirementData: IRequirementData;
    let initialBasicInfoDto: IRequirementInitialBasicInfoDto;
    let initialAnalysisDto: IRequirementInitialAnalysisDto;
    let dirty: boolean;
    switch (action.type) {
      // 设置 需求data
      case SET_REQUIREMENT_DATA:
        requirementData = (action as SetRequirementDataAction).requirementData;
        return Object.assign({}, state, { requirementData });

      // 更新 澄清需求Dto
      case UPDATE_REQUIREMENT_INITIAL_BASIC_INFO_DTO:
        initialBasicInfoDto = (action as UpdateRequirementInitialBasicInfoDtoAction).initialBasicInfoDto;
        return Object.assign({}, state, { initialBasicInfoDto });

      // 设置 澄清需求Dirty
      case SET_REQUIREMENT_INITIAL_BASIC_INFO_DIRTY:
        dirty = (action as SetRequirementInitialBasicInfoDirtyAction).dirty;
        return Object.assign({}, state, { initialBasicInfoDirty: dirty });

      // 更新 初始需求Dto
      case UPDATE_REQUIREMENT_INITIAL_ANALYSIS_DTO:
        initialAnalysisDto = (action as UpdateRequirementInitialAnalysisDtoAction).initialAnalysisDto;
        return Object.assign({}, state, { initialAnalysisDto });

      // 设置 初始需求Dirty
      case SET_REQUIREMENT_INITIAL_ANALYSIS_DIRTY:
        dirty = (action as SetRequirementInitialAnalysisDirtyAction).dirty;
        return Object.assign({}, state, { initialAnalysisDirty: dirty });

      // 重置 需求
      case RESET_REQUIREMENT:
        return Object.assign({}, state, initialState);

      default:
        return state;
    }
  };

const getState = (state: RequirementState): RequirementState => state;
// 获取需求Data
export const getRequirementData = createSelector(
  getState,
  (state: RequirementState) => state.requirementData
);

// 获取 澄清需求Dto
export const getRequirementInitialBasicInfoDto = createSelector(
  getState,
  (state: RequirementState) => state.initialBasicInfoDto
);

// 获取 澄清需求Dirty
export const getRequirementInitialBasicInfoDirty = createSelector(
  getState,
  (state: RequirementState) => state.initialBasicInfoDirty
);

// 获取 初始需求Dto
export const getRequirementInitialAnalysisDto = createSelector(
  getState,
  (state: RequirementState) => state.initialAnalysisDto
);

// 获取 初始需求Dirty
export const getRequirementInitialAnalysisDirty = createSelector(
  getState,
  (state: RequirementState) => state.initialAnalysisDirty
);
