import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { APP_INITIALIZER, NgModule, Inject } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from './modules/shared/shared.module';

import { httpInterceptorProviders } from './http-interceptors';
import { AuthProvider } from './providers/auth.provider';

import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { zhCnLocale } from 'ngx-bootstrap/locale';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { AlertModule } from 'ngx-bootstrap/alert';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { AlertComponent } from './components/alert/alert.component';
import { SaveNotifyComponent } from './components/save-notify/save-notify.component';
import { DialogComponent } from './components/dialog/dialog.component';
import { LeftNavComponent } from './components/left-nav/left-nav.component';
import { LeftNavZentaoComponent } from './components/left-nav-zentao/left-nav-zentao.component';
import { LeftNavRemsComponent } from './components/left-nav-rems/left-nav-rems.component';
import { FullscreenComponent } from './components/fullscreen/fullscreen.component';
import { SystemAdminComponent } from './components/system-admin/system-admin.component';
import { ShuxiaofuComponent } from './components/shuxiaofu/shuxiaofu.component';
import { AccountComponent } from './components/account/account.component';
import { ManagementCenterComponent } from './components/management-center/management-center.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    AlertComponent,
    SaveNotifyComponent,
    DialogComponent,
    LeftNavComponent,
    LeftNavRemsComponent,
    FullscreenComponent,
    SystemAdminComponent,
    ShuxiaofuComponent,
    AccountComponent,
    LeftNavZentaoComponent,
    ManagementCenterComponent,
  ],
  imports: [
    BrowserModule,
    BrowserTransferStateModule,
    BrowserAnimationsModule,
    SharedModule,
    AppRoutingModule,
    HttpClientModule,
    BsDropdownModule.forRoot(),
    BsDatepickerModule.forRoot(),
    ModalModule.forRoot(),
    AlertModule.forRoot(),
    PaginationModule.forRoot(),
    PopoverModule.forRoot(),
    TypeaheadModule.forRoot(),
    AccordionModule.forRoot()
  ],
  providers: [
    httpInterceptorProviders,
    AuthProvider,
    {
      provide: APP_INITIALIZER,
      useFactory: (provider: AuthProvider) => () => provider.loadAuthPromise(),
      deps: [AuthProvider],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    @Inject(BsLocaleService) private localeService: BsLocaleService,
  ) {
    defineLocale('zh-cn', zhCnLocale); // ngx-bootstrap/datepicker使用中文
    this.localeService.use('zh-cn');
  }
}
