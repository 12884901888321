<div class="organization-tree-item" *ngFor="let item of items"
  [ngClass]="{isChecked: checkedItem && checkedItem.org_number===item.org_number}">
  <div *ngIf="item._is_show" class="content">
    <div class="row align-items-center no-gutters">
      <div class="col-auto mr-1" [ngStyle]="{'padding-left.px': item._level * 16}" (click)="toggleExpandItem(item);">
        <i class="fa fa-plus-square-o" *ngIf="item.has_children && !item._is_expand"></i>
        <i class="fa fa-minus-square-o" *ngIf="item.has_children && item._is_expand"></i>
        <i class="fa fa-file-o" *ngIf="!item.has_children"></i>
      </div>
      <!-- 名称 -->
      <div class="col" (click)="checkItem(item);">
        {{item.org_name}}
      </div>
    </div>
  </div>
</div>
