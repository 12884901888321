import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import _ from 'lodash';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { EConfigType, EDeliverableType } from 'src/app/enums';
import { IAchievementDeliverableContentItem, IAchievementDeliverableContentParams, IAchievementNewCodeParams, IAchievementTargetItem, IAddAchievementDeliverableContentCommand, IConfigInfo, IDigitalDeliverableInfo, IUpdateAchievementDeliverableContentCommand } from 'src/app/models';
import { ConfigService } from 'src/app/services/config.service';
import { DeliverableService } from 'src/app/services/deliverable.service';
import { SharedService } from 'src/app/services/shared.service';

@Injectable({
  providedIn: 'root'
})
export class PropertyConfigService {

  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private sharedService: SharedService,
    private deliverableService: DeliverableService
  ) { }


  // #region NTP配置

  // 获取未被禁用的configs
  getConfigsExcludeDisabled(type: EConfigType): Observable<IConfigInfo[]> {
    return this.configService.getConfigs(type).pipe(
      map(configs => {
        return _.filter(configs, {
          disabled: false
        })
      })
    )
  }

  // 成果所属分类
  getAchievementTypes(): Observable<IConfigInfo[]> {
    return this.configService.getConfigs(EConfigType.NTP_AchievementType);
  }

  getAchievementTypesExcludeDisabled(): Observable<IConfigInfo[]> {
    return this.getConfigsExcludeDisabled(EConfigType.NTP_AchievementType);
  }

  // 迁移目标规划
  getMigrationTargetPlans(): Observable<IConfigInfo[]> {
    return this.configService.getConfigs(EConfigType.NTP_MigrationTargetPlan);
  }

  getMigrationTargetPlansExcludeDisabled(): Observable<IConfigInfo[]> {
    return this.getConfigsExcludeDisabled(EConfigType.NTP_MigrationTargetPlan);
  }

  // 成果定级建议
  getAchievementLevelSuggestions(): Observable<IConfigInfo[]> {
    return this.configService.getConfigs(EConfigType.NTP_AchievementLevelSuggestion);
  }

  getAchievementLevelSuggestionsExcludeDisabled(): Observable<IConfigInfo[]> {
    return this.getConfigsExcludeDisabled(EConfigType.NTP_AchievementLevelSuggestion);
  }

  // 成熟度分类
  getMaturityTypes(): Observable<IConfigInfo[]> {
    return this.configService.getConfigs(EConfigType.NTP_MaturityType);
  }

  getMaturityTypesExcludeDisabled(): Observable<IConfigInfo[]> {
    return this.getConfigsExcludeDisabled(EConfigType.NTP_MaturityType);
  }

  // 获取技术成果
  getAchievementTargets(projectCode: string): Observable<IConfigInfo[]> {
    return this.deliverableService.getDigitalDeliverableByType(projectCode, EDeliverableType.AchievementTarget).pipe(
      map(digitalDeliverableInfo => {
        const configs = [];
        _.forEach(digitalDeliverableInfo?.data?.items as IAchievementTargetItem[], item => {
          if (!item.is_droped) {
            configs.push({
              key: item.id,
              name: item.name
            });
          }
        });
        return configs;
      })
    )
  }

  // 获取成果交付物内容列表
  getAchievementDeliverableContentsList(params?: IAchievementDeliverableContentParams): Observable<IAchievementDeliverableContentItem[]> {
    return this.http.get<IAchievementDeliverableContentItem[]>(`/api/configs/ntp/deliverable-configs`, { params: params as unknown as HttpParams }).pipe(
      catchError(this.sharedService.handleError('get achievement deliverable content list', []))
    )
  }

  // 获取成果交付物内容列表数量
  getAchievementDeliverableContentsListCount(params: IAchievementDeliverableContentParams): Observable<number> {
    return this.http.get<number>(`/api/configs/ntp/deliverable-configs-count`, { params: params as unknown as HttpParams }).pipe(
      catchError(this.sharedService.handleError('get achievement deliverable content list count', 0))
    )
  }

  // 新增成果交付物内容
  addAchievementDeliverableContent(command: IAddAchievementDeliverableContentCommand): Observable<any> {
    return this.http.post<any>(`/api/configs/ntp/deliverable-configs`, command).pipe(
      catchError(this.sharedService.handleError('add achievement deliverable content', undefined))
    )
  }

  // 更新成果交付物内容
  updateAchievementDeliverableContent(command: IUpdateAchievementDeliverableContentCommand): Observable<any> {
    return this.http.put<any>(`/api/configs/ntp/deliverable-configs`, command).pipe(
      catchError(this.sharedService.handleError('update achievement deliverable content', undefined))
    )
  }

  // 获取流水编码
  getAchievementNewCode(params: IAchievementNewCodeParams): Observable<string> {
    const options = { // 防止string型的response被直接转换成number，丢失精度
      responseType: 'text' as 'json',
      params: params as unknown as HttpParams
    };
    return this.http.get<string>(`/api/configs/ntp/archievements/newcode`, options).pipe(
      catchError(this.sharedService.handleError('get achievement new code', ''))
    )
  }

  // #endregion

}
