import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import _ from 'lodash';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ReduxService } from '../redux/redux.service';

import {
  IMultifunctionalTeamType,
  IMultifunctionalTeamTypeParams,
  IAddIMultifunctionalTeamParams,
  IUpdateIMultifunctionalTeamParams,
  IOrganizationChartItem,
  IProject,
  IAlert,
  IOrganizationTeamItem,
  IMultifunctionalTeamData
} from 'src/app/models';
import { SharedService } from 'src/app/services/shared.service';
import { SubscriptionService } from 'src/app/services/subscription.service';
import { DEFAULT_ROLE_NAMES } from '../consts';
import { EOrganizationChartItemOperationType } from '../enums';
import { IMultifunctionalTeam, ITeamMemberRole, IUpdatedMultifunctionalTeamTypeInfo } from '../models/IOrganizationChartItem';

@Injectable({
  providedIn: 'root'
})
export class MultifunctionalTeamService {

  constructor(
    private http: HttpClient,
    private sharedService: SharedService,
    private subscriptionService: SubscriptionService,
    private reduxService: ReduxService
  ) { }

  getMultifunctionalTeams(params: IMultifunctionalTeamTypeParams): Observable<IMultifunctionalTeamType[]> {
    return this.http.get<IMultifunctionalTeamType[]>
      ('/api/divisions/org/multifunctional-team', { params: params as unknown as HttpParams }).pipe(
        catchError(this.sharedService.handleError('get multifunction team list', []))
      );
  }

  getMultifunctionalTeamsCount(params: IMultifunctionalTeamTypeParams): Observable<number> {
    return this.http.get<number>
      ('/api/divisions/org/multifunctional-team-count', { params: params as unknown as HttpParams }).pipe(
        catchError(this.sharedService.handleError('get multifunction team list count', 0))
      );
  }

  deleteMultifunctionalTeam(params: number): Observable<any> {
    return this.http.delete<any>
      (`/api/divisions/org/multifunctional-team/delete/${params}`).pipe(
        catchError(this.sharedService.handleError('delete multifunction team', []))
      );
  }

  addMultifunctionalTeam(params: IAddIMultifunctionalTeamParams): Observable<any> {
    return this.http.post<any>
      ('/api/divisions/org/multifunctional-team/add', params).pipe(
        catchError(this.sharedService.handleError('add multifunction team', undefined))
      );
  }

  updateMultifunctionalTeam(params: IUpdateIMultifunctionalTeamParams): Observable<any> {
    return this.http.put<any>
      ('/api/divisions/org/multifunctional-team/update', params).pipe(
        catchError(this.sharedService.handleError('update multifunction team', undefined))
      );
  }

  getMultifunctionalTeam(params: number): Observable<IMultifunctionalTeamType> {
    return this.http.get<IMultifunctionalTeamType>
      (`/api/divisions/org/multifunctional-team/${params}`).pipe(
        catchError(this.sharedService.handleError('update multifunction team by id', undefined))
      );
  }

  // 检测多功能小组是否发生了变更
  // （权限：节点上的工程师有当前用户）
  verifyMultifunctionalTeamsUpdated(
    organizationChartItems: IOrganizationChartItem[], // 组织树的所有节点列表
    currentUserId: string,
    project?: IProject
  ): Observable<boolean> {
    return this.getUpdatedMultifunctionalTeamTypeInfos(
      organizationChartItems, // 组织树的所有节点列表
      currentUserId,
      project
    ).pipe(
      map(updatedMultifunctionalTeamTypeInfos => {
        if (!updatedMultifunctionalTeamTypeInfos.length) {
          return false;
        }
        this.reduxService.showDialog(
          '加载多功能小组数据',
          [`您所使用的多功能小组已发生变更，点击“确定”加载最新数据。`],
          this.updateMultifunctionalTeamTypes,
          undefined,
          {
            organizationChartItems,
            updatedMultifunctionalTeamTypeInfos,
            currentUserId
          }
        );
        return true;
      })
    );
  }

  // 对比获取：发生了变更的多功能小组模板信息
  // （权限：当前用户是工程师 && 工程师使用了多功能小组模板维护多功能小组）  
  // tslint:disable-next-line:max-line-length
  private getUpdatedMultifunctionalTeamTypeInfos(
    organizationChartItems: IOrganizationChartItem[], // 组织树的所有节点列表
    currentUserId: string,
    project: IProject
  ): Observable<IUpdatedMultifunctionalTeamTypeInfo[]> {
    if (!project) {
      return of([]);
    }

    const nodeIdAndEnginFieldKeys: {
      node_id: string;
      engin_field_key: string;
    }[] = [];
    _.forEach(organizationChartItems, organizationChartItem => {
      if (!organizationChartItem?.team_members?.length) {
        return;
      }
      _.forEach(organizationChartItem.team_members, team_member => {
        // 工程师是当前用户?
        if (team_member.user_id !== currentUserId) {
          return;
        }
        // 工程师使用了多功能小组模板维护多功能小组？
        if (!team_member.multifunctional_teams?.items?.length) {
          return;
        }
        nodeIdAndEnginFieldKeys.push({
          node_id: organizationChartItem.id,
          engin_field_key: organizationChartItem.engin_field_key
        });
      });
    });

    if (!nodeIdAndEnginFieldKeys.length) {
      return of([]);
    }

    // 获取多功能小组模板信息（通过项目division、category、组织节点engin_field_key）
    const requests: Observable<IMultifunctionalTeamType[]>[] = [];
    _.forEach(nodeIdAndEnginFieldKeys, nodeIdAndEnginFieldKey => {
      const params: IMultifunctionalTeamTypeParams = this.sharedService.omitEmptyParams({
        division_org_number: project.development_division_org_number,
        project_category_key: project.category_key,
        engin_field_key: nodeIdAndEnginFieldKey.engin_field_key
      });
      requests.push(this.getMultifunctionalTeams(params));
    });
    return forkJoin(requests).pipe(
      map(results => {
        return _.map(results, multifunctionTeamTypes => {
          if (multifunctionTeamTypes && multifunctionTeamTypes.length) {
            return multifunctionTeamTypes[0];
          } else {
            return null;
          }
        });
      }),
      map(multifunctionalTeamTypes => {    // 需要检查的多功能小组模板[]
        const updatedMultifunctionalTeamTypeInfos: IUpdatedMultifunctionalTeamTypeInfo[] = [];
        _.forEach(nodeIdAndEnginFieldKeys, (item, index) => {
          const oldOrganizationChartItem = _.find(organizationChartItems, { id: item.node_id });
          const oldMultifunctionalTeamItems = _.find(oldOrganizationChartItem.team_members, { user_id: currentUserId }).multifunctional_teams.items;
          const newMultifunctionalTeamType = multifunctionalTeamTypes[index];
          const newMultifunctionalTeamItems = newMultifunctionalTeamType.teams;
          const isUpdated = !_.isEqual(
            _.map(oldMultifunctionalTeamItems, 'name'),
            _.map(newMultifunctionalTeamItems, 'name')
          );
          // 多功能小组模板被删除 || 新旧多功能小组模板不同（names不同）
          if (newMultifunctionalTeamType.is_deleted || isUpdated) {
            updatedMultifunctionalTeamTypeInfos.push({
              node_id: item.node_id,
              multifunctional_team_type: newMultifunctionalTeamType
            });
          }
        });
        return updatedMultifunctionalTeamTypeInfos;
      })
    );
  }

  // 变更了多功能小组模板，使用多功能小组模板新数据，更新组织节点上的多功能小组信息
  // （节点上使用的多功能小组模板发生了变更 && 节点上的工程师有当前用户）
  private updateMultifunctionalTeamTypes = (params: {
    organizationChartItems: IOrganizationChartItem[], // 组织树的所有节点列表
    updatedMultifunctionalTeamTypeInfos: IUpdatedMultifunctionalTeamTypeInfo[] // 发生变更的多功能小组模板列表
    currentUserId: string
  }): void => {
    _.forEach(params.updatedMultifunctionalTeamTypeInfos, updatedMultifunctionalTeamTypeInfo => {
      // 多功能小组模板不存在
      if (!updatedMultifunctionalTeamTypeInfo.multifunctional_team_type) {
        return;
      }

      const organizationChartItem = _.cloneDeep(
        _.find(params.organizationChartItems, { id: updatedMultifunctionalTeamTypeInfo.node_id })
      );
      if (!organizationChartItem) {
        return;
      }

      if (!organizationChartItem?.team_members?.length) {
        // 未维护团队
        return;
      }
      _.forEach(organizationChartItem.team_members, (team, index) => {
        if (team.user_id !== params.currentUserId) {
          // ！当前用户是工程师 
          return;
        }
        let multifunctionalTeams = team.multifunctional_teams.items;
        if (!multifunctionalTeams.length) {
          // 未选择多功能小组
          return;
        }
        // 多功能小组模板被删除，清空组织节点多功能小组
        if (updatedMultifunctionalTeamTypeInfo.multifunctional_team_type.is_deleted) {
          organizationChartItem.team_members[index].multifunctional_teams.items = [];
          return;
        }
        const updatedMultifunctionalTeamTypeTeams = updatedMultifunctionalTeamTypeInfo.multifunctional_team_type.teams;
        const updatedMultifunctionalTeams: IMultifunctionalTeam[] = [];
        _.forEach(updatedMultifunctionalTeamTypeTeams, teamItem => {
          const oldTeamItem = _.find(multifunctionalTeams, { id: teamItem.id });
          updatedMultifunctionalTeams.push({
            id: teamItem.id,
            name: teamItem.name,
            persons: oldTeamItem ? oldTeamItem.persons : []
          });
        });
        organizationChartItem.team_members[index].multifunctional_teams.items = updatedMultifunctionalTeams;
      });
      setTimeout(() => {
        this.reduxService.updateOrganizationChartItem(organizationChartItem);
      });
    });

    // 暂存audit
    setTimeout(() => {
      this.subscriptionService.publishNeedSaveAudit();
    }, 100);
  }

  verifyOrganizationTeams(organizationTeams: IOrganizationTeamItem[]) {
    _.forEach(organizationTeams, (organizationTeam) => {
      organizationTeam.module_name = _.trim(organizationTeam.module_name);
    });
    // 如果OrganizationTeams的角色名称是否为空且OrganizationTeams的角色名称和用户名称的组合是否重复都通过才会返回true
    return this.verifyOrganizationTeamsRoleNamesAndUserNotRepeatTogether(organizationTeams)
      && this.verifyOrganizationTeamsRoleNamesNotNull(organizationTeams)
      && this.verifyOrganizationTeamsUserNotNull(organizationTeams);

  }

  //  验证OrganizationTeams的角色名称是否为空
  verifyOrganizationTeamsRoleNamesNotNull(organizationTeams: IOrganizationTeamItem[]) {
    const organizationTeamsWithoutDelete = _.reject(_.cloneDeep(organizationTeams), { operation: EOrganizationChartItemOperationType.Delete });
    if (organizationTeamsWithoutDelete.length === 0) {
      return true;
    }
    let organizationTeamsModuleNameIsNull = false;
    _.forEach(organizationTeamsWithoutDelete, (organizationTeam) => {
      if (!organizationTeam.module_name) {
        organizationTeamsModuleNameIsNull = true;
      }
    });
    if (organizationTeamsModuleNameIsNull) {
      const alert: IAlert = {
        type: 'danger',
        title: '角色名称不能为空'
      };
      this.subscriptionService.publishAlert(alert);
      return false;
    } else {
      return true;
    }
  }
  //  验证OrganizationTeams的角色名称是否为空
  verifyOrganizationTeamsUserNotNull(organizationTeams: IOrganizationTeamItem[]) {
    // tslint:disable-next-line:max-line-length
    const organizationTeamsWithoutDelete = _.reject(_.cloneDeep(organizationTeams), { operation: EOrganizationChartItemOperationType.Delete });
    if (organizationTeamsWithoutDelete.length === 0) {
      return true;
    }
    let organizationTeamsUserIsNull = false;
    _.forEach(organizationTeamsWithoutDelete, (organizationTeam) => {
      if (!organizationTeam.user_id) {
        organizationTeamsUserIsNull = true;
      }
    });
    if (organizationTeamsUserIsNull) {
      const alert: IAlert = {
        type: 'danger',
        title: '用户不能为空'
      };
      this.subscriptionService.publishAlert(alert);
      return false;
    } else {
      return true;
    }
  }

  // 验证OrganizationTeams的角色名称和用户名称的组合是否重复
  verifyOrganizationTeamsRoleNamesAndUserNotRepeatTogether(organizationTeams: IOrganizationTeamItem[]): boolean {
    // tslint:disable-next-line:max-line-length
    const organizationTeamsWithoutDelete = _.reject(_.cloneDeep(organizationTeams), { operation: EOrganizationChartItemOperationType.Delete });
    // 没有organizationTeamsWithoutDelete或者organizationTeamsWithoutDelete只有一条，都不会出现角色名称和用户名称的组合重复的问题
    if (organizationTeamsWithoutDelete.length <= 1) {
      return true;
    }
    let organizationTeamsRoleNamesAndUserNotRepeatTogether = true;

    let duplicateEngineer: IOrganizationTeamItem = null;
    _.forEach(organizationTeamsWithoutDelete, member => {
      if (!member.user_id) {
        return;
      }
      const duplicateEngineers = _.filter(organizationTeamsWithoutDelete, {
        module_name: member.module_name,
        user_id: member.user_id
      });
      if (duplicateEngineers.length > 1) {
        organizationTeamsRoleNamesAndUserNotRepeatTogether = false;
        duplicateEngineer = duplicateEngineers[0];
        return false;
      }
    });

    if (!organizationTeamsRoleNamesAndUserNotRepeatTogether) {
      const alert: IAlert = {
        type: 'danger',
        title: `“${duplicateEngineer.module_name}”重复`
      };
      this.subscriptionService.publishAlert(alert);
      return false;
    } else {
      return true;
    }
  }


  // 获取默认角色名称
  // getDefaultRoleNamesSync(): string[] {
  //   return DEFAULT_ROLE_NAMES;
  // }
  // tslint:disable-next-line:variable-name
  getDefaultRoleNames(template_org_node_id: string): Observable<ITeamMemberRole[]> {
    const params = this.sharedService.omitEmptyParams({ template_org_node_id });
    // tslint:disable-next-line:max-line-length
    return this.http.get<ITeamMemberRole[]>('/api/templates/projects/orgs/node/team-member-role', { params: params as unknown as HttpParams }).pipe(
      catchError(this.sharedService.handleError('get default role names', []))
    );
  }

  verifyMultifunctionalTeams(teams) {
    let isNull = false;
    if (teams.length === 0) {
      const alert: IAlert = {
        type: 'danger',
        title: `多功能小组不能为空`
      };
      this.subscriptionService.publishAlert(alert);
      return false;
    } else {
      _.forEach(teams, (team, index) => {
        if (!_.trim(team.name)) {
          isNull = true;
        }
      });
      if (isNull) {
        const alert: IAlert = {
          type: 'danger',
          title: `多功能小组不能为空`
        };
        this.subscriptionService.publishAlert(alert);
        return false;
      } else {
        return true;
      }
    }
  }

}
