import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import {
  ITaskInfo,
  IWorkTimeParams,
  IWorkTimeExceptionHandlingParams,
  IAuditInfo,
  IAuditInfoSubmitUpdateCommand,
  IAuditInfoSaveUpdateCommand,
  IAuditInfoApproveUpdateCommand,
  IAuditInfoRejectUpdateCommand,
  IAuditInfoTransferUpdateCommand,
  IWorktimeProjectInfoParams,
  IWorktimeModuleInfo,
  IWorktimeRelationPlan,
  IWorktimeRelationPlanParams,
  IProjectMilestone,
  IWorkTimeExceptionHandlingInfo,
  IResponse,
  IWorktime,
  IAlert,
  IAuditInfoSaveCreateCommand,
  IWorktimeProject,
  IUserInfo,
  IWorktimeFlowStepUsersParams,
  IWorktimeUsersParams,
  IWorktimeUserInfo,
  IWorktimeApproval,
  IWorktimeReportParams,
} from 'src/app/models';
import { SharedService } from 'src/app/services/shared.service';
import { SubscriptionService } from './subscription.service';
import { TaskInfoService } from './task-info.service';
import _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class WorktimeService {
  apiUrl = '/api/worktime-flow';

  constructor(
    private http: HttpClient,
    private sharedService: SharedService,
    private subscriptionService: SubscriptionService,
    private taskInfoService: TaskInfoService
  ) { }

  getWorkTimeInfos(params: IWorkTimeParams): Observable<ITaskInfo[]> {
    return this.http.get<ITaskInfo[]>('/api/worktime-flow/tasks', { params: params as unknown as HttpParams }).pipe(
      catchError(this.sharedService.handleError<ITaskInfo[]>('get working time', []))
    );
  }

  getWorkTimeInfosCount(params: IWorkTimeParams): Observable<number> {
    return this.http.get<number>('/api/worktime-flow/tasks-count', { params: params as unknown as HttpParams }).pipe(
      catchError(this.sharedService.handleError<number>('get working time count', 0))
    );
  }

  // 创建流程
  // post save
  // 返回新创建的任务id
  createAudit(auditInfoSaveCreateCommand: IAuditInfoSaveCreateCommand): Observable<string> {
    let auditId: string;
    return this.taskInfoService.getNewTaskAuditId(auditInfoSaveCreateCommand.audit_type).pipe(
      switchMap(taskAuditId => {
        auditId = taskAuditId;
        auditInfoSaveCreateCommand.id = taskAuditId;
        return this.http.post<any>(`${this.apiUrl}/save`, auditInfoSaveCreateCommand);
      }),
      catchError(this.sharedService.handleError<any>(`create audit`, undefined)),
      // tslint:disable-next-line:no-shadowed-variable
      map(_ => {
        // 成功返回auditId，失败返回undefined（bugfix：来自OA的任务，重复创建任务单，报412"节点下发单已存在，不能重复创建"）
        return _ ? auditId : undefined;
      })
    );
  }

  getAudit(id: string, version?: number): Observable<IAuditInfo> {
    const params = this.sharedService.omitEmptyParams({
      version
    }) as unknown as HttpParams;
    return this.http.get<IAuditInfo>(`${this.apiUrl}/${id}`, { params }).pipe(
      catchError(this.sharedService.handleError<IAuditInfo>(`get audit: ${id}`, undefined))
    );
  }

  // 提交
  submitAudit(auditInfoSubmitUpdateCommand: IAuditInfoSubmitUpdateCommand): Observable<boolean> {
    return this.http.put<boolean>(`${this.apiUrl}/${auditInfoSubmitUpdateCommand.id}/submit`, auditInfoSubmitUpdateCommand).pipe(
      catchError(this.sharedService.handleError<any>(`submit audit: ${auditInfoSubmitUpdateCommand.id}`, false))
    );
  }

  // 暂存
  saveAudit(auditInfoSaveUpdateCommand: IAuditInfoSaveUpdateCommand): Observable<boolean> {
    return this.http.put<boolean>(`${this.apiUrl}/${auditInfoSaveUpdateCommand.id}/save`, auditInfoSaveUpdateCommand).pipe(
      catchError(this.sharedService.handleError<any>(`save audit: ${auditInfoSaveUpdateCommand.id}`, false))
    );
  }

  // 作废
  dropAudit(id: string): Observable<boolean> {
    const body = {
      id
    };
    return this.http.put<boolean>(`${this.apiUrl}/${id}/drop`, body).pipe(
      catchError(this.sharedService.handleError<any>(`drop audit: ${id}`, false))
    );
  }

  // 撤回
  recallAudit(id: string): Observable<boolean> {
    const body = {
      id,
      remark: ''
    };
    return this.http.put<boolean>(`${this.apiUrl}/${id}/recall`, body).pipe(
      catchError(this.sharedService.handleError<any>(`recall audit: ${id}`, false))
    );
  }

  // 通过
  passAudit(command: IAuditInfoApproveUpdateCommand): Observable<boolean> {
    return this.http.put<boolean>(`${this.apiUrl}/${command.id}/approve`, command).pipe(
      catchError(this.sharedService.handleError<boolean>(`pass audit: ${command.id}`, false))
    );
  }

  // 驳回
  rejectAudit(command: IAuditInfoRejectUpdateCommand): Observable<boolean> {
    return this.http.put<boolean>(`${this.apiUrl}/${command.id}/reject`, command).pipe(
      catchError(this.sharedService.handleError<boolean>(`reject audit: ${command.id}`, false))
    );
  }

  // 转办
  turnAudit(command: IAuditInfoTransferUpdateCommand): Observable<boolean> {
    return this.http.put<boolean>(`${this.apiUrl}/${command.id}/transfer`, command).pipe(
      catchError(this.sharedService.handleError<boolean>(`turn audit: ${command.id}`, false))
    );
  }
  getWorkTimeExceptionHandlingInfos(params: IWorkTimeExceptionHandlingParams): Observable<IWorkTimeExceptionHandlingInfo[]> {
    return this.http.get<IWorkTimeExceptionHandlingInfo[]>
      ('/api/worktime-flow/workflow/list', { params: params as unknown as HttpParams }).pipe(
        catchError(this.sharedService.handleError<IWorkTimeExceptionHandlingInfo[]>('get exception handling', []))
      );
  }

  getWorkTimeExceptionHandlingInfosCount(params: IWorkTimeExceptionHandlingParams): Observable<number> {
    return this.http.get<number>('/api/worktime-flow/workflow/count', { params: params as unknown as HttpParams }).pipe(
      catchError(this.sharedService.handleError<number>('get exception handling count', 0))
    );
  }

  rejectWorkTimeExceptionHandling(id: string): Observable<IResponse> {
    return this.http.put<IResponse>(`/api/worktime-flow/workflow/reject/${id}`, '').pipe(
      catchError(this.sharedService.handleError<IResponse>('reject exception handling', { success: false, errors: [] }))
    );
  }


  // 获取工时填报单项目列表
  getWorktimeProjects(params: IWorktimeProjectInfoParams): Observable<IWorktimeProject[]> {
    params = this.sharedService.omitEmptyParams(params);
    return this.http.get<IWorktimeProject[]>(`${this.apiUrl}/projects`, { params: params as unknown as HttpParams }).pipe(
      catchError(this.sharedService.handleError<IWorktimeProject[]>('get worktime projects', []))
    );
  }

  getWorktimeProjectsCount(params: IWorktimeProjectInfoParams): Observable<number> {
    params = this.sharedService.omitEmptyParams(params);
    return this.http.get<number>(`${this.apiUrl}/projects-count`, { params: params as unknown as HttpParams }).pipe(
      catchError(this.sharedService.handleError<number>('get worktime projects count', 0))
    );
  }

  // 获取工时填报单项目节点信息
  getWorktimeProjectMilestoneInfo(projectCode: string): Observable<IProjectMilestone> {
    const params = this.sharedService.omitEmptyParams({ projectCode });
    return this.http.get<IProjectMilestone>(`${this.apiUrl}/milestones`, { params: params as unknown as HttpParams }).pipe(
      catchError(this.sharedService.handleError<IProjectMilestone>('get worktime project milestone', undefined))
    );
  }

  // 获取已维护的系统模块信息
  getWorktimeModuleInfo(projectCodes: string[]): Observable<IWorktimeModuleInfo[]> {
    const params = { projectCodes };
    return this.http.get<IWorktimeModuleInfo[]>(`${this.apiUrl}/projects/module-info`, { params: params as unknown as HttpParams }).pipe(
      catchError(this.sharedService.handleError<IWorktimeModuleInfo[]>('get worktime projects module info', []))
    );
  }

  // 获取工时审批单项目关联计划
  getRelationPlans(params: IWorktimeRelationPlanParams): Observable<IWorktimeRelationPlan[]> {
    params = this.sharedService.omitEmptyParams(params);
    // tslint:disable-next-line:max-line-length
    return this.http.get<IWorktimeRelationPlan[]>(`${this.apiUrl}/projects/relation-plans`, { params: params as unknown as HttpParams }).pipe(
      catchError(this.sharedService.handleError<IWorktimeRelationPlan[]>('get worktime relation plans', []))
    );
  }

  // 获取工时审批单项目关联计划总数
  getRelationPlansCount(params: IWorktimeRelationPlanParams): Observable<number> {
    params = this.sharedService.omitEmptyParams(params);
    // tslint:disable-next-line:max-line-length
    return this.http.get<number>(`${this.apiUrl}/projects/relation-plans-count`, { params: params as unknown as HttpParams }).pipe(
      catchError(this.sharedService.handleError<number>('get worktime relation plans count', 0))
    );
  }

  // 获取选择的关联计划
  getCheckedRelationPlans(ids: string[]): Observable<IWorktimeRelationPlan[]> {
    const params = this.sharedService.omitEmptyParams({ ids });
    // tslint:disable-next-line:max-line-length
    return this.http.get<IWorktimeRelationPlan[]>(`${this.apiUrl}/projects/relation-plans-info`, { params: params as unknown as HttpParams }).pipe(
      catchError(this.sharedService.handleError<IWorktimeRelationPlan[]>('get worktime relation plans info', []))
    );
  }

  // 检测工时填报人是否可以补录
  checkMakeUpAuth(userId: string): Observable<boolean> {
    const params = { userId };
    return this.http.get<boolean>('/api/worktime-users/check/make-up-auth', { params: params as unknown as HttpParams }).pipe(
      catchError(this.sharedService.handleError<boolean>('check make up auth', false))
    );
  }

  // 查询审批节点工时审批人员列表
  getWorkFlowStepUsers(params: IWorktimeFlowStepUsersParams): Observable<IUserInfo[]> {
    return this.http.get<IUserInfo[]>('api/worktime-users/audit/flow-step/users', { params: params as unknown as HttpParams }).pipe(
      catchError(this.sharedService.handleError<IUserInfo[]>('get flow step users', []))
    );
  }

  // 查询审批节点工时审批人员总数
  getWorkFlowStepUsersCount(params: IWorktimeFlowStepUsersParams): Observable<number> {
    return this.http.get<number>('api/worktime-users/audit/flow-step/users-count', { params: params as unknown as HttpParams }).pipe(
      catchError(this.sharedService.handleError<number>('get flow step users count', 0))
    );
  }

  // 查询工时填报人员列表
  getWorktimeUsers(params: IWorktimeUsersParams): Observable<IUserInfo[]> {
    return this.http.get<IWorktimeUserInfo[]>('/api/worktime-users/users', { params: params as unknown as HttpParams }).pipe(
      // catchError(this.sharedService.handleError<IWorktimeUserInfo[]>('get worktime users', []))
      map(items => {
        const worktimeUsers: IUserInfo[] = [];
        _.forEach(items, item => {
          const worktimeUser = {
            // name:item.u
            org_name: item.org_name,
            org_full_path: item.org_name,
            org_number: item.org_number,
            user_id: item.user_id,
            name: item.user_name
          };
          worktimeUsers.push(worktimeUser);
        });
        return worktimeUsers;
      }),
      catchError(this.sharedService.handleError<IUserInfo[]>('get worktime users', []))
    );
  }
  // 查询工时填报人员列表条数
  getWorktimeUsersCount(params: IWorktimeUsersParams): Observable<number> {
    return this.http.get<number>('/api/worktime-users/users-count', { params: params as unknown as HttpParams }).pipe(
      catchError(this.sharedService.handleError<number>('get worktime users', 0))
    );
  }

  // 获取工时审批人（调整工时”审批人“节点带人逻辑）
  getWorktimeApprovals(projectCodes: string[]): Observable<IWorktimeApproval> {
    const params = this.sharedService.omitEmptyArrayParams({ project_codes: projectCodes });
    return this.http.get<IWorktimeApproval>('/api/worktime-flow/audit/users', { params: params as unknown as HttpParams }).pipe(
      catchError(this.sharedService.handleError<IWorktimeApproval>('get worktime-flow users', undefined))
    );
  }

  verifyWorktimeValid(worktimes: IWorktime[]): boolean {
    return this.verifyWorkHoursValid(worktimes)
      && this.verifyWorkHoursCountValid(worktimes);
  }

  // 工时校验：0<=工时<=24
  private verifyWorkHoursValid(worktimes: IWorktime[]): boolean {
    let workHoursValidFlag = true;
    _.forEach(worktimes, worktime => {
      if (+worktime.work_hours > 24 || +worktime.work_hours < 0) {
        const alert: IAlert = {
          type: 'danger',
          title: '工时不能低于0h，不能超出24h',
          content: [`${worktime.project_name}项目的工时不符合要求`]
        };
        this.subscriptionService.publishAlert(alert);
        workHoursValidFlag = false;
      }
    });
    return workHoursValidFlag;
  }

  // 所有项目工时和：0<=工时<=24
  private verifyWorkHoursCountValid(worktimes: IWorktime[]): boolean {
    let WorkHoursCount = 0;
    _.forEach(worktimes, worktime => {
      WorkHoursCount = WorkHoursCount + (+worktime.work_hours);
    });
    if (WorkHoursCount > 24 || WorkHoursCount < 0) {
      const alert: IAlert = {
        type: 'danger',
        title: '总工时不能低于0h，不能超出24h',
      };
      this.subscriptionService.publishAlert(alert);
      return false;
    }
    return true;
  }

  // 选择未带出审批人的项目不允许提交
  verifyWorktimeProjects(worktimes: IWorktime[]): Observable<boolean> {
    const projectCodes = _.map(worktimes, 'project_code');
    if (!projectCodes.length) {
      return of(true);
    }
    return this.getWorktimeApprovals(projectCodes).pipe(
      map(result => {
        let worktimeProjectValid = true;
        const projects = result.projects;
        _.forEach(projectCodes, projectCode => {
          const project = _.find(projects, { project_code: projectCode });
          if (!project || (project && !project.users.length)) {
            const alert: IAlert = {
              type: 'danger',
              title: `您不在“${projectCode}”组织上，请联系PMT/PAT及时维护`,
            };
            this.subscriptionService.publishAlert(alert);
            worktimeProjectValid = false;
          }
        });
        return worktimeProjectValid;
      })
    );
  }

  exportWorkTimeReport(params: IWorktimeReportParams): Observable<string> {
    const options = {
      responseType: 'text' as 'json',
      params: params as unknown as HttpParams
    };
    return this.http.get<string>('/api/reporting/worktime/data-report', options).pipe(
      catchError(this.sharedService.handleError<string>('export working time report', ''))
    );
  }

}
