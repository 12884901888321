import { Injectable } from '@angular/core';
import {
  createStore,
  Store,
  compose,
  StoreEnhancer,
  Unsubscribe
} from 'redux';

import {
  AppState,
  default as reducer,

  //#region Common
  getCommonIsFullscreen,
  getCommonIsBusy,
  getCommonIsProjectCenter,
  getCommonProject,
  getCommonParentPlanEnginFieldKey,
  //#endregion

  //#region Workflow
  getWorkflow,
  getWorkflowEditEnable,
  getWorkflowDirty,
  getWorkflowValid,
  getWorkflowTips,
  getWorkflowSteps,
  getCurrentWorkflowStep,
  getCurrentWorkflowStepCanCc,
  getCheckedWorkflowStep,
  getWorkflowShowPersonsMaxLimit,
  //#endregion

  //#region OrganizationChart
  getOrganizationChartItems,
  getOrganizationChartItemsTransformed,
  getOrganizationChartItemsTransformedWithoutDeleted,
  getOrganizationChartLayers,
  getOrganizationChartLayersTransformed,
  getOrganizationChartLayersWithoutDeletedItems,
  getOrganizationChartLayersWithoutDeletedItemsTransformed,
  getOrganizationChartAllItems,
  getOrganizationChartAllLayers,
  getOrganizationChartAllLayersTransformed,
  getOrganizationChartAllLayersWithoutDeletedItems,
  getOrganizationChartAllLayersWithoutDeletedItemsTransformed,
  getOrganizationChartCurrentLayers,
  getOrganizationChartCurrentLayersTransformed,
  getOrganizationChartCurrentAllItems,
  getOrganizationChartCurrentAllLayers,
  getOrganizationChartCurrentAllItemsTransformed,
  getOrganizationChartCurrentAllLayersTransformed,
  getOrganizationChartPreviewLayers,
  getOrganizationChartPreviewLayersTransformed,
  getOrganizationChartPreviewAllItems,
  getOrganizationChartPreviewAllLayers,
  getOrganizationChartPreviewAllItemsTransformed,
  getOrganizationChartPreviewAllLayersTransformed,
  getOrganizationChartPreviewAllLayersWithoutDeletedItems,
  getOrganizationChartPreviewAllLayersWithoutDeletedItemsTransformed,
  getCheckedOrganizationChartItem,
  getOrganizationChartEditEnable,
  getOrganizationChartDirty,
  getOrganizationChartViewType,
  getOrganizationTemplateOrgHashCodes,
  getOrganizationChartNeedUpdateParentIdItem,
  //#endregion

  //#region  AddOrganizationItems
  getAddOrganizationChartItemsShow,
  getAddOrganizationChartItemAddWays,
  getAddOrganizationChartItemsParentNodePath,
  getAddOrganizationChartItemsEnableEdit,
  getAddOrganizationChartItemsCustomBlackList,
  //#endregion

  //#region PlanTree
  getPlanTreeEditEnable,
  getPlanTreeDirty,
  getPlanTreeEditingPlan,
  getPlanTreeProject,
  getPlanTreeMilestones,
  getPlanTreeCurrentMilestone,
  getPlanTreePlans,
  getPlanTreeDirtyPlans,
  getPlanTreeShowPlans,
  getPlanTreeCurrentMilestoneShowPlans,
  getPlanTreeCurrentMilestoneCheckedPlans,
  getPlanTreeCurrentMilestoneIsCheckedAll,
  getPlanTreeCurrentPlan,
  getPlanTreeShowIssueApprovedPlans,
  //#endregion

  //#region Milestone
  getMilestones,
  getCurrentMilestone,
  getEndOn,
  //#endregion

  //#region CheckUsers
  getCheckUsersShow,
  getCheckUsersMultiple,
  getCheckProjectsTooltip,
  getCheckProjectsConfirmMessage,
  getCheckUsersCallback,
  getCheckUsersNewUsers,
  getCheckUsersOldUsers,
  getCheckUsersCheckUsersType,
  //#endregion

  //#region CheckProjects
  getCheckProjectsShow,
  getCheckProjectsAuditType,
  getCheckProjectsMultiple,
  getCheckProjectsCallback,
  getCheckProjectsNewProjects,
  //#endregion

  //#region DigitalDeliverable
  getDeliverablesItems,
  getDeliverablesLightEditEnable,
  getDeliverablesFileEditEnable,
  getDeliverablesOperateEnable,
  getDeliverablesShowAll,
  getDeliverablesValid,
  getDeliverablesDirty,
  getDeliverablesIsLastVersion,
  //#endregion

  //#region DigitalDeliverable
  getDigitalDeliverableShow,
  getDigitalDeliverableEditEnable,
  getDigitalDeliverableCallback,
  getDigitalDeliverableData,
  //#endregion

  //#region Attachments 附件
  getAttachmentsItems,
  // getAttachmentsEditEnable,
  getAttachmentsDirty,
  //#endregion

  //#region Turn
  getTurnShow,
  getTurnCallback,
  getTurnAuditId,
  getTurnCallbackFailed,
  getTurnTask,
  getTurnTurnBy,
  getTurnIsAdminApproval,
  getTurnInfo,
  //#endregion

  //#region Chart
  getChatShow,
  getChatAuditId,
  getChatCallback,
  getChatInfo,
  //#endregion

  //#region ProjectFilters
  getProjectFilters,
  getProjectFiltersCheckedDivisions,
  getProjectFiltersCheckedCategories,
  getProjectFiltersCheckedGroups,
  getProjectFiltersCheckedMilestones,
  getProjectFiltersCheckedProjectRelationTypes,
  getProjectFiltersCheckedLights,
  getProjectFiltersCheckedStatuses,
  getProjectFiltersCheckedOrganizations,
  getProjectFiltersCheckedDevelopmentHosts,
  getProjectFiltersCheckedManagementHosts,
  //#endregion

  //#region SaveNotify  暂存提醒
  getSaveNotifyShow,
  getSaveNotifyConfirmCallback,
  getSaveNotifyCancelCallback,
  //#endregion

  //#region Dialog  对话框
  getDialog,
  getDialogShow,
  getDialogConfirmCallback,
  getDialogCancelCallback,
  //#endregion

  //#region CC  抄送
  getCcPersons,
  //#endregion

  //#region 组织抄送
  getCcOrganizations,
  getWorkflowEnforceDone,
  getTurnUserType,
  getProjectFiltersCheckedPlanLevels,
  getCheckUsersParams,
  CheckOrganizationUsersState,
  getCheckOrganizationUsersShow,
  getCheckOrganizationUsersMultiple,
  getCheckOrganizationUsersCheckUsersType,
  getProjectFiltersCheckedEnginFields,
  getProjectFiltersCheckedCenters,
  getProjectFiltersCheckedProductLines,
  getProjectFiltersCheckedWorktimeDayStates,
  getProjectFiltersCheckedProjectLevels,
  getProjectFiltersCheckedBrands,
  getProjectFiltersCheckedPlatforms,
  getProjectFiltersCheckedProjectCodes,
  //#endregion

  // #region 需求
  getRequirementData,
  getRequirementInitialBasicInfoDto,
  getRequirementInitialBasicInfoDirty,
  getRequirementInitialAnalysisDto,
  getRequirementInitialAnalysisDirty,
  // #endregion

  // #region 需求附件
  getRequirementAttachmentsOriginalItems,
  getRequirementAttachmentsOriginalDirty,
  getRequirementAttachmentsInitialBasicItems,
  getRequirementAttachmentsInitialBasicDirty,
  getRequirementAttachmentsInitialAnalysisItems,
  getRequirementAttachmentsInitialAnalysisDirty,
  getRequirementAttachmentsAuditItems,
  getRequirementAttachmentsAuditDirty,
  getProjectFiltersCheckedRequirementFields,
  getProjectFiltersCheckedRequirementStatuses,
  getProjectFiltersCheckedRequirementTypes,
  getProjectFiltersCheckedRequirementLevels,
  getProjectFiltersCheckedConnectRequirements,
  // #endregion

} from './reducers';

import {
  CommonActions,
  WorkflowActions,
  OrganizationChartActions,
  PlanTreeActions,
  MilestoneActions,
  CheckUsersActions,
  CheckProjectsActions,
  DeliverablesActions,
  DigitalDeliverableActions,
  AttachmentsActions,
  TurnActions,
  ChatActions,
  ProjectFiltersActions,
  SaveNotifyActions,
  DialogActions,
  CcActions,
  CcOrgActions,
  AddOrganizationChartItemsActions,
  CheckOrganizationUsersActions,
  RequirementActions,
  RequirementAttachmentsActions
} from './actions';
import {
  CommonState,
  WorkflowState,
  OrganizationChartState,
  AddOrganizationChartItemState,
  PlanTreeState,
  MilestoneState,
  CheckUsersState,
  CheckProjectsState,
  DeliverablesState,
  DigitalDeliverableState,
  AttachmentsState,
  TurnState,
  ChatState,
  ProjectFiltersState,
  SaveNotifyState,
  DialogState,
  CcState,
  CcOrgState,
  RequirementState,
  RequirementAttachmentsState
} from './reducers';

import {
  IWorkflow,
  IWorkflowStep,
  IOrganizationChartItem,
  IOrganizationChartLayer,
  TOrganizationChartItemAddType,
  IPlanTreeItem,
  IMilestone,
  IPlan,
  IUserInfo,
  ICheckUsersCallback,
  IProject,
  ICheckProjectsCallback,
  IDigitalDeliverableCallback,
  ITurnCallback,
  ITurnFailedCallback,
  ITurnInfo,
  IChatInfo,
  IChatCallback,
  IDeliverableItem,
  IFileUploadInfo,
  IProjectFilterItem,
  IProjectFilterType,
  IOrganization,
  ISaveNotifyCallback,
  ITaskInfo,
  IDialogCallback,
  ICcPerson,
  IAddOrganizationChartItemsCallback,
  IOrganizationTemplateOrgHashCode,
  IOrganizationTemplate,
  ICheckOrganizationUsersCallback,
  IProjectFilterProjectCode,
  IRequirementInitialBasicInfoDto,
  IRequirementData,
  IRequirementInitialAnalysisDto
} from 'src/app/models';
import { EAuditType, ECheckOrganizationUsersType, ECheckUsersType } from 'src/app/enums';
import { TWorkflowStepAddType } from '../models/IWorkflow';
import { ICcOrganization } from '../models/IAuditInfo';
import {
  TOrganizationChartViewType,
  TOrganizationChartItemAddWay,
  TOrganizationChartContentType
} from '../models/IOrganizationChartItem';
import { TRequirementAttachmentType } from '../types';
import { setRequirementInitialBasicInfoDirty } from './actions/requirement.actions';

const devtools: StoreEnhancer<AppState> =
  // tslint:disable-next-line: no-string-literal
  window['__REDUX_DEVTOOLS_EXTENSION__'] ?
    // tslint:disable-next-line: no-string-literal
    window['__REDUX_DEVTOOLS_EXTENSION__']() : f => f;

@Injectable({
  providedIn: 'root'
})
export class ReduxService {
  private store: Store<AppState>;
  private unsubscribe: Unsubscribe;

  constructor() {
    this.store = createStore(
      reducer,
      compose(devtools)
    );
  }

  /**
   * 订阅
   *
   * @param readStateFun 读取状态方法.
   * @returns void
   */
  subscribeStore(readStateFun: () => void) {
    this.unsubscribe = this.store.subscribe(readStateFun);
  }

  /**
   * 取消订阅
   */
  unsubscribeStore() {
    this.unsubscribe();
  }

  // #region Common
  getCommonIsFullscreen(): boolean {
    const state: CommonState = this.store.getState().common;
    return getCommonIsFullscreen(state);
  }
  setCommonIsFullscreen(isFullscreen: boolean) {
    this.store.dispatch(CommonActions.setCommonIsFullscreen(isFullscreen));
  }
  getCommonIsBusy(): boolean {
    const state: CommonState = this.store.getState().common;
    return getCommonIsBusy(state);
  }
  setCommonIsBusy(isBusy: boolean) {
    this.store.dispatch(CommonActions.setCommonIsBusy(isBusy));
  }
  getCommonIsProjectCenter(): boolean {
    const state: CommonState = this.store.getState().common;
    return getCommonIsProjectCenter(state);
  }
  getCommonParentPlanEnginFieldKey() {
    const state: CommonState = this.store.getState().common;
    return getCommonParentPlanEnginFieldKey(state);
  }
  setCommonIsProjectCenter(isProjectCenter: boolean) {
    this.store.dispatch(CommonActions.setCommonIsProjectCenter(isProjectCenter));
  }
  getCommonProject(): IProject {
    const state: CommonState = this.store.getState().common;
    return getCommonProject(state);
  }
  setCommonProject(project: IProject) {
    this.store.dispatch(CommonActions.setCommonProject(project));
  }
  setCommonParentPlanEnginFieldKey(enginFieldKey: string) {
    this.store.dispatch(CommonActions.setCommonParentPlanEnginFieldKey(enginFieldKey));
  }
  //#endregion

  // #region Workflow
  getWorkflow(): IWorkflow {
    const state: WorkflowState = this.store.getState().workflow;
    return getWorkflow(state);
  }
  getWorkflowEditEnable(): boolean {
    const state: WorkflowState = this.store.getState().workflow;
    return getWorkflowEditEnable(state);
  }
  getWorkflowDirty(): boolean {
    const state: WorkflowState = this.store.getState().workflow;
    return getWorkflowDirty(state);
  }
  getWorkflowValid(): boolean {
    const state: WorkflowState = this.store.getState().workflow;
    return getWorkflowValid(state);
  }
  getWorkflowTips(): string {
    const state: WorkflowState = this.store.getState().workflow;
    return getWorkflowTips(state);
  }
  getWorkflowEnforceDone(): boolean {
    const state: WorkflowState = this.store.getState().workflow;
    return getWorkflowEnforceDone(state);
  }
  getCurrentWorkflowStep(): IWorkflowStep {
    const state: WorkflowState = this.store.getState().workflow;
    return getCurrentWorkflowStep(state);
  }
  getCurrentWorkflowStepCanCc(): boolean {
    const state: WorkflowState = this.store.getState().workflow;
    return getCurrentWorkflowStepCanCc(state);
  }
  getCheckedWorkflowStep(): IWorkflowStep {
    const state: WorkflowState = this.store.getState().workflow;
    return getCheckedWorkflowStep(state);
  }
  getWorkflowSteps(): IWorkflowStep[] {
    const state: WorkflowState = this.store.getState().workflow;
    return getWorkflowSteps(state);
  }
  getWorkflowShowPersonsMaxLimit(): number {
    const state: WorkflowState = this.store.getState().workflow;
    return getWorkflowShowPersonsMaxLimit(state);
  }
  setWorkflow(workflow: IWorkflow) {
    this.store.dispatch(WorkflowActions.setWorkflow(workflow));
  }
  setWorkflowEditEnable(editEnable: boolean) {
    this.store.dispatch(WorkflowActions.setWorkflowEditEnable(editEnable));
  }
  setWorkflowEnforceDone(enforceDone: boolean) {
    this.store.dispatch(WorkflowActions.setWorkflowEnforceDone(enforceDone));
  }
  setWorkflowDirty(dirty: boolean) {
    this.store.dispatch(WorkflowActions.setWorkflowDirty(dirty));
  }
  setWorkflowShowPersonsMaxLimit(showPersonsMaxLimit: number) {
    this.store.dispatch(WorkflowActions.setWorkflowShowPersonsMaxLimit(showPersonsMaxLimit));
  }
  checkWorkflowStep(step: IWorkflowStep) {
    this.store.dispatch(WorkflowActions.checkWorkflowStep(step));
  }
  addWorkflowStep(referenceStep: IWorkflowStep, type: TWorkflowStepAddType) {
    this.store.dispatch(WorkflowActions.addWorkflowStep(referenceStep, type));
  }
  deleteWorkflowStep(step: IWorkflowStep) {
    this.store.dispatch(WorkflowActions.deleteWorkflowStep(step));
  }
  updateWorkflowStep(step: IWorkflowStep) {
    this.store.dispatch(WorkflowActions.updateWorkflowStep(step));
  }
  //#endregion

  // #region OrganizationChart
  getOrganizationChartItems() {
    const state: OrganizationChartState = this.store.getState().organizationChart;
    return getOrganizationChartItems(state);
  }
  getOrganizationChartItemsTransformed() {
    const state: OrganizationChartState = this.store.getState().organizationChart;
    return getOrganizationChartItemsTransformed(state);
  }
  getOrganizationChartItemsTransformedWithoutDeleted() {
    const state: OrganizationChartState = this.store.getState().organizationChart;
    return getOrganizationChartItemsTransformedWithoutDeleted(state);
  }
  getOrganizationChartLayers() {
    const state: OrganizationChartState = this.store.getState().organizationChart;
    return getOrganizationChartLayers(state);
  }
  getOrganizationChartLayersTransformed() {
    const state: OrganizationChartState = this.store.getState().organizationChart;
    return getOrganizationChartLayersTransformed(state);
  }
  getOrganizationChartLayersWithoutDeletedItems() {
    const state: OrganizationChartState = this.store.getState().organizationChart;
    return getOrganizationChartLayersWithoutDeletedItems(state);
  }
  getOrganizationChartLayersWithoutDeletedItemsTransformed() {
    const state: OrganizationChartState = this.store.getState().organizationChart;
    return getOrganizationChartLayersWithoutDeletedItemsTransformed(state);
  }
  getOrganizationChartAllItems() {
    const state: OrganizationChartState = this.store.getState().organizationChart;
    return getOrganizationChartAllItems(state);
  }
  getOrganizationChartAllLayers() {
    const state: OrganizationChartState = this.store.getState().organizationChart;
    return getOrganizationChartAllLayers(state);
  }
  getOrganizationChartAllLayersTransformed() {
    const state: OrganizationChartState = this.store.getState().organizationChart;
    return getOrganizationChartAllLayersTransformed(state);
  }
  getOrganizationChartAllLayersWithoutDeletedItems() {
    const state: OrganizationChartState = this.store.getState().organizationChart;
    return getOrganizationChartAllLayersWithoutDeletedItems(state);
  }
  getOrganizationChartAllLayersWithoutDeletedItemsTransformed() {
    const state: OrganizationChartState = this.store.getState().organizationChart;
    return getOrganizationChartAllLayersWithoutDeletedItemsTransformed(state);
  }
  getOrganizationChartCurrentLayers() {
    const state: OrganizationChartState = this.store.getState().organizationChart;
    return getOrganizationChartCurrentLayers(state);
  }
  getOrganizationChartCurrentLayersTransformed() {
    const state: OrganizationChartState = this.store.getState().organizationChart;
    return getOrganizationChartCurrentLayersTransformed(state);
  }
  getOrganizationChartCurrentAllItems() {
    const state: OrganizationChartState = this.store.getState().organizationChart;
    return getOrganizationChartCurrentAllItems(state);
  }
  getOrganizationChartCurrentAllLayers() {
    const state: OrganizationChartState = this.store.getState().organizationChart;
    return getOrganizationChartCurrentAllLayers(state);
  }
  getOrganizationChartCurrentAllItemsTransformed() {
    const state: OrganizationChartState = this.store.getState().organizationChart;
    return getOrganizationChartCurrentAllItemsTransformed(state);
  }
  getOrganizationChartCurrentAllLayersTransformed() {
    const state: OrganizationChartState = this.store.getState().organizationChart;
    return getOrganizationChartCurrentAllLayersTransformed(state);
  }
  getOrganizationChartPreviewLayers() {
    const state: OrganizationChartState = this.store.getState().organizationChart;
    return getOrganizationChartPreviewLayers(state);
  }
  getOrganizationChartPreviewLayersTransformed() {
    const state: OrganizationChartState = this.store.getState().organizationChart;
    return getOrganizationChartPreviewLayersTransformed(state);
  }
  getOrganizationChartPreviewAllItems() {
    const state: OrganizationChartState = this.store.getState().organizationChart;
    return getOrganizationChartPreviewAllItems(state);
  }
  getOrganizationChartPreviewAllLayers() {
    const state: OrganizationChartState = this.store.getState().organizationChart;
    return getOrganizationChartPreviewAllLayers(state);
  }
  getOrganizationChartPreviewAllItemsTransformed() {
    const state: OrganizationChartState = this.store.getState().organizationChart;
    return getOrganizationChartPreviewAllItemsTransformed(state);
  }
  getOrganizationChartPreviewAllLayersTransformed() {
    const state: OrganizationChartState = this.store.getState().organizationChart;
    return getOrganizationChartPreviewAllLayersTransformed(state);
  }
  getOrganizationChartPreviewAllLayersWithoutDeletedItems() {
    const state: OrganizationChartState = this.store.getState().organizationChart;
    return getOrganizationChartPreviewAllLayersWithoutDeletedItems(state);
  }
  getOrganizationChartPreviewAllLayersWithoutDeletedItemsTransformed() {
    const state: OrganizationChartState = this.store.getState().organizationChart;
    return getOrganizationChartPreviewAllLayersWithoutDeletedItemsTransformed(state);
  }
  getCheckedOrganizationChartItem() {
    const state: OrganizationChartState = this.store.getState().organizationChart;
    return getCheckedOrganizationChartItem(state);
  }
  getOrganizationChartEditEnable(): boolean {
    const state: OrganizationChartState = this.store.getState().organizationChart;
    return getOrganizationChartEditEnable(state);
  }
  getOrganizationChartDirty(): boolean {
    const state: OrganizationChartState = this.store.getState().organizationChart;
    return getOrganizationChartDirty(state);
  }
  getOrganizationChartViewType(): TOrganizationChartViewType {
    const state: OrganizationChartState = this.store.getState().organizationChart;
    return getOrganizationChartViewType(state);
  }
  setOrganizationChartItems(items: IOrganizationChartItem[]) {
    this.store.dispatch(OrganizationChartActions.setOrganizationChartItems(items));
  }
  setOrganizationChartCurrentAllItems(currentAllItems: IOrganizationChartItem[]) {
    this.store.dispatch(OrganizationChartActions.setOrganizationChartCurrentAllItems(currentAllItems));
  }
  setOrganizationChartPreviewAllItems(previewAllItems: IOrganizationChartItem[]) {
    this.store.dispatch(OrganizationChartActions.setOrganizationChartPreviewAllItems(previewAllItems));
  }
  setOrganizationChartPath(path: string) {
    this.store.dispatch(OrganizationChartActions.setOrganizationChartPath(path));
  }
  setOrganizationChartEditEnable(editEnable: boolean) {
    this.store.dispatch(OrganizationChartActions.setOrganizationChartEditEnable(editEnable));
  }
  setOrganizationChartDirty(dirty: boolean) {
    this.store.dispatch(OrganizationChartActions.setOrganizationChartDirty(dirty));
  }
  setOrganizationChartNeedUpdateParentIdItem(item: IOrganizationChartItem) {
    this.store.dispatch(OrganizationChartActions.setOrganizationChartNeedUpdateParentIdItem(item));
  }

  setOrganizationChartViewType(viewType: TOrganizationChartViewType) {
    this.store.dispatch(OrganizationChartActions.setOrganizationChartViewType(viewType));
  }

  checkOrganizationChartItem(item: IOrganizationChartItem) {
    this.store.dispatch(OrganizationChartActions.checkOrganizationChartItem(item));
  }
  checkOrganizationChartItemTransformed(itemTransformed: IOrganizationChartItem) {
    this.store.dispatch(OrganizationChartActions.checkOrganizationChartItemTransformed(itemTransformed));
  }

  toggleCollapseOrganizationChartItemTransformed(itemTransformed: IOrganizationChartItem) {
    this.store.dispatch(OrganizationChartActions.toggleCollapseOrganizationChartItemTransformed(itemTransformed));
  }

  toggleCollapseAllOrganizationChartItems(isCollapsed: boolean, contentType: TOrganizationChartContentType) {
    this.store.dispatch(OrganizationChartActions.toggleCollapseAllOrganizationChartItems(isCollapsed, contentType));
  }

  updateOrganizationChartItem(item: IOrganizationChartItem) {
    this.store.dispatch(OrganizationChartActions.updateOrganizationChartItem(item));
  }
  addOrganizationChartItems(
    referenceItem: IOrganizationChartItem,
    newItems: IOrganizationChartItem[],
    addType: TOrganizationChartItemAddType
  ) {
    this.store.dispatch(OrganizationChartActions.addOrganizationChartItems(referenceItem, newItems, addType));
  }
  addStandardOrganizationChartItems(
    referenceItem: IOrganizationChartItem,
    newItems: IOrganizationChartItem[],
  ) {
    this.store.dispatch(OrganizationChartActions.addStandardOrganizationChartItems(referenceItem, newItems));
  }
  deleteOrganizationChartItems(
    items: IOrganizationChartItem[]
  ) {
    this.store.dispatch(OrganizationChartActions.deleteOrganizationChartItems(items));
  }
  deleteOrganizationChartOffspringItems(
    item: IOrganizationChartItem
  ) {
    this.store.dispatch(OrganizationChartActions.deleteOrganizationChartOffspringItems(item));
  }
  addTemplateOrganizationChartItems(
    items: IOrganizationChartItem[],
    parentNodePath: string,
    currentUser?: IUserInfo,
    isProjectInWhitelist?: boolean,
    permissions?: string[]
  ) {
    this.store.dispatch(OrganizationChartActions.addTemplateOrganizationChartItems(items, parentNodePath, currentUser, isProjectInWhitelist, permissions));
  }
  getOrganizationTemplateOrgHashCodes(): IOrganizationTemplateOrgHashCode[] {
    const state: OrganizationChartState = this.store.getState().organizationChart;
    return getOrganizationTemplateOrgHashCodes(state);
  }
  setOrganizationTemplateOrgHashCodes(
    organizationTemplateOrgHashCodes: IOrganizationTemplateOrgHashCode[]
  ) {
    this.store.dispatch(OrganizationChartActions.setOrganizationTemplateOrgHashCodes(organizationTemplateOrgHashCodes));
  }
  addOrganizationTemplateOrgHashCode(organizationTemplateOrgHashCode: IOrganizationTemplateOrgHashCode
  ) {
    this.store.dispatch(OrganizationChartActions.addOrganizationTemplateOrgHashCode(organizationTemplateOrgHashCode));
  }
  deleteOrganizationTemplateOrgHashCode(templateOrgId: number
  ) {
    this.store.dispatch(OrganizationChartActions.deleteOrganizationTemplateOrgHashCode(templateOrgId));
  }
  getOrganizationChartNeedUpdateParentIdItem(): IOrganizationChartItem {
    const state: OrganizationChartState = this.store.getState().organizationChart;
    return getOrganizationChartNeedUpdateParentIdItem(state);
  }
  // #endregion

  // #region addOrganizationItem
  getAddOrganizationChartItemsShow() {
    const state: AddOrganizationChartItemState = this.store.getState().addOrganizationChartItem;
    return getAddOrganizationChartItemsShow(state);
  }
  getAddOrganizationChartItemAddWays() {
    const state: AddOrganizationChartItemState = this.store.getState().addOrganizationChartItem;
    return getAddOrganizationChartItemAddWays(state);
  }
  getAddOrganizationChartItemsParentNodePath() {
    const state: AddOrganizationChartItemState = this.store.getState().addOrganizationChartItem;
    return getAddOrganizationChartItemsParentNodePath(state);
  }
  getAddOrganizationChartItemsEnableEdit() {
    const state: AddOrganizationChartItemState = this.store.getState().addOrganizationChartItem;
    return getAddOrganizationChartItemsEnableEdit(state);
  }
  getAddOrganizationChartItemsCustomBlackList() {
    const state: AddOrganizationChartItemState = this.store.getState().addOrganizationChartItem;
    return getAddOrganizationChartItemsCustomBlackList(state);
  }
  showAddOrganizationChartItem(
    callback: IAddOrganizationChartItemsCallback, addWays: TOrganizationChartItemAddWay[], parentNodePath?: string,
    enableEdit?: boolean
  ) {
    this.store.dispatch(AddOrganizationChartItemsActions.showAddOrganizationChartItem(callback,
      addWays, parentNodePath, enableEdit));
  }
  confirmAddOrganizationChartItem() {
    this.store.dispatch(AddOrganizationChartItemsActions.confirmAddOrganizationChartItem());
  }
  cancelAddOrganizationChartItem() {
    this.store.dispatch(AddOrganizationChartItemsActions.cancelAddOrganizationChartItem());
  }
  setAddOrganizationChartItem(addData: number | IOrganizationChartItem | IOrganizationChartItem[]) {
    this.store.dispatch(AddOrganizationChartItemsActions.setAddOrganizationChartItem(addData));
  }
  setAddOrganizationChartItemsCustomBlackList(customBlackList: string[]) {
    this.store.dispatch(AddOrganizationChartItemsActions.setAddOrganizationChartItemsCustomBlackList(customBlackList));
  }
  // #endregion

  // #region PlanTree
  getPlanTreeEditEnable() {
    const state: PlanTreeState = this.store.getState().planTree;
    return getPlanTreeEditEnable(state);
  }
  getPlanTreeDirty() {
    const state: PlanTreeState = this.store.getState().planTree;
    return getPlanTreeDirty(state);
  }
  getPlanTreeEditingPlan() {
    const state: PlanTreeState = this.store.getState().planTree;
    return getPlanTreeEditingPlan(state);
  }
  getPlanTreeProject() {
    const state: PlanTreeState = this.store.getState().planTree;
    return getPlanTreeProject(state);
  }
  getPlanTreeMilestones() {
    const state: PlanTreeState = this.store.getState().planTree;
    return getPlanTreeMilestones(state);
  }
  getPlanTreeCurrentMilestone() {
    const state: PlanTreeState = this.store.getState().planTree;
    return getPlanTreeCurrentMilestone(state);
  }
  getPlanTreePlans() {
    const state: PlanTreeState = this.store.getState().planTree;
    return getPlanTreePlans(state);
  }
  getPlanTreeDirtyPlans() {
    const state: PlanTreeState = this.store.getState().planTree;
    return getPlanTreeDirtyPlans(state);
  }
  getPlanTreeShowPlans() {
    const state: PlanTreeState = this.store.getState().planTree;
    return getPlanTreeShowPlans(state);
  }
  getPlanTreeCurrentMilestoneShowPlans() {
    const state: PlanTreeState = this.store.getState().planTree;
    return getPlanTreeCurrentMilestoneShowPlans(state);
  }
  getPlanTreeCurrentMilestoneCheckedPlans() {
    const state: PlanTreeState = this.store.getState().planTree;
    return getPlanTreeCurrentMilestoneCheckedPlans(state);
  }
  getPlanTreeCurrentMilestoneIsCheckedAll() {
    const state: PlanTreeState = this.store.getState().planTree;
    return getPlanTreeCurrentMilestoneIsCheckedAll(state);
  }
  getPlanTreeCurrentPlan() {
    const state: PlanTreeState = this.store.getState().planTree;
    return getPlanTreeCurrentPlan(state);
  }
  getPlanTreeShowIssueApprovedPlans() {
    const state: PlanTreeState = this.store.getState().planTree;
    return getPlanTreeShowIssueApprovedPlans(state);
  }
  setPlanTreeEditEnable(editEnable: boolean) {
    this.store.dispatch(PlanTreeActions.setPlanTreeEditEnable(editEnable));
  }
  setPlanTreeDirty(dirty: boolean) {
    this.store.dispatch(PlanTreeActions.setPlanTreeDirty(dirty));
  }
  setPlanTreeEditingPlan(plan: IPlan) {
    this.store.dispatch(PlanTreeActions.setPlanTreeEditingPlan(plan));
  }
  setPlanTreeProject(project: IProject) {
    this.store.dispatch(PlanTreeActions.setPlanTreeProject(project));
  }
  setPlanTreeMilestones(milestones: IMilestone[]) {
    this.store.dispatch(PlanTreeActions.setPlanTreeMilestones(milestones));
  }
  checkPlanTreeMilestone(milestone: IMilestone) {
    this.store.dispatch(PlanTreeActions.checkPlanTreeMilestone(milestone));
  }
  setPlanTreeCurrentMilestoneIsCheckedAll(isCheckedAll: boolean) {
    this.store.dispatch(PlanTreeActions.setPlanTreeCurrentMilestoneIsCheckedAll(isCheckedAll));
  }
  setPlanTreePlans(plans: IPlan[]) {
    this.store.dispatch(PlanTreeActions.setPlanTreePlans(plans));
  }
  setPlanTreeDirtyPlans(plans: IPlan[]) {
    this.store.dispatch(PlanTreeActions.setPlanTreeDirtyPlans(plans));
  }
  addPlanTreePlan(plan: IPlan) {
    this.store.dispatch(PlanTreeActions.addPlanTreePlan(plan));
  }
  addPlanTreePlans(plans: IPlan[]) {
    this.store.dispatch(PlanTreeActions.addPlanTreePlans(plans));
  }
  deletePlanTreePlan(plan: IPlan) {
    this.store.dispatch(PlanTreeActions.deletePlanTreePlan(plan));
  }
  deletePlanTreePlans(plans: IPlan[]) {
    this.store.dispatch(PlanTreeActions.deletePlanTreePlans(plans));
  }
  updatePlanTreePlan(plan: IPlan) {
    this.store.dispatch(PlanTreeActions.updatePlanTreePlan(plan));
  }
  toggleCheckPlanTreePlan(plan: IPlan, isChecked: boolean) {
    this.store.dispatch(PlanTreeActions.toggleCheckPlanTreePlan(plan, isChecked));
  }
  toggleFoldPlanTreePlan(plan: IPlan) {
    this.store.dispatch(PlanTreeActions.toggleFoldPlanTreePlan(plan));
  }
  setPlanTreeCurrentPlan(plan: IPlan) {
    this.store.dispatch(PlanTreeActions.setPlanTreeCurrentPlan(plan));
  }
  setPlanTreeShowIssueApprovedPlans(showIssueApprovedPlans: boolean) {
    this.store.dispatch(PlanTreeActions.setPlanTreeShowIssueApprovedPlans(showIssueApprovedPlans));
  }
  //#endregion

  // #region Milestone
  getMilestones() {
    const state: MilestoneState = this.store.getState().milestone;
    return getMilestones(state);
  }
  getCurrentMilestone() {
    const state: MilestoneState = this.store.getState().milestone;
    return getCurrentMilestone(state);
  }
  getMilestoneEndOn() {
    const state: MilestoneState = this.store.getState().milestone;
    return getEndOn(state);
  }
  setMilestones(milestones: IMilestone[]) {
    this.store.dispatch(MilestoneActions.setMilestones(milestones));
  }
  setCurrentMilestone(milestone: IMilestone) {
    this.store.dispatch(MilestoneActions.setCurrentMilestone(milestone));
  }
  setMilestoneEndOn(endOn: Date) {
    this.store.dispatch(MilestoneActions.setEndOn(endOn));
  }
  //#endregion

  // #region CheckUsers
  getCheckUsersShow() {
    const state: CheckUsersState = this.store.getState().checkUsers;
    return getCheckUsersShow(state);
  }
  getCheckUsersMultiple() {
    const state: CheckUsersState = this.store.getState().checkUsers;
    return getCheckUsersMultiple(state);
  }
  getCheckUsersCallback() {
    const state: CheckUsersState = this.store.getState().checkUsers;
    return getCheckUsersCallback(state);
  }
  getCheckUsersNewUsers() {
    const state: CheckUsersState = this.store.getState().checkUsers;
    return getCheckUsersNewUsers(state);
  }
  getCheckUsersOldUsers() {
    const state: CheckUsersState = this.store.getState().checkUsers;
    return getCheckUsersOldUsers(state);
  }
  getCheckUsersCheckUsersType() {
    const state: CheckUsersState = this.store.getState().checkUsers;
    return getCheckUsersCheckUsersType(state);
  }
  getCheckUsersParams() {
    const state: CheckUsersState = this.store.getState().checkUsers;
    return getCheckUsersParams(state);
  }
  // tslint:disable-next-line:max-line-length
  showCheckUsers(callback: ICheckUsersCallback, isMultiple?: boolean, oldUsers?: IUserInfo[], checkUsersType?: ECheckUsersType, params?: string | string[]) {
    this.store.dispatch(CheckUsersActions.showCheckUsers(callback, isMultiple, oldUsers, checkUsersType, params));
  }
  setCheckUsersNewUsers(newUsers: IUserInfo[]) {
    this.store.dispatch(CheckUsersActions.setCheckUsersNewUsers(newUsers));
  }
  confirmCheckUsers() {
    this.store.dispatch(CheckUsersActions.confirmCheckUsers());
  }
  cancelCheckUsers() {
    this.store.dispatch(CheckUsersActions.cancelCheckUsers());
  }
  //#endregion

  // #region CheckProjects
  getCheckProjectsShow() {
    const state: CheckProjectsState = this.store.getState().checkProjects;
    return getCheckProjectsShow(state);
  }
  getCheckProjectsAuditType() {
    const state: CheckProjectsState = this.store.getState().checkProjects;
    return getCheckProjectsAuditType(state);
  }
  getCheckProjectsMultiple() {
    const state: CheckProjectsState = this.store.getState().checkProjects;
    return getCheckProjectsMultiple(state);
  }
  getCheckProjectsTooltip() {
    const state: CheckProjectsState = this.store.getState().checkProjects;
    return getCheckProjectsTooltip(state);
  }
  getCheckProjectsConfirmMessage() {
    const state: CheckProjectsState = this.store.getState().checkProjects;
    return getCheckProjectsConfirmMessage(state);
  }
  getCheckProjectsCallback() {
    const state: CheckProjectsState = this.store.getState().checkProjects;
    return getCheckProjectsCallback(state);
  }
  getCheckProjectsNewProjects() {
    const state: CheckProjectsState = this.store.getState().checkProjects;
    return getCheckProjectsNewProjects(state);
  }
  showCheckProjects(
    // tslint:disable-next-line:max-line-length
    callback: ICheckProjectsCallback, auditType: EAuditType, isMultiple?: boolean, tooltip?: string, confirmMessage?: string, oldProjects?: IProject[]
  ) {
    this.store.dispatch(CheckProjectsActions.showCheckProjects(callback, auditType, isMultiple, tooltip, confirmMessage, oldProjects));
  }
  setCheckProjectsNewProjects(newProjects: IProject[]) {
    this.store.dispatch(CheckProjectsActions.setCheckProjectsNewProjects(newProjects));
  }
  confirmCheckProjects() {
    this.store.dispatch(CheckProjectsActions.confirmCheckProjects());
  }
  cancelCheckProjects() {
    this.store.dispatch(CheckProjectsActions.cancelCheckProjects());
  }
  //#endregion

  // #region Deliverables
  getDeliverablesItems() {
    const state: DeliverablesState = this.store.getState().deliverables;
    return getDeliverablesItems(state);
  }
  getDeliverablesLightEditEnable() {
    const state: DeliverablesState = this.store.getState().deliverables;
    return getDeliverablesLightEditEnable(state);
  }
  getDeliverablesFileEditEnable() {
    const state: DeliverablesState = this.store.getState().deliverables;
    return getDeliverablesFileEditEnable(state);
  }
  getDeliverablesOperateEnable() {
    const state: DeliverablesState = this.store.getState().deliverables;
    return getDeliverablesOperateEnable(state);
  }
  getDeliverablesShowAll() {
    const state: DeliverablesState = this.store.getState().deliverables;
    return getDeliverablesShowAll(state);
  }
  getDeliverablesValid() {
    const state: DeliverablesState = this.store.getState().deliverables;
    return getDeliverablesValid(state);
  }
  getDeliverablesDirty() {
    const state: DeliverablesState = this.store.getState().deliverables;
    return getDeliverablesDirty(state);
  }
  getDeliverablesIsLastVersion() {
    const state: DeliverablesState = this.store.getState().deliverables;
    return getDeliverablesIsLastVersion(state);
  }
  setDeliverablesItems(items: IDeliverableItem[]) {
    this.store.dispatch(DeliverablesActions.setDeliverablesItems(items));
  }
  setDeliverablesLightEditEnable(lightEditEnable: boolean) {
    this.store.dispatch(DeliverablesActions.setDeliverablesLightEditEnable(lightEditEnable));
  }
  setDeliverablesFileEditEnable(fileEditEnable: boolean) {
    this.store.dispatch(DeliverablesActions.setDeliverablesFileEditEnable(fileEditEnable));
  }
  setDeliverablesOperateEnable(operateEnable: boolean) {
    this.store.dispatch(DeliverablesActions.setDeliverablesOperateEnable(operateEnable));
  }
  setDeliverablesShowAll(showAll: boolean) {
    this.store.dispatch(DeliverablesActions.setDeliverablesShowAll(showAll));
  }
  updateDeliverablesItem(item: IDeliverableItem) {
    this.store.dispatch(DeliverablesActions.updateDeliverablesItem(item));
  }
  setDeliverablesDirty(dirty: boolean) {
    this.store.dispatch(DeliverablesActions.setDeliverablesDirty(dirty));
  }
  setDeliverablesIsLastVersion(isLastVersion: boolean) {
    this.store.dispatch(DeliverablesActions.setDeliverablesIsLastVersion(isLastVersion));
  }
  //#endregion

  // #region DigitalDeliverable
  getDigitalDeliverableShow() {
    const state: DigitalDeliverableState = this.store.getState().digitalDeliverable;
    return getDigitalDeliverableShow(state);
  }
  getDigitalDeliverableEditEnable() {
    const state: DigitalDeliverableState = this.store.getState().digitalDeliverable;
    return getDigitalDeliverableEditEnable(state);
  }
  getDigitalDeliverableCallback() {
    const state: DigitalDeliverableState = this.store.getState().digitalDeliverable;
    return getDigitalDeliverableCallback(state);
  }
  getDigitalDeliverableData() {
    const state: DigitalDeliverableState = this.store.getState().digitalDeliverable;
    return getDigitalDeliverableData(state);
  }
  showDigitalDeliverable(callback: IDigitalDeliverableCallback, editEnable?: boolean) {
    this.store.dispatch(DigitalDeliverableActions.showDigitalDeliverable(callback, editEnable));
  }
  setDigitalDeliverableData(data: JSON) {
    this.store.dispatch(DigitalDeliverableActions.setDigitalDeliverableData(data));
  }
  confirmDigitalDeliverable() {
    this.store.dispatch(DigitalDeliverableActions.confirmDigitalDeliverable());
  }
  cancelDigitalDeliverable() {
    this.store.dispatch(DigitalDeliverableActions.cancelDigitalDeliverable());
  }
  //#endregion

  // #region Attachments
  getAttachmentsItems() {
    const state: AttachmentsState = this.store.getState().attachments;
    return getAttachmentsItems(state);
  }
  // getAttachmentsEditEnable() {
  //   const state: AttachmentsState = this.store.getState().attachments;
  //   return getAttachmentsEditEnable(state);
  // }
  getAttachmentsDirty() {
    const state: AttachmentsState = this.store.getState().attachments;
    return getAttachmentsDirty(state);
  }
  setAttachmentsItems(items: IFileUploadInfo[]) {
    this.store.dispatch(AttachmentsActions.setAttachmentsItems(items));
  }
  // setAttachmentsEditEnable(editEnable: boolean) {
  //   this.store.dispatch(AttachmentsActions.setAttachmentsEditEnable(editEnable));
  // }
  setAttachmentsDirty(dirty: boolean) {
    this.store.dispatch(AttachmentsActions.setAttachmentsDirty(dirty));
  }
  addAttachmentsItem(item: IFileUploadInfo) {
    this.store.dispatch(AttachmentsActions.addAttachmentsItem(item));
  }
  deleteAttachmentsItem(item: IFileUploadInfo) {
    this.store.dispatch(AttachmentsActions.deleteAttachmentsItem(item));
  }
  //#endregion

  // #region Turn
  getTurnShow() {
    const state: TurnState = this.store.getState().turn;
    return getTurnShow(state);
  }
  getTurnCallback() {
    const state: TurnState = this.store.getState().turn;
    return getTurnCallback(state);
  }
  getTurnCallbackFailed() {
    const state: TurnState = this.store.getState().turn;
    return getTurnCallbackFailed(state);
  }
  getTurnAuditId() {
    const state: TurnState = this.store.getState().turn;
    return getTurnAuditId(state);
  }
  getTurnInfo() {
    const state: TurnState = this.store.getState().turn;
    return getTurnInfo(state);
  }
  getTurnTask() {
    const state: TurnState = this.store.getState().turn;
    return getTurnTask(state);
  }
  getTurnTurnBy() {
    const state: TurnState = this.store.getState().turn;
    return getTurnTurnBy(state);
  }
  getTurnFlow() {
    const state: TurnState = this.store.getState().turn;
    return getTurnIsAdminApproval(state);
  }
  getTurnUserType() {
    const state: TurnState = this.store.getState().turn;
    return getTurnUserType(state);
  }
  // tslint:disable-next-line:max-line-length
  showTurn(callback: ITurnCallback, failedCallback: ITurnFailedCallback, auditId: string, turnTask?: ITaskInfo, turnBy?: string, flow?: IWorkflow, turnUserType?: ECheckUsersType) {
    this.store.dispatch(TurnActions.showTurn(callback, failedCallback, auditId, turnTask, turnBy, flow, turnUserType));
  }
  setTurnInfo(turnInfo: ITurnInfo) {
    this.store.dispatch(TurnActions.setTurnInfo(turnInfo));
  }
  confirmTurn() {
    this.store.dispatch(TurnActions.confirmTurn());
  }
  cancelTurn() {
    this.store.dispatch(TurnActions.cancelTurn());
  }
  //#endregion

  // #region Chat
  getChatShow() {
    const state: ChatState = this.store.getState().chat;
    return getChatShow(state);
  }
  getChatAuditId() {
    const state: ChatState = this.store.getState().chat;
    return getChatAuditId(state);
  }
  getChatCallback() {
    const state: ChatState = this.store.getState().chat;
    return getChatCallback(state);
  }
  getChatInfo() {
    const state: ChatState = this.store.getState().chat;
    return getChatInfo(state);
  }
  showChat(callback: IChatCallback, auditId: string) {
    this.store.dispatch(ChatActions.showChat(callback, auditId));
  }
  setChatInfo(chatInfo: IChatInfo) {
    this.store.dispatch(ChatActions.setChatInfo(chatInfo));
  }
  confirmChat() {
    this.store.dispatch(ChatActions.confirmChat());
  }
  cancelChat() {
    this.store.dispatch(ChatActions.cancelChat());
  }
  //#endregion

  //#region ProjectFilters
  getProjectFilters() {
    const state: ProjectFiltersState = this.store.getState().projectFilters;
    return getProjectFilters(state);
  }
  getProjectFiltersCheckedDivisions() {
    const state: ProjectFiltersState = this.store.getState().projectFilters;
    return getProjectFiltersCheckedDivisions(state);
  }
  getProjectFiltersCheckedCategories() {
    const state: ProjectFiltersState = this.store.getState().projectFilters;
    return getProjectFiltersCheckedCategories(state);
  }
  getProjectFiltersCheckedGroups() {
    const state: ProjectFiltersState = this.store.getState().projectFilters;
    return getProjectFiltersCheckedGroups(state);
  }
  getProjectFiltersCheckedMilestones() {
    const state: ProjectFiltersState = this.store.getState().projectFilters;
    return getProjectFiltersCheckedMilestones(state);
  }
  getProjectFiltersCheckedEnginFields() {
    const state: ProjectFiltersState = this.store.getState().projectFilters;
    return getProjectFiltersCheckedEnginFields(state);
  }
  getProjectFiltersCheckedLights() {
    const state: ProjectFiltersState = this.store.getState().projectFilters;
    return getProjectFiltersCheckedLights(state);
  }
  getProjectFiltersCheckedProjectRelationTypes() {
    const state: ProjectFiltersState = this.store.getState().projectFilters;
    return getProjectFiltersCheckedProjectRelationTypes(state);
  }
  getProjectFiltersCheckedStatuses() {
    const state: ProjectFiltersState = this.store.getState().projectFilters;
    return getProjectFiltersCheckedStatuses(state);
  }
  getProjectFiltersCheckedOrganizations() {
    const state: ProjectFiltersState = this.store.getState().projectFilters;
    return getProjectFiltersCheckedOrganizations(state);
  }
  getProjectFiltersCheckedDevelopmentHosts() {
    const state: ProjectFiltersState = this.store.getState().projectFilters;
    return getProjectFiltersCheckedDevelopmentHosts(state);
  }
  getProjectFiltersCheckedManagementHosts() {
    const state: ProjectFiltersState = this.store.getState().projectFilters;
    return getProjectFiltersCheckedManagementHosts(state);
  }
  getProjectFiltersCheckedPlanLevels() {
    const state: ProjectFiltersState = this.store.getState().projectFilters;
    return getProjectFiltersCheckedPlanLevels(state);
  }
  getProjectFiltersCheckedCenters() {
    const state: ProjectFiltersState = this.store.getState().projectFilters;
    return getProjectFiltersCheckedCenters(state);
  }
  getProjectFiltersCheckedProductLines() {
    const state: ProjectFiltersState = this.store.getState().projectFilters;
    return getProjectFiltersCheckedProductLines(state);
  }
  getProjectFiltersCheckedWorktimeDayStates() {
    const state: ProjectFiltersState = this.store.getState().projectFilters;
    return getProjectFiltersCheckedWorktimeDayStates(state);
  }
  getProjectFiltersCheckedProjectLevels() {
    const state: ProjectFiltersState = this.store.getState().projectFilters;
    return getProjectFiltersCheckedProjectLevels(state);
  }
  getProjectFiltersCheckedBrands() {
    const state: ProjectFiltersState = this.store.getState().projectFilters;
    return getProjectFiltersCheckedBrands(state);
  }
  getProjectFiltersCheckedPlatforms() {
    const state: ProjectFiltersState = this.store.getState().projectFilters;
    return getProjectFiltersCheckedPlatforms(state);
  }
  getProjectFiltersCheckedConnectRequirements() {
    const state: ProjectFiltersState = this.store.getState().projectFilters;
    return getProjectFiltersCheckedConnectRequirements(state);
  }
  getProjectFiltersCheckedProjectCodes() {
    const state: ProjectFiltersState = this.store.getState().projectFilters;
    return getProjectFiltersCheckedProjectCodes(state);
  }
  getProjectFiltersCheckedRequirementFields() {
    const state: ProjectFiltersState = this.store.getState().projectFilters;
    return getProjectFiltersCheckedRequirementFields(state);
  }
  getProjectFiltersCheckedRequirementStatuses() {
    const state: ProjectFiltersState = this.store.getState().projectFilters;
    return getProjectFiltersCheckedRequirementStatuses(state);
  }
  getProjectFiltersCheckedRequirementTypes() {
    const state: ProjectFiltersState = this.store.getState().projectFilters;
    return getProjectFiltersCheckedRequirementTypes(state);
  }
  getProjectFiltersCheckedRequirementLevels() {
    const state: ProjectFiltersState = this.store.getState().projectFilters;
    return getProjectFiltersCheckedRequirementLevels(state);
  }
  setProjectFiltersItems(filterType: IProjectFilterType, items: IProjectFilterItem[]) {
    this.store.dispatch(ProjectFiltersActions.setProjectFiltersItems(filterType, items));
  }
  setProjectFiltersOrganizations(items: IOrganization[]) {
    this.store.dispatch(ProjectFiltersActions.setProjectFiltersOrganizations(items));
  }
  setProjectFiltersProjectCodes(items: IProjectFilterProjectCode[]) {
    this.store.dispatch(ProjectFiltersActions.setProjectFiltersProjectCodes(items));
  }
  toggleCheckProjectFiltersItem(filterType: IProjectFilterType, item: IProjectFilterItem, isMultiple?: boolean) {
    this.store.dispatch(ProjectFiltersActions.toggleCheckProjectFiltersItem(filterType, item, isMultiple));
  }
  clearCheckProjectFiltersItems(filterType: IProjectFilterType) {
    this.store.dispatch(ProjectFiltersActions.clearCheckProjectFiltersItems(filterType));
  }
  //#endregion

  // #region SaveNotify 暂存提醒
  getSaveNotifyShow() {
    const state: SaveNotifyState = this.store.getState().saveNotify;
    return getSaveNotifyShow(state);
  }
  getSaveNotifyConfirmCallback() {
    const state: SaveNotifyState = this.store.getState().saveNotify;
    return getSaveNotifyConfirmCallback(state);
  }
  getSaveNotifyCancelCallback() {
    const state: SaveNotifyState = this.store.getState().saveNotify;
    return getSaveNotifyCancelCallback(state);
  }
  showSaveNotify(confirmCallback: ISaveNotifyCallback, cancelCallback: ISaveNotifyCallback) {
    this.store.dispatch(SaveNotifyActions.showSaveNotify(confirmCallback, cancelCallback));
  }
  confirmSaveNotify() {
    this.store.dispatch(SaveNotifyActions.confirmSaveNotify());
  }
  cancelSaveNotify() {
    this.store.dispatch(SaveNotifyActions.cancelSaveNotify());
  }
  //#endregion

  // #region Dialog 对话框
  getDialog() {
    const state: DialogState = this.store.getState().dialog;
    return getDialog(state);
  }
  getDialogShow() {
    const state: DialogState = this.store.getState().dialog;
    return getDialogShow(state);
  }
  getDialogConfirmCallback() {
    const state: DialogState = this.store.getState().dialog;
    return getDialogConfirmCallback(state);
  }
  getDialogCancelCallback() {
    const state: DialogState = this.store.getState().dialog;
    return getDialogCancelCallback(state);
  }
  showDialog(
    title: string,
    content: string[],
    confirmCallback: IDialogCallback,
    cancelCallback?: IDialogCallback,
    confirmCallbackParams?: any,
    cancelCallbackParams?: any,
    tip?: string
  ) {
    this.store.dispatch(
      DialogActions.showDialog(title, content, confirmCallback, cancelCallback, confirmCallbackParams, cancelCallbackParams, tip)
    );
  }
  confirmDialog() {
    this.store.dispatch(DialogActions.confirmDialog());
  }
  cancelDialog() {
    this.store.dispatch(DialogActions.cancelDialog());
  }
  //#endregion

  // #region CC 抄送
  getCcPersons() {
    const state: CcState = this.store.getState().cc;
    return getCcPersons(state);
  }
  setCcPersons(persons: ICcPerson[]) {
    this.store.dispatch(CcActions.setCcPersons(persons));
  }
  addCcPersons(persons: ICcPerson[]) {
    this.store.dispatch(CcActions.addCcPersons(persons));
  }
  deleteCcPerson(person: ICcPerson) {
    this.store.dispatch(CcActions.deleteCcPerson(person));
  }
  clearCcPersons() {
    this.store.dispatch(CcActions.clearCcPersons());
  }
  //#endregion


  // #region 组织抄送
  getCcOrganizations() {
    const state: CcOrgState = this.store.getState().ccOrg;
    return getCcOrganizations(state);
  }
  setCcOrgs(organizations: ICcOrganization[]) {
    this.store.dispatch(CcOrgActions.setCcOrg(organizations));
  }
  addCcOrg(organization: ICcOrganization) {
    this.store.dispatch(CcOrgActions.addCcOrg(organization));
  }
  deleteCcOrg(organization: ICcOrganization) {
    this.store.dispatch(CcOrgActions.deleteCcOrg(organization));
  }
  clearCcOrgs() {
    this.store.dispatch(CcOrgActions.clearCcOrgs());
  }
  //#endregion

  // #region CheckOrganizationUsers
  getCheckOrganizationUsersShow() {
    const state: CheckOrganizationUsersState = this.store.getState().checkOrganizationUsers;
    return getCheckOrganizationUsersShow(state);
  }
  getCheckOrganizationUsersMultiple() {
    const state: CheckOrganizationUsersState = this.store.getState().checkOrganizationUsers;
    return getCheckOrganizationUsersMultiple(state);
  }
  getCheckOrganizationUsersUserType() {
    const state: CheckOrganizationUsersState = this.store.getState().checkOrganizationUsers;
    return getCheckOrganizationUsersCheckUsersType(state);
  }
  showCheckOrganizationUsers(
    callback: ICheckOrganizationUsersCallback,
    isMultiple?: boolean,
    checkOrganizationUserType?: ECheckOrganizationUsersType
  ) {
    this.store.dispatch(CheckOrganizationUsersActions.showCheckOrganizationUsers(callback, isMultiple, checkOrganizationUserType));
  }
  setCheckOrganizationUsersNewUsers(newUsers: IUserInfo[]) {
    this.store.dispatch(CheckOrganizationUsersActions.setCheckOrganizationUsersNewUsers(newUsers));
  }
  confirmCheckOrganizationUsers() {
    this.store.dispatch(CheckOrganizationUsersActions.confirmCheckOrganizationUsers());
  }
  cancelCheckOrganizationUsers() {
    this.store.dispatch(CheckOrganizationUsersActions.cancelCheckOrganizationUsers());
  }
  //#endregion

  // #region 需求
  getRequirementData() {
    const state: RequirementState = this.store.getState().requirement;
    return getRequirementData(state);
  }
  getRequirementInitialBasicInfoDto() {
    const state: RequirementState = this.store.getState().requirement;
    return getRequirementInitialBasicInfoDto(state);
  }
  getRequirementInitialBasicInfoDirty() {
    const state: RequirementState = this.store.getState().requirement;
    return getRequirementInitialBasicInfoDirty(state);
  }
  getRequirementInitialAnalysisDto() {
    const state: RequirementState = this.store.getState().requirement;
    return getRequirementInitialAnalysisDto(state);
  }
  getRequirementInitialAnalysisDirty() {
    const state: RequirementState = this.store.getState().requirement;
    return getRequirementInitialAnalysisDirty(state);
  }
  setRequirementData(requirementData: IRequirementData) {
    this.store.dispatch(RequirementActions.setRequirementData(requirementData));
  }
  updateRequirementInitialBasicInfoDto(initialBasicInfoDto: IRequirementInitialBasicInfoDto) {
    this.store.dispatch(RequirementActions.updateRequirementInitialBasicInfoDto(initialBasicInfoDto));
  }
  setRequirementInitialBasicInfoDirty(dirty: boolean) {
    this.store.dispatch(RequirementActions.setRequirementInitialBasicInfoDirty(dirty));
  }
  updateRequirementInitialAnalysisDto(initialAnalysisDto: IRequirementInitialAnalysisDto) {
    this.store.dispatch(RequirementActions.updateRequirementInitialAnalysisDto(initialAnalysisDto));
  }
  setRequirementInitialAnalysisDirty(dirty: boolean) {
    this.store.dispatch(RequirementActions.setRequirementInitialAnalysisDirty(dirty));
  }
  resetRequirement() {
    this.store.dispatch(RequirementActions.resetRequirement());
  }
  // #endregion

  // #region 需求附件
  getRequirementAttachmentsOriginalItems() {
    const state: RequirementAttachmentsState = this.store.getState().requirementAttachments;
    return getRequirementAttachmentsOriginalItems(state);
  }
  getRequirementAttachmentsOriginalDirty() {
    const state: RequirementAttachmentsState = this.store.getState().requirementAttachments;
    return getRequirementAttachmentsOriginalDirty(state);
  }
  getRequirementAttachmentsInitialBasicItems() {
    const state: RequirementAttachmentsState = this.store.getState().requirementAttachments;
    return getRequirementAttachmentsInitialBasicItems(state);
  }
  getRequirementAttachmentsInitialBasicDirty() {
    const state: RequirementAttachmentsState = this.store.getState().requirementAttachments;
    return getRequirementAttachmentsInitialBasicDirty(state);
  }
  getRequirementAttachmentsInitialAnalysisItems() {
    const state: RequirementAttachmentsState = this.store.getState().requirementAttachments;
    return getRequirementAttachmentsInitialAnalysisItems(state);
  }
  getRequirementAttachmentsInitialAnalysisDirty() {
    const state: RequirementAttachmentsState = this.store.getState().requirementAttachments;
    return getRequirementAttachmentsInitialAnalysisDirty(state);
  }
  getRequirementAttachmentsAuditItems() {
    const state: RequirementAttachmentsState = this.store.getState().requirementAttachments;
    return getRequirementAttachmentsAuditItems(state);
  }
  getRequirementAttachmentsAuditDirty() {
    const state: RequirementAttachmentsState = this.store.getState().requirementAttachments;
    return getRequirementAttachmentsAuditDirty(state);
  }
  setRequirementAttachmentsItems(items: IFileUploadInfo[], attachmentType: TRequirementAttachmentType) {
    this.store.dispatch(RequirementAttachmentsActions.setRequirementAttachmentsItems(items, attachmentType));
  }
  setRequirementAttachmentsDirty(dirty: boolean, attachmentType: TRequirementAttachmentType) {
    this.store.dispatch(RequirementAttachmentsActions.setRequirementAttachmentsDirty(dirty, attachmentType));
  }
  addRequirementAttachmentsItem(item: IFileUploadInfo, attachmentType: TRequirementAttachmentType) {
    this.store.dispatch(RequirementAttachmentsActions.addRequirementAttachmentsItem(item, attachmentType));
  }
  deleteRequirementAttachmentsItem(item: IFileUploadInfo, attachmentType: TRequirementAttachmentType) {
    this.store.dispatch(RequirementAttachmentsActions.deleteRequirementAttachmentsItem(item, attachmentType));
  }
  // #endregion
}


