// 公共信息
import { Action, ActionCreator } from 'redux';
import { IProject } from 'src/app/models';

// #region
export interface SetCommonIsBusyAction extends Action {
  isBusy: boolean;
}
export const SET_COMMON_IS_BUSY = 'SET_COMMON_IS_BUSY';
export const setCommonIsBusy: ActionCreator<SetCommonIsBusyAction> = (isBusy: boolean) => ({
  type: SET_COMMON_IS_BUSY,
  isBusy
});
// #endregion

// #region
export interface SetCommonIsFullscreenAction extends Action {
  isFullscreen: boolean;
}
export const SET_COMMON_IS_FULLSCREEN = 'SET_COMMON_IS_FULLSCREEN';
export const setCommonIsFullscreen: ActionCreator<SetCommonIsFullscreenAction> = (isFullscreen: boolean) => ({
  type: SET_COMMON_IS_FULLSCREEN,
  isFullscreen
});
// #endregion

// #region
export interface SetCommonIsProjectCenterAction extends Action {
  isProjectCenter: boolean;
}
export const SET_COMMON_IS_PROJECT_CENTER = 'SET_COMMON_IS_PROJECT_CENTER';
export const setCommonIsProjectCenter: ActionCreator<SetCommonIsProjectCenterAction> = (isProjectCenter: boolean) => ({
  type: SET_COMMON_IS_PROJECT_CENTER,
  isProjectCenter
});
// #endregion

// #region
export interface SetCommonProjectAction extends Action {
  project: IProject;
}
export const SET_COMMON_PROJECT = 'SET_COMMON_PROJECT';
export const setCommonProject: ActionCreator<SetCommonProjectAction> = (project: IProject) => ({
  type: SET_COMMON_PROJECT,
  project
});
// #endregion


// #region 设置父计划的engin_field_key
export interface SetCommonParentPlanEnginFieldKeyAction extends Action {
  parentPlanEnginFieldKey: string;
}
export const SET_COMMON_PARENT_PLAN_ENGIN_FIELD_KEY = 'SET_COMMON_PARENT_PLAN_ENGIN_FIELD_KEY';
// tslint:disable-next-line:max-line-length
export const setCommonParentPlanEnginFieldKey: ActionCreator<SetCommonParentPlanEnginFieldKeyAction> = (parentPlanEnginFieldKey: string) => ({
  type: SET_COMMON_PARENT_PLAN_ENGIN_FIELD_KEY,
  parentPlanEnginFieldKey
});
// #endregion
