// 模板类型
export enum EOrganizationTemplateType {
    /// <summary>
    /// 个人模板
    /// </summary>
    Personal = 0,

    /// <summary>
    /// 标准模板
    /// </summary>
    Standard = 10,

    /// <summary>
    /// 组织模板
    /// </summary>
    Org = 20,
}
