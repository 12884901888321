// 工时费用统计报表-按单个项目导出
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { map } from 'rxjs/operators';

import { UserService } from 'src/app/services/user.service';
import { SharedService } from '../services/shared.service';
import { Observable } from 'rxjs';
import { EWorktimeCostReportViewPermission } from '../enums';


@Injectable({
    providedIn: 'root',
})
// tslint:disable-next-line:class-name
export class WorktimeCostReportByProjectGuard implements CanActivate {
    constructor(
        private router: Router,
        private userService: UserService,
        private sharedService: SharedService,
    ) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.userService.hasPermissionToReportWorktimeCost().pipe(
            map(permissionValue => {
                if ((permissionValue & EWorktimeCostReportViewPermission.ReportByProject) > 0) {
                    return true;
                } else {
                    this.sharedService.setRedirectUrl(window.location.href);
                    this.router.navigate(['/work-center']);
                    return false;
                }
            })
        );
    }

}