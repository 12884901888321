import { Reducer, Action } from 'redux';
import {
  SetRequirementAttachmentsItemsAction,
  SetRequirementAttachmentsDirtyAction,
  AddRequirementAttachmentsItemAction,
  DeleteRequirementAttachmentsItemAction,
  SET_REQUIREMENT_ATTACHMENTS_ITEMS,
  SET_REQUIREMENT_ATTACHMENTS_DIRTY,
  ADD_REQUIREMENT_ATTACHMENTS_ITEM,
  DELETE_REQUIREMENT_ATTACHMENTS_ITEM,
} from '../actions/requirement-attachments.actions';
import { createSelector } from 'reselect';
import {
  IFileUploadInfo
} from 'src/app/models';
import _ from 'lodash';
import { TRequirementAttachmentType } from 'src/app/types';

export interface RequirementAttachmentsState {
  originalItems: IFileUploadInfo[];
  originalDirty: boolean;
  initialBasicItems: IFileUploadInfo[];
  initialBasicDirty: boolean;
  initialAnalysisItems: IFileUploadInfo[];
  initialAnalysisDirty: boolean;
  auditItems: IFileUploadInfo[]; // 流程附件
  auditDirty: boolean;
}

const initialState: RequirementAttachmentsState = {
  originalItems: [],
  originalDirty: false,
  initialBasicItems: [],
  initialBasicDirty: false,
  initialAnalysisItems: [],
  initialAnalysisDirty: false,
  auditItems: [],
  auditDirty: false,
};

export const RequirementAttachmentsReducer: Reducer<RequirementAttachmentsState> =
  (state: RequirementAttachmentsState = initialState, action: Action): RequirementAttachmentsState => {
    let items: IFileUploadInfo[] = [];
    let item: IFileUploadInfo;
    let attachmentType: TRequirementAttachmentType;

    switch (action.type) {
      case SET_REQUIREMENT_ATTACHMENTS_ITEMS:
        items = (action as SetRequirementAttachmentsItemsAction).items;
        attachmentType = (action as SetRequirementAttachmentsItemsAction).attachmentType;
        switch (attachmentType) {
          case 'original':
            return Object.assign({}, state, {
              originalItems: items
            });
          case 'initial-basic':
            return Object.assign({}, state, {
              initialBasicItems: items
            });
          case 'initial-analysis':
            return Object.assign({}, state, {
              initialAnalysisItems: items
            });
          case 'audit':
            return Object.assign({}, state, {
              auditItems: items
            });
          default:
            return state;
        }

      case SET_REQUIREMENT_ATTACHMENTS_DIRTY:
        const dirty = (action as SetRequirementAttachmentsDirtyAction).dirty;
        attachmentType = (action as SetRequirementAttachmentsDirtyAction).attachmentType;
        switch (attachmentType) {
          case 'original':
            return Object.assign({}, state, {
              originalDirty: dirty
            });
          case 'initial-basic':
            return Object.assign({}, state, {
              initialBasicDirty: dirty
            });
          case 'initial-analysis':
            return Object.assign({}, state, {
              initialAnalysisDirty: dirty
            });
          case 'audit':
            return Object.assign({}, state, {
              auditDirty: dirty
            });
          default:
            return state;
        }

      case ADD_REQUIREMENT_ATTACHMENTS_ITEM:
        item = (action as AddRequirementAttachmentsItemAction).item;
        attachmentType = (action as AddRequirementAttachmentsItemAction).attachmentType;
        item._is_new = true;
        switch (attachmentType) {
          case 'original':
            items = state.originalItems;
            return Object.assign({}, state, {
              originalItems: [...items, item],
              originalDirty: true
            });
          case 'initial-basic':
            items = state.initialBasicItems;
            return Object.assign({}, state, {
              initialBasicItems: [...items, item],
              initialBasicDirty: true
            });
          case 'initial-analysis':
            items = state.initialAnalysisItems;
            return Object.assign({}, state, {
              initialAnalysisItems: [...items, item],
              initialAnalysisDirty: true
            });
          case 'audit':
            items = state.auditItems;
            return Object.assign({}, state, {
              auditItems: [...items, item],
              auditDirty: true
            });
          default:
            return state;
        }

      case DELETE_REQUIREMENT_ATTACHMENTS_ITEM:
        item = (action as DeleteRequirementAttachmentsItemAction).item;
        attachmentType = (action as AddRequirementAttachmentsItemAction).attachmentType;
        switch (attachmentType) {
          case 'original':
            items = state.originalItems;
            return Object.assign({}, state, {
              originalItems: _.reject(items, { url_path: item.url_path }),
              originalDirty: true
            });
          case 'initial-basic':
            items = state.initialBasicItems;
            return Object.assign({}, state, {
              initialBasicItems: _.reject(items, { url_path: item.url_path }),
              initialBasicDirty: true
            });
          case 'initial-analysis':
            items = state.initialAnalysisItems;
            return Object.assign({}, state, {
              initialAnalysisItems: _.reject(items, { url_path: item.url_path }),
              initialAnalysisDirty: true
            });
          case 'audit':
            items = state.auditItems;
            return Object.assign({}, state, {
              auditItems: _.reject(items, { url_path: item.url_path }),
              auditDirty: true
            });
          default:
            return state;
        }

      default:
        return state;
    }
  };

const getState = (state: RequirementAttachmentsState): RequirementAttachmentsState => state;

export const getRequirementAttachmentsOriginalItems = createSelector(
  getState,
  (state: RequirementAttachmentsState) => state.originalItems
);

export const getRequirementAttachmentsOriginalDirty = createSelector(
  getState,
  (state: RequirementAttachmentsState) => state.originalDirty
);

export const getRequirementAttachmentsInitialBasicItems = createSelector(
  getState,
  (state: RequirementAttachmentsState) => state.initialBasicItems
);

export const getRequirementAttachmentsInitialBasicDirty = createSelector(
  getState,
  (state: RequirementAttachmentsState) => state.initialBasicDirty
);

export const getRequirementAttachmentsInitialAnalysisItems = createSelector(
  getState,
  (state: RequirementAttachmentsState) => state.initialAnalysisItems
);

export const getRequirementAttachmentsInitialAnalysisDirty = createSelector(
  getState,
  (state: RequirementAttachmentsState) => state.initialAnalysisDirty
);

export const getRequirementAttachmentsAuditItems = createSelector(
  getState,
  (state: RequirementAttachmentsState) => state.auditItems
);

export const getRequirementAttachmentsAuditDirty = createSelector(
  getState,
  (state: RequirementAttachmentsState) => state.auditDirty
);
