<!-- #region tablePlaceholder -->
<div *ngIf="!items.length" class="text-center mt-5">
  <div><i class="fa fa-mixcloud fa-5x fa-fw text-secondary"></i></div>
  <div class="text-secondary"><b>暂无信息</b></div>
</div>
<div *ngIf="items && items.length">
  <form [formGroup]="checkForm">
    <table class="table-sm table-bordered table-hover table-fixed text-center align-middle">
      <thead>
        <tr (click)="toggleCheckAll()">
          <th class="masked" style="width:40px;">
            <input type="checkbox" [checked]="isCheckedAll">
          </th>
          <th style="width:60px;">序号</th>
          <th style="width:100px;">姓名</th>
          <th style="width:120px;">用户名</th>
          <th>部门</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of items;let i=index" (click)="toggleCheckItem(item)">
          <td class="masked">
            <input type="checkbox" [formControl]="checkForm.controls[item.user_id]">
          </td>
          <td>{{(page - 1) * limit + i + 1}}</td>
          <td class="ellipsis">{{item.name}}</td>
          <td class="ellipsis">{{item.user_id}}</td>
          <td class="text-left ellipsis" [title]="item.org_full_path">{{item.org_full_path}}</td>
        </tr>
      </tbody>
    </table>
  </form>
  <div class="pagination-box" *ngIf="tabValue !== 1 || tabValue === 1 && q">
    <app-pagination
      [pageLinkLimit]="pageLinkLimit"
      [page]="page"
      [limit]="limit"
      [count]="count"
      (pageChanged)="onPageChanged($event)"
    ></app-pagination>
  </div>
</div>
<!-- #endregion -->
<!-- #region 虚拟滚动 -->
<!-- <form [formGroup]="checkForm">
  <table class="table-sm table-bordered table-hover table-fixed text-center align-middle">
    <thead>
      <tr (click)="toggleCheckAll()">
        <th class="masked" style="width:40px;">
          <input type="checkbox" [checked]="isCheckedAll">
        </th>
        <th style="width:60px;">序号</th>
        <th style="width:100px;">姓名</th>
        <th style="width:120px;">用户名</th>
        <th>部门</th>
      </tr>
    </thead>
  </table> -->
<!-- cdk-virtual-scroll-viewport的高度固定为trHeight * chunkSize，防止虚拟滚动列表显示不全；通过viewport-box来限制虚拟滚动高度-->
<!-- <div class="viewport-box" style="overflow: hidden;" [ngStyle]="{'height.px': viewPortHeight}">
  <cdk-virtual-scroll-viewport [itemSize]="trHeight" [minBufferPx]="trHeight * 7" [maxBufferPx]="trHeight * 15"
    [ngStyle]="{'height.px': trHeight * chunkSize}">
    <table class="table-sm table-bordered table-hover table-fixed text-center align-middle">
      <tbody>
        <tr *cdkVirtualFor="let item of items; let i = index;  trackBy:indexTracker" (click)="toggleCheckItem(item)">
          <td style="width:40px;" class="masked">
            <input type="checkbox" [formControl]="checkForm.controls[item.user_id]">
          </td>
          <td style="width:60px;">{{i + 1}}</td>
          <td style="width:100px;" class="ellipsis">{{item.name}}</td>
          <td style="width:120px;" class="ellipsis">{{item.user_id}}</td>
          <td class="text-left ellipsis" [title]="item.org_full_path">{{item.org_full_path}}</td>
        </tr>
      </tbody>
    </table>
  </cdk-virtual-scroll-viewport>
</div>
</form>  -->
<!-- #endregion -->