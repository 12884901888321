<div class="nav-navigation" [ngClass]="{ narrow: !isExpand }">
  <div class="toggle radius-t" *ngIf="isExpand" (click)="toggle()">
    <i class="fa fa-outdent mr-2" aria-hidden="true"></i>折叠
  </div>
  <div class="toggle radius-t indent" *ngIf="!isExpand" (click)="toggle()">
    <i class="fa fa-indent mr-1" aria-hidden="true"></i>展开
  </div>
  <ul>
    <li
      class="nav-item mt-2"
      [routerLink]="['/portal']"
      routerLinkActive="active"
    >
      <a>
        <i class="fa fa-home fa-fw"></i>
        <span>首页</span>
      </a>
    </li>
    <li
      class="nav-item"
      [routerLink]="['/work-center']"
      routerLinkActive="active"
    >
      <a>
        <i class="fa fa-street-view fa-fw"></i>
        <span>工作中心</span>
      </a>
    </li>
    <li
      class="nav-item"
      [routerLink]="['/project-center']"
      routerLinkActive="active"
    >
      <a>
        <i class="fa fa-list-ol fa-fw"></i>
        <span>项目中心</span>
      </a>
    </li>
    <li class="nav-item" [routerLink]="['/report']" routerLinkActive="active">
      <a>
        <i class="fa fa-area-chart fa-fw"></i>
        <span>报表中心</span>
      </a>
    </li>
    <li *ngIf="showManagementCenter()" class="nav-item" [routerLink]="['/management-center']" routerLinkActive="active">
      <a>
        <i class="fa fa-cogs fa-fw"></i>
        <span>管理中心</span>
      </a>
    </li>
  </ul>
</div>
