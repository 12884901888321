import _ from 'lodash';
import { Reducer, Action } from 'redux';
import { createSelector } from 'reselect';
import { ICcOrganization } from 'src/app/models/IAuditInfo';
import { AddCcOrgAction, DeleteCcOrgAction, SetCcOrgsAction } from '../actions/cc-organization.actions';
// tslint:disable-next-line: max-line-length
import { SET_CC_ORGANIZATIONS, ADD_CC_ORGANIZATION, DELETE_CC_ORGANIZATION, CLEAR_CC_ORGANIZATIONS } from '../actions/cc-organization.actions';

export interface CcOrgState {
    organizations: ICcOrganization[];
}

const initialState: CcOrgState = {
    organizations: []
};

export const CcOrgsReducer: Reducer<CcOrgState> =
    (state: CcOrgState = initialState, action: Action): CcOrgState => {
        let organizations: ICcOrganization[];
        switch (action.type) {
            case SET_CC_ORGANIZATIONS:
                organizations = (action as SetCcOrgsAction).organizations;
                return Object.assign({}, state, {
                    organizations
                });
            case ADD_CC_ORGANIZATION:
                const organization: ICcOrganization = (action as AddCcOrgAction).organization;
                return Object.assign({}, state, {
                    organizations: _.unionBy(state.organizations, [organization], 'org_number')
                });
            case DELETE_CC_ORGANIZATION:
                const org: ICcOrganization = (action as DeleteCcOrgAction).organization;
                return Object.assign({}, state, {
                    organizations: _.reject(state.organizations, { org_number: org.org_number })
                });
            case CLEAR_CC_ORGANIZATIONS:
                return Object.assign({}, state, { organizations: [] });
            default:
                return state;


        }

    };

const getState = (state: CcOrgState): CcOrgState => state;
export const getCcOrganizations = createSelector(
    getState,
    (state: CcOrgState) => state.organizations
);
