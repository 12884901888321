import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PAGE_LINK_LIMIT } from 'src/app/consts';
import { IPagination } from 'src/app/models';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {
  @Input() pageLinkLimit: number = PAGE_LINK_LIMIT;
  @Input() count: number;
  @Input() page: number;
  @Input() limit: number;
  @Output() pageChanged: EventEmitter<IPagination> = new EventEmitter<IPagination>();
  constructor() { 
  }

  ngOnInit(): void {
  }
  

  onPageChanged(pagination: IPagination) {
   this.pageChanged.emit(pagination);
  }

  pageCount() {
    return Math.ceil(this.count / this.limit) > 0 ? Math.ceil(this.count / this.limit) : 1;;
  }

}
