import {
  trigger,
  style,
  animate,
  transition,
  state
} from '@angular/animations';

export const openCloseAnimation =
  trigger('openClose', [
    state('open', style({
      minHeight: '0.1px',
      opacity: 1
    })),
    state('closed', style({
      height: 0,
      minHeight: 0,
      opacity: 0
    })),
    transition('open => closed', [
      animate('0.3s ease-in')
    ]),
    transition('closed => open', [
      animate('0.3s ease-in')
    ])
  ]);
