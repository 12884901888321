import { Reducer, Action } from 'redux';
import {
  SET_PROJECT_FILTERS_ITEMS,
  TOGGLE_CHECK_PROJECT_FILTERS_ITEM,
  CLEAR_CHECK_PROJECT_FILTERS_ITEMS,
  SET_PROJECT_FILTERS_ORGANIZATIONS,
  SetProjectFiltersItemsAction,
  ToggleCheckProjectFiltersItemAction,
  ClearCheckProjectFiltersItemsAction,
  SetProjectFiltersOrganizationsAction,
  SET_PROJECT_FILTERS_PROJECT_CODES,
  SetProjectFiltersProjectCodesAction,
} from '../actions/project-filters.actions';

import { createSelector } from 'reselect';
import { IProjectFilterItem, IProjectFilterType, IOrganization, IProjectFilterProjectCode } from 'src/app/models';
import _ from 'lodash';

export interface ProjectFiltersState {
  // 属性按 IProjectFilterType 遍历
  divisions: IProjectFilterItem[];
  categories: IProjectFilterItem[];
  groups: IProjectFilterItem[];
  milestones: IProjectFilterItem[];
  enginFields: IProjectFilterItem[];
  projectRelationTypes: IProjectFilterItem[];
  lights: IProjectFilterItem[];
  statuses: IProjectFilterItem[];
  planLevels: IProjectFilterItem[];
  centers: IProjectFilterItem[];
  productLines: IProjectFilterItem[];
  worktimeDayStates: IProjectFilterItem[]; // 工时填报日状态
  projectLevels: IProjectFilterItem[]; // 项目级别
  brands: IProjectFilterItem[]; // 品牌
  developmentHosts: IProjectFilterItem[]; // 开发主体
  managementHosts: IProjectFilterItem[]; // 管理主体
  platforms: IProjectFilterItem[]; // 平台
  requirementFields: IProjectFilterItem[]; // 需求业务
  requirementStatuses: IProjectFilterItem[]; // 需求状态
  requirementTypes: IProjectFilterItem[]; // 需求类型
  requirementLevels: IProjectFilterItem[]; // 需求层级
  connectRequirements: IProjectFilterItem[]; // 是否已关联需求

  // 例外：
  // 组织
  organizations: IOrganization[];
  // 例外：
  // 项目编码
  projectCodes: IProjectFilterProjectCode[];
}

const initialState: ProjectFiltersState = {
  divisions: [],
  categories: [],
  groups: [],
  milestones: [],
  enginFields: [],
  projectRelationTypes: [],
  lights: [],
  statuses: [],
  organizations: [],
  developmentHosts: [],
  managementHosts: [],
  planLevels: [],
  centers: [],
  productLines: [],
  worktimeDayStates: [],
  projectLevels: [],
  brands: [],
  platforms: [],
  projectCodes: [],
  requirementFields: [],
  requirementStatuses: [],
  requirementTypes: [],
  requirementLevels: [],
  connectRequirements: []
};

export const ProjectFiltersReducer: Reducer<ProjectFiltersState> =
  (state: ProjectFiltersState = initialState, action: Action): ProjectFiltersState => {
    let items: IProjectFilterItem[];
    let item: IProjectFilterItem;
    let filterType: IProjectFilterType;
    let organizations: IOrganization[];
    let projectCodes: IProjectFilterProjectCode[];

    switch (action.type) {
      case SET_PROJECT_FILTERS_ITEMS:
        filterType = (action as SetProjectFiltersItemsAction).filterType;
        items = (action as SetProjectFiltersItemsAction).items;
        // 更新相应类型的filter
        return Object.assign({}, state, {
          [filterType]: items
        });

      case TOGGLE_CHECK_PROJECT_FILTERS_ITEM:
        filterType = (action as SetProjectFiltersItemsAction).filterType;
        item = (action as ToggleCheckProjectFiltersItemAction).item;
        const isMultiple = (action as ToggleCheckProjectFiltersItemAction).isMultiple;
        item.is_checked = !item.is_checked;
        items = state[filterType];
        const updateIndex = _.findIndex(items, { key: item.key });
        if (-1 === updateIndex) {
          return state;
        }
        // 多选
        if (isMultiple) {
          items = [
            ...items.slice(0, updateIndex),
            item,
            ...items.slice(updateIndex + 1)
          ];
          // 单选
        } else {
          _.forEach(items, item1 => {
            item1.is_checked = item.key === item1.key ? item.is_checked : false;
          });
        }

        // 该筛选条件不为空，只更新该筛选条件
        if (-1 !== _.findIndex(items, { is_checked: true })) {
          return Object.assign({}, state, {
            [filterType]: items
          });
        }
        // 该筛选条件为空，更新联动的筛选条件
        switch (filterType) {
          case 'divisions': // 清空选中事业部，对应清空项目细分类、项目群，节点，所属业务
            return Object.assign({}, state, {
              [filterType]: items,
              categories: [],
              groups: [],
              milestones: [],
              enginFields: []
            });
          case 'developmentHosts': // 清空选中开发主体，对应清空项目细分类、项目群，节点，所属业务
            return Object.assign({}, state, {
              [filterType]: items,
              categories: [],
              groups: [],
              milestones: [],
              enginFields: []
            });
          case 'categories': // 清空选中项目细分类，对应清空节点，所属业务
            return Object.assign({}, state, {
              [filterType]: items,
              milestones: [],
              enginFields: []
            });
          default:
            return Object.assign({}, state, {
              [filterType]: items
            });
        }
        break;

      case CLEAR_CHECK_PROJECT_FILTERS_ITEMS:
        filterType = (action as ClearCheckProjectFiltersItemsAction).filterType;
        items = state[filterType];
        _.forEach(items, item1 => {
          item1.is_checked = false;
        });
        // 更新联动的筛选条件
        switch (filterType) {
          case 'divisions': // 清空所选事业部，对应清空项目细分类、项目群，节点，所属业务
            return Object.assign({}, state, {
              [filterType]: items,
              categories: [],
              groups: [],
              milestones: [],
              enginFields: []
            });
          case 'developmentHosts': // 清空选中开发主体，对应清空项目细分类、项目群，节点，所属业务
            return Object.assign({}, state, {
              [filterType]: items,
              categories: [],
              groups: [],
              milestones: [],
              enginFields: []
            });
          case 'categories': // 清空所选项目细分类，对应清空节点，所属业务
            return Object.assign({}, state, {
              [filterType]: items,
              milestones: [],
              enginFields: []
            });
          default:
            return Object.assign({}, state, {
              [filterType]: items
            });
        }
        break;

      case SET_PROJECT_FILTERS_ORGANIZATIONS:
        organizations = (action as SetProjectFiltersOrganizationsAction).items;
        return Object.assign({}, state, { organizations });

      case SET_PROJECT_FILTERS_PROJECT_CODES:
        projectCodes = (action as SetProjectFiltersProjectCodesAction).items;
        return Object.assign({}, state, { projectCodes });


      default:
        return state;
    }
  };

const getState = (state: ProjectFiltersState): ProjectFiltersState => state;
export const getProjectFilters = createSelector(
  getState,
  (state: ProjectFiltersState) => state
);

export const getProjectFiltersCheckedDivisions = createSelector(
  getState,
  (state: ProjectFiltersState) => _.filter(state.divisions, { is_checked: true })
);

export const getProjectFiltersCheckedCategories = createSelector(
  getState,
  (state: ProjectFiltersState) => _.filter(state.categories, { is_checked: true })
);

export const getProjectFiltersCheckedGroups = createSelector(
  getState,
  (state: ProjectFiltersState) => _.filter(state.groups, { is_checked: true })
);

export const getProjectFiltersCheckedMilestones = createSelector(
  getState,
  (state: ProjectFiltersState) => _.filter(state.milestones, { is_checked: true })
);

export const getProjectFiltersCheckedEnginFields = createSelector(
  getState,
  (state: ProjectFiltersState) => _.filter(state.enginFields, { is_checked: true })
);

export const getProjectFiltersCheckedProjectRelationTypes = createSelector(
  getState,
  (state: ProjectFiltersState) => _.filter(state.projectRelationTypes, { is_checked: true })
);

export const getProjectFiltersCheckedLights = createSelector(
  getState,
  (state: ProjectFiltersState) => _.filter(state.lights, { is_checked: true })
);

export const getProjectFiltersCheckedStatuses = createSelector(
  getState,
  (state: ProjectFiltersState) => _.filter(state.statuses, { is_checked: true })
);

export const getProjectFiltersCheckedOrganizations = createSelector(
  getState,
  (state: ProjectFiltersState) => _.filter(state.organizations, { is_checked: true })
);

export const getProjectFiltersCheckedPlanLevels = createSelector(
  getState,
  (state: ProjectFiltersState) => _.filter(state.planLevels, { is_checked: true })
);

export const getProjectFiltersCheckedCenters = createSelector(
  getState,
  (state: ProjectFiltersState) => _.filter(state.centers, { is_checked: true })
);

export const getProjectFiltersCheckedProductLines = createSelector(
  getState,
  (state: ProjectFiltersState) => _.filter(state.productLines, { is_checked: true })
);

export const getProjectFiltersCheckedWorktimeDayStates = createSelector(
  getState,
  (state: ProjectFiltersState) => _.filter(state.worktimeDayStates, { is_checked: true })
);

export const getProjectFiltersCheckedProjectLevels = createSelector(
  getState,
  (state: ProjectFiltersState) => _.filter(state.projectLevels, { is_checked: true })
);

export const getProjectFiltersCheckedBrands = createSelector(
  getState,
  (state: ProjectFiltersState) => _.filter(state.brands, { is_checked: true })
);

export const getProjectFiltersCheckedDevelopmentHosts = createSelector(
  getState,
  (state: ProjectFiltersState) => _.filter(state.developmentHosts, { is_checked: true })
);

export const getProjectFiltersCheckedPlatforms = createSelector(
  getState,
  (state: ProjectFiltersState) => _.filter(state.platforms, { is_checked: true })
);

export const getProjectFiltersCheckedManagementHosts = createSelector(
  getState,
  (state: ProjectFiltersState) => _.filter(state.managementHosts, { is_checked: true })
);

export const getProjectFiltersCheckedRequirementFields = createSelector(
  getState,
  (state: ProjectFiltersState) => _.filter(state.requirementFields, { is_checked: true })
);

export const getProjectFiltersCheckedRequirementStatuses = createSelector(
  getState,
  (state: ProjectFiltersState) => _.filter(state.requirementStatuses, { is_checked: true })
);

export const getProjectFiltersCheckedRequirementTypes = createSelector(
  getState,
  (state: ProjectFiltersState) => _.filter(state.requirementTypes, { is_checked: true })
);

export const getProjectFiltersCheckedRequirementLevels = createSelector(
  getState,
  (state: ProjectFiltersState) => _.filter(state.requirementLevels, { is_checked: true })
);

export const getProjectFiltersCheckedProjectCodes = createSelector(
  getState,
  (state: ProjectFiltersState) => state.projectCodes
);

export const getProjectFiltersCheckedConnectRequirements = createSelector(
  getState,
  (state: ProjectFiltersState) => _.filter(state.connectRequirements, { is_checked: true })
);


