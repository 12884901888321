import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';

import { OrgChartContainerComponent } from './components/org-chart-container/org-chart-container.component';
import { OrgChartNodeComponent } from './components/org-chart-node/org-chart-node.component';
import { OrganizationChartComponent } from './components/organization-chart/organization-chart.component';
import { OrganizationChartItemComponent } from './components/organization-chart-item/organization-chart-item.component';
import { OrganizationChartDetailComponent } from './components/organization-chart-detail/organization-chart-detail.component';
import { OrganizationChartItemDetailComponent } from './components/organization-chart-item-detail/organization-chart-item-detail.component';
import { OrganizationTeamsComponent } from './components/organization-teams/organization-teams.component';
import { OrganizationTeamsItemComponent } from './components/organization-teams-item/organization-teams-item.component';
import { OrganizationChartTipsComponent } from './components/organization-chart-tips/organization-chart-tips.component';

@NgModule({
  declarations: [
    OrgChartContainerComponent,
    OrgChartNodeComponent,
    OrganizationChartComponent,
    OrganizationChartItemComponent,
    OrganizationChartDetailComponent,
    OrganizationChartItemDetailComponent,
    OrganizationTeamsComponent,
    OrganizationTeamsItemComponent,
    OrganizationChartTipsComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    ModalModule
  ],
  exports: [
    OrganizationChartComponent,
    OrganizationChartDetailComponent,
    OrganizationChartTipsComponent
  ]
})
export class SharedOrganizationChartModule { }
