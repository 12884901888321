// *任务状态
export enum ETaskInfoStatus {
    /// <summary>
    /// 待处理
    /// </summary>
    Pending = 0,

    /// <summary>
    /// 已处理
    /// </summary>
    Processed = 10,

    /// <summary>
    /// 已转办
    /// </summary>
    Forwarded = 20,

    /// <summary>
    /// 已废弃
    /// </summary>
    Abandoned = -1
}
