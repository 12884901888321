import { IOrganizationChartItem } from 'src/app/models';

// *默认组织架构（已废弃）
export const DEFAULT_ORGANIZATION_CHART_ITEMS: IOrganizationChartItem[] = [{
    parent_id: undefined,
    id: '1',
    name: '项目经理',
    disable_delete: true,
    disable_edit_name: true,
    person_min_limit: 1
}, {
    parent_id: '1',
    id: '2',
    name: '项目管理经理',
    disable_delete: true,
    disable_edit_name: true,
    person_min_limit: 1
}, {
    parent_id: '1',
    id: '3',
    name: '项目管理员',
    disable_delete: true,
    disable_edit_name: true,
    person_min_limit: 1,
    person_max_limit: 1
}, {
    parent_id: '1',
    id: '4',
    name: '采购工程经理',
    disable_delete: true,
    disable_edit_name: true,
    person_min_limit: 1
}];
