import { Action, ActionCreator } from 'redux';
import { IFileUploadInfo } from 'src/app/models';
import { TRequirementAttachmentType } from 'src/app/types';

// #region 设置附件列表
export interface SetRequirementAttachmentsItemsAction extends Action {
  items: IFileUploadInfo[];
  attachmentType: TRequirementAttachmentType;
}
export const SET_REQUIREMENT_ATTACHMENTS_ITEMS = 'SET_REQUIREMENT_ATTACHMENTS_ITEMS';
export const setRequirementAttachmentsItems: ActionCreator<SetRequirementAttachmentsItemsAction> = (items: IFileUploadInfo[], attachmentType: TRequirementAttachmentType) => ({
  type: SET_REQUIREMENT_ATTACHMENTS_ITEMS,
  items,
  attachmentType
});
// #endregion

// #region 设置附件dirty
export interface SetRequirementAttachmentsDirtyAction extends Action {
  dirty: boolean;
  attachmentType: TRequirementAttachmentType;
}
export const SET_REQUIREMENT_ATTACHMENTS_DIRTY = 'SET_REQUIREMENT_ATTACHMENTS_DIRTY';
export const setRequirementAttachmentsDirty: ActionCreator<SetRequirementAttachmentsDirtyAction> = (dirty: boolean, attachmentType: TRequirementAttachmentType) => ({
  type: SET_REQUIREMENT_ATTACHMENTS_DIRTY,
  dirty,
  attachmentType
});
// #endregion

// #region 增加附件
export interface AddRequirementAttachmentsItemAction extends Action {
  item: IFileUploadInfo;
  attachmentType: TRequirementAttachmentType;
}
export const ADD_REQUIREMENT_ATTACHMENTS_ITEM = 'ADD_REQUIREMENT_ATTACHMENTS_ITEM';
export const addRequirementAttachmentsItem: ActionCreator<AddRequirementAttachmentsItemAction> = (item: IFileUploadInfo, attachmentType: TRequirementAttachmentType) => ({
  type: ADD_REQUIREMENT_ATTACHMENTS_ITEM,
  item,
  attachmentType
});
// #endregion

// #region 删除附件
export interface DeleteRequirementAttachmentsItemAction extends Action {
  item: IFileUploadInfo;
  attachmentType: TRequirementAttachmentType;
}
export const DELETE_REQUIREMENT_ATTACHMENTS_ITEM = 'DELETE_REQUIREMENT_ATTACHMENTS_ITEM';
export const deleteRequirementAttachmentsItem: ActionCreator<DeleteRequirementAttachmentsItemAction> = (item: IFileUploadInfo, attachmentType: TRequirementAttachmentType) => ({
  type: DELETE_REQUIREMENT_ATTACHMENTS_ITEM,
  item,
  attachmentType
});
// #endregion
