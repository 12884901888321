import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import _ from 'lodash';

import { EDeliverableType } from 'src/app/enums';
import { SharedService } from './shared.service';
import { SubscriptionService } from './subscription.service';
import {
  IDeliverableInfoParams,
  IDeliverableInfo,
  IDeliverableType,
  IDeliverableItem,
  IMilestoneDeliverableInfoParams,
  IDigitalDeliverableInfo,
  IAlert,
  IPlanDeliverable,
  ICommonConfig,
  IConfigInfo,
  IDeliverableData,
  IMigrationTarget
} from 'src/app/models';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class DeliverableService {
  apiUrl = '/api/audits/deliverables';

  constructor(
    private http: HttpClient,
    private sharedService: SharedService,
    private subscriptionService: SubscriptionService,
    private configService: ConfigService
  ) { }


  getDeliverableTypes(): Observable<ICommonConfig[]> {
    return this.configService.getDigitalDeliverableTypesConfigs().pipe(
      map(digitalDeliverableTypes => {
        return _.concat(digitalDeliverableTypes, [{ key: EDeliverableType.File, name: '文件' }])
      })
    )
  }

  // 获取交付物信息（计划回执时提交过的）
  getDeliverableInfos(params: IDeliverableInfoParams): Observable<IDeliverableInfo[]> {
    return this.http.get<IDeliverableInfo[]>(`${this.apiUrl}`, { params: params as HttpParams }).pipe(
      map(deliverableInfos => {
        _.forEach(deliverableInfos, deliverableInfo => {
          deliverableInfo.light = +deliverableInfo.light; // bugfix: 服务端将number存成string
        });
        return deliverableInfos;
      }),
      catchError(this.sharedService.handleError('get plan deliverableInfos', []))
    );
  }

  // 获取节点下的所有交付物信息（计划回执时已提交的交付物有id、未提交的无id）
  getMilestoneDeliverableInfos(params: IMilestoneDeliverableInfoParams): Observable<IDeliverableInfo[]> {
    params = this.sharedService.omitEmptyParams(params);
    return this.http.get<IDeliverableInfo[]>(`/api/business/projects/${params.project_code}/${params.milestone_code}/deliverables`, {
      params: params as HttpParams
    }).pipe(
      map(deliverableInfos => {
        _.forEach(deliverableInfos, deliverableInfo => {
          deliverableInfo.light = +deliverableInfo.light; // bugfix: 服务端将number存成string
        });
        return deliverableInfos;
      }),
      catchError(this.sharedService.handleError('get milestone deliverableInfos', []))
    );
  }

  // 节点交付物清单 -> 计划交付物清单 (用于交付物清单组件展示)
  deliverableInfos2deliverableItems(deliverableInfos: IDeliverableInfo[]): IDeliverableItem[] {
    const deliverableItems: IDeliverableItem[] = [];
    _.forEach(deliverableInfos, deliverableInfo => {
      deliverableItems.push({
        code: deliverableInfo.code,
        name: deliverableInfo.name,
        light: +deliverableInfo.light,    // 交付物状态灯
        deliverable_types: deliverableInfo.deliverable_types,    // 交付物类型
        support_flow: deliverableInfo.support_flow,    // 支持流程
        support_standard: deliverableInfo.support_standard,    // 支持标准
        task_description: deliverableInfo.task_description,    // 任务描述
        attachments: deliverableInfo.attachments,
        type: +deliverableInfo.type,
        items: _.get(deliverableInfo, 'data.items') ? deliverableInfo.data.items : [],
        // id、plan_id用于交付物版本化
        id: deliverableInfo.id,
        plan_id: deliverableInfo.plan_id,
        is_secret: deliverableInfo.is_secret,
        is_can_view: deliverableInfo.is_can_view,
        is_can_download: deliverableInfo.is_can_download,
      });
    });
    return deliverableItems;
  }

  // 获取数据化交付物
  getDigitalDeliverables(projectCode: string): Observable<IDigitalDeliverableInfo[]> {
    // tslint:disable-next-line: max-line-length
    return this.http.get<IDigitalDeliverableInfo[]>(`/api/audits/deliverables/digital/${projectCode}`).pipe(
      catchError(this.sharedService.handleError('get digital deliverables', []))
    );
  }

  // 根据交付物type 获取数字化交付物
  getDigitalDeliverableByType(projectCode: string, type?: EDeliverableType): Observable<IDigitalDeliverableInfo> {
    // tslint:disable-next-line: max-line-length
    const params = this.sharedService.omitEmptyParams({ type });
    return this.http.get<IDigitalDeliverableInfo>(`/api/audits/deliverables/digital/${projectCode}`, { params: params as unknown as HttpParams }).pipe(
      map(digitalDeliverableInfos => {
        return _.find(digitalDeliverableInfos, { deliverable_type: type }) as IDigitalDeliverableInfo;
      }),
      catchError(this.sharedService.handleError('get digital deliverables', undefined))
    );
  }

  // 系统管理员更新交付物
  systemAdminUpdateDeliverables(auditId: string, deliverables: IDeliverableItem[]): Observable<any> {
    return this.http.post<any>(`/api/audits/admins/update-deliverable/${auditId}`, { deliverables }).pipe(
      catchError(this.sharedService.handleError('systemAdmin update deliverables', false))
    );
  }

  // 验证数据化交付物是否合法
  verifyDigitalDeliverableIsValid(deliverables: IDeliverableItem[]): boolean {
    let flag = true;
    _.forEach(deliverables, deliverable => {
      _.forEach(deliverable.deliverable_types, type => {
        if (type === EDeliverableType.Digital && !this.verifyConceptDeliverableIsValid(deliverable.items)) {
          flag = false;
        }
        if (type === EDeliverableType.AchievementTarget && !this.verifyAchievementTargetDeliverableIsValid(deliverable.items)) {
          flag = false;
        }
        if (type === EDeliverableType.MigrationTarget && !this.verifyMigrationTargetDeliverableIsValid(deliverable.items)) {
          flag = false;
        }
      });
    });
    return flag;
  }

  verifyConceptDeliverableIsValid(deliverables: IDeliverableData[]): boolean {
    const item = _.find(deliverables, { type: EDeliverableType.Digital });
    if (item && !item.report_data.valid) {
      const alert: IAlert = {
        type: 'danger',
        title: '概念报告不能为空',
        content: ['概念报告必须编辑']
      };
      this.subscriptionService.publishAlert(alert);
      return false;
    }
    return true;
  }

  verifyAchievementTargetDeliverableIsValid(deliverables: IDeliverableData[]): boolean {
    const item = _.find(deliverables, { type: EDeliverableType.AchievementTarget });
    if (item && !item.report_data.valid) {
      const alert: IAlert = {
        type: 'danger',
        title: `成果目标内容不符合要求，请重新维护`,
      };
      this.subscriptionService.publishAlert(alert);
      return false;
    }
    return true;
  }

  verifyMigrationTargetDeliverableIsValid(deliverables: IDeliverableData[]): boolean {
    const item = _.find(deliverables, { type: EDeliverableType.MigrationTarget });
    if (item && !item.report_data.valid) {
      const alert: IAlert = {
        type: 'danger',
        title: `迁移目标内容不符合要求，请重新维护`,
      };
      this.subscriptionService.publishAlert(alert);
      return false;
    }
    return true;
  }

  verifyAchievementTargetValid(items: IMigrationTarget[], achievementTargets: IConfigInfo[], showAlert?: boolean): boolean {
    let flag = true;
    const groupAchievementTargetsById = _.groupBy(achievementTargets, 'key');
    _.forEach(items, item => {
      if (!item.is_droped && !groupAchievementTargetsById[item.archievement_id]) {
        if (showAlert) {
          const alert: IAlert = {
            type: 'danger',
            title: `成果名称为“${item.archievement_name}”的成果目标已废弃，请重新选择技术成果名称或废弃该迁移目标`,
          };
          this.subscriptionService.publishAlert(alert);
        }
        flag = false;
      }
    });
    return flag;
  }

  // 验证每个文件类型交付物都已上传文件
  verifyDeliverablesEachHasFile(deliverables: IDeliverableItem[]): boolean {
    let flag = true;
    deliverables.forEach(deliverable => {
      const index = deliverable.deliverable_types.findIndex((type) => +type === EDeliverableType.File);
      // tslint:disable-next-line: max-line-length
      if (index !== -1 && !_.get(deliverable, 'items[0].files.length')) {
        const alert: IAlert = {
          type: 'danger',
          title: '文件交付物内容不能为空',
          content: ['文件交付物必须上传']
        };
        this.subscriptionService.publishAlert(alert);
        flag = false;
      }
    });
    return flag;
  }

  verifyNewSpecialDeliverableNameNotNull(targetDeliverable: IPlanDeliverable) {
    if (!targetDeliverable.name) {
      const alert: IAlert = {
        type: 'danger',
        title: '非标准交付物的交付物名称不能为空',
      };
      this.subscriptionService.publishAlert(alert);
      return false;
    }
    return true;
  }

  verifySpecialDeliverableNamesNotRepeat(specialDeliverables: IPlanDeliverable[], targetDeliverable: IPlanDeliverable, index: number) {
    const specialDeliverableNames = [];
    let specialDeliverableNamesNotRepeat = true;
    _.forEach(specialDeliverables, (specialDeliverable, i) => {
      if (i !== index) {
        specialDeliverableNames.push(specialDeliverable.name);
      }
    });
    _.forEach(specialDeliverableNames, specialDeliverableName => {
      if (specialDeliverableName === targetDeliverable.name) {
        specialDeliverableNamesNotRepeat = false;
      }
    });
    if (!specialDeliverableNamesNotRepeat) {
      const alert: IAlert = {
        type: 'danger',
        title: '交付物名称不能重复',
      };
      this.subscriptionService.publishAlert(alert);
      return false;
    }
    return true;
  }

  // 为交付物分配保密属性（is_secret、is_can_view、is_can_download）
  assignDeliverableSecretProperty(
    deliverableItems: IDeliverableItem[],
    deliverableInfos: IDeliverableInfo[]
  ) {
    const deliverableInfosGroupByPlan = _.groupBy(deliverableInfos, 'plan_id');
    _.forEach(deliverableItems, item => {
      if (deliverableInfosGroupByPlan[item.plan_id].length) {
        item.is_secret = deliverableInfosGroupByPlan[item.plan_id][0].is_secret;
        item.is_can_view = deliverableInfosGroupByPlan[item.plan_id][0].is_can_view;
        item.is_can_download = deliverableInfosGroupByPlan[item.plan_id][0].is_can_download;
      }
    })
  }
}
