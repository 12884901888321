// *工作流节点状态
export enum EWorkflowStepStatus {
    /// <summary>
    /// 待审批
    /// </summary>
    Pending = 0,

    /// <summary>
    /// 通过
    /// </summary>
    Approval = 10,

    /// <summary>
    /// 驳回
    /// </summary>
    Reject = 20,

    /// <summary>
    /// 撤回
    /// </summary>
    Cancel = -1
}
