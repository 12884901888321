import _ from 'lodash';

import { Reducer, Action } from 'redux';
import {
  SHOW_CHECK_PROJECTS,
  SET_CHECK_PROJECTS_NEW_PROJECTS,
  CONFIRM_CHECK_PROJECTS,
  CANCEL_CHECK_PROJECTS,
  ShowCheckProjectsAction,
  SetCheckProjectsNewProjectsAction,
  ConfirmCheckProjectsAction,
  CancelCheckProjectsAction
} from '../actions/check-projects.actions';
import { createSelector } from 'reselect';
import { IProject, ICheckProjectsCallback } from 'src/app/models';
import { EAuditType } from 'src/app/enums';

export interface CheckProjectsState {
  isShow: boolean;
  auditType: EAuditType;
  isMultiple: boolean;
  tooltip: string;
  confirmMessage:string;
  callback: ICheckProjectsCallback;
  oldProjects: IProject[];
  newProjects: IProject[];
}

const initialState: CheckProjectsState = {
  isShow: false,
  auditType: undefined,
  isMultiple: false,
  tooltip: '',
  confirmMessage:'',
  callback: undefined,
  oldProjects: [],
  newProjects: [],
};

export const CheckProjectsReducer: Reducer<CheckProjectsState> =
  (state: CheckProjectsState = initialState, action: Action): CheckProjectsState => {
    switch (action.type) {
      case SHOW_CHECK_PROJECTS:
        const callback = (action as ShowCheckProjectsAction).callback;
        const auditType = (action as ShowCheckProjectsAction).auditType;
        const isMultiple = (action as ShowCheckProjectsAction).isMultiple;
        const tooltip = (action as ShowCheckProjectsAction).tooltip;
        const confirmMessage = (action as ShowCheckProjectsAction).confirmMessage;
        const oldProjects = (action as ShowCheckProjectsAction).oldProjects || [];
        return Object.assign({}, state, {
          isShow: true,
          auditType,
          callback,
          isMultiple,
          tooltip,
          confirmMessage,
          oldProjects
        });

      case SET_CHECK_PROJECTS_NEW_PROJECTS:
        const newProjects = (action as SetCheckProjectsNewProjectsAction).newProjects;
        return Object.assign({}, state, {
          newProjects
        });

      case CONFIRM_CHECK_PROJECTS:
        if (state.callback) {
          state.callback(state.newProjects);
        }
        return Object.assign({}, state, {
          isShow: false,
          auditType: undefined,
          tooltip: '',
          confirmMessage:'',
          callback: undefined,
          oldProjects: undefined,
          newProjects: []
        });

      case CANCEL_CHECK_PROJECTS:
        return Object.assign({}, state, {
          isShow: false,
          auditType: undefined,
          tooltip: '',
          confirmMessage:'',
          callback: undefined,
          oldProjects: undefined,
          newProjects: []
        });

      default:
        return state;
    }
  };

const getState = (state: CheckProjectsState): CheckProjectsState => state;
export const getCheckProjectsShow = createSelector(
  getState,
  (state: CheckProjectsState) => state.isShow
);

export const getCheckProjectsAuditType = createSelector(
  getState,
  (state: CheckProjectsState) => state.auditType
);

export const getCheckProjectsMultiple = createSelector(
  getState,
  (state: CheckProjectsState) => state.isMultiple
);

export const getCheckProjectsTooltip = createSelector(
  getState,
  (state: CheckProjectsState) => state.tooltip
);

export const getCheckProjectsConfirmMessage = createSelector(
  getState,
  (state: CheckProjectsState) => state.confirmMessage
);

export const getCheckProjectsCallback = createSelector(
  getState,
  (state: CheckProjectsState) => state.callback
);

export const getCheckProjectsNewProjects = createSelector(
  getState,
  (state: CheckProjectsState) => state.newProjects
);
