import _ from 'lodash';

import { Reducer, Action } from 'redux';
import {
  SHOW_SAVE_NOTIFY,
  CONFIRM_SAVE_NOTIFY,
  CANCEL_SAVE_NOTIFY,
  ShowSaveNotifyAction,
  ConfirmSaveNotifyAction,
  CancelSaveNotifyAction
} from '../actions/save-notify.actions';
import { createSelector } from 'reselect';
import { ISaveNotifyCallback } from 'src/app/models';

export interface SaveNotifyState {
  isShow: boolean;
  confirmCallback: ISaveNotifyCallback;
  cancelCallback: ISaveNotifyCallback;
}

const initialState: SaveNotifyState = {
  isShow: false,
  confirmCallback: undefined,
  cancelCallback: undefined
};

export const SaveNotifyReducer: Reducer<SaveNotifyState> =
  (state: SaveNotifyState = initialState, action: Action): SaveNotifyState => {
    switch (action.type) {
      case SHOW_SAVE_NOTIFY:
        const confirmCallback = (action as ShowSaveNotifyAction).confirmCallback;
        const cancelCallback = (action as ShowSaveNotifyAction).cancelCallback;
        return Object.assign({}, state, {
          isShow: true,
          confirmCallback,
          cancelCallback
        });

      case CONFIRM_SAVE_NOTIFY:
        if (state.confirmCallback) {
          state.confirmCallback();
        }
        return Object.assign({}, state, {
          isShow: false,
          confirmCallback: undefined,
          cancelCallback: undefined
        });

      case CANCEL_SAVE_NOTIFY:
        if (state.cancelCallback) {
          state.cancelCallback();
        }
        return Object.assign({}, state, {
          isShow: false,
          confirmCallback: undefined,
          cancelCallback: undefined
        });

      default:
        return state;
    }
  };

const getState = (state: SaveNotifyState): SaveNotifyState => state;
export const getSaveNotifyShow = createSelector(
  getState,
  (state: SaveNotifyState) => state.isShow
);

export const getSaveNotifyConfirmCallback = createSelector(
  getState,
  (state: SaveNotifyState) => state.confirmCallback
);

export const getSaveNotifyCancelCallback = createSelector(
  getState,
  (state: SaveNotifyState) => state.cancelCallback
);
