// 单子作废时效类型
export enum EWorkflowIndate {
    /// <summary>
    /// 未提交
    /// </summary>
    UnSubmitted = 0,

    /// <summary>
    /// 流程中驳回
    /// </summary>
    ReviewingRejected = 30,

    /// <summary>
    /// 通过后驳回
    /// </summary>
    ApprovedRejected = 40,
}
