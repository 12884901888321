import _ from 'lodash';

import { Reducer, Action } from 'redux';
import {
  SHOW_TURN,
  SET_TURN_INFO,
  CONFIRM_TURN,
  CANCEL_TURN,
  ShowTurnAction,
  SetTurnInfoAction,
  ConfirmTurnAction,
  CancelTurnAction
} from '../actions/turn.actions';
import { createSelector } from 'reselect';
import { ITurnInfo, ITurnCallback, ITaskInfo, ITurnFailedCallback, IWorkflow } from 'src/app/models';
import { ECheckUsersType } from 'src/app/enums';

export interface TurnState {
  isShow: boolean;
  auditId: string;
  callback: ITurnCallback;
  callbackFailed: ITurnFailedCallback;
  turnInfo: ITurnInfo;
  turnTask: ITaskInfo;
  turnBy: string;
  flow: IWorkflow;
  turnUserType: ECheckUsersType;
}

const initialState: TurnState = {
  isShow: false,
  callback: undefined,
  callbackFailed: undefined,
  auditId: undefined,
  turnInfo: undefined,
  turnTask: undefined,
  turnBy: undefined,
  flow: undefined,
  turnUserType: undefined
};

export const TurnReducer: Reducer<TurnState> =
  (state: TurnState = initialState, action: Action): TurnState => {
    switch (action.type) {
      case SHOW_TURN:
        const callback = (action as ShowTurnAction).callback;
        const callbackFailed = (action as ShowTurnAction).callbackFailed;
        const auditId = (action as ShowTurnAction).auditId;
        const turnTask = (action as ShowTurnAction).turnTask;
        const turnBy = (action as ShowTurnAction).turnBy;
        const flow = (action as ShowTurnAction).flow;
        const turnUserType = (action as ShowTurnAction).turnUserType;
        return Object.assign({}, state, {
          isShow: true,
          callback,
          callbackFailed,
          auditId,
          turnTask,
          turnBy,
          flow,
          turnUserType
        });

      case SET_TURN_INFO:
        const turnInfo = (action as SetTurnInfoAction).turnInfo;
        return Object.assign({}, state, {
          turnInfo
        });

      case CONFIRM_TURN:
        if (state.callback) {
          state.callback();
        }
        return Object.assign({}, state, {
          isShow: false,
          callback: undefined,
          callbackFailed: undefined,
          auditId: undefined,
          turnInfo: undefined,
          turnTask: undefined,
          turnBy: undefined,
          flow: undefined,
          turnUserType: undefined
        });

      case CANCEL_TURN:
        if (state.callbackFailed) {
          state.callbackFailed();
        }
        return Object.assign({}, state, {
          isShow: false,
          callback: undefined,
          callbackFailed: undefined,
          auditId: undefined,
          turnInfo: undefined,
          turnTask: undefined,
          turnBy: undefined,
          flow: undefined,
          turnUserType: undefined
        });

      default:
        return state;
    }
  };

const getState = (state: TurnState): TurnState => state;
export const getTurnShow = createSelector(
  getState,
  (state: TurnState) => state.isShow
);

export const getTurnAuditId = createSelector(
  getState,
  (state: TurnState) => state.auditId
);

export const getTurnCallback = createSelector(
  getState,
  (state: TurnState) => state.callback
);

export const getTurnCallbackFailed = createSelector(
  getState,
  (state: TurnState) => state.callbackFailed
);

export const getTurnInfo = createSelector(
  getState,
  (state: TurnState) => state.turnInfo
);

export const getTurnTask = createSelector(
  getState,
  (state: TurnState) => state.turnTask
);

export const getTurnTurnBy = createSelector(
  getState,
  (state: TurnState) => state.turnBy
);


export const getTurnIsAdminApproval = createSelector(
  getState,
  (state: TurnState) => state.flow
);

export const getTurnUserType = createSelector(
  getState,
  (state: TurnState) => state.turnUserType
);
