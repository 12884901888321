import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import _ from 'lodash';

import { IChatInfo, IChatCreateCommand, IChatCancelCommand, IWorkflow, IHandlerPerson, IChatReplyCommand } from 'src/app/models';
import { ConfigService } from './config.service';
import { switchMap, catchError } from 'rxjs/operators';
import { SharedService } from './shared.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  apiUrl = '/api/audits/chats';

  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private sharedService: SharedService,
    private userService: UserService
  ) { }

  // 获取沟通
  // getChat(auditId: string): Observable<IChat> {
  //   return this.http.get(`${this.apiUrl}/${auditId}`).pipe(
  //     catchError(this.sharedService.handleError('get chat', undefined))
  //   );
  // }

  // 发起|追加 沟通
  createChat(auditId: string, chatInfo: IChatInfo, workflowCurrentStepPersons: IHandlerPerson[]): Observable<boolean> {
    // 获取当前工作流节点上的当前用户的信息（包含沟通信息）
    const currentUser = this.userService.getCurrentUserSync();
    const currentPerson = _.find(workflowCurrentStepPersons, { user_id: currentUser.user_id });

    // is_chatting 为 true，说明当前正在沟通中，再发起沟通，此时是追加沟通，使用原来的chatting_room_id
    // is_chatting 为 false，说明为发起沟通，或沟通已回复完成，此时是新建沟通，分配新的chatting_room_id
    // (is_chatting === !audit.is_can_chat， 二者同步)
    return (currentPerson.is_chatting ? of(currentPerson.chatting_room_id) : this.configService.getNewCode()).pipe(
      switchMap(id => {
        const chatCreateCommand: IChatCreateCommand = {
          ...chatInfo,
          chat_id: id,  // 聊天室 ID
          workflow_id: auditId,    // 审批流 ID
          user_id: currentUser.user_id    // 发起人账号
        };
        return this.http.post<boolean>(`${this.apiUrl}/start`, chatCreateCommand);
      }),
      catchError(this.sharedService.handleError('create chat', false))
    );
  }

  // 取消沟通
  cancelChat(workflowCurrentStepPersons: IHandlerPerson[]): Observable<boolean> {
    // 获取当前工作流节点上的当前用户的信息（包含沟通信息）
    const currentUser = this.userService.getCurrentUserSync();
    const currentPerson = _.find(workflowCurrentStepPersons, { user_id: currentUser.user_id });

    // 正在沟通中可撤回，否则不可撤回
    if (currentPerson.is_chatting) {
      const command: IChatCancelCommand = {
        chat_id: currentPerson.chatting_room_id,
        user_id: currentPerson.user_id
      };
      return this.http.put<boolean>(`${this.apiUrl}/${command.chat_id}/cancel`, command).pipe(
        catchError(this.sharedService.handleError(`cancel chat: ${command.chat_id}`, false))
      );
    } else {
      return of(false);
    }
  }

  // 回复沟通
  replyChat(command: IChatReplyCommand) {
    return this.http.put<boolean>(`${this.apiUrl}/${command.chat_id}/reply`, command).pipe(
      catchError(this.sharedService.handleError(`reply chat: ${command.chat_id}`, false))
    );
  }

  // 是否当前用户有已发起的沟通（正在进行中，没有取消或全部被回复）
  isCurrentUserStartedChat(workflowCurrentStepPersons: IHandlerPerson[]): boolean {
    // 获取当前工作流节点上的当前用户的信息（包含沟通信息）
    const currentUser = this.userService.getCurrentUserSync();
    const currentPerson = _.find(workflowCurrentStepPersons, { user_id: currentUser.user_id });
    return currentPerson && currentPerson.is_chatting;
  }
}
