/// <summary>
/// 组织节点标签类型
/// </summary>
export enum EOrganizationNodeTagType {
    /// <summary>
    /// 无
    /// </summary>
    None = 0,

    /// <summary>
    /// 国内
    /// </summary>
    Domestic = 10,

    /// <summary>
    /// 国外
    /// </summary>
    Foreign = 20,

}
