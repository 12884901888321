import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import _ from 'lodash';
import { CRadioBase } from 'src/app/classes';
import { PAGE_LINK_LIMIT, PAGE_MAX_LIMIT } from 'src/app/consts';
import { IOrganization, IOrganizationUserParams, IPagination, IUserInfo } from 'src/app/models';
import { UserService } from 'src/app/services/user.service';
import { ProjectRoleService } from '../../permission/services/project-role.service';

@Component({
  selector: 'app-check-organization-user-list',
  templateUrl: './check-organization-user-list.component.html',
  styleUrls: ['./check-organization-user-list.component.scss']
})
export class CheckOrganizationUserListComponent extends CRadioBase<IUserInfo, any> implements OnInit, OnChanges {

  // tslint:disable-next-line: variable-name
  private _allCheckedItems: IUserInfo[];
  @Input()
  get allCheckedItems(): IUserInfo[] {
    return this._allCheckedItems;
  }
  set allCheckedItems(value) {
    this._allCheckedItems = value;
    this.allCheckedItemsChange.emit(value);
  }

  @Input() tabValue: number;
  @Input() checkedOrganization: IOrganization;
  @Input() checkedLatelyOrgNumber: string;
  @Input() uncheckedItem: IUserInfo;
  @Input() q: string;

  page = 1;
  limit = 8;
  pageLinkLimit = PAGE_LINK_LIMIT;
  count = 0;

  @Output()
  allCheckedItemsChange: EventEmitter<IUserInfo[]> = new EventEmitter();

  lateLyUsers: IUserInfo[];
  constructor(
    private userService: UserService,
    private projectRoleService: ProjectRoleService,
  ) {
    super(userService, 'getLatelyUsers', 'user_id');

    this.radio.valueChanges.subscribe(value => {
      if (!value) {
        return;
      }
      const checkedItem = _.find(this.items, { user_id: value });
      if (!checkedItem) {
        return;
      }
      this.allCheckedItems = [checkedItem];
    });

  }

  ngOnInit() {
    this.initUserLists();
  }

  initUserLists() {
    switch (this.tabValue) {
      case 1: // “最近”
        super.updateConstructor(this.userService, 'getLatelyUsers', 'user_id');
        super.getItemsNotCheckedFirstItem(this.allCheckedItems[0]);
        break;

      case 2:  // “组织结构”
        super.updateConstructor(this.projectRoleService, 'getOrganizationUsers', 'user_id');
        if (!this.checkedOrganization) {
          this.items = [];
          return;
        }
        this.getOrganizationUsers(this.checkedOrganization.org_number);
        this.getOrganizationUsersCount();
        break;

      case 3:  // “常用组织”
        super.updateConstructor(this.projectRoleService, 'getOrganizationUsers', 'user_id');
        this.getOrganizationUsers(this.checkedLatelyOrgNumber);
        this.getOrganizationUsersCount();
        break;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.checkedOrganization && changes.checkedOrganization.currentValue) {
      this.checkedOrganization = changes.checkedOrganization.currentValue;
      if (changes.tabValue) {
        // 未updateConstructor前，不需要getOrganizationUser
        return;
      }
      this.page = 1;
      this.q = '';
      this.initUserLists();
    }

    if (changes.checkedLatelyOrgNumber && changes.checkedLatelyOrgNumber.currentValue) {
      this.checkedLatelyOrgNumber = changes.checkedLatelyOrgNumber.currentValue;
      if (changes.tabValue) {
        // 未updateConstructor前，不需要getOrganizationUser
        return;
      }
      this.page = 1;
      this.q = '';
      this.initUserLists();
    }

    if (changes.uncheckedItem && changes.uncheckedItem.currentValue) {
      this.radio.setValue(undefined);
    }

    if (changes.q && !_.isUndefined(changes.q.currentValue)) {
      if (changes.q.currentValue) {
        this.q = changes.q.currentValue;
        this.page = 1;
        super.updateConstructor(this.userService, 'getUsers', 'user_id');
        this.getSearchedUsers();
        this.getSearchedUsersCount();
        return;
      }
      this.initUserLists();
    }
  }

  getOrganizationUsers(checkedOrgNumber: string) {
    if (!checkedOrgNumber) {
      return;
    }
    this.queryParams = {
      page: this.page,
      limit: this.limit,
      org_numbers: [checkedOrgNumber]
    } as IOrganizationUserParams;
    super.getItemsNotCheckedFirstItem(this.allCheckedItems[0]);
  }

  getOrganizationUsersCount() {
    if (!this.queryParams?.org_numbers?.length) {
      return;
    }
    this.projectRoleService.getOrganizationUsersCount(this.queryParams).subscribe(count => {
      this.count = count;
    });
  }

  getSearchedUsers() {
    this.queryParams = {
      page: this.page,
      limit: this.limit,
      q: this.q
    };
    super.getItemsNotCheckedFirstItem(this.allCheckedItems[0]);
  }

  getSearchedUsersCount() {
    this.userService.getUsersCount(this.queryParams).subscribe(count => {
      this.count = count;
    });
  }

  onPageChanged(pagination: IPagination) {
    this.page = pagination.page;
    if (this.q) {
      // 通过q来区分当前是否为查询后的列表；若存在q，当前为查询列表
      this.getSearchedUsers();
      return;
    }
    switch (this.tabValue) {
      case 2:  // “组织结构”
        if (!this.checkedOrganization) {
          this.items = [];
          return;
        }
        this.getOrganizationUsers(this.checkedOrganization.org_number);
        break;

      case 3:  // “常用组织”
        this.getOrganizationUsers(this.checkedLatelyOrgNumber);
        break;
    }

  }
}


