import { Action, ActionCreator } from 'redux';
import { IMilestone } from 'src/app/models';

// #region Milestones
export interface SetMilestonesAction extends Action {
  milestones: IMilestone[];
}
export const SET_MILESTONES = 'SET_MILESTONES';
export const setMilestones: ActionCreator<SetMilestonesAction> = (milestones: IMilestone[]) => ({
  type: SET_MILESTONES,
  milestones
});
// #endregion

// #region currentMilestone
export interface SetCurrentMilestoneAction extends Action {
  currentMilestone: IMilestone;
}
export const SET_CURRENT_MILESTONE = 'SET_CURRENT_MILESTONE';
export const setCurrentMilestone: ActionCreator<SetCurrentMilestoneAction> = (currentMilestone: IMilestone) => ({
  type: SET_CURRENT_MILESTONE,
  currentMilestone
});
// #endregion

// #region endOn
export interface SetEndOnAction extends Action {
  endOn: Date;
}
export const SET_END_ON = 'SET_END_ON';
export const setEndOn: ActionCreator<SetEndOnAction> = (endOn: Date) => ({
  type: SET_END_ON,
  endOn
});
// #endregion
